<!--
 * @Description: 页面模板
 * @Author: luocheng
 * @Date: 2022-04-07 09:53:28
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-28 16:58:44
-->
<template>
  <el-dialog
    title="模板列表"
    :visible.sync="value"
    :fullscreen="true"
    :append-to-body="true"
    custom-class="page-template"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :show-close="false"
    :destroy-on-close="true"
    v-loading="loading"
    >
    <article class="template-list">
      <template v-if="templateList && templateList.length">
        <section v-for="item in templateList" :key="item.id" class="item"
          :class="{
            'is-selected': selected && selected.id === item.id
          }"
        >
          <el-image :src="item.graphic || defaultCover" class="graphic" />
          <h3 class="name">{{ item.name }}</h3>
          <div class="actions">
            <a href="javascript:;" @click="onPreview(item)">预览</a>
            <a href="javascript:;" @click="onSelect(item)">选中</a>
          </div>
        </section>
      </template>
      <el-empty description="暂无模板" v-else></el-empty>
    </article>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onConfirm" :disabled="!selected">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { Empty, Image } from 'element-ui';
import { mapState } from 'vuex';

export default {
  name: 'PageTemplate',
  components: {
    'el-empty': Empty,
    'el-image': Image
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      templateList: [], // 模板列表
      defaultCover: require('@/assets/images/logo1.png'),
      // 当前选中
      selected: null
    }
  },
  computed: {
    ...mapState(['componentData'])
  },
  created() {
    this.getTemplates();
  },
  methods: {
    /**
     * @desc: 获取模板列表
     */
    getTemplates() {
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        object_uuid:'a4f016d6-c602-4492-8874-f088c3c0b3b9',
        view_uuid:'view624674683abb7',
        transcode: 0
      }).then(res => {
        this.templateList = res?.data?.data || [];
        this.loading = false;
      }).catch(err => {
        console.log(err, '555555')
        this.loading = false;
      })
    },
    /**
     * @desc: 预览
     * @param {Object} item 模板
     */
    onPreview(item) {
      window.open(`${window.location.origin}/page-view?pageUUID=${item.uuid}`)
    },
    /**
     * @desc: 选中模板
     * @param {Object} item
     */
    onSelect(item) {
      this.selected = item;
    },
    /**
     * @desc: 确认
     */
    onConfirm() {
      this.$confirm(`是否确认使用模板——【${this.selected.name}】?`).then(() => {
        this.$store.commit('setComponentsBatch', this.selected?.page_data?.componentData);
        this.onClose();
      }).catch(() => {});
    },
    /**
     * @desc: 取消
     */
    onClose() {
      this.$emit('input', false);
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.page-template){
  display: flex;
  flex-direction: column;
  .el-dialog__header{
    border-bottom: 1px solid #f2f3f5;
  }
  .el-dialog__body{
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
  .template-list{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;
    padding: 16px;
    .item{
      position: relative;
      width: 240px;
      height: 280px;
      box-shadow: 0 3px 10px 0 rgba(31, 35, 41, 0.1);
      cursor: pointer;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 10px;
      overflow: hidden;
      .el-image{
        width: 100%;
        margin: 0 auto;
      }
      .name{
        text-align: center;
        line-height: 20px;
        font-size: 14px;
        word-break: break-all;
        padding: 10px 0;
      }
      .actions{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 100%;
        left: 0;
        background: rgba(0, 0, 0, .3);
        display: flex;
        z-index: 100;
        a{
          flex: 1;
          height: 100%;
          line-height: 280px;
          text-align: center;
          font-size: 16px;
          color: #ccc;
          font-weight: bold;
          &:hover{
            color: #fff;
            text-decoration: underline;
          }
        }
      }
      &:hover{
        box-shadow: 0px 10px 10px 3px rgba(31, 35, 41, 0.2);
        .actions{
          top: 0;
        }
      }
      &.is-selected{
        &::after{
          position: absolute;
          top: 0;
          left: 0;
          height: calc(100% - 6px);
          width: calc(100% - 6px);
          box-sizing: border;
          content: '';
          border: 3px solid @theme;
          border-radius: 10px;
          z-index: 10;
        }
      }
    }
  }
  .el-empty{
    margin: 0 auto;
  }
}
</style>