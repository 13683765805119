<!--
 * @Description: 表单容器配置
 * @Author: luocheng
 * @Date: 2021-10-15 15:12:22
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-08 17:38:11
-->
<template>
  <div class="form-container-config" v-if="curComponent">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <article class="contents">
      <!-- 表单内容配置 -->
      <ContentConfig :type="curComponent.component"></ContentConfig>
      <template v-if="curComponent.component !== 'CommonPageContainer'">
        <!-- 来源&&参数 -->
        <RelationSource></RelationSource>
        <!-- 操作配置 -->
        <ActionConfig v-if="curComponent.component !== 'ConsoleForm'"></ActionConfig>
        <!-- 更新配置 -->
        <UpdateRules></UpdateRules>
        <!-- 中控台的操作配置表单需要引用组件内的事件 -->
        <ActionItem v-if="curComponent.component === 'ConsoleForm'"></ActionItem>
      </template>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import ContentConfig from './ContentConfig';
import ContentConfig from '@/components/dialogConfig/ContentConfig';
import RelationSource from '@/components/dialogConfig/RelationSource';
import ActionConfig from '@/components/dialogConfig/ActionConfig';
import ActionItem from '@/components/listConfig/ActionConfig.vue'
import UpdateRules from './UpdateRules';

export default {
  name: 'FormContainerConfig',
  components: {
    ContentConfig,
    ActionConfig,
		ActionItem,
    RelationSource,
    UpdateRules
  },
  computed: {
    ...mapState(['curComponent'])
  }
}
</script>

<style lang="less" scoped>
.form-container-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .title{
    text-align: left;
		margin-bottom: 10px;
  }
  .contents{
    box-sizing: border-box;
    padding-right: 5px;
    width: 100%;
    overflow-y: auto;
  }
}
</style>