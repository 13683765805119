<!--
 * @Description: 选择数据对象
 * @Author: luocheng
 * @Date: 2021-09-29 18:51:47
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-12 15:02:18
-->
<template>
	<div class="select-view-uuid">
		<el-select
			v-model="uuid"
			filterable
			remote
			:placeholder="placeholder"
			:remote-method="onRemoteView"
			:loading="viewLoading"
			@change="onEmit"
			clearable
		>
			<el-option v-for="item in viewList" :key="item.id" :label="item.name" :value="item.uuid"></el-option>
			<template slot="prefix">
				<MetaTraceIcon method="ShowViewForm" :uuid="uuid" style="line-height: 40px" />
			</template>
		</el-select>
	</div>
</template>

<script>
import { getViews } from '@/apis/data/index';
import MetaTraceIcon from '@/components/metaSelect/MetaTraceIcon.vue';

export default {
	name: 'SelectViewUUID',
	components: {
		MetaTraceIcon
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		// 说明
		placeholder: {
			type: String,
			required: false,
			default: '请选择'
		},
		// 对象UUID
		objectUUID: {
			type: String,
			required: true,
			default: ''
		}
	},
	data() {
		return {
			uuid: '',
			viewLoading: false,
			viewList: []
		};
	},
	created() {
		this.uuid = this.value || '';
		this.onRemoteView('');
	},
	watch: {
		objectUUID() {
			this.onRemoteView('');
		}
	},
	methods: {
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteView(name) {
			this.viewLoading = true;
			getViews({
				object_uuid: this.objectUUID,
				name,
				size: 200
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
					}
					this.viewLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.viewLoading = false;
				});
		},
		/**
		 * @desc: 提交数据
		 */
		onEmit() {
			this.$emit('input', this.uuid);
			this.$emit('update', this.uuid);
		}
	}
};
</script>

<style></style>
