<!--
 * @Description: 配置状态
 * @Author: luocheng
 * @Date: 2022-06-14 14:02:08
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 13:56:18
-->
<template>
  <div class="config-state">
    <el-form :model="configForm" ref="configForm" label-width="100px" :rules="rules">
      <el-form-item label="状态类型" prop="type">
        <el-radio-group v-model="configForm.type">
          <el-radio :label="1">页面状态</el-radio>
          <el-radio :label="2">全局状态</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态标识" prop="code">
        <el-input v-model="configForm.code" placeholder="请输入状态标识" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态值类型" prop="valueType">
        <el-select v-model="configForm.valueType" placeholder="请选择状态值类型" clearable>
          <el-option v-for="item in valueTypes" :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使用默认值">
        <el-switch v-model="configForm.useDefault"></el-switch>
      </el-form-item>
      <el-form-item label="默认值">
        <el-input-number v-model="configForm.defaultValue" placeholder="请输入默认值" clearable v-if="configForm.valueType === 'number'"></el-input-number>
        <el-input v-model="configForm.defaultValue" placeholder="请输入默认值" clearable v-else></el-input>
      </el-form-item>
    </el-form>
    <footer class="actions">
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button type="" @click="onCancel">取消</el-button>
    </footer>
  </div>
</template>

<script>
import { InputNumber } from 'element-ui';

export default {
  name: 'ConfigState',
  components: {
    'el-input-number': InputNumber
  },
  props: {
    defaultData: {
      required: true
    }
  },
  data() {
    return {
      configForm: {
        type: 1, // 1 页面状态， 2 全局状态
        code: '', // 状态code
        valueType: 'string', // 值类型， 数字：number， 字符串： string， 判断值： boolean， 时间日期：date ，复杂对象： object，数组：array
        useDefault: false,
        defaultValue: '', // 默认值
      },
      // 取值类型列表
      valueTypes: [
        {
          label: '数字',
          value: 'number'
        },
        {
          label: '字符串',
          value: 'string'
        },
        // {
        //   label: '判断值',
        //   value: 'boolean'
        // },
        // {
        //   label: '时间日期',
        //   value: 'date'
        // },
        // {
        //   label: '复杂对象',
        //   value: 'object'
        // },
        // {
        //   label: '数组列表',
        //   value: 'array'
        // }
      ],
      rules: {
        type: [{ required: true, message: '请选择状态类型', trigger: 'change' }],
        code: [{ required: true, message: '请输入状态标识', trigger: 'blur' }],
        valueType: [{ required: true, message: '请选择值类型', trigger: 'change' }]
      }
    }
  },
  created() {
    this.configForm = this.defaultData ? {
      ...this.defaultData
    } : {
      type: 1,
      code: '',
      valueType: 'string',
      useDefault: false,
      defaultValue: ''
    }
  },
  methods: {
    /**
     * @desc: 提交
     */
    onSubmit() {
      this.$refs.configForm.validate(valid => {
        if (!valid) return;
        this.$emit('update', this.configForm);
      });
    },
    /**
     * @desc: 取消
     */
    onCancel() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="less" scoped>
.config-state{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  .el-form{
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    .el-form-item{
      // display: flex;
      .el-input__inner{
        width: 300px;
      }
      .el-select, .el-input{
        width: 244px;
      }
      .el-input-number {
        width: 244px;
      }
    }
  }
  .actions{
    border-top: 1px solid #f2f3f5;
    padding: 20px 0;
    display: flex;
    .el-button{
      flex: 1;
    }
  }
}
</style>