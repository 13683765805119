<!--
 * @Description: 字表字段配置
 * @Author: luocheng
 * @Date: 2021-09-30 16:13:39
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-10-24 17:57:57
-->
<template>
	<div class="field-config">
		<header class="header">
      <span class="line"></span>
			<p class="item-title">布局配置</p>
			<el-button type="warning" @click="onResetField" size="mini">重置</el-button>
			<el-button type="primary" @click="onConfirm" size="mini">确认配置</el-button>
			<a
				href="javascript:;"
				class="header-btn"
				@click="toggleShow = !toggleShow"
			>{{ toggleShow ? "收起" : "展开" }}</a>
		</header>
		<div class="configs" v-show="toggleShow">
      <p v-if="fieldList.length === 0">请完成配置字段后使用</p>
      <template v-else>
        <el-form
					label-position="top"
				>	
					<el-form-item v-if="editorType !== 'mobile'" label="请求数条数:">
						<el-input class="w-full" v-model="config.pageSize" placeholder="请输入列表每次请求数据数量" />
					</el-form-item>
					<el-form-item label="列表类型">
						<el-select class="w-full" popper-class="layout-select" @change="handleTypeChange" v-model="config.type">
							<el-option v-for="(item, index) in typeOptions" :key="index" :value="item.value" :label="item.label">
								<p> {{ item.label }}</p>
								<img v-if="item.img === 'img1'" width="300px" height="auto" src="./img/img1.png">
								<img v-if="item.img === 'img2'" width="300px" height="auto" src="./img/img2.png">
								<img v-if="item.img === 'img3'" width="300px" height="auto" src="./img/img3.png">
								<img v-if="item.img === 'img4'" width="300px" height="auto" src="./img/img4.png">
							</el-option>
						</el-select>
					</el-form-item>
					<template v-if="['3rowTextAndImg', '2rowTextAndImg'].includes(config.type)">
						<el-form-item label="图片:">
							<el-select class="w-full" v-model="config.img" clearable placeholder="不选择则不显示">
								<el-option v-for="item in imageConfigList" :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
							</el-select>
						</el-form-item>
					</template>
          <el-form-item label="标题:">
            <el-select class="w-full" v-model="config.title" clearable placeholder="不选择则不显示">
              <el-option v-for="item in titleConfigList" :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
            </el-select>
          </el-form-item>
					<template v-if="editorType === 'mobile' && config.title">
						<el-form-item label="标题前缀:">
							<el-input class="w-full" v-model="config.titlePrefix" placeholder="标题前缀，无则不显示"></el-input>
						</el-form-item>
						<el-form-item label="标题后缀:">
							<el-input class="w-full" v-model="config.titleSuffix" placeholder="标题后缀，无则不显示"></el-input>
						</el-form-item>
					</template>
          <el-form-item v-if="editorType === 'mobile'" label="文本:">
            <el-select class="w-full" v-model="config.text" :multiple-limit="['3rowTextAndImg', '3rowText'].includes(config.type) ? 2 : 1" multiple placeholder="不选择则不显示">
              <el-option v-for="item in textConfigList" :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
            </el-select>
          </el-form-item>
					<template v-if="editorType === 'mobile' && ['2rowTextAndImg', '2rowText'].includes(config.type)">
						<el-form-item label="单行组合文本（优先级高于文本）:">
							<template v-for="(item, index) in config.lineText">
								<p :key="'input' + index" class="line-text-line">
									<span class="input-area">
										<el-select v-if="item.type === 'fild'" v-model="item.value" clearable placeholder="请选择">
											<el-option v-for="item in textConfigList" :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
										</el-select>
										<el-input v-else-if="item.type === 'input'" v-model="item.value" placeholder="请输入"></el-input>
									</span>
									<el-button type="danger" class="delete-action" @click.stop="handlerRemoveLineTextItem(index)">
										<i class="iconfont iconc-trash-can" />
									</el-button>
								</p>
							</template>
							<div style="margin-top: 5px">
								<el-button type="primary" @click.stop="config.lineText.push({ type: 'fild', value: ''})">添加字段</el-button>
								<el-button type="primary" @click.stop="config.lineText.push({ type: 'input', value: ''})">添加自定义文字</el-button>
							</div>
						</el-form-item>
					</template>
          <el-form-item v-if="editorType === 'mobile'" label="状态:">
            <el-select class="w-full" v-model="config.status" clearable placeholder="不选择则不显示">
              <el-option v-for="item in statusConfigList" :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="editorType !== 'mobile' && curComponent.component !== 'ConsoleList'" label="状态:">
            <el-select class="w-full" v-model="config.filterKey" clearable placeholder="用于顶部路由组件筛选数据">
              <el-option v-for="item in textConfigList" :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
            </el-select>
          </el-form-item>
					<el-form-item v-if="curComponent.component === 'ConsoleList'" label="选中背景色:">
            <el-color-picker
							v-model="config.activeCol"
							show-alpha
						>
						</el-color-picker>
          </el-form-item>

        </el-form>
      </template>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ColorPicker } from "element-ui";

export default {
	name: 'LayoutConfig',
	components: {
			"el-color-picker": ColorPicker,
	},
	data() {
		return {
			toggleShow: false,
			// 配置
			config: {
				type: '',
        img: '',
        title: '',
				titlePrefix: '',
				titleSuffix: '',
        text: [],
				lineText: [],
        status: '',
				activeCol:'',//背景色
				pageSize:15
      },
      fieldList: [],
			typeOptions: [
				{ label: '文本', value: '3rowText', img: 'img1' },
				{ label: '文本+图片', value: '3rowTextAndImg', img: 'img2' }
			]
			// 字段类型
		};
	},
	mounted() {
		if (this.editorType === 'mobile') {
			this.typeOptions = [
				{ label: '三行:文本', value: '3rowText', img: 'img1' },
				{ label: '三行:文本+图片', value: '3rowTextAndImg', img: 'img2' },
				{ label: '两行:文本', value: '2rowText', img: 'img3' },
				{ label: '两行:文本+图片', value: '2rowTextAndImg', img: 'img4' }
			]
		}
	},
	computed: {
		...mapState(['curComponent', 'editorType']),
    imageConfigList() {
      return this.fieldList.filter(el => el.type === 2 && el.show)
    },
    titleConfigList() {
      return this.fieldList.filter(el => el.type === 1 && el.show)
    },
    textConfigList() {
      return this.fieldList.filter(el => el.type !== 2 && el.show)
    },
    statusConfigList() {
      return this.fieldList.filter(el => el.type === 3 && el.show)
    }
	},
	watch: {
		curComponent: {
			handler(n) {
				this.config = {
					type: '',
					img: '',
					title: '',
					text: [],
					pageSize:15
        };
        if(n.layoutConfig && Object.keys(n.layoutConfig).length > 0) {
          for (const key in n.layoutConfig) {
            if (Object.hasOwnProperty.call(n.layoutConfig, key)) {
							if(Array.isArray(n.layoutConfig[key])) {
								this.config[key] = JSON.parse(JSON.stringify(n.layoutConfig[key]))
							} else {
								this.config[key] = n.layoutConfig[key]
							}
            }
          }
        }
        if (
					n.fieldConfig &&
					n.fieldConfig.length
				) {
          this.fieldList = n.fieldConfig.map(el => ({...el}))
        }
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		/**
		 * @desc: 重置字段
		 */
		onResetField() {
			this.$confirm('是否确认重置字段?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.initField();
					this.$message.success('重置字段成功!');
				})
				.catch(() => { });
		},
		/**
		 * @desc: 初始化字段
		 * pref: 保持已经配置好的字段 (ps: 以当前配置为基础，缺失的移除，新增的追加)
		 * 当前组件更新会重新触发initField
		 */
		initField() {
			// console.log(oldConfigList, '初始化字段,-00000000000', this.configList);
			this.config = {
        type: '',
				img: '',
				title: '',
				titlePrefix: '',
				titleSuffix: '',
				text: [],
				lineText: [],
				status: '',
				pageSize:15
      };
		},
		/**
		 * @desc: 保存
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					layoutConfig: this.config
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc 移除lineText小项
		 */
		handlerRemoveLineTextItem(index) {
			this.config.lineText.splice(index, 1)
		},
		/**
		 * @desc
		 */
		handleTypeChange(v) {
			this.config = {
        type: v,
				img: '',
				title: '',
				titlePrefix: '',
				titleSuffix: '',
				text: [],
				lineText: [],
				status: '',
				pageSize:15
      };
		}
	}
};
</script>

<style lang="less" scoped>
.field-config {
	padding-top: 10px;
	text-align: left;
	.header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		.line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 28px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 28px;
		}
	}
	.configs {
		height: auto;
		.line-text-line{
			display: flex;
			width: 100%;
			.input-area{
				flex-grow: 1;
			}
			.delete-action{
				margin-left: 10px;
				flex-shrink: 0;
				width: 50px;
			}
			& + .line-text-line{
				margin-top: 5px;
			}
		}
	}

	:deep(.el-collapse-item__header)  {
		border: none;
		height: 30px;
	}
	:deep(.el-collapse-item__wrap) {
		border: none;
	}
	:deep(.el-collapse-item__content) {
		padding-bottom: 0px !important;
	}
}
</style>
<style lang="less">
.layout-select {
	.el-select-dropdown__item{
		height: auto !important;
	}
}
</style>