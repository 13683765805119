<!--
 * @Description: 按钮配置
 * @Author: luocheng
 * @Date: 2021-12-07 18:10:22
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 18:48:40
-->
<template>
  <div class="btn-item" v-if="configData">
    <header class="header">
      <p>{{ configData.name }}</p>
      <div class="actions">
        <span class="el-icon-delete remove" @click="onRemove"></span>
        <span class="toggle" @click="showToggle = !showToggle">{{ showToggle ? '收起' : '展开' }}</span>
      </div>
    </header>
    <article class="column-content" :class="[showToggle ? 'toggle' : '']">
      <el-form :model="configData" label-width="100px">
        <el-form-item label="名称">
          <el-input v-model="configData.name" placeholder="请输入名称" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="按钮类型">
          <el-select v-model="configData.type" placeholder="请选择按钮类型" size="mini" clearable>
            <el-option
              v-for="btn in buttonTypes"
              :key="btn.value"
              :label="btn.label"
              :value="btn.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标">
          <el-input v-model="configData.icon" placeholder="请输入图标" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="操作类型">
          <el-select v-model="configData.function" placeholder="请选择操作类型" size="mini" clearable>
            <el-option
              v-for="action in actionTypes"
              :key="action.value"
              :label="action.label"
              :value="action.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <section class="params-box" v-if="configData.function">
          <p class="title">参数</p>
          <!-- 表单 -->
          <template v-if="configData.function === 'showForm' || configData.function === 'deleteData'">
            <section class="item">
              <p class>数据对象：</p>
              <el-select
                v-model="configData.payload.object_uuid"
                filterable
                size="mini"
                remote
                placeholder="请选择数据对象"
                :remote-method="onRemoteObject"
                @change="onObject"
                clearable
              >
                <el-option
                  v-for="obj in objectList"
                  :key="obj.id"
                  :label="obj.name"
                  :value="obj.uuid"
                ></el-option>
              </el-select>
            </section>
            <section class="item" v-if="configData.payload.object_uuid">
              <p class>数据视图：</p>
              <el-select
              size="mini"
                v-model="configData.payload.view_uuid"
                filterable
                placeholder="请选择视图"
                clearable
              >
                <el-option
                  v-for="item in viewList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </section>
            <section class="item">
              <p class>data_id:</p>
              <el-select
                v-model="configData.payload.data_id"
                size="mini"
                filterable
                placeholder="请选择视图"
                clearable
              >
                <el-option
                  v-for="item in fieldList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </section>
          </template>
          <!-- 弹出消息 -->
          <template v-if="configData.function === 'showMessage'">
            <section class="item">
              <p >标题:</p>
              <el-input size="mini" v-model="configData.payload.title" filterable placeholder="请输入标题" clearable></el-input>
            </section>
            <section class="item">
              <p >消息:</p>
              <el-input
              size="mini"
                v-model="configData.payload.message"
                filterable
                placeholder="请输入消息"
                clearable
              ></el-input>
            </section>
          </template>
        </section>
      </el-form>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { buttonTypes } from '@/components/attributes/config';
import { getObjects, getViews } from '@/apis/data/index';

export default {
  name: 'BtnItem',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => { }
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      // name: "导入",
      // type: "warning",
      // icon: "",
      // function: "showMessage",
      // payload: {
      //   title: '提示语',
      //   message: '随便写了个内容'
      // },
      configData: null,
      showToggle: false,
      buttonTypes,
      // 操作类型
      actionTypes: [
        {
          label: '表单弹窗',
          value: 'showForm'
        },
        {
          label: '删除数据',
          value: 'deleteData'
        },
        {
          label: '详情',
          value: 'showMessage'
        }
      ],
      loading: false,
      // 对象列表
      objectList: [],
      viewList: []
    }
  },
  computed: {
    ...mapState(['curComponent']),
    fieldList() {
      return this.curComponent && this.curComponent.templateConfig.fieldList;
    }
  },
  created() {
    this.configData = this.data || null;
    console.log(this.configData, '9999999');
    this.onRemoteObject('',
      this.configData.payload.object_uuid || ''
    );
    if (this.configData.payload.object_uuid) {
      this.getViewList();
    }
  },
  methods: {
    /**
     * @desc: 选择或输入code
     */
    onCode() {
      this.emitData();
    },
    /**
     * @desc: 删除字段
     */
    onRemove() {
      this.$confirm("是否确定移除字段", '提示').then(res => {
        console.log(res);
        this.$emit('removeConfig', {
          key: 'otherButtons',
          index: this.index
        })
      }).catch(() => { });
    },
    /**
     * @desc: 提交编辑数据
     */
    emitData() {
      console.log('提交编辑数据')
    },
    /**
    * @desc: 远程搜索对象
    * @param {String} name 关键字
    */
    onRemoteObject(name = '', code = '') {
      this.loading = true;
      getObjects({
        name, code,
        uuid: code
      })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const data = res.data.data;
            this.objectList = data.data || [];
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.objectList = [];
          this.loading = false;
        });
    },
    /**
     * @desc: 选择对象
     * @param {*}
     * @return {*}
     */
    onObject() {
      this.configData.payload.view_uuid = '';
      if (this.configData.payload.object_uuid) {
        this.getViewList();
      }
    },
    /**
     * @desc: 远程搜索视图
     */
    getViewList() {
      this.loading = true;
      getViews({
        object_uuid: this.configData.payload.object_uuid,
        size: 999,
      })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const data = res.data.data;
            this.viewList = data.data || [];
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  }
}
</script>

<style lang="less" scoped>
.btn-item {
  width: 100%;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
  padding-bottom: 0;
  margin-bottom: 10px;
  .header {
    display: flex;
    height: 28px;
    p {
      flex: 1;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .actions {
      padding-left: 10px;
      span {
        cursor: pointer;
        box-sizing: border-box;
        line-height: 28px;
        padding: 0 5px;
        color: @theme;
        font-size: 16px;
        font-weight: bold;
        &.remove {
          color: @dangerColor;
        }
        &.toggle {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .column-content {
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
    &.toggle {
      height: auto;
    }
  }
  .params-box{
    .title{
      text-align: left;
      text-indent: 60px;
      padding-bottom: 10px;
      color: #606266;
    }
  }
  .item{
    display: flex;
    margin-bottom: 5px;
    box-sizing: border-box;
    padding-left: 60px;
    p{
      width: 100px;
      font-size: 12px;
      color: #606266;
    }
  }
}
</style>