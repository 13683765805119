<!--
 * @Author: zqy
 * @Date: 2022-06-16 18:09:09
 * @LastEditTime: 2022-08-18 11:16:14
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="custom-form-config" v-if="curComponent">
    <el-form ref="form" :rules="rules" :model="formData" label-width="80px" label-position="left">
      <el-form-item label="模式" prop="type">
        <el-radio-group v-model="formData.type" @change="onObjectUUID">
          <el-radio label="add">新增</el-radio>
          <el-radio v-if="curComponent.component === 'ListContainerOne'" label="detail">详情/编辑</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="数据对象" prop="object_uuid">
        <el-select
          v-model="formData.object_uuid"
          filterable
          remote
          :remote-method="onRemoteObject"
          :loading="objLoading"
          @change="onObjectUUID"
        >
          <el-option
            v-for="obj in objectList"
            :key="obj.id"
            :label="obj.name"
            :value="obj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="视图"
        v-if="formData.object_uuid"
        prop="view_uuid"
      >
        <!-- 视图 -->
        <el-select
          v-model="formData.view_uuid"
          filterable
          remote
          :remote-method="onRemoteView"
          :loading="viewLoading"
          @change="emitFormEdit()"
        >
          <el-option
            v-for="view in viewList"
            :key="view.id"
            :label="view.name"
            :value="view.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="表单uuid"
        v-if="formData.object_uuid && formData.type === 'add'"
        prop="createData"
      >
        <!-- 表单对象 -->
        <el-select
          v-model="formData.createData"
          filterable
          remote
          :remote-method="onRemoteForm"
          :loading="formLoading"
          @change="emitFormEdit()"
        >
          <el-option
            v-for="formObj in formList"
            :key="formObj.id"
            :label="formObj.name || '默认'"
            :value="formObj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="开启缓存"
        v-if="formData.type === 'add'"
        prop="editData"
      >
        <el-switch v-model="formData.openCache" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item
        label="详情表单uuid"
        v-if="formData.object_uuid && formData.type === 'detail'"
        prop="detailData"
      >
        <!-- 表单对象 -->
        <el-select
          v-model="formData.detailData"
          filterable
          remote
          :remote-method="onRemoteForm"
          :loading="formLoading"
          @change="emitFormEdit()"
        >
          <el-option
            v-for="formObj in formList"
            :key="formObj.id"
            :label="formObj.name || '默认'"
            :value="formObj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="编辑表单uuid"
        v-if="formData.object_uuid && formData.type === 'detail'"
        prop="editData"
      >
        <!-- 表单对象 -->
        <el-select
          v-model="formData.editData"
          filterable
          remote
          :remote-method="onRemoteForm"
          :loading="formLoading"
          @change="emitFormEdit()"
        >
          <el-option
            v-for="formObj in formList"
            :key="formObj.id"
            :label="formObj.name || '默认'"
            :value="formObj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="关闭编辑"
        v-if="formData.object_uuid && formData.type === 'detail'"
        prop="editData"
      >
        <el-switch v-model="formData.hideEdit"></el-switch>
      </el-form-item>
      <el-form-item
        label="关闭删除"
        v-if="formData.object_uuid && formData.type === 'detail'"
        prop="editData"
      >
        <el-switch v-model="formData.hideDelete"></el-switch>
      </el-form-item>
      <div class="dividing-line" v-if="formData.type === 'detail'">
        <el-form-item label="编辑架构限制">
          <SetArchiLimit v-model="formData.detailLimit.archiLimit"></SetArchiLimit>
        </el-form-item>
        <el-form-item label="编辑权限设置">
          <SetPermissions v-model="formData.detailLimit.permission"></SetPermissions>
        </el-form-item>
        <el-form-item label="删除架构限制">
          <SetArchiLimit v-model="formData.deleteLimit.archiLimit"></SetArchiLimit>
        </el-form-item>
        <el-form-item label="删除权限设置">
          <SetPermissions v-model="formData.deleteLimit.permission"></SetPermissions>
        </el-form-item>
      </div>
      <div class="dividing-line" v-if="formData.type === 'detail'">
        <!-- 依赖值判断操作 -->
        <el-form-item v-if="formData.type === 'detail'" label="启用编辑依赖" label-width="110px">
          <div class="switch-container">
            <el-popover
              placement="top-start"
              width="250"
              trigger="hover"
              content="编辑依赖配置设置的是自定义表单页面内的编辑按钮是否显示"
            >
              <i class="header-icon el-icon-question" slot="reference"></i>
            </el-popover>
            <el-switch v-model="formData.detailShow.useDepend"></el-switch>
          </div>
        </el-form-item>
        <section class="login-rule" v-if="formData.detailShow.useDepend">
          <header class="rules-header">
            <p style="text-align: left;padding-bottom: 10px">依赖条件</p>
            <i :class="[formData.detailShow.expand ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" @click="onToggleRule('edit')"></i>
          </header>
          <DependLogic v-model="formData.detailShow.dependLogic" v-show="formData.detailShow.expand"></DependLogic>
        </section>
      </div>
      <div class="dividing-line" v-if="formData.type === 'detail'">
        <!-- 详情ui逻辑判断 -->
        <div class="dividing-line-title">编辑ui逻辑判断</div>
        <UILogic v-model="formData.detaiUILogic"></UILogic>
      </div>
      <div class="dividing-line" v-if="formData.type === 'detail'">
        <!-- 删除判断操作 -->
        <el-form-item v-if="formData.type === 'detail'" label="启用删除依赖" label-width="110px">
          <div class="switch-container">
            <el-popover
              placement="top-start"
              width="250"
              trigger="hover"
              content="删除依赖配置设置的是自定义表单页面内的删除按钮是否显示"
            >
              <i class="header-icon el-icon-question" slot="reference"></i>
            </el-popover>
            <el-switch v-model="formData.deleteShow.useDepend"></el-switch>
          </div>
        </el-form-item>
        <section class="login-rule" v-if="formData.deleteShow.useDepend">
          <header class="rules-header">
            <p style="text-align: left;padding-bottom: 10px">依赖条件</p>
            <i :class="[formData.deleteShow.expand ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" @click="onToggleRule('delete')"></i>
          </header>
          <DependLogic v-model="formData.deleteShow.dependLogic" v-show="formData.deleteShow.expand"></DependLogic>
        </section>
      </div>
      <div class="dividing-line" v-if="formData.type === 'detail'">
        <!-- 删除ui逻辑判断 -->
        <div class="dividing-line-title">删除ui逻辑判断</div>
        <UILogic v-model="formData.deleteUILogic"></UILogic>
      </div>
      <el-form-item label="功能名称" prop="title">
        <el-input v-model="formData.title" @change="emitFormEdit()"></el-input>
      </el-form-item>
      <el-form-item label="参数设置" prop="params">
        <div
          class="query-item"
          v-for="(queryItem, queryIndex) in formData.params"
          :key="queryIndex"
        >
          <div class="query-item-form">
            <el-input
              style="margin-bottom: 10px;"
              v-model="queryItem.key"
              placeholder="请输入参数名称"
              clearable
              @change="emitFormEdit()"
            ></el-input>
            <el-select v-model="queryItem.queryType" style="margin-bottom: 10px;" clearable placeholder="选择携带参数"  @change="changeQueryType()">
              <el-option v-for="(item) in configList" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
            <el-select v-if="queryItem.queryType === 'query'" style="margin-bottom: 10px;" v-model="queryItem.value" @change="emitFormEdit()">
              <el-option
                v-for="(item) in getQueryStringArgs()"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 其他组件暴露值 -->
            <el-select v-if="queryItem.queryType === 'component'" style="margin-bottom: 10px;" v-model="queryItem.component" placeholder="请选择组件" @change="changeComponent(queryIndex, 1)">
              <el-option v-for="item in targetList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-if="queryItem.queryType === 'component'" style="margin-bottom: 10px;" v-model="queryItem.value" placeholder="请选择组件内部参数" @change="emitFormEdit()">
              <el-option
                v-for="(item) in queryItem.fieldList"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              ></el-option>
            </el-select>
            <!-- 系统参数 -->
            <el-select
              v-if="queryItem.queryType === 'system'"
              v-model="queryItem.systemKey"
              placeholder="请选择系统对象"
              clearable
              style="margin-bottom: 10px;"
              @change="onSystemObject(queryIndex, queryItem.systemKey, 'params')"
            >
              <el-option label="用户信息" value="userInfo"></el-option>
              <el-option label="当前架构" value="targetArchi"></el-option>
            </el-select>
            <el-select v-if="queryItem.queryType === 'system'" v-model="queryItem.systemCode" style="margin-bottom: 10px;" placeholder="请选择取值对象">
              <el-option v-for="(value, key) in queryItem.systemObject" :key="key" :label="key" :value="key"></el-option>
            </el-select>
            <!-- 固定值 -->
            <el-input v-if="queryItem.queryType === 'fixed'" style="margin-bottom: 10px;" v-model="queryItem.value" placeholder="请输入参数值" clearable></el-input>
          </div>
          <section class="action">
            <i class="el-icon-delete" @click="onRemoveQuery(queryIndex)"></i>
          </section>
        </div>
        <el-button
          type="primary"
          @click="onAddQuery()"
        >新增参数</el-button>
      </el-form-item>
      <el-form-item label="表单填充" prop="fillData">
        <div
          class="query-item"
          v-for="(queryItem, queryIndex) in formData.fillData"
          :key="queryIndex"
        >
          <div class="query-item-form">
            <el-input
              style="margin-bottom: 10px;"
              v-model="queryItem.key"
              placeholder="请输入参数名称"
              clearable
              @change="emitFormEdit()"
            ></el-input>
            <el-select v-model="queryItem.queryType" style="margin-bottom: 10px;" clearable placeholder="选择携带参数"  @change="changeQueryType()">
              <el-option v-for="(item) in configList" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
            <!-- query参数 -->
            <el-select v-if="queryItem.queryType === 'query'" style="margin-bottom: 10px;" v-model="queryItem.value" @change="emitFormEdit()">
              <el-option
                v-for="(item) in getQueryStringArgs()"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 其他组件暴露值 -->
            <el-select v-if="queryItem.queryType === 'component'" style="margin-bottom: 10px;" v-model="queryItem.component" placeholder="请选择组件" @change="changeComponent(queryIndex, 2)">
              <el-option v-for="item in targetList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-if="queryItem.queryType === 'component'" style="margin-bottom: 10px;" v-model="queryItem.value" placeholder="请选择组件内部参数" @change="emitFormEdit()">
              <el-option
                v-for="(item) in queryItem.fieldList"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              ></el-option>
            </el-select>
            <!-- 系统参数 -->
            <el-select
              v-if="queryItem.queryType === 'system'"
              v-model="queryItem.systemKey"
              placeholder="请选择系统对象"
              clearable
              style="margin-bottom: 10px;"
              @change="onSystemObject(queryIndex, queryItem.systemKey, 'fillData')"
            >
              <el-option label="用户信息" value="userInfo"></el-option>
              <el-option label="当前架构" value="targetArchi"></el-option>
            </el-select>
            <el-select v-if="queryItem.queryType === 'system'" v-model="queryItem.systemCode" style="margin-bottom: 10px;" placeholder="请选择取值对象">
              <el-option v-for="(value, key) in queryItem.systemObject" :key="key" :label="key" :value="key"></el-option>
            </el-select>
            <!-- 固定值 -->
            <el-input v-if="queryItem.queryType === 'fixed'" style="margin-bottom: 10px;" v-model="queryItem.value" placeholder="请输入参数值" clearable></el-input>
          </div>
          <section class="action">
            <i class="el-icon-delete" @click="onRemoveFillData(queryIndex)"></i>
          </section>
        </div>
        <el-button
          type="primary"
          @click="onAddFillData()"
        >新增参数</el-button>
      </el-form-item>

      <!-- <el-button type="primary" style="width: 100%" @click="confirmConfig">确认事件配置</el-button> -->
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getObjects, getViews, dataInterface } from '@/apis/data/index';
import { Message, Popover } from 'element-ui';
import { flatTree, unique, getComponentById } from '@/utils/tools';
import DependLogic from '@/components/tableConfig/DependLogic';
import UILogic from '@/components/common/UILogic.vue'
import SetArchiLimit from '@/components/common/SetArchiLimit';
import SetPermissions from '@/components/common/SetPermissions'; 

export default {
  components:{
    DependLogic,
    UILogic,
    SetArchiLimit,
    SetPermissions,
    'el-popover': Popover
  },
  props: {
    value: {
      type: Object,
      default() { return {} }
    }
  },
  data(){
    return{
      formData:{
        object_uuid:'',
        view_uuid:'',
        createData:'',
        detailData: '',
        title:'',
        editData: '',
        params: [],
        fillData: [],
        type: '',
        targetList: [],
        openCache: 0,
        hideEdit: false,
        hideDelete: false,
        detailLimit: {
          archiLimit: [],
          permission: [],
        },
        deleteLimit: {
          archiLimit: [],
          permission: [],
        },
        detailShow: {
          useDepend: false,
          expand: false,
          dependLogic: [{
            type: '',
            field: '',
            dataAttr: 'value',
            logicRule: '===',
            valueType: 'string',
            value: '',
            itemLogic: 'and'
          }]
        },
        detaiUILogic: {
          enable: false,
          logicConfigs: []
        },
        deleteShow: {
          useDepend: false,
          expand: false,
          dependLogic: [{
            type: '',
            field: '',
            dataAttr: 'value',
            logicRule: '===',
            valueType: 'string',
            value: '',
            itemLogic: 'and'
          }]
        },
        deleteUILogic: {
          enable: false,
          logicConfigs: []
        },
      },
      configList: [
        {
          name: 'query参数',
          value: 'query'
        },
        {
          name: '其他组件内部参数',
          value: 'component'
        },
        {
          name: '系统参数',
          value: 'system'
        },
        {
          name: '固定值',
          value: 'fixed'
        },
      ], // 字段数组
      objLoading: false,
      viewLoading: false,
      formLoading: false,
      objectList: [],
      viewList: [],
      formList: [],
      rules: {
        type: [{ required: true, message: '不能为空', trigger: 'blur' }],
        object_uuid: [{ required: true, message: '不能为空', trigger: 'blur' }],
        view_uuid: [{ required: true, message: '不能为空', trigger: 'blur' }],
        createData: [{ required: true, message: '不能为空', trigger: 'blur' }],
        detailData: [{ required: true, message: '不能为空', trigger: 'blur' }],
        title: [{ required: true, message: '不能为空', trigger: 'blur' }]
      },
      fieldList: []
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData', // 组件数据
      'sContainer',
      'originComponentData',
    ]),
  },
	watch: {
    value: {
      handler(n) {
        if(n && typeof n  === 'object' ) {
          for (const key in n) {
            if (Object.hasOwnProperty.call(n, key)) {
              this.formData[key] = n[key]
            }
          }
          if (!this.formData.detailShow) {
            this.formData.detailShow = {
              useDepend: false,
              expand: false,
              dependLogic: [{
                type: '',
                field: '',
                dataAttr: 'value',
                logicRule: '===',
                valueType: 'string',
                value: '',
                itemLogic: 'and'
              }]
            }
          }
          if (!this.formData.deleteShow) {
            this.formData.deleteShow = {
              useDepend: false,
              expand: false,
              dependLogic: [{
                type: '',
                field: '',
                dataAttr: 'value',
                logicRule: '===',
                valueType: 'string',
                value: '',
                itemLogic: 'and'
              }]
            }
          }
          if (!this.formData.detaiUILogic) {
            this.formData.detaiUILogic = {
              enable: false,
              logicConfigs: []
            }
          }
          if (!this.formData.deleteUILogic) {
            this.formData.deleteUILogic = {
              enable: false,
              logicConfigs: []
            }
          }
          if (!this.formData.detailLimit) {
            this.formData.detailLimit = {
              archiLimit: [],
              permission: []
            }
          }
          if (!this.formData.deleteLimit) {
            this.formData.deleteLimit = {
              archiLimit: [],
              permission: []
            }
          }
          if (!this.formData.hideEdit) {
            this.formData.hideEdit = false
          }
          if (!this.formData.hideDelete) {
            this.formData.hideDelete = false
          }
        }
        console.log(this.formData)
      },
      immediate: true
    }
	},
  methods:{
    confirmConfig(){
      this.$refs.form.validate(valid => {
        if(valid) {
          Message.success('事件配置已确认')
          this.$emit('input', this.formData)
        }
      })
    },
    emitFormEdit() {
      this.$emit('input', this.formData)
    },
    /**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.objLoading = true;
			getObjects({ name, uuid: !name ? this.formData.object_uuid : '' })
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
					this.objLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.objectList = [];
					this.objLoading = false;
				});
		},
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteView(name) {
			this.viewLoading = true;
			getViews({
				object_uuid: this.formData.object_uuid,
				name,
				size: 200
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
					}
					this.viewLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.viewLoading = false;
				});
		},
    /**
		 * @desc: 获取可用表单对象
		 * @param {String} name <未启用>
		 */
		onRemoteForm(name) {
			this.formLoading = true;
      if (!this.formData.object_uuid) {
        return
      }
			dataInterface({
				object_uuid: this.formData.object_uuid,
				name
			}, 'api/saas/metadata/getFormDesigns', 'GET')
				.then((res) => {
					console.log(res.data.data)
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.formList = data || [];
					}
					this.formLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.formList = [];
					this.formLoading = false;
				});
		},
		/**
		 * @desc: 选择对象
		 */
		onObjectUUID() {
			this.onRemoteView('');
			this.onRemoteForm('')
      this.emitFormEdit()
		},
		/**
		 * @desc: 获取url参数
		 */
    getQueryStringArgs() {
      //取得查询字符串并去掉开头的问号
      var qs = location.search.length > 0 ? location.search.substring(1) : "",
      //保存数据的对象
      args = [],
      //取得每一项
      items = qs.length ? qs.split("&") : [],
      item = null,
      name = null,
      value = null,
      //在 for 循环中使用
      i = 0,
      len = items.length;
      //逐个将每一项添加到 args 对象中
      for (i = 0; i < len; i++) {
        item = items[i].split("=");
        name = decodeURIComponent(item[0]);
        value = decodeURIComponent(item[1]);
        if (name.length) {
          args.push({
            name,
            value
          })
        }
      }
      return args;
    },
    /* 新增参数携带 */
    onAddQuery() {
      this.formData.params.push({
        key: '',
        value: ''
      })
    },
    /* 新增表单填充携带 */
    onAddFillData() {
      this.formData.fillData.push({
        key: '',
        value: ''
      })
    },
    /* 删除 */
    onRemoveQuery(index) {
      this.formData.params.splice(index, 1)
      this.emitFormEdit()
    },
    /* 删除 */
    onRemoveFillData(index) {
      this.formData.fillData.splice(index, 1)
      this.emitFormEdit()
    },
    /* 切换参数携带类型 */
    changeQueryType() {
      this.emitFormEdit()
    },
    /* 携带参数选择组件参数触发 */
    changeComponent(index, type) {
      let originComponent = getComponentById(this.componentData, +type === 1 ? this.formData.params[index].component : this.formData.fillData[index].component);
      if (originComponent.component !== "Calendar") {
        let fieldList = originComponent.database.fieldList
        if (originComponent.component === "CommonTabs") {
          /* tab组件只有一个值可选 */
          fieldList = [
            {
              name: originComponent.metadata[originComponent.dynamicConfig.valueKey],
              uuid: originComponent.dynamicConfig.valueKey
            }
          ]
        }
        if (+type === 1) {
          this.formData.params[index].fieldList = fieldList
        } else {
          this.formData.fillData[index].fieldList = fieldList
        }
        this.emitFormEdit()
      } else {
        const arr = [
          { name: '日期（年月日）', uuid: 'date' },
          { name: '日期（年月日 时分秒）', uuid: 'dateTime' },
          { name: '数量', uuid: 'num' },
          { name: '总数量', uuid: 'total_num' },
          { name: '当天日期范围', uuid: 'dateArr' },
          { name: '星期', uuid: 'weekDay' },
        ]
        if (+type === 1) {
          this.formData.params[index].fieldList = arr
        } else {
          this.formData.fillData[index].fieldList = arr
        }
        this.emitFormEdit()
      }
    },
    /* 依赖条件展开或者收起 */
    onToggleRule(type) {
      if (type === 'edit') {
        this.formData.detailShow.expand = !this.formData.detailShow.expand
        console.log(this.formData.detailShow.expand)
      } else {
        this.formData.deleteShow.expand = !this.formData.deleteShow.expand
      }
    },
		/**
		 * @desc: 选择系统对象
		 */
		onSystemObject(index, systemKey, type) {
			let systemObject = {};
			if (systemKey === 'userInfo') {
				systemObject = this.$GetUserInfo();
			} else if (systemKey === 'targetArchi') {
				systemObject = this.$GetTargetArchi();
			}
			this.formData[type].splice(index, 1, {
				...this.formData[type][index],
				systemCode: '',
				systemObject
			});
		},
  },
  created() {
    this.onRemoteObject('')
		if (this.formData.object_uuid) {
			this.onObjectUUID();
		}
    let result = [];
    if (this.sContainer) {
      result = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
    }
    this.targetList = unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id'
      }, [])))
    if (this.curComponent.component !== 'ListContainerOne') {
      this.formData.type = 'add'
    }
  }
}
</script>
<style lang="less" scoped>
.custom-form-config{
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
  .query-item {
    display: flex;
    align-items: center;
    .query-item-form {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-right: 10px;
    }
    .action {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
			font-size: 16px;
			color: @dangerColor;
			font-weight: bold;
    }
  }
  .dividing-line {
    margin: 15px 0;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid rgb(201, 194, 194);
    .dividing-line-title {
      line-height: 24px;
    }
    .switch-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .login-rule{
      border: 1px solid #f2f3f5;
      box-sizing: border-box;
      width: 98%;
      margin-bottom: 10px;
      padding: 10px 10px 0 10px;
      border-radius: 6px;
    }
    .rules-header{
      display: flex;
      p{
        flex: 1;
        text-indent: 20px;
        line-height: 22px;
      }
      i{
        font-weight: bold;
        color: @theme;
        font-size: 16px;
        padding: 0 5px;
      }
    }
  }
}
</style>