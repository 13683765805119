<!--
 * @Description: 数据来源填写
 * @Author: luocheng
 * @Date: 2021-10-27 10:19:51
 * @LastEditors: zx
 * @LastEditTime: 2022-11-09 11:13:52
-->
<template>
  <div class="source-item">
    <el-form :model="configForm" label-width="100px">
      <el-form-item label="类型">
        <el-select v-model="configForm.type"  @change="onEmit">
          <el-option label="新增" value="createData"></el-option>
          <el-option label="编辑" value="updateData"></el-option>
          <el-option label="详情" value="dataInfo"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="字段名">
        <el-input v-model="configForm.key" @input="onEmit"></el-input>
      </el-form-item>
      <el-form-item label="校验必须">
        <el-switch v-model="configForm.isRequired" @change="onEmit"></el-switch>
      </el-form-item>
      <el-form-item label="值来源">
        <el-select v-model="configForm.originType"  @change="onEmit" placeholder="请选择值来源">
          <el-option label="来源组件" value=""></el-option>
          <el-option label="URL参数" value="url"></el-option>
          <el-option label="页面状态" value="pageStatus"></el-option>
          <el-option label="系统参数" value="system"></el-option>
          <el-option label="固定值" value="fixed"></el-option>
        </el-select>
      </el-form-item>
      <!-- 来源组件 -->
      <template v-if="!configForm.originType">
        <el-form-item label="关联组件(绑定值)">
          <el-select
            v-model="configForm.componentId"
            placeholder="请选择关联组件"
            @change="onComponent"
          >
            <el-option
              v-for="component in componentList"
              :key="component.value"
              :label="component.label"
              :value="component.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="关联字段"
          v-if="configForm.componentId && fieldList"
        >
          <el-select v-model="configForm.field" placeholder="请选择关联字段"  @change="onEmit">
            <el-option
              v-for="(item, key) in fieldList"
              :key="item"
              :label="item + '【' + key + '】'"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- URL参数 -->
      <template v-else-if="configForm.originType === 'url'">
        <el-form-item label="url参数key">
          <el-input v-model="configForm.urlParamKey" placeholder="请输入url参数key" @input="onEmit" clearable></el-input>
        </el-form-item>
      </template>
      <!-- 页面状态 -->
      <template v-else-if="configForm.originType === 'pageStatus'">
        <el-form-item label="状态字段">
          <el-select v-model="configForm.statusCode" placeholder="请选择状态字段">
            <el-option v-for="statusItem in statusConfig"
              :key="statusItem.code"
              :value="statusItem.code"
              :label="statusItem.code + '【' + (statusItem.type === 1 ? '页面' : '全局') + '状态】'"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- 系统参数 -->
      <template v-if="configForm.originType === 'system'">
        <el-form-item label="系统对象">
          <el-select v-model="configForm.systemKey" placeholder="请选择系统对象" clearable
            @change="onSystemObject(configForm.systemKey)">
            <el-option label="用户信息" value="userInfo"></el-option>
            <el-option label="当前架构" value="targetArchi"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取值Code" v-if="configForm.systemKey && systemObject">
          <el-select v-model="configForm.systemCode" placeholder="请选择取值对象" @change="onEmit">
            <el-option v-for="(value, key) in systemObject"
              :key="key"
              :label="key"
              :value="key"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- 固定值 -->
      <template v-else-if="configForm.originType === 'fixed'">
        <el-form-item label="参数值">
          <el-input v-model="configForm.fixedValue" placeholder="请输入参数值" clearable @input="onEmit"></el-input>
        </el-form-item>
      </template>
    </el-form>
    <i class="el-icon-delete remove" @click="onRemove"></i>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { flatTree, getComponentById, unique } from "@/utils/tools";

export default {
  name: 'SourceItem',
  props: {
    // 绑定值
    config: {
      type: Object,
      default: () => {
        return {
          type: '',
          key: '',
          componentId: '',
          field: ''
        }
      },
      required: true
    },
    // 下标
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      // 配置
      configForm: {
        type: '',
        key: '',
        componentId: '',
        field: '',
        isRequired: false,
        systemKey: '',
        systemCode: '',
        fixedValue: ''
      },
      // 字段列表
      fieldList: null,
      // 系统对象
      systemObject: null
    }
  },
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
      'originComponentData',
      'sContainer',
      'statusConfig'
    ]),
    // 可选组件列表
    componentList() {
      const result =  flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id',
      }, []);
			return unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id',
      }, [], [])))
    },
  },
  created() {
    this.configForm = {
      type: '',
      key: '',
      componentId: '',
      field: '',
      isRequired: false,
      systemKey: '',
      systemCode: '',
      fixedValue: '',
      ...this.config
    }
    if (this.configForm.componentId) {
      this.onComponent(this.configForm.componentId, -1)
    }
  },
  methods: {
    /**
     * @desc: 选择组件后清空字段并重新获取字段列表
     */
    onComponent(val, clearField) {
      if (clearField !== -1) {
        this.configForm.field = "";
      }
      this.fieldList = null;
      const component = getComponentById(this.componentData, val);
      if (component.component === 'CommonTabs') {
        this.fieldList = {
          label: '说明',
          value: '值'
        };
      } else {
        this.fieldList = component && component.metadata;
      }
      this.onEmit();
    },
    /**
		 * @desc: 选择系统对象
		 */
		onSystemObject(systemKey) {
      this.systemObject = null;
			if (systemKey === 'userInfo') {
				this.systemObject = this.$GetUserInfo()
			} else if (systemKey === 'targetArchi') {
				this.systemObject = this.$GetTargetArchi()
			}
      this.onEmit();
		},
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config:  this.configForm
      });
    },
    /**
     * @desc: 删除
     */
    onRemove() {
      this.$confirm('是否确认移除配置？').then(() => {
        this.$emit('removeSource', this.index)
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.source-item {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f2f3f7;
  margin-bottom: 5px;
  :deep(.el-form) {
    flex: 1;
    overflow: hidden;
    .el-form-item { 
      margin-bottom: 10px;
    }
  }
  .remove {
    line-height: 150px;
    color: @dangerColor;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>