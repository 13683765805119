<!--
 * @Description: 表格操作栏事件绑定
 * @Author: luocheng
 * @Date: 2021-09-14 10:13:27
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:32:20
-->
<template>
	<div class="gis-events">
		<section class="part">
			<article class="list" v-if="actionList && Object.keys(actionList).length">
				<section class="item" v-for="(btn, key) in actionList" :key="key">
					<el-row>
						<el-col :span="8">名称：</el-col>
						<el-col :span="16">
              <span style="font-weight: 600;color: #333;">{{ btn.label }}</span>
            </el-col>
					</el-row>
					<el-form class="event-list" v-if="btn.eventList && btn.eventList.length">
						<el-row v-for="(value, index) in btn.eventList" :key="index" class="event-box">
							<el-form-item label="事件类型:" label-width="120px">
								<h3 class="title">{{ value.key | filterEvent }}</h3>
							</el-form-item>
							<!-- 复杂类型 -->
							<template v-if="value.pattern === 'special'">
								<section v-for="(item, index) in value.specialEventList" :key="index">
									<template v-if="item.actionType === 'eeAction'">
										<!-- 后端事件 -->
										<el-form-item label="操作:" label-width="120px">
											<div class="tag-list">
												<el-tag>后端事件--【{{ item.eventName }}】</el-tag>
											</div>
										</el-form-item>
									</template>
									<template v-else-if="item.actionType === 'pageAction'">
											<div v-for="(effect, eIndex) in item.effects" :key="eIndex">
												<el-form-item label="作用组件:" label-width="120px">
													<el-select v-model="effect.targets" placeholder="请选择作用组件" multiple disabled>
														<el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
												<el-form-item label="操作:" label-width="120px">
													<div class="tag-list">
														<el-tag>{{ effect.actions | filterActions }}</el-tag>
													</div>
												</el-form-item>
											</div>
									</template>
								</section>
							</template>
							<!-- 普通及常规类型 -->
							<template v-else>
								<template v-if="value.actionType === 'eeAction'">
									<!-- 后端事件 -->
									<el-form-item label="操作:" label-width="120px">
										<div class="tag-list">
											<el-tag>后端事件--【{{ value.eventName }}】</el-tag>
										</div>
									</el-form-item>
								</template>
								<template v-else-if="value.actionType === 'pageAction'">
										<div v-for="(effect, eIndex) in value.effects" :key="eIndex">
											<el-form-item label="作用组件:" label-width="120px">
												<el-select v-model="effect.targets" placeholder="请选择作用组件" multiple disabled>
													<el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
													</el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="操作:" label-width="120px">
												<div class="tag-list">
													<el-tag>{{ effect.actions | filterActions }}</el-tag>
												</div>
											</el-form-item>
										</div>
								</template>
							</template>
						</el-row>
					</el-form>
					<el-row>
						<el-button type="success" size="mini" @click="onShowSelect(btn)">绑定事件</el-button>
					</el-row>
				</section>
			</article>
			<p v-else>暂未配置操作按钮</p>
		</section>
		<!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'tableEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
	</div>
</template>

<script>
import { defaultList, actionList } from '../../config';
import { mapState } from 'vuex';
import SelectEvent from '../../SelectEvent';
import { flatTree } from '@/utils/tools';
import { Tag } from 'element-ui';

export default {
	name: 'GisEvents',
	components: {
		'el-tag': Tag,
		SelectEvent
	},
	data() {
		return {
			// 显示配置抽屉
			showSelect: false,
			// 当前配置的按钮
			partData: null
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前(表格组件数据)
			'componentData' // 图层数据
		]),
		// 表格绑定的操作栏
		actionList() {
			return this.curComponent?.actionConfig || {};
		}
	},
	filters: {
		// 事件名称
		filterEvent(value) {
			for (let i = 0; i < defaultList.length; i++) {
				if (defaultList[i].value === value) {
					return defaultList[i].label;
				}
			}
			return value;
		},
		// 操作
		filterActions(value) {
			let result = [];
			for (let i = 0; i < value.length; i++) {
				const item = actionList.find((ele) => ele.value === value[i]);
				if (item) {
					result.push(item.label);
				}
			}
			return result.join();
		}
	},
	created() {
		this.targetList = flatTree(
			this.componentData,
			'children',
			{
				label: 'name',
				value: 'id'
			},
			[]
		);
	},
	methods: {
		/**
		 * @desc: 显示配置事件表单
		 * @param {Object} btnData 按钮配置数据
		 */
		onShowSelect(btnData) {
			if (!btnData) return;
			this.partData = btnData;
			this.showSelect = true;
		}
	}
};
</script>

<style lang="less" scoped>
.gis-events{
	.header-title{
		position: relative;
    text-align: left;
    padding-bottom: 10px;
		box-sizing: border-box;
		padding-left: 10px;
		&:before{
			position: absolute;
			content: '';
			height: 14px;
			width: 3px;
			left: 0px;
			top: 5px;
			border-radius: 1px;
			background: @theme;
		}
  }
	.event-box{
		border-bottom: 1px solid @borderColor;
		&:last-of-type{
			border: none;
		}
	}
}	
</style>
