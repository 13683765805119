<!--
 * @Description: 筛选项配置
 * @Author: luocheng
 * @Date: 2021-10-11 09:26:45
 * @LastEditors: zx
 * @LastEditTime: 2022-12-07 09:05:42
-->
<template>
  <div class="item-config">
    <div class="content">
      <SelectObject @removeObject="onRemove"></SelectObject>
      <article class="field-list" v-if="fieldList && fieldList.length">
        <VueDraggable v-model="fieldList" @start="onStart" @end="onEnd">
          <div
            :model="item"
            class="field-item"
            v-for="item in fieldList"
            :key="item.id"
            label-width="0"
          >
            <!-- 虚拟字段不允许筛选 -->
            <!-- && item.is_search && item.type !== 'append' -->
            <template v-if="item">
              <FieldConfig v-model="item.viewConfig"></FieldConfig>
            </template>
          </div>
        </VueDraggable>
      </article>
      <el-button type="primary" @click="onConfirm" size="mini">确认配置</el-button>
    </div>
  </div>
</template>

<script>
import SelectObject from "@/components/bindValue/database/SelectObject";
import FieldConfig from "./FieldConfig";
import { mapState } from "vuex";
import VueDraggable from 'vuedraggable';
import eventBus from '@/plugins/eventBus';

export default {
  name: "ItemConfig",
  components: {
    SelectObject,
    FieldConfig,
    VueDraggable
  },
  data() {
    return {
      isToggle: false,
      // 字段类型 跟随后端配置 未使用，仅做说明参考
      fieldTypes: [
        { name: "整数", value: "integer" },
        { name: "小数", value: "decimal" },
        { name: "日期时间", value: "datetime" },
        { name: "时间戳", value: "timestamp" },
        { name: "字符串", value: "varchar" },
        { name: "长字符串", value: "text" },
        { name: "超长字符串", value: "longtext" },
        { name: "虚拟字段", value: "append" },
      ],
      // 字段类表
      fieldList: []
    };
  },
  computed: {
    ...mapState(["curComponent"])
  },
  watch: {
    curComponent: {
      handler() {
        this.fieldList = (this.curComponent &&
          this.curComponent.database &&
          this.curComponent.database.fieldList) ||
        [];
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 确认配置
     */
    onConfirm() {
      const component = {
        ...this.curComponent,
        database: {
          ...this.curComponent.database,
          fieldList: this.fieldList
        }
      }
      this.$store.commit("modifyComponent", {
        component,
        containerId: null,
        isModify: true,
      });
      eventBus.$emit('databaseTrigger', {
        componentId: this.curComponent.id,
        action: 'any',
        output: '',
        isInit: false,
        noUpdate: false
      });
      this.$message.success('保存成功!');
    },
    /**
     * @desc: 移除
     */
    onRemove() {
      this.fieldList = [];
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          fieldList: [],
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 拖拽开始
     */
    onStart() {
      // console.log('拖拽开始');
    },
    /**
     * @desc: 拖拽结束
     */
    onEnd() {
      // console.log('拖拽结束')
    },
  },
};
</script>

<style lang="less" scoped>
.item-config {
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;
  .field-list {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto;
    padding-top: 10px;
  }
  .action {
    display: flex;
    padding: 10px 0;
    .config-title {
      flex: 1;
      text-align: left;
      line-height: 28px;
    }
    .toggle {
      font-size: 14px;
      margin-left: 10px;
      line-height: 28px;
      color: @theme;
    }
  }
  .content {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto;
  }
}
</style>