<!--
 * @Description: excel配置
 * @Author: luocheng
 * @Date: 2022-04-21 16:46:43
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 15:56:13
-->
<template>
  <div class="sheet-container-config">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item title="基础配置" name="1">
        <BaseConfig></BaseConfig>
      </el-collapse-item>
      <el-collapse-item title="数据仓库" name="2">
        <DatabaseConfig></DatabaseConfig>
      </el-collapse-item>
      <el-collapse-item title="数据来源" name="3">
        <DataSource></DataSource>
      </el-collapse-item>
      <el-collapse-item title="操作配置" name="4">
        <ActionConfig></ActionConfig>
      </el-collapse-item>
      <el-collapse-item title="更新规则" name="5">
        <UpdateRules></UpdateRules>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseConfig from './BaseConfig';
import DatabaseConfig from './DatabaseConfig';
import DataSource from './DataSource';
import ActionConfig from './ActionConfig';
import UpdateRules from './UpdateRules';

export default {
  name: 'SheetContainerConfig',
  components: {
    BaseConfig,
    DatabaseConfig,
    DataSource,
    ActionConfig,
    UpdateRules
  },
  data() {
    return {
      activeNames: ''
    }
  },
  computed: {
    ...mapState(['curComponent'])
  }
}
</script>

<style lang="less" scoped>
.sheet-container-config{
  height: 100%;
  width: 100%;
  .title{
    padding-bottom: 10px;
    text-align: left;
  }
}
</style>