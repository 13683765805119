<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2021-09-30 11:46:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-12-05 14:14:16
-->
<template>
  <div class="action-config">
		<FeildConfig	></FeildConfig>
    <header class="header">
      <span class="line"></span>
      <p class="item-title">其他配置</p>
      <el-button type="warning" size="mini" @click="onReset">重置</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确认配置</el-button>
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
    </header>
    <div class="configs" v-show="toggleShow">
      <el-form :model="ganttConfig">
				<el-form-item label="名称字段" label-width="85px">
					<el-select v-model="ganttConfig.textField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="前锋线字段" label-width="85px">
					<el-select v-model="ganttConfig.frontLineField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="计划开始" label-width="85px">
					<el-select v-model="ganttConfig.startDateField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="计划结束" label-width="85px">
					<el-select v-model="ganttConfig.endDateField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
        <el-form-item label="计划颜色" label-width="85px">
					<el-select v-model="ganttConfig.taskColField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
        <el-form-item label="GIS字段" label-width="85px">
					<el-select v-model="ganttConfig.gisField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
        <el-form-item label="BIM字段" label-width="85px">
					<el-select v-model="ganttConfig.bimField" placeholder="请选择">
						<el-option v-for="(item,key) in mateData" :key="key"  :value="key">{{item}}--{{key}}</el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="选择任务" label-width="85px">
					<el-switch	v-model="ganttConfig.checkbox"></el-switch>
				</el-form-item>
      </el-form>
    </div>
		<ActionConfig />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FeildConfig from '@/components/tableConfig/FeildConfig.vue'
import ActionConfig from '@/components/tableConfig/ActionConfig.vue'
export default {
  name: 'ganttConfig',
	components: {
		FeildConfig,
		ActionConfig
	},
  data() {
    return {
      toggleShow: false,
      ganttConfig: {
				textField:'',//名称显示字段
				frontLineField:'',//前锋线fielduuid字段判断
				startDateField:'',//计划开始时间fielduuid
				endDateField:'',//计划结束时间fielduuid
				checkbox:false,//显示多选框
        bimField:'',//绑定的bim模型fielduuid
        gisField:'',//绑定的gis模型fielduuid
        taskColField:'',//绑定的任务颜色fielduuid
			},
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ]),
		mateData(){
			return this.curComponent.metadata || []
		}

  },
  created() {
		console.log(this.curComponent)
    this.ganttConfig = this.curComponent && this.curComponent.ganttConfig;
  },
  methods: {
		onReset(){
			this.ganttConfig = {
				textField:'',//名称显示字段
				frontLineField:'',//前锋线fielduuid字段判断
				startDateField:'',//计划开始时间fielduuid
				endDateField:'',//计划结束时间fielduuid
				checkbox:false,//显示多选框
        bimField:'',//绑定的bim模型fielduuid
        gisField:'',//绑定的gis模型fielduuid
        taskColField:'',//绑定的任务颜色fielduuid
			}
		},
		/**
		 * @desc: 保存
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					ganttConfig: this.ganttConfig
				},
				containerId: null,
				isModify: true
			});
		},
    
  }
}
</script>

<style lang="less" scoped>
.action-config{
  padding: 10px 0;
  .header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
    .item-title{
      flex: 1;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
    }
    .header-btn{
      margin-left: 5px;
      color: @theme;
      line-height: 28px;
    }
  }
  .configs{
    padding-top: 20px;
    .el-form{
      .el-form-item{
        margin-bottom: 10px;
      }
    }
  }
}
</style>