<!--
 * @Description: 操作
 * @Author: luocheng
 * @Date: 2022-04-22 13:55:13
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-05-10 17:42:16
-->
<template>
  <div class="action-item">
    <el-form :model="actionConfig" label-width="100px">
    <el-form-item label="按钮名称">
        <el-input
          v-model="actionConfig.text"
          clearable
          placeholder="请输入按钮名称"
          @input="onEmit"
        ></el-input>
      </el-form-item>
      <el-form-item label="按钮类型">
        <el-select
          v-model="actionConfig.buttonType"
          placeholder="请选择按钮类型"
          @change="onEmit"
        >
          <el-option
            v-for="item in buttonTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-switch
          v-model="actionConfig.show"
          @change="onEmit"
          >
        </el-switch>
      </el-form-item>
      <el-form-item label="附加作用">
        <el-switch
          v-model="actionConfig.effect"
          clearable
        ></el-switch>
      </el-form-item>
      <template v-if="actionConfig.effect">
        <el-form-item label="附作用类型">
          <el-select
            v-model="actionConfig.effectType"
            placeholder="请选择附加作用类型"
            @change="onEmit"
          >
            <el-option
              label="发送请求"
              value="postData"
            ></el-option>
            <el-option
              label="后端事件"
              value="eeAction"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 发送请求 -->
        <template v-if="actionConfig.effectType === 'postData'">
          <el-form-item label="请求类型">
            <el-select
              v-model="actionConfig.postType"
              placeholder="请选择请求类型"
              @change="onEmit"
            >
              <el-option
                label="新增"
                value="createData"
              ></el-option>
              <el-option
                label="编辑"
                value="updateData"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定对象">
            <SelectObjectUUID
              v-model="actionConfig.objectUUID"
              :placeholder="'请选择对象'"
              @change="onEmit"
            >
            </SelectObjectUUID>
          </el-form-item>
          <el-form-item
            label="绑定视图"
            v-if="actionConfig.objectUUID"
          >
            <SelectViewUUID
              v-model="actionConfig.viewUUID"
              :objectUUID="actionConfig.objectUUID"
              :placeholder="'请选择视图'"
              @change="onEmit"
            >
            </SelectViewUUID>
          </el-form-item>
        </template>
        <!-- 后端事件 -->
        <template v-else-if="actionConfig.effectType === 'eeAction'">
          <el-form-item label="绑定对象">
            <SelectObjectUUID
              v-model="actionConfig.objectUUID"
              :placeholder="'请选择对象'"
              @change="onEmit"
            >
            </SelectObjectUUID>
          </el-form-item>
          <el-form-item
            label="绑定视图"
            v-if="actionConfig.objectUUID"
          >
            <SelectViewUUID
              v-model="actionConfig.viewUUID"
              :objectUUID="actionConfig.objectUUID"
              :placeholder="'请选择视图'"
              @change="onEmit"
            >
            </SelectViewUUID>
          </el-form-item>
            
          <el-form-item label="事件名称" v-if="actionConfig.objectUUID">
            <SelectEEEvent
              v-model="actionConfig.eventName"
              :objectUUID="actionConfig.objectUUID"
              :placeholder="'请选择事件'"
              @change="onEmit"
            >
            </SelectEEEvent>
          </el-form-item>
        </template>
      </template>
    </el-form>
    <section class="actions">
      <i
        class="el-icon-remove remove"
        @click="onRemove(index)"
        v-if="actionConfig.canCustom"
      ></i>
    </section>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import SelectEEEvent from '@/components/common/SelectEEEvent';
import { buttonTypes } from '@/components/attributes/config';

export default {
  name: 'ActionItem',
  components: {
    SelectObjectUUID,
    SelectViewUUID,
    SelectEEEvent
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      actionConfig: {},
      buttonTypes
    }
  },
  created() {
    this.actionConfig = {
      ...this.config
    }
  },
  methods: {
    /**
     * @desc: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.actionConfig
      })
    }
  }
}
</script>

<style lang="less" scoped>
.action-item{
  width: 100%;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #f2f3f5;
  padding: 10px 5px;
  border-radius: 6px;
  margin-bottom: 10px;
  .el-form-item{
    margin-bottom: 10px;
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
  .actions{
    cursor: pointer;
    padding-left: 10px;
    line-height: 100px;
    font-weight: bold;
    color: @dangerColor;
    font-size: 16px;
  }
}
</style>