/*
 * @Description: 组件配置
 * @Author: luocheng
 * @Date: 2021-09-03 17:37:14
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-01-10 10:41:35
 */
import { elementIcons } from '@/utils/icons';
// 按钮的类型
export const buttonTypes = [
  {
    label: '默认按钮',
    value: ''
  },
  {
    label: '主要按钮',
    value: 'primary'
  },
  {
    label: '成功按钮',
    value: 'success'
  },
  {
    label: '信息按钮',
    value: 'info'
  },
  {
    label: '警告按钮',
    value: 'warning'
  },
  {
    label: '危险按钮',
    value: 'danger'
  },
  {
    label: '文本按钮',
    value: 'text'
  }
]

const button = {
  type: {
    label: '类型',
    type: 'select',
    options: buttonTypes
  }, // primary / success / warning / danger / info / text
  size: {
    label: '尺寸',
    type: 'select',
    options: [
      {
        label: '默认',
        value: ''
      },
      {
        label: '中等按钮',
        value: 'medium'
      },
      {
        label: '小型按钮',
        value: 'small'
      },
      {
        label: '超小按钮',
        value: 'mini'
      }
    ]
  }, // medium / small / mini
  plain: {
    label: '朴素按钮',
    type: 'switch'
  },
  round: {
    label: '圆角按钮',
    type: 'switch'
  },
  circle: {
    label: '圆形按钮',
    type: 'switch'
  },
  loading: {
    label: '加载中状态',
    type: 'switch'
  },
  disabled: {
    label: '禁用状态',
    type: 'switch'
  },
  icon: {
    label: '图标类名',
    type: 'icon',
    options: elementIcons
  },
  autofocus: {
    label: '是否默认聚焦',
    type: 'switch'
  },
  // needTime: {
  //   label: '选择时间点',
  //   type: 'switch'
  // },
  // dateFormat: {
  //   label: '显示值类型',
  //   type: 'select',
  //   options: []
  // },
  // valueFormat: 'yyyy-MM-dd'
};

const commonConfig = {
  displayShow: {
    label: '虚拟显示',
    tips: '是否隐藏该组件。该组件所产生的数据将始终可用。',
    type: 'switch'
  },
  isShow: {
    label: '是否显示',
    tips: '是否挂载该组件，若为否则不会挂载，该组件所产生的数据将不可用。',
    type: 'switch'
  },
  signType: {
    label: '标记来源',
    tips: '决定该标记容器显示的是BIM标记还是GIS标记。',
    type: 'select',
    options: [
      { label: 'BIM标记', value: 'bim' },
      { label: 'GIS标记', value: 'gis' }
    ],
  },
  isContrast: {
    label: '全景对比',
    tips: '开启则为全景对比，全景对比需绑定数据对象。',
    type: 'switch'
  },

};

const table = {
  loading: {
    label: '加载',
    type: 'switch'
  },
  idUUID: {
    label: 'id的UUID',
    type: 'string'
  },
  rowKey: {
    label: '行key',
    type: 'string'
  },
  height: {
    label: '高度',
    type: 'string'
  },
  maxHeight: {
    label: '最大高度',
    type: 'string'
  },
  stripe: {
    label: '斑马纹',
    type: 'switch'
  },
  border: {
    label: '纵向边框',
    type: 'switch'
  },
  size: {
    label: '尺寸',
    type: 'select',
    options: [
      {
        label: '默认',
        value: ''
      },
      {
        label: '中等',
        value: 'medium'
      },
      {
        label: '小型',
        value: 'small'
      },
      {
        label: '超小',
        value: 'mini'
      }
    ]
  }, // medium / small / mini
  fit: {
    label: '自动宽度',
    type: 'switch'
  },
  showHeader: {
    label: '显示表头',
    type: 'switch'
  },
  highlightCurrentRow: {
    label: '高亮当前行',
    type: 'switch'
  },
  rowShowDetails: {
    label: '点击行显示详情',
    type: 'switch'
  },
  emptyText: {
    label: '空数据',
    type: 'string'
  },
  action: {
    label: '操作栏',
    type: 'tableAction'
  },
  align: {
    label: '对齐方式',
    type: 'select',
    options: [
      {
        label: '居左',
        value: 'left'
      },
      {
        label: '居中',
        value: 'center'
      },
      {
        label: '居右',
        value: 'right'
      }
    ],
    'header-align': {
      label: '表头对齐方式',
      type: 'select',
      options: [
        {
          label: '默认(与表格一致)',
          value: ''
        },
        {
          label: '居左',
          value: 'left'
        },
        {
          label: '居中',
          value: 'center'
        },
        {
          label: '居右',
          value: 'right'
        }
      ]
    }
  },
  useStyle: {
    label: '使用样式回调',
    type: 'switch'
  },
  canSelect: {
    label: '可选择',
    type: 'switch'
  },
  showOrder: {
    label: '显示顺序',
    type: 'switch'
  },
  pagination: {
    label: '分页',
    type: 'switch'
  },
  defaultPerPage: {
    label: '默认每页条数',
    type: 'number'
  },
  customPagination: {
    label: '自定义分页',
    type: 'switch'
  },
  perList: {
    label: '条数列表',
    type: 'string'
  },
  isTreeTable: {
    label: '树形表格',
    type: 'switch'
  },
  children: {
    label: '下级字段',
    type: 'string'
  },
  defaultExpandAll: {
    label: '默认展开下级',
    type: 'switch'
  },
  isNesting: {
    label: '嵌套表格',
    type: 'switch'
  },
  nestingKey: {
    label: '子表字段',
    type: 'string'
  },
  nestingRowKey: {
    label: '子表行KeyUUID',
    type: 'string'
  },
  nestingObjectUUID: {
    label: '子表数据对象',
    type: 'selectObjectUUID'
  },
  useSort: {
    label: '前端排序',
    tips: '多个排序字段，最终将根据字段顺序进行排序',
    type: 'switch'
  },
  useFeWBS: {
    label: '前端WBS',
    type: 'switch'
  },
  orderWidth: {
    label: '序号列宽度',
    type: 'number'
  },
  feTreeKey: {
    label: '前端树形',
    tips: '指定前端树形结构的parentId字段的UUID，当填写后将进行前端树形结构序列化',
    type: 'string'
  },
  // fixColumn: {
  //   label: '固定列',
  //   tips: '支持数字(从1开始)和F（First第一列）、T（Todo，操作列），以逗号(英文逗号)分隔开。大于0的数字时候，如4，则会固定 1 ~ 4 列。',
  //   type: 'string'
  // }
};

const pagination = {
  small: {
    label: '小型分页样式',
    type: 'switch'
  },
  background: {
    label: '按钮背景色',
    type: 'switch'
  },
  'pager-count': {
    label: '页码数量',
    type: 'number'
  },
  layout: {
    label: '组件布局',
    type: 'string'
  },
  'prev-text': {
    label: '上一页文字',
    type: 'string'
  },
  'next-text': {
    label: '下一页文字',
    type: 'string'
  },
  disabled: {
    label: '禁用',
    type: 'switch'
  },
  'hide-on-single-page': {
    label: '只有一页时隐藏',
    type: 'switch'
  }
};

const tree = {
  label: {
    label: '说明字段',
    type: 'string'
  },
  showSearch: {
    label: '搜索框',
    type: 'switch'
  },
  placeholder: {
    label: '搜索提示',
    type: 'string'
  },
  nodeKey: {
    label: '唯一标识',
    type: 'string'
  },
  children: {
    label: '子节点字段',
    type: 'string'
  },
  disabled: {
    label: '禁用',
    type: 'switch'
  },
  lazy: {
    label: '懒加载',
    type: 'switch'
  },
  emptyText: {
    label: '空数据说明',
    type: 'string'
  },
  hideActions: {
    label: '隐藏操作',
    type: 'switch'
  },
  appendChild: {
    label: '添加子集',
    type: 'switch'
  },
  defaultExpandAll: {
    label: '默认展开全部',
    type: 'switch'
  },
  showCheckBox: {
    label: '使用选择框',
    type: 'switch'
  },
  showCheckAll: {
    label: '显示全选',
    type: 'switch'
  },
  defaultSelectAll: {
    label: '默认全选',
    type: 'switch'
  },
  checkAllText: {
    label: '全选文本',
    type: 'string'
  }
};

const dialog = {
  title: {
    label: '标题',
    type: 'string'
  },
  width: {
    label: '宽度',
    type: 'string'
  },
  showConfirm: {
    label: '显示确认按钮',
    type: 'switch'
  },
  confirmText: {
    label: '确认按钮文字',
    type: 'string'
  },
  confirmType: {
    label: '确认按钮类型',
    type: 'select',
    options: buttonTypes
  },
  showCancel: {
    label: '显示取消按钮',
    type: 'switch'
  },
  cancelText: {
    label: '取消按钮文字',
    type: 'string'
  },
  cancelType: {
    label: '取消按钮类型',
    type: 'select',
    options: buttonTypes
  },
  showClose: {
    label: '显示关闭',
    type: 'switch'
  },
  center: {
    label: '居中',
    type: 'switch'
  },
  fullscreen: {
    label: '全屏',
    type: 'switch'
  },
  firstType: {
    label: '默认类型',
    type: 'select',
    options: [
      {
        label: '抽屉',
        value: 'drawer'
      },
      {
        label: '弹窗',
        value: 'dialog'
      }
    ]
  },
  drawerWidth: {
    label: '抽屉宽度',
    type: 'string'
  },
  top: {
    label: '距顶距离',
    type: 'string'
  },
  modal: {
    label: '显示遮罩',
    type: 'switch'
  },
  closeOnClickModal: {
    label: '点击遮罩关闭',
    type: 'switch'
  },
  closeOnPressEscape: {
    label: 'ESC关闭',
    type: 'switch'
  },
  showExcel: {
    label: '允许显示excel',
    type: 'switch'
  },
  preferredType: {
    label: '渲染模式',
    type: 'select',
    options: [
      {
        label: '双重(首选表单)',
        value: 'form'
      },
      {
        label: '双重(首选Excel)',
        value: 'excel'
      },
      {
        label: '仅表单',
        value: 'onlyForm'
      },
      {
        label: '仅Excel',
        value: 'onlyExcel'
      }
    ]
  },
  useCache: {
    label: '数据缓存',
    type: 'switch'
  },
  createWithDetails: {
    label: '带填充值新增',
    type: 'switch'
  }
};

const tabs = {
  type: {
    label: '类型',
    type: 'select',
    options: [
      {
        label: '基本类型',
        value: ''
      },
      {
        label: '选项卡',
        value: 'card'
      },
      {
        label: '卡片化',
        value: 'border-card'
      },
      {
        label: '块状',
        value: 'block'
      },
      {
        label: '主色按钮块状',
        value: 'primary-block'
      }
    ]
  },
  tabPosition: {
    label: '位置',
    type: 'select',
    options: [
      {
        label: '顶部',
        value: 'top'
      },
      {
        label: '底部',
        value: 'bottom'
      },
      {
        label: '左侧',
        value: 'left'
      },
      {
        label: '右侧',
        value: 'right'
      }
    ]
  },
  useSolt: {
    label: '启用SOLT',
    type: 'switch',
    tips: '启用SOLT能自定义默认颜色及活动颜色'
  },
  defaultColor: {
    label: '默认颜色',
    type: 'color'
  },
  activeColor: {
    label: '活动颜色',
    type: 'color'
  }
}

const screenTabs = {
  defaultText: {
    label: '默认文本',
    type: 'string'
  },
  activeText: {
    label: '活动文本',
    type: 'string'
  },
  defaultColor: {
    label: '默认颜色',
    type: 'color'
  },
  activeColor: {
    label: '活动颜色',
    type: 'color'
  },
  defaultImage: {
    label: '默认图片',
    type: 'string'
  },
  activeImage: {
    label: '活动图片',
    type: 'string'
  },
  objectFit: {
    label: '填充方式',
    type: 'select',
    options: [
      {
        label: '剪切填充',
        value: 'cover'
      },
      {
        label: '填充',
        value: 'fill'
      },
      {
        label: '比例缩放',
        value: 'contain'
      },
      {
        label: '固定尺寸',
        value: 'none'
      },
      {
        label: '自适应(小尺寸优先)',
        value: 'scale-down'
      }
    ]
  },
  fontSize: {
    label: '字体大小',
    type: 'string'
  }
}
const swiper = {
  height: {
    label: '高度',
    type: 'number'
  },
  initialIndex: {
    label: '初始索引',
    type: 'number'
  },
  trigger: {
    label: '指示器触发方式',
    type: 'select',
    options: [
      {
        label: '默认',
        value: ''
      },
      {
        label: '点击',
        value: 'click'
      }
    ]
  },
  autoplay: {
    label: '自动播放',
    type: 'switch'
  },
  interval: {
    label: '切换间隔(ms)',
    type: 'number'
  },
  indicatorPosition: {
    label: '指示器位置',
    type: 'select',
    options: [
      {
        label: '默认',
        value: ''
      },
      {
        label: '外部',
        value: 'outside'
      },
      {
        label: '不显示',
        value: 'none'
      }
    ]
  },
  arrow: {
    label: '切换显示时机',
    type: 'select',
    options: [
      {
        label: '鼠标滑过',
        value: 'hover'
      },
      {
        label: '总是显示',
        value: 'always'
      },
      {
        label: '从不显示',
        value: 'never'
      },
    ]
  },
  type: {
    label: '类型',
    type: 'select',
    options: [
      {
        label: '默认',
        value: ''
      },
      {
        label: '卡片',
        value: 'card'
      }
    ]
  },
  loop: {
    label: '循环显示',
    type: 'switch'
  },
  direction: {
    label: '展示方向',
    type: 'select',
    options: [
      {
        label: '水平方向',
        value: 'horizontal'
      },
      {
        label: '垂直方向',
        value: 'vertical'
      }
    ]
  },
  showText: {
    label: '显示描述文本',
    type: 'switch'
  },
  clickLink: {
    label: '点击跳转功能',
    type: 'switch'
  }
};
const MobileSwiper = {
  height: {
    label: '高度',
    type: 'number'
  },
  initialIndex: {
    label: '初始索引',
    type: 'number'
  },
  // trigger: {
  //   label: '指示器触发方式',
  //   type: 'select',
  //   options: [
  //     {
  //       label: '默认',
  //       value: ''
  //     },
  //     {
  //       label: '点击',
  //       value: 'click'
  //     }
  //   ]
  // },
  // autoplay: {
  //   label: '自动播放',
  //   type: 'switch'
  // },
  interval: {
    label: '切换间隔(ms)',
    type: 'number'
  },
  // 移动端自定义指示器
  // indicatorPosition: {
  //   label: '指示器位置',
  //   type: 'select',
  //   options: [
  //     {
  //       label: '默认',
  //       value: ''
  //     },
  //     {
  //       label: '外部',
  //       value: 'outside'
  //     },
  //     {
  //       label: '不显示',
  //       value: 'none'
  //     }
  //   ]
  // },
  // arrow: {
  //   label: '切换显示时机',
  //   type: 'select',
  //   options: [
  //     {
  //       label: '鼠标滑过',
  //       value: 'hover'
  //     },
  //     {
  //       label: '总是显示',
  //       value: 'always'
  //     },
  //     {
  //       label: '从不显示',
  //       value: 'never'
  //     },
  //   ]
  // },
  // type: {
  //   label: '类型',
  //   type: 'select',
  //   options: [
  //     {
  //       label: '默认',
  //       value: ''
  //     },
  //     {
  //       label: '卡片',
  //       value: 'card'
  //     }
  //   ]
  // },
  loop: {
    label: '循环显示',
    type: 'switch'
  },
  vertical: {
    label: '纵向滚动',
    type: 'switch'
  },
  // direction: {
  //   label: '展示方向',
  //   type: 'select',
  //   options: [
  //     {
  //       label: '水平方向',
  //       value: 'horizontal'
  //     },
  //     {
  //       label: '垂直方向',
  //       value: 'vertical'
  //     }
  //   ]
  // },
  showText: {
    label: '显示描述文本',
    type: 'switch'
  },
  showIndicators: {
    label: '显示指示器',
    type: 'switch'
  },
  clickLink: {
    label: '点击跳转功能',
    type: 'switch'
  },
  touchable: {
    label: '通过手势滑动',
    type: 'switch'
  },
  indicatorColor: {
    label: '指示器颜色',
    type: 'color'
  },
  descColor: {
    label: '描述文字颜色',
    type: 'color'
  },
};

const selectPerson = {
  filter: {
    label: '搜索框',
    type: 'switch'
  },
  filterText: {
    label: '搜索提示',
    type: 'string'
  },
  label: {
    label: '说明文字',
    type: 'string'
  },
  key: {
    label: '唯一标识',
    type: 'string'
  },
  children: {
    label: '子节点字段',
    type: 'string'
  }
};

const CommonFormContainer = {
  showAction: {
    type: 'switch',
    label: '显示操作'
  }
}

const ConsoleForm = {
  showAction: {
    type: 'switch',
    label: '显示操作'
  }
}

const CommonSheetContainer = {
  showAction: {
    type: 'switch',
    label: '显示操作'
  }
}

const FormOnlyOne = {
  AutoCreate: {
    type: 'switch',
    label: '自动创建'
  }
}

const CommonDrawer = {
  direction: {
    type: 'select',
    label: '打开的方向',
    options: [
      {
        label: '从右往左',
        value: 'rtl'
      },
      {
        label: '从左往右',
        value: 'ltr'
      },
      {
        label: '从上往下',
        value: 'ttb'
      },
      {
        label: '从下往上',
        value: 'btt'
      }
    ]
  },
  modal: {
    label: '遮罩层',
    type: 'switch'
  },
  size: {
    label: '尺寸',
    type: 'string'
  },
  withHeader: {
    label: 'header栏,',
    type: 'switch'
  },
  title: {
    label: '标题',
    type: 'string'
  },
  showClose: {
    label: '显示关闭',
    type: 'switch'
  },
  closeOnPressEscape: {
    label: 'Esc关闭',
    type: 'switch'
  },
  wrapperClosable: {
    label: '遮罩层关闭',
    type: 'switch'
  }
};

const CommonSelect = {
  showLabel: {
    label: '显示描述',
    type: 'switch'
  },
  label: {
    label: '描述文本',
    type: 'string'
  },
  labelWidth: {
    label: '描述宽度',
    type: 'string'
  },
  labelPosition: {
    label: '文本位置',
    type: 'select',
    options: [
      {
        label: '居左',
        value: 'left'
      },
      {
        label: '居右',
        value: 'right'
      },
      {
        label: '居中',
        value: 'center'
      }
    ]
  },
  selectSize: {
    label: '尺寸',
    type: 'select',
    options: [
      {
        label: '常规',
        value: 'medium'
      },
      {
        label: '小型',
        value: 'small'
      },
      {
        label: '超小',
        value: 'mini'
      }
    ]
  },
  optionLabel: {
    label: '选项描述字段',
    type: 'string'
  },
  optionValue: {
    label: '选项值字段',
    type: 'string'
  },
  optionValueUUID: {
    label: '值UUID(多ID必填)',
    type: 'string'
  },
  defaultSelect: {
    label: '默认选中首个',
    type: 'switch'
  },
  needAll: {
    label: '添加全部选项',
    type: 'switch'
  },
  placeholder: {
    label: '提示文本',
    type: 'string'
  },
  clearable: {
    label: '一键清空',
    type: 'switch'
  },
  filterable: {
    label: '可筛选',
    type: 'switch'
  },
  // multiple: {
  //   label: '可多选',
  //   type: 'switch'
  // },
  disabled: {
    label: '禁用',
    type: 'switch'
  },
  allowCreate: {
    label: '允许创建',
    type: 'switch'
  },
  noDataText: {
    label: '无选项提示',
    type: 'string'
  },
  prefixIcon: {
    label: '头部icon',
    type: 'string'
  },
  transparent: {
    label: '透明背景',
    type: 'switch'
  },
  hideBorder: {
    label: '隐藏边框',
    type: 'switch'
  },
  hideIcon: {
    label: '影藏图标',
    type: 'switch'
  },
  selectWidth: {
    label: '选择框宽度',
    type: 'string'
  },
  selectHeight: {
    label: '选择框高度',
    type: 'string'
  }
}

const CommonCalendar = {
  type: {
    label: '日历类型',
    type: 'select',
    options: [
      {
        label: '日期',
        value: 'date'
      },
      {
        label: '月份',
        value: 'month'
      },
      {
        label: '年',
        value: 'year'
      },
      {
        label: '周(基于月)',
        value: 'monthWeek'
      },
      {
        label: '周(基于年)',
        value: 'monthYear'
      }
    ]
  },
  calendarType: {
    label: '数据类型',
    type: 'select',
    options: [
      {
        label: '基础类型',
        value: 'base'
      },
      {
        label: '仅总数',
        value: 'justTotal'
      },
      {
        label: '复合数据型',
        value: 'withDatabase'
      }
    ]
  },
  headerFormat: {
    label: '标志类型',
    type: 'select',
    tips: '日期类型有效',
    options: [
      {
        label: '周日在后',
        value: 'sundayEnd'
      },
      {
        label: '周日在前',
        value: 'sundayStart'
      }
    ]
  },
  // valueFormat: {
  //   label: '取值类型',
  //   type: 'select',
  //   tips: '标准类型取值为：每种类型自带的特殊取值。日期：年月日, 月份：年月，年份：年，周(基于月/基于年)：第N周',
  //   options: [
  //     {
  //       label: '标准类型(年月日时分秒)',
  //       value: 'YMDhms'
  //     },
  //     {
  //       label: '标准类型取值',
  //       value: 'standard'
  //     }
  //   ]
  // },
  startYear: {
    label: '起始年份',
    type: 'string'
  }
}

const CommonNetDisk = {
  partName: {
    label: '模块名称',
    type: 'string'
  },
  newFolder: {
    label: '创建文件夹',
    type: 'switch'
  }, // 新建文件夹
  canUpload: {
    label: '上传文件',
    type: 'switch'
  }, // 上传文件
  pushFile: {
    label: '添加文件(功能网盘)',
    type: 'switch'
  }, // 添加文件(从功能网盘)
  showSearch: {
    label: '使用搜索',
    type: 'switch'
  },
  changeModel: {
    label: '切换显示模式',
    type: 'switch'
  }, // 切换显示模式
  showActions: {
    label: '显示操作',
    type: 'switch'
  }, // 显示操作
  showInfoBtn: {
    label: '显示详情按钮',
    type: 'switch'
  }, // 显示详情按钮
  showModifyBtn: {
    label: '显示编辑按钮',
    type: 'switch'
  }, // 显示编辑按钮
  showShareBtn: {
    label: '显示分享按钮',
    type: 'switch'
  }, // 显示分享按钮
  showDownloadBtn: {
    label: '显示下载按钮',
    type: 'switch'
  }, // 显示下载按钮
  showMoveBtn: {
    label: '显示移动按钮',
    type: 'switch'
  }, // 显示详情按钮
  showRemoveBtn: {
    label: '显示删除按钮',
    type: 'switch'
  }, //
  multiple: {
    label: '多文件上传',
    type: 'switch'
  },
  batchAction: {
    label: '批量操作',
    type: 'switch'
  }
}

const CommonForm = {
  showButton: {
    label: '显示操作',
    type: 'switch'
  },
  quickSearch: {
    label: '开启快捷搜索',
    type: 'switch'
  }
}

const CommonDateFilter = {
  valueFormat: {
    label: '值类型',
    type: 'select',
    options: [
      {
        label: '年月日(补零)',
        value: 'yyyy-MM-dd'
      },
      {
        label: '年月日(无补零)',
        value: 'yyyy-M-d'
      },
      {
        label: '年月日时分(补零)',
        value: 'yyyy-MM-dd HH:mm'
      },
      {
        label: '年月日时分',
        value: 'yyyy-M-d H:m'
      },
      {
        label: '年月日时分秒(补零)',
        value: 'yyyy-MM-dd HH:mm:ss'
      },
      {
        label: '年月日时分秒',
        value: 'yyyy-M-d H:m:s'
      },
      {
        label: '时间戳',
        value: 'timestamp'
      }
    ]
  },
  monthDay: {
    label: '每月天数',
    type: 'number'
  },
  transparent: {
    label: '透明背景',
    type: 'switch'
  },
  hideBorder: {
    label: '隐藏边框',
    type: 'switch'
  },
  hideIcon: {
    label: '影藏图标',
    type: 'switch'
  },
  selectWidth: {
    label: '选择框宽度',
    type: 'string'
  },
  selectHeight: {
    label: '选择框高度',
    type: 'string'
  }
}

const CommonBaseDate = {
  type: {
    label: '类型',
    type: 'select',
    options: [
      {
        label: '日期',
        value: 'date'
      },
      {
        label: '时间段',
        value: 'daterange'
      },
      {
        label: '月份',
        value: 'month'
      },
      {
        label: '月份段',
        value: 'monthrange'
      }
    ]
  },
  valueFormat: {
    label: '值类型',
    type: 'select',
    options: [
      {
        label: '年月日(补零)',
        value: 'yyyy-MM-dd'
      },
      {
        label: '年月日(无补零)',
        value: 'yyyy-M-d'
      },
      {
        label: '年月日时分(补零)',
        value: 'yyyy-MM-dd HH:mm'
      },
      {
        label: '年月日时分',
        value: 'yyyy-M-d H:m'
      },
      {
        label: '年月日时分秒(补零)',
        value: 'yyyy-MM-dd HH:mm:ss'
      },
      {
        label: '年月日时分秒',
        value: 'yyyy-M-d H:m:s'
      },
      {
        label: '年月(补零)',
        value: 'yyyy-MM'
      },
      {
        label: '年月',
        value: 'yyyy-M'
      },
      {
        label: '无需格式化',
        value: '[MM]'
      },
      {
        label: '时间戳',
        value: 'timestamp'
      }
    ]
  },
  shortcuts: {
    label: '快捷键(日/月段)',
    type: 'multiple',
    options: [
      {
        label: '本月',
        value: 'targetMonth'
      },
      {
        label: '今年至今',
        value: 'targetYear'
      },
      {
        label: '近三个月',
        value: 'threeMonth'
      },
      {
        label: '近六个月',
        value: 'sixMonth'
      },
      {
        label: '近一年',
        value: 'oneYear'
      }
    ]
  },
  transparent: {
    label: '透明背景',
    type: 'switch'
  },
  hideBorder: {
    label: '隐藏边框',
    type: 'switch'
  },
  hideIcon: {
    label: '影藏图标',
    type: 'switch'
  },
  selectWidth: {
    label: '选择框宽度',
    type: 'string'
  },
  selectHeight: {
    label: '选择框高度',
    type: 'string'
  }
}

const CommonTitle = {
  level: {
    label: '标题层级',
    type: 'select',
    options: [
      {
        label: '一级标题',
        value: 1
      },
      {
        label: '二级标题',
        value: 2
      },
      {
        label: '三级标题',
        value: 3
      },
      {
        label: '四级标题',
        value: 4
      },
      {
        label: '五级标题',
        value: 5
      },
      {
        label: '六级标题',
        value: 6
      }
    ]
  }
}

const CommonStatTableOne = {
  planKey: {
    label: '计划key',
    type: 'string'
  },
  actualKey: {
    label: '实际key',
    type: 'string'
  },
  fixedColumn: {
    label: '固定头尾',
    type: 'switch'
  },
  showSelect: {
    label: '可选择',
    type: 'switch'
  }
}

const decoration = {
  mainColor: {
    label: '主色',
    tips: '装饰的主要颜色，需与副色配合使用！',
    type: 'color'
  },
  secondaryColor: {
    label: '副色',
    tips: '装饰的次要要颜色，需与主色配合使用！',
    type: 'color'
  },
  dur: {
    label: '动画时长',
    type: 'number'
  },
  reverse: {
    label: '反转',
    tips: '改变装饰方向',
    type: 'switch'
  },
  textContent: {
    label: '嵌入文本',
    tips: '装饰内部嵌入文本',
    type: 'string'
  },
  scanDur: {
    label: '扫描动画时长',
    type: 'number'
  },
  haloDur: {
    label: '光晕动画时长',
    type: 'number'
  },
  title: {
    label: '边框标题',
    type: 'string'
  },
  titleWidth: {
    label: '标题宽度',
    type: 'number'
  },
  textColor: {
    label: '字体颜色',
    type: 'color'
  },
  textSize: {
    label: '字体大小',
    type: 'string'
  },
  textWeight: {
    label: '字重大小',
    type: 'string'
  },
  blur: {
    label: '背景模糊度',
    type: 'number'
  },
  backgroundColor: {
    label: '背景颜色',
    type: 'color'
  }
}

const currency = {
  // 通用
  fontSize: {
    type: 'string',
    label: '字体大小'
  },
  textAlign: {
    label: '对齐方式',
    type: 'select',
    options: [
      {
        label: '左对齐',
        value: 'left'
      },
      {
        label: '居中',
        value: 'center'
      },
      {
        label: '右对齐',
        value: 'right'
      }
    ]
  },
  lineHeight: {
    label: '行高',
    type: 'string'
  },
  bold: {
    type: 'switch',
    label: '是否加粗'
  },
  italic: {
    type: 'switch',
    label: '是否斜体'
  },
  fontFamily: {
    label: '字体（已废弃）',
    type: 'select',
    tips: '已废止该配置，选中将不再生效',
    options: [
      {
        value: "DIN Next LT Pro",
        label: "DIN"
      },
      {
        value: "Helvetica",
        label: "Helvetica"
      },
      {
        value: "cursive",
        label: "思源黑体"
      },
      {
        value: "'Linux Biolinum', 'Noto Serif SC', serif",
        label: "思源宋体"
      }
    ]
  },
  // 字体省略
  showEffect: {
    label: '显示效果',
    type: 'select',
    options: [
      {
        label: '默认',
        value: ''
      },
      {
        label: '超出隐藏',
        value: 'hidden'
      },
      {
        label: '单行超出隐藏(省略号)',
        value: 'ellipsis'
      },
      {
        label: '多行超出隐藏(省略号)',
        value: 'multilineEllipsis'
      }
    ]
  },
  // 补零
  zeroFilling: {
    type: 'switch',
    label: '是否补零'
  },
  // 时间格式化
  timeFormat: {
    label: '字体',
    type: 'select',
    options: [
      {
        value: "YYYY-MM-DD",
        label: "YYYY-MM-DD"
      },
      {
        value: "YYYY年MM月DD日",
        label: "YYYY年MM月DD日"
      },
      {
        value: "YYYY/MM/DD",
        label: "YYYY/MM/DD"
      },
      {
        value: "custom",
        label: "使用自定义显示格式"
      }
    ]
  },
  timeFormatString: {
    type: 'string',
    label: '自定义日期显示格式 (${YYYY}、${MM}、${DD}将被具体年、月、日取代)'
  },
  // 格式化中文
  formatChinese: {
    type: 'switch',
    label: '格式化中文'
  },
  dayShowType: {
    label: '显示类型',
    type: 'select',
    options: [
      {
        value: "chinese",
        label: "中文字符"
      },
      {
        value: "english",
        label: "英文字符"
      },
      {
        value: "number",
        label: "数字"
      }
    ]
  },
  dayFormatString: {
    type: 'string',
    label: "模板（中文和数字类型生效, eg.'周${day}' => '周三'/'周3'）"
  },
  // 翻牌器
  content: {
    type: 'string',
    label: '单位'
  },
  separator: {
    type: 'switch',
    label: '千位分割符'
  },
  animationFrame: {
    type: 'number',
    label: '动画时长'
  },
  // 超链接
  hrefValue: {
    type: 'string',
    label: '链接'
  },
  target: {
    label: '跳转方式',
    type: 'select',
    options: [
      {
        value: "_blank",
        label: "新标签页打开"
      },
      {
        value: "_self",
        label: "在当前标签页打开"
      }
    ]
  },
  mainColor: {
    label: '主色',
    type: 'color'
  },
  secondaryColor: {
    label: '副色',
    type: 'color'
  },
  direction: {
    label: '渐变方向',
    type: 'select',
    options: [
      {
        label: '从上至下',
        value: ''
      },
      {
        label: '从左至右',
        value: 'to right'
      },
      {
        label: '从右至左',
        value: 'to left'
      },
      {
        label: '左下至右上',
        value: 'to top right'
      },
      {
        label: '左上至右下',
        value: 'to bottom right'
      },
      {
        label: '放射',
        value: 'repeating-radial-gradient'
      }
    ]
  }
}

const functionCom = {
  isPreview: {
    type: 'switch',
    tips: '用于编辑时预览Ifarme',
    label: '预览'
  },
  src: {
    type: 'string',
    label: '地址'
  },
  fontSize: {
    type: 'string',
    label: '字体大小'
  },
  textAlign: {
    label: '对齐方式',
    type: 'select',
    options: [
      {
        label: '左对齐',
        value: 'left'
      },
      {
        label: '居中',
        value: 'center'
      },
      {
        label: '右对齐',
        value: 'right'
      }
    ]
  },
  lineHeight: {
    label: '行高',
    type: 'string'
  },
  pagination: {
    label: '分页',
    type: 'switch'
  },
  showHeader: {
    label: '显示表头',
    type: 'switch'
  },
  headerHieght: {
    label: '表头高度',
    type: 'string'
  },
  headerBackGround: {
    label: '表头背景',
    type: 'color'
  },
  headerTransparent: {
    label: '表头透明',
    type: 'switch'
  },
  headerColor: {
    label: '表头字体颜色',
    type: 'color'
  },
  headerFontSize: {
    label: '表头字体大小',
    type: 'string'
  },
  bodyHieght: {
    label: '内容行高',
    type: 'string'
  },
  bodyBackGround: {
    label: '内容背景',
    type: 'color'
  },
  bodyTransparent: {
    label: '内容背景透明',
    type: 'switch'
  },
  bodyFontSize: {
    label: '内容字体大小',
    type: 'string'
  },
  showWeather: {
    label: '是否显示天气',
    type: 'switch'
  },
  showAirpm: {
    label: '是否显示PM2.5',
    type: 'switch'
  },
  showHumidity: {
    label: '是否显示湿度',
    type: 'switch'
  },
  showWindSpeed: {
    label: '是否显示风速',
    type: 'switch'
  },
  mainColor: {
    label: '主色',
    tips: '主要字体颜色。',
    type: 'color'
  },
  secondaryColor: {
    label: '副色',
    tips: '次要体颜色。',
    type: 'color'
  },
  showIcon: {
    label: '显示ICON/显示文字',
    type: 'switch'
  },
  weatherMargin: {
    label: '间距',
    type: 'string'
  },
  showIndex: {
    label: '是否显示序号',
    type: 'switch'
  },
  loopScroll: {
    label: '是否循环滚动',
    type: 'switch'
  },
  rowNumber: {
    label: '行数',
    tips: '需要滚动的行数',
    type: 'number'
  },
  duration: {
    label: '滚动间隔',
    tips: '每次滚动的间隔时间',
    type: 'number'
  },
  videoType: {
    label: '类型',
    tips: '需要播放的视频类型，Other为普通视频，YS7为萤石云直播流，HLS为HLS的直播流，其优先级低于数据仓库绑定',
    type: 'select',
    options: [
      {
        label: 'Other',
        value: 'other'
      },
      {
        label: 'YS7',
        value: 'YS7'
      },
      {
        label: 'HLS',
        value: 'HLS'
      }
    ]
  },
  showNum: {
    label: '展示数量',
    type: 'switch'
  },
  showTitle: {
    label: '展示问题类型',
    type: 'switch'
  },
  title: {
    label: '问题类型',
    type: 'string'
  },
  numColor: {
    label: '数量颜色',
    type: 'color'
  },
  transparentPagination: {
    label: '透明分页',
    type: 'switch'
  },
  useSort: {
    label: '前端排序',
    tips: '多个排序字段，最终将根据字段顺序进行排序',
    type: 'switch'
  },
  showDateScreen: {
    label: '时间筛选',
    type: 'switch'
  },
  screen_sequence: {
    label: '视频顺序',
    tips: '用于接口请求现场监控地址时匹配配置中的顺序。',
    type: 'number'
  },
  showControl: {
    label: '显示控制',
    tips: '是否显示视频的播放、暂停及进度条',
    type: 'switch'
  },
  isDark: {
    label: '深色模式',
    tips: '模式切换暂不支持自动切换,请手动切换是否显示查看效果!',
    type: 'switch'
  },
  label: {
    label: '标签',
    type: 'string'
  },
  backgroundImage: {
    type: 'string',
    label: '背景图片'
  },
  objectFit: {
    label: '填充方式',
    type: 'select',
    options: [
      {
        label: '剪切填充',
        value: 'cover'
      },
      {
        label: '比例缩放',
        value: 'contain'
      },
      {
        label: '填满',
        value: '100% 100%'
      }
    ]
  },
  titleColor: {
    label: '标题字体颜色',
    type: 'color'
  },
  titleFontSize: {
    label: '标题字体大小',
    type: 'string'
  },
  userInnerBorder: {
    type: 'switch',
    label: '使用内边框'
  },
  borderColor: {
    label: '边框颜色',
    type: 'color'
  },
  borderWidth: {
    label: '边框宽度',
    type: 'number'
  },
  usePasswordLogin: {
    label: '使用密码登录',
    type: 'switch'
  },
  useCodeLogin: {
    label: '使用验证码登录',
    type: 'switch'
  },
  userQRCodelogin: {
    label: '使用二维码登录',
    type: 'switch'
  },
  useResetPassword: {
    label: '允许重置密码',
    type: 'switch'
  },
  formItemMargin: {
    label: '表单间距',
    type: 'number'
  },
  tabsMargin: {
    label: '登录选项间距',
    type: 'number'
  },
  tabsFontSize: {
    label: '登录选项字体大小',
    type: 'number'
  },
  tabsFontWeight: {
    label: '登录选择字重',
    type: 'select',
    options: [
      {
        label: '普通',
        value: 'normal'
      },
      {
        label: '加粗',
        value: 'bold'
      },
      {
        label: '变浅',
        value: 'lighter'
      },
      {
        label: '更粗',
        value: 'bolder'
      }
    ]
  },
  activeColor: {
    label: '按钮颜色及激活颜色',
    type: 'color'
  },
  btnColor: {
    label: '按钮字体颜色',
    type: 'color'
  },
  inputStyle: {
    label: '输入框类型',
    type: 'select',
    options: [
      {
        label: '描边',
        value: 'border'
      },
      {
        label: '下划线',
        value: 'underline'
      },
      {
        label: '填充',
        value: 'fill'
      }
    ]
  },
  PtzControlSize: {
    label: '云台控制器大小',
    type: 'number'
  }
}

const CommonImage = {
  objectFit: {
    label: '填充方式',
    type: 'select',
    options: [
      {
        label: '剪切填充',
        value: 'cover'
      },
      {
        label: '填充',
        value: 'fill'
      },
      {
        label: '比例缩放',
        value: 'contain'
      },
      {
        label: '固定尺寸',
        value: 'none'
      },
      {
        label: '自适应(小尺寸优先)',
        value: 'scale-down'
      }
    ]
  }
}

const RouteTop = {
  showTitle: {
    label: '显示标题',
    type: 'switch'
  },
  showSearch: {
    label: '显示筛选按钮',
    type: 'switch'
  },
  mobileSearchInput: {
    label: '是否显示搜索框',
    type: 'switch'
  }
}

const MilestoneCom = {
  showTitle: {
    label: '显示标题',
    type: 'switch'
  }
}

const MobileCommonContainer = {
  borderTopShow: {
    label: '是否展示上边框',
    type: 'switch'
  },
  borderTopWidth: {
    label: '边框宽度',
    type: 'number'
  },
  borderTopType: {
    label: '边框类型',
    type: 'select',
    options: [
      {
        label: '无',
        value: 'none'
      },
      {
        label: '实线',
        value: 'solid'
      },
      {
        label: '虚线',
        value: 'dashed'
      },
      {
        labek: '双线',
        value: 'double'
      },
      {
        label: '点状(浏览器实际为准)',
        value: 'dotted'
      },
      {
        label: '3D 凹槽边框',
        value: 'groove'
      },
      {
        label: '3D 垄状边框',
        value: 'ridge'
      },
      {
        label: '3D inset 边框',
        value: 'inset'
      },
      {
        label: '3D outset 边框',
        value: 'outset'
      },
      {
        label: '隐藏(浏览器效果为准)',
        value: 'hidden'
      },
    ]
  },
  borderTopColor: {
    label: '边框颜色',
    type: 'color'
  },
  borderLeftShow: {
    label: '是否展示左边框',
    type: 'switch'
  },
  borderLeftWidth: {
    label: '边框宽度',
    type: 'number'
  },
  borderLeftType: {
    label: '边框类型',
    type: 'select',
    options: [
      {
        label: '无',
        value: 'none'
      },
      {
        label: '实线',
        value: 'solid'
      },
      {
        label: '虚线',
        value: 'dashed'
      },
      {
        labek: '双线',
        value: 'double'
      },
      {
        label: '点状(浏览器实际为准)',
        value: 'dotted'
      },
      {
        label: '3D 凹槽边框',
        value: 'groove'
      },
      {
        label: '3D 垄状边框',
        value: 'ridge'
      },
      {
        label: '3D inset 边框',
        value: 'inset'
      },
      {
        label: '3D outset 边框',
        value: 'outset'
      },
      {
        label: '隐藏(浏览器效果为准)',
        value: 'hidden'
      },
    ]
  },
  borderLeftColor: {
    label: '边框颜色',
    type: 'color'
  },
  borderBottomShow: {
    label: '是否展示下边框',
    type: 'switch'
  },
  borderBottomWidth: {
    label: '边框宽度',
    type: 'number'
  },
  borderBottomType: {
    label: '边框类型',
    type: 'select',
    options: [
      {
        label: '无',
        value: 'none'
      },
      {
        label: '实线',
        value: 'solid'
      },
      {
        label: '虚线',
        value: 'dashed'
      },
      {
        labek: '双线',
        value: 'double'
      },
      {
        label: '点状(浏览器实际为准)',
        value: 'dotted'
      },
      {
        label: '3D 凹槽边框',
        value: 'groove'
      },
      {
        label: '3D 垄状边框',
        value: 'ridge'
      },
      {
        label: '3D inset 边框',
        value: 'inset'
      },
      {
        label: '3D outset 边框',
        value: 'outset'
      },
      {
        label: '隐藏(浏览器效果为准)',
        value: 'hidden'
      },
    ]
  },
  borderBottomColor: {
    label: '边框颜色',
    type: 'color'
  },
  borderRightShow: {
    label: '是否展示右边框',
    type: 'switch'
  },
  borderRightWidth: {
    label: '边框宽度',
    type: 'number'
  },
  borderRightType: {
    label: '边框类型',
    type: 'select',
    options: [
      {
        label: '无',
        value: 'none'
      },
      {
        label: '实线',
        value: 'solid'
      },
      {
        label: '虚线',
        value: 'dashed'
      },
      {
        labek: '双线',
        value: 'double'
      },
      {
        label: '点状(浏览器实际为准)',
        value: 'dotted'
      },
      {
        label: '3D 凹槽边框',
        value: 'groove'
      },
      {
        label: '3D 垄状边框',
        value: 'ridge'
      },
      {
        label: '3D inset 边框',
        value: 'inset'
      },
      {
        label: '3D outset 边框',
        value: 'outset'
      },
      {
        label: '隐藏(浏览器效果为准)',
        value: 'hidden'
      },
    ]
  },
  borderRightColor: {
    label: '边框颜色',
    type: 'color'
  }
}

const CommonRandomText = {
  defaultVal: {
    label: '默认值',
    type: 'number'
  },
  useRandom: {
    label: '启用随机',
    type: 'switch'
  },
  randomTime: {
    label: '随机时间(ms)',
    type: 'number'
  },
  min: {
    label: '最小值',
    type: 'number'
  },
  max: {
    label: '最大值',
    type: 'number'
  },
  showUnit: {
    label: '显示单位',
    type: 'switch'
  },
  unit: {
    label: '单位',
    type: 'string'
  },
  unitSize: {
    label: '单位字体大小',
    type: 'number'
  },
  floatLength: {
    label: '小数点位数',
    type: 'number'
  }
}

const MiniCard = {
  title: {
    type: 'string',
    label: '标题'
  },
  description: {
    label: '描述',
    type: 'string'
  },
  iconUrl: {
    label: '图标地址',
    type: 'string'
  },
  route: {
    label: '路由',
    type: 'string'
  },
  pageUUID: {
    label: '页面UUID',
    type: 'string'
  },
  menuType: {
    label: '菜单类型(0 配置页面,1 外链,2 功能组,4 微应用)',
    type: 'number'
  }
}

const LargeCard = {
  title: {
    type: 'string',
    label: '标题'
  },
  description: {
    label: '描述',
    type: 'string',
  },
  miniIconUrl: {
    label: '小图标',
    type: 'string',
  },
  largeIconUrl: {
    label: '大图标',
    type: 'string',
  },
  type: {
    label: '类型',
    type: 'select',
    options: [
      {
        label: '上下结构',
        value: 'default'
      },
      {
        label: '左右结构',
        value: 'row'
      }
    ]
  },
  route: {
    label: '路由',
    type: 'string'
  },
  pageUUID: {
    label: '页面UUID',
    type: 'string'
  },
  menuType: {
    label: '菜单类型(0 配置页面,1 外链,2 功能组,4 微应用)',
    type: 'number'
  }
}

const commonStatisticsAnalyze = {
  valueStatus: {
    label: '图表类型',
    type: 'select',
    options: [
      {
        label: '质量',
        value: 1
      },
      {
        label: '安全',
        value: 2
      }
    ]
  },
  projectLevelFilterHidden: {
    label: '隐藏规模筛选',
    type: 'switch'
  }

}

const CommonGridContainer = {
  toolbar: {
    type: 'switch',
    label: '工具栏显示'
  },
  panelName: {
    type: 'string',
    label: '面板名称'
  },
  addPanel: {
    type: 'switch',
    label: '添加面板'
  },
  autoSize: {
    type: 'switch',
    label: '面板自适应大小'
  },
  preventCollision: {
    type: 'switch',
    label: '防止碰撞'
  },
  responsive: {
    type: 'switch',
    label: '响应式'
  }
}

const CommonSubitemTemplate = {
  title: {
    label: '标题',
    type: 'string'
  },
  // type: {
  //   label: '类型',
  //   type: 'select',
  //   options: [
  //     {
  //       label: '标准用表',
  //       value: 'table'
  //     },
  //     {
  //       label: '分部分项',
  //       value: 'subitem'
  //     }
  //   ]
  // },
  needTreeTerminal: {
    label: '开启三端',
    type: 'switch'
  }
}

const CommonDateButton = {
  ...button,
  dateFormat: {
    label: '时间格式(显示)',
    type: 'select',
    options: [
      {
        value: "date",
        label: "仅日期"
      },
      {
        value: "datetime",
        label: "日期+时间"
      }
    ]
  },
  valueFormat: {
    label: '时间格式(取值)',
    type: 'select',
    options: [
      {
        value: "yyyy-MM-dd",
        label: "年月日"
      },
      {
        value: "yyyy-MM-dd hh:mm",
        label: "年月日时分"
      },
      {
        value: "yyyy-MM-dd hh:mm:ss",
        label: "年月日时分秒"
      }
    ]
  },
  pickerType: {
    label: '可选类型',
    type: 'select',
    options: [
      {
        value: "date",
        label: "日期"
      },
      {
        value: "week",
        label: "周"
      },
      {
        value: "month",
        label: "月"
      },
      {
        value: "year",
        label: "年"
      }
    ]
  },
  // straddleYear: {
  //   label: '跨年份选择',
  //   type: 'switch'
  // },
  advance: {
    label: '允许提前新增',
    type: 'switch'
  }
}

const CommonText = {
  ellipsis: {
    label: '使用省略号',
    type: 'switch'
  },
  clamp: {
    label: '显示行数',
    type: 'number'
  },
  nullText: {
    label: '空值文本',
    type: 'string'
  }
}

const BaseIcon = {
  type: {
    label: '渲染类型',
    type: 'select',
    options: [
      {
        value: "auto",
        label: "自动"
      },
      {
        value: "svg",
        label: "Svg"
      },
      {
        value: "class",
        label: "常规"
      }
    ]
  }
}
/* 移动端新增按钮配置 */
const addComponent = {
  icon: {
    label: '图标类名',
    type: 'string'
  },
}

// 基础容器
const CommonContainer = {
  resolveObject: {
    label: '暴露唯一对象',
    type: 'switch'
  }
}
export default {
  button,
  CommonContainer,
  CommonDateButton,
  table,
  CommonText,
  BaseIcon,
  CommonGridContainer,
  CommonTableContainer: table,
  commonConfig,
  pagination,
  tree,
  dialog,
  swiper,
  tabs,
  screenTabs,
  CommonFormContainer,
  ConsoleForm,
  CommonForm,
  selectPerson,
  FormOnlyOne,
  CommonDrawer,
  CommonSelect,
  CommonCalendar,
  CommonNetDisk,
  CommonDateFilter,
  CommonTitle,
  CommonStatTableOne,
  CommonStatTableTwo: CommonStatTableOne,
  decoration,
  currency,
  functionCom,
  TableCom: functionCom,
  ScrollTableCom: functionCom,
  ScrollBlock: functionCom,
  CommonSheetContainer,
  CommonBaseDate,
  StatusCom: functionCom,
  PunchinStatistics: functionCom,
  LiveCom: functionCom,
  CommonImage,
  MilestoneCom,
  RouteTop,
  MobileCommonContainer,
  CommonRandomText,
  MiniCard,
  LargeCard,
  commonStatisticsAnalyze,
  CommonSubitemTemplate,
  MobileSwiper,
  addComponent
}
