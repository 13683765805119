<!--
 * @Description: 行为配置Item
 * @Author: luocheng
 * @Date: 2022-05-11 16:10:04
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-16 10:34:28
-->
<template>
  <div class="behavior-item" v-if="configForm">
    <section class="config-box">
      <el-form :model="configForm" label-width="80px">
        <el-form-item label="作用组件">
          <el-select v-model="configForm.component" placeholder="请选择作用组件" @change="onComponent">
            <el-option
              v-for="item in componentList"
              :key="item.value"
              :disabled="item.disabled"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="effects-list">
          <Item v-for="(item, index) in configForm.list" :key="index"
            :index="index"
            :config="item"
            @remove="onListRemove"
            @update="onListUpdate"
            :behaviorList="behaviorList"
          >
          </Item>
          <el-button type="success" size="mini" @click="onPlusConfig">新增作用</el-button>
        </div>
      </el-form>
    </section>
    <div class="actions">
      <i class="iconfont el-icon-delete" @click="onRemove(index)"></i>
    </div>
  </div>
</template>

<script>
import { flatTree, getComponentById, unique } from '@/utils/tools';
import { mapState } from 'vuex';
import Item from './Item';

export default {
  name: 'BehaviorItem',
  components: {
    Item
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      configForm: null,
      // 可选组件列表
      componentList: [],
      // 行为列表
      behaviorList: []
    }
  },
  computed: {
    ...mapState(['componentData', 'originComponentData', 'sContainer'])
  },
  created() {
    this.configForm = this.config || {
      component: '', // 触发的组件
      list: [ // 行为列表
        {
          behaviors: [],
          params: []
        }
      ]
    };
    if (this.sContainer) {
      this.componentList = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
    }
    this.componentList = unique(this.componentList.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id'
      }, [])));
  },
  methods: {
    /**
     * @desc: 选择组件
     * @param {String} componentId 组件ID
     */
    onComponent(componentId) {
      const originComponent = getComponentById(this.componentData, componentId);
      this.behaviorList = originComponent.behaviors || [];
      this.onEmit();
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.configForm
      });
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.$emit('remove', index)
    },
    /**
     * @desc: 新增配置
     */
    onPlusConfig() {
      this.configForm.list.push({
        behaviors: [],
        params: []
      });
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onListRemove(index) {
      this.configForm.list.splice(index, 1);
    },
    /**
     * @desc: 更新行为list
     * @param {Number} index
     * @param {Object} config
     */
    onListUpdate({ index, config }) {
      this.configForm.list.splice(index, 1, {
        ...config
      });
    }
  }
}
</script>

<style lang="less" scoped>
.behavior-item{
  width: 100%;
  display: flex;
  margin-top: 10px;
  .config-box{
    flex: 1;
    border: 1px solid #f2f3f5;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 6px;
  }
  .actions{
    padding: 10px;
    i{
      line-height: 100px;
      color: @dangerColor;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .effects-list{
    padding-bottom: 10px;
  }
  .el-form{
    border: none!important;
  }
  .el-form-item{
    margin-bottom: 5px;
  }
}
</style>