<!--
 * @Description: 独立表单配置
 * @Author: luocheng
 * @Date: 2021-11-23 16:13:50
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-23 17:59:18
-->
<template>
	<div
		class="form-only-one-config"
		v-if="contentForm"
	>
		<el-form :model="contentForm">
			<el-form-item label="对象">
				<SelectObject v-model="contentForm.objectUUID"></SelectObject>
			</el-form-item>
			<template v-if="contentForm.objectUUID">
				<el-form-item label="视图">
					<SelectView
						v-model="contentForm.viewUUID"
						:objectUUID="contentForm.objectUUID"
					></SelectView>
				</el-form-item>
				<el-form-item label="表单">
					<SelectForm
						v-model="contentForm.formUUID"
						:objectUUID="contentForm.objectUUID"
					></SelectForm>
				</el-form-item>
			</template>
			<el-form-item label="额外条件">
				<CreateFixedOptions v-model="contentForm.moreCondition"></CreateFixedOptions>
			</el-form-item>
			<el-form-item label="附加字典">
				<CreateFixedOptions v-model="contentForm.moreCreateDicts"></CreateFixedOptions>
			</el-form-item>
			<el-form-item>
				<el-button
					type="primary"
					@click="onSubmit"
					style="width: 200px"
				>提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SelectObject from '@/components/commonComponents/SelectObject';
import SelectView from '@/components/commonComponents/SelectView';
import SelectForm from '@/components/commonComponents/SelectForm';
import CreateFixedOptions from '@/components/commonComponents/CreateFixedOptions';

export default {
	name: 'FormOnlyOneConfig',
	components: {
		SelectObject,
		SelectView,
		SelectForm,
		CreateFixedOptions
	},
	data() {
		return {
			contentForm: null
			// objectUUID: '', // 数据对象
			// 	viewUUID: '', // 视图ID
			// 	formUUID: '', // 表单对象
			//   moreCondition: [], // 查找数据时的额外条件，会自动当成字典在新增时写入 code  value
			//   moreCreateDicts: [], // 自动创建时的附加字典  code  value
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	created() {
		this.contentForm = {
			...this.curComponent.contentConfig
		};
	},
	methods: {
		/**
		 * @desc: 提交
		 */
		onSubmit() {
			console.log(this.contentForm, '-----2222');
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					contentConfig: {
						...this.contentForm
					}
				},
				containerId: null,
				isModify: true
			});
		}
	}
};
</script>

<style>
</style>