<!--
 * @Description: 关联数据源
 * @Author: luocheng
 * @Date: 2021-09-30 10:38:05
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-30 10:50:52
-->
<template>
	<div class="relation-source">
		<header class="header">
			<span class="line"></span>
			<p class="item-title">数据来源</p>
			<el-button type="primary" @click="onConfirm">确认</el-button>
			<el-button type="success" @click="onPlus">新增</el-button>
			<a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
				{{ toggleShow ? "收起" : "展开" }}
			</a>
		</header>
		<article v-show="toggleShow">
			<template v-if="sourceConfig && sourceConfig.length">
				<SourceItem
					v-for="(item, index) in sourceConfig" :key="item.id"
					:config="sourceConfig[index]"
					:index="index"
					@removeSource="onRemove"
					@update="onUpdateItem"
				></SourceItem>
			</template>
      <el-empty description="暂无数据" v-else></el-empty>
		</article>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SourceItem from './SourceItem';
import { Empty } from 'element-ui';

export default {
	name: 'RelationSource',
	components: {
		'el-empty': Empty,
		SourceItem
	},
	data() {
		return {
			toggleShow: false,
			// 配置 暂时只需要传递data_id ，后续进行其他参数配置(同容器的请求参数配置):todo => 组件抽离
			sourceConfig: []
		};
	},
	created() {
		this.sourceConfig = this.curComponent && this.curComponent.sourceConfig;
	},
	computed: {
		...mapState(['curComponent'])
	},
	methods: {
		/**
		 * @desc: 确认配置
		 */
		onConfirm() {
			/* eslint-disable */
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					sourceConfig: this.sourceConfig
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 新增来源
		 */
		onPlus() {
			this.sourceConfig.push({
        id: new Date().getTime(),
				type: '',
				key: '',
				componentId: '',
				field: '',
				originType: '',
				urlParamKey: '',
				isRequired: false,
				statusCode: '' // 状态字段
			});
		},
		/**
		 * @desc: 更新节点数据
		 * @param {Object} data 配置 index 下标 config 配置数据
		 */
		onUpdateItem({ index, config }) {
			this.sourceConfig.splice(index, 1, {
				...config
			});
		},
		/**
		 * @desc: 移除
		 * @param {Number} index 下标
		 */
		onRemove(index) {
			this.sourceConfig.splice(index, 1);
		}
	}
};
</script>

<style lang="less" scoped>
.relation-source {
	.header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		.line{
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 9px;
			background: @theme;
		}
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 32px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 32px;
			padding-right: 4px;
		}
	}
	>article{
		width: 100%;
	}
}
</style>