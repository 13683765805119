<!--
 * @Description: 更新规则
 * @Author: luocheng
 * @Date: 2022-05-16 18:15:06
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-04 12:02:08
-->
<template>
  <div class="update-rules">
    <header class="header">
			<span class="line"></span>
			<p class="item-title"></p>
			<el-button type="primary" size="mini" @click="onConfirm">确认</el-button>
			<el-button type="success" @click="onPlus">新增规则</el-button>
			<a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
				{{ toggleShow ? "收起" : "展开" }}
			</a>
		</header>
    <article class="rules" v-show="toggleShow">
      <el-form :model="updateRulesConfig" label-width="80px">
        <el-form-item label="更新模式">
          <el-select v-model="updateRulesConfig.pattern">
            <el-option label="自动模式" value=""></el-option>
            <el-option label="强规则模式" value="rules"></el-option>
            <el-option label="兼容模式" value="compatible"></el-option>
          </el-select>
        </el-form-item>
        <RuleItem v-for="(item, index) in updateRulesConfig.rules" :key="index"
          :index="index"
          :config="item"
          :componentList="componentList"
          @remove="onRemove"
          @update="onUpdate"
        ></RuleItem>
      </el-form>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RuleItem from '@/components/dialogConfig/RuleItem';
import { flatTree } from '@/utils/tools';

export default {
  name: 'UpdateRules',
  components: {
    RuleItem
  },
  data() {
    return {
      toggleShow: true,
      componentList: [],
      updateRulesConfig: {
        pattern: '',
        rules: []
      }
    }
  },
  computed: {
    ...mapState(['curComponent', 'componentData'])
  },
  created() {
    this.updateRulesConfig = this.curComponent?.updateRulesConfig || {
      pattern: '',
      rules: []
    }
    this.componentList = flatTree(this.componentData, 'children', null, []);
  },
  methods: {
    /**
     * @desc: 确认
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					updateRulesConfig: this.updateRulesConfig
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.updateRulesConfig.rules.push({
        componentId: ''
      })
    },
    /**
     * @desc: 移除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.updateRulesConfig.rules.splice(index, 1);
    },
    /**
     * @desc: 更新
     * @param {Object} config 配置
     * @param {Number} index 下标
     */
    onUpdate({ config, index }) {
      this.updateRulesConfig.rules.splice(index, 1, {
        ...config
      });
    } 
  }
}
</script>

<style lang="less" scoped>
.update-rules{
  .header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
    flex-wrap: wrap;
		.line{
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 9px;
			background: @theme;
		}
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 32px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 32px;
			padding-right: 4px;
		}
	}
}
</style>