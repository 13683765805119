<template>
    <div class="model-config" v-if="curComponent">
        <h3 class="title">基础配置</h3>
        <article class="contents">
            <div class="view-config" v-if="curComponent">
                <header class="header"></header>

                <el-divider>源配置</el-divider>
                <el-form  label-width="85px">
                    <el-form-item label="目标组件">
                        <el-select placeholder="请选择目标组件" size="mini" v-model="bindViewerId">
                            <el-option v-for="item in bindViewerList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button class="config-button" type="primary" @click="bindViewer">应用设置</el-button>
            </div>
        </article>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import { Divider } from "element-ui";
    export default {
        name: "ModelTreeConfig",
        data (){
            return {
                bindViewerId : "",
                bindViewerList : [],

            }
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        components: {
            "el-divider" : Divider,
        },
        methods:{
            init(){
                eventBus.$emit("BIMModule.GetSource" , this.curComponent.id , ( { list , bindId } )=>{
                    this.bindViewerList = list;
                    this.bindViewerId = bindId;
                });
            },
            bindViewer(){
                eventBus.$emit("BIMModule.BindViewer" ,  this.curComponent.id , this.bindViewerId );
            },
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="less" scoped>
    .model-config{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title{
            text-align: left;
        }
        .contents{
            width: 100%;
            overflow-y: auto;
        }
        .btn-bar{
            display: flex;
            justify-content: right;
        }
        .cards{
            padding-top: 5px;
        }

    }
</style>