<!--
 * @Description: 页面配置页面
 * @Author: luocheng
 * @Date: 2021-08-10 15:10:33
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:34:56
-->
<template>
	<div class="modify-page" v-loading="loading" v-if="!loading">
		<el-header height="auto">
			<Toolbar></Toolbar>
		</el-header>
		<el-container>
			<!-- 左侧侧边栏 组件列表 及 图层列表 -->
			<el-aside
				:width="showLeft ? '275px' : '0px'"
				:style="{ padding: showLeft ? '0 8px 8px 8px' : '0px' }"
			>
				<el-tabs v-model="leftTab" :stretch="true" v-show="showLeft" class="left-tabs">
					<el-tab-pane label="组件" name="componentList">
						<ComponentsList :isMobile="isMobile"></ComponentsList>
					</el-tab-pane>
					<el-tab-pane label="图层" name="layerList">
						<LayerList :layerData="componentData"></LayerList>
					</el-tab-pane>
				</el-tabs>
				<!-- 收起 -->
				<a
					href="javascript:;"
					class="iconfont put-away left"
					:class="showLeft ? 'iconc-chevron-left' : 'iconxiangyoujiantou2'"
					@click="onToggleLeft"
				></a>
			</el-aside>
			<!-- 中间画布 -->
			<el-main>
				<div class="content" @click.self="clearCurComponent">
					<DataView v-if="editorType == 'dataview' || editorType == 'console'"></DataView>
					<NormalPage v-else-if="['page', 'template', 'second', 'card'].includes(editorType)" ></NormalPage>
					<MobilePage v-if="editorType == 'mobile'"></MobilePage>
				</div>
			</el-main>
			<!-- 属性 -->
			<el-aside
				:width="showRight ? '286px' : '0px'"
				id="youshouqi"
				:style="{ padding: showRight ? '0 10px 10px 10px' : '0px' }"
			>
				<el-tabs v-model="rightTab" :stretch="true" v-show="showRight">
					<el-tab-pane label="属性" name="attribute">
						<Attribute></Attribute>
					</el-tab-pane>
					<template v-if="curComponent && curComponent.type !== 'pageTemplate'">
						<el-tab-pane label="tab配置" name="tabs" v-if="curComponent.component === 'CommonTabs'">
							<TabsConfig></TabsConfig>
						</el-tab-pane>
						<el-tab-pane label="大屏tab配置" name="tabs" v-if="curComponent.component === 'ScreenTabs'">
							<ScreenTabsConfig></ScreenTabsConfig>
						</el-tab-pane>
						<el-tab-pane label="网盘配置" name="netDisk" v-if="curComponent.component === 'CommonNetDisk'">
							<NetDiskConfig></NetDiskConfig>
						</el-tab-pane>
						<el-tab-pane
							label="人员选择配置"
							name="selectPerson"
							v-if="curComponent.component === 'CommonSelectPerson'"
						>
							<SelectPersonConfig></SelectPersonConfig>
						</el-tab-pane>
						<el-tab-pane label="表单配置" name="filter" v-if="curComponent.component === 'CommonForm' || curComponent.component === 'ConsoleFilter' || curComponent.component === 'RouteTop'">
							<FilterConfig></FilterConfig>
						</el-tab-pane>
						<el-tab-pane
							label="表单配置"
							name="commonForm"
							v-if="['CommonFormContainer', 'ConsoleForm', 'CommonPageContainer'].includes(curComponent.component)"
						>
							<FormContainerConfig></FormContainerConfig>
						</el-tab-pane>
						<el-tab-pane label="项目配置页配置" name="commonConfigPage" v-if="curComponent.component === 'CommonConfigPage'">
							<ConfigPageConfig></ConfigPageConfig>
						</el-tab-pane>
						<el-tab-pane label="渲染配置" name="dialog" v-if="curComponent.type === 'dialog'">
							<DialogConfig></DialogConfig>
						</el-tab-pane>
						<el-tab-pane label="模型配置" name="dialog" v-if="curComponent.component === 'CommonModel' || curComponent.component === 'ConsoleBimModel'">
							<ModelConfig></ModelConfig>
						</el-tab-pane>

						<el-tab-pane label="基础" name="dialog" v-if="curComponent.component === 'ViewerBIM'">
							<ModelSourceConfig></ModelSourceConfig>
						</el-tab-pane>

						<el-tab-pane label="工具" name="dialogpart" v-if="curComponent.component === 'ViewerBIM'">
							<ModelPartConfig></ModelPartConfig>
						</el-tab-pane>

						<el-tab-pane label="标记" name="dialogmark" v-if="curComponent.component === 'ViewerBIM'">
							<ModelMarkerConfig></ModelMarkerConfig>
						</el-tab-pane>

						<el-tab-pane label="基础" name="dialogmark" v-if="curComponent.component === 'viewerBIMModule'">
							<ModelTreeConfig></ModelTreeConfig>
						</el-tab-pane>

						<el-tab-pane label="基础" name="dialog" v-if="curComponent.component === 'ViewerGIS'">
							<GISSourceConfig></GISSourceConfig>
						</el-tab-pane>

						<el-tab-pane label="基础" name="dialog" v-if="curComponent.component === 'ViewerGISModule'">
							<GISModuleConfig></GISModuleConfig>
						</el-tab-pane>

						<el-tab-pane label="透明" name="dialogopacity" v-if="curComponent.component === 'ViewerBIM'">
							<ModelOpacityConfig></ModelOpacityConfig>
						</el-tab-pane>


						<el-tab-pane label="监控配置" name="dialog" v-if="curComponent.component === 'CommonMonitorSign'">
							<MonitorConfig></MonitorConfig>
						</el-tab-pane>
						<el-tab-pane label="塔吊配置" name="dialog" v-if="curComponent.component === 'CommonTowerSign'">
							<TowerConfig></TowerConfig>
						</el-tab-pane>
						<el-tab-pane label="流程配置" name="flowConfig" v-if="curComponent.component === 'CommonFlow'">
							<FlowConfig></FlowConfig>
						</el-tab-pane>
						<el-tab-pane
							label="表格配置"
							name="table"
							v-if="curComponent.component === 'CommonTableContainer' || ['TableCom', 'ScrollTableCom', 'ScrollBlock'].indexOf(curComponent.component) > -1"
						>
							<TableConfig></TableConfig>
						</el-tab-pane>
						<el-tab-pane
							label="列表配置"
							name="table"
							v-if="['ListContainerOne','ConsoleList'].indexOf(curComponent.component) > -1"
						>
							<ListComConfig></ListComConfig>
						</el-tab-pane>
						<el-tab-pane
							label="时间轴配置"
							name="table"
							v-if="curComponent.component === 'AxisList'"
						>
							<AxisListConfig></AxisListConfig>
						</el-tab-pane>
						<el-tab-pane label="树形图" name="tree" v-if="curComponent.component === 'CommonTree'">
							<TreeConfig></TreeConfig>
						</el-tab-pane>
						<el-tab-pane label="日历配置" name="calendar" v-if="curComponent.component === 'CommonCalendar'">
							<CalendarConfig></CalendarConfig>
						</el-tab-pane>
						<el-tab-pane label="独立表单配置" name="table" v-if="curComponent.component === 'FormOnlyOne'">
							<FormOnlyOneConfig></FormOnlyOneConfig>
						</el-tab-pane>
						<!-- 移动端模型预览组件取model_id等配置 -->
						<el-tab-pane label="模型对象配置" name="mobileModelPreview" v-if="curComponent.component === 'ModelPreview'">
							<MobileModelConfig></MobileModelConfig>
						</el-tab-pane>
						<el-tab-pane label="循环配置" name="loopContainer" v-if="curComponent.component === 'CommonLoopContainer'">
							<LoopConfig></LoopConfig>
						</el-tab-pane>
						<el-tab-pane label="全景配置" name="mobilePanoConfig" v-if="curComponent.component === 'Pano' && isMobile && !curComponent?.statusConfig?.isContrast">
							<MobilePanoConfig></MobilePanoConfig>
						</el-tab-pane>
						<el-tab-pane label="值绑定" name="value" v-if="!excludeValue.includes(curComponent.component)">
							<BindValue></BindValue>
						</el-tab-pane>
						<el-tab-pane label="魔法盒子配置" name="magicBox" v-if="curComponent.component === 'CommonMagic'">
							<MagicConfig></MagicConfig>
						</el-tab-pane>
						<el-tab-pane label="图表配置" name="chartBox" v-if="curComponent.component === 'CommonChart'">
							<ChartConfig></ChartConfig>
						</el-tab-pane>
						<el-tab-pane label="Excel容器配置" name="chartBox" v-if="curComponent.component === 'CommonSheetContainer'">
							<SheetContainerConfig></SheetContainerConfig>
						</el-tab-pane>
						<el-tab-pane label="轮播图配置" name="swiperConfig" v-if="curComponent.component === 'CommonSwiper'">
							<SwiperConfig></SwiperConfig>
						</el-tab-pane>
						<el-tab-pane label="分部分项模板配置" name="subitemTemplate" v-if="curComponent.component === 'CommonSubitemTemplate'">
							<SubitemTemplateConfig></SubitemTemplateConfig>
						</el-tab-pane>
						<el-tab-pane label="下拉按钮事件配置" name="dropDownConfig" v-if="curComponent.component === 'DropDownButton'">
							<DropDownConfig></DropDownConfig>
						</el-tab-pane>
						<el-tab-pane label="甘特图组件" name="ganttConfig" v-if="curComponent.component === 'CommonGantt'">
							<GanttConfig />
						</el-tab-pane>
						<el-tab-pane label="绑定组件配置" name="bindConfig" v-if="curComponent.component === 'BindComponent'">
							<BindComponentConfig></BindComponentConfig>
						</el-tab-pane>
						<!-- 自定义或首页组件的参数公共配置 -->
						<template  v-else-if="customComponents.includes(curComponent.component)">
							<el-tab-pane label="参数绑定" name="customParams">
								<CustomComponentsAttrs></CustomComponentsAttrs>
							</el-tab-pane>
						</template>
						<el-tab-pane label="表单配置" name="formValue" v-if="curComponent.component === 'FormMobileDetail' || curComponent.component === 'FormMobileAdd'">
							<FormMobileConfig></FormMobileConfig>
						</el-tab-pane>
						<el-tab-pane label="日历配置" name="formValue" v-if="curComponent.component === 'Calendar' && isMobile">
							<CalendarMobileConfig></CalendarMobileConfig>
						</el-tab-pane>
						<el-tab-pane label="事件" name="events">
							<ConfigEvents></ConfigEvents>
						</el-tab-pane>
						<!-- <el-tab-pane label="UI逻辑配置" name="UILogic">
							<UILogicConfig></UILogicConfig>
						</el-tab-pane> -->
					</template>
					<!-- 页面模板配置项目 -->
					<el-tab-pane
						v-else-if="curComponent && curComponent.component === 'ListTemplate'"
						label="模板配置"
						name="listTemplateConfig"
					>
						<ListConfig></ListConfig>
					</el-tab-pane>
					<template v-if="!curComponent">
						<el-tab-pane label="状态管理" name="globalState">
							<!-- <GlobalState></GlobalState> -->
							<StateMachine></StateMachine>
						</el-tab-pane>
						<!-- <el-tab-pane label="其他属性..." name="second"></el-tab-pane> -->
					</template>
				</el-tabs>
				<!-- 直接显示全局 -->
				<a href="javascript:;" class="page-config" @click.self="onGlobalAttr">全局属性</a>
				<!-- 收起 -->
				<a href="javascript:;" class="iconfont put-away right" :class="showRight ? 'iconxiangyoujiantou2' : 'iconc-chevron-left'" @click="onToggleRight"></a>
			</el-aside>
		</el-container>
		<!-- 选择基本布局容器 -->
		<template v-if="needConfigLayout">
			<SelectLayout v-model="showSelectLayout"></SelectLayout>
		</template>
		<!-- 页面模板 -->
		<PageTemplate v-model="showTemplates" v-if="showTemplates"></PageTemplate>
	</div>
</template>

<script>
import Toolbar from '@/components/layout/Toolbar';
import ComponentsList from '@/components/layout/ComponentsList';
import LayerList from '@/components/layout/LayerList';
import DataView from '@/components/editor/Index';
import Attribute from '@/components/attributes/Index';
import FilterConfig from '@/components/filterConfig/Index';
import TabsConfig from '@/components/tabsConfig/Index';
import ScreenTabsConfig from '@/components/screen_tabs_config'
import DialogConfig from '@/components/dialogConfig/Index';
import SelectLayout from '@/components/selectLayout/Index';
import NormalPage from '@/components/normalPage/Index';
import MobilePage from '@/mobile-components/mobilePage/Index';
import ConfigEvents from '@/components/events/Index';
import SelectPersonConfig from '@/components/selectPersonConfig/Index';
import TreeConfig from '@/components/treeConfig/Index';
import FlowConfig from '@/components/flowConfig/Index'
import MagicConfig from '@/components/magicConfig/Index';
import ChartConfig from '@/components/chartConfig/Index';
import SheetContainerConfig from '@/components/sheetContainerConfig/Index';
import ListComConfig from '@/components/listConfig/index.vue'
import AxisListConfig from '@/components/axisListConfig/index.vue'
import StateMachine from '@/components/stateMachine/Index';
import NetDiskConfig from '@/components/netDiskConfig/Index';
import ConfigPageConfig from '@/components/configPageConfig/Index';
import SwiperConfig from '@/components/swiperConfig/Index';
import CalendarConfig from '@/components/calendarConfig/Index';
import SubitemTemplateConfig from '@/components/subitemTemplateConfig/Index';
// 页面配置
import ListConfig from '@/pageTemplateConfig/listConfig/Index';
// 模板配置
import PageTemplate from '@/components/pageTemplate/Index';
import { Aside, Container, Header, Main, TabPane, Tabs } from 'element-ui';
import { mapState } from 'vuex';
import BindValue from '@/components/bindValue/Index';
// import GlobalState from '@/components/globalState/Index';
import TableConfig from '@/components/tableConfig/Index';
import ModelConfig from '@/components/modelConfig/Index';
import MonitorConfig from '@/components/monitorConfig/Index';
import TowerConfig from '@/components/towerConfig/Index';
import FormContainerConfig from '@/components/formContainerConfig/Index';
import { pageFromDetail } from '@/api/saas'
import FormOnlyOneConfig from '@/components/formOnlyOneConfig/Index';
import { isJSONStr } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import CustomComponentsAttrs from '@/components/customComponentsAttrs/Index';
import LoopConfig from '@/components/loopConfig/Index';
/* 移动端自定义表单渲染配置 */
import FormMobileConfig from '@/components/formMobileConfig/Index'
/* 移动端日历渲染配置 */
import CalendarMobileConfig from '@/components/calendarMobileConfig/Index'
/* 移动端模型渲染取值配置 */
import MobileModelConfig from '@/components/mobileModelConfig/Index'
/* 移动端全景渲染取值配置 */
import MobilePanoConfig from '@/components/mobilePanoConfig/Index'
/* 模型相关配置 */
import ModelSourceConfig from '@/components/modelViewerConfig/ModelSourceConfig';
import ModelMarkerConfig from '@/components/modelViewerConfig/ModelMarkerConfig';
import ModelPartConfig from '@/components/modelViewerConfig/ModelPartConfig';
import ModelTreeConfig from '@/components/modelViewerModuleConfig/ModelTreeConfig';
/* GIS相关配置 */
import GISSourceConfig from '@/components/GISViewerConfig/GISSourceConfig';
import GISModuleConfig from "@/components/GISModuleConfig/GISModuleConfig";
import DropDownConfig from '@/components/dropDownConfig'
import ModelOpacityConfig from '@/components/modelViewerConfig/ModelOpacityConfig';
//绑定组件配置
import BindComponentConfig from '@/components/bindComponentConfig/index.vue';
//进度计划配置
import GanttConfig from '@/components/ganttConfig/index.vue';

export default {
	name: 'ModifyPage',
	components: {
		'el-header': Header,
		'el-container': Container,
		'el-main': Main,
		'el-aside': Aside,
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		Toolbar,
		ComponentsList,
		LayerList,
		DataView,
		Attribute,
		SelectLayout,
		NormalPage,
		ConfigEvents,
		BindValue,
		// GlobalState,
		FilterConfig,
		DialogConfig,
		TableConfig,
		TabsConfig,
		ScreenTabsConfig,
		ModelConfig,
		MonitorConfig,
		TowerConfig,
		FormContainerConfig,
		SelectPersonConfig,
		FormOnlyOneConfig,
		TreeConfig,
		ListConfig,
		AxisListConfig,
		FlowConfig,
		MagicConfig,
		ChartConfig,
		NetDiskConfig,
		ConfigPageConfig,
		PageTemplate,
		SheetContainerConfig,
		CustomComponentsAttrs,
		StateMachine,
		ListComConfig,
		SubitemTemplateConfig,
		MobilePage, // 移动端页面画布渲染
		SwiperConfig,
		FormMobileConfig,
		CalendarConfig,
		CalendarMobileConfig,
		MobileModelConfig,
		MobilePanoConfig,
		ModelSourceConfig,
		ModelMarkerConfig,
		ModelPartConfig,
		ModelTreeConfig,
		GISSourceConfig,
		GISModuleConfig,
		LoopConfig,
		DropDownConfig,
		ModelOpacityConfig,
		BindComponentConfig,
		GanttConfig
	},
	data() {
		return {
			// 左侧tab componentList 组件 layerList 图层
			leftTab: 'componentList',
			// 显示左侧边
			showLeft: true,
			// 显示右侧边
			showRight: true,
			// 右侧tab attribute 属性 events 事件 value 值绑定 globalState 全局状态
			rightTab: 'attribute',
			// 是否需要配置 当新建页面时候需要
			needConfigLayout: false,
			// 是否显示选择样式
			showSelectLayout: false,
			// 不需要值绑定的组件
			excludeValue: ['CommonForm', 'CommonDialog', 'CommonFormContainer', 'FormOnlyOne', 'CommonFlow',
				'CommonMagic', 'CommonChart', 'CommonSheetContainer', 'MilestoneCom', 'CommonProgressCurve', 'ConsoleSignCont',
				'CommonOverallProgress', 'PanoCom', 'CommonStatisticsAnalyze', 'IframeCom', 'RouteTop', 'MobileListTable','AddButton',
				'CommonSwiper', 'FormMobileDetail', 'FormMobileAdd', 'CommonSubitemTemplate','ConsoleForm','ConsoleFilter',
				'DateCom', 'DayCom', 'TimeCom', 'Hyperlinks', 'GradientBackground', 'CommonRandomText', 'CommonDateButton'],
			customComponents: ['MilestoneCom', 'CommonProgressCurve', 'CommonOverallProgress', 'PanoCom',
				'CommonStatisticsAnalyze','IframeCom', 'CommonSwiper'], // 自定义或手写的组件需要注入外部参数
			loading: true,
			// 模板
			showTemplates: false,
			// 是否为h5页面
			isMobile: false
		};
	},
	provide() {
    return {
      EDITOR_pageUUID: null
    }
  },
	created() {
		this.$store.commit('clearComponents');
		sessionStorage.removeItem('isModifySite');
		this.getComponentData();
		const componentData = sessionStorage.getItem('componentData');
		const editorType = this.$router.query?.type || sessionStorage.getItem('editorType');
		const canvasStyle = sessionStorage.getItem('canvasStyle');
		const statusConfig = sessionStorage.getItem('statusConfig');
		if (componentData) {
			this.$store.commit('setComponentData', JSON.parse(componentData));
		}
		if (editorType && canvasStyle) {
			this.$store.commit('createPage', {
				editorType,
				editorConfig: JSON.parse(canvasStyle),
				statusConfig: JSON.parse(statusConfig)
			});
		}
	},
	computed: {
		...mapState([
			'editorType', // 编辑的页面类型
			'isClickComponent', // 是否点击组件
			'componentData', // 图层列表
			'curComponent' // 当前组件
		])
	},
	watch: {
		curComponent(newV, oldV) {
			if (!newV || !oldV || newV.id === oldV.id) return;
			this.rightTab = 'attribute';
		}
	},
	mounted() {
		eventBus.$on('usePageTemplate', () => {
			this.showTemplates = true;
		});
		/* h5页面重新渲染 */
		eventBus.$on('mobileRefreshPage', (pageUuid) => {
			this.getComponentData(pageUuid);
			const componentData = sessionStorage.getItem('componentData');
			const editorType = sessionStorage.getItem('editorType');
			const canvasStyle = sessionStorage.getItem('canvasStyle');
			const statusConfig = sessionStorage.getItem('statusConfig');
			if (componentData) {
				this.$store.commit('setComponentData', JSON.parse(componentData));
			}
			if (editorType && canvasStyle) {
				this.$store.commit('createPage', {
					editorType,
					editorConfig: JSON.parse(canvasStyle),
					statusConfig: JSON.parse(statusConfig)
				});
			}
		});
	},
	methods: {
		/**
		 * @desc: 获取编辑数据
		 */
		getComponentData(uuid) {
			const { pageUUID } = this.$route.query;
			if (!pageUUID) {
				this.closeEditor();
				return;
			}
			// 获取
			this.loading = true;
			// 是否为移动端页面
			this.isMobile = false
			pageFromDetail({
				__method_name__: 'dataList',
				page_uuid: uuid ? uuid : pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
				if (!res || res.status !== 200) {
					this.loading = false;
					this.closeEditor();
					return;
				}
				const { page_data, page_type } = res.data.data?.[0] || {};
				if (page_type === 'mobile') {
					this.isMobile = true
				}
				if (!page_data) {
					this.closeEditor();
					return;
				}
				const {
					editorType,
					editorConfig,
					componentData = [],
					statusConfig = []
				} = isJSONStr(page_data) ? JSON.parse(page_data) : page_data;
				if (statusConfig && Array.isArray(statusConfig) && statusConfig.length) {
					this.$store.commit('initPageCustomStatus', statusConfig);
				}
				this.$store.commit('setComponentData', componentData);
				this.$store.commit('createPage', {
					editorType,
					editorConfig,
					statusConfig
				});
				this.loading = false;
				// 使用模板填充
				if (!componentData.length && this.editorType === 'page') {
					this.$confirm('是否使用页面模板进行预填充?').then(() => {
						this.showTemplates = true;
					}).catch(() => {});
				}
			}).catch((err) => {
				console.log(err);
				this.loading = false;
				this.closeEditor();
			});
		},
		/**
		 * @desc: 根据表单类型获取对应的表单UUID
		 * @param {Array} arr 配置列表
		 * @param {String} type 类型
		 * @return {String} formUUID
		 */
		getUUIDs(arr, result = {}) {
			const TYPES = ['createData', 'dataInfo', 'updateData'];
			let resultObj = {
				...result
			};
			if (!arr || !Array.isArray(arr) || !arr.length) return '';
			for (let i = 0, len = arr.length; i < len;i++) {
				const { component = null, sourceConfig = [], contentConfig = {}, children = [] } = arr[i];
				if ((component === 'CommonDialog' || component === 'CommonDrawer') && sourceConfig.length && TYPES.includes(sourceConfig[0].type)) {
					resultObj[sourceConfig[0].type] = contentConfig || {};
				}
				if (component === 'CommonTableContainer') {
					const { database } = arr[i];
					resultObj['tableConfig'] = {
						objectUUID: database?.objectData?.uuid,
						viewUUID: database?.viewData?.uuid
					}
				}
				if (children && children.length) {
					resultObj = this.getUUIDs(children, resultObj);
				}
			}
			return resultObj;
		},
		/**
		 * @desc: 退出配置
		 */
		closeEditor() {
			this.$message.error('获取页面配置失败!');
			const isModifySite = sessionStorage.getItem('isModifySite');
			if (isModifySite) {
				this.$store.commit('clearComponents');
				// 需要将状态值清空
				this.$router.replace({
					path: '/index'
				});
			} else {
				window.parent.postMessage({ flag: true, type: 'mobilemenu' }, '*');
			}
		},
		/**
		 * @desc: 清除当前选中组件
		 */
		clearCurComponent(e) {
			if (!this.isClickComponent) {
				this.$store.commit('setCurComponent', { component: null, index: null });
			}
			// 0 左击 1 滚轮 2 右击
			// 右击关闭菜单
			if (e.button != 2) {
				this.$store.commit('hideEditorMenu');
			}
		},
		/**
		 * @desc: 切换左侧菜单
		 */
		onToggleLeft() {
			this.showLeft = !this.showLeft;
		},
		/**
		 * @desc: 切换右侧菜单
		 */
		onToggleRight() {
			this.showRight = !this.showRight;
		},
		onGlobalAttr(e) {
			this.$store.commit('setCurComponent', { component: null, index: null });
			// 0 左击 1 滚轮 2 右击
			// 右击关闭菜单
			if (e.button != 2) {
				this.$store.commit('hideEditorMenu');
			}
			this.rightTab = 'attribute';
		}
	},
	beforeDestroy() {
		eventBus.$off('usePageTemplate');
		// 清除页面状态
		this.$store.commit('clearPageCustomStatus');
	}
};
</script>

<style lang="less" scoped>
@putHeight: 16px;
.modify-page {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: #fff;
	.el-header {
		box-sizing: border-box;
		border-bottom: 1px solid #ddd;
	}
	.el-container {
		flex: 1;
		width: 100%;
		overflow: hidden;
		.el-aside {
			position: relative;
			box-sizing: border-box;
			padding: 0 10px 10px 10px;
			overflow: initial;
			@height: 16px;
			transition: width 0.3s;
			.page-config{
				position: absolute;
				top: 0;
				left: -75px;
				border: 1px solid @theme;
				padding: 2px;
				border-radius: 4px;
				font-size: 12px;
				color: @theme;
				z-index: 1000;
				&:hover{
					background: @theme;
					color: #fff;
				}
			}
			.put-away {
				height: @putHeight;
				width: 18px;
				position: absolute;
				z-index: 1000;
				line-height: @putHeight;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				top: 3px;
				cursor: pointer;
				font-size: 12px;
				color: @theme;
				font-weight: bold;
				text-align: center;
				&:hover {
					background: @theme;
					color: #fff;
				}
				&.left {
					right: -18px;
				}
				&.right {
					left: -18px;
				}
			}
			:deep(.el-tabs)  {
				height: 100%;
				width: 100%;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				.el-tab-pane {
					height: 100%;
					width: 100%;
					overflow-y: auto;
				}
				.el-tabs__content {
					flex: 1;
					// overflow: visible;
				}
			}
		}
		.el-main {
			background: #f5f5f5;
			overflow: hidden;
			.content {
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				overflow: auto;
			}
		}
	}
	:deep(.pane-dialog) {
		overflow-y: auto;
	}
}
</style>
