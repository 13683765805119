<!--
 * @Description: 显示配置
 * @Author: luocheng
 * @Date: 2021-10-14 14:53:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-26 10:15:23
-->
<template>
    <div class="view-config" v-if="curComponent">
        <header class="header">
            <p class="item-title">监控显示配置</p>
            <el-button type="primary" @click="onConfirm">确认操作</el-button>
            <a
                href="javascript:;"
                class="header-btn"
                @click="toggleShow = !toggleShow"
            >
                {{ toggleShow ? "收起" : "展开" }}
            </a>
        </header>
        <div class="configs" v-show="toggleShow">
            <el-form :model="viewConfig" label-width="85px">
                <el-form-item label="监控类型">
										<el-select
                        v-model="viewConfig.type"
                        placeholder="请选择"
                        size="mini"
                    >
                        <el-option label="萤石" value="ys7"></el-option>
												<el-option label="乐橙" value="lechange"></el-option>
												<el-option label="其他" value="other"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="播放地址">
                    <el-input
                        v-model="viewConfig.monitor_path"
                        placeholder="请输入播放地址"
                        clearable
												rows="4"
												type="textarea"
                        size="mini"
                    ></el-input>
                </el-form-item>
                <el-form-item  label="标记背景">
                    <el-color-picker
                        v-model="viewConfig.signBg"
                    ></el-color-picker>
                </el-form-item>
                <el-form-item  label="标记颜色">
                    <el-color-picker
                        v-model="viewConfig.signCol"
                    ></el-color-picker>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { ColorPicker } from "element-ui";

export default {
    name: "ViewConfig",
    data() {
        return {
            toggleShow: true,
            // 显示配置
            viewConfig: {
								type:'ys7',
								monitor_path:'',
                signBg: "#FFFFFF",
                signCol: "#409EFF",
            },
        };
    },
    components: {
        "el-color-picker": ColorPicker,
    },
    computed: {
        ...mapState(["curComponent"]),
    },
    created() {
        this.viewConfig = this.curComponent && this.curComponent.monitorConfig;
    },
    methods: {
        /**
         * @desc: 确认
         */
        onConfirm() {
            this.$store.commit("modifyComponent", {
                component: {
                    ...this.curComponent,
                    monitorConfig: this.viewConfig,
                },
                containerId: null,
                isModify: true,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.view-config {
    padding: 10px 0;
    .header {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
				position: relative;
        .item-title {
            flex: 1;
            text-align: left;
            padding-left: 10px;
            line-height: 32px;
        }
        .header-btn {
            margin-left: 5px;
            color: @theme;
            line-height: 32px;
        }
    }
		.header::before{
			content: '';
			position: absolute;
			left:0;
			top:9px;
			width: 3px;
			height: 14px;
			background-color: @theme;
		}
    .configs {
        .el-form-item {
            margin-bottom: 10px;
            .el-input {
                width: 180px;
            }
            .el-select {
                width: 180px;
            }
        }
    }
}
</style>