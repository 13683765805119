<!--
 * @Description: 选择数据对象
 * @Author: luocheng
 * @Date: 2021-09-29 18:51:47
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-18 09:46:59
-->
<template>
	<div class="select-view-uuid">
		<el-select
			v-model="uuid"
			filterable
			remote
			:placeholder="placeholder"
			:remote-method="onRemoteView"
			:loading="loading"
			@change="onEmit"
		>
			<el-option
				v-for="item in viewList"
				:key="item.id"
				:label="item.name"
				:value="item.uuid"
			></el-option>
		</el-select>
	</div>
</template>

<script>
import { getViews } from '@/apis/data/index';

export default {
	name: 'SelectViewUUID',
	props: {
		value: {
			type: String,
			default: ''
		},
		// 说明
		placeholder: {
			type: String,
			required: false,
			default: '请选择'
		},
		// 对象UUID
		objectUUID: {
			type: String,
			required: true,
			default: ''
		}
	},
	data() {
		return {
			uuid: '',
			loading: false,
			viewList: [],
			parentUUID: ''
		};
	},
	created() {
		this.uuid = this.value || '';
		this.parentUUID = this.objectUUID || '';
		this.onRemoteView('');
	},
	watch: {
		objectUUID: {
			handler() {
				if (!this.objectUUID || this.objectUUID !== this.parentUUID) {
					this.uuid = '';
					this.onEmit();
				}
				this.onRemoteView('');
			},
			deep: true,
			immediate: false
		}
	},
	methods: {
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteView(name) {
			this.loading = true;
			getViews({
				object_uuid: this.objectUUID,
				name,
				size: 200
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 提交数据
		 */
		onEmit() {
			this.$emit('input', this.uuid);
		}
	}
};
</script>

<style lang="less" scoped>
.select-view-uuid{
  width: 100%;
}
</style>