<!--
 * @Description: 单个状态
 * @Author: luocheng
 * @Date: 2022-06-14 18:00:26
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-16 10:37:17
-->
<template>
  <div class="state-item">
    <el-form :model="configForm" label-width="100px">
      <el-form-item label="状态字段">
        <el-select v-model="configForm.code" placeholder="请选择状态字段" @change="onCode">
          <el-option v-for="item in statusConfig"
            :key="item.code"
            :value="item.code"
            :label="item.code + '【' + (item.type === 1 ? '页面' : '全局') + '状态】'"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="取值类型">
        <el-select v-model="configForm.type" placeholder="请选择取值类型" @change="onEmit">
          <el-option label="固定值" value="fixed"></el-option>
          <el-option label="组件来源" value="component"></el-option>
        </el-select>
      </el-form-item>
      <!-- 固定值 -->
      <el-form-item label="固定值" v-if="configForm.type === 'fixed'">
        <el-input-number v-model="configForm.fixedValue" placeholder="请输入固定值"
          v-if="currentConfig && currentConfig.valueType === 'number'"
           @input="onEmit"
        ></el-input-number>
        <el-input v-model="configForm.fixedValue" placeholder="请输入固定值" v-else @input="onEmit"></el-input>
      </el-form-item>
      <!-- 组件来源 -->
      <template v-else-if="configForm.type === 'component'">
        <el-form-item label="来源组件">
          <el-select v-model="configForm.componentId" placeholder="请选择组件来源" @change="onEmit">
            <el-option v-for="item in componentList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组件取值" v-if="configForm.componentId">
          <el-select v-model="configForm.componentValueOrigin" clearable @change="onValueOrigin">
            <el-option label="组件暴露数据" value="resolveData"></el-option>
            <el-option label="绑定值(固定)" value="propValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取值字段" v-if="configForm.componentValueOrigin === 'resolveData' && fieldList">
          <el-select v-model="configForm.resolveKey" placeholder="请选择取值字段" @change="onEmit">
            <el-option v-for="(key, value) in fieldList"
              :key="key"
              :label="key"
              :value="value">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="逻辑判断" v-if="configForm.logics">
        <LogicConfig :config="configForm.logics" @updateConfig="onUpdateBefore"></LogicConfig>
      </el-form-item>
    </el-form>
    <section class="remove">
      <i class="el-icon-delete" @click="onRemove"></i>
    </section>
  </div>
</template>

<script>
import { InputNumber } from 'element-ui';
import { flatTree, unique } from '@/utils/tools';
// 状态列表
// type: 1, // 1 页面状态， 2 全局状态
// code: '', // 状态code
// valueType: 'string', // 值类型， 数字：number， 字符串： string， 判断值： boolean， 时间日期：date ，复杂对象： object，数组：array
// useDefault: false,
// defaultValue: '', // 默认值
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import LogicConfig from '../logic/Index';

export default {
  name: 'StateItem',
  components:{
    LogicConfig,
    'el-input-number': InputNumber
  },
  props: {
    index: {
      type: Number,
      required: true,
      default: 0
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      configForm: {
        // code: '', // 字段
        // type: '', // 取值类型， 固定值： fixed, 组件来源: component,  逻辑产出值： fromLogic 
        // fixedValue: '', // 固定值
        // componentId: '', // 组件ID
        // componentValueOrigin: '', // 组件取值值来源，resolveData: 暴露数据， propValue:  绑定值
        // resolveKey: '', // 暴露数据的取值key
      },
      // 当前配置
      currentConfig: null,
      // 可选组件列表
      componentList: [],
      // 字段列表
      fieldList: []
    }
  },
  computed: {
    ...mapState([
      'statusConfig',
      'originComponentData',
      'componentData',
      'sContainer'
    ])
  },
  created() {
    this.configForm = {
      ...this.config
    };
    if (this.sContainer) {
      this.componentList = flatTree(this.originComponentData || [], 'children', null, []);
    }
    this.componentList = unique(this.componentList.concat(flatTree(this.componentData, 'children', null, [])));
  },
  methods: {
    /**
     * @desc: 选择字段
     */
    onCode(code) {
      this.currentConfig = this.statusConfig.find(ele => ele.code === code);
      this.onEmit();
    },
    /**
     * @desc: 删除
     */
    onRemove() {
      this.$emit('remove', this.index)
    },
    /**
     * @desc: 切换取值来源
     */
    onValueOrigin(val) {
      this.fieldList = null;
      this.onEmit();
      if (val !== 'resolveData') {
        return;
      }
      // 去组建暴露数据值
      let component = null;
      if (this.sContainer) {
        component = getComponentById(this.originComponentData, this.configForm.componentId);
      } else {
        component = getComponentById(this.componentData, this.configForm.componentId);
      }
      if (component) {
        this.fieldList = component.metadata || {};
      }
    },
    /**
     * @desc: 更新逻辑配置
     * @param {Object} data
     */
    onUpdateBefore(data) {
      this.configForm = {
        ...this.configForm,
        logics: data.config
      };
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        config: this.configForm,
        index: this.index
      });
    }
  }
}
</script>

<style lang="less" scoped>
.state-item{
  display: flex;
  .el-form{
    flex: 1;
  }
}
</style>