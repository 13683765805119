<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2021-10-09 16:50:30
 * @LastEditors: zx
 * @LastEditTime: 2022-06-15 15:59:29
-->
<template>
  <div class="filter-action-config">
    <article class="action-content">
      <section  v-for="(item, index) in configList" :key="index" class="action-box">
        <el-form :model="item" class="action-item" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="item.label" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-input v-model="item.type" placeholder="请输入类型" :disabled="!item.isCustom"></el-input>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-switch v-model="item.show"></el-switch>
          </el-form-item>
          <el-form-item label="按钮类型">
            <el-select v-model="item.btnType" clearable>
              <el-option value="" label="默认按钮"></el-option>
              <el-option value="primary" label="主要按钮"></el-option>
              <el-option value="success" label="成功按钮"></el-option>
              <el-option value="warning" label="警告按钮"></el-option>
              <el-option value="danger" label="危险按钮"></el-option>
              <el-option value="info" label="信息按钮"></el-option>
              <el-option value="text" label="文本按钮"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图标">
            <el-select v-model="item.icon">
              <el-option v-for="(icon, index) in iconList" :key="index" :value="icon">
                <div :class="icon">
                  {{ icon }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 架构限制 -->
          <el-form-item label="架构限制"  v-if="item.archiLimit">
            <SetArchiLimit v-model="item.archiLimit"></SetArchiLimit>
          </el-form-item>
          <!-- 权限 -->
          <el-form-item label="权限设置"  v-if="item.permission">
            <SetPermissions v-model="item.permission"></SetPermissions>
          </el-form-item>
          <!-- UI判断逻辑 -->
          <UILogic v-model="item.UILogic"></UILogic>
        </el-form>
        <i class="el-icon-delete remove" v-if="item.isCustom" @click="onRemove(item, index)"></i>
      </section>
    </article>
    <footer>
      <el-button type="warning" @click="onSync" size="mini">同步配置</el-button>
      <el-button type="success" @click="onPlus" size="mini">新增</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确认</el-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { elementIcons } from '@/utils/icons';
import form from '@/custom-component/component-config/form';
import SetArchiLimit from '@/components/common/SetArchiLimit';
import SetPermissions from '@/components/common/SetPermissions';
import UILogic from '@/components/common/UILogic';

export default {
  name: 'ActionConfig',
  components: {
    SetArchiLimit,
    SetPermissions,
    UILogic
  },
  data() {
    return {
      configList: [], // 配置列表
      iconList: [] // 图标列表
    }
  },
  computed: {
    ...mapState([
      'curComponent', // 当前组件
    ])
  },
  created() {
    this.configList = this.curComponent.actionConfig || [];
    this.configList = this.configList.map(ele => {
      return {
        UILogic: null,
        ...ele
      }
    })
    this.iconList = elementIcons;
    this.iconList.unshift('无');
  },
  methods: {
    /**
     * @desc: 确认配置
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
        component: {
          ...this.curComponent,
          actionConfig: this.configList
        },
        containerId: null,
        isModify: true
      });
    },
    /**
     * @desc: 新增按钮
     */
    onPlus() {
      this.configList.push({
        isCustom: true,
        label: '',
        type: 'custom',
        btnType: '',
        icon: '',
        show: false,
        eventList: [],
        archiLimit: [],
        permission: [],
        UILogic: null
      })
    },
    /**
     * @desc: 移除
     */
    onRemove(item, index) {
      if (!item.isCustom) return;
      this.$confirm('是否确认移除操作？').then(() => {
        this.configList.splice(index, 1);
      })
    },
    /**
     * @desc: 同步配置
     */
    onSync() {
      this.$confirm('是否确认同步配置？(谨慎使用可能出现新老配置不兼容情况！)').then(() => {
        const formConfig = form.componentList.find(ele => ele.component === 'CommonForm');
        const { actionConfig = [] } = formConfig;
        this.configList = this.configList.map(ele => {
          return {
            ...actionConfig[0],
            ...ele
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.filter-action-config{
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;
  .action-box{
    display: flex;
    .remove{
      padding-left: 10px;
      line-height: 200px;
      color: @dangerColor;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }
    .el-form{
      padding: 5px;
      box-sizing: border-box;
    }
  }
  .action {
    display: flex;
    padding: 10px 0;
    .config-title {
      flex: 1;
      text-align: left;
      line-height: 28px;
    }
    .toggle {
      margin-left: 10px;
      font-size: 14px;
      line-height: 28px;
      color: @theme;
    }
  }
  .action-content{
    .action-item{
      margin-bottom: 10px;
      box-sizing: border-box;
      border: 1px solid #f2f3f5;
      border-radius: 4px;
      padding: 5px;
      .el-form-item{
        margin-bottom: 8px;
        .el-switch{
          margin-top: 6px;
          float: left;
        }
      }
    }
  }
}
</style>