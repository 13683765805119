<!--
 * @Description: 特殊样式
 * @Author: luocheng
 * @Date: 2021-08-30 16:05:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-11 15:47:37
-->
<template>
	<div class="special-style">
		<header class="actions">
			<el-button type="warning" size="mini" @click="onSyncConfig">同步最新配置项</el-button>
		</header>
		<el-form :inline="true">
			<el-form-item :label="commonConfig[key].label" v-for="key in configKeys" :key="key" label-width="100px" v-show="commonConfig[key].isShow">
				<span slot="label" v-if="commonConfig[key].desc">
					<el-popover
						placement="top-start"
						title="提示"
						width="400"
						:center="true"
						:content="commonConfig[key].desc"
						trigger="click">
						<span slot="reference">
							<span>{{ commonConfig[key].label }}</span>
							<i class="el-icon-question"></i>
						</span>
					</el-popover>
				</span>
				<el-switch v-model="curComponent.specialStyle[key]" v-if="commonConfig[key].type === 'switch'"></el-switch>
				<el-select v-model="curComponent.specialStyle[key]" v-else-if="commonConfig[key].type === 'select'">
					<el-option
						v-for="item in commonConfig[key].options"
						:key="item.value"
						:value="item.value"
						:label="item.label"
					></el-option>
				</el-select>
				<el-input-number
					v-else-if="commonConfig[key].type === 'number'"
					v-model="curComponent.specialStyle[key]"
					:placeholder="'请输入' + commonConfig[key].label">
				</el-input-number>
				<el-input v-else
					v-model="curComponent.specialStyle[key]"
					:placeholder="'请输入' + commonConfig[key].label">
				</el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { commonAttr } from '@/custom-component/component-list';
import { Popover, InputNumber } from 'element-ui';

export default {
	name: 'SpecialStyle',
	components: {
		'el-popover': Popover,
		'el-input-number': InputNumber
	},
	data() {
		return {
			// 属性
			commonConfig: {
				isCollector: {
					label: '设为收集者',
					isShow: true,
					type: 'switch'
				},
				flexDirection: {
					label: '主轴方向',
					type: 'select',
					isShow: true,
					options: [
						{
							value: 'row',
							label: '水平轴，X轴'
						},
						{
							value: 'row-reverse',
							label: '水平轴反向，X轴'
						},
						{
							value: 'column',
							label: '纵轴，Y轴'
						},
						{
							value: 'column-reverse',
							label: '纵轴反向，Y轴'
						}
					]
				},
				flexWrap: {
					label: '自动换行',
					type: 'switch',
					isShow: true
				},
				justifyContent: {
					label: '间距',
					type: 'select',
					isShow: true,
					options: [
						{
							value: 'flex-start',
							label: '左侧对齐,无间距'
						},
						{
							value: 'flex-end',
							label: '右侧对齐,无间距'
						},
						{
							value: 'center',
							label: '居中对齐,无间距'
						},
						{
							value: 'space-around',
							label: '环绕间距,首尾取半'
						},
						{
							value: 'space-evenly',
							label: '环绕相等间距,所有间距相等'
						},
						{
							value: 'space-between',
							label: '中间相等间距,首尾贴边'
						}
					]
				},
				fixedSize: {
					label: '固定尺寸(大屏)',
					type: 'switch',
					isShow: sessionStorage.getItem('editorType') === 'dataview' ||  sessionStorage.getItem('editorType') === 'console'
				},
				isAbsolute: {
					label: '灵活布局',
					type: 'switch',
					desc: '开启为零活布局后，此元素将变得不可控并可以随意决定在父级组件内的位置',
					isShow: sessionStorage.getItem('editorType') === 'page' || sessionStorage.getItem('editorType') === 'mobile'
				},
				absoluteTop: {
					label: '上边距',
					type: 'string',
					desc: '开启灵活布局后有效',
					isShow: sessionStorage.getItem('editorType') === 'page' || sessionStorage.getItem('editorType') === 'mobile'
				},
				absoluteLeft: {
					label: '左边距',
					type: 'string',
					desc: '开启灵活布局后有效',
					isShow: sessionStorage.getItem('editorType') === 'page' || sessionStorage.getItem('editorType') === 'mobile'
				},
				zIndex: {
					label: '图层层级',
					type: 'number',
					desc: '开启灵活布局后有效，一般为10, 100, 1000等数量级递增',
					isShow: sessionStorage.getItem('editorType') === 'page' || sessionStorage.getItem('editorType') === 'mobile'
				}
			}
		};
	},
	computed: {
		...mapState([
			'curComponent' // 当前组件
		]),
		// 配置
		configKeys() {
			return this.curComponent.specialStyle ? Object.keys(this.curComponent.specialStyle) : [];
		}
	},
	mounted() {
		console.log(this.configKeys, 'configKeysconfigKeysconfigKeys')
	},
	methods: {
  /**
   * @desc: 同步配置
   */
		onSyncConfig() {
			this.$confirm('是否确认同步最新配置？', '提示').then(() => {
				let newConfig = {
					...this.curComponent.specialStyle
				};
				let specialStyle = commonAttr.specialStyle;
				if (!specialStyle) {
					return false;
				}
				for (let key in specialStyle) {
					/* eslint-disable */ 
					if (!this.configKeys.includes(key)) {
						newConfig = {
							...newConfig,
							[key]: this.commonConfig[key].type === 'switch' ? false : '',
						}
					}
				}
				this.$store.commit('modifyComponent', {
					component: {
						...this.curComponent,
						specialStyle: newConfig
					},
					containerId: null,
					isModify: true
				});
			}).catch(() => {});
		}
	}
};
</script>

<style lang="less" scoped>
.special-style {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.actions{
		text-align: left;
	}
	:deep(.el-form)  {
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		overflow: hidden;
		overflow-y: auto;
		.el-form-item {
			display: flex;
			margin-bottom: 10px;
			padding-left: 10px;
			box-sizing: border-box;
			border-radius: 4px;
			cursor: pointer;
			&:last-of-type{
				margin: 0;
			}
			&:hover {
				background-color: #f5f7fa;
				.el-form-item__label {
					color: @theme;
				}
			}
			.el-form-item__label {
				width: 100px;
				text-align: left;
				cursor: pointer;
			}
			.el-form-item__content {
				flex: 1;
				.el-input {
					.el-input__inner {
						text-align: left;
					}
				}
			}
			.value-box {
				width: 100%;
				display: flex;
				.el-input {
					margin-left: 10px;
				}
			}
		}
	}
}
</style>
