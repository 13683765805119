<!--
 * @Author: zqy
 * @Date: 2022-06-22 13:46:03
 * @LastEditTime: 2022-07-21 11:08:23
 * @Description: 移动端顶部路由配置
 * @LastEditors: zx
-->
<template>
  <div class="title-config">
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="标题类型">
        <el-select v-model="formData.topTitleType" placeholder="请选择">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题名称" v-if="formData.topTitleType === 'onlyTitle'">
        <el-input v-model="formData.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <template v-if="formData.topTitleType === 'tab'">
        <div v-for="(item,index) in formData.tabs" :key="'tab'+index" class="tab-item"> 
          <div class="input-wrap">
            <!-- <el-form-item label="选择作用组件">
              <el-select @change="changeSelect($event,index)" v-model="item.targetComponent" placeholder="请选择">
                <el-option
                  v-for="item in targetList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item :label="`标题${index+1}`">
              <el-input v-model="item.title" :placeholder="`请输入标题${index+1}`"></el-input>
            </el-form-item>
            <el-form-item :label="`key值${index+1}`">
              <el-input v-model="item.type" :placeholder="`请输入key值${index+1}`"></el-input>
            </el-form-item>
            <el-form-item label="是否默认选中">
              <el-switch v-model="item.isDefault" @change="changeSwitch($event,index)"></el-switch>
            </el-form-item>
            <!-- <el-form-item label="事件绑定">
              <el-button type="primary" size="mini" @click="onShowSelect(item)">绑定事件</el-button>
            </el-form-item> -->
          </div>
          <div class="icon-wrap">
            <i v-if="index===0" class="el-icon-circle-plus" @click="addTabs"></i>
            <i style="color:red;" v-else class="el-icon-remove" @click="delTabs(index)"></i>
          </div>
        </div>
      </template>
      <el-form-item>
        <el-button class="confirm-btn" type="primary" @click="confirmConfig">确认配置</el-button>
      </el-form-item>
    </el-form>
    <!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'tabEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { flatTree } from '@/utils/tools';
import SelectEvent from '@/components/events/SelectEvent';

// page62171a5f00740
export default {
  components:{
    SelectEvent,
  },
  data(){
    return{
      formData:{
        topTitleType:'tab',
        title:'标题',
        tabs:[
          {
            // tab:'',
            type:'',
            title:'',
            targetComponent:'',
            isDefault:false
          }
        ]
      },
      typeOptions:[
        {
          label:'tab切换类型',
          value:'tab',
        },
        {
          label:'单标题类型',
          value:'onlyTitle',
        },
      ],
      targetList:[],
      // 显示配置抽屉
			showSelect: false,
			// 当前配置的按钮
			partData: null,
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData', // 组件数据
      'sContainer',
      'originComponentData'
    ]),
  },
  created() {
    if (this.sContainer) {
			this.targetList = flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, []);
		} else {
			this.targetList = flatTree(this.componentData, 'children', {
					label: 'name',
					value: 'id'
				}, [])
		}
  },
  methods:{
    /**
		 * @desc: 显示配置事件表单
		 * @param {Object} tabData 按钮配置数据
		 */
		onShowSelect(tabData) {
			if (!tabData) return;
			this.partData = tabData;
			this.showSelect = true;
		},
    changeSelect(data,i){
      this.formData.tabs[i].tab = data
    },
    changeSwitch(bol,i){
      if(bol){
        this.formData.tabs.map((item,index)=>{
          if(index!==i){
            item.isDefault = false;
          }
        })
      }
    },
    addTabs(){
      this.formData.tabs.push(
        {
          tab:'',
          title:'',
          targetComponent:'',
          isDefault:false
        }
      )
    },
    delTabs(i){
      this.formData.tabs.splice(i,1)
    },
    confirmConfig(){
      let copyCommponents = {
        ...this.curComponent,
        ...this.formData
      }
      copyCommponents.propValue = this.formData.title
      copyCommponents.tabConfig = this.formData.tabs
      this.$store.commit("modifyComponent", {
        component: {
          ...copyCommponents,
          titleConfig:this.formData,
        },
        containerId: null,
        isModify: true,
      })
      this.$message.success('保存成功!');
    }
  },
  mounted(){
    this.formData.title = this.curComponent.propValue
    if (this.curComponent.titleConfig) {
      this.formData = this.curComponent.titleConfig
    }
  }
}
</script>
<style lang="less" scoped>
.tab-item{
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .input-wrap{
    flex: 1;
    text-align: left;
  }
  .icon-wrap{
    padding-left: 10px;
    font-size: 24px;
  }
}
.confirm-btn{
  margin-top: 10px;
}
</style>