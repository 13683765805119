<!--
 * @Description: 选择数据对象
 * @Author: luocheng
 * @Date: 2021-11-17 17:10:42
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-17 18:38:40
-->
<template>
  <div class="select-object">
    <el-select
    v-model="uuid"
    filterable
    remote
    reserve-keyword
    placeholder="请输入关键词"
    :remote-method="onRemoteObject"
    @change="onEmit"
    :loading="loading">
      <el-option v-for="obj in objectList" :key="obj.id" :label="obj.name" :value="obj.uuid"></el-option>
  </el-select>
  </div>
</template>

<script>
import { getObjects } from '@/apis/data/index';

export default {
  name: 'SelectObject',
  props: {
    // 绑定值
    value: {
      type: [String, Number],
      default: '',
      required: true
    }
  },
  data() {
    return {
      loading: false,
      objectList: [],
      uuid: ''
    }
  },
  created() {
    this.uuid = this.value || '';
    this.onRemoteObject('');
  },
  methods: {
     /**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.loading = true;
			getObjects({ name }).then(res => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.objectList = [];
					this.loading = false;
				});
		},
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('input', this.uuid);
    }
  }
}
</script>

<style lang="less" scoped>
.select-object{
  width: 100%;
}
</style>