<!--
 * @Author: ttheitao
 * @Description: Excel绑定组件
 * @Date: 2022-08-12 09:24:30
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-12 13:40:44
-->
<template>
  <Entry
    loadType="create"
    :type="type"
    :elementId="elementId"
    :excelUuid="uuid"
    :needBtn="true"
    @close="close"
  ></Entry>
</template>

<script>
import BaseMixin from './baseMixin.js';
import Entry from '@/custom-component/common/Entry.vue';
import Tool from '../../components/x6Graph/js/Tool.js';

export default {
  name: 'GlobalExcel',
  mixins: [BaseMixin],
  components: {
    Entry,
  },
  computed: {
    type: function () {
      return this.config?.type || 'bind';
    },
    uuid: function () {
      return this.config?.uuid || '';
    },
  },
  data() {
    return {
      elementId: Tool.uuid2(32),
    };
  },
};
</script>
