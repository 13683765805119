<!--
 * @Description: 创建固定的选项或参数列表
 * @Author: luocheng
 * @Date: 2021-11-23 17:39:01
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-23 17:56:07
-->
<template>
	<div class="create-fixed-option">
		<section
			class="item"
			v-for="(item, index) in optionList"
			:key="index"
		>
			<div class="item-content">
				<div class="row">
					<span class="label">code:</span>
					<el-input
						v-model="item.code"
						placeholder="请输入code"
						clearable
            size="mini"
            @input="onEmit"
					></el-input>
				</div>
				<div class="row">
					<span class="label">value:</span>
					<el-input
						v-model="item.value"
						placeholder="请输入value"
						clearable
            size="mini"
            @input="onEmit"
					></el-input>
				</div>
			</div>
			<div class="item-action">
				<i class="el-icon-remove-outline remove" @click="onRemove(index)" v-if="optionList.length"></i>
			</div>
		</section>
    <el-button type="primary" icon="el-icon-plus" @click="onPlus" size="mini">新增</el-button>
	</div>
</template>

<script>
export default {
	name: 'CreateFixedOptions',
	props: {
		value: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	data() {
		return {
			optionList: []
		};
	},
	created() {
		this.optionList = this.value || [];
	},
	methods: {
		/**
		 * @desc: 新增
		 */
		onPlus() {
			this.optionList.push({
				code: '',
				value: ''
			});
		},
		/**
		 * @desc: 移除
		 * @param {Number} index 需要移除的下标
		 */
		onRemove(index) {
			this.optionList.splice(index, 1);
		},
		/**
		 * @desc: 提交
		 */
		onEmit() {
			this.$emit('input', this.optionList);
		}
	}
};
</script>

<style lang="less" scoped>
.create-fixed-option {
	width: 100%;
  padding-left: 20px;
    box-sizing: border-box;
	.item {
		width: 100%;
		display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #f2f3f5;
    &:last-of-type{
      margin-bottom: 10px;
    }
		.item-content {
			flex: 1;
			.row {
				width: 100%;
				display: flex;
        &:first-of-type{
          margin-bottom: 5px;
        }
				.label {
					margin-right: 10px;
				}
				.el-input {
					width: 160px;
				}
			}
		}
    .item-action{
      box-sizing: border-box;
      padding-top: 5px;
      i{
        display: flex;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
        &.remove{
          color: @dangerColor
        }
        &.plus{
          margin-top: 5px;
          color: @theme;
        }
      }
    }
	}
}
</style>