<!--
 * @Description: 选择数据对象
 * @Author: luocheng
 * @Date: 2021-09-07 11:22:50
 * @LastEditors: zx
 * @LastEditTime: 2022-11-07 17:12:02
-->
<template>
	<div class="select-object" v-if="curComponent">
		<header class="header">
      <h4 class="title">选择数据对象</h4>
      <i class="el-icon-edit edit" @click="onSelectList"></i>
      <i class="el-icon-delete delete" @click="onRemoveObject" v-show="curComponent.database && curComponent.database.objectData"></i>
    </header>
		<div class="value-box" v-if="curComponent.database && curComponent.database.objectData">
			<p>
				<span class="label">名称:</span>
        <el-input  v-model="curComponent.database.objectData.name" disabled></el-input>
			</p>
			<p>
				<span class="label">uuid:</span>
        <el-input  v-model="curComponent.database.objectData.uuid" disabled></el-input>
			</p>
		</div>
		<!-- <section class="actions">
      <el-button  type="primary" @click="onSelectList">选择数据对象</el-button>
      <el-button  type="danger" @click="onRemoveObject" v-show="curComponent.database && curComponent.database.objectData">移除</el-button>
    </section> -->
		<!-- 选择对象 -->
		<el-drawer title="选择对象" :visible.sync="showList" :size="400" :direction="'ltr'" :destroy-on-close="true">
			<article class="database-box">
        <header class="search-box">
          <el-input  v-model="search" placeholder="输入关键字搜索" @change="onSearch"></el-input>
          <el-button  type="primary" @click="onSearch">搜索</el-button>
        </header>
				<el-table
          ref="objectTable"
					:data="objectList"
					border
					center
					style="width: 100%;"
					height="calc(97vh - 200px)"
					highlight-current-row
					@current-change="onCurrentChange"
				>
					<el-table-column prop="id" label="ID" width="80"></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<!-- <el-table-column prop="updated_at" label="创建时间"></el-table-column> -->
					<el-table-column prop="remark" label="说明" width="120"></el-table-column>
				</el-table>
				<el-pagination
					@size-change="onSize"
					@current-change="onPage"
					:current-page="+objectPager.page"
					:page-sizes="[15, 20, 50, 100, 200]"
					:page-size="+objectPager.size"
					layout="total, sizes, prev, pager, next"
					:total="+objectPager.total"
				>
				</el-pagination>
			</article>
      <footer class="action">
        <el-button  type="primary" @click="onConfirm">确认</el-button>
        <el-button  @click="showList = false">取消</el-button>
      </footer>
		</el-drawer>
	</div>
</template>

<script>
import { Pagination, Table, TableColumn } from 'element-ui';
import { mapState } from 'vuex';
// import { getFields } from '@/apis/data';
const _needFieldComponent = ['CommonForm', 'RouteTop', 'MobileSelectTree', 'ThreeTerminal', 'ConsoleList','ConsoleFilter','ConsoleTypeTree']
export default {
	name: 'SelectObject',
	components: {
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-pagination': Pagination
	},
	props:{
		//是否是外部传入的objectId
		outerParam:{
			type: Object,
			default: null
		}
	},
	data() {
		return {
			// 选择对象
			showList: false,
      // 搜索
      search: '',
      // 选中的项
      objData: null
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前组件
			'objectList', // 对象列表
			'objectPager' // 分页
		])
	},
  watch: {
    // 将空对像列表设置选中
    objectList: {
      handler() {
        this.setTableCurrent();
      },
      deep: true
    }
  },
  mounted() {
    if (!this.objectList || this.objectList.length === 0) {
      this.doSearch({
        ...this.objectPager,
        page: 1,
        name: ''
      });
    }
  },
	methods: {
		/**
		 * @desc: 选择对象
		 * @param {Object} objData 数据仓库对象
		 */
		onCurrentChange(objData) {
      this.objData = objData;
		},
    /**
     * @desc: 显示选择列表 
     */
    onSelectList() {
      this.showList = true;
      this.setTableCurrent();
    },
    /**
     * @desc: 移除所选数据对象
     */
    onRemoveObject() {
      this.$store.commit('removeDatabaseObject');
      this.$emit('removeObject')
    },
    /**
     * @desc: 确定选择
     */
    onConfirm() {
      this.showList = false;
      this.$store.dispatch('getDatabaseField', {
        needField: _needFieldComponent.includes(this.curComponent.component),
        objData: this.objData
      })
    },
    /**
     * @desc: 设置选中项
     */
    setTableCurrent() {
      this.$nextTick(() => {
        if (this.objData && this.objectList && this.objectList.length) {
          const index = this.objectList.findIndex(ele => ele.uuid === this.objData.uuid);
          if (index > -1) {
            this.$refs.objectTable.setCurrentRow(this.objectList[index]);
          }
        }
      });
    },
		/**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.doSearch({
        size,
        page: 1,
        name: this.search
			});
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.doSearch({
				...this.objectPager,
        page,
        name: this.search
			});
		},
    /**
     * @desc: 搜索
     */
    onSearch() {
      this.doSearch({
				...this.objectPager,
        page: 1,
        name: this.search
			});
    },
    /**
     * @desc: 获取数据
     * @param {Object} params 参数
     */
    doSearch(params) {
      this.$store.dispatch('getObjectList', params);
    }
	}
};
</script>

<style lang="less" scoped>
.select-object {
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid #f2f3f5;
	margin-top: 10px;
  padding-bottom: 10px;
  .header{
		display: flex;
		.title {
			flex: 1;
			padding: 5px;
		}
		i{
			font-weight: bold;
			line-height: 33px;
			font-size: 16px;
			box-sizing: border-box;
			padding: 0 5px;
			cursor: pointer;
			color: #409EFF;
      &.delete{
        color: @dangerColor;
      }
		}
	}
	.value-box {
		margin-top: 5px;
		padding-bottom: 5px;
		p {
			display: flex;
			line-height: 20px;
			font-size: 14px;
			padding-bottom: 5px;
			box-sizing: border-box;
			padding-left: 20px;
			.label {
				text-align: left;
        line-height: 32px;
        margin-right: 10px;
			}
			.el-input {
				flex: 1;
        text-align: left;
        word-break: break-all;
			}
		}
	}
  .actions {
    display: flex;
    .el-button{
      flex: 1;
    }
  }
  :deep(.el-drawer){
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .el-drawer__header{
      margin: 0;
      padding-bottom: 20px;
      span{
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .el-drawer__body{
      flex: 1;
      width: 100%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      .database-box {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        // height: calc(97vh - 165px);
        overflow: hidden;
        .search-box{
          display: flex;
          margin-bottom: 10px;
          .el-input{
            width: 200px;
            margin-right: 20px;
          }
        }
        .el-table {
          .el-table th, .el-table tr{
            cursor: pointer;
          }
        }
        .el-pagination {
          padding-top: 20px;
          text-align: center;
        }
      }
      .action{
        padding: 20px 10px;
        display: flex;
        .el-button{
          flex: 1;
        }
      }
    }
  }
}
</style>
