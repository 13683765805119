<!--
 * @Description: 触发组件行为
 * @Author: luocheng
 * @Date: 2022-05-11 15:04:06
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-09 13:59:26
-->
<template>
  <div class="component-behavior">
    <BehaviorItem
      v-for="(item, index) in configList"
      :key="index"
      :index="index"
      :config="item"
      @remove="onRemove"
      @update="onUpdate"
    ></BehaviorItem>
  </div>
</template>

<script>
import BehaviorItem from './BehaviorItem';

export default {
  name: 'ComponentBehavior',
  components: {
    BehaviorItem
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      configList: []
    }
  },
  created() {
    this.configList = this.value || [];
  },
  watch: {
    value: {
      handler() {
        this.configList = this.value || [];
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    /**
     * @desc: 新增
     */
    onPlus() {
      this.configList.push({
        component: '', // 触发的组件
        list: [ // 行为列表
          {
            behaviors: [],
            params: []
          }
        ]
      });
      this.onEmit();
    },
    /**
     * @desc: 移除
     * @param index
     */
    onRemove(index) {
      this.configList.splice(index, 1)
      this.onEmit();
    },
    /**
     * @desc: 更新
     */
    onUpdate({ index, config }) {
      this.configList.splice(index, 1, {
        ...config
      });
      this.onEmit();
    },
    onEmit() {
      this.$emit('input', this.configList);
      this.$emit('update', this.configList);
    }
  }
}
</script>

<style lang="less" scoped>
.component-behavior{
  width: 100%;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>