<template>
    <div class="model-config" v-if="curComponent">
        <h3 class="title">标记功能 </h3>
        <article class="contents">
            <div class="view-config" v-if="curComponent">
                <header class="header">
                    <div class="btn-bar">
                        <el-button type="primary" @click="addMarker">添加</el-button>
                    </div>


                    <div class="cards">
                        <el-card class="marker-card"
                                 shadow="never"
                                 :body-style="{ padding: '10px' }"
                                 v-for="(item , index) in markList"
                                 :key="item.id">
                            <template #header>
                                <div v-text="item.content"></div>
                            </template>
                            <div v-show="!item.showEdit">
                                <el-button type="primary" @click="editMarker(item)">编辑</el-button>
                                <el-button type="primary" @click="deleteMarker(item , index)">删除</el-button>
                            </div>
                            <el-form  label-width="85px" v-show="item.showEdit">
                                <el-form-item label="ID">
                                    <el-input size="mini" v-model="item.id" disabled=""></el-input>
                                </el-form-item>

                                <el-form-item label="显示">
                                    <el-switch  v-model="item.visible"/>
                                </el-form-item>

                                <el-form-item label="内容">
                                    <el-input size="mini" v-model="item.content"></el-input>
                                </el-form-item>

                                <el-form-item label="位置">
                                    <el-button type="primary" @click="getPosition(index)">选择</el-button>
                                </el-form-item>
                                <el-button type="primary" @click="syncMarker(item)">同步</el-button>
                                <el-button type="primary" @click="deleteMarker(item , index)">删除</el-button>
                                <el-button type="primary" @click="cancelEdit(item)">取消</el-button>
                            </el-form>
                        </el-card>
                    </div>



                </header>
            </div>
        </article>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import {  Card } from "element-ui";
    import eventBus from '@/plugins/eventBus';

    const getGuid = (format = 'xxxxxxxxxxxx') =>{
        var d = new Date().getTime();
        var guid = format.replace(
            /[xy]/g,
            function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });
        return guid;
    }

    export default {
        name: "ModelMarkerConfig",
        data (){
            return {
                markList : [],
            }
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        components: {
            "el-card" : Card,
        },
        methods:{
            init(){
                if (!this.curComponent || !this.curComponent.modelBIMMark) return;

                const marks = this.curComponent.modelBIMMark;
                for(let id in marks){
                    const { content , position , visible } = marks[id];

                    this.markList.push({
                        id,
                        content,
                        position : Object.assign({} , position),
                        visible ,
                        showEdit : false,
                        prev : {}
                    });
                }
            },
            //编辑标记
            editMarker(item){
                item.showEdit = true;
                item.prev = {
                    content : item.content,
                    position : {
                        x : item.position.x,
                        y : item.position.y,
                        z : item.position.z,
                    },
                    visible : item.visible,
                }
            },
            //取消编辑
            cancelEdit(item){
                item.showEdit = false;

                item.content = item.prev.content;
                item.position = {
                    x : item.prev.position.x,
                    y : item.prev.position.y,
                    z : item.prev.position.z,
                }
                item.visible = item.prev.visible;
            },
            //同步标记
            syncMarker(item){
                item.showEdit = false;

                eventBus.$emit('ViewerBIM.Marker.Edit' , this.curComponent.id , item.id , {
                    position : Object.assign({}, item.position),
                    visible : item.visible,
                    content : item.content,
                });
            },
            //删除标记
            deleteMarker(item , index){
                this.markList.splice( index , 1);

                eventBus.$emit('ViewerBIM.Marker.Delete' , this.curComponent.id , item.id);
            },
            //添加标记
            addMarker(){
                const id = getGuid();

                this.markList.push({
                    id,
                    content : '新增标记',
                    position : { x:0 , y : 0 , z :0},
                    visible : false,
                    showEdit : false,
                    prev : {}
                });

                eventBus.$emit('ViewerBIM.Marker.Add' , this.curComponent.id , {
                    id,
                    content : '新增标记',
                    position : {x : 0 , y : 0 , z : 0},
                    visible : false,
                });
            },
            getPosition( index ){
                eventBus.$emit('ViewerBIM.getMousePoint' , this.curComponent.id , ( pos )=>{
                    if( !pos) {
                        return this.$message.warning('未能获取到位置');
                    }

                    this.markList[index].position = pos;
                });
            },
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="less" scoped>
    .model-config{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title{
            text-align: left;
        }
        .contents{
            width: 100%;
            overflow-y: auto;
        }
        .btn-bar{
            display: flex;
            justify-content: right;
        }
        .cards{
            padding-top: 5px;
        }

    }
</style>