<!--
 * @Description: BIMGIS绑定的字段
 * @Author: Shiltin
 * @Date: 2022-08-30 10:19:51
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-10-25 18:25:14
-->
<template>
	<div class="bind-Cont">
		<header class="header">
			<span class="line"></span>
			<p class="item-title">模型字段</p>
			<el-button type="warning" @click="onResetField" size="mini">重置</el-button>
			<el-button type="primary" size="mini" @click="onConfirm">确认配置</el-button>
			<a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
				{{ toggleShow ? "收起" : "展开" }}
			</a>
		</header>
		<article v-show="toggleShow">
			<template>
				<div v-if="fieldList" class="bind-item">
					<el-form :model="configForm" label-width="100px">
							<el-form-item
								label="BIM绑定字段"
							>
								<el-select v-model="configForm.bimField" placeholder="请选择BIM绑定字段">
									<el-option
										v-for="(item, key) in fieldList"
										:key="item + key"
										:label="item + '【' + key + '】'"
										:value="key"
									>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item
								label="GIS绑定字段"
							>
								<el-select v-model="configForm.gisField" placeholder="请选择GIS绑定字段">
									<el-option
										v-for="(item, key) in fieldList"
										:key="item + key"
										:label="item + '【' + key + '】'"
										:value="key"
									>
									</el-option>
								</el-select>
							</el-form-item>
					</el-form>
				</div>
				<el-empty description="请先选择数据对象以及视图" v-else></el-empty>
			</template>
		</article>
	</div>
  
</template>

<script>
import { mapState } from "vuex";
import { Empty} from 'element-ui';

export default {
  name: 'BindModel',
	components: {
    'el-empty': Empty,
  },
  data() {
    return {
			toggleShow:false,
      // 配置
      configForm: {
				gisField:'',
				bimField:'',
      },
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ]),
		fieldList(){
			return  this.curComponent && this.curComponent.metadata;
		}
  },
	mounted() {
		if(this.curComponent?.bindFieldConfig){
			this.configForm = this.curComponent.bindFieldConfig
		}
	},
  methods: {
		/**
		 * @desc: 重置绑定字段
		 */
		onResetField() {
			this.$confirm('是否确认重置绑定字段?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.configForm = {
					gisField:'',
					bimField:'',
				}
				this.$message.success('重置绑定字段成功!');
			}).catch(() => { });
		},
    /**
		 * @desc: 确认配置
		 */
		onConfirm() {
			/* eslint-disable */
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					bindFieldConfig: this.configForm
				},
				containerId: null,
				isModify: true
			});
		},
  }
}
</script>

<style lang="less" scoped>
.bind-Cont {
	.header {
		width: 100%;
		display: flex;
		padding-top: 10px;
		margin-bottom: 10px;
		.line{
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 9px;
			background: @theme;
		}
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 32px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 32px;
		}
	}
	.bind-item{
		display: flex;
		border-bottom: 1px solid #f2f3f7;
		margin-bottom: 5px;
		:deep(.el-form-item) { 
			margin-bottom: 10px;
		}
		.remove{
			line-height: 150px;
			color: @dangerColor;
			font-size: 14px;
			font-weight: bold;
			margin-left: 10px;
			cursor: pointer;
		}
	}
}

</style>