<!--
 * @Description: 特殊事件配置
 * @Author: luocheng
 * @Date: 2022-06-08 16:51:38
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:02:04
-->
<template>
  <div class="special-config">
    <article class="config-list">
      <SingleConfig
        v-for="(item, index) in configList"
        :key="index"
        :index="index"
        :config="item"
        @remove="onRemove"
        @update="onUpdate"
      ></SingleConfig>
    </article>
    <footer class="footer">
      <el-button type="success" size="mini" @click="onPlus">新增操作类型</el-button>
      <el-button type="danger" size="mini" @click="onRemoveEvent">删除事件</el-button>
    </footer>
  </div>
</template>

<script>
import { defaultList, actionList } from '../config';
import SingleConfig from './SingleConfig';

export default {
  name: 'SpecialConfig',
  components: {
    SingleConfig
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    index: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      defaultList,
      actionList,
      configList: []
    }
  },
  created() {
    this.configList = this.value || [];
  },
  methods: {
    /**
     * @desc: 新增操作类型
     */
    onPlus() {
      this.configList.push({
        actionType: '', // 操作类型
        effects: [
					{
						targets: [],
						actions: [],
						logics: []
					}
				],
				eventList: this.defaultList || [],
				// 导出
				exportTarget: '', // 导出的表格组件
				exportType: '', // 导出的方式 all 全部 searchAll 带参数全部 checked 选中数据  targetPage 当前页 searchTargetPage 带参数本页
				exportTemplate: '', // 导出模板
				exportRequest: '', // 请求方式
				exportTypeSheet: 'pdf', // 导出类型表格新增, [excel | image | pdf] 2022-02-16 14:38:28
				objectUUID: '', // 对象UUID
				viewUUID: '', // 视图
				eventName: '', // 触发的后端事件名称
				// 组件行为
				behaviors: [
					{
						component: '', // 触发的组件
						list: [ // 行为列表
							{
								behaviors: [],
								params: []
							}
						]
					}
				],
        // 状态
        stateMachine: []
      });
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.configList.splice(index, 1);
      this.emitData();
    },
    /**
     * @desc: 移除事件
     */
    onRemoveEvent() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 编辑
     * @param {Number} index
     * @param {Object} config
     */
    onUpdate({ index, config }) {
      this.configList.splice(index, 1, {
        ...config
      });
      this.emitData();
    },
    /**
     * @desc: 提交数据
     */
    emitData() {
      this.$emit('input', this.configList);
    }
  }
}
</script>

<style lang="less" scoped>
.special-config{
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  .config-list{
    padding-bottom: 10px;
  }
}
</style>