<!--
 * @Description: 表格操作栏事件绑定
 * @Author: luocheng
 * @Date: 2021-09-14 10:13:27
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-17 15:47:28
-->
<template>
	<div class="table-events">
		<section class="part">
			<h4 class="header-title">操作</h4>
			<article class="list" v-if="actionList && Object.keys(actionList).length">
				<section class="item" v-for="(btn, key) in actionList" :key="key">
					<el-row>
						<el-col :span="8">名称：</el-col>
						<el-col :span="16">{{ btn.name }}</el-col>
					</el-row>
					<el-form class="event-list" v-if="btn.eventList && btn.eventList.length">
						<el-row v-for="(item, index) in btn.eventList" :key="index" class="event-box">
							<section v-for="(value, vIndex) in item.eventList" :key="vIndex">
								<el-form-item label="事件类型:" label-width="120px">
									<h3 class="title">{{ value.key | filterEvent }}</h3>
								</el-form-item>
								<template v-if="value.pattern === 'special'">
									<!-- 复杂事件 -->
									<section v-for="(item, index) in value.specialEventList" :key="index">
										<EventDetails :item="item"></EventDetails>
									</section>
								</template>
								<template v-else>
									<!-- 普通事件 -->
									<template v-if="value.actionType === 'eeAction'">
										<!-- 后端事件 -->
										<el-form-item label="操作:" label-width="120px">
											<div class="tag-list">
												<el-tag>后端事件--【{{ value.eventName }}】</el-tag>
											</div>
										</el-form-item>
									</template>
									<template v-else-if="value.actionType === 'pageAction'">
											<div v-for="(effect, eIndex) in value.effects" :key="eIndex">
												<el-form-item label="作用组件:" label-width="120px">
													<el-select v-model="effect.targets" placeholder="请选择作用组件" multiple disabled>
														<el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
												<el-form-item label="操作:" label-width="120px">
													<div class="tag-list">
														<el-tag>{{ effect.actions | filterActions }}</el-tag>
													</div>
												</el-form-item>
											</div>
									</template>
								</template>
							</section>
						</el-row>
					</el-form>
					<el-row>
						<el-button type="success" size="mini" @click="onShowSelect(btn)">绑定事件</el-button>
					</el-row>
				</section>
			</article>
			<p v-else>暂未配置操作按钮</p>
		</section>
		<!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'listEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
	</div>
</template>

<script>
import { defaultList, actionList } from '../../config';
import { mapState } from 'vuex';
import SelectEvent from '../../SelectEvent';
import { flatTree } from '@/utils/tools';
import { Tag } from 'element-ui';
import EventDetails from '@/components/events/EventDetails';

export default {
	name: 'ListEvents',
	components: {
		'el-tag': Tag,
		SelectEvent,
		EventDetails
	},
	data() {
		return {
			// 显示配置抽屉
			showSelect: false,
			// 当前配置的按钮
			partData: null
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前(表格组件数据)
			'componentData' // 图层数据
		]),
		// 表格绑定的操作栏
		actionList() {
			if (!this.curComponent) return [];
			const result = {};
			if (this.curComponent.component === 'CommonTable') {
				if (!this.curComponent.statusConfig || !this.curComponent.statusConfig.action) return [];
				if (typeof this.curComponent.statusConfig.action !== 'object') return;
				for (let action in this.curComponent.statusConfig.action) {
					const obj = this.curComponent.statusConfig.action[action];
					if (typeof obj === 'object' && obj.use) {
						result[action] = obj;
					}
				}
			} else if (this.curComponent.component === 'CommonTableContainer' || this.curComponent.component === 'ListContainerOne' || this.curComponent.component === 'ConsoleList') {
				for (let action in this.curComponent.actionConfig) {
					const obj = this.curComponent.actionConfig[action];
					if (typeof obj === 'object' && obj.use) {
						result[action] = obj;
					}
				}
			}
			return result;
		}
	},
	filters: {
		// 事件名称
		filterEvent(value) {
			for (let i = 0; i < defaultList.length; i++) {
				if (defaultList[i].value === value) {
					return defaultList[i].label;
				}
			}
			return value;
		},
		// 操作
		filterActions(value) {
			let result = [];
			for (let i = 0; i < value.length; i++) {
				const item = actionList.find((ele) => ele.value === value[i]);
				if (item) {
					result.push(item.label);
				}
			}
			return result.join();
		}
	},
	created() {
		console.log(this.actionList, '----actionList')
		this.targetList = flatTree(
			this.componentData,
			'children',
			{
				label: 'name',
				value: 'id'
			},
			[]
		);
	},
	methods: {
		/**
		 * @desc: 显示配置事件表单
		 * @param {Object} btnData 按钮配置数据
		 */
		onShowSelect(btnData) {
			if (!btnData) return;
			this.partData = btnData;
			this.showSelect = true;
		}
	}
};
</script>

<style lang="less" scoped>
.table-events{
	.header-title{
		position: relative;
    text-align: left;
    padding-bottom: 10px;
		box-sizing: border-box;
		padding-left: 10px;
		&:before{
			position: absolute;
			content: '';
			height: 14px;
			width: 3px;
			left: 0px;
			top: 5px;
			border-radius: 1px;
			background: @theme;
		}
  }
	.event-box{
		border-bottom: 1px solid @borderColor;
		&:last-of-type{
			border: none;
		}
	}
}	
</style>
