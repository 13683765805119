<!--
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-08-09 10:57:50
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-12 10:53:09
-->
<!--
 * @Description: 填充值配置
 * @Author: luocheng
 * @Date: 2022-08-09 10:57:50
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-09 15:32:01
-->
<template>
  <div class="form-fill-config">
    <header class="header">
			<span class="line"></span>
			<p class="item-title">表单填充值</p>
			<el-button type="primary" @click="onConfirm">确认</el-button>
			<el-button type="success" @click="onPlus">新增</el-button>
			<a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
				{{ toggleShow ? "收起" : "展开" }}
			</a>
		</header>
    <article class="fill-list" v-show="toggleShow">
      <template v-if="fillList && fillList.length">
        <section v-for="(item, index) in fillList" :key="index">
          <FillItem
            :index="index"
            :config="item"
            @update="onUpdate">
          </FillItem>
        </section>
      </template>
      <el-empty description="暂无数据" v-else></el-empty>
    </article>
  </div>
</template>

<script>
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
import FillItem from './FillItem';

export default {
  name: 'FillConfig',
  components: {
    FillItem,
    'el-empty': Empty
  },
  data() {
    return {
      toggleShow: false,
      fillList: [],
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  created() {
    this.fillList = this.curComponent?.formFillConfig || []
  },
  methods: {
    /**
     * @desc: 更新配置
     * @param {Number} index 下标
     * @param {Object/null} config 配置数据
     * @param {Boolean} isDelete 是否为删除
     */
    onUpdate({ index, config, isDelete = false }) {
      if (isDelete) {
        this.fillList.splice(index, 1);
      } else {
        this.fillList.splice(index, 1, config);
      }
    },
    /**
     * @desc: 确认配置
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					formFillConfig: this.fillList
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.fillList.push({
        objectUUID: '',
        viewUUID: '',
        fieldUUID: '',
        sourceType: 'component',
        valueType: 'value', // 组件取值结果。 value 取值，length 值长度
        componentId: '', // 组件ID
        isRequired: false, // 使否必须
        comValueType: 'resolveData', // 暴露值。 resolveData : 暴露值， bindValue: 注入值（propValue, 容器值）
        componentField: '', // 组件取值字段key
        systemKey: '', // 系统取值对象
        systemCode: '', // 系统取值参数
        urlParamsKey: '', // 链接请求参数key
        fixedValue: '', // 固定值
        statusCode: '', // 页面状态字段
      });
    }
  }
}
</script>

<style lang="less" scoped>
.form-fill-config{
  .header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
    flex-wrap: wrap;
		.line{
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 9px;
			background: @theme;
		}
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 32px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 32px;
			padding-right: 4px;
		}
	}
  .fill-list{
  }
}
</style>