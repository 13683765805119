<!--
 * @Description: 固定值配置
 * @Author: luocheng
 * @Date: 2021-08-30 11:55:05
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-01 10:50:41
-->
<template>
	<div class="fixed-value">
		<el-form :model="curComponent">
			<el-form-item label="内容设置" v-if="typeof curComponent.propValue === 'string'">
				<el-input v-model="curComponent.propValue" placeholder="请设置固定值" clearable></el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'FixedValue',
	data() {
		return {};
	},
	computed: {
		...mapState([
			'curComponent' // 当前组件
		])
	}
};
</script>

<style lang="less" scoped>
.fixed-value {
	width: 100%;
	// height: 100%;
	max-height: calc(100% - 26px);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	:deep(.el-form)  {
		.el-form-item__label {
			width: 100px;
			text-align: left;
		}
		.el-form-item {
			display: flex;
			box-sizing: border-box;
			padding-left: 10px;
			margin: 0;
			box-sizing: border-box;
			padding: 8px 10px;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				background-color: #f5f7fa;
				.el-form-item__label {
					color: @theme;
				}
			}
		}
	}
}
</style>
