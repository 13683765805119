<!--
 * @Description: 字典数据来源
 * @Author: luocheng
 * @Date: 2021-11-17 15:52:28
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:59:06
-->
<template>
  <div class="dictionary">
    <section class="config-content">
      <p class="label">选择字典：</p>
      <el-select v-model="dictionary" placeholder="请选择字典" clearable>
        <el-option v-for="item in list" :key="item.uuid"
          :label="item.name" :value="item.uuid"
        ></el-option>
      </el-select>
    </section>
    <footer class="actions">
      <el-button type="primary" @click="onConfirm">确认配置</el-button>
    </footer>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';

export default {
  name: 'DictionaryConfig',
  data() {
    return {
      // 字典列表
      list: [],
      // 字典
      dictionary: ''
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.getList();
    this.dictionary = this.curComponent.dictionaryConfig || '';
    // console.log(this.dictionary, '===dictionary');
  },
  methods: {
    /**
     * @desc: 获取字典列表
     */
    getList() {
      dataInterface({
        object_uuid: 'global'
      }, 'api/metadata/dictionary', 'GET').then(res => {
        if (!res) return;
        this.list = res.data.data || [];
      }).catch(err => {
        console.log(err)
      });
    },
    /**
     * @desc: 确认
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					dictionaryConfig: this.dictionary
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.dictionary{
  width: 100%;
  .config-content{
    width: 100%;
    display: flex;
    padding-bottom: 16px;
    .label{
      width: 80px;
      line-height: 32px;
      margin-right: 16px;
    }
  }
  .actions{
    display: flex;
    .el-button {
      flex: 1;
    }
  }
}
</style>