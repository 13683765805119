<!--
 * @Description: 可选组件列表
 * @Author: luocheng
 * @Date: 2021-08-02 11:15:56
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-09 11:24:49
-->
<template>
	<div class="components-list">
		<section class="component-group" v-for="(group, gIndex) in componentsForView" :key="gIndex">
      <!-- v-if="group.types && group.types.includes(editorType)" -->
			<div>
				<header class="group-title">
					<svg aria-hidden="true" class="color-icon">
						<use xlink:href="#iconfenzuhuizong" />
					</svg>
					<p>{{ group.title }}</p>
          <a href="javascript:;" @click="onToggle(gIndex, group)">
            {{ group.show ? '收起' : '展开' }}
          </a>
				</header>
				<div class="component-content" v-show="group.show">
					<template v-if="group.componentList && group.componentList.length">
						<!-- v-show="item.types && item.types.includes(editorType)" -->
						<section
							class="component"
							v-for="(item, index) in group.componentList"
							draggable
							:key="index"
							@dragstart="onDragStart"
							:data-index="index"
							:data-group-index="gIndex"
						>
							<svg aria-hidden="true" class="color-icon">
								<use :xlink:href="'#' + item.icon" />
							</svg>
							<span class="name">{{ item.name }}</span>
						</section>
					</template>
					<p v-else class="no-com">组件补充中....</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import componentList from '@/custom-component/component-list';
import mobileComponentList from '@/mobile-custom-components/component-list';
import { mapState } from 'vuex';

export default {
	name: 'ComponentsList',
	data() {
		return {
			componentList,
			mobileComponentList
		};
	},
	computed: {
		...mapState([
			'editorType', // 类型
		]),
		// 筛选可显示在当前类型下的组件列表
		componentsForView() {
			return this.editorType === 'mobile' ? this.mobileComponentList : this.componentList;
		}
	},
	created() {
		// 是否是移动端页面  使用不同组件配置文件
		if (this.editorType === 'mobile') {
			this.componentList = mobileComponentList
		}
	},
	methods: {
		/**
		 * @desc: 当用户开始拖动一个元素或者一个选择文本的时候 dragstart 事件就会触发
		 */
		onDragStart(e) {
			e.dataTransfer.setData('index', e.target.dataset.index);
			e.dataTransfer.setData('group-index', e.target.dataset.groupIndex);
		},
		/**
		 * @desc: 切换显示隐藏
		 * @param {Number} index
		 */
		onToggle(index, group) {
			console.log('切换显示隐藏, ------')
			this.componentList.splice(index, 1, {
				...group,
				show: !group.show
			});
			this.$set(group, 'show', !group.show);
		}
	}
};
</script>

<style lang="less" scoped>
.components-list {
	width: 100%;
	.component-group {
		box-sizing: border-box;
		.group-title {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 10px 2px;
			.color-icon {
				width: 18px;
				height: 18px;
				font-size: 18px;
				vertical-align: -2.7px;
				fill: currentColor;
				overflow: hidden;
			}
			p {
				flex: 1;
				box-sizing: border-box;
				padding-left: 10px;
				text-align: left;
				font-size: 14px;
				line-height: 18px;
				color: #222;
			}
      a {
        font-size: 14px;
				line-height: 18px;
        color: @theme;
      }
		}
		.component-content {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.component {
				height: 35px;
				width: 48%;
				margin: 1%;
				box-sizing: border-box;
				cursor: -webkit-grab;
				cursor: grab;
				margin-bottom: 10px;
				text-align: center;
				color: #333;
				box-sizing: border-box;
				padding: 8px 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 3px;
				color: #606266;
				white-space: nowrap;
				transition: transform 0ms,-webkit-transform 0ms!important;
				border: 1px dashed #f6f7ff;
				background: #f6f7ff;
				cursor: move;
				&:hover {
					color: #fff;
					border: 1px dashed @theme;
					font-weight: bold;
					color: @theme;
				}
				&:active {
					cursor: grabbing;
				}
				span{
					line-height: 22px;
				}
				.color-icon {
					width: 18px;
					height: 18px;
					margin-right: 5px;
					fill: currentColor;
					overflow: hidden;
					color: #777;
					font-size: 16px;
				}
				.name {
					flex: 1;
					color: #777;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: left;
				}
			}
			.no-com {
				text-align: center;
				line-height: 30px;
				color: @minorColor;
			}
		}
	}
}
</style>
