<!--
 * @Description: 日历配置
 * @Author: luocheng
 * @Date: 2021-12-03 18:02:45
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-04 15:28:04
-->
<template>
  <div class="calendar-config">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="统计配置" name="1">
        <StaticConfig></StaticConfig>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StaticConfig from './StaticConfig';

export default {
  name: 'CalendarConfig',
  components: {
    StaticConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  data() {
    return {
      activeNames: '1',
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.calendar-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
    padding-bottom: 10px;
  }
  .tab-origin{
    display: flex;
    padding-bottom: 10px;
    p{
      width: 60px;
    }
    .origin-content{
      flex: 1;
      text-align: left;
      .el-radio{
        margin-bottom: 10px;
      }
    }
  }
  .el-collapse{
    flex: 1;
    overflow-y: auto;
  }
}
</style>