<!--
 * @Description: 表单配置
 * @Author: luocheng
 * @Date: 2021-09-23 17:34:53
 * @LastEditors: zx
 * @LastEditTime: 2022-07-08 16:25:54
-->
<template>
  <div class="filter-config">
    <template v-if="curComponent">
      <h3 class="title">组件名称: {{ curComponent.name }}</h3>
      <article class="contents">

      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item title="筛选项配置" name="itemConfig">
          <!-- 筛选项配置 -->
          <ItemConfig></ItemConfig>
        </el-collapse-item>
        <el-collapse-item v-if="editorType === 'mobile'" title="标题配置" name="titleConfig">
          <!-- 标题配置 -->
          <TitleConfig></TitleConfig>
        </el-collapse-item>
        <el-collapse-item v-if="editorType === 'mobile'" title="返回配置" name="backConfig">
          <!-- 标题配置 -->
          <BackConfig></BackConfig>
        </el-collapse-item>
        <!-- 移动端页面筛选不显示 -->
        <el-collapse-item v-if="editorType !== 'mobile'" title="操作配置" name="actionConfig">
          <!-- 配置操作 -->
          <ActionConfig></ActionConfig>
        </el-collapse-item>
      </el-collapse>
      </article>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionConfig from './ActionConfig';
import ItemConfig from './ItemConfig';
import TitleConfig from '../mobileTopConfig/titleConfig';
import BackConfig from '../mobileTopConfig/backConfig';

export default {
  name: "FilterConfig",
  components: {
    ActionConfig,
    ItemConfig,
    TitleConfig,
    BackConfig
  },
  data() {
    return {
      activeNames: ''
    };
  },
  computed: {
    ...mapState(['curComponent', 'editorType'])
  }
};
</script>

<style lang="less" scoped>
.filter-config {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title {
    text-align: left;
    line-height: 20px;
    line-height: 16px;
    word-break: break-all;
    color: #333;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f3f5;
  }
  .contents{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
