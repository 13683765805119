<!--
 * @Description: 拖放区域
 * @Author: luocheng
 * @Date: 2021-08-11 11:08:58
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-16 11:35:41
-->
<template>
	<div
		:style="{
			left: area.x + 'px',
			top: area.y + 'px',
			width: area.width + 'px',
			height: area.height + 'px'
		}"
		class="select-area"
	></div>
</template>

<script>
export default {
	name: 'SelectArea',
	props: {
		// 位置坐标信息
		area: {
			type: Object,
			default: () => {
				return {
					x: 0,
					y: 0,
					width: 0,
					height: 0
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.select-area {
	border: 1px solid #70c0ff;
	position: absolute;
}
</style>
