<!--
 * @Description: 页面/画布属性
 * @Author: luocheng
 * @Date: 2021-08-23 09:44:06
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 12:00:41
-->
<template>
	<div class="page-attributes">
		<el-collapse v-model="actives">
			<el-collapse-item
				title="画布属性"
				name="canvas"
			>
				<el-form
					class="attr-list"
					:inline="true"
				>
					<!-- <el-form-item label="页面名称">
						<el-input
							v-model="canvasStyle.title"
							maxlength="10"
							minlength="1"
							placeholder="请设置页面名称"
							clearable=""
						></el-input>
					</el-form-item> -->
					<el-form-item label="页面宽度">
						<el-input
							v-model="canvasStyle.width"
							type="number"
							min="0"
							placeholder="请设置页面宽度"
						></el-input>
					</el-form-item>
					<el-form-item label="页面高度">
						<el-input
							v-model="canvasStyle.height"
							type="number"
							min="0"
							placeholder="请设置页面高度"
						></el-input>
					</el-form-item>
					<el-form-item label="缩放比例(%)">
						<!-- <el-input  v-model="canvasStyle.scale" type="number" min="0" placeholder="请设置页面缩放比例"></el-input> -->
						<el-input-number
							v-model="canvasStyle.scale"
							:step="1"
							:min="0"
							label="请设置页面缩放比例"
						></el-input-number>
					</el-form-item>
					<el-form-item label="背景颜色">
						<section class="value-box">
							<!-- 颜色 -->
							<el-color-picker
								v-model="canvasStyle.backgroundColor"
								show-alpha
								:predefine="predefineColors"
							>
							</el-color-picker>
							<el-input
								type="string"
								v-model="canvasStyle.backgroundColor"
							></el-input>
						</section>
					</el-form-item>
				</el-form>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { InputNumber, ColorPicker } from 'element-ui';

export default {
	name: 'PageAttributes',
	components: {
		'el-color-picker': ColorPicker,
		'el-input-number': InputNumber
	},
	data() {
		return {
			actives: ['canvas'],
			// 预定义颜色
			predefineColors: [
				'#ff4500',
				'#ff8c00',
				'#ffd700',
				'#90ee90',
				'#00ced1',
				'#1e90ff',
				'#c71585',
				'#000000',
				'#ffffff'
			]
		};
	},
	computed: {
		...mapState(['canvasStyle'])
	}
};
</script>

<style lang="less" scoped>
.page-attributes {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	:deep(.el-collapse)  {
		border-top: none;
	}
	:deep(.el-form)  {
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		padding: 10px 0;
		overflow: hidden;
		overflow-y: auto;
		.el-form-item {
			display: flex;
			box-sizing: border-box;
			padding-left: 15px;
			margin: 0;
			box-sizing: border-box;
			padding: 8px 5px;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				background-color: #f5f7fa;
				.el-form-item__label {
					color: @theme;
				}
			}
			.el-form-item__label {
				width: 100px;
				text-align: left;
				cursor: pointer;
			}
			.el-form-item__content {
				flex: 1;
				.el-input {
					.el-input__inner {
						text-align: left;
					}
				}
				.el-input-number {
					width: 100%;
					.el-input__inner {
						text-align: center;
					}
				}
			}
			.value-box {
				width: 100%;
				display: flex;
				.el-input {
					margin-left: 10px;
				}
			}
		}
	}
}
</style>