<!--
 * @Description: 操作
 * @Author: luocheng
 * @Date: 2021-09-09 10:59:07
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-09 18:38:10
-->
<template>
  <section class="action-item">
    <el-form-item label="是否使用">
      <el-checkbox v-model="value.use" @change="emitData">
        {{ value.use ? '使用中' : '禁用中' }}
      </el-checkbox>
    </el-form-item>
    <el-form-item label="名称">
      <el-input v-model="value.name" placeholder="请输入名称" @input="emitData"></el-input>
    </el-form-item>
    <el-form-item label="按钮类型">
      <el-select v-model="value.btnType" placeholder="请选择按钮类型" @change="emitData">
        <el-option
          v-for="type in btnTypes" :key="type.value"
          :label="type.label" :value="type.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="按钮尺寸">
      <el-select v-model="value.btnSize" placeholder="请选择按钮尺寸" @change="emitData">
        <el-option
          v-for="size in btnSizes" :key="size.value"
          :label="size.label" :value="size.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="字体颜色" class="color-item">
      <el-color-picker
        v-model="value.color"
        show-alpha
        :predefine="predefineColors">
      </el-color-picker>
      <el-input type="string" v-model="value.color"></el-input>
    </el-form-item>
    <el-form-item label="间距">
      <el-input v-model="value.margin" type="number" min="0" placeholder="请输入间距"></el-input>
    </el-form-item>
  </section>
</template>

<script>
// use: false,
// type: 'add',
// name: '新增',
// btnType: 'text',
// color: '#67C23A',
// btnSize: ''
import { ColorPicker } from 'element-ui';
export default {
  name: 'TableActionItem',
  components: {
		'el-color-picker': ColorPicker
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
			// 按钮类型列表
			btnTypes: [
				{
					value: '',
					label: '默认'
				},
				{
					value: 'text',
					label: '文本'
				},
				{
					value: 'primary',
					label: '主要'
				},
				{
					value: 'success',
					label: '成功'
				},
				{
					value: 'warning',
					label: '警告'
				},
				{
					value: 'danger',
					label: '危险'
				},
				{
					value: 'info',
					label: '信息'
				}
			],
      // 按钮尺寸
      btnSizes: [
        {
					value: '',
					label: '默认'
				},
        {
					value: 'medium',
					label: '中等'
				},
        {
					value: 'small',
					label: '小型'
				},
        {
					value: 'mini',
					label: '超小'
				}
      ],
      // 预定义颜色
			predefineColors: [
          '#ff4500',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585',
					'#000000',
					'#ffffff'
			]
    }
  },
  methods: {
    /**
     * @desc: 提交数据 
     */
    emitData() {
      this.$emit('input', this.value);
    }
  }
}
</script>

<style lang="less" scoped>
.action-item{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 5px 0;
  padding-left: 5px;
  :deep(.el-form-item) { 
    display: flex;
    margin-right: 0;
    box-sizing: border-box;
    padding: 0 5px;
    &.color-item{
      .el-form-item__content{
        display: flex;
      }
      .el-input{
        margin-left: 5px;
      }
    }
    .el-form-item__label{
      width: 80px;
    }
    .el-form-item__content{
      flex: 1;
      text-align: left;
    }
  }
}
</style>