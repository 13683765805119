<!--
 * @Description: 常规页面配置
 * @Author: luocheng
 * @Date: 2021-08-17 16:59:51
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-22 09:56:44
-->
<template>
	<div
		class="normal normalpage-container"
		:style="{
			...canvasStyle,
			height: canvasHeight + 'px',
			width: canvasWidth + 'px',
			scale: canvasScale
		}"
		@drop="onDropDown"
		@dragover="onDragover"
		@mousedown="onMousedown"
		@mouseup="clearCurComponent"
		@contextmenu="onContextMenu"
	>
		<!-- <VueDraggable v-model="componentList"
			forceFallback="true"
			group="component"
			animation="300"
			@start="onStart"
			@end="onEnd"
		> -->
		<!-- eslint-disable -->
		<ComponentBox
			v-for="item in componentData"
			:key="item.id"
			:element="item"
			:isPreview="isPreview"
			@click.native.stop="onClickBox(item)"
			:componentList="componentData"
			:pageUUID="pageUUID"
		></ComponentBox>
		<!-- </VueDraggable> -->
		<!-- 右击菜单 -->
		<EditorMenu v-if="!isPreview"></EditorMenu>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ComponentBox from './ComponentBox';
import componentList from '@/custom-component/component-list';
import { deepCopy, getComponentId, judgingEventLogic, getStatusVal, getComponentById } from '@/utils/tools';
import EditorMenu from '@/components/editor/EditorMenu';

export default {
	name: 'NormalPage',
	components: {
		ComponentBox,
		EditorMenu
		// VueDraggable
	},
	props: {
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		// 页面UUID用于内嵌页渲染
    pageUUID: {
      type: String,
      required: false,
      default: ''
    }
	},
	data() {
		return {
			componentList: [],
			componentForLogic: []
		};
	},
	computed: {
		...mapState([
			'canvasStyle', // 画布样式
			'componentData', // 图层列表
			'isClickComponent', // 是否点击组件
			'sContainer',
			'originComponentData' // 大屏容器组件的元组件
		]),
		// 画布宽度
		canvasWidth() {
			if (isNaN(this.canvasStyle.width) || !this.canvasStyle.scale)
				return this.canvasStyle.width;
			return (this.canvasStyle.width * parseInt(this.canvasStyle.scale)) / 100;
		},
		// 画布高度
		canvasHeight() {
			if (isNaN(this.canvasStyle.height) || !this.canvasStyle.scale)
				return this.canvasStyle.height;
			return (this.canvasStyle.height * parseInt(this.canvasStyle.scale)) / 100;
		},
		// 缩放比例(兼容特殊处理)
		canvasScale() {
			if (isNaN(+this.canvasStyle.scale)) {
				return this.canvasStyle.scale;
			}
			return this.canvasStyle.scale/100;
		}
	},
	created() {
		this.componentList = this.componentData;
	},
	watch: {
		componentData: {
			handler() {
				this.componentList = this.componentData;
			},
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 放置拖放
		 */
		onDropDown(e) {
			if (this.isPreview) return;
			const groupIndex = e.dataTransfer.getData('group-index');
			const comIndex = e.dataTransfer.getData('index');
			if (
				isNaN(groupIndex) ||
				isNaN(comIndex) ||
				!Array.isArray(componentList) ||
				!componentList.length
			) {
				this.$message.error('获取组件失败！');
				return;
			}
			const group = componentList[groupIndex];
			if (
				!group ||
				!group.componentList ||
				!Array.isArray(group.componentList)
			) {
				this.$message.error('获取组件失败！');
				return;
			}
			const component = deepCopy(group.componentList[comIndex]);
			component.id = getComponentId(component.component);
			component.id = getComponentId(component.component);
			component.parentId = '';
			// 组件嵌套方式
			const target = e.target;
			let containerId = null;
			if (target instanceof SVGElement || target.className.includes('normal')) {
				console.log('放置到画布根节点上!');
			} else {
				// 1、当前放置位置为容器则在容器中追加
				// 2、获取当前放置节点的第一个祖先容器并放置
				const parentContainer = this.getParentContainer(target);
				if (parentContainer) {
					containerId = parentContainer.containerId;
					component.parentId = containerId;
				}
			}
			// 将组件写入到图层列表
			this.$store.commit('modifyComponent', { component, containerId });
			// 添加快照
			this.$store.commit('recordSnapshot');
			e.dataTransfer.setData('group-index', -1);
			e.dataTransfer.setData('index', -1);
		},
		/**
		 * @desc: 拖动进入放置区域
		 */
		onDragover(e) {
			e.stopPropagation();
			e.preventDefault();
			if (this.isPreview) return;
			e.dataTransfer.dropEffect = 'copy';
		},
		/**
		 * @desc: 获取距离当前节点最近的容器ID
		 * @param {Object} target 当前节点
		 * @return {String} 返回节点树上距离当前节点最近的容器ID
		 */
		getParentContainer(target) {
			if (!target || typeof target !== 'object') return null;
			if (target.dataset && target.dataset.id) {
				return {
					target,
					containerId: target.dataset.id
				};
			}
			return this.getParentContainer(target.parentNode);
		},
		/**
		 * @desc: 移除点击状态
		 */
		onMousedown() {
			if (this.isPreview) return;
			this.$store.commit('setClickComponentStatus', false);
		},
		/**
		 * @desc: 清除当前选择当前组件
		 */
		clearCurComponent(e) {
			if (this.isPreview) return;
			if (!this.isClickComponent) {
				this.$store.commit('setCurComponent', { component: null, index: null });
			}
			// 0 左击 1 滚轮 2 右击
			// 右击关闭菜单
			if (e.button != 2) {
				this.$store.commit('hideEditorMenu');
			}
		},
		/**
		 * @desc: 打开右击菜单
		 */
		onContextMenu(e) {
			e.stopPropagation();
			e.preventDefault();
			if (this.isPreview) return;
			/* eslint-disable */
			// 计算菜单相对于编辑器的位移
			// 此部分在嵌套组件时存在问题 留待进一步修改 question1
			let target = e.target;
			let top = e.offsetY;
			let left = e.offsetX;
			while (target instanceof SVGElement) {
				target = target.parentNode;
			}
			while (!target.className.includes('normal')) {
				left += target.offsetLeft;
				top += target.offsetTop;
				target = target.parentNode;
			}
			this.$store.commit('showEditorMenu', {
				left,
				top
			});
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} item 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(item) {
			// 特殊：无须触发点击的组件
			const excludesEvents = ['CommonDateButton'];
			if (
				excludesEvents.includes(item.component) ||
				!item.events ||
				!item.events.click ||
				!Array.isArray(item.events.click.effects)
			) {
				return false
			}
			const click = item.events.click || {};
			const { pattern, effects = [], specialEventList = []} = click;
			if (!pattern) {
				const { actionType, linkType, queryList = [], linkUrl = '', pageId } = click;
				if (actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(click.stateMachine || []);
				} else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
					effects.forEach((ele) => {
						this.$store.commit('triggerEvents', {
							config: ele,
							element: item
						});
					});
				}
			} else if (pattern === 'special') {
				specialEventList.forEach(ele => {
					const { actionType, linkType, queryList = [], linkUrl = '', pageId } = ele;
					if (actionType === 'pageStatus') {
						// 修改页面状态
						this.updatePageStatus(ele.stateMachine || []);
					}else if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: effect,
								element: item
							});
						})
					}
				});
			}
		},
		/**
		 * @desc: 开始拖动
		 */
		onStart(e) {
			e.preventDefault();
			e.stopPropagation();
		},
		/**
		 * @desc: 结束拖动
		 */
		onEnd(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$store.commit('setComponentData', this.componentList);
		},
		/**
		 * @desc: 进行组件逻辑判定(此处逻辑废弃)
		 */
		setLogicStatus() {
			this.componentForLogic = [];
			this.flatTree(this.componentData);
			for (let i = 0, len = this.componentForLogic.length; i < len; i++) {
				const { UILogic } = this.componentForLogic[i];
				if (!UILogic || !UILogic.length) {
					continue;
				}
				for (let x = 0, len = UILogic.length; x < len; x++) {
					const { effects = [], logics = [] } = UILogic[x];
					if (!effects.length || !logics.length) {
						continue;
					}
					// 逻辑结果
					let validResult = false;
					for (let j = 0; j < logics.length; j++) {
						const logic = logics[j];
						const target = this.componentForLogic.find(
							(ele) => ele.id === logic.target
						);
						if (!target) {
							break;
						}
						let targetData = null;
						if (logic.dataKey === 'resolveData') {
							targetData = target.resolveData;
						} else if (logic.dataKey === 'componentData') {
							targetData = target.fullData;
						}
						if (!targetData) {
							break;
						}
						const {
							value,
							valueType,
							logicRule,
							itemLogic,
							dataAttr,
							dataField
						} = logic;
						if (dataAttr === 'length') {
							targetData = targetData.toString().length;
						} else if (dataAttr === 'value') {
							targetData = targetData[dataField];
						}
						let itemValid = false;
						// 逻辑判断
						if (logicRule === '===') {
							if (valueType === 'date') {
								itemValid = new Date(targetData).getTime() === value;
							} else if (valueType === 'string') {
								itemValid = targetData === value;
							} else if (valueType === 'number') {
								itemValid = +targetData === +value;
							}
						} else if (logicRule === '!=') {
							if (valueType === 'date') {
								itemValid = new Date(targetData).getTime() !== value;
							} else if (valueType === 'string') {
								itemValid = targetData !== value;
							} else if (valueType === 'number') {
								itemValid = +targetData != +value;
							}
						} else if (logicRule === '>') {
							if (valueType === 'date') {
								itemValid = new Date(targetData).getTime() > value;
							} else if (valueType === 'number') {
								itemValid = +targetData > +value;
							}
						} else if (logicRule === '>=') {
							if (valueType === 'date') {
								itemValid = new Date(targetData).getTime() >= value;
							} else if (valueType === 'number') {
								itemValid = +targetData >= +value;
							}
						} else if (logicRule === '<') {
							if (valueType === 'date') {
								itemValid = new Date(targetData).getTime() < value;
							} else if (valueType === 'number') {
								itemValid = +targetData < +value;
							}
						} else if (logicRule === '<=') {
							if (valueType === 'date') {
								itemValid = new Date(targetData).getTime() <= value;
							} else if (valueType === 'number') {
								itemValid = +targetData <= +value;
							}
						}
						if (j === 0) {
							validResult = itemValid;
						} else {
							if (itemLogic === 'and') {
								validResult = validResult && itemValid;
							}
							if (itemLogic === 'or') {
								validResult = validResult || itemValid;
							}
						}
					}
					// 副作用 目前处理显示隐藏
					console.log(effects, '----effects-----')
					effects.forEach((ele) => {
						console.log(ele, '----ele-----')
						const { type } = ele;
						// 显示 目前仅显示
						if (type === 'show') {
							this.$emit('modifyComponent', {
								component: {
									...this.componentForLogic[i],
									statusConfig: {
										...this.componentForLogic[i].statusConfig,
										isShow: validResult
									}
								},
								containerId: null,
								isModify: true
							});
						}
					});
				}
			}
		},
		/**
		 * @desc: 扁平树形结构
		 * @param {Array} arr 树形数据
		 */
		flatTree(arr) {
			arr.forEach((ele) => {
				this.componentForLogic.push({
					...ele,
					children: []
				});
				if (ele.children && ele.children.length) {
					this.flatTree(ele.children);
				}
			});
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			})
		}
	}
};
</script>

<style lang="less" scoped>
.normal {
	position: relative;
	background: #fff;
	margin: auto;
	transition: all 0.3s;
	overflow: hidden;
	box-sizing: border-box;
	padding: 10px;
}
</style>
