<!--
 * @Description: 映射配置
 * @Author: luocheng
 * @Date: 2022-10-09 14:56:57
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:30:56
-->
<template>
	<div class="card-config">
		<el-form :model="formData">
			<el-form-item label="模版">
				<el-select
					v-model="formData.loopCard"
					placeholder="请选择循环体"
					filterable
					remote
					:remote-method="onRemote"
					:loading="loading"
          @change="onCard"
				>
					<el-option v-for="item in cardList"
            :key="item.id"
            :label="item.name"
            :value="item.uuid">
          </el-option>
				</el-select>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';
import { pageFromDetail} from '@/api/saas'
export default {
	name: 'CardConfig',
	data() {
		return {
			formData: {
				loopCard: ''
			},
			cardList: [],
			loading: false
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	created() {
		this.formData.loopCard = this.curComponent?.loopConfig?.cardId || '';
    this.onRemote('');
	},
	methods: {
		/**
		 * @desc: 远程搜索
		 */
		onRemote(value) {
			this.loading = true;
      this.cardList = [];
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61922b1881103',
				search: [
					{
						field_uuid: 'field61bc41f645e17',
						ruleType: 'like',
						value: 'card'
					},
					{
						field_uuid: 'd1f7e149-2af7-468e-85be-5ef9bd97f634',
						ruleType: 'like',
						value
					}
				],
				page: 1,
				size: 20,
				transcode: 0
			}).then((res) => {
        this.cardList = res?.data?.data?.data || [];
        this.loading = false;
      }).catch((err) => {
        console.log(err, '----11');
        this.loading = false;
      });
		},
    /**
     * @desc: 选择模版
     */
    async onCard(cardId) {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					loopConfig: {
						...this.curComponent.loopConfig,
						cardId
					}
				},
				containerId: null,
				isModify: true
			});
      const pageData = await this.getPageData(cardId);
      if (!pageData) return;
      let { componentData = [] } = pageData?.page_data;
      const templateObj = componentData?.[0];
      if (!templateObj) return;
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					children: [{
						...templateObj,
						loopConfig: this.curComponent.loopConfig,
					}]
				},
				containerId: null,
				isModify: true
			});
    },
		/**
     * @desc: 获取页面数据
     * @param {String} page_uuid 页面uuid
     */
		getPageData(page_uuid) {
			return new Promise((resolve, reject) => {
				pageFromDetail({
					__method_name__: 'dataInfo',
					object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
					view_uuid: 'view61b951c6a8186',
					page_uuid,
					transcode: 0
				}).then((res) => {
          resolve(res?.data?.data);
        }).catch((err) => {
          console.log(err);
          reject(null);
        });
			});
		}
	}
};
</script>

<style lang="less" scoped>
.card-config {
	width: 100%;
}
</style>
