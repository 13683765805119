/*
 * @Description: 配置备选项
 * @Author: luocheng
 * @Date: 2021-09-27 10:30:26
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-13 10:13:26
 */

// 时间选择器类型
export const dateTypes = [
	{
		label: '日期',
		value: 'date'
	},
	{
		label: '日期+时间',
		value: 'datetime'
	},
	{
		label: '时间',
		value: 'time'
	},
	{
		label: '周',
		value: 'week'
	},
	{
		label: '月',
		value: 'month'
	},
	{
		label: '年',
		value: 'year'
	},
	{
		label: '多个日期',
		value: 'dates'
	}
];

// 逻辑关系(后端需求，可能会较难理解)
export const ruleList = [
	{ label: '不指定', value: '' },
	{ label: '等于', value: 'eq' },
	{ label: '大于', value: 'gt' },
	{ label: '大于等于', value: 'gte' },
	{ label: '小于', value: 'lt' },
	{ label: '小于等于', value: 'lte' },
	{ label: '类似', value: 'like' },
	{ label: '包含', value: 'in' },
	{ label: '空', value: 'empty' },
	{ label: '非空', value: 'notEmp' },
	{ label: '区间', value: 'between' },
	{ label: '不包含', value: 'notin' },
	{ label: '不类似', value: 'notlike' }
];
