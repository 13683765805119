<!--
 * @Description: 字段配置
 * @Author: luocheng
 * @Date: 2021-09-24 14:38:38
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-05 15:21:29
-->
<template>
	<section class="field-config">
		<header class="header">
			<el-checkbox v-model="viewConfig.showInFilter" @change="emitData"></el-checkbox>
			<p>
        <MetaTraceIcon method="ShowFieldDetail" :uuid="viewConfig.uuid" />
        {{ viewConfig.name }}
      </p>
			<a href="javascript:;" @click="onToggle">{{ isToggle ? '收起' : '展开' }}</a>
		</header>
		<el-form :model="viewConfig" v-if="viewConfig && isToggle" class="field-config" label-width="70px">
			<el-form-item label="是否启用">
				<el-switch v-model="viewConfig.showInFilter" @change="emitData"></el-switch>
			</el-form-item>
			<el-form-item label="名称">
				<el-input v-model="viewConfig.name" placeholder="请输入名称"  @input="emitData"></el-input>
			</el-form-item>
			<el-form-item label="逻辑关系">
				<el-select v-model="viewConfig.ruleType" @change="emitData">
					<el-option v-for="ruleOption in ruleList" :key="ruleOption.value"
						:label="ruleOption.label" :value="ruleOption.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="类型">
				<el-select v-model="viewConfig.type" @change="emitData">
					<el-option v-for="type in typeList" :key="type.value" :label="type.label" :value="type.value"></el-option>
				</el-select>
			</el-form-item>
			<!-- 输入框类型圆角 -->
			<el-form-item v-if="['input', 'select', 'datepicker', 'during'].includes(viewConfig.type)" label="圆角">
				<el-switch v-model="viewConfig.isRadius" @change="emitData"></el-switch>
			</el-form-item>
			<!-- 下拉选项 单选  多选 -->
			<template v-if="viewConfig.type === 'select' || viewConfig.type === 'radio' || viewConfig.type === 'checkbox' || viewConfig.type === 'tabs'">
				<el-form-item label="选项类型">
					<el-select v-model="viewConfig.optionOrign" @change="onOptionOrigin">
						<el-option label="固定值" value="fixed"></el-option>
						<el-option label="数据源" value="database"></el-option>
					</el-select>
				</el-form-item>
				<!-- 固定参数 -->
				<el-form-item label="固定值" v-if="viewConfig.optionOrign === 'fixed'">
					<ul class="fixed-list">
						<li class="option-item" v-for="(option, oIndex) in viewConfig.options" :key="oIndex">
							<div class="option-item-content">
								<div class="default-select width-static">
									<p>默认选中</p>
									<el-switch v-model="option.defaultSelect"></el-switch>
								</div>
								<el-input v-model="option.label" placeholder="请输入说明文字" size="mini"></el-input>
								<el-input v-model="option.value" placeholder="请输入绑定值" size="mini"></el-input>
								<div class="width-static">
									<p>启用统计</p>
									<el-switch size="mini" v-model="option.widthStatistics" @change="emitData"></el-switch>
								</div>
								<!-- 配置统计 -->
								<template v-if="option.widthStatistics">
									<ConfigStatistics v-model="option.statisticsConfig"></ConfigStatistics>
								</template>
							</div>
							<div class="actions">
								<i
									class="el-icon-remove remove"
									v-if="viewConfig.options.length > 1"
									@click="onModifyOption(oIndex, 'remove')"
								></i>
								<i class="el-icon-plus plus" @click="onModifyOption(oIndex, 'plus')"></i>
							</div>
						</li>
					</ul>
				</el-form-item>
				<!-- 数据源绑定 -->
				<template v-else-if="viewConfig.optionOrign === 'database'">
					<el-form-item label="数据源">
						<div class="option-item-content">
							<el-select
								v-model="viewConfig.optionConfig.objectUUID"
								placeholder="请输入关键字搜索"
								size="mini"
								filterable
								remote
								:remote-method="onRemoteObject"
								:loading="objLoading"
								@change="onGetViewList"
								clearable
							>
								<el-option v-for="objItem in objectList" :key="objItem.uuid" :label="objItem.name" :value="objItem.uuid">
								</el-option>
							</el-select>
							<el-select
								v-model="viewConfig.optionConfig.viewUUID"
								placeholder="请输入关键字搜索"
								size="mini"
								v-if="viewConfig.optionConfig.objectUUID"
								@change="onGetFieldList"
								clearable
							>
								<el-option v-for="viewItem in viewList" :key="viewItem.uuid" :label="viewItem.name" :value="viewItem.uuid">
								</el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="说明字段">
						<div class="option-item-content">
							<el-select
								v-model="viewConfig.optionConfig.labelCode"
								placeholder="请选择说明字段"
								allow-create
								size="mini"
								clearable
							>
								<el-option v-for="(fieldText, fieldItem) in selectOptionsField" :key="fieldItem" :label="fieldText" :value="fieldItem">
								</el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="取值字段">
						<div class="option-item-content">
							<el-select
								v-model="viewConfig.optionConfig.valueCode"
								placeholder="请选择取值字段"
								allow-create
								size="mini"
								clearable
							>
								<el-option v-for="(fieldText, fieldItem) in selectOptionsField" :key="fieldItem" :label="fieldText" :value="fieldItem">
								</el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="'全部'">
						<el-switch size="mini" v-model="viewConfig.optionConfig.popAll"></el-switch>
					</el-form-item>
					<el-form-item label="默认选中">
						<el-switch size="mini" v-model="viewConfig.optionConfig.defaultSelect"></el-switch>
					</el-form-item>
					<!-- <el-form-item label="启用统计">
						<el-switch size="mini" v-model="viewConfig.optionConfig.widthStatistics"></el-switch>
					</el-form-item>
					<el-form-item label="配置统计" v-if="viewConfig.optionConfig.widthStatistics">
						<ConfigStatistics v-model="viewConfig.optionConfig.statisticsConfig"></ConfigStatistics>
					</el-form-item> -->
				</template>
			</template>
			<!-- 时间选择器 -->
			<template v-else-if="viewConfig.type === 'datepicker'">
				<el-form-item label="时间类型">
					<el-select v-model="viewConfig.dateType"  @change="emitData">
						<el-option v-for="dateOption in dateTypes" :key="dateOption.value"
							:label="dateOption.label" :value="dateOption.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- 时间的格式 -->
				<el-form-item label="时间格式">
					<el-select v-model="viewConfig.dateFormat" clearable placeholder="请选择时间格式" @change="emitData">
						<el-option
							v-for="(format, index) in formatList"
							:key="index"
							:label="format.label"
							:value="format.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="默认时间">
					<el-select v-model="viewConfig.defaultDate" placeholder="请选择默认时间" @change="emitData">
						<el-option label="不设置" value=""></el-option>
						<el-option label="当日为默认" value="today"></el-option>
					</el-select>
				</el-form-item>
			</template>
			<template v-else-if="viewConfig.type === 'during'">
				<el-form-item label="时间类型">
					<el-select v-model="viewConfig.dateType"  @change="emitData">
						<el-option label="年月日" value="daterange"></el-option>
						<el-option label="年月" value="monthrange"></el-option>
						<el-option label="年月日时分秒" value="datetimerange"></el-option>
					</el-select>
				</el-form-item>
				<!-- 时间的格式 -->
				<el-form-item label="时间格式">
					<el-select v-model="viewConfig.dateFormat" clearable placeholder="请选择时间格式" @change="emitData">
						<el-option
							v-for="(format, index) in formatList"
							:key="index"
							:label="format.label"
							:value="format.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="默认时间">
					<el-select v-model="viewConfig.defaultDate" placeholder="请选择默认时间" @change="emitData">
						<el-option label="不设置" value=""></el-option>
						<el-option label="截止今天(开始时间不限制)" value="today"></el-option>
						<el-option label="截止今天,近一周" value="todayWeek"></el-option>
						<el-option label="截止今天,近一月" value="todayMonth"></el-option>
						<el-option label="截止今天,近一年" value="todayYear"></el-option>
					</el-select>
				</el-form-item>
			</template>
			<!-- 架构限制 -->
			<el-form-item label="架构限制"  v-if="viewConfig.archiLimit">
				<SetArchiLimit v-model="viewConfig.archiLimit" @change="emitData"></SetArchiLimit>
			</el-form-item>
			<!-- 权限 -->
			<el-form-item label="权限设置"  v-if="viewConfig.permission">
				<SetPermissions v-model="viewConfig.permission" @change="emitData"></SetPermissions>
			</el-form-item>
			<!-- UI判断逻辑 -->
			<UILogic v-model="viewConfig.UILogic" @change="emitData"></UILogic>
		</el-form>
	</section>
</template>

<script>
import { getObjects, getViews } from '@/apis/data/index';
import { dateTypes, ruleList } from './options';
import ConfigStatistics from './ConfigStatistics';
import { dataInterface } from '@/apis/data';
import SetArchiLimit from '@/components/common/SetArchiLimit';
import SetPermissions from '@/components/common/SetPermissions';
import UILogic from '@/components/common/UILogic';
import MetaTraceIcon from '@/components/metaSelect/MetaTraceIcon.vue';

export default {
	name: 'FieldConfig',
	components: {
		ConfigStatistics,
		SetArchiLimit,
		SetPermissions,
		UILogic,
    MetaTraceIcon
	},
	props: {
		value: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			viewConfig: null,
			// 类型列表
			typeList: [
				{
					label: '输入框',
					value: 'input'
				},
				{
					label: '下拉选择框',
					value: 'select'
				},
				{
					label: '时间选择器',
					value: 'datepicker'
				},
				{
					label: '时间段',
					value: 'during'
				},
				{
					label: '单选框组',
					value: 'radio'
				},
				{
					label: '多选框组',
					value: 'checkbox'
				},
				{
					label: '省市区',
					value: 'address'
				},
				{
					label: 'tab选项',
					value: 'tabs'
				}
			],
			// 筛选项对象
			objectList: [],
			objLoading: false,
			// 筛选项视图
			viewList: [],
			viewLoading: false,
			// 时间类型
			dateTypes,
			// 逻辑规则类型(后端)
			ruleList,
      // 时间格式列表
      formatList: [
        {
          label: '默认',
          value: ''
        },
        {
          label: '年月日',
          value: 'yyyy-MM-dd'
        },
        {
          label: '年月日时分',
          value: 'yyyy-MM-dd HH:mm'
        },
        {
          label: '年月日时分秒',
          value: 'yyyy-MM-dd HH:mm:ss'
        },
        {
          label: '年月日时',
          value: 'YMDh'
        },
				{
					label: '年月',
					value: 'yyyy-MM'
				},
        {
          label: '月日',
          value: 'MM-dd'
        },
        {
          label: '月',
          value: 'M'
        },
        {
          label: '日',
          value: 'd'
        },
				{
					label: '年',
					value: 'yyyy'
				}
      ],
			// 下拉框阻断列表对象<Object>
			selectOptionsField: {},
			isToggle: false
		};
	},
	watch: {
		value: {
			handler() {
				this.viewConfig = this.value;
			}
		},
		deep: true
	},
	created() {
		this.viewConfig = {
			permission: [], // 权限
			archiLimit: [], // 架构限制	
			UILogic: null,
			defaultDate: '',
			...this.value
		};
		const { type, optionOrign, optionConfig } = this.viewConfig;
		if (type === 'select' || type === 'radio' || type === 'checkbox' || type === 'tabs') {
			if (optionOrign === 'database') {
				const { objectUUID, viewUUID } = optionConfig;
				if (objectUUID && viewUUID) {
					this.onGetFieldList();
				}
			}
		}
	},
	methods: {
  /**
   * @desc: 切换显示
   */
		onToggle() {
			this.isToggle = !this.isToggle;
		},
		/**
		 * @desc: 移除选项
		 * @param {Number} index 下标
		 * @param {String} action 操作
		 */
		onModifyOption(index, action) {
			if (action === 'remove') {
				this.viewConfig.options.splice(index, 1);
			} else if (action === 'plus') {
				this.viewConfig.options.splice(index + 1, 0, {
					label: '',
					value: '',
					widthStatistics: false,
					defaultSelect: false,
					statisticsConfig: [
						{
							key: 'count', // 统计字段
							view_uuid: '', // 视图
							field_uuid: '', // 字段UUID 可以不填
							aggregate: 4 // 统计函数
						}
					],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				});
			}
		},
		/**
		 * @desc: 切换选项类型
		 * @param {String} val 选项类型
		 */
		onOptionOrigin() {
			this.emitData();
			if (!this.objectList || !this.objectList.length) {
				getObjects().then((res) => {
          if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		},
		/**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.objLoading = true;
			getObjects({ name }).then((res) => {
				if (res && res.data && res.data.data) {
					const data = res.data.data;
					this.objectList = data.data || [];
				}
				this.objLoading = false;
			}).catch((err) => {
				console.log(err);
				this.objectList = [];
				this.objLoading = false;
			});
		},
		/**
		 * @desc: 获取视图
		 */
		onGetViewList() {
			this.viewConfig.optionConfig.viewUUID = '';
			this.viewList = [];
			this.$loading();
			getViews({
				object_uuid: this.viewConfig.optionConfig.objectUUID,
				size: 200
			}).then((res) => {
				if (res && res.data && res.data.data) {
					const data = res.data.data;
					this.viewList = data.data || [];
				}
				this.$loading().close();
			}).catch((err) => {
				console.log(err);
				this.$loading().close();
			});
      this.emitData();
		},
		/**
		 * @desc: 获取字段
		 */
		onGetFieldList() {
			const { viewUUID, objectUUID } = this.viewConfig?.optionConfig || {};
			if (!objectUUID || !viewUUID) return;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: objectUUID,
				view_uuid: viewUUID
			}).then(res => {
				this.selectOptionsField = res?.data?.metadata || {};
			}).catch(err => {
				console.log(err);
				this.$message.error('获取字段失败!')
			});
		},
		/**
		 * @desc: 提交编辑数据
		 */
		emitData() {
			this.$emit('input', this.viewConfig);
		}
	}
};
</script>

<style lang="less" scoped>
.field-config {
	width: 100%;
	border: 1px solid #f2f3f5;
	margin: 5px auto;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 10px;
	&.active {
		border: 1px solid @theme;
	}
	.header{
		display: flex;
		.el-checkbox{
			line-height: 28px;
		}
		p{
			flex: 1;
			line-height: 28px;
			text-align: left;
			box-sizing: border-box;
			padding: 0 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		a{
			left: 28px;
			color: #409EFF;
		}
	}
	.el-form-item {
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
		.fixed-list {
			.option-item {
				display: flex;
				border-bottom: 1px dashed #f2f3f5;
				.width-static{
					display: flex;
					margin-top: 5px;
					border-radius: 6px;
					box-sizing: border-box;
					padding: 3px 5px;
					border: 1px solid #f2f3f5;
					p{
						flex: 1;
						text-align: left;
						font-size: 12px;
					}
					.el-switch {
						margin-top: 5px;
					}
				}
				.actions {
					box-sizing: border-box;
					margin-left: 10px;
					i {
						font-size: 20px;
						&.remove {
							color: @dangerColor;
						}
						&.plus {
							font-size: 14px;
							background: @theme;
							color: #fff;
							padding: 2px;
							border-radius: 50%;
						}
					}
				}
			}
		}
	}
}
</style>
