<!--
 * @Description: 基础配置
 * @Author: luocheng
 * @Date: 2022-04-21 17:58:49
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-05-13 09:21:57
-->
<template>
  <div class="base-config">
    <el-form v-model="baseConfig" label-width="100px">
      <el-form-item label="解析类型" class="is-required">
        <el-select v-model="baseConfig.solveType" placeholder="请选择解析类型">
          <el-option label="表单" value="form"></el-option>
          <el-option label="数据表" value="table"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作类型" class="is-required">
        <el-select v-model="baseConfig.type" placeholder="请选择操作类型">
          <el-option label="新增/导入" value="add"></el-option>
          <el-option label="编辑" value="edit"></el-option>
          <el-option label="详情" value="info"></el-option>
          <el-option label="导出" value="export"></el-option>
          <!-- <el-option label="详情" value="dataInfo"></el-option> -->
          <!-- <el-option label="列表" value="dataList"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item label="基础模板">
        <el-select
          v-model="baseConfig.excelTemplate"
          clearable
          filterable
          remote
          :remote-method="getExcelTemplate"
          :loading="templateLoading"
          placeholder="请选择基础Excel模板"
        >
          <el-option
            v-for="temp in templateList"
            :key="temp.id"
            :label="temp.name"
            :value="temp.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="导入查重" v-if="baseConfig.solveType === 'table'">
        <el-switch v-model="baseConfig.checkOnly"></el-switch>
      </el-form-item>
      <el-form-item label="自动匹配模板">
        <el-switch v-model="baseConfig.autoTemplate"></el-switch>
      </el-form-item>
      <el-form-item label="开启弹窗">
        <el-switch v-model="baseConfig.enableDialog"></el-switch>
      </el-form-item>
      <el-form-item label="弹窗标题" v-show="baseConfig.enableDialog">
          <el-input v-model="baseConfig.dialogTitle"></el-input>
      </el-form-item>
    </el-form>
    <footer class="actions">
      <el-button type="primary" size="mini" @click="onSave">保存</el-button>
    </footer>
  </div>
</template>

<script>
import { dataInterface } from "@/apis/data";
import { mapState } from "vuex";

export default {
  name: "BaseConfig",
  data() {
    return {
      baseConfig: {
        type: "",
        excelTemplate: "",
        autoTemplate: false, // 自动匹配模板
      },
      templateList: [],
      templateLoading: false,
    };
  },
  computed: {
    ...mapState(["curComponent"]),
  },
  created() {
    this.getExcelTemplate();
    this.baseConfig = {
      ...this.curComponent.baseConfig,
    };
  },
  methods: {
    /**
     * @desc: 获取模板列表
     */
    getExcelTemplate(value = "") {
      this.templateLoading = true;
      dataInterface({
        object_uuid: "object617fbedcefab4",
        view_uuid: "view61baf4e99b7b3",
        __method_name__: "dataList",
        search: [
          {
            field_uuid: "field617fbedcf29d2",
            ruleType: "like",
            value,
          },
        ],
        transcode: 0,
      })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const data = res.data.data;
            this.templateList = data.data || [];
          }
          this.templateLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.templateList = [];
          this.templateLoading = false;
        });
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          baseConfig: this.baseConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.base-config {
  width: 100%;
  .database-box {
    box-sizing: border-box;
    padding-left: 10px;
    display: block;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>