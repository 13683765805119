<!--
 * @Description: 图层右键菜单
 * @Author: luocheng
 * @Date: 2021-10-19 17:31:52
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-05 18:32:11
-->
<template>
  <ul class="layout-menu" v-show="value">
    <section class="layout-box">
     <div class="input-box">
       <el-input v-model="componentName" placeholder="请输入组件名称(1-10哥子)" class="menu"
        minlength="1"
        maxlength="10"
      >
      </el-input>
      <el-button type="text" :disabled="confirmDisabled" @click="onRename">确认</el-button>
     </div>
      <li class="menu delete" @click="onDelete">删除组件</li>
    </section>
    <div class="mask" @click="onClose"></div>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LayoutMenu',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    modifyComponent: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      componentName: ''
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ]),
    confirmDisabled() {
      return !this.componentName || this.componentName === this.modifyComponent?.name;
    }
  },
  watch: {
    modifyComponent: {
      handler() {
        this.componentName = this.modifyComponent?.name || '';
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClose(e) {
      e.preventDefault();
			e.stopPropagation();
      this.$emit('input', false);
    },
    /**
     * @desc: 删除
     */
    onDelete() {
      if (!this.curComponent) return;
      this.$confirm('是否确认删除该组件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit('deleteComponent', this.curComponent);
          this.$emit('input', false);
        }).catch(() => {});
    },
    onRename(e) {
      this.$store.commit('modifyComponent', {
				component: {
          ...this.curComponent,
          name: this.componentName
        },
				containerId: null,
				isModify: true
			});
			// this.onModify(component, false);
      // this.showMenu = false;
      this.onClose(e);
    }
  }
}
</script>

<style lang="less" scoped>
.layout-menu{
  position: absolute;
  right: 2px;
  border-radius: 4px;
  border: 1px solid #f2f3f5;
  background: #fff;
  box-shadow: 5px 5px 20px 5px rgba(72, 134, 255, 0.1);
  z-index: 1000;
  width: 180px;
  .layout-box{
    position: relative;
    z-index: 2000;
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    :deep(.el-input) {
      width: 120px;
    }
    .input-box{
      display: flex;
      .el-input{
        flex: 1;
      }
      .el-button{
        padding: 0 5px;
      }
    }
    .menu{
      width: 100%;
      height: 30px;
      text-align: center;
      cursor: pointer;
      line-height: 30px;
      text-align: center;
      margin-bottom: 5px;
      &:last-of-type{
        margin: 0;
      }
      &:hover{
        font-weight: bold;
        background: rgba(0, 0, 0, .1);
      }
      &.delete{
        margin-top: 5px;
        color: @dangerColor;
      }
    }
  }
  .mask{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: transparent;
    z-index: 10;
  }
}
</style>