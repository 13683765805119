<!--
 * @Description: 选择布局
 * @Author: luocheng
 * @Date: 2021-08-13 15:40:36
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-08 17:21:43
-->
<template>
	<el-dialog
		class="select-layout"
		title="基础布局设置"
		:visible.sync="value"
		width="99%"
		top="1vh"
		:lock-scroll="true"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
		:destroy-on-close="true"
		center
	>
		<article class="content">
			<el-steps :active="step" simple finish-status="success">
				<el-step title="选择布局模板"></el-step>
				<el-step title="配置"></el-step>
				<el-step title="确认"></el-step>
			</el-steps>
      <article class="main">
        <!-- 第一步选择模板 -->
        <LayoutList
          @getLayout="selectLayout"
          v-if="step === 0"
          :defaultId="layoutObj ? layoutObj.id : 0">
        </LayoutList>
      </article>
		</article>
		<span slot="footer" class="dialog-footer">
      <el-button type="default" @click="prevStep" v-if="step > 0">上一步</el-button>
      <el-button type="primary" @click="nextStep"
        v-if="step < 2"
        :disabled="(step === 0 && !layoutObj)"
      >下一步</el-button>
      <el-button type="success" @click="onConfirm" v-if="isLast">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { Step, Steps } from 'element-ui';
import LayoutList from './LayoutList';

export default {
	name: 'SelectLayout',
	components: {
		'el-steps': Steps,
		'el-step': Step,
    LayoutList
	},
	props: {
		// 控制显隐
		value: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	data() {
		return {
			// 当前步骤
			step: 0,
      // 布局模板
      layoutObj: null
		};
	},
  computed: {
    // 是否最后一步
    isLast() {
      return this.step >= 2;
    }
  },
	methods: {
    /**
     * @desc: 选择布局模板
     * @param {Object} layout 
     * @return {*}
     */
    selectLayout(layout) {
      this.layoutObj = layout;
    },
		/**
		 * @desc: 确认
		 */
		onConfirm() {
			this.$emit('input', false);
			this.$store.commit('setLayout', this.layoutObj);
		},
    /**
     * @desc: 上一步
     */
    prevStep() {
      if (this.step > 0) {
        this.step--;
      }
    },
    /**
     * @desc: 下一步
     */
    nextStep() {
      if (this.step < 2) {
        this.step++;
      }
    }
	}
};
</script>

<style lang="less" scoped>
.select-layout {
	:deep(.el-dialog) {
		height: 98vh;
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		.el-dialog__body {
			flex: 1;
			overflow: hidden;
			width: 100%;
			padding: 0;
			.content {
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				overflow: hidden;
				padding: 10px 25px;
				.main {
					flex: 1;
					width: 100%;
					overflow: hidden;
					overflow-y: auto;
          box-sizing: border-box;
          padding: 10px;
          // background: #f2f3f5;
				}
			}
		}
	}
	:deep(.is-process) {
		color: @theme;
    border-color: @theme;
	}
}
</style>
