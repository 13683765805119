/*
 * @Description: 事件配置
 * @Author: luocheng
 * @Date: 2021-08-26 16:59:39
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-07 09:39:18
 */

export const defaultList = [
  {
    label: 'click -> 点击事件',
    value: 'click',
    disabled: false
  },
  {
    label: 'focus -> 获取焦点事件',
    value: 'focus',
    disabled: true
  },
  {
    label: 'blur -> 失去焦点事件',
    value: 'blur',
    disabled: true
  },
  {
    label: 'change -> 内容改变事件',
    value: 'change',
    disabled: true
  },
  {
    label: 'input -> 输入事件',
    value: 'input',
    disabled: true
  },
  {
    label: 'select -> 选中事件',
    value: 'select',
    disabled: true
  }
];

// 可选操作
export const actionList = [
  {
    label: '显示',
    value: 'show'
  },
  {
    label: '隐藏',
    value: 'hidden'
  },
  {
    label: '虚拟显示',
    value: 'displayShow'
  },
  {
    label: '虚拟隐藏',
    value: 'displayHidden'
  },
  {
    label: '缓存显示',
    value: 'cacheDisplay'
  },
  {
    label: '缓存隐藏',
    value: 'cacheHidden'
  }
];
