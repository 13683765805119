<!--
 * @Description: 固定值配置
 * @Author: luocheng
 * @Date: 2021-11-17 15:34:16
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:57:25
-->
<template>
	<div class="fixed-config">
		<el-form :model="item" class="tab-item" v-for="(item, index) in configList" :key="index" label-width="100px">
			<section class="tab-content">
				<el-form-item label="首选项">
					<el-switch v-model="item.isFirst" size="mini" @change="onSwitch(index)"></el-switch>
				</el-form-item>
				<el-form-item label="说明文字">
					<el-input v-model="item.label" size="mini" placeholder="请输入说明文字"></el-input>
				</el-form-item>
				<el-form-item label="key字段">
					<el-input v-model="item.type" size="mini" placeholder="请输入绑定值"></el-input>
				</el-form-item>
			</section>
			<i class="icon el-icon-delete" @click="onRemove(index)"></i>
		</el-form>
		<footer class="actions">
			<el-button type="success" size="mini" @click="onPlus">新增</el-button>
			<el-button type="primary" size="mini" @click="onConfirm">确认配置</el-button>
		</footer>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'FixedConfig',
	data() {
		return {
			// 固定配置
			configList: [
				{
					isFirst: true,
					label: '',
					type: '',
					eventList: []
				}
			]
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	created() {
		this.configList = this.curComponent.tabConfig;
	},
	methods: {
		/**
		 * @desc: 确认配置
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					tabConfig: this.configList
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 移除
		 * @param {Number} index
		 */
		onRemove(index) {
			this.configList.splice(index, 1);
		},
		/**
		 * @desc: 追加配置
		 */
		onPlus() {
			this.configList.push({
				isFirst: false,
				label: '',
				type: '',
				eventList: []
			});
		},
		/**
		 * @desc: 设为首选项
		 */
		onSwitch(index) {
			if (!this.configList[index].isFirst) return;
			this.configList = this.configList.map((ele, idx) => {
				return {
					...ele,
					isFirst: idx === index
				};
			});
		}
	}
};
</script>

<style lang="less" scoped>
.fixed-config {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	.tab-item {
		width: 100%;
		display: flex;
		overflow: hidden;
		overflow-y: auto;
		.el-form-item {
			margin-bottom: 10px;
		}
		.icon {
			margin-left: 10px;
			font-size: 18px;
			line-height: 110px;
			color: @dangerColor;
			font-weight: bold;
		}
	}
	.actions {
		display: flex;
		.el-button{
			flex: 1;
		}
	}
}
</style>
