<!--
 * @Author: ttheitao
 * @Description: 页面关系分析
 * @Date: 2022-08-12 09:24:30
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-15 18:02:48
-->
<template>
  <div class="page-meta-container">
    <div class="tree">
      <div class="title">页面关系</div>
      <div class="main">
        <el-tree
          :data="data"
          :props="props"
          show-checkbox
          @check-change="handleCheckChange"
          :default-expand-all="true"
        >
        </el-tree>
      </div>
    </div>
    <div class="list">
      <div class="title">选中项</div>
      <div class="item">
        <div class="name">秦考核配置</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from './baseMixin.js';
import { Tree } from 'element-ui';
import page from '@/api/page.js';

export default {
  name: 'GlobalPageMeta',
  mixins: [BaseMixin],
  components: {
    'el-tree': Tree,
  },
  data() {
    return {
      data: [],
      dataFlatten: {},
      props: {
        label: 'name',
        children: 'children',
      },
      autoId: 0,
    };
  },
  computed: {
    uuid: function () {
      return this.config.uuid || '';
    },
  },
  created() {
    if (this.uuid) {
      this.getData(this.data);
    }
  },
  methods: {
    getData(parentData) {
      page
        .pageInfo({ page_uuid: this.uuid })
        .then((res) => {
          if (res.data.code === 200) {
            for (let i in res.data.data) {
              let parent = res.data.data[i];
              let node = {
                id: this.getAutoId(),
                name: parent.name,
                children: [],
                origin: null,
              };

              if (this.dataFlatten[node.uuid]) {
                node.origin = this.dataFlatten[parent.uuid];
              } else {
                this.dataFlatten[parent.uuid] = parent;
              }

              parentData.push(node);

              for (let s in parent.objects) {
                let subObject = parent.objects[s];
                let subNode = {
                  id: this.getAutoId(),
                  name: subObject.name,
                  children: [],
                  origin: null,
                };

                if (this.dataFlatten[subNode.uuid]) {
                  subNode.origin = this.dataFlatten[parent.uuid];
                } else {
                  this.dataFlatten[parent.uuid] = parent;
                }

                node.children.push(subObject);
              }
            }
          }
        })
        .catch((err) => {
          this.$message.error('获取数据失败');
          console.error(err);
        });
    },
    getAutoId() {
      return ++this.autoId;
    },
    handleCheckChange() {},
  },
};
</script>

<style lang="less" scoped>
.page-meta-container {
  display: flex;
  flex-wrap: nowrap;
  height: 500px;

  .tree {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #f2f3f5;
    height: 100%;

    .main {
      left: 1;
      overflow: auto;
    }
  }

  .list {
    flex: 1;
    margin-left: 20px;
    border: 1px solid #f2f3f5;
    height: 100%;
    overflow: auto;

    .item {
      text-align: left;
    }
  }

  .title {
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    text-align: left;
    font-weight: bold;
    background-color: #f5f7fa;
  }
}
</style>
