<template>
  <i class="el-icon-postcard click-icon" />
</template>

<script>
export default {
  name: 'MetaClickIcon',
};
</script>

<style lang="less" scoped>
.click-icon {
  font-size: 16px;
  color: #67c23a;
  cursor: pointer;
}
</style>
