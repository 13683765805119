<!--
 * @Author: zx
 * @Date: 2022-08-05 11:14:08
 * @LastEditTime: 2022-08-11 15:19:40
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="title-config">
    <el-form ref="form" :model="formData" label-width="100px">
      <template>
        <div v-for="(item,index) in formData.tabs" :key="'tab'+index" class="tab-item"> 
          <div class="input-wrap">
            <el-form-item :label="`tab${index+1}`">
              <el-input v-model="item.title" :placeholder="`请输入tab${index+1}`"></el-input>
            </el-form-item>
            <el-form-item :label="`key值${index+1}`">
              <el-input v-model="item.type" :placeholder="`请输入key值${index+1}`"></el-input>
            </el-form-item>
            <el-form-item label="日历显示样式">
              <el-select
                v-model="item.styleConfig"
                placeholder="请选择"
                filterable
                clearabl
                size="mini"
              >
                <el-option
                  v-for="item in styleConfigList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否默认选中">
              <el-switch v-model="item.isDefault" @change="changeSwitch($event,index)"></el-switch>
            </el-form-item>
          </div>
          <div class="icon-wrap">
            <i class="el-icon-circle-plus" @click="addTabs(index)"></i>
            <i style="color:red;" class="el-icon-remove" @click="delTabs(index)"></i>
          </div>
        </div>
        <div v-if="!formData.tabs || (Array.isArray(formData.tabs) && formData.tabs.length === 0)" class="add-icon">
          <el-button class="add-btn" type="primary" @click="addTabs">新增tab</el-button>
        </div>
      </template>
    </el-form>
    <el-button class="confirm-btn" type="primary" @click="confirmConfig">确认配置</el-button>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { flatTree } from '@/utils/tools';

export default {
  components:{
  },
  data(){
    return{
      formData:{
        tabs:[]
      },
      targetList:[],
      styleConfigList:[
        {
          name: '展示数量',
          value: 'number',
        },
        {
          name: '展示数量及总数',
          value: 'numberTotal',
        },
        {
          name: '展示状态',
          value: 'status',
        }
      ],
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData', // 组件数据
      'sContainer',
      'originComponentData'
    ]),
  },
  created() {
    if (this.sContainer) {
			this.targetList = flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, []);
		} else {
			this.targetList = flatTree(this.componentData, 'children', {
					label: 'name',
					value: 'id'
				}, [])
		}
  },
  methods:{
    changeSwitch(bol,i){
      if(bol){
        this.formData.tabs.map((item,index)=>{
          if(index!==i){
            item.isDefault = false;
          }
        })
      }
    },
    addTabs(index){
      if (index || index === 0) {
        this.formData.tabs.splice(index+1, 0, {
            tab:'',
            title:'',
            targetComponent:'',
            isDefault:false
          })
      } else {
        this.formData.tabs = []
        this.formData.tabs.push(
          {
            tab:'',
            title:'',
            targetComponent:'',
            isDefault:false
          }
        )
      }
      
    },
    delTabs(i){
      this.formData.tabs.splice(i,1)
    },
    confirmConfig(){
      let copyCommponents = {
        ...this.curComponent
      }
      copyCommponents.tabConfig = this.formData
      this.$store.commit("modifyComponent", {
        component: {
          ...copyCommponents
        },
        containerId: null,
        isModify: true,
      })
      this.$message.success('保存成功!');
    }
  },
  mounted(){
    if (this.curComponent.tabConfig) {
      this.formData = this.curComponent.tabConfig
    }
  }
}
</script>
<style lang="less" scoped>
.tab-item{
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .input-wrap{
    flex: 1;
    text-align: left;
  }
  .icon-wrap{
    padding-left: 10px;
    font-size: 24px;
  }
}
.confirm-btn{
  margin-top: 10px;
}

.add-icon {
  width: 100%;
  .add-btn {
    width: 100%;
  }
}
</style>