<!--
 * @Author: zqy
 * @Date: 2022-06-23 09:06:33
 * @LastEditTime: 2022-10-14 11:14:56
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="back-config">
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="返回类型">
        <el-select v-model="formData.topBackType" placeholder="请选择">
          <el-option
            v-for="item in typeOptions"
            :key="item.key"
            :label="item.name"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="固定功能" v-if="formData.topBackType === 'fixedRoute'">
        <el-input v-model="formData.fixedRoutePath" placeholder="请输入固定功能"></el-input>
      </el-form-item>
      <el-form-item label="选择页面" v-if="formData.topBackType === 'appointPage'">
        <el-select v-model="formData.appointPageUuid" placeholder="请选择">
          <el-option
            v-for="item in allPages"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="confirm-btn" type="primary" @click="confirmConfig">确认配置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';

export default {
  data(){
    return{
      formData:{
        topBackType:'',
        fixedRoutePath:'',
        appointPageUuid:''
      },
      typeOptions:[
        {
          key:'beforePage',
          name:'上一页'
        },{
          key:'homePage',
          name:'首页'
        },
        {
          key:'fixedRoute',
          name:'固定功能'
        },
        {
          key:'appointPage',
          name:'指定页面'
        },
      ] ,
      allPages:[],//所有的h5 页面
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData', // 组件数据
      'sContainer',
      'originComponentData'
    ]),
  },
  methods:{
    confirmConfig(){
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          routeBackConfig:this.formData,
        },
        containerId: null,
        isModify: true,
      })
      this.$message.success('保存成功!');
    },
    getAllPages(){
      const params = {
        object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
        page: 1,
        search: [{field_uuid: "field61bc41f645e17", ruleType: "like", value: "mobile"}],
        field_uuid: "field61bc41f645e17",
        ruleType: "like",
        value: "mobile",
        size: 1000000000,
        transcode: 0,
        view_uuid: "view61922b1881103",
        __method_name__: "dataList"
      }
      dataInterface(params).then(res=>{
        if(res && +res.data.code === 200){
          const data = res.data.data.data;
          this.allPages = data;
        }
      })
    }
  },
  mounted(){
    if(this.curComponent.routeBackConfig){
      this.formData = this.curComponent.routeBackConfig
    }
    this.getAllPages();
  }
}
</script>
<style lang="less" scoped>
.back-config{

}
</style>