/*
 * @Description: 组合
 * @Author: luocheng
 * @Date: 2021-08-11 14:24:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-01 17:45:23
 */
/* eslint-disable */
import { $ } from './tools';
// import { mod360 } from './translate';
/**
 * @desc: 将组合中的各个子组件拆分出来，并计算它们新的 style
 * @param {Object} component 组件
 * @param {Object} editorRect
 * @param {Object} parentStyle
 */
export default function splitCompose(component, editorRect, parentStyle) {
	const componentRect = $(`#component${component.id}`).getBoundingClientRect();
	// 获取元素的中心点坐标
	const center = {
		x: componentRect.left - editorRect.left + componentRect.width / 2,
		y: componentRect.top - editorRect.top + componentRect.height / 2
	};
  const result = {
    ...component
  };
	// result.style.rotate = mod360(component.style.rotate + parentStyle.rotate);
	// result.style.width = (parseFloat(component.groupStyle.width) / 100) * parentStyle.width;
	// result.style.height = (parseFloat(component.groupStyle.height) / 100) * parentStyle.height;
	// 计算出元素新的 top left 坐标
	result.style.left = center.x - component.style.width / 2;
	result.style.top = center.y - component.style.height / 2;
	result.groupStyle = {};
  return result;
}
