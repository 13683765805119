<!--
 * @Description: 内容配置
 * @Author: luocheng
 * @Date: 2021-09-29 18:04:03
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-09 10:44:06
-->
<template>
	<div class="content-config">
		<header class="header">
			<span class="line"></span>
			<p class="item-title">内容配置</p>
			<el-button type="primary" @click="onConfirm"
				:disabled="!configForm.contentType && !configForm.objectUUID">
				确认配置
			</el-button>
			<a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
				{{ toggleShow ? '收起' : '展开' }}
			</a>
		</header>
		<el-form :model="configForm" label-width="80px" v-show="toggleShow">
			<!-- 预留 -->
			<el-form-item label="内容类型" v-if="!['CommonFormContainer', 'CommonPageContainer'].includes(type)">
				<el-select v-model="configForm.contentType" placeholder="请选择弹窗内容" @change="onContentType">
					<el-option label="外部表单" value="outForm"></el-option>
					<el-option label="表格模式" value="luckySheet"></el-option>
					<el-option label="配置页面" value="configPage"></el-option>
					<!-- <el-option
						label="手写页面"
						value="developPage"
					></el-option> -->
				</el-select>
			</el-form-item>
			<el-form-item label="配置方式" v-if="type !== 'CommonPageContainer'">
				<el-select v-model="configForm.configType" placeholder="请选择配置方式" @change="onConfigType">
					<el-option label="静态配置" value=""></el-option>
					<el-option label="动态取值" value="dynamic"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="取值组件" v-if="configForm.configType === 'dynamic'">
				<el-select v-model="configForm.dynamicComponent" placeholder="请选择动态取值组件" @change="onComponent">
					<el-option
						:label="comItem.name"
						:value="comItem.id"
						v-for="comItem in componentList"
						:key="comItem.id"
					></el-option>
				</el-select>
			</el-form-item>
			<!-- 表单 -->
			<template
				v-if="
					type === 'CommonFormContainer' ||
					configForm.contentType === 'outForm' ||
					configForm.contentType === 'luckySheet'
				"
			>
				<el-form-item label="数据对象">
					<!-- 外部表单 传递objectID进入即可 -->
					<el-select
						v-model="configForm.objectUUID"
						filterable
						remote
						:remote-method="onRemoteObject"
						:loading="objLoading"
						@change="onObjectUUID"
					>
						<template v-if="configForm.configType === 'dynamic'">
							<el-option v-for="(label, key) in fieldList"
								:key="key"
								:label="label"
								:value="key">
							</el-option>
						</template>
						<template v-else>
							<el-option v-for="obj in objectList" :key="obj.id" :label="obj.name" :value="obj.uuid"></el-option>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item label="数据视图" v-if="configForm.objectUUID">
					<!-- 视图 -->
					<el-select
						v-model="configForm.viewUUID"
						filterable
						remote
						:remote-method="onRemoteView"
						:loading="viewLoading"
					>
						<template v-if="configForm.configType === 'dynamic'">
							<el-option v-for="(label, key) in fieldList"
								:key="key"
								:label="label"
								:value="key">
							</el-option>
						</template>
						<template v-else>
							<el-option v-for="view in viewList" :key="view.id" :label="view.name" :value="view.uuid"></el-option>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item label="表单对象" v-if="configForm.objectUUID">
					<!-- 表单对象 -->
					<el-select
						v-model="configForm.formUUID"
						filterable
						remote
						:remote-method="onRemoteForm"
						:loading="formLoading"
					>
					<template v-if="configForm.configType === 'dynamic'">
						<el-option v-for="(label, key) in fieldList"
							:key="key"
							:label="label"
							:value="key">
						</el-option>
					</template>
					<template v-else>
						<el-option
							v-for="formObj in formList"
							:key="formObj.id"
							:label="formObj.name || '默认'"
							:value="formObj.uuid"
						></el-option>
					</template>
					</el-select>
				</el-form-item>
				<!-- 表格模板 -->
				<el-form-item label="Excel模板">
					<el-select
						v-model="configForm.excelTemplate"
						clearable
						filterable
						remote
						:remote-method="getExcelTemplate"
						:loading="templateLoading"
						placeholder="请选择Excel模板"
					>
						<template v-if="configForm.configType === 'dynamic'">
							<el-option v-for="(label, key) in fieldList"
								:key="key"
								:label="label"
								:value="key">
							</el-option>
						</template>
						<template v-else>
							<el-option v-for="temp in templateList" :key="temp.id" :label="temp.name" :value="temp.uuid"></el-option>
							<template slot="prefix">
								<GlobalFactory
									v-if="configForm.excelTemplate"
									name="Excel"
									title="编辑Excel模板"
									:fullscreen="true"
									:config="{ uuid: configForm.excelTemplate }"
								>
									<MetaClickIcon />
								</GlobalFactory>
							</template>
						</template>
					</el-select>
				</el-form-item>
			</template>
			<!-- 页面配置 -->
			<template v-else-if="
				configForm.contentType === 'configPage' ||
				type === 'CommonPageContainer'
			">
				<el-form-item label="页面配置">
					<el-select
						v-model="configForm.pageId"
						placeholder="请选择页面"
						filterable
						remote
						:remote-method="onRemotePage"
						:loading="pageLoading"
					>
						<template v-if="configForm.configType === 'dynamic'">
							<el-option v-for="(label, key) in fieldList"
								:key="key"
								:label="label"
								:value="key">
							</el-option>
						</template>
						<template v-else>
							<el-option v-for="item in pageList" :key="item.id" :label="item.name" :value="item.uuid"></el-option>
						</template>
					</el-select>
				</el-form-item>
			</template>
			<!-- 手写页面 -->
			<template v-else-if="configForm.contentType === 'developPage'">
				<el-form-item label="基础路由">
					<el-select v-model="configForm.developPageRoute" placeholder="青玄在基础路由字段" 
						v-if="configForm.configType === 'dynamic'">
							<el-option v-for="(label, key) in fieldList"
								:key="key"
								:label="label"
								:value="key">
							</el-option>
						</el-select>
					<el-input v-model="configForm.developPageRoute" placeholder="请输入基础路由" clearable v-else></el-input>
				</el-form-item>
			</template>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getObjects, getViews, dataInterface } from '@/apis/data/index';
import GlobalFactory from '@/global/GlobalFactory.vue';
import MetaClickIcon from '@/components/metaSelect/MetaClickIcon.vue';
import { flatTree, unique } from "@/utils/tools";

export default {
	name: 'ContentConfig',
	components: {
		GlobalFactory,
		MetaClickIcon
	},
	props: {
		type: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			toggleShow: false,
			// 配置表单
			configForm: {
				contentType: '',
				objectUUID: '',
				viewUUID: '',
				formUUID: ''
			},
			// 对象
			objLoading: false,
			objectList: [],
			// 视图
			// 对象
			viewLoading: false,
			viewList: [],
			// 表单对象
			formLoading: false,
			formList: [],
			// 页面
			pageLoading: false,
			pageList: [],
			// excel模板
			templateLoading: false,
			templateList: [],
			// 可选字段列表
			fieldList: []
		};
	},
	computed: {
		...mapState([
      'componentData',
      'curComponent',
      'originComponentData',
    ]),
		// 可选组件列表
    componentList() {
      const result =  flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id',
      }, []);
			return unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id',
      }, [], [])))
    },
	},
	created() {
		this.configForm = {
			contentType: 'outForm',
			configType: '',
			dynamicComponent: '',
			...this.curComponent.contentConfig
		};
		if (this.configForm.dynamicComponent) {
			this.onComponent();
		}
		this.onRemoteObject('');
		if (this.configForm.objectUUID) {
			this.onObjectUUID();
			// this.onRemoteView('');
		}
		this.onRemotePage('');
		this.getExcelTemplate();
	},
	methods: {
		/**
		 * @desc: 选择了弹窗内容
		 * @param {String} val
		 */
		onContentType(val) {
			if (val === 'configPage') {
				this.onRemotePage('');
			} else if (val === 'outForm') {
				this.onRemoteObject('');
			}
		},
		/**
		 * @desc: 切换配置方式
		 */
		onConfigType() {
			this.configForm = {
				...this.configForm,
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面UUid
				formUUID: '', // 表单对象
				developPageRoute: '', // 首页页面路由
			};
		},
		/**
		 * @desc: 选择组件
		 */
		onComponent() {
			const currentComponent = this.componentList.find(ele => ele.id === this.configForm.dynamicComponent);
			this.fieldList = currentComponent?.metadata || {};
		},
		/**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.objLoading = true;
			getObjects({ name, uuid: !name ? this.configForm.objectUUID : '' })
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
					this.objLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.objectList = [];
					this.objLoading = false;
				});
		},
		/**
		 * @desc: 获取可用表单对象
		 * @param {String} name <未启用>
		 */
		onRemoteForm(name) {
			this.formLoading = true;
			dataInterface(
				{
					object_uuid: this.configForm.objectUUID,
					name
				},
				'api/saas/metadata/getFormDesigns',
				'GET'
			)
				.then((res) => {
					console.log(res.data.data);
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.formList = data || [];
					}
					this.formLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.formList = [];
					this.formLoading = false;
				});
		},
		/**
		 * @desc: 选择对象
		 */
		onObjectUUID() {
			this.onRemoteView('');
			this.onRemoteForm('');
		},
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteView(name) {
			this.viewLoading = true;
			getViews({
				object_uuid: this.configForm.objectUUID,
				name,
				size: 200
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
					}
					this.viewLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.viewLoading = false;
				});
		},
		/**
		 * @desc: 确认配置
		 */
		onConfirm() {
			console.log(this.configForm, '----configForm---111-')
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					contentConfig: {
						...this.configForm
					}
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 远程搜索页面
		 * @param {String} name 关键字
		 */
		onRemotePage(name) {
			this.pageLoading = true;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
				search: [
					{
						field_uuid: 'd1f7e149-2af7-468e-85be-5ef9bd97f634',
						ruleType: 'like',
						value: name || ''
					}
				],
				page: 1,
				size: 30,
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					if (res && res.status === 200 && res.data && res.data.data) {
						this.pageList = res.data.data.data || [];
						this.pageLoading = false;
					}
				})
				.catch((err) => {
					console.log(err);
					this.pageList = [];
					this.pageLoading = false;
				});
		},
		/**
		 * @desc: 获取模板列表
		 */
		getExcelTemplate(value = '') {
			this.templateLoading = true;
			// , `${process.env.VUE_APP_BASE_URL}api/mapi`
			dataInterface({
				object_uuid: 'object617fbedcefab4',
				view_uuid: 'view61baf4e99b7b3',
				__method_name__: 'dataList',
				search: [
					{
						field_uuid: 'field617fbedcf29d2',
						ruleType: 'like',
						value
					}
				],
				transcode: 0
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.templateList = data.data || [];
					}
					this.templateLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.templateList = [];
					this.templateLoading = false;
				});
		}
	}
};
</script>

<style lang="less" scoped>
.content-config {
	.header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		.line {
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 8px;
			background: @theme;
		}
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 32px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 32px;
			padding-right: 4px;
		}
	}
}
</style>
