<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-20 14:01:00
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 16:32:15
 * @FilePath: /dataview-next/src/components/treeConfig/iconConfig/CustomList.vue
 * @Description: 自定义图标配置
-->
<template>
  <div class="custom-list">
    <h3 class="title">
      自定义配置
      <el-popover
        placement="top-start"
        title="提示"
        width="400"
        :center="true"
        content="支持图标、图片类型，自定义判断渲染。不输入将使用默认图标"
        trigger="click">
        <span slot="reference">
          <i class="el-icon-question"></i>
        </span>
      </el-popover>
    </h3>
    <el-form :model="customForm" label-width="90px">
      <header class="header-item">
        <p>1、前置图标配置</p>
        <a href="javascript:;" @click="onToggle('showPre')">{{ showPre ? '收起' : '展开' }}</a>
      </header>
      <CustomItem v-model="customForm.preList" :isShow="showPre" @change="onEmit"></CustomItem>
      <header class="header-item">
        <p>2、操作图标配置</p>
        <a href="javascript:;" @click="onToggle('showAction')">{{ showAction ? '收起' : '展开' }}</a>
      </header>
      <CustomItem v-model="customForm.actionList" :isShow="showAction" @change="onEmit"></CustomItem>
    </el-form>
  </div>
</template>

<script>
import { Popover } from 'element-ui';
import CustomItem from './CustomItem';

export default {
  name: 'CustomList',
  components: {
    CustomItem,
    'el-popover': Popover
  },
  props: {
    value: {
      type: Object,
      isRequired: true,
      default: () => {}
    },
  },
  data() {
    return {
      customForm: {
        preList: [], // 前置 { logics: {}, icon: '' }
        actionList: [] // 操作配置
      },
      showPre: false,
      showAction: false
    }
  },
  created() {
    this.customForm = this.value ?? this.customForm;
  },
  methods: {
    onEmit() {
      this.$emit('input', this.customForm);
    },
    /**
     * @description: 展开收起
     * @param {String} key
     */
    onToggle(key) {
      this[key] = !this[key];
    }
  }
}
</script>

<style lang="less" scoped>
.custom-list {
  width: 100%;
  .title {
    position: relative;
    margin-bottom: 10px;
    text-align: left;
    text-indent: 8px;
    &::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 14px;
      background: @theme;
      left: 0;
      top: 6px;
      border-radius: 1px;
    }
    .el-icon-question {
      color: @warnColor;
    }
  }
  .header-item {
    margin-bottom: 10px;
    display: flex;
    .el-button {
      margin: 0 10px;
    }
    p {
      flex: 1;
      text-align: left;
      font-weight: bold;
    }
  }
}
</style>