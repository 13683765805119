<!--
 * @Description: 配置数据仓库
 * @Author: luocheng
 * @Date: 2022-04-22 09:35:50
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-26 15:26:05
-->
<template>
  <div class="config-database">
    <template v-if="configList && configList.length">
      <DatabaseItem
        v-for="(item, index) in configList"
        :key="index"
        :config="item"
        :index="index"
        @remove="onRemove"
        @update="onChild"
      ></DatabaseItem>
    </template>
    <footer class="config-actions">
      <el-button type="success" size="mini" @click="onPlus">新增</el-button>
      <el-button type="primary" size="mini" @click="onSave">保存</el-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DatabaseItem from './DatabaseItem';

export default {
  name: 'DatabaseConfig',
  components: {
    DatabaseItem
  },
  data() {
    return {
      configList: [{
        objectUUID: '',
        viewUUID: '',
        templateField: ''
      }]
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.configList = this.curComponent.databaseList || [];
  },
  methods: {
    /**
     * @desc: 移除
     */
    onRemove(index) {
      this.configList.splice(index, 1);
    },
    onChild({ index, config }) {
      this.configList.splice(index, 1, {
        ...config
      });
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					databaseList: this.configList
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.configList.push({
        objectUUID: '',
        viewUUID: '',
        formUUID: '',
        templateField: ''
      });
    }
  }
}
</script>

<style lang="less" scoped>
.config-database{
  width: 100%;
  .config-actions{
    padding-top: 10px;
  }
}
</style>