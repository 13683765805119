<!--
 * @Description: 分组属性配置
 * @Author: luocheng
 * @Date: 2021-08-13 10:57:07
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-11-11 17:38:47
-->
<template>
	<div class="group-attr">
		<el-form :inline="true" class="attr-form" v-if="styleObj">
			<el-form-item :label="'栅格数'" v-if="(['template', 'page', 'card'].includes(editorType))  && curComponent.span !== undefined">
				<el-input type="number" max="24" placeholder="请输入栅格数" v-model="curComponent.span"></el-input>
			</el-form-item>
			<el-form-item
				:label="commonConfig[key]"
				v-for="(key, order) in styleKeys.filter((ele) => commonConfig[ele])"
				:key="order"
			>
				<!-- 通过选项 -->
				<el-select
					v-model="styleObj[key]"
					:placeholder="'请选择' + commonConfig[key]"
					v-if="selectKeys.includes(key)"
				>
					<el-option
						v-for="option in selectOptions[key]"
						:key="option.value"
						:label="option.label"
						:value="option.value"
					>
					</el-option>
				</el-select>
				<!-- 颜色 -->
				<section class="value-box" v-else-if="colorKeys.includes(key)">
					<el-color-picker
						v-model="styleObj[key]"
						show-alpha
						:predefine="predefineColors">
					</el-color-picker>
					<el-input type="string" v-model="styleObj[key]"></el-input>
				</section>
				<!-- 输入型 -->
				<el-input type="text" v-else :placeholder="'请输入' + commonConfig[key]" v-model="styleObj[key]" @input="onInput(key)">
				</el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ColorPicker } from 'element-ui';
import options from './options';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'GroupAttributes',
	components: {
		'el-color-picker': ColorPicker
	},
	data() {
		return {
			// 属性列表
			attributesList: [],
			// 基础配置项
			commonConfig: {
				height: '高度',
				width: '宽度',
				minHeight: '最小高度',
				maxHeight: '最大高度',
				minWidth: '最小宽度',
				maxWidth: '最大高度',
				marginTop: '外边距(上)',
				marginBottom: '外边距(下)',
				marginLeft: '外边距(左)',
				marginRight: '外边距(右)',
				paddingTop: '内边距(上)',
				paddingBottom: '内边距(下)',
				paddingLeft: '内边距(左)',
				paddingRight: '内边距(右)',
				overflowX: '横向',
				overflowY: '纵向',
				boxSizing: '盒模型',
				flex: '设为收集者',
				lineHeight: '行高',
				color: '字体颜色',
				backgroundColor: '背景颜色',
				backdropFilter:'背景模糊',
				borderWidth: '边框宽度',
				borderStyle: '边框样式',
				borderColor: '边框颜色',
				borderRadius: '圆角',
				top: 'Y坐标',
				left: 'X坐标',
				textAlign: '文本对齐',
				fontSize: '字体大小',
				rotate: '旋转角度'
			},
			// 使用
			selectKeys: ['overflowX', 'overflowY', 'borderStyle', 'textAlign'],
			selectOptions: {
				...options,
				overflowX: options.overflow,
				overflowY: options.overflow,
				textAlign: options.textAlign
			},
			// 颜色
			colorKeys: ['color', 'backgroundColor', 'borderColor'],
			// 预定义颜色
			predefineColors: ['#ff4500',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585',
					'#000000',
					'#ffffff'
			],
			// 样式对象
			styleObj: null,
			styleKeys: []
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前编辑组件
			'editorType', // 编辑器类型
			'moveStatus' // 移动状态
		])
	},
	mounted() {
		eventBus.$on('syncComponentStyleObj', () => {
			this.styleObj = this.curComponent?.style || {};
			this.styleKeys = this.curComponent ? Object.keys(this.curComponent.style) : [];
		})
	},
	watch: {
		curComponent: {
			handler() {
				if (!this.moveStatus) {
					this.styleObj = this.curComponent?.style || {};
					this.styleKeys = this.curComponent ? Object.keys(this.curComponent.style) : []
				}
				if (this.editorType === 'mobile' && this.curComponent.component === 'AddButton' && !this.commonConfig.right) {
					Object.assign(this.commonConfig, { right: '右边距', bottom: '下边距', zIndex: '层级' })
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
  /**
   * @desc: 输入(大屏情况将宽高数字类型固定)
   * @param {String} key 属性Key
   */
		onInput(key) {
			const includes = ['height', 'width', 'top', 'left', 'minHeight', 'minWidth']
			if (!includes.includes(key)) return;
			const val = +this.styleObj[key];
			if (isNaN(val)) return;
			this.styleObj[key] = val;
		}
	},
	beforeDestroy() {
		eventBus.$off('syncComponentStyleObj');
	}
};
</script>

<style lang="less" scoped>
.group-attr {
	width: 100%;
	// height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	:deep(.el-form)  {
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		overflow: hidden;
		overflow-y: auto;
		.el-form-item {
			display: flex;
			box-sizing: border-box;
			padding-left: 10px;
			margin: 0;
			box-sizing: border-box;
			padding: 8px 10px;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				background-color: #f5f7fa;
				.el-form-item__label {
					color: @theme;
				}
			}
			.el-form-item__label {
				width: 100px;
				text-align: left;
				cursor: pointer;
			}
			.el-form-item__content {
				flex: 1;
				.el-input {
					.el-input__inner {
						text-align: left;
					}
				}
			}
			.value-box{
				width: 100%;
				display: flex;
				.el-input{
					margin-left: 10px;
				}
			}
		}
	}
}
::-webkit-scrollbar {
	display: none; /* Chrome Safari */
}
</style>
