<!--
 * @Description: 动态配置
 * @Author: luocheng
 * @Date: 2022-07-19 15:05:43
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-19 15:26:27
-->
<template>
  <div class="database-config">
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item title="数仓配置" name="database">
        <DynamicConfig></DynamicConfig>
      </el-collapse-item>
      <el-collapse-item title="参数配置" name="params">
        <ParamsConfig></ParamsConfig>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import ParamsConfig from '@/components/common/ParamsConfig';
import DynamicConfig from './DynamicConfig';

export default {
  name: 'DatabaseConfig',
  components: {
    DynamicConfig,
    ParamsConfig
  },
  data() {
    return {
      activeNames: 'database'
    }
  }
}
</script>

<style lang="less" scoped>
.database-config{
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>