<!--
 * @Description: 容器的数据仓库配置
 *   1.首先选择来源可能来源于其他组件的关系图或独立数据源
 *   2.选择来源后需要选择视图(仅列表)
 *   3.通过对象ID进行字段集的获取
 *   4.绑定字段
 *   5.实际渲染时，使用数据中的接口进行请求
 * @Author: luocheng
 * @Date: 2021-09-07 10:59:13
 * @LastEditors: zx
 * @LastEditTime: 2022-10-17 16:44:06
-->
<template>
	<div class="container-database">
		<section class="origin" v-if="curComponent.database.mapping === 'object'">
			<p class="label type-label">数据类型：</p>
			<el-select style="margin-top:15px" v-model="requestType" @change="onType" placeholder="请选择数据类型">
				<el-option
					:label="type.label"
					:value="type.value"
					v-for="type in typeList"
					:key="type.value"
				></el-option>
			</el-select>
		</section>
		<!-- 分析值配置 -->
		<template v-if="requestType === 'moreAction'">
			<StatisticalConfig></StatisticalConfig>
			<!-- 参数配置 -->
			<ConfigParams></ConfigParams>
		</template>
		<!--  -->
		<template v-else>
			<!-- 选择数据对象 -->
			<SelectObject></SelectObject>
			<!-- 默认来源 数据对象 + 视图   方式 -->
			<template v-if="curComponent.database.mapping === 'object'">
				<!-- 选择视图 -->
				<SelectView v-if="canSelect"></SelectView>
			</template>
			<!-- 关联关系 -->
			<template v-else-if="curComponent.database.mapping === 'relation'">
				<!-- 关联关系 -->
				<SelectRelation v-if="canSelect"></SelectRelation>
			</template>
			<!-- 参数配置 -->
			<ConfigParams></ConfigParams>
			<template v-if="curComponent.component === 'MobileSelectTree' || curComponent.component === 'ThreeTerminal'">
				<!-- 移动端树形数据key值设置 -->
				<!-- 三端组件取消跳转配置 -->
				<MobileSelectTreeKey :hiddenJump="curComponent.component === 'ThreeTerminal'" @setting="setTreeInfo"></MobileSelectTreeKey>
			</template>
			<template v-if="curComponent.component === 'ConsoleTypeTree'">
				<!-- 中控台树形分类 -->
				<ConsoleTypeTreeConfig @setting="setTreeInfo" />
			</template>
			<template v-if="curComponent.component === 'ListContainerOne'">
				<!-- 移动端树形获取路由携带参数设置 -->
				<MobileGetQuery @setting="MobileGetQuery"></MobileGetQuery>
			</template>
			<el-button
				type="primary"
				:disabled="!canConfirm"
				class="confirm-config"
				@click="onConfirmConfig"
			>确认配置</el-button>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SelectObject from './SelectObject';
import SelectView from './SelectView';
import ConfigParams from './ConfigParams';
import eventBus from '@/plugins/eventBus';
import SelectRelation from './SelectRelation';
import StatisticalConfig from './StatisticalConfig';
import MobileSelectTreeKey from './MobileSelectTreeKey';
import ConsoleTypeTreeConfig from './ConsoleTypeTreeConfig';
import MobileGetQuery from './MobileGetQuery';

export default {
	name: 'ContainerDatabase',
	components: {
		SelectObject,
		SelectView,
		ConfigParams,
		SelectRelation,
		StatisticalConfig,
		MobileSelectTreeKey,
		MobileGetQuery,
		ConsoleTypeTreeConfig
	},
	data() {
		return {
			// 默认对象来源
			objectOrigin: 'default',
			// 数据请求类型
			requestType: '',
			typeList: [
				{
					label: '列表',
					value: 'dataList'
				},
				{
					label: '详情',
					value: 'dataInfo'
				},
				{
					label: '编辑/更新数据',
					value: 'updateData'
				},
				{
					label: '新增',
					value: 'createData'
				},
				{
					label: '删除',
					value: 'deleteData'
				},
				{
					label: '统计分析',
					value: 'moreAction'
				}
			],
			mobileTreeInfo: {},
			mobileListParamsInfo: {},
		};
	},
	computed: {
		...mapState([
			'curComponent' // 当前组件
		]),
		// 是否可以选择视图
		canSelect() {
			return this.curComponent?.database?.objectData
		},
		// 是否可以确认
		canConfirm() {
			if (this.curComponent.database.mapping === 'object') {
				return (
					this.curComponent &&
					this.curComponent.database &&
					this.curComponent.database.objectData &&
					this.curComponent.database.viewData
				);
			}
			if (this.curComponent.database.mapping === 'relation') {
				return (
					this.curComponent &&
					this.curComponent.database &&
					this.curComponent.database.objectData &&
					this.curComponent.database.relationData
				);
			}
			return false;
		}
	},
	created() {
		if (this.curComponent && this.curComponent.database) {
			this.requestType = this.curComponent.database.requestType;
		}
	},
	methods: {
		/**
		 * @desc: 选择请求类型
		 */
		onType() {
			this.$store.commit('setDatabaseRequestType', this.requestType);
		},
		/**
		 * @desc: 确认配置
		 */
		onConfirmConfig() {
			// 初始化获取参数
			eventBus.$emit('databaseTrigger', {
				componentId: this.curComponent.id,
				action: this.requestType,
				output: '',
				isInit: true
			});
			if (this.curComponent.component === 'MobileSelectTree' || 
				this.curComponent.component === 'ListContainerOne' || 
				this.curComponent.component === 'ConsoleList' || 
				this.curComponent.component === 'ConsoleTypeTree' ||
				this.curComponent.component === 'ThreeTerminal'
			) {
				const database = JSON.parse(JSON.stringify(this.curComponent.database))
				if (this.curComponent.component === 'MobileSelectTree' || this.curComponent.component === 'ThreeTerminal') {
					database.mobileTreeInfo = this.mobileTreeInfo
				} else if (this.curComponent.component === 'ListContainerOne') {
					database.mobileQeuryInfo = this.mobileListParamsInfo
				} else if(this.curComponent.component === 'ConsoleTypeTree'){ //中控台分类取值
					database.typeTreeInfo = this.mobileTreeInfo
				}
				this.$store.commit("modifyComponent", {
					component: {
						...this.curComponent,
						database
					},
					containerId: null,
					isModify: true,
				})
			}
		},
		/**
		 * @desc: 改变数据来源
		 * @param {String} val 来源类型
		 */
		onChangeMapping(val) {
			this.$store.commit('setDatabaseMapping', val);
		},
		/**
		 * @desc: 移动端属性选择设置参数
		 * @param {String} val 来源类型
		 */
		setTreeInfo(val) {
			this.mobileTreeInfo = val
		},
		/**
		 * @desc: 移动端设置获取query参数回调
		 * @param {String} val 来源类型
		 */
		MobileGetQuery(val) {
			console.log(val, 'query获取设置')
			this.mobileListParamsInfo = val
		}
	}
};
</script>

<style lang="less" scoped>
.container-database {
	width: 100%;
	.origin {
		display: flex;
		margin-bottom: 10px;
		.label {
			line-height: 16px;
			margin-right: 40px;
			font-weight: bold;
			&.type-label {
				height: 32px;
				box-sizing: border-box;
				line-height: 32px;
				padding: 5px;
			}
		}
		.el-radio-group {
			flex: 1;
		}
		.el-select {
			flex: 1;
		}
	}
	.confirm-config {
		width: 100%;
		margin-top: 10px;
	}
}
</style>
