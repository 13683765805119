<!--
 * @Description: 数据仓库配置
 * @Author: luocheng
 * @Date: 2021-09-07 10:44:43
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-07 11:02:13
-->
<template>
  <div class="database">
    <template v-if="curComponent">
      <!-- 容器 -->
      <ContainerDatabase v-if="curComponent.type === 'container'"></ContainerDatabase>
      <!-- 组件 -->
      <ComponentDatabase v-else></ComponentDatabase>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContainerDatabase from './ContainerDatabase';
import ComponentDatabase from './ComponentDatabase';

export default {
  name: 'Database',
  components: {
    ContainerDatabase,
    ComponentDatabase
  },
  computed: {
    ...mapState([
      'curComponent', // 当前组件
    ])
  }
}
</script>

<style lang="less" scoped>
.database{
  width: 100%;
}
</style>