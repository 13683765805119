<!--
 * @Description: 图表盒子配置
 * @Author: luocheng
 * @Date: 2022-01-10 11:20:31
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 15:54:59
-->
<template>
	<div class="custom-components-attrs">
		<h3 class="title">组件名称: {{ curComponent.name }}</h3>
		<el-collapse v-model="activeNames" accordion>
			<el-collapse-item title="参数配置" name="paramsConfig">
				<ParamsConfig></ParamsConfig>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ParamsConfig from '@/components/common/ParamsConfig';

export default {
	name: 'CustomComponentsAttrs',
	components: {
		ParamsConfig
	},
  data() {
    return {
      activeNames: 'paramsConfig'
    }
  },
	computed: {
		...mapState(['curComponent'])
	},
  methods: {
  }
};
</script>

<style lang="less" scoped>
.custom-components-attrs {
  height: 100%;
  width: 100%;
	.title{
		text-align: left;
    line-height: 20px;
    line-height: 16px;
    padding-bottom: 10px;
    word-break: break-all;
    color: #333;
	}
}
</style>