<!--
 * @Description: 填充
 * @Author: luocheng
 * @Date: 2022-08-09 15:26:27
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-11 14:20:53
-->
<template>
  <div class="fill-item" v-if="configForm">
    <h6>【{{ index + 1}}】</h6>
    <article class="fill-content">
      <el-form :model="configForm" label-width="86px">
        <el-form-item label="数据对象">
          <SelectObjectUUID v-model="configForm.objectUUID" @input="onEmit"></SelectObjectUUID>
        </el-form-item>
        <el-form-item label="数据视图" v-if="configForm.objectUUID">
          <SelectViewUUID v-model="configForm.viewUUID" :objectUUID="configForm.objectUUID" @input="onGetField"></SelectViewUUID>
        </el-form-item>
        <el-form-item label="字段" v-if="configForm.objectUUID && configForm.viewUUID && fieldList.length">
          <SelectField v-model="configForm.fieldUUID" :options="fieldList" @input="onEmit"></SelectField>
        </el-form-item>
        <SelectBindValue :config="configForm" @change="onBindChange"></SelectBindValue>
      </el-form>
      <div class="actions">
        <i class="el-icon-delete" @click="onRemove"></i>
      </div>
    </article>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import SelectBindValue from '@/components/common/selectBindValue/Index';
import SelectField from '@/components/common/SelectField';
import { getFields } from '@/apis/data/index';

export default {
  name: 'FillItem',
  components: {
    SelectBindValue,
    SelectObjectUUID,
    SelectViewUUID,
    SelectField
  },
  props: {
    index: {
      type: Number,
      required: true,
      default: 0
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      configForm: null,
      fieldList: []
    }
  },
  created() {
    this.configForm = {
      ...this.config
    }
    if (this.configForm.viewUUID) {
      this.onGetField();
    }
  },
  methods: {
    /**
     * @desc: 获取字段
     */
    onGetField() {
      getFields({
        object_uuid: this.configForm.objectUUID,
        view_uuid: this.configForm.viewUUID,
        size: 1000,
        page: 1
      }).then(res => {
        this.fieldList = res?.data?.data?.data || [];
      }).catch(err => {
        console.log(err);
        this.$message.error('获取字段失败！');
      })
      this.onEmit();
    },
    /**
     * @desc: 绑定值变化
     */
    onBindChange(bindObj) {
      const {
        objectUUID = '',
        viewUUID = '',
        fieldUUID = '',
      } = this.configForm;
      this.configForm = {
        ...bindObj,
        objectUUID,
        viewUUID,
        fieldUUID
      };
      this.onEmit();
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.configForm,
        isDelete: false
      });
    },
    /**
     * @desc: 删除
     */
    onRemove() {
      this.$confirm(`是否确认删除第【${this.index + 1}】项？`).then(() => {
        this.$emit('update', {
          index: this.index,
          config: this.configForm,
          isDelete: true
        });
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.fill-item{
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px;   
  &:hover{
    border: 1px solid @theme;
  }
  h6{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .fill-content{
    display: flex;
    .el-form{
      flex: 1;
      .el-form-item{
        margin-bottom: 10px;
      }
    }
    .actions{
      padding-left: 8px;
      .el-icon-delete{
        line-height: 100px;
        font-weight: bold;
        font-size: 16px;
        color: @dangerColor;
      }
    }
  }
}
</style>