<!--
 * @Description: 表格操作配置
 * @Author: luocheng
 * @Date: 2021-12-07 11:35:13
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 15:41:41
-->
<template>
  <div class="table-action-config" v-if="actionConfig">
    <el-form :model="actionConfig">
      <el-form-item label="需要操作">
        <el-switch v-model="actionConfig.needColumnActions"></el-switch>
      </el-form-item>
      <article class="action-config" v-if="actionConfig.needColumnActions">
        <p class="title">操作配置</p>
        <div v-if="actionConfig.columnActions && actionConfig.columnActions.length">
          <ColumnAction
            v-for="(item, index) in actionConfig.columnActions"
            :key="index"
            :data="item"
            :index="index"
            @removeConfig="onRemoveAction"
          ></ColumnAction>
        </div>
        <p v-else class="no-data">暂无操作</p>
      </article>
      <section class="actions">
        <el-button size="mini" @click="onReset">重置</el-button>
        <el-button type="primary" size="mini" @click="onSubmit">确认</el-button>
        <el-button
          type="success"
          size="mini"
          @click="onPlus"
          v-if="actionConfig.needColumnActions"
        >新增</el-button>
      </section>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ColumnAction from './ColumnAction';

export default {
  name: 'TableActionConfig',
  components: {
    ColumnAction
  },
  data() {
    return {
      actionConfig: {
        needColumnActions: false,
        columnActions: [
          // 名称 - 操作名称 - 文字颜色 - 操作函数参数 （object_uuid,view_uuid,form_uuid可以不填，默认列表参数）
          { name: '详情', action: 'showForm', color: '#000000', payload: { disabled: true } },
          { name: '编辑', action: 'showForm', color: '#0076ff', payload: {} },
          { name: '删除', action: 'deleteData', color: '#e83525', payload: {} },
        ],
      }
    }
  },
  computed: {
    ...mapState(['curComponent']),
  },
  created() {
    const { needColumnActions, columnActions = [] } = this.curComponent?.templateConfig || {};
    this.actionConfig = {
      needColumnActions, columnActions
    };
  },
  methods: {
    /**
     * @desc: 提交
     */
    onSubmit() {
      this.$store.commit('modifyComponent', {
        component: {
          ...this.curComponent,
          templateConfig: {
            ...this.curComponent.templateConfig,
            ...this.actionConfig
          }
        },
        containerId: null,
        isModify: true
      });
    },
    /**
     * @desc: 重置
     */
    onReset() {
      this.$confirm('是否确认重置配置？', '提示').then(() => {
        this.actionConfig = {
          needColumnActions: false,
          columnActions: []
        };
        this.$store.commit('modifyComponent', {
          component: {
            ...this.curComponent,
            templateConfig: {
              ...this.curComponent.templateConfig,
              ...this.actionConfig

            }
          },
          containerId: null,
          isModify: true
        });
      }).catch(() => { })
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.actionConfig.columnActions.push({
        name: '',
        action: '',
        color: '#333333',
        payload: []
      });
    },
    /**
     * @desc: 移除操作
     * @param {Number} index
     */
    onRemoveAction({ index }) {
      this.actionConfig.columnActions.splice(index, 1);
    }
  }
}
</script>

<style lang="less" scoped>
.table-action-config {
  width: 100%;
  .action-config {
    .title {
      display: block;
      text-align: left;
      width: 100%;
    }

    .no-data {
      color: #ccc;
      text-align: center;
    }
  }
}
</style>