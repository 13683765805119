import { render, staticRenderFns } from "./TableActionItem.vue?vue&type=template&id=0b2411a5&scoped=true&"
import script from "./TableActionItem.vue?vue&type=script&lang=js&"
export * from "./TableActionItem.vue?vue&type=script&lang=js&"
import style0 from "./TableActionItem.vue?vue&type=style&index=0&id=0b2411a5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2411a5",
  null
  
)

export default component.exports