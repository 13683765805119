<!--
 * @Description: 列表配置
 * @Author: wsp
 * @LastEditors: Shiltin 18580045074@163.com
-->
<template>
  <div class="list-config" v-if="curComponent">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <article class="contents">
      <!-- 字段配置 -->
      <FieldConfig></FieldConfig>
      <LayoutConfig></LayoutConfig>
			<!-- 绑定模型配置 -->
			<BindConfig v-if="curComponent.component === 'ConsoleList'"></BindConfig>
			<!-- 标记配置 -->
			<SignConfig v-if="curComponent.component === 'ConsoleList'"></SignConfig>
      <ActionConfig></ActionConfig>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FieldConfig from '@/components/tableConfig/FeildConfig.vue';
import ActionConfig from './ActionConfig.vue'
import LayoutConfig from './LayoutConfig.vue'
import BindConfig from './BindConfig.vue'
import SignConfig from '@/components/signConfig/index.vue'

export default {
  name: 'TableConfig',
  components: {
    FieldConfig,
    ActionConfig,
    LayoutConfig,
		BindConfig,
		SignConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ]),
  },
}
</script>

<style lang="less" scoped>
.list-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
  }
  .contents{
    width: 100%;
    box-sizing: border-box;
    padding-right: 10px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>