<!--
 * @Description: 模型配置
 * @Author: luocheng
 * @Date: 2021-10-14 14:47:22
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-06-30 14:08:09
-->
<template>
  <div class="monitor-config" v-if="curComponent">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <article class="contents">
      <!-- 显示配置 -->
      <ViewConfig></ViewConfig>
      <!-- 其他配置 -->
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ViewConfig from './ViewConfig';

export default {
  name: 'TowerConfig',
  components: {
    ViewConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  }
}
</script>

<style lang="less" scoped>
.monitor-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
  }
  .contents{
    width: 100%;
    overflow-y: auto;
  }
}
</style>