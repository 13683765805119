<template>
    <div class="model-config" v-if="curComponent">
        <h3 class="title">透明度配置</h3>
        <article class="contents">
            <div class="view-config" v-if="curComponent">
                <header class="header">
                    <div class="btn-bar">
                        <el-button type="primary" @click="addOpacity">新增透明度修改</el-button>
                    </div>

                    <div class="part-cards">
                        <el-card class="part-card"
                                 shadow="never"
                                 :body-style="{ padding: '10px' }"
                                 v-for="(item , key ) in opacityMap"
                                 :key="key"
                        >
                            <div class="card-input">
                                <el-input size="mini" v-model="item.id" disabled>
                                    <template #prepend>
                                        <div style="width: 30px">id</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-input">
                                <el-input size="mini" v-model="item.label" @change="onNameChange(item)">
                                    <template #prepend>
                                        <div style="width: 30px">名称</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-opacity">
                                <div class="card-slider-text">透明度</div>
                                <el-slider class="card-slider" v-model="item.opacity" @change="onOpacityChange(item)"></el-slider>
                            </div>

                            <div class="card-content">
                                <el-tooltip content="获取viewer中选中的构件来进行修改颜色">
                                    <el-button @click="getSelection(item)">使用当前选中构件</el-button>
                                </el-tooltip>
                            </div>

                            <div class="card-footer">
                                <el-tooltip content="未同步的修改刷新后会消失">
                                    <div class="card-warn" v-show="!item.isSync">此项未同步!</div>
                                </el-tooltip>
                                <el-tooltip content="选中修改透明度的构件">
                                    <el-button @click="onSelect(item)" >选中</el-button>
                                </el-tooltip>
                                <el-tooltip content="同步并保存当前修改">
                                    <el-button type="primary" @click="onSync(item)" >同步</el-button>
                                </el-tooltip>
                                <el-button type="primary" @click="onDelete(item)">删除</el-button>
                            </div>
                        </el-card>
                    </div>
                </header>
            </div>
        </article>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import {  Card ,Tooltip , Slider} from "element-ui";

    const getGuid = (format = 'xxxxxxxxxxxx') =>{
        var d = new Date().getTime();
        var guid = format.replace(
            /[xy]/g,
            function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });
        return guid;
    }

    export default {
        name: "ModelOpacityConfig",
        data (){
            return {
                opacityMap : {
                },
            }
        },
        components: {
            "el-card" : Card,
            "el-tooltip" : Tooltip,
            "el-slider" :Slider,
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        methods:{
            init(){
                eventBus.$emit("ViewerBIM.Opacity.GetOpacityMap" , this.curComponent.id , (map)=>{
                    for(let id in map){
                        const { opacity , dbids , label } = map[id];
                        this.$set(this.opacityMap , id , {
                            id,
                            opacity,
                            dbids,
                            label,
                            isSync: true,
                        });
                    }
                });
            },
            onDelete(item){
                eventBus.$emit("ViewerBIM.Opacity.ClearOpacityById" , this.curComponent.id , JSON.parse(JSON.stringify(item)) );
                this.$delete( this.opacityMap, item.id );
            },
            onSync(item){
                eventBus.$emit("ViewerBIM.Opacity.SyncOpacityData" , this.curComponent.id , JSON.parse(JSON.stringify(item)));
                item.isSync = true;
            },
            onSelect(item){
                eventBus.$emit('ViewerBIM.setSelect' ,this.curComponent.id , JSON.parse(JSON.stringify(item.dbids)));
            },
            onOpacityChange(item){
                item.isSync = false;
            },
            onNameChange( item ){
                item.isSync = false;
            },
            getSelection( item ){
                eventBus.$emit('ViewerBIM.getSelection' , this.curComponent.id , ( selection )=>{
                    item.dbids = selection;
                    if(selection.length !== 0 ){
                        this.$message.success('选择构件成功');
                        item.isSync = false;
                    }else{
                        this.$message.warning('未选择构件');
                    }
                });
            },
            addOpacity(){
                const id = getGuid();

                this.$set(this.opacityMap , id , {
                    id,
                    opacity: 100,
                    dbids: [],
                    label: '新的透明度',
                    isSync: false,
                });
            },
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="less" scoped>
    .model-config{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title{
            text-align: left;
        }
        .contents{
            width: 100%;
            overflow-y: auto;
        }
        .btn-bar{
            display: flex;
            justify-content: right;
        }
        .part-cards{
            padding-top: 5px;
        }
        .card-content{
            display: flex;
            justify-content: space-between;
            padding-top: 5px;

        .card-content-color{
                display: flex;
                .cc-text{
                    padding-top: 10px;
                    margin-right: 3px;
                }
            }
        }
        .card-footer{
            padding-top: 5px;
            display: flex;
            justify-content: right;
            .card-warn{
                color : red;
                padding-top: 10px;
                padding-right: 10px;
            }
        }
        .card-input{
            padding-top: 5px;
        }
        .card-slider{
            width: 160px
        }
        .card-opacity{
            padding-top: 5px;
            display: flex;
            justify-content: space-around;
        }
        .card-slider-text{
            line-height: 38px;
        }
    }
</style>