<!--
 * @Description: 绑定从组件来源的值
 * @Author: luocheng
 * @Date: 2021-09-02 09:54:34
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-21 17:21:26
-->
<template>
	<el-form class="get-com-value" label-width="80px">
		<el-form-item label="值来源">
			<el-select v-model="componentValueConfig.component" clearable placeholder="请选择值来源组件" @change="onSelectComponent">
				<el-option
					v-for="component in componentList"
					:key="component.value"
					:label="component.label"
					:value="component.value"
				>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="字段值">
			<el-select v-if="editorType !== 'mobile'" clearable v-model="componentValueConfig.field" :disabled="!componentValueConfig.component" placeholder="请选择值来源组件" @change="onSelectField">
				<el-option v-for="(field, fieldIndex) in canUseFields" :key="fieldIndex" :label="field" :value="field">
				</el-option>
			</el-select>
			<el-select v-if="editorType === 'mobile'" clearable v-model="componentValueConfig.field" :disabled="!componentValueConfig.component" placeholder="请选择值来源组件" @change="onSelectField">
				<el-option v-for="(field, fieldIndex) in canUseFields" :key="fieldIndex" :label="field.label" :value="field.field">
				</el-option>
			</el-select>
		</el-form-item>
	</el-form>
</template>

<script>
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'GetComValue',
  props: {
    // 组件列表
    componentList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      // 可选列表
      canUseFields: [],
      componentValueConfig: null
    }
  },
  computed: {
    ...mapState([
      'componentData', // 组件列表
      'curComponent', // 当前组件
      'editorType', // 页面类型
    ])
  },
  created() {
    this.componentValueConfig = this.curComponent.componentValueConfig;
  },
	watch: {
		curComponent: {
			handler() {
				this.componentValueConfig = this.curComponent.componentValueConfig;
			}
		},
		deep: true
	},
  methods: {
    /**
     * @desc: 选择组件获取该组件可使用字段
     */
    onSelectComponent() {
      this.canUseFields = [];
      const component = getComponentById(this.componentData, this.componentValueConfig.component);
      if (!component) return;
      if (component.customStatus) {
        this.canUseFields = Object.keys(component.customStatus);
      }
      console.log(component, '999999')
      /* 移动端图片组件选择其他组件暴露值 */
      if (this.editorType === 'mobile' && (component?.database?.metadata || component.metadata)) {
        const metadata = component?.database?.metadata || component.metadata
        this.canUseFields = Object.keys(metadata).map((item) => {
          if (typeof(metadata[item]) !== 'object' && typeof(item) !== 'object') {
            return {
            label: metadata[item],
            field: item
          }
          }
          
        })
      } else {
        this.canUseFields.push('propValue');
      }
      console.log(this.canUseFields, 'this.canUseFields')
    },
    /**
     * @desc: 选择字段回调
     */
    onSelectField() {
    },
  }
};
</script>

<style lang="less" scoped>
.get-com-value {
  padding-top: 10px;
  border-top: 1px solid @borderColor;
}
</style>
