<!--
 * @Description: 树事件
 * @Author: luocheng
 * @Date: 2021-10-20 09:37:02
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:37:11
-->
<template>
  <div class="tree-event">
    <section class="action-item" v-for="item in actionConfig" :key="item.key">
      <el-row>
        <el-col :span="4">名称：</el-col>
        <el-col :span="20">【{{ item.name }}】</el-col>
      </el-row>
      <el-form class="event-list" v-if="item.eventList && item.eventList.length" label-position="left">
        <el-row v-for="(value, index) in item.eventList" :key="index" class="event-box">
          <el-form-item label="事件类型:" label-width="120px">
            <h3 class="title">{{ value.key | filterEvent }}</h3>
          </el-form-item>
          <div v-for="(effect, eIndex) in value.effects" :key="eIndex">
            <el-form-item label="作用组件:" label-width="120px">
              <el-select v-model="effect.targets" placeholder="请选择作用组件" multiple disabled>
                <el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="逻辑判断">
              <span v-if="effect.logics && effect.logics.length">{{ effect.logics.length }}条逻辑</span>
              <span class="no-data" v-else>无</span>
            </el-form-item>
            <el-form-item label="操作:" label-width="120px">
              <div class="tag-list">
                <el-tag>{{ effect.actions | filterActions }}</el-tag>
              </div>
            </el-form-item>
          </div>
        </el-row>
      </el-form>
      <el-row class="bind-row">
        <el-button type="success" size="mini" @click="onShowSelect(item)">绑定事件</el-button>
      </el-row>
    </section>
    <!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'treeEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { defaultList, actionList } from '../../config';
import SelectEvent from '../../SelectEvent';
import { flatTree } from '@/utils/tools';
import { Tag } from 'element-ui';

export default {
  name: 'TreeEvent',
  components: {
		'el-tag': Tag,
		SelectEvent
	},
  data() {
    return {
      actionConfig: [],
      partData: null,
      showSelect: false
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData'
    ])
  },
  watch: {
    curComponent: {
      handler() {
        this.actionConfig = this.curComponent && this.curComponent.actionConfig;
      },
      deep: true,
      immediate: true
    }
  },
	filters: {
		// 事件名称
		filterEvent(value) {
			for (let i = 0; i < defaultList.length; i++) {
				if (defaultList[i].value === value) {
					return defaultList[i].label;
				}
			}
			return value;
		},
		// 操作
		filterActions(value) {
			let result = [];
			for (let i = 0; i < value.length; i++) {
				const item = actionList.find((ele) => ele.value === value[i]);
				if (item) {
					result.push(item.label);
				}
			}
			return result.join();
		}
	},
  created() {
		this.targetList = flatTree(
			this.componentData,
			'children',
			{
				label: 'name',
				value: 'id'
			},
			[]
		);
	},
	methods: {
		/**
		 * @desc: 显示配置事件表单
		 * @param {Object} btnData 按钮配置数据
		 */
		onShowSelect(btnData) {
			if (!btnData) return;
			this.partData = btnData;
			this.showSelect = true;
		}
	}
}
</script>

<style lang="less" scoped>
.tree-event {
  .action-item{
    padding: 10px 0;
    border-bottom: 1px solid #f2f3f5;
    &:last-of-type {
      border: none;
    }
    .el-row{
      .el-col{
        font-weight: bold;
      }
      &:first-of-type{
        padding-bottom: 5px;
      }
    }
  }
  .bind-row {
    display: flex;
    .el-button{
      flex: 1;
    }
  }
}
</style>