<!--
 * @Description: 事件配置
 * @Author: luocheng
 * @Date: 2021-08-23 11:32:43
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-12-06 18:34:51
-->
<template>
  <div class="config-events">
    <template v-if="curComponent">
      <h3 class="title">组件名称: {{ curComponent.name }}</h3>
      <article class="config-list"  v-if="curComponent.type !== 'group'">
        <el-collapse v-model="actives" accordion>
          <!-- 组件事件 -->
          <el-collapse-item title="组件事件" name="events">
            <el-button type="success" class="action" @click="onShowSelect">添加事件</el-button>
            <el-form class="list-main" v-if="Object.keys(curComponent.events).length">
              <section class="config-item" v-for="(value, key) in curComponent.events" :key="key">
                <el-form-item label="事件类型:" :label-width="labelWidth">
                  <h3 class="title">{{ key | filterEventName }}</h3>
                </el-form-item>
                <!-- 复杂事件 -->
                <template v-if="value.pattern === 'special'">
                  <section v-for="(item, index) in value.specialEventList" :key="index">
                     <EventDetails :item="item"></EventDetails>
                  </section>
                </template>
                <template v-else>
                  <!-- 普通事件 -->
                  <EventDetails :item="value"></EventDetails>
                </template>
              </section>
            </el-form>
            <p v-else class="no-current">暂未添加事件</p>
          </el-collapse-item>
          <!-- 组件的自定义事件 -->
          <el-collapse-item title="组件内事件" name="custom" v-if="customEvents.includes(curComponent.component)">
            <!-- 表格 -->
            <template
              v-if="['CommonTable','CommonTableContainer','ConsoleList','ConsoleForm','ConsoleTypeTree','BindComponent','CommonGantt','ViewerBIM'].includes(curComponent.component)"
            >
              <TableEvents></TableEvents>
              <TableNestingEvents v-if="curComponent.statusConfig.isNesting"></TableNestingEvents>
            </template>
            <!-- tabs -->
            <TabsEvents v-else-if="curComponent.component === 'CommonTabs'"></TabsEvents>
            <!-- 筛选项 -->
            <FilterEvent v-else-if="curComponent.component === 'CommonForm'"></FilterEvent>
            <!-- 树形图 -->
            <TreeEvent v-else-if="curComponent.component === 'CommonTree'"></TreeEvent>
            <!-- sheet 容器 -->
            <SheetEvents v-else-if="curComponent.component === 'CommonSheetContainer'"></SheetEvents>
            <!-- Gis模型 -->
            <GisEvent v-else-if="curComponent.component === 'CommonProjectMap'"></GisEvent>
            <!-- List -->
            <ListEvent v-else-if="curComponent.component === 'ListContainerOne'"/>
            <!-- 移动端顶部路由 tab -->
            <TopRouteTab v-else-if="curComponent.component === 'RouteTop' || curComponent.component === 'Calendar'"></TopRouteTab>
            <!-- 移动端树形选择 -->
            <MobileTreeEvent v-else-if="curComponent.component === 'MobileSelectTree' || curComponent.component === 'ThreeTerminal' || curComponent.component === 'ConsoleTypeTree'"></MobileTreeEvent>
            <!-- 弹窗自定义事件 -->
            <DialogEvent v-else-if="curComponent.component === 'CommonDialog'"></DialogEvent>
          </el-collapse-item>
        </el-collapse>
      </article>
      <p v-else class="no-current">当前组件不支持绑定值</p>
    </template>
    <p v-else class="no-current">请选择组件</p>
    <!-- 选择事件 -->
    <el-drawer
      title="选择事件"
      :visible.sync="showSelect"
      direction="ltr"
      custom-class="selct-drawer"
      destroy-on-close
      ref="drawer"
      :wrapperClosable="false"
      size="400px"
    >
      <SelectEvent @close="showSelect = false"></SelectEvent>
    </el-drawer>
  </div>
</template>

<script>
import SelectEvent from './SelectEvent';
import { mapState } from 'vuex';
import { defaultList } from './config';
import TableEvents from './custom/table/Index';
import TableNestingEvents from './custom/table/Nesting';
import TabsEvents from './custom/tab/Index';
import FilterEvent from './custom/filter/FilterEvent';
import TreeEvent from './custom/tree/TreeEvent';
import SheetEvents from './custom/sheet/Index';
import GisEvent from './custom/gis/GisEvent';
import ListEvent from './custom/ListEvent'
import TopRouteTab from './custom/topRouteTab'
import MobileTreeEvent from './custom/MobileTreeEvent'
import DialogEvent from './custom/dialog/Index.vue';
import EventDetails from './EventDetails.vue';

export default {
  name: 'ConfigEvents',
  components: {
    SelectEvent,
    TableEvents,
    TabsEvents,
    FilterEvent,
    TreeEvent,
    TableNestingEvents,
    SheetEvents,
    GisEvent,
    ListEvent,
    TopRouteTab,
    MobileTreeEvent,
    DialogEvent,
    EventDetails
  },
  data() {
    return {
      // 显示选择事件
      showSelect: false,
      labelWidth: '100px',
      // event
      actives: 'events',
      // 可以绑定自定义事件的组件类表
      customEvents: [
        'CommonTable', 'CommonTableContainer', 'CommonTabs', 'CommonForm', 
        'CommonTree', 'CommonSheetContainer', 'ListContainerOne', 'CommonProjectMap',
        'RouteTop', 'MobileSelectTree', 'ThreeTerminal','Calendar',
				'ConsoleList','ConsoleForm','ConsoleTree', 'ConsoleTypeTree',
        'CommonDialog','BindComponent','CommonGantt','ViewerBIM'
      ]
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  filters: {
    // 事件名称过滤器
    filterEventName(value) {
      for (let i = 0; i < defaultList.length; i++) {
        if (defaultList[i].value === value) {
          return defaultList[i].label;
        }
      }
      return value;
    },
  },
  created() {
  },
  methods: {
    /**l
     * @desc: 显示选择事件抽屉
     */
    onShowSelect() {
      // 兼容莫名的events属性变为数组的情况 2022-2-25
      if (Array.isArray(this.curComponent.events) && !this.curComponent.events.length) {
        this.$store.commit('modifyComponent', {
          component: {
            ...this.curComponent,
            events: {}
          },
          containerId: null,
          isModify: true
        })
      } 
      this.showSelect = true;
    }
  }
}
</script>

<style lang="less" scoped>
.config-events{
  display: flex;
  flex-direction: column;
  width: 100%;
	height: 100%;
	overflow: hidden;
  .title{
    text-align: left;
    line-height: 20px;
    line-height: 16px;
    padding-bottom: 10px;
    word-break: break-all;
    color: #333;
  }
  .config-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    // padding: 10px 0;
    padding-bottom: 10px;
    overflow: hidden;
    overflow-y: auto;
    .action{
      width: 100%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    :deep(.el-form) { 
      flex: 1;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      .config-item{
        // border-bottom: 1px solid @borderColor;
        box-sizing: border-box;
        padding-top: 10px;
        &:first-of-type {
          padding-top: 0;
        }
        .title {
          line-height: 32px;
          padding-bottom: 0;
        }
      }
      .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin-bottom: 8px;
        .el-form-item__label {
          text-align: center;
        }
        h3, p{
          font-size: 14px;
          text-align: left;
        }
        .tag-list{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content:flex-start;
          .el-tag{
            margin: 5px 10px 0px 0;
          }
        }
      }
    }
  }
  .no-current{
    color: @minorColor;
    text-align: center;
  }
}
:deep(.el-drawer) {
  .el-drawer__header{
    margin: 0;
    padding-bottom: 20px;
    span{
      color: #333;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>