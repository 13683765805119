<!--
 * @Description: 单元格配置
 * @Author: luocheng
 * @Date: 2021-12-06 14:16:50
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-19 19:24:54
-->
<template>
  <section class="column-item">
    <header class="header">
      <p>{{ configData.name }}</p>
      <div class="actions">
        <span class="el-icon-caret-top top" v-if="showTop" @click="onMove('up')"></span>
        <span class="el-icon-caret-bottom bottom" v-if="showBottom" @click="onMove('down')"></span>
        <span class="el-icon-delete remove" @click="onRemove"></span>
        <span class="toggle" @click="showToggle = !showToggle">{{ showToggle ? '收起' : '展开' }}</span>
      </div>
    </header>
    <article class="column-content" :class="[showToggle ? 'toggle' : '']">
      <el-form :model="configData" label-width="100px">
        <el-form-item label="code">
          <el-select v-model="configData.code" placeholder="请选择或输入绑定字段" clearable @change="onCode" allow-create>
            <el-option
              v-for="code in fieldList"
              :key="code.value"
              :label="code.label"
              :value="code.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="configData.name" placeholder="请输入名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="宽度">
          <el-input v-model="configData.width" placeholder="请输入宽度" clearable></el-input>
        </el-form-item>
        <el-form-item label="字体颜色">
          <el-color-picker v-model="configData.color" placeholder="请选择颜色" clearable></el-color-picker>
        </el-form-item>
        <el-form-item label="组合字段(eg)">
          <el-input v-model="configData.formula" placeholder="请输入组合字段" clearable></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="configData.type" placeholder="请选择类型" clearable>
            <el-option
              v-for="type in typeList"
              :key="type.value"
              :label="type.label"
              :value="type.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="configData.type === 'status'">
          <!-- 状态 -->
          <el-form-item label="状态值描述">
            <section
              v-for="(statusItem, index) in configData.statusList"
              :key="index"
              class="status-config"
            >
              <div class>
                <el-input v-model="statusItem.code" placeholder="请输入值" size="mini" clearable></el-input>
                <el-input v-model="statusItem.value" placeholder="请输入描述" size="mini" clearable></el-input>
                <el-color-picker v-model="statusItem.color" placeholder="请选择状态颜色" size="mini"></el-color-picker>
              </div>
              <i class="el-icon-delete remove" @click="onRemoveStatus(index)"></i>
            </section>
            <section class="status-action">
              <el-button size="mini" type="text" @click="onPlusStatus">新增状态</el-button>
            </section>
          </el-form-item>
        </template>
        <!-- 时间格式 -->
        <template v-if="configData.type === 'date' || configData.type === 'dateRange'">
          <el-form-item label="时间格式">
            <el-select v-model="configData.dateFormat" clearable placeholder="请选择时间格式">
              <el-option
                v-for="(format, index) in formatList"
                :key="index"
                :label="format.label"
                :value="format.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- 图片格式 -->
        <template v-if="configData.type === 'image'">
          <el-form-item label="图片尺寸">
            <el-input
              v-model="configData.imageSize.width"
              placeholder="请输入宽度"
              size="mini"
              clearable
            ></el-input>
            <el-input
              v-model="configData.imageSize.height"
              placeholder="请输入高度"
              size="mini"
              clearable
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
    </article>
  </section>
</template>

<script>
import { ColorPicker } from 'element-ui';

import { mapState } from 'vuex';
export default {
  name: 'ColumnItem',
  components: {
    'el-color-picker': ColorPicker
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => { }
    },
    index: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      // 可用字段
      // code: '',
      //   name: '',
      //   width: '',
      //   type: '',
      //   color: '',
      //   formula: ""
      configData: null,
      // 类型列表
      typeList: [
        {
          label: '普通文本',
          value: 'text'
        },
        {
          label: '图片',
          value: 'image'
        },
        {
          label: '状态',
          value: 'status'
        },
        {
          label: '时间',
          value: 'date'
        },
        {
          label: '时间段',
          value: 'dateRange'
        },
        {
          label: 'switch操作',
          value: 'switch'
        },
        {
          label: '超链接',
          value: 'link'
        },
        {
          label: '富文本',
          value: 'richText'
        },
        {
          label: 'Tag标签',
          value: 'tag'
        },
        {
          label: '评分',
          value: 'score'
        }
      ],
      // 时间格式列表
      formatList: [
        {
          label: '默认',
          value: ''
        },
        {
          label: '年月日',
          value: 'yyyy-MM-dd'
        },
        {
          label: '年月日时分',
          value: 'yyyy-MM-dd HH:mm'
        },
        {
          label: '年月日时分秒',
          value: 'yyyy-MM-dd HH:mm:ss'
        },
        {
          label: '年月日时',
          value: 'YMDh'
        },
        {
          label: '月日',
          value: 'MM-dd'
        },
        {
          label: '月',
          value: 'M'
        },
        {
          label: '日',
          value: 'd'
        },
        {
          label: '星期*',
          value: 'weekDay'
        }
      ],
      showToggle: false
    }
  },
  computed: {
    ...mapState(['curComponent']),
    fieldList() {
      return this.curComponent?.templateConfig?.fieldList || []
    },
    showTop() {
      return this.count > 1 && this.index > 0;
    },
    showBottom() {
      return this.count > 1 && this.index < this.count - 1;
    }
  },
  created() {
    this.configData = this.data || null;
  },
  methods: {
    /**
     * @desc: 选择或输入code
     */
    onCode() {
      this.configData.name = this.configData.name || this.configData.code || '';
      this.emitData();
    },
    /**
     * @desc: 新增状态
     */
    onPlusStatus() {
      this.configData.statusList.push({
        code: '',
        value: '',
        color: '#333333'
      })
    },
    /**
     * @desc: 移除状态
     * @param {Number} index 下标
     */
    onRemoveStatus(index) {
      this.configData.statusList.splice(index, 1);
    },
    /**
     * @desc: 删除字段
     */
    onRemove() {
      this.$confirm("是否确定移除字段", '提示').then(res => {
        console.log(res);
        this.$emit('removeConfig', {
          index: this.index
        })
      }).catch(() => { });
    },
    /**
     * @desc: 移动
     * @param {String} direction top上移 bottom 下移动
     */
    onMove(direction) {
      this.$emit('setOrder', {
        direction,
        index: this.index
      })
    },
    /**
     * @desc: 提交编辑数据
     */
    emitData() {
      console.log('提交编辑数据')
    }
  }
}
</script>

<style lang="less" scoped>
.column-item {
  width: 100%;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
  margin-bottom: 10px;
  position: relative;
  .header {
    display: flex;
    height: 28px;
    p {
      flex: 1;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .actions {
      padding-left: 10px;
      span {
        cursor: pointer;
        box-sizing: border-box;
        line-height: 28px;
        padding: 0 5px;
        color: @theme;
        font-size: 16px;
        font-weight: bold;
        &.remove {
          color: @dangerColor;
        }
        &.toggle {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .column-content {
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
    &.toggle {
      height: auto;
    }
  }
  .el-color-picker,
  .el-color-picker--mini {
    float: left;
  }
  .status-config {
    display: flex;
    padding-bottom: 5px;
    .remove {
      color: @dangerColor;
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .status-action {
    text-align: left;
  }
}
</style>