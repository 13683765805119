<!--
 * @Description: 渲染逻辑
 * @Author: luocheng
 * @Date: 2022-02-14 11:47:53
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-29 14:32:00
-->
<template>
  <div class="ui-logic">
    <el-form :model="uiLogic" label-width="80px">
      <el-form-item label="使用">
        <el-switch v-model="uiLogic.enable" size="mini"></el-switch>
      </el-form-item>
      <section class="logic-item" v-for="(logic, index) in uiLogic.logicConfigs" :key="index">
        <div class="item-main">
          <el-form-item label="逻辑类型">
            <el-select v-model="logic.type" placeholder="请选择逻辑类型" clearable size="mini" @change="onType(index)">
              <el-option label="组件取值" value="component"></el-option>
              <el-option label="路由参数" value="url"></el-option>
              <el-option label="用户信息" value="userInfo"></el-option>
              <el-option label="架构信息" value="framework"></el-option>
              <el-option label="企业类型(五方)" value="enterpriseType"></el-option>
              <el-option label="映射值(循环容器)" value="mapValue"></el-option>
            </el-select>
          </el-form-item>
          <!-- 字段 用户信息和架构信息 -->
          <template v-if="logic.type === 'userInfo' || logic.type === 'framework'">
            <el-form-item label="字段">
              <el-select v-model="logic.field" placeholder="请选择判断字段" clearable size="mini">
                <template v-if="logic.type === 'userInfo'">
                  <el-option :label="key" :value="key" v-for="(fieldItem, key) in userInfoField" :key="key"></el-option>
                </template>
                <template v-else-if="logic.type === 'framework'">
                  <el-option :label="key" :value="key" v-for="(fieldItem, key) in archiInfoField" :key="key"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </template>
          <!-- 组件取值 -->
          <template v-else-if="logic.type === 'component'">
            <el-form-item label="来源组件">
              <SelectComponent v-model="logic.componentId"></SelectComponent>
            </el-form-item>
            <!-- 取值属性 -->
            <el-form-item label="组件取值1" v-if="logic.componentId">
              <el-select v-model="logic.dataOrigin" placeholder="请选择取值属性" size="mini" clearable @change="getComponentField(index)">
                <el-option label="暴露值" value="resolveData"></el-option>
                <el-option label="组件绑定值" value="componentData"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="取值字段" v-if="logic.componentId">
              <el-select v-model="logic.valueField" placeholder="取值字段" size="mini" clearable filterable>
                <el-option v-for="(item, value) in logic.fieldList" :key="item"
                  :label="item"
                  :value="value"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- url参数 -->
          <template v-else-if="logic.type === 'url'">
            <el-form-item label="URL参数key">
              <el-input v-model="logic.urlParamsKey" placeholder="请属于URL参数key" clearable size="mini">
              </el-input>
            </el-form-item>
          </template>
          <!-- 五方 -->
          <template v-else-if="logic.type === 'enterpriseType'">
            <el-form-item label="五方信息">
              <el-select v-model="logic.unitType" placeholder="请选择五方信息" clearable multiple>
                <el-option
                  v-for="item in unitTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="五方判断逻辑">
              <el-select v-model="logic.unitLogic" placeholder="请选择五方判断逻辑" clearable>
                <el-option label="包含一个" value="in"></el-option>
                <el-option label="安全相等" value="equal"></el-option>
                <el-option label="全不包含" value="none"></el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- 映射值取值 -->
          <template v-else-if="logic.type === 'mapValue'">
            <el-form-item label="映射对象">
              <SelectObjectUUID v-model="logic.mapObject" @input="onGetFiledList(index)"></SelectObjectUUID>
            </el-form-item>
            <el-form-item label="映射字段">
              <el-select v-model="logic.mapField"
                placeholder="请选择字段"
                filterable
                clearable
              >
                <el-option
                  v-for="item in logic.mapFieldList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- 普通判断逻辑 -->
          <template v-if="logic.type !== 'enterpriseType'">
            <el-form-item label="规则">
              <el-select v-model="logic.logicRule" placeholder="请选择逻辑规则" size="mini" clearable>
                <el-option label="等于" value="==="></el-option>
                <el-option label="不等于" value="!="></el-option>
                <el-option label="大于" value=">"></el-option>
                <el-option label="小于" value="<"></el-option>
                <el-option label="大于等于" value=">="></el-option>
                <el-option label="小于等于" value="<="></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="值类型">
              <el-select v-model="logic.valueType" placeholder="请选择值类型" size="mini" clearable>
                <el-option label="字符串" value="string"></el-option>
                <el-option label="数字" value="number"></el-option>
                <el-option label="日期" value="date"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="比较值">
              <el-input-number
                v-model="logic.value"
                size="mini"
                v-if="logic.valueType === 'number'"
              ></el-input-number>
              <el-input
                size="mini"
                v-model="logic.value"
                v-else-if="logic.valueType === 'string'"
                placeholder="请输入比较值"
              ></el-input>
              <el-date-picker
                size="mini"
                v-else-if="logic.valueType === 'date'"
                v-model="logic.value"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </template>
          <el-form-item label="逻辑组合">
            <el-select v-model="logic.itemLogic" placeholder="请选择逻辑组合" clearable>
              <el-option label="且" value="and"></el-option>
              <el-option label="或" value="or"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <i class="remove el-icon-delete" @click="onRemove(index)"></i>
      </section>
      <div class="action">
        <el-button type="" size="mini" @click="onPlus">新增逻辑</el-button>
        <el-button type="primary" size="mini" @click="onSave">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { DatePicker, InputNumber } from 'element-ui';
import { mapState } from 'vuex';
import SelectComponent from '@/components/common/SelectComponent';
import { getComponentById } from '@/utils/tools';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import { getFields } from '@/apis/data';

export default {
  name: 'UILogic',
  components: {
    'el-input-number': InputNumber,
    'el-date-picker': DatePicker,
    SelectComponent,
    SelectObjectUUID
  },
  data() {
    return {
      uiLogic: {
        enable: false,
        logicConfigs: []
      },
      unitTypes: [
        {
          label: '建设单位',
          value: 1
        },
        {
          label: '监理单位',
          value: 2
        },
        {
          label: '设计单位',
          value: 3
        },
        {
          label: '勘察单位',
          value: 4
        },
        {
          label: '施工单位',
          value: 5
        }
      ],
      // {
      //   enable: false,
      //   logicConfigs: [
      //     {
      //       type: '', // 类型 用户信息 userInfo, 架构信息 framework, 企业类型 enterpriseType
      //       field: '', // 字段
      //       dataAttr: 'value', // 取值属性 value 值 length 数据长度
      //       logicRule: '===', // 逻辑规则: === 等于, != 不等于, > 大于, < 小于, <= 小于等于, >= 大于等于
      //       valueType: 'string', //  类型  string 字符串 number 数字 date 日期
      //       value: '', // 比较值
      //       itemLogic: 'and' // 逻辑组合: and 且, or 或
      //     }
      //   ]
      // }
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData',
      'sContainer', // 配置时候处于编辑大屏内组件状态
			'originComponentData' // 大屏容器组件的元组件
    ]),
    configLogic() {
      return this.curComponent && this.curComponent.UILogic
    },
    // 用户信息字段列表
    userInfoField() {
      const userInfo = localStorage.getItem('userInfo');
      return userInfo && JSON.parse(userInfo);
    },
    // 架构字段列表
    archiInfoField() {
      const archiInfo = localStorage.getItem('targetArchi');
      return archiInfo && JSON.parse(archiInfo);
    }
  },
  created() {
    this.uiLogic = this.configLogic && (!Array.isArray(this.configLogic)) ? this.configLogic : {
      enable: false,
      logicConfigs: []
    }
  },
  methods: {
    /**
     * @desc: 新增逻辑
     */
    onPlus() {
      this.uiLogic.logicConfigs.push({
        type: '',
        field: '',
        dataAttr: 'value',
        componentId: '',
        dataOrigin: '',
        valueField: '',
        urlParamsKey: '',
        logicRule: '===',
        valueType: 'string',
        value: '',
        itemLogic: 'and',
        unitType: '',
        unitLogic: 'in',
        mapObject: '',
        mapField: ''
      });
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					UILogic: this.uiLogic
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 移除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.uiLogic.logicConfigs.splice(index, 1);
    },
    /**
     * @desc: 切换类型
     */
    onType(index) {
      this.uiLogic.logicConfigs.splice(index, 1, {
        ...this.uiLogic.logicConfigs[index],
        dataAttr: ''
      });
    },
    /**
     * @desc: 获取组件的值
     */
    getComponentField(index) {
      this.uiLogic.logicConfigs[index].valueField = '';
      this.uiLogic.logicConfigs[index].fieldList = {};
      const { componentId } = this.uiLogic.logicConfigs[index] || {};
      let originComponent = null;
      if (this.sContainer) {
				originComponent = getComponentById(this.originComponentData, componentId);
      } else {
				originComponent = getComponentById(this.componentData, componentId);
      }
      let fieldList = {};
      if (Object.prototype.toString.call(originComponent?.resolveData) === '[object Object]'
        && !['CommonContainer', 'CommonTableContainer'].includes(originComponent.component)) {
        for (let key in originComponent?.resolveData) {
          fieldList[key] = key;
        }
      } else {
        fieldList = originComponent?.metadata || {}
      }
      this.uiLogic.logicConfigs.splice(index, 1, {
        ...this.uiLogic.logicConfigs[index],
        fieldList
      });
    },
    /**
     * @desc: 获取映射值字段
     */
    onGetFiledList(index) {
      if (!this.uiLogic.logicConfigs[index].mapObject) return;
      getFields({
        object_uuid: this.uiLogic.logicConfigs[index].mapObject,
        size: 500,
        page: 1
      }).then(res => {
        this.uiLogic.logicConfigs.splice(index, 1, {
          ...this.uiLogic.logicConfigs[index],
          mapFieldList: res?.data?.data?.data || []
        });
      }).catch(err => {
        console.log(err, '---22---');
      });
    }
  }
}
</script>

<style lang="less" scoped>
.ui-logic{
  width: 100%;
  .el-form{
    .el-form-item{
      margin-bottom: 5px;
      .el-date-editor{
        width: 148px;
      }
    }
    .logic-item{
      border-radius: 4px;
      border: 1px solid #f2f3f5;
      box-sizing: border-box;
      padding: 5px;
      display: flex;
      margin-bottom: 5px;
      cursor: pointer;
      &:hover{
        border-color: @theme;
      }
      .item-main{
        flex: 1;
      }
      .remove{
        margin-top: 86px;
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
        color: @dangerColor;
        cursor: pointer;
      }
    }
    .action{
      margin-top: 5px;
      display: flex;
      .el-button{
        flex: 1;
      }
    }
  }
}
</style>