<!--
 * @Description: 统计数量配置
 * @Author: luocheng
 * @Date: 2022-08-04 15:26:53
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 15:20:18
-->
<template>
  <div class="static-config">
    <el-form :model="configForm" label-width="90px">
      <el-form-item label="数据对象">
        <SelectObjectUUID v-model="configForm.object_uuid"></SelectObjectUUID>
      </el-form-item>
      <el-form-item label="数据视图" v-if="configForm.object_uuid">
        <SelectViewUUID v-model="configForm.view_uuid" :objectUUID="configForm.object_uuid" @input="onGetField"></SelectViewUUID>
      </el-form-item>
      <el-form-item label="字段" v-if="configForm.object_uuid && configForm.view_uuid && fieldList.length">
        <SelectField v-model="configForm.field_uuid" :options="fieldList"></SelectField>
      </el-form-item>
    </el-form>
    <footer class="actions">
      <el-button type="primary" @click="onSave">保存配置</el-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import SelectField from '@/components/common/SelectField';
import { getFields } from '@/apis/data';

export default {
  name: 'StaticConfig',
  components: {
    SelectObjectUUID,
    SelectViewUUID,
    SelectField
  },
  data() {
    return {
      configForm: {
        object_uuid: '',
        view_uuid: '',
        field_uuid: '' // 统计字段
      },
      fieldList: []
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.configForm = this.curComponent?.countConfig || {
      object_uuid: '',
      view_uuid: '',
      field_uuid: '' // 统计字段
    };
    if (this.configForm?.object_uuid && this.configForm.view_uuid) {
      this.onGetField();
    }
  },
  methods: {
    /**
     * @desc: 获取字段
     */
    onGetField() {
      getFields({
        object_uuid: this.configForm.object_uuid,
        view_uuid: this.configForm.view_uuid,
        size: 1000,
        page: 1
      }).then(res => {
        this.fieldList = res?.data?.data?.data || [];
        if (!this.configForm.field_uuid) {
          this.configForm.field_uuid = this.fieldList.find(ele => ele.name === '创建日期')?.uuid || '';
        }
      }).catch(err => {
        console.log(err);
        this.$message.error('获取字段失败！');
      })
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					countConfig: this.configForm
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.static-config {
  width: 100%;
  .actions {
    display: flex;
    padding-bottom: 10px;
    .el-button {
      flex: 1;
    }
  }
}
</style>