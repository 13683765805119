<!--
 * @Author: zx
 * @Date: 2022-07-26 10:23:46
 * @LastEditTime: 2022-11-17 15:45:53
 * @Description: 
 * @LastEditors: 冉桂精 156189868@qq.com
-->
<!--
 * @Description: Tab事件配置 切换到对应的值之后进行相应组件显隐状态切换
 * @Author: luocheng
 * @Date: 2021-10-08 18:04:08
 * @LastEditors: zx
 * @LastEditTime: 2022-07-25 16:26:47
-->
<template>
  <div class="tab-event">
    <section class="part">
			<h4 class="header-title" style="text-align: left">操作</h4>
			<article class="list" v-if="actionList && Object.keys(actionList).length">
				<section class="item" v-for="(btn, key) in actionList" :key="key">
					<el-row>
						<el-col :span="8">名称：</el-col>
						<el-col :span="16">{{ btn.name }}</el-col>
					</el-row>
					<el-form class="event-list" v-if="btn.eventList && btn.eventList.length">
						<el-row v-for="(item, index) in btn.eventList" :key="index" class="event-box">
							<section v-for="(value, vIndex) in item.eventList" :key="vIndex">
								<el-form-item label="事件类型:" label-width="120px">
									<h3 class="title">{{ value.key | filterEvent }}</h3>
								</el-form-item>
								<template v-if="value.pattern === 'special'">
									<!-- 复杂事件 -->
									<section v-for="(item, index) in value.specialEventList" :key="index">
										<EventDetails :item="item"></EventDetails>
									</section>
								</template>
								<template v-else>
									<!-- 普通事件 -->
									<template v-if="value.actionType === 'eeAction'">
										<!-- 后端事件 -->
										<el-form-item label="操作:" label-width="120px">
											<div class="tag-list">
												<el-tag>后端事件--【{{ value.eventName }}】</el-tag>
											</div>
										</el-form-item>
									</template>
									<template v-else-if="value.actionType === 'pageAction'">
											<div v-for="(effect, eIndex) in value.effects" :key="eIndex">
												<el-form-item label="作用组件:" label-width="120px">
													<el-select v-model="effect.targets" placeholder="请选择作用组件" multiple disabled>
														<el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
														</el-option>
													</el-select>
												</el-form-item>
												<el-form-item label="操作:" label-width="120px">
													<div class="tag-list">
														<el-tag>{{ effect.actions | filterActions }}</el-tag>
													</div>
												</el-form-item>
											</div>
									</template>
								</template>
							</section>
						</el-row>
					</el-form>
					<el-row>
						<el-button type="success" size="mini" @click="onShowSelect(btn)">绑定事件</el-button>
					</el-row>
				</section>
			</article>
			<p v-else>暂未配置操作按钮</p>
		</section>
    <!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'tabEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { defaultList, actionList } from "../../config";
import { flatTree } from '@/utils/tools';
import SelectEvent from '../../SelectEvent';
import { Tag } from 'element-ui';
import EventDetails from '@/components/events/EventDetails';

export default {
  name: "TabsEvents",
  components: {
    SelectEvent,
    'el-tag': Tag,
		EventDetails
	},
  data() {
    return {
      // 显示配置抽屉
			showSelect: false,
			// 当前配置的按钮
			partData: null,
      tabConfig: []
    };
  },
  computed: {
    ...mapState(["curComponent", 'componentData', 'sContainer', 'originComponentData']),
    // 组件对象
    targetList() {
      if (this.sContainer) {
				return flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, []);
			}
			return flatTree(this.componentData, 'children', {
					label: 'name',
					value: 'id'
				}, [])
    },
		// 组件绑定的操作栏
		actionList() {
			if (!this.curComponent) return [];
			const result = {};
      for (let action in this.curComponent.actionConfig) {
        const obj = this.curComponent.actionConfig[action];
        if (typeof obj === 'object' && obj.use) {
          result[action] = obj;
        }
      }
			return result;
		}
  },
  watch: {
    curComponent: {
      handler() {
        if (this.curComponent.topTitleType === 'tab') {
          this.tabConfig = this.curComponent && this.curComponent.tabConfig;
        }
      },
      deep: true,
      immediate: true
    }
  },
  filters: {
    // 事件名称过滤器
    filterEvent(value) {
      for (let i = 0; i < defaultList.length; i++) {
        if (defaultList[i].value === value) {
          return defaultList[i].label;
        }
      }
      return value;
    },
    // 操作过滤器
    filterActions(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        const item = actionList.find((ele) => ele.value === value[i]);
        if (item) {
          result.push(item.label);
        }
      }
      return result.join();
    },
  },
  methods: {
    /**
		 * @desc: 显示配置事件表单
		 * @param {Object} tabData 按钮配置数据
		 */
		onShowSelect(tabData) {
			if (!tabData) return;
			this.partData = tabData;
			this.showSelect = true;
		}
  }
};
</script>

<style lang="less" scoped>
.event-box{
  margin: 5px 0;
}
</style>