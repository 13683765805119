<!--
 * @Description: 弹窗的表单配置
 * @Author: luocheng
 * @Date: 2021-09-28 10:30:55
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-08 16:19:44
-->
<template>
  <div class="dialog-config">
    <template v-if="curComponent">
      <h3 class="title">组件名称: {{ curComponent.name }}</h3>
      <article class="contents">
          <!-- 内容配置 -->
        <ContentConfig></ContentConfig>
        <!-- 配置关联的数据源 用于表单提交的参数绑定 目前固定的是 data_id -->
        <RelationSource></RelationSource>
        <!-- 表单填充值 -->
        <FillConfig></FillConfig>
        <!-- 配置操作 -->
        <ActionConfig></ActionConfig>
        <!-- 指定更新规则 -->
        <UpdateRules></UpdateRules>
      </article>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentConfig from './ContentConfig';
import ActionConfig from './ActionConfig';
import RelationSource from './RelationSource';
import UpdateRules from './UpdateRules';
import FillConfig from './FillConfig';

export default {
  name: 'DialogConfig',
  components: {
    ContentConfig,
    ActionConfig,
    RelationSource,
    UpdateRules,
    FillConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  data() {
    return {
      // 按钮操作配置
      actionConfig: null
    }
  },
  created() {
    this.actionConfig = {
      ...this.curComponent.actionConfig
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.dialog-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    padding-bottom: 20px;
    text-align: left;
    border-bottom: 1px solid #f2f3f5;
    margin-bottom: 10px;
  }
  .contents{
    width: calc(100% - 7px);
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    margin-right: 15px;
  }
}
</style>