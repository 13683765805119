<!--
 * @Description: 表格行内导出配置
 * @Author: luocheng
 * @Date: 2021-12-16 19:05:17
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-22 09:56:51
-->
<template>
	<div class="table-export-config">
		<header class="header">
			<span class="line"></span>
			<p class="item-title">导出配置</p>
			<el-button
				type="warning"
				@click="onReset"
				size="mini"
			>重置</el-button>
			<el-button
				type="primary"
				@click="onConfirm"
				size="mini"
			>确认</el-button>
			<a
				href="javascript:;"
				class="header-btn"
				@click="toggleShow = !toggleShow"
			>{{ toggleShow ? "收起" : "展开" }}</a>
		</header>
		<div
			class="config"
			v-show="toggleShow"
		>
			<el-form
				:model="configForm"
				label-width="80px"
			>
				<el-form-item label="导出方式">
					<el-select v-model="configForm.exportWay" placeholder="请选择导出方式" clearable>
						<el-option label="自定义配置" value=""></el-option>
						<el-option label="使用后端配置" value="eeConfig"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="导出类型">
					<el-select
						v-model="configForm.exportType"
						placeholder="请选择导出类型"
					>
						<el-option
							label="当前行"
							value="targetRow"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="configForm.exportWay === 'eeConfig' ? '模板字段' : '导出模板'">
					<el-select
						v-if="!configForm.exportWay"
						v-model="configForm.exportTemplate"
						clearable
						filterable
						remote
						:remote-method="getExportTemplate"
						:loading="templateLoading"
						placeholder="请选择导出模板"
					>
						<el-option
							v-for="tem in templateList"
							:key="tem.id"
							:label="tem.name"
							:value="tem.uuid"
						></el-option>
					</el-select>
					<el-select v-model="configForm.exportTemplateField" v-else-if="configForm.exportWay === 'eeConfig'">
						<el-option v-for="(field, value) in fieldList" :key="value" :label="field" :value="value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="请求方式">
					<el-select
						v-model="configForm.exportRequest"
						placeholder="请选择请求方式"
					>
						<el-option
							label="列表"
							value="dataList"
						></el-option>
						<el-option
							label="详情"
							value="dataInfo"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="导出格式">
					<el-select v-model="configForm.exportTypeSheet" placeholder="请选择导出结果类型">
						<el-option label="表格" value="excel"></el-option>
						<el-option label="图片" value="image"></el-option>
						<el-option label="PDF" value="pdf"></el-option>
					</el-select>
				</el-form-item>
				<template v-if="configForm.exportWay === 'eeConfig'">
					<!-- 后端配置导出 -->
					<el-form-item label="对象字段">
						<el-select v-model="configForm.objectUUIDField">
							<el-option v-for="(field, value) in fieldList" :key="value" :label="field" :value="value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="视图字段">
						<el-select v-model="configForm.viewUUIDField">
							<el-option v-for="(field, value) in fieldList" :key="value" :label="field" :value="value">
							</el-option>
						</el-select>
					</el-form-item>
				</template>
				<!-- 自定义导出配置 -->
				<template v-else>
						<el-form-item label="数据对象">
						<SelectObjectUUID
							v-model="configForm.objectUUID"
							:placeholder="'请选择对象'"
						></SelectObjectUUID>
					</el-form-item>
					<el-form-item
						label="导出视图"
						v-if="configForm.objectUUID"
					>
						<SelectViewUUID
							v-model="configForm.viewUUID"
							:objectUUID="configForm.objectUUID"
							:placeholder="'请选择视图'"
						>
						</SelectViewUUID>
					</el-form-item>
				</template>
			</el-form>
		</div>
	</div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import { dataInterface } from '../../apis/data';
import { mapState } from 'vuex';

export default {
	name: 'ExportConfig',
	components: {
		SelectObjectUUID,
		SelectViewUUID
	},
	data() {
		return {
			toggleShow: false,
			// 配置
			configForm: {
				exportWay: '',
				exportType: 'targetRow',
				exportTemplate: '',
				exportRequest: '',
				exportTypeSheet: 'pdf',
				objectUUID: '',
				viewUUID: '',
				exportTemplateField: '',
				objectUUIDField: '',
				viewUUIDField: ''
			},
			// 模板
			templateLoading: false,
			templateList: [],
		};
	},
	computed: {
		...mapState(['curComponent']),
		exportConfig() {
			return this.curComponent?.exportConfig || null;
		},
		// 字段列表
		fieldList() {
			return this.curComponent?.metadata || {}
		}
	},
	created() {
		if (this.exportConfig) {
			this.configForm = {
				...this.exportConfig,
				exportWay: this.exportConfig.exportWay || ''
			};
		}
		this.getExportTemplate('');
	},
	methods: {
		/**
		 * @desc: 重置
		 */
		onReset() {
			this.$confirm('是否重置配置？').then(() => {
				this.configForm = {
					exportWay: '',
					exportType: 'targetRow',
					exportTemplate: '',
					exportRequest: '',
					exportTypeSheet: 'pdf',
					objectUUID: '',
					viewUUID: '',
					exportTemplateField: '',
					objectUUIDField: '',
					viewUUIDField: ''
				};
			});
		},
		/**
		 * @desc: 确认配置
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					exportConfig: this.configForm
				},
				containerId: null,
				isModify: true
			});
			this.$message.success('配置成功！');
		},
		/**
		 * @desc: 获取模板列表
		 */
		getExportTemplate(value = '') {
			this.templateLoading = true;
			dataInterface({
				object_uuid: 'object617fbedcefab4',
				view_uuid: 'view61baf4e99b7b3',
				__method_name__: 'dataList',
				search: [
					{
						field_uuid: 'field617fbedcf29d2',
						ruleType: 'like',
						value
					}
				],
				transcode: 0
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.templateList = data.data || [];
					}
					this.templateLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.templateList = [];
					this.templateLoading = false;
				});
		}
	}
};
</script>

<style lang="less" scoped>
.table-export-config {
	width: 100%;
	padding-bottom: 10px;
	.header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		.line {
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 6px;
			background: @theme;
		}
		a,
		p {
			line-height: 28px;
		}
		.header-btn {
			color: @theme;
		}
	}
	.config {
		box-sizing: border-box;
		padding: 10px 0;
	}
}
</style>