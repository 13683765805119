<!--
 * @Description: 配置列表
 * @Author: luocheng
 * @Date: 2022-01-18 16:46:42
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 15:10:19
-->
<template>
	<div class="logic-item" v-if="configForm">
    <el-form :model="configForm">
      <el-form-item label="取值类型">
        <el-select
          v-model="configForm.type"
          placeholder="请选择取值类型"
          clearable
          size="mini"
          @change="emitData"
        >
          <el-option label="当前组件" v-if="type === 'inner'" value="inner"></el-option>
          <el-option label="页面组件" value="component"></el-option>
          <el-option label="前端系统取值" value="system"></el-option>
        </el-select>
      </el-form-item>
      <!-- 组件内部 -->
      <template v-if="configForm.type === 'inner'">
        <el-form-item label="取值字段">
          <el-select
            v-model="configForm.dataField"
            placeholder="请选择取值字段"
            clearable
            size="mini"
            @change="emitData"
          >
            <el-option
            v-for="(key, value) in metadata"
            :key="key"
            :label="key"
            :value="value"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- 页面组件 -->
      <template v-if="configForm.type === 'component'">
        <el-form-item label="来源组件">
          <el-select
            v-model="configForm.target"
            placeholder="请选择来源组件"
            clearable
            size="mini"
            @change="emitData"
          >
            <el-option
              v-for="item in targetList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取值对象">
          <el-select
            v-model="configForm.dataKey"
            placeholder="请选择取值对象"
            size="mini"
            @change="onDataKey"
          >
            <el-option
              label="组件选中"
              value="resolveData"
            ></el-option>
            <el-option
              label="组件暴露数据"
              value="componentData"
            ></el-option>
            <el-option
              label="组件绑定数据"
              value="propValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取值字段" v-if="configForm.dataKey === 'resolveData'">
          <el-select
            v-if="fieldList"
            v-model="configForm.dataField"
            placeholder="请选择取值字段"
            clearable
            size="mini"
            @change="emitData"
          >
            <el-option
            v-for="(key, value) in fieldList"
            :key="key"
              :label="key"
              :value="value"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- 系统参数 -->
      <template v-else-if="configForm.type === 'system'">
        <el-form-item label="取值对象">
          <el-select v-model="configForm.valueObj" placeholder="请选择取值类型"
            filterable
            clearable
            size="mini"
            allow-create
            @change="onValueObj">
            <el-option label="架构信息" value="targetArchi"></el-option>
            <el-option label="用户信息" value="userInfo"></el-option>
            <el-option label="分部分项" value="_formFilleData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取值字段" v-if="configForm.valueObj">
          <el-select v-model="configForm.valueKey"
            placeholder="请选择取值字段"
            filterable
            allow-create
            clearable
            @change="emitData"
          >
            <el-option v-for="(val, key) in keysObj"
              :key="key"
              :label="key"
              :value="key"   
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="数据属性">
        <el-select
          v-model="configForm.dataAttr"
          placeholder="请选择数据属性"
          size="mini"
          @change="emitData"
        >
          <el-option
            label="值"
            value="value"
          ></el-option>
          <el-option
            label="数据长度"
            value="length"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 逻辑相关 -->
      <el-form-item label="逻辑规则">
        <el-select
          v-model="configForm.logicRule"
          placeholder="请选择逻辑规则"
          size="mini"
          @change="emitData"
        >
          <el-option
            v-for="logic in logicList"
            :key="logic.value"
            :label="logic.label"
            :value="logic.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="值类型">
        <el-select
          v-model="configForm.valueType"
          placeholder="请选择值类型"
          clearable
          size="mini"
          @change="emitData"
        >
          <el-option
            label="字符串"
            value="string"
          ></el-option>
          <el-option
            label="数字"
            value="number"
          ></el-option>
          <el-option
            label="日期"
            value="date"
          ></el-option>
        </el-select>
      </el-form-item>
      <!--比较值 -->
      <el-form-item label="比较值">
        <el-input-number
          v-model="configForm.value"
          size="mini"
          v-if="configForm.valueType === 'number'"
          @change="emitData"
        ></el-input-number>
        <el-input
          size="mini"
          v-model="configForm.value"
          v-else-if="configForm.valueType === 'string'"
          @input="emitData"
        ></el-input>
        <el-date-picker
          size="mini"
          v-else-if="configForm.valueType === 'date'"
          v-model="configForm.value"
          type="datetime"
          value-format="timestamp"
          placeholder="选择日期时间"
          clearable
          @change="emitData"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="逻辑组合">
        <el-select v-model="configForm.itemLogic" placeholder="请选择逻辑组合" size="mini" @change="emitData">
          <el-option label="且" value="and"></el-option>
          <el-option label="或" value="or"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <i class="el-icon-delete-solid remove" @click="onRemoveItem"></i>
	</div>
</template>

<script>
import { DatePicker, InputNumber } from 'element-ui';
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'LogicItem',
  components: {
    'el-input-number': InputNumber,
    'el-date-picker': DatePicker
  },
	props: {
		// target: '', // 目标组件
		// dataKey: 'resolveData', // 取值对象 resolveData 当前暴露数据 componentData 组件绑定数据
		// dataField: '', // 取值字段 当使用取值对象时 resolveData
		// dataAttr: 'value', // 取值属性 value 值 length 数据长度
		// logicRule: '===', // 逻辑规则 === 等于 != 不等于 > 大于 < 小于 <= 小于等于 >= 大于等于
		// valueType: 'string', //  类型  string 字符串 number 数字 date 日期
		// value: '', // 比较值
		// itemLogic: 'and' // 逻辑组合 and 且 or 或
    index: {
      type: Number,
    },
		config: {
			type: Object,
			// required: true,
			default: () => {}
		},
		// 组件列表
		targetList: {
			type: Array,
			default: () => []
		},
    // 配置类型   inner 组件内部配置（此时首选在组件内部暴露值选取进行操作）
		type: {
			type: String,
			isRequired: false,
			default: ''
		}
	},
	data() {
		return {
			configForm: null,
			// 逻辑判定
			logicList: [
				{
					label: '等于',
					value: '==='
				},
				{
					label: '不等于',
					value: '!='
				},
				{
					label: '大于',
					value: '>'
				},
				{
					label: '小于',
					value: '<'
				},
				{
					label: '小于等于',
					value: '<='
				},
				{
					label: '大于等于',
					value: '>='
				}
			],
      // 字段列表
      fieldList: null,
      // 取值对象字段
      keysObj: {}
		};
	},
  computed: {
    ...mapState(['componentData', 'curComponent']),
    metadata() {
      return this.curComponent?.metadata || {};
    }
  },
	created() {
		this.configForm = JSON.parse(JSON.stringify(this.config));
    if (this.configForm.dataKey) {
      this.onDataKey(true);
    }
	},
  methods: {
    /**
     * @desc: 切换取值对象 
     */
    onDataKey(init = false) {
      if (this.configForm.dataKey === 'resolveData') {
        this.fieldList = null;
        const component = getComponentById(this.componentData, this.configForm.target)
        if (component) {
          this.fieldList = component.metadata || null;
        }
      }
      if (!init) {
        this.emitData();
      }
    },
    /**
     * @desc: 提交数据
     */
    emitData() {
      this.$emit('updateConfig', {
        index: this.index,
        config: JSON.parse(JSON.stringify(this.configForm))
      });
    },
    /**
     * @desc: 选择取值对象
     * @param {String} val 取值对象类型
     */
    onValueObj(val) {
      this.configForm.valueKey = '';
      this.keysObj = {};
      if (val) {
        let result = sessionStorage.getItem(val);
        if (!result) {
          result = localStorage.getItem(val);
        }
        try {
          const value = JSON.parse(result);
          if (value && Object.prototype.toString.call(value)) {
            this.keysObj = value;
          }
        } catch(err) {
          console.log(err);
        }
      }
      this.emitData();
    },
    /**
     * @desc: 移除
     */
    onRemoveItem() {
      this.$emit('removeItem', {
        index: this.index
      });
    }
  }
};
</script>

<style lang="less" scoped>
.logic-item {
	width: 100%;
  border-bottom: 2px dashed #f2f3f5;
  display: flex;
  box-sizing: border-box;
  box-sizing: border-box;
  :deep(.el-form) { 
		padding-left:10px;
    flex: 1;
    .el-form-item{
      margin-bottom: 5px!important;
      .el-input{
        width: 180px;
      }
      .el-select{
        width: 180px;
      }
      .el-date-picker{
        width: 180px;
      }
      .el-input-number{
        width: 180px;
        .el-input{
          width: auto;
        }
      }
    }
  }
  .remove{
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    color: @dangerColor;
    padding-left: 10px;
    line-height: 100px;
    cursor: pointer;
  }
}
</style>