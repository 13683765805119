<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2022-04-22 13:52:36
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-05-10 17:41:50
-->
<template>
  <div class="action-config">
    <article class="action-list">
      <ActionItem v-for="(item, index) in actionList"
        :key="index"
        :index="index"
        :config="item"
        @remove="onRemove"
        @update="onUpdate"
      ></ActionItem>
    </article>
    <footer class="footer">
      <el-button type="success" size="mini" @click="onPlus">新增</el-button>
      <el-button type="primary" size="mini" @click="onSave">保存</el-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionItem from './ActionItem';

export default {
  name: 'ActionConfig',
  components: {
    ActionItem
  },
  data() {
    return {
      actionList: []
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.actionList = this.curComponent?.actionConfig || []
  },
  methods: {
    /**
     * @desc: 新增
     */
    onPlus() {
      this.actionList.push({
        key: 'other',
				effect: false,
				effectType: '',
				canCustom: true,
				postType: '', // 请求类型
				buttonType: 'primary',
				text: '自定义',
				objectUUID: '', // 对象UUID
				viewUUID: '', // 视图UUID
        eventName: '', // 事件名称
        show: true, // 是否显示
      })
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.actionList.splice(index, 1);
    },
    /**
     * @desc: 更新
     * @param {Number} index
     * @param {Object} config
     */
    onUpdate({ index, config }) {
      this.actionList.splice(index, 1, {
        ...config
      })
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					actionConfig: this.actionList
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.action-config{
  width: 100%;
}
</style>