<!--
 * @Description: 配置数据对象
 * @Author: luocheng
 * @Date: 2022-04-22 09:43:51
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-10 13:54:43
-->
<template>
  <div class="database-item">
    <el-form v-model="configData" label-width="80px">
      <el-form-item label="数据对象" class="is-required">
        <SelectObjectUUID v-model="configData.objectUUID" @input="onEmit"></SelectObjectUUID>
      </el-form-item>
      <template v-if="configData.objectUUID"> 
        <el-form-item label="视图" class="is-required">
          <SelectViewUUID
            v-model="configData.viewUUID"
            :objectUUID="configData.objectUUID"
            @input="getFieldList"
          >
          </SelectViewUUID>
        </el-form-item>
        <el-form-item label="表单对象">
					<el-select
						v-model="configData.formUUID"
						filterable
						remote
						:remote-method="onRemoteForm"
						:loading="formLoading"
            @change="onEmit"
					>
						<el-option
							v-for="formObj in formList"
							:key="formObj.id"
							:label="formObj.name || '默认'"
							:value="formObj.uuid"
						></el-option>
					</el-select>
				</el-form-item>
        <el-form-item label="模板字段" v-if="configData.viewUUID">
          <el-select v-model="configData.templateField" placeholder="请选择模板字段" @change="onEmit" clearable>
            <el-option v-for="field in fieldList"
              :key="field.uuid"
              :label="field.name"
              :value="field.uuid"
            ></el-option>
            <template slot="prefix">
              <MetaTraceIcon method="ShowFieldDetail" :uuid="configData.templateField" style="line-height: 36px" />
            </template>
          </el-select>
        </el-form-item>
      </template>
    </el-form>
    <section class="actions">
      <i class="el-icon-minus remove" @click="onRemove"></i>
    </section>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import { getFields } from '@/apis/data';
import { dataInterface } from '../../apis/data';
import MetaTraceIcon from '@/components/metaSelect/MetaTraceIcon.vue';

export default {
  name: 'DatabaseItem',
  components: {
    SelectObjectUUID,
    SelectViewUUID,
    MetaTraceIcon
  },
  props: {
    // 下标
    index: {
      type: Number,
      required: true
    },
    // 配置
    config: {
      type: Object,
      required: true,
      default: () => {
        return {
          objectUUID: '',
          viewUUID: '',
          templateField: ''
        }
      }
    }
  },
  data() {
    return {
      configData: {
        objectUUID: '',
        viewUUID: '',
        formUUID: '',
        templateField: ''
      },
      fieldList: [],
      // 表单uuid
      formList: [],
      formLoading: false
    }
  },
  created() {
    this.configData = {
      ...this.config
    }
    if(this.configData.objectUUID) {
      this.getFieldList();
    }
    if (this.configData.formUUID) {
      this.onRemoteForm('');
    }
  },
  methods: {
    /**
     * @desc: 获取字段列表
     */
    getFieldList() {
      this.onEmit();
      this.fieldList = [];
      getFields({
        object_uuid: this.configData.objectUUID,
        view_uuid: this.configData.viewUUID,
        size: 1000,
				page: 1
      }).then(res => {
        // console.log(res, '00000000')
        this.fieldList = res?.data?.data.data || [];
      }).catch(err => {
        console.log(err);
      })
      this.onRemoteForm('');
    },
    /**
		 * @desc: 获取可用表单对象
		 * @param {String} name <未启用>
		 */
		onRemoteForm(name) {
			this.formLoading = true;
			dataInterface({
				object_uuid: this.configData.objectUUID,
				name
			}, 'api/saas/metadata/getFormDesigns', 'GET')
				.then((res) => {
					// console.log(res.data.data)
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.formList = data || [];
					}
					this.formLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.formList = [];
					this.formLoading = false;
				});
		},
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: {
          ...this.configData
        }
      })
    },
    /**
     * @desc: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    }
  }
}
</script>

<style lang="less" scoped>
.database-item{
  width: 100%;
  display: flex;
  border: 1px solid #f2f3f5;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 5px;
  margin-bottom: 10px;
  &:last-of-type{
    margin: 0;
  }
  .el-form-item {
    margin-bottom: 10px;
    &:last-of-type{
      margin: 0;
    }
  }
  .actions{
    box-sizing: border-box;
    padding-left: 10px;
    i{
      display: block;
      font-size: 16px;
      line-height: 1;
      font-weight: bold;
      &.remove{
        margin-bottom: 10px;
        color: @dangerColor;
        line-height: 32px;
      }
    }
  }
}
</style>