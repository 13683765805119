<template>
    <div class="model-config" v-if="curComponent">
        <h3 class="title">工具配置</h3>
        <article class="contents">
            <div class="view-config" v-if="curComponent">
                <header class="header">
                    <el-divider>颜色设置</el-divider>
                    <div class="btn-bar">
                        <el-button type="primary" @click="addColor">新增颜色修改</el-button>
                        <el-button type="primary" v-text="showColor?'隐藏':'显示'" @click="changeShowColor"></el-button>

                    </div>

                    <div class="part-cards" v-show="showColor">
                        <el-card class="part-card"
                                 shadow="never"
                                 :body-style="{ padding: '10px' }"
                                 v-for="(item , key ) in colorMap"
                                 :key="key"
                        >
                            <div class="card-input">
                                <el-input size="mini" v-model="item.id" disabled>
                                    <template #prepend>
                                        <div style="width: 30px">id</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-input">
                                <el-input size="mini" v-model="item.label" @change="onNameChange(item)">
                                    <template #prepend>
                                        <div style="width: 30px">名称</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-content">
                                <el-tooltip content="获取viewer中选中的构件来进行修改颜色">
                                    <el-button @click="getSelection(item)">使用当前选中构件</el-button>
                                </el-tooltip>


                                <div class="card-content-color">
                                    <div class="cc-text">修改颜色</div>
                                    <el-color-picker v-model="item.color" @change="onColorChange(item)"></el-color-picker>
                                </div>
                            </div>

                            <div class="card-footer">
                                <el-tooltip content="未同步的修改刷新后会消失">
                                    <div class="card-warn" v-show="!item.isSync">此项未同步!</div>
                                </el-tooltip>
                                <el-tooltip content="选中修改颜色的构件">
                                    <el-button  @click="viewSelection(item)">选中</el-button>
                                </el-tooltip>
                                <el-tooltip content="同步并保存当前修改">
                                    <el-button type="primary" @click="syncData(item)" >同步</el-button>
                                </el-tooltip>
                                <el-button type="primary" @click="onDelete(item)">删除</el-button>
                            </div>
                        </el-card>
                    </div>
                    <el-divider>工具栏设置</el-divider>
                    <el-form  label-width="85px">
                        <el-form-item label="显示工具栏" >
                            <el-button type="primary"  v-text="useToolbar?'禁用':'启用'" @click="changeUseToolbar"></el-button>
                            <el-button type="primary" class="toolbarmore" v-text="showToolbarConfig?'隐藏详细设置':'显示详细设置'" @click="changeToolbarConfig"></el-button>
                        </el-form-item>

                        <el-form-item :label="item.name" v-for="(item, id) in toolbarList" :key="id" v-show="showToolbarConfig">
                            <el-button :type="hideIds.indexOf(id) == -1?'info':'primary'"  v-text="hideIds.indexOf(id) == -1?'隐藏':'显示'" @click="changeHideList( id )"></el-button>
                        </el-form-item>

                        <el-form-item label="自定义按钮" >
                            <el-button type="primary" @click="addCustomToolbar">添加</el-button> 
                            <el-button type="primary" class="toolbarmore" v-text="showCustomToolbar?'隐藏详细列表':'显示详细列表'" @click="changeCustomList"></el-button>
                        </el-form-item>

                        <el-card class="part-card"
                                 shadow="never"
                                 :body-style="{ padding: '10px' }"
                                 v-for="(item , key ) in customToolbarMap"
                                 :key="key"
                                 v-show="showCustomToolbar"
                        >
                            <div class="card-input">
                                <el-input size="mini" disabled v-model="item.id">
                                    <template #prepend>
                                        <div style="width: 30px">id</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-input">
                                <el-input size="mini" v-model="item.label" @change="item.isSync=false">
                                    <template #prepend>
                                        <div style="width: 30px">名称</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-input">
                                <el-input size="mini" v-model="item.icon" @change="item.isSync=false">
                                    <template #prepend>
                                        <div style="width: 30px">图标</div>
                                    </template>
                                </el-input>
                            </div>

                            <div class="card-footer">
                                <div class="card-warn" v-show="!item.isSync">此项未同步!</div>
                                <el-button type="primary" @click="saveCustomToolbar(item)">保存</el-button>
                                <el-button type="primary" @click="deleteCustomToolbar(item.id)">删除</el-button>
                            </div>
                        </el-card>

                    </el-form>
                </header>
            </div>
        </article>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import {  Card , ColorPicker ,Tooltip , Divider} from "element-ui";
    const getGuid = (format = 'xxxxxxxxxxxx') =>{
        var d = new Date().getTime();
        var guid = format.replace(
            /[xy]/g,
            function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });
        return guid;
    }

    export default {
        name: "ModelPartConfig",
        data (){
            return {
                showColor : false,
                showToolbarConfig : false,
                showCustomToolbar : false,
                colorMap : {
                },
                useToolbar : true,
                toolbarList : {
                    bimWalk : {
                        name : "第一人称",
                    },
                    shadow : {
                        name : "显示阴影"
                    },
                    jiaoxuan : {
                        name : "交选",
                    },
                    kuangxuan : {
                        name : "框选"
                    },
                    focus : {
                        name : "焦点"
                    },
                    measure : {
                        name : "测量",
                    },
                },
                hideIds : [],
                customToolbarMap : {
                }
            }
        },
        components: {
            "el-card" : Card,
            "el-color-picker": ColorPicker,
            "el-tooltip" : Tooltip,
            "el-divider" : Divider,
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        methods:{
            init(){
                eventBus.$emit("ViewerBIM.Part.GetColorMap" , this.curComponent.id , (map)=>{
                    for(let id in map){
                        const { color , dbids , label } = map[id];
                        this.$set(this.colorMap , id , {
                            id,
                            color,
                            dbids,
                            label,
                            isSync: true,
                        });
                    }
                });

                eventBus.$emit("ViewerBIM.Toolbar.GetConfig" , this.curComponent.id , ( config )=>{
                    if(config){
                        this.useToolbar = !!config.display;

                        if(config.hideIds){
                            this.hideIds = config.hideIds.concat();
                        }
                    }
                });

                eventBus.$emit("ViewerBIM.CustomToolbar.GetConfig" , this.curComponent.id , (config)=>{
                    for(let id in config){
                        this.$set( this.customToolbarMap , id , {
                            id ,
                            label : config[id].label,
                            icon : config[id].icon,
                            isSync : true,
                        } );
                    }
                });
            },
            getSelection( item ){
                eventBus.$emit('ViewerBIM.getSelection' , this.curComponent.id , ( selection )=>{
                    item.dbids = selection;
                    if(selection.length !== 0 ){
                        this.$message.success('选择构件成功');
                        item.isSync = false;
                    }else{
                        this.$message.warning('未选择构件');
                    }
                });
            },
            onColorChange(item){
                item.isSync = false;
            },
            onNameChange( item ){
                item.isSync = false;
            },
            viewSelection( item ){
               eventBus.$emit('ViewerBIM.setSelect' ,this.curComponent.id , JSON.parse(JSON.stringify(item.dbids)));
            },
            syncData(item){
                if(!item.color) return this.$message.warning('请修改颜色后进行同步');
                if(item.dbids.length === 0) return this.$message.warning('请先选择需要修改颜色的构件后进行同步');
                eventBus.$emit("ViewerBIM.Part.SyncColorData" , this.curComponent.id , JSON.parse(JSON.stringify(item)));
                item.isSync = true;
            },
            onDelete(item){
                eventBus.$emit("ViewerBIM.Part.ClearColorById" , this.curComponent.id , item.id );
                this.$delete( this.colorMap, item.id );
            },
            addColor(){
                const id = getGuid();

                this.$set(this.colorMap , id , {
                    id,
                    color: "",
                    dbids: [],
                    label: '新的颜色',
                    isSync: false,
                });
            },
            changeShowColor(){
                this.showColor = !this.showColor;
            },
            changeToolbarConfig(){
                this.showToolbarConfig = !this.showToolbarConfig;
            },
            changeUseToolbar(){
                this.useToolbar = !this.useToolbar;
                eventBus.$emit('ViewerBIM.changeUseToolbar' , this.curComponent.id , this.useToolbar);
            },
            changeHideList( id ){
                if(this.hideIds.indexOf(id) == -1){
                    this.hideIds.push(id);
                }else{
                    this.hideIds.splice( this.hideIds.indexOf(id) , 1);
                }
                eventBus.$emit('ViewerBIM.changeToolbarHideIds' , this.curComponent.id , this.hideIds.concat());
                this.$forceUpdate();
            },
            changeCustomList(){
                this.showCustomToolbar = !this.showCustomToolbar;
            },
            addCustomToolbar(){
                const id = `customtoolbar_${getGuid()}`;

                this.$set( this.customToolbarMap , id , {
                    id ,
                    label : "新的toolbar",
                    icon : "iconfont iconbim_diyirencheng",
                    isSync : false,
                } );
            },
            saveCustomToolbar(item){
                if(!item.label) return this.$message.warning('请输入名称');
                if(!item.icon) return this.$message.warning('请输入图标');

                eventBus.$emit("ViewerBIM.syncCustomToolbar" , this.curComponent.id , JSON.parse(JSON.stringify(item)));
                
                item.isSync = true;
            },
            deleteCustomToolbar( id ){
                this.$delete( this.customToolbarMap , id );
                eventBus.$emit("ViewerBIM.deleteCustomToolbar" , this.curComponent.id , id);
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="less" scoped>
    .model-config{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title{
            text-align: left;
        }
        .contents{
            width: 100%;
            overflow-y: auto;
        }
        .btn-bar{
            display: flex;
            justify-content: left;
        }
        .part-cards{
            padding-top: 5px;
        }
        .card-content{
            display: flex;
            justify-content: space-between;
            padding-top: 5px;

            .card-content-color{
                display: flex;
                .cc-text{
                    padding-top: 10px;
                    margin-right: 3px;
                }
            }
        }
        .card-footer{
            padding-top: 5px;
            display: flex;
            justify-content: right;

            .card-warn{
                color : red;
                padding-top: 10px;
                padding-right: 10px;
            }
        }
        .card-input{
            padding-top: 5px;
        }
        .toolbarmore{
            margin-left: 10px;
        }
    }
</style>