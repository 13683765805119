<!--
 * @Description: 表格配置
 * @Author: luocheng
 * @Date: 2021-09-30 11:32:13
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-26 10:06:05
-->
<template>
  <div class="table-config 123" v-if="curComponent">
    <h3 class="title">{{ curComponent.name }}</h3>
    <article class="contents">
      <!-- 字段配置 -->
      <FieldConfig></FieldConfig>
      <!-- 操作配置 -->
      <ActionConfig v-if="['TableCom', 'ScrollTableCom', 'ScrollBlock'].indexOf(curComponent.component) < 0"></ActionConfig>
      <!-- 行内导出配置 -->
      <ExportConfig v-if="['TableCom', 'ScrollTableCom', 'ScrollBlock'].indexOf(curComponent.component) < 0"></ExportConfig>
      <!-- 子表配置 -->
      <template v-if="statusConfig && statusConfig.isNesting">
        <!-- 子表字段 -->
        <NestingFieldConfig></NestingFieldConfig>
        <!-- 子表操作配置 -->
        <NestingActionConfig></NestingActionConfig>
      </template>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionConfig from './ActionConfig';
import FieldConfig from './FeildConfig';
import ExportConfig from './ExportConfig';
import NestingFieldConfig from './NestingFieldConfig';
import NestingActionConfig from './NestingActionConfig';

export default {
  name: 'TableConfig',
  components: {
    ActionConfig,
    FieldConfig,
    ExportConfig,
    NestingFieldConfig,
    NestingActionConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ]),
    statusConfig() {
      return this.curComponent?.statusConfig;
    }
  }
}
</script>

<style lang="less" scoped>
.table-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
    line-height: 16px;
    padding-bottom: 10px;
    word-break: break-all;
    color: #333;
    border-bottom: 1px solid #f2f3f5;
  }
  .contents{
    width: 100%;
    box-sizing: border-box;
    padding-right: 10px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>