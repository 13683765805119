<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2021-12-03 18:03:36
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-08-03 10:53:20
-->
<template>
  <article class="action-box">
    <section class="action-limit">
      <header>
        <h4>附加功能</h4>
        <el-button type="primary" @click="onSubmitAll" size="mini"
          >确认</el-button
        ><el-button type="warning" @click="forceRebuildNumber" size="mini"
          >刷新</el-button
        >
      </header>
      <section class="all-limit">
        <p class="title">显示业务数据数量</p>
        <el-switch
          style="width: 20%; height: 30px"
          v-model="countNumberShow"
        ></el-switch>
      </section>
      <section class="all-limit" v-show="countNumberShow">
        <el-form label-width="70px" style="margin-top: 5px">
          <el-radio-group
            v-model="countNumberConfig.numberDataType"
            size="mini"
            style="margin-bottom: 10px"
            @change="forceRebuildNumber"
          >
            <el-radio-button label="1">自动</el-radio-button>
            <el-radio-button label="2">组件</el-radio-button>
            <el-radio-button label="3">视图</el-radio-button>
          </el-radio-group>

          <!-- tips区 -->
          <p v-if="countNumberConfig.numberDataType == 1">自动查找，本页面的第一个表格组件的配置</p>
          <p v-if="countNumberConfig.numberDataType == 2">选择指定组件的配置作为视图 </p>
          <p v-if="countNumberConfig.numberDataType == 3">指定视图查找总数 </p>

          <!-- 组件区 -->
          <el-form-item v-show="countNumberConfig.numberDataType == 2" label="选择组件">
            <el-select v-model="countNumberConfig.numberDataComponent">
              <el-option v-for="(item) in componentArray" :key="item.id" :value="item.id">{{item.name}}</el-option>
            </el-select>
          </el-form-item>
          <!-- 组件区 -->
          
          <!-- 视图区 -->
          <el-form-item label="指定对象" v-show="countNumberConfig.numberDataType == 3">
            <SelectObjectUUID
              v-model="countNumberConfig.forceObjectUuid"
            ></SelectObjectUUID>
          </el-form-item>
          <el-form-item label="指定视图" v-show="countNumberConfig.numberDataType == 3">
            <SelectViewUUID
              :objectUUID="countNumberConfig.forceObjectUuid"
              v-model="countNumberConfig.forceViewUuid"
            ></SelectViewUUID>
          </el-form-item>
          <!-- 视图区 -->

          <el-form-item label="id字段名" v-show="countNumberConfig.numberDataType != 1">
            <el-input v-model="countNumberConfig.idToParam"></el-input>
          </el-form-item>
          
          <el-form-item label="前置文本">
            <el-input v-model="countNumberConfig.preText"></el-input>
          </el-form-item>
          <el-tooltip placement="bottom" effect="dark">
            <div slot="content">
              tips: 开启前置数字<br />将会在查询前置数字时给视图传入
              __show_pre__ = 1<br />请转至表格视图处修改
            </div>
            <el-form-item label="前置数字">
              <el-switch
                style="width: 20%; height: 30px"
                v-model="countNumberConfig.showPreNumber"
              ></el-switch>
            </el-form-item>
          </el-tooltip>
          <el-form-item label="主色">
            <el-color-picker
              v-model="countNumberConfig.masterColor"
              size="small"
            ></el-color-picker>
          </el-form-item>
          <el-form-item label="主色位置">
            <el-radio-group
              v-model="countNumberConfig.masterColorPosition"
              size="mini"
            >
              <el-radio-button label="前" />
              <el-radio-button
                v-show="countNumberConfig.showPreNumber"
                label="0"
              />
              <el-radio-button
                v-show="countNumberConfig.showPreNumber"
                label="/"
              />
              <el-radio-button label="1" />
            </el-radio-group>
          </el-form-item>
        </el-form>
      </section>

      <!-- 整体权限 -->
      <header>
        <h4>整体操作限制</h4>
        <el-button type="primary" @click="onSubmitAll" size="mini"
          >确认</el-button
        >
      </header>
      <section class="all-limit">
        <p class="title">整体架构</p>
        <SetArchiLimit v-model="actionLimitForm.archiLimit"></SetArchiLimit>
      </section>
      <section class="all-limit">
        <p class="title">整体权限</p>
        <SetPermissions v-model="actionLimitForm.permission"></SetPermissions>
      </section>
      <!-- UI判断逻辑 -->
      <UILogic v-model="actionLimitForm.UILogic"></UILogic>
    </section>
    <!-- 删除权限(内置功能单独权限控制) -->
    <section class="action-limit">
      <!-- 整体权限 -->
      <header>
        <h4>删除操作限制</h4>
        <el-button type="primary" @click="onSubmitDelete" size="mini"
          >确认</el-button
        >
      </header>
      <section class="all-limit">
        <p class="title">删除架构</p>
        <SetArchiLimit v-model="deleteLimitForm.archiLimit"></SetArchiLimit>
      </section>
      <section class="all-limit">
        <p class="title">删除权限</p>
        <SetPermissions v-model="deleteLimitForm.permission"></SetPermissions>
      </section>
      <!-- UI判断逻辑 -->
      <UILogic v-model="deleteLimitForm.UILogic"></UILogic>
    </section>
    <div class="action-config" v-if="actionList && actionList.length">
      <!-- 分开的按钮 -->
      <h4>按钮配置</h4>
      <section
        v-for="(item, index) in actionList"
        :key="index"
        class="action-item"
      >
        <el-form :model="item" label-width="80px">
          <el-form-item label="key">
            <el-input
              placeholder="请输入key"
              v-model="item.key"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              placeholder="请输入名称"
              v-model="item.name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="颜色">
            <el-color-picker v-model="item.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="架构限制">
            <SetArchiLimit v-model="item.archiLimit"></SetArchiLimit>
          </el-form-item>
          <el-form-item label="权限">
            <SetPermissions v-model="item.permission"></SetPermissions>
          </el-form-item>
          <!-- UI判断逻辑 -->
          <UILogic v-model="item.UILogic"></UILogic>
        </el-form>
        <i class="el-icon-delete remove-btn" @click="onRemove(index)"></i>
      </section>
      <div class="actions">
        <el-button type="primary" size="mini" @click="onSave">保存</el-button>
        <el-button type="success" size="mini" @click="onPlus">新增</el-button>
      </div>
    </div>
  </article>
</template>

<script>
import { ColorPicker, RadioGroup, RadioButton, Tooltip } from "element-ui";
import { mapState } from "vuex";
import SetPermissions from "@/components/common/SetPermissions";
import SetArchiLimit from "@/components/common/SetArchiLimit";
import UILogic from "@/components/common/UILogic";
import SelectViewUUID from "../common/SelectViewUUID.vue";
import SelectObjectUUID from "../common/SelectObjectUUID.vue";
import eventBus from '@/plugins/eventBus';

export default {
  name: "ActionConfig",
  components: {
    "el-color-picker": ColorPicker,
    "el-radio-group": RadioGroup,
    "el-radio-button": RadioButton,
    "el-tooltip": Tooltip,
    SetPermissions,
    SetArchiLimit,
    UILogic,
    SelectViewUUID,
    SelectObjectUUID,
  },
  data() {
    return {
      // key: 'modify',
      // type: 'modify',
      // name: '编辑',
      // color: '#409EFF',
      // eventList: []
      // permission: [], // 权限
      // archiLimit: [], // 架构限制
      actionList: [],
      actionLimitForm: {
        permission: [], // 权限
        archiLimit: [], // 架构限制
        UILogic: null,
      },
      deleteLimitForm: {
        permission: [], // 权限
        archiLimit: [], // 架构限制
        UILogic: null,
      },
      countNumberShow: false,
      countNumberConfig: {
        numberDataType: 1, // 1:自动查找 2:来自组件 3:来自视图
        idToParam:"type_id", // id 字段变成什么参数名来请求数据
        preText: "", // 前置文本
        showPreNumber: false,
        masterColorPosition: "", // 显示位置
        masterColor: "red", // 提醒色
        forceViewUuid: "", // 强制视图uuid
        forceObjectUuid: "", // 强制视图uuid
        numberDataComponent:"", // 数据组件
      },
    };
  },
  computed: {
    ...mapState(["curComponent","componentData"]),
    // 操作配置
    actionConfig() {
      return (this.curComponent && this.curComponent.actionConfig) || [];
    },
    componentArray(){
      return this.getComponentArray(this.componentData);
    },
    actionLimit() {
      return (
        this.curComponent?.actionLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
    // 删除权限
    deleteLimit() {
      return (
        this.curComponent?.deleteLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
  },
  created() {
    console.log(this.curComponent, "this.curComponent");
    if (this.actionConfig && this.actionConfig.length) {
      this.actionList = this.actionConfig.map((ele) => {
        return {
          permission: [], // 权限
          archiLimit: [], // 架构限制
          ...ele,
        };
      });
    }
    if (this.actionLimit) {
      this.actionLimitForm = this.actionLimit;
    }
    if (this.deleteLimit) {
      this.deleteLimitForm = this.deleteLimit;
    }
    if (this.curComponent.countNumberShow) {
      this.countNumberShow = true;
    } else {
      this.countNumberShow = false;
    }
    if (this.curComponent.countNumberConfig) {
      this.countNumberConfig = this.curComponent.countNumberConfig;
    }
  },
  methods: {
    /**
     * @desc: 整体权限配置
     */
    onSubmitAll() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          actionLimit: this.actionLimitForm || {
            permission: [], // 权限
            archiLimit: [], // 架构限制
            UILogic: null,
          },
          countNumberShow: this.countNumberShow,
          countNumberConfig: this.countNumberConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 删除权限配置
     */
    onSubmitDelete() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          deleteLimit: this.deleteLimitForm || {
            permission: [], // 权限
            archiLimit: [], // 架构限制
            UILogic: null,
          },
          countNumberShow: this.countNumberShow,
          countNumberConfig: this.countNumberConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 删除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.actionList.splice(index, 1);
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          actionConfig: this.actionList || [],
          countNumberShow: this.countNumberShow,
          countNumberConfig: this.countNumberConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.actionList.push({
        key: "",
        name: "",
        color: "#409EFF",
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
        UILogic: null,
      });
    },

    /**
     * @description: 获取摊平的组件列表
     * @param {*} component 待处理的数据
     * @param {Array} data 已处理好的数据
     * @return {Array} 摊平的组件数据
    */
    getComponentArray(component = null,data = []){
      if(component === null){
        component = this.componentData;
      }
      for(let i = 0;i<component.length;i++){
        data.push(component[i]);
        if(component[i].children && component[i].children.length){
          data = this.getComponentArray(component[i].children,data)
        }
      }
      return data;
    },


    /**
     * @description: 强制重建树形图数字
     */
    forceRebuildNumber(){
      eventBus.$emit('refresh-number-count' + this.curComponent.id)
    }
  },
};
</script>

<style lang="less" scoped>
h4 {
  text-align: left;
}
.action-limit {
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 10px;
  header {
    display: flex;
    padding-bottom: 10px;
    h4 {
      flex: 1;
    }
  }
  .all-limit {
    display: flex;
    padding-bottom: 5px;
    p {
      flex: 1;
      line-height: 32px;
    }
  }
}
.action-config {
  height: 100%;
  .action-item {
    display: flex;
    border-bottom: 1px solid #f2f3f5;
    padding-top: 10px;
    .el-form {
      flex: 1;
    }
    .remove-btn {
      margin-left: 10px;
      font-size: 16px;
      line-height: 40px;
      color: @dangerColor;
      cursor: pointer;
    }
  }
  .actions {
    padding-top: 10px;
  }
}
</style>