<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-08-31 15:19:18
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-13 12:20:25
 * @FilePath: \dataview-next\src\components\signConfig\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="sign-config" v-if="curComponent">
		<header class="header">
      <span class="line"></span>
      <p class="item-title">标记配置</p>
			<el-button type="warning" @click="onResetField" size="mini">重置</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确认操作</el-button>
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
    </header>
    <div class="configs" v-show="toggleShow">
      <el-form v-model="signConfig">
				<el-form-item label="显示标记" label-width="80px">
          <el-switch v-model="signConfig.show" />
        </el-form-item>
        <el-form-item v-if="signConfig.show" label="选择模板" label-width="80px">
          <el-select v-model="signConfig.pageUuid" placeholder="请选择">
						<el-option
							v-for="item in signList"
							:key="item.uuid"
							:label="item.name"
							:value="item.uuid">
						</el-option>
					</el-select>
        </el-form-item>
      </el-form>
    </div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { dataInterface } from '@/apis/data/index';
export default {
	name: "signConfig",
	data() {
			return {
					toggleShow:false,
					signConfig:{
						show:false,
						pageUuid:'',
						content:null
					},
					signList:[]
			};
	},
	components: {
	},
	computed: {
			...mapState(["curComponent","componentData"]),
	},
	mounted() {
			if(this.curComponent.signConfig){
				this.signConfig = this.curComponent.signConfig
			}
			this.getSignList()
	},
	methods: {
			/**
			 * @desc: 确认
			 */
			onConfirm() {
				let contentConfig = null
				if(this.signConfig.pageUuid){
					contentConfig = this.signList.filter((v)=>v.uuid === this.signConfig.pageUuid)[0] || null
					if(contentConfig!==null && contentConfig?.page_data?.componentData?.length){
						this.signConfig.content = contentConfig.page_data.componentData[0]
					}
				}
				console.log(this.signConfig.content,'this.signConfig.content')
				this.$store.commit("modifyComponent", {
						component: {
								...this.curComponent,
								signConfig: this.signConfig,
						},
						containerId: null,
						isModify: true,
				});
			},
			/**
			 * @desc: 重置
			 */
			onResetField(){
				this.signConfig = {
					show:false,
					pageUuid:''
				}
			},
			/**
			 * @desc: 获取标记模板
			 */
			getSignList(){
				dataInterface({
					object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
					page: 1,
					search: [
						{field_uuid: "field61bc41f645e17", ruleType: "like", value: "console"},
						{field_uuid: "d1f7e149-2af7-468e-85be-5ef9bd97f634", ruleType: "like", value: "样式"}
					],
					size: 1000,
					transcode: 0,
					view_uuid: "view6311790375749",
					__method_name__: "dataList",
				}).then((res) => {
					if(res.data.code === 200){
						this.signList = res.data.data.data
					}
				})
			},

	},
};
</script>

<style lang="less" scoped>
.sign-config {
	padding-top:10px;
	.title{
    text-align: left;
    line-height: 20px;
    line-height: 16px;
    padding-bottom: 10px;
    word-break: break-all;
    color: #333;
  }
	.header {
			width: 100%;
			display: flex;
			position:relative;
			margin-bottom: 10px;
			.item-title {
					flex: 1;
					text-align: left;
					padding-left: 13px;
					line-height: 32px;
			}
			.header-btn {
					margin-left: 5px;
					color: @theme;
					line-height: 32px;
			}
	}
	.header::before{
		content: '';
		position: absolute;
		left:0;
		top:9px;
		width: 3px;
		height: 14px;
		background-color: @theme;
	}
	.configs {
			.el-form-item {
					margin-bottom: 10px;
					.el-input {
							width: 180px;
					}
					.el-select {
							width: 180px;
					}
			}
	}
}
</style>