<!--
 * @Description: 编辑器入口
 * @Author: luocheng
 * @Date: 2021-08-10 16:07:13
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-03 10:50:25
-->
<template>
	<div
		class="editor screen-container"
		id="editor"
		:style="{
			height: canvasHeight + 'px',
			width: canvasWidth + 'px',
			backgroundColor: canvasStyle.backgroundColor
		}"
		@contextmenu="onContextMenu"
	>
		<!-- 网格线 -->
		<Grid></Grid>
		<!-- 放置区域 -->
		<div class="editor-content"
			:style="{
				...pageConfig.style,
				height: canvasHeight + 'px',
				width: canvasWidth + 'px',
			}"
			@drop="onDrop"
			@dragover="onDragOver"
			@mousedown="onClearStatus"
			@mouseup="clearCurComponent">
			<!-- 大屏布局 -->
			<!-- eslint-disable -->
			<Shape
				v-for="(item, index) in componentData"
				:key="item.id"
				:index="index"
				:defaultStyle="item.style || {}"
				:element="item"
				:style="getBoxStyle(item.style)"
				@mousedown="onMouseDown"
				v-if="(item.statusConfig && item.statusConfig.isShow)
					&& judgingArchiAPermission(item.archiLimit, item.authConfig && item.authConfig.list)
				"
				v-show="item.statusConfig
					&& judgingUILogic(item.UILogic, componentData)
					&& (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)
				"
				@click.native.stop="onClickBox(item)"
			>
				<component
					:is="item.component"
					:index="index"
					:element="item"
					:id="'component' + item.id"
					:style="getComStyle(item.style)"
					:propValue="item.propValue"
					:scaleHeight="1"
					:scaleWidth="1"
				>
				</component>
			</Shape>
			<!-- 选择框 -->
			<SelectArea :area="position" v-show="showArea"></SelectArea>
			<!-- 右击菜单 -->
			<EditorMenu></EditorMenu>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Grid from '@/components/common/Grid';
import { onSizeWithScale } from '@/utils/translate';
import { getStyle, getComponentRotatedStyle } from '@/utils/style';
import { deepCopy, getComponentId, getComponentById } from '@/utils/tools';
import componentList from '@/custom-component/component-list';
import Shape from './Shape';
import SelectArea from './SelectArea';
import EditorMenu from './EditorMenu';
import { $ } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';

// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
//中控台组件注册
componentsConsole.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsConsole(fileName).default;
});

export default {
	name: 'Editor',
	components: {
		...customComponents,
		Grid,
		Shape,
		SelectArea,
		EditorMenu
	},
	data() {
		return {
			// 编辑器位置点信息
			editorX: 0,
			editorY: 0,
			// 拖动选择位置信息
			position: {
				x: 0,
				y: 0,
				width: 0,
				height: 0
			},
			// 是否显示拖动位置框
			showArea: false
		};
	},
	computed: {
		...mapState([
			'canvasStyle', // 样式
			'componentData', // 所选组件列表
			'curComponent', // 单钱所选组件
			'editor', // 编辑器对象
			'pageConfig', // 页面整体样使配置
			'editorType', // 编辑器类型
			'isClickComponent'
		]),
		// 画布宽度
		canvasWidth() {
			if (isNaN(this.canvasStyle.width) || !this.canvasStyle.scale) return this.canvasStyle.width;
			return (this.canvasStyle.width * parseInt(this.canvasStyle.scale)) / 100;
		},
		// 画布高度
		canvasHeight() {
			if (isNaN(this.canvasStyle.height) || !this.canvasStyle.scale) return this.canvasStyle.height;
			return (this.canvasStyle.height * parseInt(this.canvasStyle.scale)) / 100;
		}
	},
	methods: {
		onSizeWithScale,
		judgingArchiAPermission,
		judgingUILogic,
		onClearStatus(e) {
			this.$store.commit('setClickComponentStatus', false)
			this.onMouseDown(e);
		},
		/**
		 * @desc: 拖动组件放置在画布中
		 */
		onDrop(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$store.commit('hideEditorMenu');
			// 根据组ID与组件ID获取组件
			const groupIndex = e.dataTransfer.getData('group-index');
			const comIndex = e.dataTransfer.getData('index');
			if (isNaN(groupIndex) || isNaN(comIndex) || !Array.isArray(componentList) || !componentList.length) {
				this.$message.error('获取组件失败！');
				return;
			}
			const group = componentList[groupIndex];
			if (!group || !group.componentList || !Array.isArray(group.componentList)) {
				this.$message.error('获取组件失败！');
				return;
			}
			let component = deepCopy(group.componentList[comIndex]);
			component = this.setComponentStyle(component);
			component.style.top = e.offsetY || 0;
			component.style.left = e.offsetX || 0;
			component.id = getComponentId(component.component);
			// 将背景设置为透明
			component.style.backgroundColor = 'transparent';
			// 组件嵌套方式
			const target = e.target;
			let containerId = null;
			// 处理元素无宽高的计算错误问题(具体组件可以后续具体限制)
			const componentStyle = component?.style || {};
			const { width = '', height = '' } = componentStyle;
			if (!width || isNaN(width)) {
				component.style.width = 400;
			}
			if (!height || isNaN(height)) {
				component.style.height = 300;
			}
			// 待测试(对于定位布局的需要后面测试)
			if (target instanceof SVGElement || target.className.includes('editor-content')) {
				// console.log('放置到画布根节点上!');
			} else {
				// console.log('放置到容器中!', target);
				// 1、当前放置位置为容器则在容器中追加
				// 2、获取当前放置节点的第一个祖先容器并放置
				const parentContainer = this.getParentContainer(target);
				if (!parentContainer || parentContainer.containerId.toLowerCase().indexOf('container') < -1) return;
				if (parentContainer) {
					containerId = parentContainer.containerId;
					component.style.width = parentContainer.target.offsetWidth / 2;
					component.style.height = parentContainer.target.offsetHeight / 2;
				}
			}
			// 将组件写入到图层列表
			this.$store.commit('modifyComponent', { component, containerId });
			// 添加快照
			this.$store.commit('recordSnapshot');
			e.dataTransfer.setData('group-index', -1);
			e.dataTransfer.setData('index', -1);
		},
		/**
		 * @desc: 设置组件样式主要用于大屏设置样式
		 * @param {Object} component
		 */
		setComponentStyle(componentObj) {
			let { style, component } = componentObj;
			if (sessionStorage.getItem('editorType') === 'dataview') {
				style = {
					...style,
					background: 'transparent'
				}
			}
			if (sessionStorage.getItem('editorType') === 'console') {
				style = {
					...style,
				}
			}
			if (component === 'BaseIcon') {
				style = {
					...style,
					minWidth: sessionStorage.getItem('editorType') === 'dataview' || sessionStorage.getItem('editorType') === 'console' ? 0 : 100,
					color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				}
			} else if (component === 'CommonTitle') {
				style = {
					...style,
					color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				}
			} else if (component === 'CommonText') {
				style = {
					...style,
					minWidth: sessionStorage.getItem('editorType') === 'dataview' || sessionStorage.getItem('editorType') === 'console' ? 0 : 200,
					color: sessionStorage.getItem('editorType') === 'dataview' || sessionStorage.getItem('editorType') === 'console' ? '#fff' : '#333',
				}
			}
			return {
				...componentObj,
				style
			};
		},
		/**
		 * @desc: 获取距离当前节点最近的容器ID
		 * @param {Object} target 当前节点
		 * @return {String} 返回节点树上距离当前节点最近的容器ID
		 */		
		getParentContainer(target) {
			if (typeof target !== 'object') return null;
			if (target.dataset && target.dataset.id) {
				return {
					target,
					containerId: target.dataset.id
				};
			}
			return this.getParentContainer(target.parentNode);
		},
		/**
		 * @desc: 拖动组件进入到画布区域
		 */
		onDragOver(e) {
			e.preventDefault();
			e.dataTransfer.dropEffect = 'copy';
		},
		/**
		 * @desc: 清除当前选中组件
		 */
		clearCurComponent(e) {
			if (!this.isClickComponent) {
				this.$store.commit('setCurComponent', { component: null, index: null });
			}
			// 0 左击 1 滚轮 2 右击
			// 右击关闭菜单
			if (e.button != 2) {
				this.$store.commit('hideEditorMenu');
			}
		},
		/**
		 * @desc: 根据图层的样式对象获取可渲染的组件样式
		 * @param {Object/null} styleObj
		 * @return {Object} 壳渲染组件
		 */
		getComStyle(styleObj) {
			if (!styleObj || !styleObj.backgroundColor) return {};
			let excludesArr = ['top', 'left', 'rotate'];
			return getStyle(styleObj, excludesArr);
		},
		/**
		 * @desc: 获取组件盒子的样式
		 * @param {Object} style 组件样式
		 * @return {Object} 格式化后样式对象
		 */
		getBoxStyle(styleObj, isLayout = false) {
			if (isLayout) {
				// 布局样式
				return this.getComStyle(styleObj, isLayout);
			}
			const result = {};
			['width', 'height', 'top', 'left', 'rotate'].forEach((attr) => {
				const attrVal = styleObj[attr]
				if (attr != 'rotate') {
					if (typeof attrVal === 'number' || !isNaN(+attrVal)) {
						result[attr] = attrVal + 'px';
					} else {
						result[attr] = attrVal;
					}
				} else {
					result.transform = 'rotate(' + attrVal + 'deg)';
				}
			});
			return result;
		},
		/**
		 * @desc: 拖动选择组件进行组合
		 */
		onMouseDown(e) {
			// 如果没有选中组件 在画布上点击时需要调用 e.preventDefault() 防止触发 drop 事件
			e.preventDefault();
			if (!this.curComponent) {
				e.preventDefault();
			}
			this.hideArea();
			// 获取编辑器的位移信息，每次点击时都需要获取一次。主要是为了方便开发时调试用。
			const rectInfo = this.editor.getBoundingClientRect();
			this.editorX = rectInfo.x;
			this.editorY = rectInfo.y;

			const startX = e.clientX;
			const startY = e.clientY;
			this.position.x = startX - this.editorX;
			this.position.y = startY - this.editorY;
			// 展示选中区域
			this.showArea = true;
			// 移动鼠标
			const move = (moveEvent) => {
				moveEvent.preventDefault()
				this.position.width = Math.abs(moveEvent.clientX - startX);
				this.position.height = Math.abs(moveEvent.clientY - startY);
				if (moveEvent.clientX < startX) {
					this.position.x = moveEvent.clientX - this.editorX;
				}
				if (moveEvent.clientY < startY) {
					this.position.y = moveEvent.clientY - this.editorY;
				}
			};
			// 抬起鼠标
			const up = (moveEvent) => {
				document.removeEventListener('mousemove', move);

				if (moveEvent.clientX == startX && moveEvent.clientY == startY) {
					this.hideArea();
					return;
				}

				this.createGroup();
				document.removeEventListener('mouseup', up);
			};
			document.addEventListener('mousemove', move);
			document.addEventListener('mouseup', up);
		},
		/**
		 * @desc: 创建分组
		 */
		createGroup() {
			// 获取选中区域的组件数据
			const areaData = this.getSelectArea();
			if (areaData.length <= 1) {
				this.hideArea();
				return;
			}
			// 根据选中区域和区域中每个组件的位移信息来创建 Group 组件
			// 要遍历选择区域的每个组件，获取它们的 left top right bottom 信息来进行比较
			let top = Infinity,
				left = Infinity;
			let right = -Infinity,
				bottom = -Infinity;
			areaData.forEach((component) => {
				let style = {};
				if (component.component == 'Group') {
					component.propValue.forEach((item) => {
						const rectInfo = $(`#component${item.id}`).getBoundingClientRect();
						style.left = rectInfo.left - this.editorX;
						style.top = rectInfo.top - this.editorY;
						style.right = rectInfo.right - this.editorX;
						style.bottom = rectInfo.bottom - this.editorY;

						if (style.left < left) left = style.left;
						if (style.top < top) top = style.top;
						if (style.right > right) right = style.right;
						if (style.bottom > bottom) bottom = style.bottom;
					});
				} else {
					style = getComponentRotatedStyle(component.style);
				}

				if (style.left < left) left = style.left;
				if (style.top < top) top = style.top;
				if (style.right > right) right = style.right;
				if (style.bottom > bottom) bottom = style.bottom;
			});

			this.position.x = left;
			this.position.y = top;
			this.position.width = right - left;
			this.position.height = bottom - top;
			// 设置选中区域位移大小信息和区域内的组件数据
			this.$store.commit('setAreaData', {
				style: {
					left,
					top,
					width: this.position.width,
					height: this.position.height
				},
				components: areaData
			});
		},
		/**
		 * @desc: 获取拖选区域内组件
		 */
		getSelectArea() {
			const result = [];
			// 区域起点坐标
			const { x, y } = this.position;
			// 计算所有的组件数据，判断是否在选中区域内
			// 锁定的以及未显示的不进如组合
			this.componentData.forEach((component) => {
				if (!component.isLock && component.statusConfig.isShow ) {
					const { left, top, width, height } = component.style;
					if (
						x <= left &&
						y <= top &&
						left + width <= x + this.position.width &&
						top + height <= y + this.position.height
					) {
						result.push(component);
					}
				}
			});
			// 返回在选中区域内的所有组件
			return result;
		},
		/**
		 * @desc: 影藏拖选框
		 */
		hideArea() {
			this.showArea = false;
			this.position = {
				x: 0,
				y: 0,
				width: 0,
				height: 0
			};
		},
		/**
		 * @desc: 打开右击菜单
		 */
		onContextMenu(e) {
			e.stopPropagation();
			e.preventDefault();
			/* eslint-disable */
			// 计算菜单相对于编辑器的位移
			let target = e.target;
			let top = e.offsetY;
			let left = e.offsetX;
			while (target instanceof SVGElement) {
				target = target.parentNode;
			}

			while (!target.className.includes('editor')) {
				left += target.offsetLeft;
				top += target.offsetTop;
				target = target.parentNode;
			}
			this.$store.commit('showEditorMenu', {
				left,
				top
			});
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} element 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(element) {
			// console.log('触发方式实质就是在改变指定的组件状态,-----')
			// console.log(element.events, )
			if (!element.events || !element.events.click) {
				return;
			}
			const click = element.events.click || {};
			const { pattern, effects = [], specialEventList = [] } = click;
			console.log('---click', pattern)
			if (!pattern) {
				const { actionType, linkType, queryList = [], linkUrl = '', pageId } = click;
				if (actionType === 'jumpPage') {
					if (linkType === 'projectPage') {
						const query = {};
						queryList.forEach((queryItem) => {
							let component = getComponentById(this.componentData, queryItem.componentId);
							this.$set(
								query,
								queryItem.key,
								component?.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: pageId,
							query
						});
						return;
					} else if (linkType === 'outPage') {
						window.open(linkUrl);
					} else if (linkType === 'custom') {
						// 暂未处理
						// const customLink = getLinkByTemplate(linkTemplate, output)
						// window.open(customLink);
					}
				} else if (actionType === 'eeAction') {
					// 触发后端事件 暂未处理
					// console.log(ele, '0000001111111111', output);
					// this.doEEAction(element, output, type);
					// 搜集参数
				} else {
					effects.forEach((ele) => {
						this.$store.commit('triggerEvents', {
							config: ele,
							element: element
						});
					});
				}
			} else if (pattern === 'special') {
				console.log('specialEventList', specialEventList)
				specialEventList.forEach(ele => {
					const { actionType, linkType, queryList = [] } = ele;
					if (actionType === 'jumpPage') {
						if (linkType === 'projectPage') {
							const query = {};
							queryList.forEach((queryItem) => {
								let component = getComponentById(this.componentData, queryItem.componentId);
								this.$set(
									query,
									queryItem.key,
									component?.resolveData[queryItem.feild]
								);
							});
							this.$router.push({
								path: ele.pageId,
								query
							});
							return;
						} else if (linkType === 'outPage') {
							window.open(ele.linkUrl);
						} else if (linkType === 'custom') {
							// 暂未处理
							// const customLink = getLinkByTemplate(linkTemplate, output)
							// window.open(customLink);
						}
					} else if (actionType === 'eeAction') {
						// 触发后端事件 暂未处理
						// console.log(ele, '0000001111111111', output);
						// this.doEEAction(element, output, type);
						// 搜集参数
					} else {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: effect,
								element: element
							});
						})
					}
				})
			}
			const behaviors = element?.events?.click?.behaviors || [];
			// 触发行为
			behaviors.forEach(behavior => {
				this.$store.commit('triggerEvents', {
					config: {
						behavior,
						isBehavior: true
					},
					element
				});
			});
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$store.commit('getEditor');
		});
		// 隐藏拖选
		eventBus.$on('hideArea', () => {
			this.hideArea();
		});
	},
	beforeDestroy() {
		eventBus.$off('hideArea');
	}
};
</script>

<style lang="less" scoped>
.editor {
	position: relative;
	background: #fff;
	margin: auto;
	transition: all 0.3s;
	.editor-content {
		position: relative;
		height: 100%;
		width: 100%;
		z-index: 100;
		overflow: hidden;
		background: transparent;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
	}
}
</style>
