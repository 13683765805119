<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2021-09-30 11:46:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-10-20 09:32:05
-->
<template>
  <div class="action-config">
    <header class="header">
      <span class="line"></span>
      <p class="item-title">基础配置</p>
      <el-button type="warning" size="mini" @click="onReset">重置</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确认配置</el-button>
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
    </header>
    <div class="configs" v-show="toggleShow">
      <el-form :model="listConfig">
				<el-form-item label="标题内容" label-width="90px">
					<el-input v-model="listConfig.title" placeholder="请输入" />
				</el-form-item>
        <el-form-item label="排列方向" label-width="90px">
					<el-select v-model="listConfig.axisDirection" placeholder="请选择">
							<el-option label="水平排列" value="x"	></el-option>
							<el-option label="垂直排列" value="y"	></el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="分布方向" label-width="90px">
					<el-select v-model="listConfig.itemPosition" placeholder="请选择">
							<el-option label="右侧分布" value="right"	></el-option>
							<el-option label="左侧分布" value="left"	></el-option>
							<el-option label="两侧均匀分布" value="between"	></el-option>
					</el-select>
        </el-form-item>
        <el-form-item v-if="chooseFields.length &&  listConfig.axisDirection === 'x'" label="置顶参数" label-width="90px">
					<el-select multiple v-model="listConfig.topFields" placeholder="请选择">
							<el-option v-for="item in chooseFields"  :key="item.uuid" :value="item.uuid" :label="item.label"> {{ item.label }}</el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="单元格宽度" label-width="90px">
					<el-input v-model="listConfig.itemWidth" placeholder="请输入" />
				</el-form-item>
				<el-form-item label="单元格高度" label-width="90px">
					<el-input v-model="listConfig.itemHeight" placeholder="请输入" />
				</el-form-item>
        <el-form-item label="轴虚线" label-width="90px">
					<el-switch	v-model="listConfig.isDashed"></el-switch>
				</el-form-item>
        <el-form-item label="点居中" label-width="90px">
					<el-switch	v-model="listConfig.centerDot"></el-switch>
				</el-form-item>
        <el-form-item label="文本对齐" label-width="90px">
					<el-select v-model="listConfig.textAlign" placeholder="请选择">
							<el-option label="居中对齐" value="center"	></el-option>
							<el-option label="左对齐" value="left"	></el-option>
							<el-option label="右对齐" value="right"	></el-option>
					</el-select>
        </el-form-item>
        <el-form-item label="字体颜色" label-width="90px">
					<el-color-picker
							v-model="listConfig.itemCol"
							show-alpha
						>
						</el-color-picker>
				</el-form-item>
        <el-form-item label="手动背景色" label-width="90px">
					<el-switch	v-model="listConfig.isBaseBg"></el-switch>
				</el-form-item>
				<el-form-item v-show="listConfig.isBaseBg" label="单元格背景" label-width="90px">
					<el-color-picker
							v-model="listConfig.itemBg"
							show-alpha
						>
						</el-color-picker>
				</el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ColorPicker } from "element-ui";

export default {
  name: 'ActionConfig',
  components: {
		"el-color-picker": ColorPicker,
  },
  data() {
    return {
      toggleShow: false,
      // 操作
      listConfig: {
				axisDirection:'x',
				itemPosition:'right',
				title:'轴列表',
				itemWidth:200,
				itemHeight:100,
        isBaseBg:true,//手动背景色
        centerDot:false,//点居中
        isDashed:false,//虚线轴
				itemBg:'#fff',
        itemCol:'#000000',
        textAlign:'left',
        topFields:[],//置顶参数
			}
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ]),
    chooseFields (){
      let arr = []
      if(this.curComponent.fieldConfig?.length){
        for(let i=0; i < this.curComponent.fieldConfig.length; i++){
          if(this.curComponent.fieldConfig[i].show && (+this.curComponent.fieldConfig[i].type === 3 || +this.curComponent.fieldConfig[i].type === 1)){
            arr.push(this.curComponent.fieldConfig[i])
          }
        }
      }
      return arr

    }
  },
  created() {
    this.listConfig = this.curComponent && this.curComponent.listConfig;
  },
  methods: {
		onReset(){
			this.listConfig = {
				axisDirection:'x',
				itemPosition:'right',
				title:'轴列表',
				itemWidth:200,
				itemHeight:100,
        isBaseBg:true,//手动背景色
        centerDot:false,//点居中
        isDashed:false,//虚线轴
				itemBg:'#fff',
        textAlign:'left',
        itemCol:'#000000',
        topFields:[],//置顶参数
      }
		},
		/**
		 * @desc: 保存
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					listConfig: this.listConfig
				},
				containerId: null,
				isModify: true
			});
		},
    
  }
}
</script>

<style lang="less" scoped>
.action-config{
  padding: 10px 0;
  .header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
    .item-title{
      flex: 1;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
    }
    .header-btn{
      margin-left: 5px;
      color: @theme;
      line-height: 28px;
    }
  }
  .configs{
    .el-form{
      .el-form-item{
        margin-bottom: 10px;
      }
    }
  }
}
</style>