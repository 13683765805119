<!--
 * @Description: 来源配置ITEM
 * @Author: luocheng
 * @Date: 2022-04-22 10:43:35
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-16 10:38:18
-->
<template>
  <div class="source-item">
    <el-form :model="sourceConfig" label-width="100px">
      <el-form-item class="is-required" label="字段名">
        <el-input v-model="sourceConfig.code" placeholder="请输入字段名" clearable @input="onEmit"></el-input>
      </el-form-item>
      <el-form-item label="必填">
        <el-switch v-model="sourceConfig.isRequired" @change="onEmit"></el-switch>
      </el-form-item>
      <el-form-item class="is-required" label="值来源">
        <el-select v-model="sourceConfig.source" @change="onEmit" placeholder="请选择值来源">
          <el-option label="页面组件" value="component"></el-option>
          <el-option label="url取值" value="url"></el-option>
          <el-option label="系统参数" value="system"></el-option>
        </el-select>
      </el-form-item>
      <!-- 组件 -->
      <template v-if="sourceConfig.source === 'component'">
        <el-form-item label="关联组件">
          <el-select class="is-required" v-model="sourceConfig.component" placeholder="请选择关联组件" @change="onComponent">
            <el-option
              v-for="component in componentList"
              :key="component.value"
              :label="component.label"
              :value="component.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联字段" v-if="sourceConfig.component && fieldList">
          <el-select class="is-required" v-model="sourceConfig.componentField" placeholder="请选择关联字段"  @change="onEmit">
            <el-option
              v-for="(item, key) in fieldList"
              :key="item"
              :label="item + '【' + key + '】'"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <!-- url -->
      <el-form-item class="is-required" label="url参数key" v-else-if="sourceConfig.source === 'url'">
        <el-input v-model="sourceConfig.urlKey" placeholder="请输入url参数字段" @input="onEmit"></el-input>
      </el-form-item>
      <!-- 系统参数 -->
      <template v-else-if="sourceConfig.source === 'system'">
        <el-form-item class="is-required" label="系统对象">
          <el-select v-model="sourceConfig.systemObject"
            clearable
            filterable
            allow-create
            @change="onSystemObject"
          >
            <el-option label="用户信息" value="userInfo"></el-option>
            <el-option label="架构信息" value="targetArchi"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="is-required" label="取值参数">
          <el-select v-model="sourceConfig.systemField"
            clearable
            filterable
            allow-create
            @change="onEmit"
          >
            <el-option v-for="(val, field) in systemKeys"
              :key="field"
              :label="field"
              :value="field">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form>
    <section class="actions">
      <i class="el-icon-delete-solid remove" @click="onRemove"></i>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { flatTree, getComponentById, unique } from "@/utils/tools";

export default {
  name: 'SourceItem',
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      sourceConfig: {
        code: '', // 字段名
        isRequired: false, // 必须
        source: 'component', // 值来源， component 组件，url url参数，system 系统参数
        component: '', // 关联组件
        componentField: '', // 组件取值字段
        urlKey: '', // url参数字段
        systemObject: '', // 系统取值对象
        systemField: '' // 系统取值字段
      },
      // 字段列表
      fieldList: [],
      // 系统的参数
      systemKeys: {}
    }
  },
  computed: {
    ...mapState(['curComponent', 'componentData', 'sContainer', 'originComponentData']),
    // 可选组件列表
    componentList() {
      let result = []
      if (this.sContainer) {
        result = flatTree(this.originComponentData || [], 'children', {
          label: 'name',
          value: 'id',
        }, [this.curComponent.id], []);
      }
			return unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id',
      }, [this.curComponent.id], [])));
    }
  },
  created() {
    this.sourceConfig = {
      ...this.config
    };
  },
  methods: {
    /**
     * @desc: 选择组件后清空字段并重新获取字段列表
     */
    onComponent(val, clearField) {
      if (clearField !== -1) {
        this.sourceConfig.componentField = '';
      }
      this.fieldList = null;
      const component = getComponentById(this.componentData, val);
      if (component.component === 'CommonTabs') {
        this.fieldList = {
          label: '说明',
          value: '值'
        };
      } else {
        this.fieldList = component && component.metadata;
      }
      this.onEmit();
    },
    /**
     * @desc: 系统参数
     */
    onSystemObject() {
      this.systemKeys = {};
      // console.log(this.sourceConfig.systemObject, '11111111');
      try {
        let obj = sessionStorage.getItem(this.sourceConfig.systemObject);
        if (!obj) {
          obj = localStorage.getItem(this.sourceConfig.systemObject);
        }
        if (!obj) return;
        const result = JSON.parse(obj);
        if (result && Object.prototype.toString.call(result) === '[object Object]') {
          this.systemKeys = result;
        }
      } catch (err) {
        console.log(err, '99999999');
      }
      this.onEmit();
    },
    /**
     * @desc: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: {
          ...this.sourceConfig
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.source-item{
  width: 100%;
  display: flex;
  border: 1px solid #f2f3f5;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 5px;
  margin-bottom: 10px;
  .el-form-item {
    margin-bottom: 10px;
    &:last-of-type{
      margin: 0;
    }
  }
  .actions{
    padding-left: 10px;
    .remove{
      color: @dangerColor;
      font-weight: bold;
      font-size: 16px;
      line-height: 100px;
      cursor: pointer;
    }
  }
}
</style>