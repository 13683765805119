<!--
 * @Description: 树形图懒加载配置
 * @Author: luocheng
 * @Date: 2021-09-14 19:00:23
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-29 14:12:58
-->
<template>
  <div>
    <h4>树形图懒加载配置</h4>
    <SelectView></SelectView>
  </div>
</template>

<script>
import SelectView from '../SelectView';

export default {
  name: 'TreeLoad',
  components: {
    SelectView
  }
}
</script>

<style>

</style>