<!--
 * @Description: Tab事件配置 切换到对应的值之后进行相应组件显隐状态切换
 * @Author: luocheng
 * @Date: 2021-10-08 18:04:08
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:31:49
-->
<template>
  <div class="tab-event">
    <section class="item" v-for="(item, key) in tabConfig" :key="key">
      <el-row>
        <el-col :span="8">Tab名称：</el-col>
        <el-col :span="16">{{ item.label }}</el-col>
      </el-row>
      <el-form
        class="event-list"
        v-if="item.eventList && item.eventList.length"
      >
        <el-row
          v-for="(value, index) in item.eventList"
          :key="index"
          class="event-box"
        >
          <el-form-item label="事件类型:" label-width="120px">
            <h3 class="title">{{ value.key | filterEvent }}</h3>
          </el-form-item>
          <div v-for="(effect, eIndex) in value.effects" :key="eIndex">
            <el-form-item label="作用组件:" label-width="120px">
              <el-select
                v-model="effect.targets"
                placeholder="请选择作用组件"
                multiple
                disabled
              >
                <el-option
                  v-for="item in targetList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="操作:" label-width="120px">
              <div class="tag-list">
                <el-tag>{{ effect.actions | filterActions }}</el-tag>
              </div>
            </el-form-item>
          </div>
        </el-row>
      </el-form>
      <el-row>
        <el-button type="success" size="mini" @click="onShowSelect(item)">绑定事件</el-button>
      </el-row>
    </section>
    <!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'tabEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { defaultList, actionList } from "../../config";
import { flatTree, unique } from '@/utils/tools';
import SelectEvent from '../../SelectEvent';
import { Tag } from 'element-ui';

export default {
  name: "TabsEvents",
  components: {
    SelectEvent,
    'el-tag': Tag
  },
  data() {
    return {
      // 显示配置抽屉
			showSelect: false,
			// 当前配置的按钮
			partData: null,
      tabConfig: []
    };
  },
  computed: {
    ...mapState(["curComponent", 'componentData', 'sContainer', 'originComponentData']),
    // 组件对象
    targetList() {
      let result = [];
			if (this.sContainer) {
        result = flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, []);
      }
			return unique(result.concat(flatTree(this.componentData, 'children', {
					label: 'name',
					value: 'id'
				}, [])))
    },
  },
  watch: {
    curComponent: {
      handler() {
        this.tabConfig = this.curComponent && this.curComponent.tabConfig;
      },
      deep: true,
      immediate: true
    }
  },
  filters: {
    // 事件名称过滤器
    filterEvent(value) {
      for (let i = 0; i < defaultList.length; i++) {
        if (defaultList[i].value === value) {
          return defaultList[i].label;
        }
      }
      return value;
    },
    // 操作过滤器
    filterActions(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        const item = actionList.find((ele) => ele.value === value[i]);
        if (item) {
          result.push(item.label);
        }
      }
      return result.join();
    },
  },
  methods: {
    /**
		 * @desc: 显示配置事件表单
		 * @param {Object} tabData 按钮配置数据
		 */
		onShowSelect(tabData) {
			if (!tabData) return;
			this.partData = tabData;
			this.showSelect = true;
		}
  }
};
</script>

<style lang="less" scoped>
</style>