<!--
 * @Author: zx
 * @Date: 2022-07-21 09:34:56
 * @LastEditTime: 2022-10-17 16:09:03
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="key-sitting">
    <div class="key-item">
      <div class="key-item-label">
        获取路由参数:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="获取query携带参数！"
        >
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <div class="params-container" style="height: auto; flex: 1">
        <div
          class="params-container-for"
          v-for="(item, index) in mobileQeuryInfo.params"
          :key="'index' + index"
        >
          <div class="params-container-left">
            <div class="item">
              <el-input
                v-model="item.key"
                placeholder="请输入key值"
                clearable
                @change="selectChange"
              ></el-input>
            </div>
            <div class="item">
              <el-select v-model="item.ruleType" @change="selectChange">
                <el-option v-for="ruleOption in ruleList" :key="ruleOption.value"
                  :label="ruleOption.label" :value="ruleOption.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="params-container-right">
            <i
              class="el-icon-remove remove"
              @click="onModifyOption(index, 'remove')"
            ></i>
            <i
              class="el-icon-plus plus"
              @click="onModifyOption(index, 'plus')"
            ></i>
          </div>
        </div>
        <div v-if="mobileQeuryInfo.params.length === 0">
          <i
            class="el-icon-plus plus"
            @click="onModifyOption('', 'add')"
          ></i>
        </div>
      </div>
    </div>
    <div class="key-item">
      <div class="key-item-label">
        获取组件参数:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="获取组件参数！"
        >
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <div class="params-container" style="height: auto; flex: 1">
        <div
          class="params-container-for"
          v-for="(item, index) in mobileComponentInfo.params"
          :key="'index' + index"
        >
          <div class="params-container-left">
            <div class="item">
              <el-select style="margin-bottom: 10px;" v-model="item.key" placeholder="请选择字段" @change="selectChange()">
                <el-option v-for="com in curComponent.database.fieldList"
                  :key="com.uuid"
                  :label="com.name"
                  :value="com.uuid">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <el-select style="margin-bottom: 10px;" v-model="item.component" placeholder="请选择组件" @change="changeComponent(index)">
                <el-option v-for="com in targetList"
                  :key="com.value"
                  :label="com.label"
                  :value="com.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <el-select v-model="item.ruleType" placeholder="请选择请求规则" @change="selectChange()">
                <el-option v-for="ruleOption in ruleList" :key="ruleOption.value"
                  :label="ruleOption.label" :value="ruleOption.value"
                ></el-option>
              </el-select>
            </div>
            <div class="item">
              <el-select style="margin-bottom: 10px;" v-model="item.value" placeholder="请选择组件内部参数" @change="selectChange()">
                <el-option
                  v-for="(fieldItem) in item.fieldList"
                  :key="fieldItem.uuid"
                  :label="fieldItem.name"
                  :value="fieldItem.uuid"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="params-container-right">
            <i
              class="el-icon-remove remove"
              @click="onModifyComponentOption(index, 'remove')"
            ></i>
            <i
              class="el-icon-plus plus"
              @click="onModifyComponentOption(index, 'plus')"
            ></i>
          </div>
        </div>
        <div v-if="mobileComponentInfo.params.length === 0">
          <i
            class="el-icon-plus plus"
            @click="onModifyComponentOption('', 'add')"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Popover } from "element-ui";
import { ruleList } from '@/components/filterConfig/options';
import { flatTree, unique, getComponentById } from '@/utils/tools';
export default {
  components: {
    "el-popover": Popover,
  },
  props: {},
  computed: {
    ...mapState(["componentData", "curComponent", 'sContainer', 'originComponentData']),
  },
  data() {
    return {
      mobileQeuryInfo: {
        params: []
      },
      mobileComponentInfo: {
        params: []
      },
      ruleList,
      targetList: []
    };
  },
  mounted() {
    let result = [];
    if (this.sContainer) {
      result = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
    }
    this.targetList = unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id'
      }, [])))
    if (
      this.curComponent && 
      this.curComponent.database && 
      this.curComponent.database.mobileQeuryInfo && 
      (typeof this.curComponent.database.mobileQeuryInfo === 'object')
    ) {
      const params = this.curComponent.database.mobileQeuryInfo.params
      if (this.curComponent.database.mobileQeuryInfo.params && this.curComponent.database.mobileQeuryInfo.params.length) {
        params.forEach((item) => {
          if (item.component) {
            this.mobileComponentInfo.params.push(item)
          } else {
            this.mobileQeuryInfo.params.push(item)
          }
        })
      } else {
        this.mobileQeuryInfo.params = []
        this.mobileComponentInfo.params = []
      }
    }
    this.selectChange()
  },
  methods: {
    selectChange() {
      const emitObj = {
        params: this.mobileQeuryInfo.params.concat(this.mobileComponentInfo.params)
      }
      this.$emit("setting", emitObj);
    },
    onModifyOption(index, type) {
      if (type === 'plus') {
        this.mobileQeuryInfo.params.splice(index, 0, {key:'', ruleType: ''})
        this.mobileQeuryInfo = JSON.parse(JSON.stringify(this.mobileQeuryInfo))
      } else if (type === 'remove') {
        this.mobileQeuryInfo.params.splice(index, 1)
        this.mobileQeuryInfo = JSON.parse(JSON.stringify(this.mobileQeuryInfo))
      } else if (type === 'add') {
        this.mobileQeuryInfo.params.push({
          key: '',
          ruleType: ''
        })
        this.mobileQeuryInfo.params = JSON.parse(JSON.stringify(this.mobileQeuryInfo.params))
      }
      this.selectChange()
    },
    onModifyComponentOption(index, type) {
      if (type === 'plus') {
        this.mobileComponentInfo.params.splice(index, 0, {key:'', ruleType: ''})
        this.mobileComponentInfo = JSON.parse(JSON.stringify(this.mobileComponentInfo))
      } else if (type === 'remove') {
        this.mobileComponentInfo.params.splice(index, 1)
        this.mobileComponentInfo = JSON.parse(JSON.stringify(this.mobileComponentInfo))
      } else if (type === 'add') {
        this.mobileComponentInfo.params.push({
          key: '',
          ruleType: ''
        })
        this.mobileComponentInfo.params = JSON.parse(JSON.stringify(this.mobileComponentInfo.params))
      }
      this.selectChange()
    },
		/**
		 * @desc: 选择组件后清空字段并重新获取字段列表
		 */
		onComponent(index, componentId) {
			const component = getComponentById(this.componentData, componentId);
      this.mobileComponentInfo.params[index].info = component.resolveData
		},
    /* 携带参数选择组件参数触发 */
    changeComponent(index) {
      let originComponent = getComponentById(this.componentData, this.mobileComponentInfo.params[index].component);
      if (originComponent.component !== "Calendar") {
        this.mobileComponentInfo.params[index].fieldList = originComponent.database.fieldList
        this.selectChange()
      } else if (originComponent.component === "Calendar") {
          const arr = [
            { name: '日期（年月日）', uuid: 'date' },
            { name: '日期（年月日 时分秒）', uuid: 'dateTime' },
            { name: '数量', uuid: 'num' },
            { name: '总数量', uuid: 'total_num' },
            { name: '当天日期范围', uuid: 'dateArr' },
            { name: '星期', uuid: 'weekDay' },
          ]
          this.mobileComponentInfo.params[index].fieldList = arr
          this.selectChange()
      }
    }
    
  },
};
</script>
<style lang='less' scoped>
.key-sitting {
  padding-top: 8px;
  .key-item {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    display: flex;
    margin-top: 10px;
    .key-item-label {
      width: 90px;
    }
    .params-container {
      width: 100%;
      height: 100%;
      .params-container-for {
        width: 100%;
        display: flex;
        align-items: center;
        .params-container-left {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            height: 35px;
          }
        }
        .params-container-right {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
      }
    }
  }
}
</style>