<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2021-09-30 11:46:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-04 11:59:44
-->
<template>
  <div class="action-config">
    <header class="header">
      <span class="line"></span>
      <p class="item-title">操作配置</p>
      <el-button type="success" size="mini" @click="onPlus">新增</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确认操作</el-button>
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
      <el-button type="warning" size="mini" @click="onSync">同步配置</el-button>
    </header>
    <div class="configs" v-show="toggleShow">
      <el-form :model="actionConfig">
        <el-form-item label="是否显示操作" label-width="100px">
          <el-switch v-model="actionConfig.needAction"></el-switch>
        </el-form-item>
        <el-form-item label="宽度" label-width="80px">
          <el-input v-model="actionConfig.width" size="mini" placeholder="请输入宽度"></el-input>
        </el-form-item>
        <section v-for="(item, key) in actionConfig" :key="key">
          <template v-if="typeof item === 'object'">
            <ActionItem v-model="actionConfig[key]" :keyWord="key"></ActionItem>
          </template>
        </section>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionItem from './ActionItem';
import containers from '@/custom-component/component-config/containers';

export default {
  name: 'ActionConfig',
  components: {
    ActionItem
  },
  data() {
    return {
      toggleShow: false,
      // 操作
      actionConfig: []
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  created() {
    this.actionConfig = this.curComponent && this.curComponent.actionConfig;
  },
  methods: {
    /**
     * @desc: 确认操作
     */
    onConfirm() {
      // 利用双向绑定直接操作了暂时无需进行确认操作
    },
    /**
     * @desc: 新增操作按钮
     */
    onPlus() {
      this.$prompt('请输入key值【英文，请勿使用：add/detail/modify/export/delete】', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: val => {
          if (['add', 'detail', 'modify', 'export', 'delete'].includes(val)) {
            return false;
          }
          return true;
        },
        inputErrorMessage: '请勿使用：add/detail/modify/export/delete!'
      }).then(({ value }) => {
        this.$store.commit('modifyComponent', {
          component: {
            ...this.curComponent,
            actionConfig: {
              ...this.curComponent.actionConfig,
              [value]: {
                use: true,
                useDepend: false,
                dependLogic: [],
                type: value,
                name: '自定义按钮',
                btnType: 'text',
                color: '#E6A23C',
                btnSize: '',
                margin: 5,
                eventList: [],
                permission: [], // 权限
                archiLimit: [], // 架构限制
                UILogic: null
              }
            }
          },
          containerId: null,
          isModify: true
        })
        this.actionConfig = this.curComponent && this.curComponent.actionConfig;
      }).catch(() => {
      });
      
    },
    // 同步配置
    onSync() {
      this.$confirm('是否确认同步配置？(谨慎使用可能出现新老配置不兼容情况！)').then(() => {
        const tableContainer = containers.componentList.find(ele => ele.component === 'CommonTableContainer');
        const { actionConfig = {} } = tableContainer;
        console.log(this.actionConfig, '----tableContainer------', tableContainer);
        for (let key in actionConfig) {
          const item = actionConfig[key]
          if (item && typeof item === 'object') {
            if (this.actionConfig[key]) {
              this.$set(this.actionConfig, key, {
                ...actionConfig[key],
                ...item
              });
            } else {
              console.log(key, '-----key-------')
              // 自定义按钮同步当前
              this.$set(this.actionConfig, key, {
                ...actionConfig.add,
                ...item
              });
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.action-config{
  padding: 10px 0;
  .header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
    .item-title{
      flex: 1;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
    }
    .header-btn{
      margin-left: 5px;
      color: @theme;
      line-height: 28px;
    }
  }
  .configs{
    .el-form{
      .el-form-item{
        margin-bottom: 10px;
      }
    }
  }
}
</style>