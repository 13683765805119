<!--
 * @Description: 绑定值
 * @Author: luocheng
 * @Date: 2021-08-30 11:42:28
 * @LastEditors: zx
 * @LastEditTime: 2022-10-13 09:15:31
-->
<template>
	<div class="bind-value">
		<template v-if="curComponent">
			<h3 class="title">组件名称: {{ curComponent.name }}</h3>
			<article class="value-main" v-if="curComponent.type !== 'group'">
				<section class="select-type" v-if="curComponent.type !== 'container'">
					<p class="label">值来源</p>
					<el-radio-group v-model="curComponent.valueOrign">
						<el-radio
							:label="item.key"
							v-for="item in typeList"
							:key="item.key"
						>{{ item.label }}</el-radio>
					</el-radio-group>
				</section>
				<el-collapse v-model="configType" accordion>
					<template v-if="curComponent.type !== 'container'">
						<el-collapse-item title="固定值" name="fixed" v-if="curComponent.valueOrign === 'fixed'">
							<FixedValue></FixedValue>
						</el-collapse-item>
						<el-collapse-item title="其他组件" name="component" v-if="curComponent.valueOrign === 'component'">
							<GetComValueVue :componentList="componentList"></GetComValueVue>
						</el-collapse-item>
					</template>
					<el-collapse-item title="数据仓库" name="database" v-if="curComponent.type === 'container' || curComponent.valueOrign === 'database'">
						<Database></Database>
					</el-collapse-item>
					<el-collapse-item title="值映射" name="valueMap" v-if="curComponent.valueOrign === 'valueMap'">
						<ValueMap></ValueMap>
					</el-collapse-item>
				</el-collapse>
			</article>
			<p class="no-current" v-else>当前组件不支持绑定值</p>
		</template>
		<p v-else class="no-current">请选择组件</p>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import FixedValue from './FixedValue';
import GetComValueVue from './GetComValue';
import Database from './database/Index';
import { flatTree, unique } from '@/utils/tools';
import ValueMap from './ValueMap';

export default {
	name: 'BindValue',
	components: {
		FixedValue,
		GetComValueVue,
		Database,
		ValueMap
	},
	data() {
		return {
			// 类型列表
			typeList: [
				{
					label: '固定值',
					key: 'fixed'
				},
				{
					label: '其他组件',
					key: 'component'
				},
				{
					label: '数据仓库',
					key: 'database'
				}
			],
			// 配置
			configType: this.curComponent ? this.curComponent.valueOrign : 'database',
			editorType: ''
		};
	},
	computed: {
		...mapState([
			'componentData', // 组件数据
			'curComponent', // 当前组件
			'originComponentData',
			'sContainer'
		]),
		// 可选组件列表
		componentList() {
			let result = [];
			if (this.sContainer) {
				result = flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, [this.curComponent.id], []);
			}
			result = result.concat(flatTree(this.componentData, 'children', {
				label: 'name',
				value: 'id'
			}, [this.curComponent.id], []))
			return unique(result);
		}
	},
	created() {
		console.log(this.curComponent, '----curComponent----');
		this.editorType = sessionStorage.getItem('editorType');
		if (this.editorType === 'card') {
			this.typeList.push({
				label: '值映射',
				key: 'valueMap'
			});
		}
	},
	methods: {}
};
</script>

<style lang="less" scoped>
.bind-value {
	height: 100%;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: column;
	.title {
		text-align: left;
		line-height: 20px;
		line-height: 16px;
		padding-bottom: 10px;
		word-break: break-all;
		color: #333;
	}
	.value-main {
		flex: 1;
		width: calc(100% + 15px);
		overflow: hidden;
		overflow-y: auto;
		margin-right: -15px;
	}
	.select-type {
		width: 266px;
		display: flex;
		padding: 10px 0;
		border-top: 1px solid #ebeef5;
		.label {
			width: 80px;
			vertical-align: middle;
			font-size: 14px;
			color: #606266;
			padding: 0 12px 0 0;
			box-sizing: border-box;
			text-align: left;
		}
		.el-radio-group {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			.el-radio {
				padding: 5px 0;
				width: 100%;
				text-align: left;
			}
		}
	}
	:deep(.el-form-item) {
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	:deep(.el-collapse)  {
		box-sizing: border-box;
		width: 266px;
		.el-collapse-item {
			box-sizing: border-box;
			width: 266px;
		}
		.el-collapse-item__wrap {
			padding-bottom: 10px;
			width: 100%;
			max-height: calc(100% - 26px);
			box-sizing: border-box;
			overflow: hidden;
			&:last-of-type{
				border: none;
			}
			.el-collapse-item__content {
				padding-bottom: 10px;
				width: 100%;
				max-height: calc(100% - 26px);
				box-sizing: border-box;
				overflow: hidden;
			}
		}
	}
	.no-current {
		color: @minorColor;
	}
}
</style>
