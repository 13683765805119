<!--
 * @Description: 设置架构限制
 * @Author: luocheng
 * @Date: 2022-03-02 16:39:41
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-12 15:02:29
-->
<template>
	<div>
		<el-select v-model="archiType" multiple placeholder="请选择架构限制(默认无)" @change="onEmit">
			<el-option label="集团" value="group"></el-option>
			<el-option label="公司" value="company"></el-option>
			<el-option label="项目" value="project"></el-option>
		</el-select>
	</div>
</template>

<script>
export default {
	name: 'SetArchiLimit',
	props: {
		value: {
			required: true
		}
	},
	data() {
		return {
			archiType: []
		};
	},
	created() {
		this.archiType = this.value || [];
	},
	methods: {
		onEmit() {
			this.$emit('input', this.archiType);
			this.$emit('change', this.archiType);
		}
	}
};
</script>

<style lang="less" scoped></style>
