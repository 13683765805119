<!--
 * @Description: 基础对接数据接口的配置
 * 主要负责
 * @Author: luocheng
 * @Date: 2021-12-03 14:17:35
 * @LastEditors: zx
 * @LastEditTime: 2022-10-13 09:17:19
-->
<template>
	<div class="database-config" v-loading="loading">
		<el-form :model="formData" label-width="100px">
			<el-form-item label="数据对象">
				<el-select
					v-model="formData.object_uuid"
					filterable
					remote
					placeholder="请选择数据对象"
					:remote-method="onRemoteObject"
					@change="onObject"
					clearable
				>
					<el-option v-for="obj in objectList" :key="obj.id" :label="obj.name" :value="obj.uuid"></el-option>
				</el-select>
			</el-form-item>
			<template v-if="formData.object_uuid">
				<el-form-item label="视图">
					<el-select v-model="formData.view_uuid" filterable placeholder="请选择视图" clearable>
						<el-option v-for="item in viewList" :key="item.id" :label="item.name" :value="item.uuid"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="表单">
					<el-select v-model="formData.form_uuid" filterable placeholder="请选择表单" clearable>
						<el-option
							v-for="formObj in formList"
							:key="formObj.id"
							:label="formObj.name || '默认'"
							:value="formObj.uuid"
						></el-option>
					</el-select>
				</el-form-item>
			</template>
			<el-button type size="mini" @click="onReset">重置</el-button>
			<el-button type="primary" size="mini" @click="onConfirm(false)">确认</el-button>
		</el-form>
	</div>
</template>

<script>
import { getObjects, getViews, dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';

export default {
	name: 'DatabaseConfig',
	props: {
		configData: {
			type: Object,
			default: () => {
				return {
					object_uuid: '',
					view_uuid: '',
					form_uuid: ''
				};
			}
		},
		modifyKey: {
			type: String,
			require: true,
			default: ''
		}
	},
	data() {
		return {
			// 配置
			formData: {
				object_uuid: '', // table object_uuid
				view_uuid: '', // table view_uuid
				form_uuid: '' // table all view_uuid
			},
			loading: false,
			// 对象
			objectList: [],
			viewList: [],
			formList: []
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	created() {
		const { object_uuid = '', view_uuid = '', form_uuid = '' } = this.configData;
		this.formData = {
			object_uuid,
			view_uuid,
			form_uuid
		};
		this.onRemoteObject('', this.formData.object_uuid || '');
		if (object_uuid) {
			this.getViewList();
			this.getFormList();
		}
	},
	methods: {
		/**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name = '', code = '') {
			this.loading = true;
			getObjects({
				name,
				code,
				uuid: code
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.objectList = [];
					this.loading = false;
				});
		},
		/**
		 * @desc: 选择对象
		 */
		onObject() {
			this.formData.view_uuid = '';
			this.formData.form_uuid = '';
			if (this.formData.object_uuid) {
				this.getViewList();
				this.getFormList();
			}
		},
		/**
		 * @desc: 远程搜索视图
		 */
		getViewList() {
			this.loading = true;
			getViews({
				object_uuid: this.formData.object_uuid,
				size: 999
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 远程搜索视图
		 */
		getFormList() {
			this.loading = true;
			dataInterface(
				{
					object_uuid: this.formData.object_uuid,
					name: ''
				},
				'api/saas/metadata/getFormDesigns',
				'GET'
			)
				.then((res) => {
					console.log(res.data.data);
					if (res && res.data && res.data.data) {
						this.formList = res.data.data || [];
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.formList = [];
					this.loading = false;
				});
		},
		/**
		 * @desc: 重置
		 */
		onReset() {
			this.formData = {
				object_uuid: '',
				view_uuid: '',
				form_uuid: ''
			};
			this.onConfirm(true);
		},
		/**
		 * @desc: 确认提交
		 * @params {Boolean} isReset 是否为重置
		 */
		onConfirm(isReset = false) {
			console.log(isReset, '-----提交');
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					[this.modifyKey]: {
						...this.formData
					}
				},
				containerId: null,
				isModify: true
			});
		}
	}
};
</script>

<style lang="less" scoped>
.database-config {
	.actions{
		display: flex;
		.el-button{
			flex: 1;
		}
	}
}
</style>
