<template>
  <div class="select-bind-value">
    <el-form-item label="参数来源">
      <el-select v-model="configData.sourceType" placeholder="请获取参数来源方式" @change="onEmit">
        <el-option label="内部组件" value="component"></el-option>
        <el-option label="URL参数" value="url"></el-option>
        <el-option label="系统参数" value="system"></el-option>
        <el-option label="页面状态" value="pageStatus"></el-option>
        <el-option label="固定值" value="fixed"></el-option>
      </el-select>
    </el-form-item>
    <!-- 系统参数 -->
    <template v-if="configData.sourceType === 'system'">
      <el-form-item label="系统对象">
        <el-select v-model="configData.systemKey" placeholder="请选择系统对象" clearable
          @change="onSystemObject">
          <el-option label="用户信息" value="userInfo"></el-option>
          <el-option label="当前架构" value="targetArchi"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="取值Code" v-if="configData.systemKey && systemObject">
        <el-select v-model="configData.systemCode" placeholder="请选择取值对象" @change="onEmit">
          <el-option v-for="(value, key) in systemObject"
            :key="key"
            :label="key"
            :value="key"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>
    <!-- URL参数 -->
    <template v-else-if="configData.sourceType === 'url'">
      <el-form-item class="item" label="参数 Key">
        <el-input v-model="configData.urlParamsKey" placeholder="请输入链接参数key" @input="onEmit"></el-input>
      </el-form-item>
    </template>
    <!-- 页面状态 -->
    <template v-else-if="configData.sourceType === 'pageStatus'">
      <el-form-item label="状态字段">
        <el-select v-model="configData.statusCode" placeholder="请选择状态字段" @change="onEmit">
          <el-option v-for="statusItem in statusConfig"
            :key="statusItem.code"
            :value="statusItem.code"
            :label="statusItem.code + '【' + (statusItem.type === 1 ? '页面' : '全局') + '状态】'"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>
    <!-- 固定值 -->
    <template v-else-if="configData.sourceType === 'fixed'">
      <el-form-item label="参数值">
        <el-input v-model="configData.fixedValue" placeholder="请输入参数值" clearable @input="onEmit"></el-input>
      </el-form-item>
    </template>
    <!-- 组件来源 -->
    <template v-else>
      <el-form-item class="item"
        label="来源组件"
      >
        <el-select
          v-model="configData.componentId"
          placeholder="请选择来源组件"
          @change="onComponent"
        >
          <el-option
            :label="comItem.name"
            :value="comItem.id"
            v-for="comItem in componentList"
            :key="comItem.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="item"
        label="参数字段"
        v-if="configData.key !== 'search'"
      >
        <el-select
          v-model="configData.componentField"
          placeholder="请选择参数字段"
          filterable
          allow-create
          @change="onEmit"
        >
          <el-option
            :label="keyItem.name + '【' + keyItem.uuid + '】'"
            :value="keyItem.uuid"
            v-for="keyItem in fieldList"
            :key="keyItem.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { flatTree, unique } from '@/utils/tools';

export default {
  name: 'SelectBindValue',
  props: {
    value: {
      type: [null, Object],
      required: false,
      default: null
    },
    config: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      configData: {
        componentId: '', // 组件ID
        sourceType: 'component',
        urlParamsKey: '',
        isRequired: false,
        key: '', // 字段名称(发送给后端的字段名)
        componentField: '', // 请求参数字段key
        statusCode: '', // 页面状态字段
        fixedValue: '', // 固定值
        systemKey: '',
        systemCode: ''
      },
      // 系统取值
      systemObject: null,
      // 取值字段
      fieldList: [],
      // 组件列表
      componentList: []
    }
  },
  computed: {
    ...mapState([
			'statusConfig', // 状态配置
			'componentData', // 组件数据
			'curComponent', // 当前组件
			'sContainer', // 配置时候处于编辑大屏内组件状态
			'originComponentData' // 大屏容器组件的元组件
		]),
  },
  created() {
    this.configData = this.value ? {
      ...this.value
    } : {
      ...this.config
    }
    if (this.sContainer) {
			this.componentList = flatTree(this.originComponentData || [], 'children', null, []);
		}
		this.componentList = unique(this.componentList.concat(flatTree(this.componentData, 'children', null, [])));
    if (this.configData?.systemKey) {
      this.onSystemObject();
    }
  },
  methods: {
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('input', this.configData);
      this.$emit('change', this.configData);
    },
    /**
		 * @desc: 选择系统对象
		 */
		onSystemObject() {
			this.systemObject = null;
			if (this.configData.systemKey === 'userInfo') {
				this.systemObject = this.$GetUserInfo()
			} else if (this.configData.systemKey === 'targetArchi') {
				this.systemObject = this.$GetTargetArchi()
			}
      this.onEmit();
		},
    /**
		 * @desc: 选择组件后获取对应的参数字段
		 */
		onComponent() {
      this.configData.componentField = '';
			this.fieldList = [];
			if (this.configData.componentId) {
				const component = this.componentList.find(
					(ele) => ele.id === this.configData.componentId
				);
				// 特殊的参数值 分页器
				if (component && component.component === 'CommonPagination') {
					this.fieldList = [
						{
							uuid: 'page',
							name: '页码'
						},
						{
							uuid: 'size',
							name: '每页条数'
						}
					];
				} else if (['CommonTabs', 'CommonSelect'].includes(component?.component)) {
					this.fieldList = [
						{
							uuid: 'label',
							name: '说明文字'
						},
						{
							uuid: 'value',
							name: '值'
						}
					];
          // 暴露其余字段@蒲亚军
					const { metadata = {} } = component;
					for (let uuid in metadata) {
						this.fieldList.push({
							uuid,
							name: metadata[uuid] 
						});
					}
				} else if (component?.component === 'CommonProjectMap')  {
					// GIS模型
					const { resolveData = {} } = component;
					for (let key in resolveData) {
						this.fieldList.push({
							uuid: key,
							name: key
						});
					}
				} else if (['CommonDateFilter', 'CommonBaseDate', 'CommonCalendar'].includes(component?.component)) {
					// 日期组&时间选择器
					this.fieldList = [
						{
							uuid: 'date',
							name: '时间值'
						}
					];
				} else if (component?.component === 'CommonDateButton') {
          // 日期按钮
          this.fieldList = [
						{
							uuid: 'date',
							name: '时间值'
						},
            {
							uuid: 'weekDay',
							name: '星期几(日期格式)'
						},
            {
							uuid: 'resultDesc',
							name: '描述文本'
						}
					];
        } else {
					this.fieldList = component?.database?.fieldList || [];
				}
			}
      this.onEmit();
		},
  }
}
</script>

<style lang="less" scoped>
.select-bind-value{

}
</style>