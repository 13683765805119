<!--
 * @Description: 文件操作配置
 * @Author: luocheng
 * @Date: 2022-09-22 11:31:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-22 17:33:33
-->
<template>
  <div class="operation-config">
    <header class="header">
      <span class="line"></span>
      <p class="item-title">文件操作</p>
      <el-button type="primary" @click="onConfirm" size="mini">确认操作</el-button>
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
    </header>
    <article class="config">
      <el-form :model="configForm" label-width="90px">
        <el-form-item label="文件夹取值视图">
            <SelectViewUUID v-model="configForm.fileViewUUID" :objectUUID="fileObjectUUID"></SelectViewUUID>
        </el-form-item>
        <el-form-item label="附加分类">
          <el-switch v-model="configForm.withClass"></el-switch>
        </el-form-item>
        <template v-if="configForm.withClass">
          <el-form-item label="分类对象">
            <SelectObjectUUID v-model="configForm.objectUUID"></SelectObjectUUID>
          </el-form-item>
          <el-form-item label="分类视图" v-if="configForm.objectUUID">
            <SelectViewUUID v-model="configForm.viewUUID" :objectUUID="configForm.objectUUID" @input="onGetField"></SelectViewUUID>
          </el-form-item>
          <template v-if="fieldList.length">
            <el-form-item label="ID字段">
              <el-select v-model="configForm.idUUID" placeholder="请选择ID字段" clearable>
                <el-option v-for="item in fieldList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="说明字段">
              <el-select v-model="configForm.labelUUID" placeholder="请选择说明字段" clearable>
                <el-option v-for="item in fieldList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="下级字段">
              <el-select v-model="configForm.childrenUUID" placeholder="请选择下级字段" clearable>
                <el-option v-for="item in fieldList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <el-form-item label="类型字段名">
            <el-input v-model="configForm.typeKey" placeholder="请输入类型字段名" clearable></el-input>
          </el-form-item>
        </template>
      </el-form>
    </article>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import { getFields } from '@/apis/data';
import { mapState } from 'vuex';

export default {
  name: 'OperationConfig',
  components: {
    SelectObjectUUID,
    SelectViewUUID
  },
  data() {
    return {
      configForm: {},
      toggleShow: true,
      // 字段列表
      fieldList: []
    }
  },
  created() {
    this.configForm = this.curComponent?.operationConfig || {
      fileViewUUID: '',
      widthClass: false,
      objectUUID: '',
      viewUUID: '',
      idUUID: '',
      labelUUID: '',
      childrenUUID: '',
      typeKey: ''
    };
    if (this.configForm.viewUUID && this.configForm.objectUUID) {
      this.onGetField();
    }
  },
  computed: {
    ...mapState(['curComponent']),
    fileObjectUUID() {
      return this.curComponent?.database?.objectData?.uuid;
    }
  },
  methods: {
    /**
     * @desc: 确认配置
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					operationConfig: this.configForm
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 获取字段
     */
    onGetField() {
      getFields({
        object_uuid: this.configForm.objectUUID,
        view_uuid: this.configForm.viewUUID,
        size: 1000,
        page: 1
      }).then(res => {
        this.fieldList = res?.data?.data?.data || [];
      }).catch(err => {
        console.log(err);
        this.$message.error('获取字段失败！');
      });
    },
  }
}
</script>

<style lang="less" scoped>
.operation-config {
  .header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
    .item-title{
      flex: 1;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
    }
    .header-btn{
      margin-left: 5px;
      color: @theme;
      line-height: 28px;
    }
  }
  .config {
    width: 100%;
    .el-form {
      .el-form-item {
        margin-bottom: 12px;
      }
    }
  }
}
</style>