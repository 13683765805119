<!--
 * @Description: 配置页配置
 * @Author: luocheng
 * @Date: 2021-10-15 15:12:22
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 15:54:54
-->
<template>
  <div class="form-container-config" v-if="curComponent">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <article class="contents">
      <!-- 表单内容配置 -->
      <ContentConfig :type="'commonFormContainer'"></ContentConfig>
      <!-- 来源&&参数 -->
      <!-- <RelationSource></RelationSource> -->
      <!-- 操作配置 -->
      <ActionConfig></ActionConfig>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentConfig from '@/components/dialogConfig/ContentConfig';
// import RelationSource from '@/components/dialogConfig/RelationSource';
import ActionConfig from './ActionConfig';

export default {
  name: 'FormContainerConfig',
  components: {
    ContentConfig,
    ActionConfig,
    // RelationSource
  },
  computed: {
    ...mapState(['curComponent'])
  }
}
</script>

<style lang="less" scoped>
.form-container-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .title{
    text-align: left;
  }
  .contents{
    box-sizing: border-box;
    padding-right: 5px;
    width: 100%;
    overflow-y: auto;
  }
}
</style>