<!--
 * @Description: 组件属性
 * @Author: luocheng
 * @Date: 2021-08-24 16:53:52
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-17 11:31:52
-->
<template>
	<div class="component-config">
		<header class="actions">
			<el-button type="warning" size="mini" @click="onSyncConfig">同步组件最新属性</el-button>
		</header>
		<el-form :inline="true" label-width="120px">
			<section v-for="key in configKeys" :key="key">
				<template v-if="commonConfig[key]">
					<!-- 表格操作项 -->
					<section class="table-config" v-if="commonConfig[key].type === 'tableAction'">
						<TableAction v-model="curComponent.statusConfig[key]"></TableAction>
					</section>
					<!-- 其他简单配置项 -->
					<el-form-item v-else :label="commonConfig[key].label" label-width="90px">
						<div class="form-item-container">
							<div class="inner-main">
								<el-switch v-model="curComponent.statusConfig[key]" v-if="commonConfig[key].type === 'switch'"></el-switch>
								<el-input
									type="textarea"
									:rows="3"
									v-model="curComponent.statusConfig[key]"
									v-else-if="commonConfig[key].type === 'string'"
									:placeholder="'请输入' + commonConfig[key].label"
								></el-input>
									<el-input
									type="number"
									v-model="curComponent.statusConfig[key]"
									v-else-if="commonConfig[key].type === 'number'"
									:placeholder="'请输入' + commonConfig[key].label"
								></el-input>
								<!-- 选项 -->
								<el-select
									v-model="curComponent.statusConfig[key]"
									:placeholder="'请选择' + commonConfig[key].label"
									v-else-if="commonConfig[key].type === 'select' || commonConfig[key].type === 'multiple'"
									:multiple="commonConfig[key].type === 'multiple'"
								>
									<template v-if="editorType === 'mobile' && key === 'type' && curComponent.component === 'CommonTabs'">
										<el-option
											label="基本类型"
											value="line"
										>
										</el-option>
										<el-option
											label="卡片类型"
											value="card"
										>
										</el-option>
									</template>
									<template v-else>
										<el-option
											v-for="item in commonConfig[key].options"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</template>
									
								</el-select>
								<!-- 图标 -->
								<section class="select-icon" v-else-if="commonConfig[key].type === 'icon'">
									<div class="preview" v-if="curComponent.statusConfig[key]">
										<i :class="curComponent.statusConfig[key]"></i>
										<span>{{ curComponent.statusConfig[key] }}</span>
									</div>
									<el-button type="primary" @click="showIconDrawer = true">选择图标</el-button>
									<el-drawer title="选择图标" :visible.sync="showIconDrawer" :direction="'ltr'">
										<SelectIcon v-model="curComponent.statusConfig[key]" @closeIcon="showIconDrawer = false"></SelectIcon>
									</el-drawer>
								</section>
								<!-- 选择数据对象 -->
								<section v-else-if="commonConfig[key].type === 'selectObjectUUID'">
								<SelectObjectUUID v-model="curComponent.statusConfig[key]"></SelectObjectUUID>
								</section>
								<!-- 选择颜色 -->
								<el-color-picker
									v-else-if="commonConfig[key].type === 'color'"
									v-model="curComponent.statusConfig[key]"
									show-alpha
									:predefine="predefineColors"
								>
								</el-color-picker>
							</div>
							<div class="tips">
								<el-popover
									v-if="commonConfig[key].tips"
									placement="top-start"
									width="200"
									trigger="hover"
									:content="commonConfig[key].tips">
									<i class="header-icon el-icon-question" slot="reference"></i>
								</el-popover>
							</div>
						</div>
					</el-form-item>
				</template>
			</section>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import config from './config';
import SelectIcon from './SelectIcon';
import TableAction from './complex/TableAction';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import ComponentList from '@/custom-component/component-list';
import { ColorPicker, Popover } from 'element-ui';

export default {
	name: 'ComponentConfig',
	components: {
		SelectIcon,
		TableAction,
		SelectObjectUUID,
		'el-color-picker': ColorPicker,
		'el-popover': Popover
	},
	data() {
		return {
			showAttrs: false,
			// 选择图标
			showIconDrawer: false,
			predefineColors: [
				'#ff4500',
				'#ff8c00',
				'#ffd700',
				'#90ee90',
				'#00ced1',
				'#1e90ff',
				'#c71585',
				'#000000',
				'#ffffff'
			]
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前组件
			'editorType'
		]),
		// 配置
		configKeys() {
			return this.curComponent.statusConfig ? Object.keys(this.curComponent.statusConfig) : [];
		},
		// 配置
		commonConfig() {
			if (!this.curComponent) return config.commonConfig;
			return {
				...config[this.curComponent.type],
				// 特殊的表格容器（等待优化统一，屎山1）
				...config[this.curComponent.component],
				...config.commonConfig
			};
		}
	},
	mounted() {
		console.log(this.commonConfig,'commonConfig')
		console.log(this.curComponent, 'curComponent')
		console.log(config, 'config')
	},
	methods: {
		/**
		 * @desc: 同步最新配置(用于对已配置项目的配置追加)/新增行为的配置
		 */
		onSyncConfig() {
			this.$confirm('是否确认同步最新配置？', '提示').then(() => {
				let newConfig = {
					...this.curComponent.statusConfig
				};
				let newbehaviors = {
					...this.curComponent.behaviors
				};
				let statusConfig = null;
				for (let i = 0; i < ComponentList.length; i++) {
					const group = ComponentList[i].componentList || [];
					let target = group.find(ele => ele.component === this.curComponent.component)
					if (this.curComponent.component === 'CommonDrawer') {
						target = group.find(ele => ele.component === 'CommonDialog');
					}
					if (target) {
						statusConfig = target.statusConfig || {};
						newbehaviors = target.behaviors ;
						break;
					}
				}
				if (!statusConfig) {
					return false;
				}
				for (let key in statusConfig) {
					/* eslint-disable */ 
					if (!this.configKeys.includes(key)) {
						newConfig = {
							...newConfig,
							[key]: this.commonConfig[key]?.type === 'switch' ? false : '',
						}
					}
				}
				let baseInfo = {
					...this.curComponent,
						statusConfig: newConfig,
				}
				if (newbehaviors?.length) {
					baseInfo = Object.assign(baseInfo, newbehaviors)
				}
				this.$store.commit('modifyComponent', {
					component:baseInfo,
					containerId: null,
					isModify: true
				});
			}).catch((err) => {
				console.log(err, '--_tongbu')
				this.$message.error('同步出错!');
			});
		}
	}
};
</script>

<style lang="less" scoped>
.component-config {
	width: 100%;
	// height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.actions{
		text-align: left;
	}
	:deep(.el-form)  {
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		overflow: hidden;
		overflow-y: auto;
		.el-form-item {
			display: flex;
			margin: 0;
			margin-bottom: 10px;
			padding-left: 10px;
			box-sizing: border-box;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				background-color: #f5f7fa;
				.el-form-item__label {
					color: @theme;
				}
			}
			.el-form-item__label {
				width: 100px;
				text-align: left;
				cursor: pointer;
			}
			.el-form-item__content {
				flex: 1;
				text-align: left;
				.el-input {
					.el-input__inner {
						text-align: left;
					}
				}
			}
			.value-box {
				width: 100%;
				display: flex;
				.el-input {
					margin-left: 10px;
				}
			}
			.select-icon {
				.preview {
					display: flex;
					box-sizing: border-box;
					padding-bottom: 10px;
					i {
						font-size: 30px;
					}
					span {
						line-height: 20px;
					}
				}
			}
		}
		.table-config {
			width: 100%;
			box-sizing: border-box;
			padding: 5px 0 5px 10px;
			border-top: 1px solid #f2f3f5;
			.title {
				text-align: left;
				vertical-align: middle;
				font-size: 14px;
				color: #606266;
				line-height: 40px;
				padding: 0 12px 0 0;
				box-sizing: border-box;
			}
		}
	}
	.form-item-container{
		display: flex;
		flex-wrap: nowrap;
		.inner-main{
			flex-grow: 1;
		}
		.tips{
			width: 16px;
			flex-shrink: 0;
			margin-left: 5px;
			.header-icon{
				color: @warnColor;
				font-size: 16px;
			}
		}
	}
}
</style>
