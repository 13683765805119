<!--
 * @Description: 组件盒子
 * @Author: luocheng
 * @Date: 2021-08-10 17:12:53
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-02 14:55:02
-->
<template>
	<div class="shape" :class="{ active: isActive }" @click="onClickCom" @mousedown="onMouseDown"
		:data-id="element.id"
	>
		<span class="iconfont rotate iconxuanzhuan" v-show="isActive && !element.isLock" @mousedown="onRotate"></span>
		<span class="iconfont iconsuoding" v-show="isActive && element.isLock"></span>
		<div
			class="shape-point"
			v-for="(item, index) in (isActive && !element.isLock ? pointList : [])"
			:key="index"
			@mousedown="onMouseDownPoint(item, $event)"
			:style="getPointStyle(item)"
		></div>
		<slot></slot>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import calculateComponentPositionAndSize from '@/utils/calculateComponentPositionAndSize';
import { mod360 } from '@/utils/translate';

export default {
	name: 'Shape',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 组件默认样式
		defaultStyle: {
			type: Object,
			default: () => {},
			required: true
		},
		// 组件在图层列表中下标
		index: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			// 操作点 八个方向
			pointList: ['lt', 't', 'rt', 'r', 'rb', 'b', 'lb', 'l'],
			initialAngle: {
				// 每个点对应的初始角度
				lt: 0,
				t: 45,
				rt: 90,
				r: 135,
				rb: 180,
				b: 225,
				lb: 270,
				l: 315
			},
			angleToCursor: [
				// 每个范围的角度对应的光标
				{ start: 338, end: 23, cursor: 'nw' },
				{ start: 23, end: 68, cursor: 'n' },
				{ start: 68, end: 113, cursor: 'ne' },
				{ start: 113, end: 158, cursor: 'e' },
				{ start: 158, end: 203, cursor: 'se' },
				{ start: 203, end: 248, cursor: 's' },
				{ start: 248, end: 293, cursor: 'sw' },
				{ start: 293, end: 338, cursor: 'w' }
			],
			// 光标方向
			cursors: {}
		};
	},
	computed: {
		...mapState([
			'canvasStyle', // 画布样式
			'curComponent', // 当前视图聚焦组件
			'editor' // 编辑器对象
		]),
		// 是否为当前操作图层
		isActive() {
			return this.curComponent && this.curComponent.id === this.element.id;
		}
	},
	methods: {
		/**
		 * @desc: 设置当前组件并绑定拖动事件
		 */
		onMouseDown(e) {
			this.$store.commit('setClickComponentStatus', true);
			e.preventDefault();
			e.stopPropagation();
			this.$store.commit('setCurComponent', {
				component: this.element,
				index: this.index
			});
			// 锁定
			if (this.element.isLock) return;
			// 光标
			this.cursors = this.getCursor() // 根据旋转角度获取光标位置
			// 样式
			const pos = {
				...this.defaultStyle,
				height: isNaN(+this.defaultStyle.height) ? 100 : +this.defaultStyle.height
			};
			const startX = e.clientX;
			const startY = e.clientY;
			const startTop = Number(pos.top);
			const startLeft = Number(pos.left);
			const height = pos.height;
			const width = pos.width;
			// 如果元素没有移动，则不保存快照
			/* eslint-disable */
			let hasMove = false;
			// 移动
			const move = (moveEvent) => {
				this.$store.commit('setMoveStatus', true);
				hasMove = true;
				const curX = moveEvent.clientX;
				const curY = moveEvent.clientY;
				// 处理边界问题
				let top = curY - startY + startTop;
				let left = curX - startX + startLeft;
				if (top <= 0) {
					top = 0;
				} else if (top + height >= this.canvasStyle.height) {
					top = this.canvasStyle.height - height;
				}
				if (left <= 0) {
					left = 0;
				}
				if (left + width >= this.canvasStyle.width) {
					left = this.canvasStyle.width - width;
				}
				pos.top = top;
				pos.left = left;
				// 修改当前组件样式
				this.$store.commit('setShapeStyle', pos);
				// 等更新完当前组件的样式并绘制到屏幕后再判断是否需要吸附
				// 如果不使用 $nextTick，吸附后将无法移动
				this.$nextTick(() => {
					// 触发元素移动事件，用于显示标线、吸附功能
					// 后面两个参数代表鼠标移动方向
					// curY - startY > 0 true 表示向下移动 false 表示向上移动
					// curX - startX > 0 true 表示向右移动 false 表示向左移动
					eventBus.$emit('move', curY - startY > 0, curX - startX > 0);
				});
			};
			// 抬起停止
			const up = () => {
				// 保存快照 未实现
				hasMove && this.$store.commit('recordSnapshot');
				// 触发元素停止移动事件，用于隐藏标线
				eventBus.$emit('unmove');
				document.removeEventListener('mousemove', move);
				document.removeEventListener('mouseup', up);
				this.$store.commit('setMoveStatus', false);
			};
			document.addEventListener('mousemove', move);
			document.addEventListener('mouseup', up);
		},
		/**
		 * @desc: 点击组件
		 */
		onClickCom(e) {
			e.stopPropagation();
			e.preventDefault();
			this.$store.commit('hideEditorMenu')
		},
		/**
		 * @desc: 获取点样式
		 * @param {String} point 点
		 * @return {Object}
		 */
		getPointStyle(point) {
			const { width, height } = this.defaultStyle;
			const hasT = /t/.test(point);
			const hasB = /b/.test(point);
			const hasL = /l/.test(point);
			const hasR = /r/.test(point);
			let newLeft = 0;
			let newTop = 0;
			// 四个角的点
			if (point.length === 2) {
				newLeft = hasL ? 0 : width;
				newTop = hasT ? 0 : height;
			} else {
				// 上下两点的点，宽度居中
				if (hasT || hasB) {
					newLeft = width / 2;
					newTop = hasT ? 0 : height;
				}
				// 左右两边的点，高度居中
				if (hasL || hasR) {
					newLeft = hasL ? 0 : width;
					newTop = Math.floor(height / 2);
				}
			}
			const style = {
				marginLeft: '-4px',
				marginTop: '-4px',
				left: `${newLeft}px`,
				top: `${newTop}px`,
				cursor: this.cursors?.[point]
			};
			return style;
		},
		/**
		 * @desc: 点击框外点
		 */
		onMouseDownPoint(point, e) {
			this.$store.commit('setClickComponentStatus', true);
			e.stopPropagation();
			e.preventDefault();

			const style = { ...this.defaultStyle };
			// 组件宽高比
			const proportion = style.width / style.height;

			// 组件中心点
			const center = {
				x: style.left + style.width / 2,
				y: style.top + style.height / 2
			};

			// 获取画布位移信息
			const editorRectInfo = this.editor.getBoundingClientRect();

			// 获取 point 与实际拖动基准点的差值 @justJokee
			// fix https://github.com/woai3c/visual-drag-demo/issues/26#issue-937686285
			const pointRect = e.target.getBoundingClientRect();
			// 当前点击圆点相对于画布的中心坐标
			const curPoint = {
				x: Math.round(pointRect.left - editorRectInfo.left + e.target.offsetWidth / 2),
				y: Math.round(pointRect.top - editorRectInfo.top + e.target.offsetHeight / 2)
			};

			// 获取对称点的坐标
			const symmetricPoint = {
				x: center.x - (curPoint.x - center.x),
				y: center.y - (curPoint.y - center.y)
			};

			// 是否需要保存快照
			let needSave = false;
			let isFirst = true;

			const needLockProportion = this.isNeedLockProportion();
			const move = (moveEvent) => {
				this.$store.commit('setMoveStatus', true);
				// 第一次点击时也会触发 move，所以会有“刚点击组件但未移动，组件的大小却改变了”的情况发生
				// 因此第一次点击时不触发 move 事件
				if (isFirst) {
					isFirst = false;
					return;
				}

				needSave = true;
				const curPosition = {
					x: moveEvent.clientX - editorRectInfo.left,
					y: moveEvent.clientY - editorRectInfo.top
				};

				calculateComponentPositionAndSize(point, style, curPosition, proportion, needLockProportion, {
					center,
					curPoint,
					symmetricPoint
				});
				this.$store.commit('setShapeStyle', style);
				eventBus.$emit('EDITOR_resizeComponent', e);
			};

			const up = () => {
				document.removeEventListener('mousemove', move);
				document.removeEventListener('mouseup', up);
				needSave && this.$store.commit('recordSnapshot');
				this.$store.commit('setMoveStatus', false);
			};

			document.addEventListener('mousemove', move);
			document.addEventListener('mouseup', up);
		},
		/**
		 * @desc: 是否需要锁定比例
		 */
		isNeedLockProportion() {
			if (this.element.component != 'Group') return false;
			const ratates = [0, 90, 180, 360];
			for (const component of this.element.propValue) {
				if (!ratates.includes(mod360(parseInt(component.style.rotate)))) {
					return true;
				}
			}

			return false;
		},
		/**
		 * @desc: 旋转TODO.存在问题
		 */
		onRotate(e) {
			this.$store.commit('setClickComponentStatus', true);
			e.preventDefault();
			e.stopPropagation();
			// 初始坐标和初始角度
			const pos = { ...this.defaultStyle };
			const startY = e.clientY;
			const startX = e.clientX;
			const startRotate = pos.rotate;

			// 获取元素中心点位置
			const rect = this.$el.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;

			// 旋转前的角度
			const rotateDegreeBefore = Math.atan2(startY - centerY, startX - centerX) / (Math.PI / 180);

			// 如果元素没有移动，则不保存快照
			let hasMove = false;
			const move = (moveEvent) => {
				hasMove = true;
				const curX = moveEvent.clientX;
				const curY = moveEvent.clientY;
				// 旋转后的角度
				const rotateDegreeAfter = Math.atan2(curY - centerY, curX - centerX) / (Math.PI / 180);
				// 获取旋转的角度值
				pos.rotate = startRotate + rotateDegreeAfter - rotateDegreeBefore;
				// 修改当前组件样式
				this.$store.commit('setShapeStyle', pos);
			};

			const up = () => {
				hasMove && this.$store.commit('recordSnapshot');
				document.removeEventListener('mousemove', move);
				document.removeEventListener('mouseup', up);
				this.cursors = this.getCursor(); // 根据旋转角度获取光标位置
				this.$store.commit('setMoveStatus', false);
				eventBus.$emit('syncComponentStyleObj');
			};

			document.addEventListener('mousemove', move);
			document.addEventListener('mouseup', up);
		},
		/**
		 * @desc: 获取光标位置
		 */
		getCursor() {
			const { angleToCursor, initialAngle, pointList, curComponent } = this;
			if (!curComponent) return;
			if (isNaN(curComponent.style.rotate)) return;
			const rotate = mod360(curComponent.style.rotate); // 取余 360
			const result = {};
			let lastMatchIndex = -1; // 从上一个命中的角度的索引开始匹配下一个，降低时间复杂度
			pointList.forEach((point) => {
				const angle = mod360(initialAngle[point] + rotate);
				const len = angleToCursor.length;
				while (true) {
					lastMatchIndex = (lastMatchIndex + 1) % len;
					const angleLimit = angleToCursor[lastMatchIndex];
					if (angle < 23 || angle >= 338) {
						result[point] = 'nw-resize';
						return;
					}
					if (angleLimit.start <= angle && angle < angleLimit.end) {
						result[point] = angleLimit.cursor + '-resize';
						return;
					}
				}
			});
			return result;
		}
	},
	mounted() {
		// 根据旋转角度获取光标位置
		if (this.curComponent) {
			this.cursors = this.getCursor(); 
		}
	},
	beforeDestroy() {
		eventBus.$off('syncComponentStyleObj');
	}
};
</script>

<style lang="less" scoped>
.shape {
	position: absolute;
	&:hover {
		cursor: move;
	}
	&.active {
		outline: 1px solid #70c0ff;
		user-select: none;
	}
	.shape-point {
		position: absolute;
		background: #fff;
		border: 1px solid #59c7f9;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		z-index: 1;
	}
	.rotate {
		position: absolute;
		top: -30px;
		left: 50%;
		// top: -15px;
		// right: -25px;
		transform: translateX(-50%) rotate(-45deg);
		font-size: 16px;
		font-weight: 600;
		cursor: grab;
		color: #59c7f9;
		font-size: 20px;
		font-weight: 600;
		&:active {
			cursor: grabbing;
		}
	}
	.iconsuoding, .iconweisuoding {
		position: absolute;
		top: 2px;
		right: 2px;
		color:#fff ;
		z-index: 1000;
	}
}
</style>
