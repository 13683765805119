<!--
 * @Description: 选择数据对象的UUID 通过远程搜索select
 * @Author: luocheng
 * @Date: 2021-09-29 18:44:14
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-09 13:55:52
-->
<template>
  <div class="select-obect-uuid">
    <el-select v-model="uuid"
      filterable
      remote
      :placeholder="placeholder"
      :remote-method="onRemoteObject"
      :loading="objLoading"
      @change="onEmit"
      clearable
    >
      <el-option v-for="obj in objectList" :key="obj.id" :label="obj.name" :value="obj.uuid"></el-option>
    </el-select>
  </div>
</template>

<script>
import { getObjects } from '@/apis/data/index';

export default {
  name: 'SelectObjectUUID',
  props: {
    value: {
      type: String,
      default: ''
    },
    // 说明
    placeholder: {
      type: String,
      required: false,
      default: '请选择'
    }
  },
  data() {
    return {
      uuid: '',
      objLoading: false,
      objectList: []
    }
  },
  created() {
    this.uuid = this.value;
    this.onRemoteObject('');
  },
  methods: {
    /**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.objLoading = true;
			getObjects({ name, uuid: name ? '' : this.uuid })
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
					this.objLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.objectList = [];
					this.objLoading = false;
				});
		},
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('input', this.uuid);
      this.$emit('update', this.uuid);
    }
  }
}
</script>

<style>

</style>