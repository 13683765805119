<!--
 * @Description: 图层列表
 * @Author: luocheng
 * @Date: 2021-08-02 11:18:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-14 11:14:17
-->
<template>
	<div class="layer-list">
			<!-- 大屏编辑情 -->
		<section class="screen-header" v-if="sContainer">
			<i class="iconfont iconxiangzuojiantou" @click="onQuiet"></i>
			<p class="title">{{ sContainer.name }}</p>
		</section>
		<article class="layer-main">
			<template v-if="layerData && layerData.length">
				<LayerTree :layerData="layerData.filter(v => !v.id.includes('-view'))"></LayerTree>
			</template>
			<p class="no-data" v-else>暂无图层</p>
		</article>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import LayerTree from './children/LayerTree';
import { $ } from '@/utils/tools';
import { getComponentRotatedStyle } from '@/utils/style';

export default {
	name: 'LayerList',
	components: {
		LayerTree
	},
	props: {
		// 图层列表数据
		layerData: {
			type: Array,
			requored: true,
			default: () => []
		}
	},
	computed: {
		...mapState(['sContainer', 'componentData',
			'editor', // 编辑器对象
		])
	},
	methods: {
		/**
		 * @desc: 退出大屏容器编辑状态
		 */
		onQuiet() {
			const positionInfo = this.getPosition();
			this.$store.commit('quietScreenContainer', positionInfo);
		},
		/**
		 * @desc: 获取坐标positon
		 */
		getPosition() {
			let top = Infinity, left = Infinity;
			let right = -Infinity, bottom = -Infinity;
			const editorInfo = this.editor.getBoundingClientRect();
			this.componentData.forEach((component) => {
				let style = {};
				if (component.component == 'Group') {
					component.propValue.forEach((item) => {
						const rectInfo = $(`#component${item.id}`).getBoundingClientRect();
						style.left = rectInfo.left - editorInfo.x;
						style.top = rectInfo.top - editorInfo.y;
						style.right = rectInfo.right - editorInfo.x;
						style.bottom = rectInfo.bottom - editorInfo.y;

						if (style.left < left) left = style.left;
						if (style.top < top) top = style.top;
						if (style.right > right) right = style.right;
						if (style.bottom > bottom) bottom = style.bottom;
					});
				} else {
					style = getComponentRotatedStyle(component.style);
				}
				if (style.left < left) left = style.left;
				if (style.top < top) top = style.top;
				if (style.right > right) right = style.right;
				if (style.bottom > bottom) bottom = style.bottom;
			});
			return {
				top,
				left,
				right,
				bottom
			}
		}
	}
};
</script>

<style lang="less" scoped>
.layer-list {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.screen-header{
		display: flex;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		.iconfont{
			font-size: 20px;
			font-weight: bold;
			padding: 0 5px;
			line-height: 30px;
			cursor: pointer;
			color: #67C23A;
		}
		.title{
			flex: 1;
			box-sizing: border-box;
			line-height: 30px;
			font-size: 16px;
			font-weight: bold;
			text-align: left;
			overflow: hidden;
			color: #67C23A;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.layer-main{
		flex: 1;
		overflow: hidden;
		overflow-y: auto;
		position: relative;
	}
	.no-data {
		color: @minorColor;
	}
}
</style>
