<!--
 * @Description: 单个内容配置
 * @Author: luocheng
 * @Date: 2022-07-19 14:13:17
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-19 15:47:18
-->
<template>
  <div class="item" v-if="formData" >
    <header class="item-header">
      <p>【{{ index + 1 }}】
        <span class="status" :class="{
          'is-complete': status
        }">{{ status ? '完成' : '未完成' }}</span>
      </p>
      <a href="javascript:;" @click="onToggle">{{ isToggle ? '收起' : '展开' }}</a>
    </header>
    <div class="item-content" v-show="isToggle">
      <el-form :model="formData" :inline="true">
        <el-form-item label="图片链接">
          <el-input v-model="formData.img" placeholder="请输入图片链接" clearable @input="onEmit"></el-input>
        </el-form-item>
        <el-form-item label="描述文本">
          <el-input v-model="formData.text" placeholder="请输入描述文本" clearable @input="onEmit"></el-input>
        </el-form-item>
        <el-form-item label="跳转链接">
          <el-input v-model="formData.href" placeholder="请输入跳转链接" clearable @input="onEmit"></el-input>
        </el-form-item>
        <el-form-item label="跳转类型">
          <el-select v-model="formData.hrefType" placeholder="请选择跳转类型" @change="onEmit">
            <el-option label="三方链接" value="link"></el-option>
            <el-option label="项目路由" value="route"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="action">
        <i class="el-icon-delete" @click="onRemove"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Item',
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      formData: null,
      isToggle: false
    }
  },
  created() {
    this.formData = {
      ...this.config
    };
  },
  computed: {
    // 配置状态
    status() {
      if (this.formData && this.formData.img) return true;
      return false;
    }
  },
  methods: {
    /**
     * @desc: 切换展开收起
     */
    onToggle() {
      this.isToggle = !this.isToggle;
    },
    /**
     * @desc: 更新配置
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.formData
      });
    },
    /**
     * @desc: 移除配置
     */
    onRemove() {
      this.$confirm('是否确认移除？').then(() => {
        this.$emit('remove', {
          index: this.index
        });
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.item{
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f3f5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 5px;
  &:hover{
    border-color: @theme;
  }
  .item-header{
    display: flex;
    padding: 5px 0;
    p{
      flex: 1;
      box-sizing: border-box;
      padding-right: 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      .status{
        padding-left: 15px;
        font-size: 16px;
        font-weight: bold;
        color: @dangerColor;
        &.is-complete{
          color: @successColor;
        }
      }
    }
    a{
      padding: 0 5px;
      color: @theme;
      line-height: 16px;
    }
  }
  .item-content{
    border-top: 1px solid #f2f3f5;
    flex: 1;
    display: flex;
    :deep(.el-form) { 
      flex: 1;
      box-sizing: border-box;
      padding-right: 5px;
      .el-form-item{
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        .el-form-item__content{
          display: block;
          width: 100%;
          .el-input, .el-select{
            width: 100%;
          }
        }
      }
    }
    .action{
      padding-left: 10px;
      i{
        line-height: 296px;
        font-size: 18px;
        font-weight: bold;
        color: @dangerColor;
      }
    }
  }
}
</style>