<!--
 * @Description: 依赖逻辑
 * @Author: luocheng
 * @Date: 2022-01-18 14:24:29
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-17 14:11:52
-->
<template>
	<div class="depend-logic">
		<!-- eslint-disable -->
		<section
			class="logic-item"
			v-for="(item, index) in appendList"
			:key="index"
		>
			<div class="logic-main">
				<el-form-item label="字段">
					<el-select
						v-model="item.field"
						placeholder="请选择判断字段"
						clearable
						size="mini"
					>
						<el-option
							v-for="fieldItem in fieldList"
							:key="fieldItem.uuid"
							:label="fieldItem.label + '【' + fieldItem.uuid + '】'"
							:value="fieldItem.uuid"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="字段属性">
					<el-select
						v-model="item.fieldAttr"
						placeholder="请选择字段属性"
						clearable
						size="mini"
					>
						<el-option
							label="值"
							value="value"
						></el-option>
						<el-option
							label="数量"
							value="length"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="逻辑关系">
					<el-select
						v-model="item.fieldLogic"
						placeholder="请选择逻辑关系"
						clearable
						size="mini"
					>
						<el-option
							v-for="logic in logicList"
							:key="logic.value"
							:label="logic.label"
							:value="logic.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="值类型">
					<el-select
						v-model="item.valueType"
						placeholder="请选择比较值类型"
						clearable
						size="mini"
					>
						<el-option
							label="数字"
							value="number"
						></el-option>
						<el-option
							label="字符串"
							value="string"
						></el-option>
						<el-option
							label="时间"
							value="date"
						></el-option>
					</el-select>
				</el-form-item>
				<!--比较值 -->
				<el-form-item label="值">
					<el-input-number
						v-model="item.value"
					size="mini"
						v-if="item.valueType === 'number'"
					></el-input-number>
					<el-input
					size="mini"
						v-model="item.value"
						v-else-if="item.valueType === 'string'"
					></el-input>
					<el-date-picker
					size="mini"
						v-else-if="item.valueType === 'date'"
						v-model="item.value"
						type="datetime"
						value-format="timestamp"
						placeholder="选择日期时间"
						clearable
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="附加逻辑">
					<el-select v-model="item.itemLogic" placeholder="请选择附加条件逻辑" clearable size="mini">
						<el-option label="且" value="and"></el-option>
						<el-option label="或" value="or"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<i class="el-icon-delete" @click="onRemove(index)"></i>
		</section>
		<section class="action">
			<el-button
				type="success"
				size="mini"
				@click="onPlus"
			>新增</el-button>
			<el-button type="primary" size="mini" @click="onSubmit">确定</el-button>
		</section>
	</div>
</template>

<script>
import { DatePicker, InputNumber } from 'element-ui';
import { mapState } from 'vuex';

export default {
	name: 'DependLogic',
	components: {
		'el-input-number': InputNumber,
		'el-date-picker': DatePicker
	},
	props: {
		value: {
			type: Array,
			required: true,
			default: () => []
		},
    // 配置表类型
    tableType: {
      type: String,
      default: '',
      required: false
    }
	},
	data() {
		return {
			appendList: [],
			// 逻辑列表
			logicList: [
				{
					label: '等于',
					value: '==='
				},
				{
					label: '不等于',
					value: '!='
				},
				{
					label: '大于',
					value: '>'
				},
				{
					label: '小于',
					value: '<'
				},
				{
					label: '大于等于',
					value: '>='
				},
				{
					label: '小于等于',
					value: '<='
				}
			]
		};
	},
	computed: {
		...mapState(['curComponent']),
		fieldList() {
			if (this.tableType === 'nesting') {
				return this.curComponent.nestingFieldConfig || [];
			}
			return this.curComponent.fieldConfig || [];
		}
	},
	created() {
		this.appendList = JSON.parse(JSON.stringify(this.value));
	},
	methods: {
  /**
   * @desc: 保存
   */
		onSubmit() {
			for (let i = 0; i < this.appendList.length; i++) {
				const item = this.appendList[i];
				const { field, fieldAttr, fieldLogic, itemLogic, valueType } = item;
				if (!field || !fieldAttr || !fieldLogic || !valueType) {
					this.$message.error('请正确配置逻辑关系!');
					return false;
				}
				if (i < this.appendList.length - 1 && !itemLogic) {
					this.$message.error('请正确配置逻辑关系!');
					return false;
				}
			}
			this.$emit('input', this.appendList);
		},
  /**
   * @desc: 移除 
   * @param {Number} index 下标
   */
		onRemove(index) {
			this.$confirm('是否确认删除？').then(() => {
				this.appendList.splice(index, 1);
			}).catch(() => {})
		},
		/**
		 * @desc: 新增
		 */
		onPlus() {
			this.appendList.push({
				field: '', // 字段
				fieldAttr: 'value', // 值 value 长度、数量 length
				fieldLogic: '===', // 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
				value: '', // 逻辑值
				valueType: 'string', // 值类型 number 数字  string 字符串  date 时间
				itemLogic: 'and' // 下级关系 且 and  或 or
			});
		}
	}
};
</script>

<style lang="less" scoped>
.depend-logic {
	border: 1px solid #f2f3f5;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 4px;
	width: 100%;
	margin-bottom: 10px;
	:deep(.el-form-item .el-form-item__label) {
		width: auto !important;
	}
	.logic-item{
		box-sizing: border-box;
		padding-top: 5px;
		display: flex;
		border-bottom: 1px solid #f2f3f5;
		.el-icon-delete{
			padding-left: 5px;
			font-size: 14px;
			font-weight: bold;
			line-height: 100px;
			color: @dangerColor;
		}
	}
}
</style>