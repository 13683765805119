<!--
 * @Description: 动态配置
 * @Author: luocheng
 * @Date: 2021-11-17 15:32:26
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 15:01:01
-->
<template>
  <div class="dynamic-config" v-if="dynamicConfig">
    <el-form :model="dynamicConfig" ref="dynamicForm">
      <el-form-item label="对象">
        <SelectObject v-model="dynamicConfig.objectUUID"></SelectObject>
      </el-form-item>
      <el-form-item label="视图">
        <SelectView v-model="dynamicConfig.viewUUID" :objectUUID="dynamicConfig.objectUUID"></SelectView>
      </el-form-item>
      <template v-if="fieldList && fieldList.length">
        <el-form-item label="描述字段">
          <el-select v-model="dynamicConfig.labelKey" placeholder="请选择" clearable>
            <el-option v-for="item in fieldList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="值字段">
          <el-select v-model="dynamicConfig.valueKey" placeholder="请选择" clearable>
            <el-option v-for="item in fieldList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item class="action-item">
        <el-button type="primary" @click="onConfirm">确认配置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SelectObject from '@/components/commonComponents/SelectObject';
import SelectView from '@/components/commonComponents/SelectView';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';

export default {
  name: 'DynamicConfig',
  components: {
    SelectObject,
    SelectView
  },
  data() {
    return {
      dynamicConfig: null,
      // 字段列表
      fieldList: [],
      metadata: []
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.dynamicConfig = this.curComponent.dynamicConfig || {
      objectUUID: '',
      viewUUID: '',
      labelKey: '',
      valueKey: ''
    };
    this.fieldList = this.curComponent.dynamicConfig?.fieldList || [];
  },
  watch: {
    dynamicConfig: {
      handler() {
        if (!this.dynamicConfig) return;
        const { objectUUID, viewUUID } = this.dynamicConfig;
        if (!objectUUID || !viewUUID) {
          return;
        }
        this.getFieldList({ objectUUID, viewUUID });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 获取字段列表
     */
    getFieldList(config) {
      dataInterface({
					__method_name__: 'dataList',
					object_uuid: config.objectUUID,
					view_uuid: config.viewUUID,
					search: []
      }).then(res => {
        if (!res || res.status !== 200 || !res.data || !res.data.metadata) return;
        this.fieldList = [];
        const { metadata } = res.data;
        this.metadata = metadata;
        for (let key in metadata) {
          this.fieldList.push({
            label: metadata[key],
            value: key
          });
        }
        this.fieldList.splice(0, 1, {
          ...this.fieldList[0],
          isFirst: true
        })
        this.onConfirm();
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 确认
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					dynamicConfig: {
            ...this.dynamicConfig,
            fieldList: this.fieldList
          },
          metadata: this.metadata
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.dynamic-config{
  width: 100%;
  :deep(.el-form) {
    .action-item{
      .el-form-item__content{
        display: flex;
        .el-button {
          flex: 1;
        }
      }
    }
  }
}
</style>