<!--
 * @Description: 操作配置<被公用>
 * @Author: luocheng
 * @Date: 2021-09-29 18:03:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-24 14:20:27
-->
<template>
	<div class="action-config">
		<header class="header">
			<span class="line"></span>
			<p class="item-title">操作配置</p>
			<el-button type="primary" @click="onSubmit">提交</el-button>
			<el-button type="success" @click="onPlus">新增按钮</el-button>
			<a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
				{{ toggleShow ? '收起' : '展开' }}
			</a>
		</header>
		<template v-if="actionConfig">
			<section class="action-item" v-show="toggleShow" v-for="(item, index) in actionConfig" :key="index">
				<template v-if="item">
					<el-form :model="item" label-width="100px">
						<el-form-item label="按钮key">
							<el-input v-model="item.key" clearable placeholder="请输入按钮key"
								:disabled="['cancel', 'confirm'].includes(item.key)"
							></el-input>
						</el-form-item>
						<el-form-item label="按钮名称">
							<el-input v-model="item.text" clearable placeholder="请输入按钮名称"></el-input>
						</el-form-item>
						<el-form-item label="按钮类型">
							<el-select v-model="item.buttonType" placeholder="请选择按钮类型">
								<el-option
									v-for="item in buttonTypes"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="附加作用">
							<el-switch v-model="item.effect" clearable></el-switch>
						</el-form-item>
						<template v-if="item.effect">
							<el-form-item label="附作用类型">
								<el-select v-model="item.effectType" placeholder="请选择附加作用类型">
									<el-option label="发送请求" value="postData"></el-option>
									<el-option label="绑定事件" value="action"></el-option>
									<el-option label="后端事件" value="eeAction"></el-option>
								</el-select>
							</el-form-item>
							<!-- 发送请求 -->
							<template v-if="item.effectType === 'postData'">
								<el-form-item label="请求类型">
									<el-select v-model="item.postType" placeholder="请选择请求类型">
										<el-option label="新增" value="createData"></el-option>
										<el-option label="编辑" value="updateData"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="绑定对象">
									<SelectObjectUUID v-model="item.objectUUID" :placeholder="'请选择对象'"> </SelectObjectUUID>
								</el-form-item>
								<el-form-item label="绑定视图" v-if="item.objectUUID">
									<SelectViewUUID v-model="item.viewUUID" :objectUUID="item.objectUUID" :placeholder="'请选择视图'">
									</SelectViewUUID>
								</el-form-item>
							</template>
							<!-- 后端事件 -->
							<template v-else-if="item.effectType === 'eeAction'">
								<el-form-item label="绑定对象">
									<SelectObjectUUID v-model="item.objectUUID" :placeholder="'请选择对象'"> </SelectObjectUUID>
								</el-form-item>
								<el-form-item label="绑定视图" v-if="item.objectUUID">
									<SelectViewUUID v-model="item.viewUUID" :objectUUID="item.objectUUID" :placeholder="'请选择视图'">
									</SelectViewUUID>
								</el-form-item>

								<el-form-item label="事件名称" v-if="item.objectUUID">
									<SelectEEEvent
										v-model="item.eventName"
										:objectUUID="item.objectUUID"
										:placeholder="'请选择事件'"
									></SelectEEEvent>
								</el-form-item>
							</template>
						</template>
					</el-form>
					<i class="el-icon-remove remove" @click="onRemove(index)" v-if="item.canCustom"></i>
				</template>
			</section>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import SelectEEEvent from '@/components/common/SelectEEEvent';
import { buttonTypes } from '@/components/attributes/config';

export default {
	name: 'ActionConfig',
	components: {
		SelectObjectUUID,
		SelectViewUUID,
		SelectEEEvent
	},
	data() {
		return {
			toggleShow: false,
			actionConfig: null,
			buttonTypes
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	created() {
		this.actionConfig = this.curComponent.actionConfig || null;
	},
	methods: {
		/**
		 * @desc: 提交数据
		 */
		onSubmit() {
			this.$store.commit('setComponentActions', this.actionConfig);
		},
		/**
		 * @desc: 移除
		 * @param {Number} index
		 */
		onRemove(index) {
			this.actionConfig.splice(index, 1);
		},
		/**
		 * @desc: 新增按钮
		 */
		onPlus() {
			this.actionConfig.push({
				key: 'other',
				effect: false,
				effectType: '',
				canCustom: true,
				postType: '', // 请求类型
				buttonType: 'primary',
				text: '',
				objectUUID: '', // 对象UUID
				viewUUID: '', // 视图UUID
				eventName: '', // 事件名称
				eventList: [],
				permission: [], // 权限
				archiLimit: [], // 架构限制
			});
		}
	}
};
</script>

<style lang="less" scoped>
.action-config {
	.header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		.line {
			width: 3px;
			border-radius: 1px;
			height: 16px;
			margin-top: 9px;
			background: @theme;
		}
		.item-title {
			flex: 1;
			text-align: left;
			padding-left: 10px;
			line-height: 32px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 32px;
			padding-right: 4px;
		}
	}
	.action-item {
		margin-bottom: 10px;
		border-bottom: 1px solid #f2f3f5;
		width: 100%;
		display: flex;
		.remove {
			font-size: 20px;
			line-height: 32px;
			margin-left: 10px;
			color: @dangerColor;
			cursor: pointer;
		}
	}
}
</style>
