<!--
 * @Description: 组件数据仓库配置 
 * @Author: luocheng
 * @Date: 2021-09-07 11:01:19
 * @LastEditors: zx
 * @LastEditTime: 2022-11-03 18:02:39
-->
<template>
	<div class="component-database">
		<el-form :model="configObj" label-width="80px">
			<el-form-item label="数据来源">
				<el-select v-model="configObj.containerKey" placeholder="请选择数据对象" filterable @change="onGetFieldList">
					<el-option v-for="item in containerList" :label="item.name" :value="item.id" :key="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<section class="field-config">
				<header class="header">
					<p class="title">字段配置</p>
					<el-button type="warning" size="mini" @click="onGetFieldList" :disabled="!fieldList.length" v-if="showConfigs"
						>重置</el-button
					>
					<el-button style="margin-left:18px" type="success" size="mini" @click="onConfig">确认配置</el-button>
					<a href="javascript:;" @click="showConfig = !showConfig" v-if="fieldList.length && showConfigs">
						{{ showConfig ? '收起' : '展开' }}
					</a>
				</header>
				<template v-if="showConfigs">
					<div class="config-content" v-show="showConfig">
						<ul v-if="fieldList && fieldList.length">
							<!-- 使用类型 -->
							<section class="database-type" v-if="showConfigs">
								<el-radio-group v-model="databaseType"  size="mini">
									<el-radio-button label="custom">自定义字段</el-radio-button>
									<el-radio-button label="wordbook">使用数据字典</el-radio-button>
									<el-radio-button label="value">使用数据值</el-radio-button>
								</el-radio-group>
							</section>
							<!-- 字段 -->
							<FeildConfigRadio ref="feildConfigRadio" v-if="databaseType==='custom'"></FeildConfigRadio>
							<!-- 数据字典和数据局值 -->
							<VueDraggable style="padding-top:20px;" v-else v-model="fieldList" @end="onDragEnd">
								<li v-for="(field, index) in fieldList" :key="index" @click="onActive(field)">
									<!-- 表格 -->
									<template v-if="curComponent && curComponent.component === 'CommonTable'">
										<header class="field-info">
											<el-checkbox v-model="field.renderConfig.forView">
												{{ field.name }}
											</el-checkbox>
											<el-button type="danger" size="mini" @click="onRemoveField(field, index)">删除</el-button>
										</header>
										<!-- 表格配置项(暂时放在这里) -->
										<template v-if="curComponent && curComponent.component === 'CommonTable'">
											<section class="fied-config" v-show="active === field.uuid">
												<el-form-item label="宽度" label-width="60px">
													<el-input v-model="field.renderConfig.width" placeholder="请输入宽度" type="number" min="0"></el-input>
												</el-form-item>
												<el-form-item label="类型" label-width="60px">
													<el-select v-model="field.renderConfig.type" placeholder="请选择类型">
														<el-option
															v-for="type in typeList"
															:key="type.value"
															:value="type.value"
															:label="type.label"
														>
														</el-option>
													</el-select>
												</el-form-item>
												<!-- 状态配置 -->
											</section>
										</template>
									</template>
									<!-- 详情或普通 -->
									<template v-else>
										<header class="field-info">
											<el-radio v-model="bindUUID" :label="field.uuid">
												{{ field.name }}
											</el-radio>
										</header>
									</template>
								</li>
							</VueDraggable>
						</ul>
						<p v-else>暂无可选字段</p>
					</div>
				</template>
				<TreeLoad v-if="curComponent.component === 'CommonTree'">
					树形图懒加载
				</TreeLoad>
			</section>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import VueDraggable from 'vuedraggable';
import TreeLoad from './special/TreeLoad';
import FeildConfigRadio from '@/components/bindValue/database/FeildConfigRadio.vue'
import { RadioGroup, RadioButton } from "element-ui";

export default {
	name: 'ComponentDatabase',
	components: {
		VueDraggable,
		TreeLoad,
		FeildConfigRadio,
		'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
	},
	data() {
		return {
			// 可选的数据对象列表
			containerList: [],
			// 数据来源容器
			originContainer: null,
			// 配置
			configObj: {
				containerKey: '' // 容器组件ID
			},
			// 字段列表
			fieldList: [],
			// 显示配置
			showConfig: true,
			// 当前编辑字段code
			active: '',
			// 字段类型
			typeList: [
				{
					value: 1,
					label: '普通文本'
				},
				{
					value: 2,
					label: '图片'
				},
				{
					value: 3,
					label: '状态'
				},
				{
					value: 4,
					label: '时间'
				},
				{
					value: 5,
					label: '富文本'
				},
				{
					value: 6,
					label: '超链接'
				}
			],
			// 自定义字段
			customField: '',
			// 普通的数据仓库值绑定 区别于表格
			// 使用的值类型
			databaseType: 'value',
			// 绑定的uuid
			bindUUID: false,
			// 无需进行字段配置的组件列表
			excludesComponents: ['CommonPagination', 'CommonTree', 'CommonSelect']
		};
	},
	computed: {
		...mapState([
			'componentData', // 组件数据
			'curComponent' // 当前组件
		]),
		// 显示配置操作相关
		showConfigs() {
			return this.curComponent && !this.excludesComponents.includes(this.curComponent.component);
		}
	},
	watch: {
		// 当前组件发生变化需要进行已选容器配置
		curComponent: {
			handler(newVal, oldVal) {
				if (newVal && oldVal && newVal.id !== oldVal.id) {
					this.initConfig();
				}
			},
			deep: true
		},
		databaseType:{
			handler(newVal, oldVal) {
				setTimeout(()=>{
					if (newVal === 'custom' && newVal !== oldVal && this.curComponent.database?.customData?.length) {
						console.log(this.curComponent.database?.customData,'this.curComponent.database?.customData')
						this.$refs.feildConfigRadio.replaceList(this.curComponent.database.customData)
					}
				},10)
			},
			deep: true,
			immediate:true
		}
	},
	created() {
		this.initConfig();
	},
	methods: {
		/**
		 * @desc: 初始化配置
		 */
		initConfig() {
			this.resetData();
			this.getContainers(this.componentData, this.curComponent.id, 0, []);
			if (!this.curComponent || !this.curComponent.database || !this.curComponent.database.containerKey) return;
			this.configObj.containerKey = this.curComponent.database.containerKey;
			this.originContainer = this.containerList.find(ele => ele.id === this.configObj.containerKey);
			this.fieldList = this.curComponent.database.fieldList;
			// 普通的
			this.databaseType = this.curComponent.database.databaseType;
			this.bindUUID = this.curComponent.database.bindUUID;
		},
		/**
		 * @desc: 根据组件查找其父级容器路径列表
		 * @param {Array} arr 列表
		 * @param {String} id id
		 */
		getNodePath(arr, id) {
			let got = false; // 是否查找到
			let result = [];
			if (arr.find((ele) => ele.id === id)) {
				return [];
			}
			// 在子节点中获取
			const gotChild = (list, id) => {
				for (let i = 0; i < list.length; i++) {
					const item = list[i];
					if (item.type === 'container' && item.children && item.children.length) {
						result.push(item);
						if (item.children.find((ele) => ele.id === id)) {
							got = true;
							break;
						} else {
							gotChild(item.children, id);
						}
					}
				}
			};
			// 遍历
			for (let i = 0; i < arr.length; i++) {
				const item = arr[i];
				if (item.type === 'container' && item.children && item.children.length) {
					result.push(item);
					if (item.children.find((ele) => ele.id === id)) {
						got = true;
						break;
					} else {
						gotChild(item.children, id);
					}
					if (!got) {
						result = [];
					} else {
						return result;
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 获取容器列表
		 */
		getContainers(arr) {
			if (!arr || !Array.isArray(arr) || !arr.length) {
				return;
			}
			arr.forEach(ele => {
				if (ele.type === 'container') {
					this.containerList.push({
						...ele
					});
				}
				if (ele.children) {
					this.getContainers(ele.children);
				}
			});
		},
		/**
		 * @desc: 选择容器
		 */
		onGetFieldList() {
			//自定义字段重置
			if(this.databaseType==='custom'){
				this.$refs.feildConfigRadio.onResetField()
				return false
			}
			this.originContainer = null;
			this.fieldList = [];
			if (!this.configObj.containerKey || !this.containerList || !this.containerList.length) {
				return;
			}
			const container = this.containerList.find((ele) => ele.id === this.configObj.containerKey);
			if (!container || !container.database || !container.database.fieldList) {
				return;
			}
			const { metadata, containerData } = container;
			// 新的字段配置逻辑
			let dataObj = null;
			const listComponent = ['CommonTable', 'CommonTree'];
			if (this.curComponent && listComponent.includes(this.curComponent.component) && Array.isArray(containerData)) {
				if (containerData.length) {
					dataObj = containerData[0];
				}
			} else {
				// 为兼容详情
				if (Array.isArray(containerData)) {
					dataObj = containerData[0] || metadata;
				} else {
					dataObj = containerData;
				}
			}
			if ((!dataObj || !Object.keys(dataObj).length) && !metadata) return;
			if (dataObj && Object.keys(dataObj).length) {
				for (let key in dataObj) {
					this.fieldList.push({
						name: metadata[key],
						uuid: key,
						renderConfig: {
							forView: true,
							width: '',
							type: 1
							// 其他特殊类型配置
						}
					});
				}
			} else if (metadata) {
				for (let key in metadata) {
					this.fieldList.push({
						name: metadata[key],
						uuid: key,
						renderConfig: {
							forView: true,
							width: '',
							type: 1
							// 其他特殊类型配置
						}
					});
				}
			}
			if (!this.fieldList.length) {
				this.$message.error('无法获取有效配置，可能无metadata或数据格式有误！');
				return;
			}
			this.originContainer = container;
			// 确认配置
			this.onConfig();
		},
		// 拖动排序
		onDragEnd() {
			this.$set(this.$data, 'fieldList', this.fieldList);
		},
		/**
		 * @desc: 点击字段
		 * @param {Object} field 字段对象
		 */
		onActive(field) {
			this.active = field.uuid;
		},
		/**
		 * @desc: 移除字段
		 * @param {Object} field
		 */
		onRemoveField(field, index) {
			this.$confirm(`是否确认删除字段【${field.name}】？`).then(() => {
				this.fieldList.splice(index, 1);
			});
		},
		/**
		 * @desc: 确认字段配置
		 */
		onConfig() {
			if (!this.originContainer) {
				this.$message.error('请选择来源容器!');
				return;
			}
			if (!this.originContainer.database
				|| (this.originContainer.database.requestType !== 'moreAction' && !this.originContainer.database.objectData)
			) {
				this.$message.error('容器暂未配置数据源!');
				return;
			}
			if (!this.curComponent || !this.curComponent.component) return;
			if (this.curComponent.component === 'CommonTable') {
				// 表格
				this.$store.commit('setComponentDatabase', {
					type: 'table',
					fieldList: this.fieldList,
					objectData: this.originContainer.database.objectData,
					viewData: this.originContainer.database.viewData,
					...this.configObj
				});
			} else {
				//自定义字段重置
				if(this.databaseType==='custom'){
					let listData = this.$refs.feildConfigRadio.configList
					if(listData && listData.length){
						listData.forEach((item)=>{
							if(item.show){
								this.$store.commit('setComponentDatabase', {
									type: 'common',
									fieldList: this.fieldList,
									objectData: this.originContainer.database.objectData,
									viewData: this.originContainer.database.viewData,
									customData:listData,
									...this.configObj,
									databaseType: this.databaseType,
									bindUUID: this.bindUUID
								});
							}
						})
					}
					
					return false
				}else{
					this.$store.commit('setComponentDatabase', {
						type: 'common',
						fieldList: this.fieldList,
						objectData: this.originContainer.database.objectData,
						viewData: this.originContainer.database.viewData,
						...this.configObj,
						databaseType: this.databaseType,
						bindUUID: this.bindUUID
					});
				}
				// 其他值
				
			}
		},
		/**
		 * @desc: 重置数据
		 */
		resetData() {
			this.containerList = [];
			this.originContainer = null;
			this.configObj = {
				containerKey: '' // 容器组件ID
			};
			this.fieldList = [];
			this.showConfig = true;
			this.active = '';
		},
	}
};
</script>

<style lang="less" scoped>
.component-database {
	width: 100%;
	.field-config {
		padding-top: 10px;
		.header {
			display: flex;
			.title {
				flex: 1;
				text-align: left;
				box-sizing: border-box;
				padding-left: 13px;
				font-size: 14px;
        color: #606266;
				line-height: 28px;
			}
			a {
				margin-left: 5px;
				line-height: 28px;
				color: @theme;
			}
		}
		.config-content {
			padding-top: 10px;
			ul {
				box-sizing: border-box;
				padding-left: 10px;
				li {
					.field-info {
						display: flex;
						padding-bottom: 10px;
						.el-checkbox {
							flex: 1;
							text-align: left;
						}
					}
					.fied-config {
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	:deep(.el-form-item--small .el-form-item__content,
	.el-form-item--small .el-form-item__label) {
		text-align: left;
	}
	:deep(.el-radio-button--mini .el-radio-button__inner){
		padding:7px 10px!important;
	}
	.custom-field {
		display: flex;
		padding: 10px 0;
		padding-left: 25px;
		border-bottom: 1px solid @borderColor;
		.el-input {
			margin-right: 10px;
		}
	}
	.database-type {
		display: flex;
	}
}
</style>
