<!--
 * @Description: 分部分项模板配置
 * @Author: luocheng
 * @Date: 2022-08-05 15:09:13
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 15:56:20
-->
<template>
	<div class="custom-components-attrs">
		<h3 class="title">组件名称: {{ curComponent.name }}</h3>
		<el-collapse v-model="activeNames" accordion>
      <el-collapse-item title="数据源" name="database">
				<DatabaseConfig></DatabaseConfig>
			</el-collapse-item>
			<el-collapse-item title="参数" name="paramsConfig">
				<ParamsConfig></ParamsConfig>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ParamsConfig from '@/components/common/ParamsConfig';
import DatabaseConfig from './DatabaseConfig';
export default {
	name: 'CustomComponentsAttrs',
	components: {
		ParamsConfig,
    DatabaseConfig
	},
  data() {
    return {
      activeNames: 'database'
    }
  },
	computed: {
		...mapState(['curComponent'])
	}
};
</script>

<style lang="less" scoped>
.custom-components-attrs {
  height: 100%;
  width: 100%;
	.title{
		text-align: left;
    line-height: 20px;
    line-height: 16px;
    padding-bottom: 10px;
    word-break: break-all;
    color: #333;
	}
}
</style>