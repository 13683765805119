/*
 * @Description: 属性备选项
 * @Author: luocheng
 * @Date: 2021-08-20 14:44:15
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-13 16:56:25
 */

export default {
  // overflow属性
  overflow: [
    {
      label: '超出隐藏',
      value: 'hidden'
    },
    {
      label: '超出滚动',
      value: 'scroll'
    },
    {
      label: '自动(超出会滚动)',
      value: 'auto'
    },
    {
      label: '自由显示',
      value: 'visible'
    }
  ],
  // 边框样式
  borderStyle: [
    {
      label: '无',
      value: 'none'
    },
    {
      label: '实线',
      value: 'solid'
    },
    {
      label: '虚线',
      value: 'dashed'
    },
    {
      labek: '双线',
      value: 'double'
    },
    {
      label: '点状(浏览器实际为准)',
      value: 'dotted'
    },
    {
      label: '3D 凹槽边框',
      value: 'groove'
    },
    {
      label: '3D 垄状边框',
      value: 'ridge'
    },
    {
      label: '3D inset 边框',
      value: 'inset'
    },
    {
      label: '3D outset 边框',
      value: 'outset'
    },
    {
      label: '隐藏(浏览器效果为准)',
      value: 'hidden'
    },
  ],
  // 文本对齐
  textAlign: [
    {
      label: '默认',
      value: ''
    },
    {
      label: '居中对齐',
      value: 'center'
    },
    {
      label: '左对齐',
      value: 'left'
    },
    {
      label: '右对齐',
      value: 'right'
    }
  ]
}