<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2022-05-16 11:54:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-04 12:00:28
-->
<template>
  <div class="action-config">
    <header class="header">
      <span class="line"></span>
      <p class="item-title">操作配置</p>
      <!-- <el-button type="success" size="mini" @click="onPlus">新增</el-button> -->
      <el-button type="primary" @click="onConfirm" size="mini">确认操作</el-button>
      <!-- <el-button type="warning" size="mini" @click="onSync">同步配置</el-button> -->
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
    </header>
    <div class="configs" v-show="toggleShow">
      <el-form :model="actionConfig" label-width="80px">
        <el-form-item label="是否需要操作">
          <el-switch v-model="actionConfig.needAction"></el-switch>
        </el-form-item>
        <el-form-item label="架构限制">
          <SetArchiLimit v-model="actionConfig.archiLimit"></SetArchiLimit>
        </el-form-item>
        <el-form-item label="权限">
          <SetPermissions v-model="actionConfig.permission"></SetPermissions>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SetArchiLimit from '@/components/common/SetArchiLimit';
import SetPermissions from '@/components/common/SetPermissions'; 

export default {
  name: 'ActionConfig',
  components: {
    SetArchiLimit,
    SetPermissions
  },
  data() {
    return {
      toggleShow: false,
      actionConfig: {
        needAction: false,
        permission: [], // 权限
				archiLimit: [], // 架构限制
				// add: {
				// 	use: false,
				// 	useDepend: false,
				// 	dependLogic: [],
				// 	type: 'add',
				// 	name: '新增',
				// 	btnType: 'text',
				// 	color: '#67C23A',
				// 	btnSize: '',
				// 	margin: 5,
				// 	eventList: [],
				// 	permission: [], // 权限
				// 	archiLimit: [], // 架构限制
				// }
      }
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    console.log(this.curComponent?.actionConfig, '1111');
    this.actionConfig = this.curComponent?.actionConfig || {
      needAction: false,
      permission: [], // 权限
			archiLimit: [], // 架构限制
    };
  },
  methods: {
    /**
     * @desc: 配置
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					actionConfig: {
            ...this.actionConfig
          }
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 同步配置
     */
    onSync() {}
  }
}
</script>

<style lang="less" scoped>
.action-config{
  padding: 10px 0;
  .header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
    .item-title{
      flex: 1;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
    }
    .header-btn{
      margin-left: 5px;
      color: @theme;
      line-height: 28px;
    }
  }
}
</style>