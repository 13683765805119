<template>
    <div class="viewer-config" v-if="curComponent">
        <h3 class="title">GIS配置</h3>
        <article class="contents">
            <div class="view-config" v-if="curComponent">
                <header class="header">
                    <el-divider>基础设置</el-divider>
                    <el-form  label-width="85px">
                        <el-form-item label="模型源设置">
                            <el-button type="primary" @click="switchSetting('source')" >{{showSetting.source?'关闭':'展开'}}</el-button>
                        </el-form-item>

                        <el-form-item label="源选择" v-show="showSetting.source">
                            <el-select v-model="serverIndex" placeholder="请选择" size="mini" disabled>
                                <el-option v-for="(item, index ) in servers" :key="index"
                                           :label="item.name" :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="应用" v-show="showSetting.source">
                            <el-select v-model="appIndex" placeholder="请选择" size="mini">
                                <el-option v-for="(item, index ) in apps" :key="index"
                                           :label="item.name" :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="动态模型源" v-show="showSetting.source">
                            <el-switch v-model="isDynamicSource"/>
                        </el-form-item>

                        <el-form-item label="模型ID" v-show="!isDynamicSource && showSetting.source">
                            <el-input v-model="modelId" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="模型位置" v-show="!isDynamicSource && showSetting.source">
                            <el-input v-model="modelPosition" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="效果设置">
                            <el-button type="primary" @click="switchSetting('config')" >{{showSetting.config?'关闭':'展开'}}</el-button>
                        </el-form-item>

                        <el-form-item label="是否锁定" v-show="showSetting.config">
                            <el-switch v-model="setting.showMask"  />
                        </el-form-item>

                        <el-form-item  label="默认视角" v-show="showSetting.config">
                            <el-button-group>
                                <el-button type="primary" @click="setDefaultViewState">设置</el-button>
                                <el-button v-show="!!setting.defaultViewState" type="primary"
                                           @click="restoreDefaultViewState">查看</el-button>
                            </el-button-group>
                        </el-form-item>

                        <el-form-item label="影像地图" v-show="showSetting.config">
                            <el-select v-model="setting.imageryIndex" placeholder="请选择" size="mini">
                                <el-option v-for="(item, index) in setting.imageryList"
                                           :key="index"
                                           :label="item.name"
                                           :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="鼠标模式" v-show="showSetting.config">
                            <el-select v-model="setting.mouseControllerIndex" placeholder="请选择" size="mini">
                                <el-option v-for="(item, index) in setting.mouseControllerList"
                                           :key="index"
                                           :label="item.name"
                                           :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="开启地形" v-show="showSetting.config">
                            <el-switch v-model="setting.terrain"/>
                        </el-form-item>

                        <el-form-item label="深度检测" v-show="showSetting.config">
                            <el-switch v-model="setting.depthTest"/>
                        </el-form-item>

                        <el-form-item label="开启阴影" v-show="showSetting.config">
                            <el-switch v-model="setting.shadows"/>
                        </el-form-item>

                        <el-form-item  label="背景颜色" v-show="showSetting.config">
                            <el-color-picker
                                    v-model="setting.backgroundColor"
                            ></el-color-picker>
                        </el-form-item>
                    </el-form>

                    <el-button type="primary" style="width: calc(100% - 20px);" @click="refresh">应用设置</el-button>

                    <el-divider>参数设置</el-divider>

                    <ParamsConfig v-model="paramsConfig"></ParamsConfig>
                </header>
            </div>
        </article>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import { ColorPicker, ButtonGroup, Divider } from "element-ui";
    import ParamsConfig from '@/components/common/ParamsConfig';

    export default {
        name: "GISSourceConfig",
        data (){
            return {
                showSetting : {
                    source : false,
                    config : false,
                },
                isDynamicSource : false,
                serverIndex : 0,
                servers : [{
                    host : 'models.bimcc.net',
                    isHttps : true,
                    name : '模型服务器',
                }],
                appIndex : 0,
                apps : [{
                    appId : 'bdafce12a2d9465d89821ec14214a382',
                    appSecret : "3EYcdAjbnWxP",
                    name : '主应用',
                },{
                    appId : '0e950e86b42642cf9d83e749d91914d7',
                    appSecret : "36vjJoSjSpy4",
                    name : '中设资讯',
                },{
                    appId : 'bf2fc2052816463aa2d86f939ccea565',
                    appSecret : "G2iziGkkY3wr",
                    name : '中原油田',
                },{
                    appId : '0f69f67eb1034cb7ae528caca906ea54',
                    appSecret : "kVTgdk4X2Fmf",
                    name : '濮阳工学院',
                },{
                    appId : '027ba5f47d07413784c35b08724db191',
                    appSecret : "JnmHYpmkKKaO",
                    name : '河北航大兴机场',
                },{
                    appId : '28099463218f4594a2ce95360d1cb636',
                    appSecret : "Cm3OW4uqyZrG",
                    name : '川藏铁路',
                },{
                    appId : '7e7122aef6f140219e0730316228bea1',
                    appSecret : "yiSQWMkSbPLY",
                    name : '成都天府站',
                },{
                    appId : '887248ca4cff47a680d3db50ae2e9511',
                    appSecret : "p3tveDcnrnCZ",
                    name : 'SASS',
                }],
                modelId : '',
                modelPosition: '',
                setting : {
                    showMask : true,
                    defaultViewState : null,
                    imageryList: [
                        {id: 'TianDiTu', name : '天地图影像'},
                        {id: 'AMapSatellite', name : '高德影像'},
                        {id: 'AMapStreets', name : '高德街道'},
                        {id: 'ArcGIS', name : 'ArcGIS暗黑'},
                    ],
                    imageryIndex: 0,
                    mouseControllerList: [
                        {id: 'bim', name : 'BIM操作模式'},
                        {id: 'gis', name : 'GIS操作模式'},
                    ],
                    mouseControllerIndex: 0,
                    terrain: false,
                    depthTest: false,
                    shadows: false,
                    backgroundColor : '#000000',
                },
                paramsConfig : [],
            }
        },
        components: {
            "el-color-picker": ColorPicker,
            "el-button-group" : ButtonGroup,
            "el-divider" : Divider,
            ParamsConfig
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                if(this.curComponent && this.curComponent.GISSourceConfig){
                    const SourceConfig = this.curComponent.GISSourceConfig;
                    const Setting = this.curComponent.GISSetting;

                    if(SourceConfig){
                        this.modelId = SourceConfig.modelId || '';
                        this.modelPosition = SourceConfig.modelPosition || '';
                        this.appIndex = SourceConfig.appIndex || 0;
                        this.serverIndex = SourceConfig.serverIndex || 0;
                        this.isDynamicSource = !!SourceConfig.isDynamicSource;
                    }

                    if(Setting){
                        this.setting.showMask = Setting.showMask;
                        this.setting.defaultViewState = Setting.defaultViewState;
                        this.setting.imageryIndex = Setting.imageryIndex || 0;
                        this.setting.mouseControllerIndex = Setting.mouseControllerIndex || 0;
                        this.setting.terrain = Setting.terrain;
                        this.setting.depthTest = Setting.depthTest;
                        this.setting.shadows = Setting.shadows;
                        this.setting.backgroundColor = Setting.backgroundColor;
                    }
                }else{
                    this.refresh();
                }
            },
            refresh(){
                const server = this.servers[this.serverIndex];
                const app = this.apps[this.appIndex];

                if(this.isDynamicSource){
                    this.$message.success(`动态模型源请通过行为或参数设置初始化模型`);
                }

                this.$store.commit("modifyComponent", {
                    component: {
                        ...this.curComponent,
                        GISSourceConfig : Object.assign({},{
                            host : server.host,
                            port : server.port,
                            isHttps : server.isHttps,
                            appId : app.appId,
                            appSecret : app.appSecret,
                            modelId : this.modelId,
                            modelPosition : this.modelPosition,
                            serverIndex : this.serverIndex,
                            appIndex : this.appIndex,
                            isDynamicSource : this.isDynamicSource,
                        }),
                        GISSetting : Object.assign({}, this.setting),
                    },
                    containerId: null,
                    isModify: true,
                });
            },
            switchSetting(id){
                this.showSetting[id] = !this.showSetting[id];
            },
            setDefaultViewState(){
                eventBus.$emit('ViewerGIS.getCameraView', this.curComponent.id,  ( state )=>{
                    this.setting.defaultViewState = state;
                    this.$message.success(`[${this.curComponent.name}] 已设置当前为默认状态`);
                });
            },
            restoreDefaultViewState(){
                eventBus.$emit('ViewerGIS.setCameraView', this.curComponent.id, this.setting.defaultViewState );
            }
        }
    }
</script>

<style lang="less" scoped>
    .viewer-config{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title{
            text-align: left;
        }
        .contents{
            padding-top: 15px;
            width: 100%;
            overflow-y: auto;
        }
    }
</style>
