<!--
 * @Description: 架构限制
 * @Author: luocheng
 * @Date: 2021-12-10 09:31:44
 * @LastEditors: luocheng
 * @LastEditTime: 2022-02-15 16:45:53
-->
<template>
  <div class="archi-limit">
    <el-select v-model="limits" multiple placeholder="请选择架构限制(默认无)" clearable @change="setLimits">
      <el-option label="集团" value="group"></el-option>
      <el-option label="公司" value="company"></el-option>
      <el-option label="项目" value="project"></el-option>
    </el-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ArchiLimit',
  data() {
    return {
      limits: []
    }
  },
  computed: {
    ...mapState(['curComponent']),
    archiLimit() {
      return this.curComponent && this.curComponent.archiLimit;
    }
  },
  created() {
    this.limits = this.archiLimit || []
  },
  methods: {
    /**
     * @desc: 设置架构限制
     */
    setLimits() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					archiLimit: this.limits
				},
				containerId: null,
				isModify: true
			});
    }
  }
};
</script>

<style lang="less" scoped>
.archi-limit{
  width: 100%;
}
</style>