<!--
 * @Description: 权限配置
 * @Author: luocheng
 * @Date: 2021-10-20 17:17:51
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-03 14:14:58
-->
<template>
	<div class="auth-config">
		<!-- <el-form
			:model="authConfig"
			label-width="80px"
			label-position="left"
		>
			<el-form-item label="权限："> -->
				<SetPermissions v-model="authConfig.list"></SetPermissions>
			<!-- </el-form-item>
		</el-form> -->
	</div>
</template>

<script>
import { mapState } from 'vuex';
import SetPermissions from '@/components/common/SetPermissions';

export default {
	name: 'AuthConfig',
	components: {
		SetPermissions
	},
	data() {
		return {
			authList: [],
			authConfig: {
				list: [], // 需要的权限列表
				privilegers: ['admin'] // 特权人
			}
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	created() {
		this.authConfig = this.curComponent && this.curComponent.authConfig || {
			list: [], // 需要的权限列表
			privilegers: ['admin'] // 特权人
		};
	},
	watch: {
		authConfig: {
			handler() {
				this.onEmit();
			},
			deep: true,
			immediate: false
		}
	},
	methods: {
		/**
		 * @desc: 提交
		 */
		onEmit() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					authConfig: this.authConfig
				},
				containerId: null,
				isModify: true
			});
		}
	}
};
</script>

<style lang="less" scoped>
.auth-config {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0 10px;
}
</style>