<!--
 * @Description: 属性配置
 * @Author: luocheng
 * @Date: 2021-08-13 10:54:08
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-17 14:13:17
-->
<template>
	<div class="attribute">
		<!-- 组件属性 -->
		<div class="attr-box" v-if="curComponent">
      <h3 class="title">组件名称: {{ curComponent.name }}</h3>
			<el-collapse v-model="actives" accordion>
        <el-collapse-item title="样式属性" name="style">
          <GroupAttributes></GroupAttributes>
        </el-collapse-item>
        <el-collapse-item title="" name="specialStyle" v-if="curComponent.type !== 'group'">
					<template slot="title">
						特殊样式
						<el-popover
							placement="top-start"
							width="200"
							trigger="hover"
							content="特殊样式为专业模式，可能引发不可预期问题，请谨慎使用！">
							<i class="header-icon el-icon-question" slot="reference"></i>
						</el-popover>
					</template>
					<SpecialStyle></SpecialStyle>
				</el-collapse-item>
        <el-collapse-item title="组件属性" name="component">
          <ComponentConfig></ComponentConfig>
        </el-collapse-item>
        <el-collapse-item title="权限配置" name="auth">
					<AuthConfig></AuthConfig>
				</el-collapse-item>
				<el-collapse-item title="架构限制" name="archiLimit">
					<ArchiLimit></ArchiLimit>
				</el-collapse-item>
				<el-collapse-item title="渲染逻辑" name="UILogic">
					<UILogic></UILogic>
				</el-collapse-item>
      </el-collapse>
		</div>
		<!-- 画布属性 -->
		<PageAttributes v-else></PageAttributes>
    <!-- <p v-else class="no-current">请选择组件</p> -->
	</div>
</template>

<script>
import { mapState } from 'vuex';
import GroupAttributes from './GroupAttributes';
import PageAttributes from './PageAttributes';
import ComponentConfig from './ComponentConfig';
import SpecialStyle from './SpecialStyle';
import AuthConfig from './AuthConfig';
import { Popover } from 'element-ui';
import ArchiLimit from './ArchiLimit';
import UILogic from './UILogic';

export default {
	name: 'Attribute',
	components: {
		GroupAttributes,
		PageAttributes,
		ComponentConfig,
		SpecialStyle,
		AuthConfig,
		ArchiLimit,
		UILogic,
		'el-popover': Popover
	},
	data() {
		return {
			// 样式
			actives: ''
		}
	},
	computed: {
		...mapState([
			'curComponent' // 当前组件
		])
	}
};
</script>

<style lang="less" scoped>
.attribute {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.title{
    text-align: left;
    line-height: 20px;
    line-height: 16px;
    padding-bottom: 10px;
    word-break: break-all;
    color: #333;
  }
	.attr-box{
		width: calc(100% + 15px);
		height: 100%;
		margin-right: -15px;
		overflow: hidden;
		overflow-y: auto;
	}
	.header-icon{
		color: @warnColor;
		margin-left: 10px;
		font-size: 16px;
	}
  .no-current{
    color: @minorColor;
  }
	:deep(.el-collapse){
    box-sizing: border-box;
    width: 266px;
    .el-collapse-item{
      box-sizing: border-box;
      width: 266px;
    }
    .el-collapse-item__wrap{
      padding-bottom: 10px;
      width: 100%;
      max-height: calc(100% - 26px);
      box-sizing: border-box;
      overflow: hidden;
      .el-collapse-item__content{
        padding-bottom: 10px;
        width: 100%;
        max-height: calc(100% - 26px);
        box-sizing: border-box;
        overflow: hidden;
      }
    }
  }
}
</style>
