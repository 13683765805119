<!--
 * @Description: tabs 配置
 * @Author: luocheng
 * @Date: 2021-10-08 17:26:37
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-22 10:40:30
-->
<template>
  <div class="tabs-config">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="选项配置" name="1">
        <section class="tab-origin">
          <p>类型：</p>
          <el-radio-group v-model="tabOrigin" class="origin-content" @change="onChange">
            <el-radio label="fixed">固定值</el-radio>
            <el-radio label="dictionary">字典</el-radio>
            <el-radio label="dynamic">动态数据</el-radio>
          </el-radio-group>
        </section>
        <!-- 固定参数 -->
        <FixedConfig v-if="tabOrigin === 'fixed'"></FixedConfig>
        <!-- 字典 -->
        <DictionaryConfig v-else-if="tabOrigin === 'dictionary'"></DictionaryConfig>
        <!-- 动态参数 -->
        <DynamicConfig  v-else-if="tabOrigin === 'dynamic'"></DynamicConfig>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FixedConfig from './options/FixedConfig';
import DynamicConfig from './options/DynamicConfig';
import DictionaryConfig from './options/DictionaryConfig';

export default {
  name: 'TabsConfig',
  components: {
    DynamicConfig,
    DictionaryConfig,
    FixedConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  data() {
    return {
      activeNames: '1',
      // 配置类型
      tabOrigin: 'fixed'
    }
  },
  created() {
    this.tabOrigin = this.curComponent.tabOrigin || 'fixed';
  },
  methods: {
    /**
     * @desc: 切换
     */
    onChange() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					tabOrigin: this.tabOrigin
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.tabs-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
		line-height: 20px;
		line-height: 16px;
		padding-bottom: 10px;
		word-break: break-all;
		color: #333;
  }
  .tab-origin{
    display: flex;
    padding-bottom: 10px;
    p{
      width: 60px;
    }
    .origin-content{
      flex: 1;
      text-align: left;
      .el-radio{
        margin-bottom: 10px;
      }
    }
  }
  .el-collapse-item{
    overflow: hidden;
    overflow-y: auto;
  }
}
:deep(.el-collapse) {
  flex: 1;
  overflow: hidden;
  .el-collapse-item__content {
    padding: 0!important;
  }
  .el-collapse-item__wrap{
    border: none;
  }
}
</style>