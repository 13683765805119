<!--
 * @Description: 选择表单
 * @Author: luocheng
 * @Date: 2021-09-29 18:51:47
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-17 14:59:33
-->
<template>
	<div class="select-view-uuid">
		<el-select
			v-model="uuid"
			filterable
			remote
			:placeholder="placeholder"
			:remote-method="onRemoteForm"
			:loading="loading"
			@change="onEmit"
		>
			<el-option
				v-for="formObj in formList"
				:key="formObj.id"
				:label="formObj.name || '默认'"
				:value="formObj.uuid"
			></el-option>
		</el-select>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';

export default {
	name: 'SelectViewUUID',
	props: {
		value: {
			type: String,
			default: ''
		},
		// 说明
		placeholder: {
			type: String,
			required: false,
			default: '请选择'
		},
		// 对象UUID
		objectUUID: {
			type: String,
			required: true,
			default: ''
		}
	},
	data() {
		return {
			uuid: '',
			loading: false,
			formList: [],
			parentUUID: ''
		};
	},
	created() {
		this.uuid = this.value || '';
		this.parentUUID = this.objectUUID || '';
		this.onRemoteForm('');
	},
	watch: {
		objectUUID: {
			handler() {
				if (!this.objectUUID || this.objectUUID !== this.parentUUID) {
					this.uuid = '';
					this.onEmit();
				}
				this.onRemoteForm('');
			},
			deep: true,
			immediate: false
		}
	},
	methods: {
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteForm(name) {
			this.loading = true;
			dataInterface(
				{
					object_uuid: this.parentUUID,
					name
				},
				'api/saas/metadata/getFormDesigns',
				'GET'
			)
				.then((res) => {
					console.log(res.data.data);
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.formList = data || [];
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.formList = [];
					this.loading = false;
				});
		},
		/**
		 * @desc: 提交数据
		 */
		onEmit() {
			this.$emit('input', this.uuid);
		}
	}
};
</script>

<style lang="less" scoped>
.select-view-uuid {
	width: 100%;
}
</style>