<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-18 16:29:49
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 16:32:26
 * @FilePath: /dataview-next/src/components/treeConfig/HighOrderConfig.vue
 * @Description: 图标配置
-->
<template>
  <div class="icon-config">
    <el-button type="primary" size="mini" @click="onConfirm">确认配置</el-button>
    <el-form :model="configForm" label-width="90px">
      <el-form-item label="类型">
        <el-select v-model="configForm.type">
          <el-option label="默认" value=""></el-option>
          <el-option label="固定图标" value="fixed"></el-option>
          <el-option label="自定义配置" value="custom"></el-option>
        </el-select>
      </el-form-item>
      <!-- 固定图标 -->
      <FixedIcon v-if="configForm.type === 'fixed'"
        v-model="configForm.fixedConfig"
      ></FixedIcon>
      <!-- 自定义配置 -->
      <CustomList v-model="configForm.customConfig" v-else-if="configForm.type === 'custom'"></CustomList>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FixedIcon from './FixedIcon';
import CustomList from './CustomList';

export default {
  name: 'IconConfig',
  components: {
    FixedIcon,
    CustomList
  },
  data() {
    return {
      configForm: {
        type: '', // 类型： '' 默认， fixed  固定， dynamic  动态
        fixedConfig: {
          preIcon: '', // 前置
          actionIcon: '' // 操作
        },
        customConfig: {
          preList: [], // 前置 { logic: {}, icon: '' }
          actionList: [] // 操作配置
        }
      }
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.configForm = this.curComponent?.iconConfig || {
      type: '',
      fixedConfig: {
        preIcon: '',
        actionIcon: ''
      },
      customConfig: {
        preList: [],
        actionList: []
      }
    };
  },
  methods: {
    /**
     * @description: 确认
     */
    onConfirm() {
      this.$store.commit('modifyComponent', {
        component: {
          ...this.curComponent,
          iconConfig: this.configForm
        },
        containerId: null,
        isModify: true
      });
    }
  }
}
</script>

<style lang="less" scoped>
.icon-config {
  width: 100%;
  .el-button {
    margin-bottom: 10px;
    width: 100%;
  }
  :deep(.el-form) {
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>