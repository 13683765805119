<!--
 * @Description: 布局模板列表
 * @Author: luocheng
 * @Date: 2021-08-13 16:05:19
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-13 17:39:22
-->
<template>
	<div class="layout-list">
		<section class="item" v-for="item in list" :key="item.id"
      :class="{'active': item.id === activeId}"
      @click="onSelect(item)"
    >
			<div class="cover">
				<img :src="item.cover" />
			</div>
			<div class="introduce">
				<h4 class="name">{{ item.name }}</h4>
				<p class="desc">{{ item.desc }}</p>
			</div>
      <div class="mask" v-if="item.id === activeId">已选择</div>
		</section>
	</div>
</template>

<script>
import list from './layout-list';

export default {
	name: 'LayoutList',
  props: {
    // 默认选中
    defaultId: {
      type: Number,
      required: false,
      default: -1
    }
  },
	data() {
		return {
      // 模板列表
      list,
      // 当前选择模板
      activeId: ''
		};
	},
  created() {
    this.activeId = this.defaultId;
    console.log(this.activeId, 'activeIdactiveId');
  },
  methods: {
    /**
     * @desc: 选择模板
     * @param {Object} 模板对象
     */
    onSelect(item) {
      this.activeId = item.id;
      this.$emit('getLayout', item);
    }
  }
};
</script>

<style lang="less" scoped>
.layout-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	.item {
    position: relative;
		width: 300px;
		margin-right: 20px;
		margin-bottom: 20px;
		background: #fff;
		box-sizing: border-box;
		border-radius: 6px;
    cursor: pointer;
    border: 1px solid #f2f3f5;
		&:hover {
			box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
		}
    &.active{
      border: 2px solid @theme;
    }
		.cover {
			width: 100%;
			height: 140px;
      box-sizing: border-box;
			border-bottom: 1px solid #f2f3f5;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				display: block;
				width: 100%;
				object-fit: cover;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
			}
		}
		.introduce {
			padding: 10px;
			.name {
				line-height: 24px;
				font-size: 14px;
			}
			.desc {
				line-height: 20px;
				font-size: 14px;
				color: @minorColor;
			}
		}
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, .6);
      line-height: 200px;
      text-align: center;
      color: @theme;
    }
	}
}
</style>
