<template>
    <div class="model-config" v-if="curComponent">
        <h3 class="title">模型配置</h3>
        <article class="contents">
            <div class="view-config" v-if="curComponent">
                <header class="header">

                    <el-divider>基础设置</el-divider>
                    <el-form  label-width="85px">
                        <el-form-item label="模型源设置">
                            <el-button type="primary" @click="switchSetting('source')" >{{showSetting.source?'关闭':'展开'}}</el-button>
                        </el-form-item>

                        <el-form-item label="动态模型源" v-show="showSetting.source">
                            <el-switch v-model="isDynamicSource" />
                        </el-form-item>

                        <el-form-item label="源选择"  v-show="!isDynamicSource && showSetting.source">
                            <el-select
                                    v-model="serverIndex"
                                    placeholder="请选择"
                                    size="mini"
                                    disabled
                            >
                                <el-option v-for="(item, index ) in servers"
                                           :key="index"
                                           :label="item.name"
                                           :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="应用" v-show="!isDynamicSource && showSetting.source">
                            <el-select
                                    v-model="appIndex"
                                    placeholder="请选择"
                                    size="mini"
                            >
                                <el-option v-for="(item, index ) in apps"
                                           :key="index"
                                           :label="item.name"
                                           :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="模型ID" v-show="!isDynamicSource && showSetting.source">
                            <el-input
                                    v-model="modelId"
                                    size="mini"
                            ></el-input>
                        </el-form-item>


                        <el-form-item label="效果设置">
                            <el-button type="primary" @click="switchSetting('config')" >{{showSetting.config?'关闭':'展开'}}</el-button>
                        </el-form-item>

                        <!--通用操作-->
                        <el-form-item v-show="showSetting.config" label="不可操作">
                            <el-switch v-model="setting.showMask"  />
                        </el-form-item>

                        <el-form-item v-show="showSetting.config" label="背景颜色">
                            <el-color-picker
                                    v-model="setting.backgroundColor"
                            ></el-color-picker>
                        </el-form-item>

                        <el-form-item v-show="showSetting.config" label="环境阴影">
                            <el-switch v-model="setting.antialiasing"  />
                        </el-form-item>

                        <el-form-item v-show="showSetting.config" label="地面阴影">
                            <el-switch v-model="setting.groundShadow"  />
                        </el-form-item>

                        <el-form-item v-show="showSetting.config" label="质量优先">
                            <el-slider class="fps-silder" size="small" v-model="setting.fps" />
                        </el-form-item>

                        <el-form-item v-show="showSetting.config" label="模型展开">
                            <el-slider class="fps-silder" size="small" v-model="setting.explode" />
                        </el-form-item>

                        <el-form-item v-show="showSetting.config" label="默认状态">
                            <el-button-group>
                                <el-button type="primary" @click="setDefaultViewState">设置</el-button>
                                <el-button v-show="!!setting.defaultViewState" type="primary" @click="restoreDefaultViewState">查看</el-button>
                            </el-button-group>
                        </el-form-item>

                    </el-form>
                    <el-button class="config-button" type="primary" @click="refresh">应用设置</el-button>

                    <el-divider>参数设置</el-divider>
                    <ParamsConfig v-model="paramsConfig"></ParamsConfig>

                    <el-divider >模型列表</el-divider>
                    <el-form  label-width="85px" >
                        <el-form-item label="模型ID">
                            <el-select v-model="modelListField.modelId" placeholder="请选择模型ID">
                                <el-option
                                        v-for="(item, key) in modelListField.metadata"
                                        :key="item + key"
                                        :label="item + '【' + key + '】'"
                                        :value="key"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="模型名称">
                            <el-select v-model="modelListField.modelName" placeholder="请选择模型名称">
                                <el-option
                                        v-for="(item, key) in modelListField.metadata"
                                        :key="item + key"
                                        :label="item + '【' + key + '】'"
                                        :value="key"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button class="config-button" type="primary" @click="saveModelField" >应用设置</el-button>

                    <el-divider >联动绑定</el-divider>
                    <el-form  label-width="125px" >
                        <el-form-item label="绑定">
                            <el-select  v-model="bindViewerId" placeholder="请选择viewer">
                                <el-option
                                        v-for="item in bindViewerList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        
                        <el-form-item label="单向控制操作" v-show="bindViewerId ">
                            <el-switch v-model="bindInfo.bindCamera" />
                        </el-form-item>
                        
                        <el-form-item label="作为控制端" v-show="bindInfo.bindCamera && bindViewerId">
                            <el-switch v-model="bindInfo.isBindCameraMain" @change="changeBindCameraMain"/>
                        </el-form-item>

                        <el-form-item label="绑定选中" v-show="bindViewerId && !bindInfo.bindCamera ">
                            <el-switch v-model="bindInfo.bindSelect" />
                        </el-form-item>

                    </el-form>
                    <el-button class="config-button" type="primary" @click="saveBind" >应用设置</el-button>
                </header>
            </div>
        </article>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import eventBus from '@/plugins/eventBus';
    import { ColorPicker , ButtonGroup , Slider , Divider} from "element-ui";
    import ParamsConfig from '@/components/common/ParamsConfig';
    export default {
        name: "ModelSourceConfig",
        data (){
            return {
                modelId : 'fae9fe29b0fa477587b34812f1522b5e',
                isDynamicSource : false,
                serverIndex : 0,
                servers : [{
                    host : 'models.bimcc.net',
                    isHttps : true,
                    name : '模型服务器',
                }],
                appIndex : 0,
                apps : [{
                    appId : 'bdafce12a2d9465d89821ec14214a382',
                    appSecret : "3EYcdAjbnWxP",
                    name : '主应用',
                },{
                    appId : '0e950e86b42642cf9d83e749d91914d7',
                    appSecret : "36vjJoSjSpy4",
                    name : '中设资讯',
                },{
                    appId : 'bf2fc2052816463aa2d86f939ccea565',
                    appSecret : "G2iziGkkY3wr",
                    name : '中原油田',
                },{
                    appId : '0f69f67eb1034cb7ae528caca906ea54',
                    appSecret : "kVTgdk4X2Fmf",
                    name : '濮阳工学院',
                },{
                    appId : '027ba5f47d07413784c35b08724db191',
                    appSecret : "JnmHYpmkKKaO",
                    name : '河北航大兴机场',
                },{
                    appId : '28099463218f4594a2ce95360d1cb636',
                    appSecret : "Cm3OW4uqyZrG",
                    name : '川藏铁路',
                },{
                    appId : '7e7122aef6f140219e0730316228bea1',
                    appSecret : "yiSQWMkSbPLY",
                    name : '成都天府站',
                }],
                setting : {
                    showMask : true,
                    backgroundColor : '#409eff',
                    defaultViewState : null,
                    fps : 70,
                    explode : 0 ,
                    antialiasing : true,
                    groundShadow : true,
                },
                showSetting : {
                    source : false,
                    config : false,
                },
                paramsConfig : [],
                modelListField : {
                    metadata : {},
                    modelId : "",
                    modelName : "",
                },
                bindViewerId : "",
                bindViewerList : [],
                bindInfo : {
                    bindCamera : false,
                    bindCameraMainId : "",
                    isBindCameraMain : false,
                    bindSelect : true,
                }
            }
        },
        components: {
            "el-color-picker": ColorPicker,
            "el-button-group" : ButtonGroup,
            "el-slider" : Slider,
            "el-divider" : Divider,
            ParamsConfig,
        },
        computed: {
            ...mapState(["curComponent"]),
        },
        methods:{
            init(){
                if(this.curComponent && this.curComponent.modelBIMSourceConfig){
                    const SourceConfig = this.curComponent.modelBIMSourceConfig;
                    const Setting = this.curComponent.modelBIMSetting;

                    this.modelId = SourceConfig.modelId || 'fae9fe29b0fa477587b34812f1522b5e';
                    this.appIndex = SourceConfig.appIndex || 0;
                    this.serverIndex = SourceConfig.serverIndex || 0;
                    this.isDynamicSource = !!SourceConfig.isDynamicSource;

                    this.setting.showMask = Setting.showMask;
                    this.setting.backgroundColor = Setting.backgroundColor;
                    this.setting.defaultViewState = Setting.defaultViewState;
                    this.setting.fps = Setting.fps || 70;
                    this.setting.explode = Setting.explode || 0;

                    if( Setting.antialiasing === undefined){
                        this.setting.antialiasing = true;
                    }else{
                        this.setting.antialiasing = Setting.antialiasing;
                    }

                    if( Setting.groundShadow === undefined){
                        this.setting.groundShadow = true;
                    }else{
                        this.setting.groundShadow = Setting.groundShadow;
                    }

                }else{
                    this.refresh();
                }

                this.refreshModelField();
                this.refreshBindViewerList();
            },
            refresh(){
                const server = this.servers[this.serverIndex];
                const app = this.apps[this.appIndex];

                this.$store.commit("modifyComponent", {
                    component: {
                        ...this.curComponent,
                        modelBIMSourceConfig : Object.assign({},{
                            host : server.host,
                            port : server.port,
                            isHttps : server.isHttps,
                            appId : app.appId,
                            appSecret : app.appSecret,
                            modelId : this.modelId,
                            serverIndex : this.serverIndex,
                            appIndex : this.appIndex,
                            isDynamicSource : this.isDynamicSource,
                        }),
                        modelBIMSetting : Object.assign({}, this.setting ),
                    },
                    containerId: null,
                    isModify: true,
                });

                if(this.isDynamicSource){
                    this.$message.success(`动态模型源请通过行为初始化模型！`);
                }

            },
            //设置默认视点
            setDefaultViewState(){
                eventBus.$emit('ViewerBIM.getState' , this.curComponent.id ,  ( state )=>{
                    this.setting.defaultViewState = state;
                    this.$message.success(`[${this.curComponent.name}] 已设置当前为默认状态`);
                });
            },
            //重置默认视点
            restoreDefaultViewState(){
                eventBus.$emit('ViewerBIM.restoreState' , this.curComponent.id , this.setting.defaultViewState );
            },
            switchSetting(id){
                this.showSetting[id] = !this.showSetting[id];
            },
            refreshModelField(){
                if(this.curComponent.modelBIMField && this.curComponent.modelBIMField["ModelList"]){
                    this.modelListField.metadata = Object.assign({},this.curComponent.modelBIMField["ModelList"].metadata);
                    this.modelListField.modelId = this.curComponent.modelBIMField["ModelList"].modelId;
                    this.modelListField.modelName = this.curComponent.modelBIMField["ModelList"].modelName;
                }
            },
            saveModelField(){
                eventBus.$emit('ViewerBIM.changeModelListField' , this.curComponent.id , {
                    modelId : this.modelListField.modelId,
                    modelName : this.modelListField.modelName,
                })
            },
            refreshBindViewerList(){
                eventBus.$emit("ViewerBIM.getBindViewerInfo" , this.curComponent.id , ({list , bindId , info})=>{
                    this.bindViewerId = bindId ? bindId : "";
                    this.bindViewerList = list;

                    if(!info){
                        this.bindInfo.bindCamera = false;
                    }else{
                        this.bindInfo.bindCamera = info.bindCamera;
                        this.bindInfo.bindCameraMainId = info.bindCameraMainId;
                        this.bindInfo.isBindCameraMain = info.bindCameraMainId === this.curComponent.id;
                        this.bindInfo.bindSelect = info.bindSelect;
                    }
                });
            },
            saveBind(){
                eventBus.$emit("ViewerBIM.bindingViewer" , this.curComponent.id , this.bindViewerId , {
                    bindCamera : this.bindInfo.bindCamera,
                    bindCameraMainId : this.bindInfo.bindCameraMainId,
                    bindSelect : this.bindInfo.bindSelect,
                });
                eventBus.$emit("ViewerBIM.bindingViewer" , this.bindViewerId , this.curComponent.id , {
                    bindCamera : this.bindInfo.bindCamera,
                    bindCameraMainId : this.bindInfo.bindCameraMainId,
                    bindSelect : this.bindInfo.bindSelect,
                });

                this.$message.success(`[${this.curComponent.name}] 绑定成功！`);
            },
            changeBindCameraMain(){
                const mainId =  this.bindInfo.isBindCameraMain ? this.curComponent.id : this.bindViewerId;

                this.bindInfo.bindCameraMainId = mainId;
            },  
        },
        mounted() {
            this.init();

            eventBus.$on("ViewerBIM.refreshModelListField" , ()=>{
                this.refreshModelField();
            });
        }
    }
</script>

<style lang="less" scoped>
    .model-config{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title{
            text-align: left;
        }
        .contents{
            width: 100%;
						padding-top: 15px;
            overflow-y: auto;
        }
    }

    .config-button{
        width: calc(100% - 20px);
    }
</style>