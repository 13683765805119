import http from "../global/http";

const page = {
  pageInfo: (params = {}) => {
    return http({
      url: '/api/metadata/search/pageInfo',
      method: 'GET',
      params,
    });
  }
}

export default page;