<!--
 * @Description: 前置操作
 * @Author: luocheng
 * @Date: 2022-01-19 15:52:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 15:01:27
-->
<template>
	<div class="before-handle">
		<header class="logic-header">
			<el-button
				type="success"
				size="mini"
				@click="onPlusLogic"
			>新增逻辑</el-button>
			<!-- <el-button type="primary" size="mini" @click="emitData">确认</el-button> -->
			<el-button
				type="text"
				size="mini"
				@click="onToggle"
			>{{ isShow ? '收起' : '展开'}}</el-button>
		</header>
		<article v-show="isShow">
			<template v-if="logicConfigs && logicConfigs.length">
				<ConfigLogic
					v-for="(item, index) in logicConfigs"
					:key="index"
					:config="item"
					:targetList="targetList"
					:index="index"
					@updateConfig="onUpdateConfig"
					@removeItem="onRemoveItem"
					:type="type"
				></ConfigLogic>
			</template>
			<el-empty v-else description="暂无数据"></el-empty>
		</article>
	</div>
</template>

<script>
import ConfigLogic from './ConfigLogic';
import { flatTree, unique } from '@/utils/tools';
import { mapState } from 'vuex';
import { Empty } from 'element-ui';

export default {
	name: 'BeforeHandle',
	components: {
		'el-empty': Empty,
		ConfigLogic
	},
	props: {
		// 配置 String 类型是为了兼容预留
		config: {
			type: [Array, String],
			default: () => []
		},
		// 下标
		index: {
			type: Number
		},
		eIndex: {
			type: Number
		},
		// 配置类型   inner 组件内部配置（此时首选在组件内部暴露值选取进行操作）
		type: {
			type: String,
			isRequired: false,
			default: ''
		}
	},
	data() {
		return {
			logicConfigs: [],
			// 组件对象列表
			targetList: [],
			isShow: true
		};
	},
	computed: {
		...mapState(['componentData', 'originComponentData', 'sContainer', 'curComponent'])
	},
	created() {
		if (this.config && typeof this.config !== 'string') {
			this.logicConfigs = this.config
				? JSON.parse(JSON.stringify(this.config))
				: [];
		}
		if (this.sContainer) {
			this.targetList = flatTree(this.originComponentData || [], 'children', {
				label: 'name',
				value: 'id'
			}, []);
		}
		this.targetList = unique(this.targetList.concat(flatTree(this.componentData, 'children', {
				label: 'name',
				value: 'id'
			}, [])));
	},
	methods: {
		/**
		 * @desc: 新增逻辑
		 */
		onPlusLogic() {
			this.logicConfigs.push({
				type: this.type === 'inner' ? 'inner' : 'component', // 类型  component 依赖组件
				target: this.type === 'inner' ? this.curComponent?.id : '', // 目标组件
				dataKey: this.type === 'inner' ? 'row' : '', // 取值对象 resolveData 当前暴露数据 componentData 组件绑定数据, row 当前行
				dataField: '', // 取值字段 当使用取值对象时 resolveData
				valueObj: '', // 系统取值对象
				valueKey: '', // 系统取值字段
				dataAttr: 'value', // 取值属性 value 值 length 数据长度
				logicRule: '===', // 逻辑规则 === 等于 != 不等于 > 大于 < 小于 <= 小于等于 >= 大于等于
				valueType: 'string', //  类型  string 字符串 number 数字 date 日期
				value: '', // 比较值
				itemLogic: 'and' // 逻辑组合 and 且 or 或
			});
			this.emitData();
		},
		/**
		 * @desc: 移除
		 */
		onRemoveItem(index) {
			this.logicConfigs.splice(index, 1);
			this.emitData();
		},
		/**
		 * @desc: 更新配置
		 * @param {Object} data
		 */
		onUpdateConfig(data) {
			this.logicConfigs.splice(data.index, 1, {
				...data.config
			});
			this.emitData();
		},
		/**
		 * @desc: 提交数据
		 */
		emitData() {
			this.$emit('updateConfig', {
				index: this.index,
				eIndex: this.eIndex,
				config: this.logicConfigs
			});
		},
		/**
		 * @desc: 切换显示
		 */
		onToggle() {
			this.isShow = !this.isShow;
		}
	}
};
</script>

<style lang="less" scoped>
.before-handle {
	width: 100%;
  // padding-bottom: 10px;
	padding: 2px 0;
	.logic-header {
		display: flex;
		width: 100%;
		.logic {
			flex: 1;
			line-height: 24px;
      text-align: left;
		}
	}
	.handle-item{
		margin-top: 10px;
		background-color: #f5f5f5;
	}
}
</style>