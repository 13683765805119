<!--
 * @Description: 名称
 * @Author: luocheng
 * @Date: 2021-12-03 14:23:32
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-22 10:03:00
-->
<template>
  <header class="part-header">
		<h3>组件名称: {{ curComponent && curComponent.name }}</h3>
  </header>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    name: 'PartHeader',
    computed: {
      ...mapState(['curComponent'])
    }
  }
</script>

<style lang="less" scoped>
.part-header{
  text-align: left;
  line-height: 20px;
  line-height: 16px;
  padding-bottom: 10px;
  word-break: break-all;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>