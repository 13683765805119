<!--
 * @Description: 单元格配置
 * @Author: luocheng
 * @Date: 2021-12-06 14:16:50
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 18:26:38
-->
<template>
  <section class="column-action">
    <header class="header">
      <p>{{ configData.name }}</p>
      <div class="actions">
        <span class="el-icon-delete remove" @click="onRemove"></span>
        <span class="toggle" @click="showToggle = !showToggle">{{ showToggle ? '收起' : '展开' }}</span>
      </div>
    </header>
    <article class="column-content" :class="[showToggle ? 'toggle' : '']">
      <el-form :model="configData" label-width="100px">
        <el-form-item label="名称">
          <el-input v-model="configData.name" placeholder="请输入名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="操作类型">
          <el-select v-model="configData.action" placeholder="请选择操作类型" clearable @change="onCode">
            <el-option
              v-for="action in actionList"
              :key="action.value"
              :label="action.label"
              :value="action.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字体颜色">
          <el-color-picker v-model="configData.color" placeholder="请选择颜色" clearable></el-color-picker>
        </el-form-item>
        <!-- 自定义参数 -->
        <el-form-item label="自定义参数">
          <section
            v-for="(statusItem, index) in configData.payload"
            :key="index"
            class="status-config"
          >
            <div class>
              <el-input v-model="statusItem.code" placeholder="请输入key" size="mini" clearable></el-input>
              <el-input v-model="statusItem.value" placeholder="请输入值" size="mini" clearable></el-input>
            </div>
            <i class="el-icon-delete remove" @click="onRemoveStatus(index)"></i>
          </section>
          <section class="status-action">
            <el-button size="mini" type="text" @click="onPlusStatus">新增状态</el-button>
          </section>
        </el-form-item>
      </el-form>
    </article>
  </section>
</template>

<script>
import { ColorPicker } from 'element-ui';

import { mapState } from 'vuex';
export default {
  name: 'ColumnAction',
  components: {
    'el-color-picker': ColorPicker
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => { }
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      configData: null,
      showToggle: false,
      // 操作列表
      actionList: [
        {
          label: '表单弹窗',
          value: 'showForm'
        },
        {
          label: '删除数据',
          value: 'deleteData'
        },
        {
          label: '详情',
          value: 'showMessage'
        }
      ] 
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.configData = this.data || null;
  },
  methods: {
    /**
     * @desc: 选择或输入code
     */
    onCode() {
      this.configData.name = this.configData.name || this.configData.code || '';
      this.emitData();
    },
    /**
     * @desc: 新增状态
     */
    onPlusStatus() {
      this.configData.payload.push({
        code: '',
        value: ''
      })
    },
    /**
     * @desc: 移除状态
     * @param {Number} index 下标
     */
    onRemoveStatus(index) {
      this.configData.payload.splice(index, 1);
    },
    /**
     * @desc: 删除字段
     */
    onRemove() {
      this.$confirm("是否确定移除字段", '提示').then(res => {
        console.log(res);
        this.$emit('removeConfig', {
          index: this.index
        })
      }).catch(() => { });
    },
    /**
     * @desc: 提交编辑数据
     */
    emitData() {
      console.log('提交编辑数据')
    }
  }
}
</script>

<style lang="less" scoped>
.column-action {
  width: 100%;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
  margin-bottom: 10px;
  .header {
    display: flex;
    height: 28px;
    p {
      flex: 1;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .actions {
      padding-left: 10px;
      span {
        cursor: pointer;
        box-sizing: border-box;
        line-height: 28px;
        padding: 0 5px;
        color: @theme;
        font-size: 16px;
        font-weight: bold;
        &.remove {
          color: @dangerColor;
        }
        &.toggle {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .column-content {
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
    &.toggle {
      height: auto;
    }
  }
  .el-color-picker,
  .el-color-picker--mini {
    float: left;
  }
  .status-config {
    display: flex;
    padding-bottom: 5px;
    .remove {
      color: @dangerColor;
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .status-action {
    text-align: left;
  }
}
</style>