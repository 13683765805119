<!--
 * @Description: 固定配置
 * @Author: luocheng
 * @Date: 2022-07-19 14:05:31
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-19 15:45:18
-->
<template>
  <div class="fixed-config">
    <header class="header">
      <el-button type="success" icon="el-icon-plus" @click="onPlus">新增</el-button>
      <el-button type="primary" @click="onSave">保存</el-button>
    </header>
    <article class="content-box">
      <Item v-for="(item, index) in configData"
        :key="index"
        :index="index"
        :config="item"
        @remove="onRemove"
        @update="onUpdate"
      ></Item>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Item from './Item';

export default {
  name: 'FixedConfig',
  components: {
    Item
  },
  data() {
    return {
      configData: null
    }
  },
  computed: {
    ...mapState(['curComponent']),
    fixedConfig() {
      return this.curComponent?.swiperConfig?.fixedConfig;
    }
  },
  created() {
    this.configData = this.fixedConfig || [];
  },
  methods: {
    onPlus() {
      this.configData.push({
        img: '', // 图片链接
        text: '', // 描述文本
        href: '', // 跳转链接
        hrefType: 'link' // 跳转方式
      });
    },
    /**
     * @desc: 移除
     */
    onRemove(index) {
      this.configData.splice(index, 1);
    },
    /**
     * @desc: 更新配置
     */
    onUpdate({ index, config }) {
      this.configData.splice(index, 1, config);
    },
    /**
     * @desc: 提交配置
     */
    onSave() {
      console.log(this.configData, '----this.configData---')
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					swiperConfig: {
            ...this.curComponent.swiperConfig,
            fixedConfig: this.configData
          }
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.fixed-config{
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    .el-button{
      flex: 1;
    }
  }
  .content-box{
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>