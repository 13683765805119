<!--
 * @Description: 网盘配置项
 * @Author: luocheng
 * @Date: 2022-03-03 18:20:58
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-22 11:31:01
-->
<template>
  <div class="net-disk-config" v-if="curComponent">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <article class="contents">
      <!-- 数据仓库配置 -->
      <!-- <DatabaseConfig></DatabaseConfig> -->
      <!-- 操作配置 -->
      <ActionConfig></ActionConfig>
      <!-- 文件移动/负责配置 -->
      <OperationConfig></OperationConfig>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionConfig from './ActionConfig';
// import DatabaseConfig from './DatabaseConfig';
import OperationConfig from './OperationConfig';

export default {
  name: 'ModelConfig',
  components: {
    ActionConfig,
    // DatabaseConfig
    OperationConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  }
}
</script>

<style lang="less" scoped>
.net-disk-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f3f5;
  }
  .contents{
    width: 100%;
    overflow-y: auto;
  }
}
</style>