var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor screen-container",style:({
		height: _vm.canvasHeight + 'px',
		width: _vm.canvasWidth + 'px',
		backgroundColor: _vm.canvasStyle.backgroundColor
	}),attrs:{"id":"editor"},on:{"contextmenu":_vm.onContextMenu}},[_c('Grid'),_c('div',{staticClass:"editor-content",style:({
			..._vm.pageConfig.style,
			height: _vm.canvasHeight + 'px',
			width: _vm.canvasWidth + 'px',
		}),on:{"drop":_vm.onDrop,"dragover":_vm.onDragOver,"mousedown":_vm.onClearStatus,"mouseup":_vm.clearCurComponent}},[_vm._l((_vm.componentData),function(item,index){return ((item.statusConfig && item.statusConfig.isShow)
				&& _vm.judgingArchiAPermission(item.archiLimit, item.authConfig && item.authConfig.list)
			)?_c('Shape',{directives:[{name:"show",rawName:"v-show",value:(item.statusConfig
				&& _vm.judgingUILogic(item.UILogic, _vm.componentData)
				&& (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)
			),expression:"item.statusConfig\n\t\t\t\t&& judgingUILogic(item.UILogic, componentData)\n\t\t\t\t&& (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)\n\t\t\t"}],key:item.id,style:(_vm.getBoxStyle(item.style)),attrs:{"index":index,"defaultStyle":item.style || {},"element":item},on:{"mousedown":_vm.onMouseDown},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onClickBox(item)}}},[_c(item.component,{tag:"component",style:(_vm.getComStyle(item.style)),attrs:{"index":index,"element":item,"id":'component' + item.id,"propValue":item.propValue,"scaleHeight":1,"scaleWidth":1}})],1):_vm._e()}),_c('SelectArea',{directives:[{name:"show",rawName:"v-show",value:(_vm.showArea),expression:"showArea"}],attrs:{"area":_vm.position}}),_c('EditorMenu')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }