<!--
 * @Description: 数据来源配置
 * @Author: luocheng
 * @Date: 2022-04-22 10:34:51
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-15 16:50:46
-->
<template>
  <div class="data-source">
    <article class="source-list">
      <SourceItem v-for="(item, index) in sourceList"
        :key="index"
        :index="index"
        :config="item"
        @remove="onRemove"
        @update="onUpdate"
      >
      </SourceItem>
    </article>
    <footer class="actions">
      <el-button type="success" size="mini" @click="onPlus">新增</el-button>
      <el-button type="primary" size="mini" @click="onSave">保存</el-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SourceItem from './SourceItem';

export default {
  name: 'DataSource',
  components: {
    SourceItem
  },
  data() {
    return {
      sourceList: []
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.sourceList = this.curComponent.dataSource || [];
  },
  methods: {
    /**
     * @desc: 新增
     */
    onPlus() {
      this.sourceList.push({
        code: '', // 字段名
        isRequired: false, // 必须
        source: 'component', // 值来源， component 组件，url url参数，system 系统参数
        component: '', // 关联组件
        componentField: '', // 组件取值字段
        urlKey: '', // url参数字段
        systemObject: '', // 系统取值对象
        systemField: '' // 系统取值字段
      });
    },
    /**
     * @desc:  保存 
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					dataSource: this.sourceList
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 移除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.sourceList.splice(index, 1);
    },
    /**
     * @desc: 更新配置
     * @param {Number} index
     * @param {Object} config
     */
    onUpdate({ index, config }) {
      this.sourceList.splice(index, 1, {
        ...config
      });
    }
  }
}
</script>

<style lang="less" scoped>
.data-source{
  width: 100%;
}
</style>
