<!--
 * @Author: zx
 * @Date: 2022-07-20 11:00:50
 * @LastEditTime: 2022-08-30 18:18:29
 * @Description:
 * @LastEditors: Shiltin 18580045074@163.com
-->
<template>
  <div class="key-sitting">
    <p style="text-align:left;font-weight: bold;">组件key值设置：</p>
		<div class="key-item">
      <div class="key-item-label">
        <span class="label-name">顶部标题:</span>
      </div>
			<el-input
				v-model="typeTreeInfo.title"
			>
			</el-input>
    </div>
    <div class="key-item">
      <div class="key-item-label">
				<span class="label-name">label显示:</span>
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="树状数据名称显示key值！">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-select v-model="typeTreeInfo.labelKey" placeholder="请选择" @change="selectChange">
        <el-option
          v-for="item in curComponent.database.fieldList"
          :key="item.uuid"
          :label="item.name"
          :value="item.uuid">
        </el-option>
      </el-select>
    </div>
    <div class="key-item">
      <div class="key-item-label">
				<span class="label-name">子级显示:</span>
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="树状数据子级key值！">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-select v-model="typeTreeInfo.childrenKey" placeholder="请选择" @change="selectChange">
        <el-option
          v-for="item in curComponent.database.fieldList"
          :key="item.uuid"
          :label="item.name"
          :value="item.uuid">
        </el-option>
      </el-select>
    </div>
    <div class="key-item">
      <div class="key-item-label">
        <span class="label-name">数据分页:</span>
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="视图是否有数据分页，影响到树组件取值">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-switch size="small" v-model="typeTreeInfo.isPage" @change="selectChange"></el-switch>
    </div>
		<!-- <div class="key-item">
      <div class="key-item-label">
        <span class="label-name">选中颜色:</span>
      </div>
			<el-color-picker
				v-model="typeTreeInfo.activeCol"
				show-alpha
			>
			</el-color-picker>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Popover } from 'element-ui';
export default {
  components: {
    'el-popover': Popover,
		// "el-color-picker": ColorPicker,
  },
  props: {},
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
    ])
  },
  data() {
    return {
      typeTreeInfo: {
        checkBoxVisible: false,
        isPage: false,
        type: 1,
				activeCol:'',
				title:'分类选择',
        params: [
          {
            key: '',
            value: ''
          }
        ]
      },
    };
  },
  mounted() {
    if (this.curComponent?.database?.typeTreeInfo && (typeof this.curComponent.database.typeTreeInfo === 'object')
    ) {
      this.typeTreeInfo = this.curComponent.database.typeTreeInfo
    }
    if (!this.typeTreeInfo.params || !Array.isArray(this.typeTreeInfo.params)) {
      this.typeTreeInfo.params = [
        {
          key: '',
          value: ''
        }
      ]
    }
    if (!this.typeTreeInfo.type) {
      this.typeTreeInfo.type = 1
    }
    if (!this.typeTreeInfo.isPage) {
      this.typeTreeInfo.isPage = false
    }
    this.selectChange()
  },
  methods: {
    selectChange() {
      if (!this.typeTreeInfo.checkBoxVisible) {
        this.typeTreeInfo.checkBoxVisible = false
      }
      if (!this.typeTreeInfo.isPage) {
        this.typeTreeInfo.isPage = false
      }
      this.$emit('setting', this.typeTreeInfo)
    },
    onModifyOption(index, type) {
      if (type === 'plus') {
        this.typeTreeInfo.params.splice(index, 0, {key:'',value: ''})
        this.typeTreeInfo = JSON.parse(JSON.stringify(this.typeTreeInfo))
      } else if (type === 'remove') {
        this.typeTreeInfo.params.splice(index, 1)
        this.typeTreeInfo = JSON.parse(JSON.stringify(this.typeTreeInfo))
      }
      this.selectChange()
    },
  },
};
</script>
<style lang='less' scoped>
.key-sitting {
  padding-top: 8px;
  .key-item {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    display: flex;
    margin-top: 10px;
    .key-item-label {
      width: 90px;
			text-align:left;
			.label-name{
				display: inline-block;
				width: 80px;
			}
    }
    .params-container {
      width: 100%;
      height: 100%;
      .params-container-for {
        width: 100%;
        display: flex;
        align-items: center;
        .params-container-left {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            height: 35px;
            margin-bottom: 6px;
          }
        }
        .params-container-right {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
</style>