<!--
 * @Description: 配置状态
 * @Author: luocheng
 * @Date: 2022-02-25 18:13:17
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-17 17:22:51
-->
<template>
  <div class="config-status">
    <div class="status-content">
      <el-input v-model="statusConfig.value" size="mini" placeholder="请输入状态值" @input="onEmit"></el-input>
      <el-input v-model="statusConfig.label" size="mini" placeholder="请输入说明文字" @input="onEmit"></el-input>
      <el-color-picker v-model="statusConfig.color" @change="onEmit"></el-color-picker>
    </div>
    <i class="el-icon-delete-solid icon" @click="onRemoveStatus"></i>
  </div>
</template>

<script>
import { ColorPicker } from 'element-ui';
export default {
  name: 'ConfigStatus',
  components: {
		'el-color-picker': ColorPicker
  },
  props: {
    status: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 外层下标
    index: {
      type: Number
    },
    // 状态下标
    statusIndex: {
      type: Number
    }
  },
  data() {
    return {
      statusConfig: {
        value: '',
        label: '',
        color: ''
      }
    }
  },
  created() {
    this.statusConfig = JSON.parse(JSON.stringify(this.status));
  },
  methods: {
    onEmit() {
      this.$emit('updateData', {
        index: this.index,
        statusIndex: this.statusIndex,
        config: this.statusConfig
      })
    },
    onRemoveStatus() {
      this.$emit('removeStatus', {
        index: this.index,
        statusIndex: this.statusIndex
      })
    }
  }
}
</script>

<style lang="less" scoped>
.config-status{
  display: flex;
	text-align: left;
  .el-icon-delete-solid{
    padding-left: 10px;
    font-size: 14px;
    color: @dangerColor;
    line-height: 80px;
  }
}
</style>