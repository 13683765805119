<!--
 * @Description: 配置参数
 * @Author: luocheng
 * @Date: 2021-09-10 10:52:11
 * @LastEditors: zx
 * @LastEditTime: 2022-12-07 09:05:07
-->
<template>
	<div class="config-params">
		<h4 class="title">配置参数：</h4>
		<article class="config-result">
			<section class="config-item">
				<el-checkbox v-model="userOutParams" @change="onUseOut">注入外部参数</el-checkbox>
				<el-popover
					placement="top-start"
					title="说明"
					width="200"
					trigger="hover"
					content="获取后端视图配置的查询条件，只会保留从参数取值的条件"
				>
					<el-button slot="reference" type="success" size="mini" @click="getViewParams">获取视图条件</el-button>
				</el-popover>
			</section>
			<!-- 模拟数据 -->
			<!-- <MockParams v-if="userOutParams"></MockParams> -->
			<!-- 配置的字段 -->
			<!-- <template v-else> -->
			<section class="config-item" v-for="(configItem, configIndex) in paramsConfig" :key="configIndex">
				<span class="label">字段名称：</span>
				<el-input v-model="configItem.key" disabled></el-input>
			</section>
			<!-- </template> -->
		</article>
		<section class="footer">
			<el-button type="primary" @click="onShowConfig">配置参数</el-button>
		</section>
		<!-- 配置参数 -->
		<el-drawer
			title="配置参数"
			:visible.sync="showConfig"
			:size="'400px'"
			:direction="'ltr'"
			:destroy-on-close="true"
			:wrapperClosable="false"
		>
			<el-form :model="configForm" class="config-list" label-width="120px" ref="configForm" :rules="rules">
				<section class="config-box" v-for="(item, index) in configForm.configList" :key="index">
					<ul class="box-content">
						<el-form-item class="item" label="参数名(参数)：">
							<el-input v-model="item.key" placeholder="请输入参数名"></el-input>
						</el-form-item>
						<el-form-item class="item" label="是否必须：">
							<el-switch v-model="item.isRequired"></el-switch>
						</el-form-item>
						<el-form-item label="参数来源方式：">
							<el-select class="w-full" v-model="item.sourceType" placeholder="请获取参数来源方式">
								<el-option label="内部组件" value=""></el-option>
								<el-option label="URL参数" value="url"></el-option>
								<el-option label="系统参数" value="system"></el-option>
								<el-option label="页面状态" value="pageStatus"></el-option>
								<el-option label="固定值" value="fixed"></el-option>
							</el-select>
						</el-form-item>
						<!-- 系统参数 -->
						<template v-if="item.sourceType === 'system'">
							<el-form-item label="系统对象">
								<el-select
									v-model="item.systemKey"
									placeholder="请选择系统对象"
									clearable
									@change="onSystemObject(index, item.systemKey)"
								>
									<el-option label="用户信息" value="userInfo"></el-option>
									<el-option label="当前架构" value="targetArchi"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="取值Code" v-if="item.systemKey">
								<el-select v-model="item.systemCode" placeholder="请选择取值对象">
									<el-option v-for="(value, key) in item.systemObject" :key="key" :label="key" :value="key"></el-option>
								</el-select>
							</el-form-item>
						</template>
						<!-- URL参数 -->
						<template v-else-if="item.sourceType === 'url'">
							<el-form-item class="item" label="链接参数key">
								<el-input v-model="item.urlParamsKey" placeholder="请输入链接参数key"></el-input>
							</el-form-item>
						</template>
						<!-- 页面状态 -->
						<template v-else-if="item.sourceType === 'pageStatus'">
							<el-form-item label="状态字段">
								<el-select v-model="item.statusCode" placeholder="请选择状态字段">
									<el-option
										v-for="statusItem in statusConfig"
										:key="statusItem.code"
										:value="statusItem.code"
										:label="statusItem.code + '【' + (statusItem.type === 1 ? '页面' : '全局') + '状态】'"
									></el-option>
								</el-select>
							</el-form-item>
						</template>
						<!-- 固定值 -->
						<template v-else-if="item.sourceType === 'fixed'">
							<el-form-item label="参数值">
								<el-input v-model="item.fixedValue" placeholder="请输入参数值" clearable></el-input>
							</el-form-item>
						</template>
						<!-- 组件来源 -->
						<template v-else>
							<el-form-item
								class="item"
								label="来源组件："
								:prop="`configList[${index}].componentId`"
								:rules="rules.componentId"
							>
								<el-select
									v-model="item.componentId"
									placeholder="请选择来源组件"
									@change="onComponent(index, item.componentId)"
								>
									<el-option
										:label="comItem.name"
										:value="comItem.id"
										v-for="comItem in componentList"
										:key="comItem.id"
									></el-option>
								</el-select>
							</el-form-item>
							<!-- item.componentId.indexOf('CommonForm') === -1 ||  -->
							<el-form-item
								class="item"
								label="参数字段："
								:prop="`configList[${index}].paramKey`"
								:rules="rules.paramKey"
								v-if="item.key !== 'search'"
							>
								<el-select
									v-model="item.paramKey"
									placeholder="请选择参数字段"
									:disabled="!item.componentId"
									filterable
									allow-create
								>
									<el-option
										:label="keyItem.name + '【' + keyItem.uuid + '】'"
										:value="keyItem.uuid"
										v-for="keyItem in item.fieldList"
										:key="keyItem.uuid"
									></el-option>
								</el-select>
							</el-form-item>
						</template>
					</ul>
					<i class="iconfont iconwangpan-shanchu1x" @click="onRemove(item, index)"></i>
				</section>
				<el-button type="success" class="plus-btn" @click="onPlus">新增</el-button>
			</el-form>
			<footer class="action">
				<el-button type="primary" @click="onConfirm">确认</el-button>
				<el-button @click="showConfig = false">取消</el-button>
			</footer>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { flatTree, unique } from '@/utils/tools';
import { Checkbox, Popover } from 'element-ui';
// import MockParams from './mockParams/Index';
import { dataInterface } from '@/apis/data';

export default {
	name: 'ConfigParams',
	components: {
		'el-checkbox': Checkbox,
		'el-popover': Popover
		// MockParams
	},
	data() {
		return {
			// 显示配置
			showConfig: false,
			// 配置列表
			configForm: {
				configList: []
			},
			// 可选组件列表
			componentList: [],
			// 验证规则
			rules: {
				componentId: [{ required: true, message: '请选择来源组件', trigger: 'change' }],
				paramKey: [{ required: true, message: '请求参数字段', trigger: 'change' }]
			},
			// 输入外部参数(dialog引入页面使用)
			userOutParams: false
		};
	},
	computed: {
		...mapState([
			'statusConfig', // 状态配置
			'componentData', // 组件数据
			'curComponent', // 当前组件
			'sContainer', // 配置时候处于编辑大屏内组件状态
			'originComponentData' // 大屏容器组件的元组件
		]),
		paramsConfig() {
			if (!this.curComponent || !this.curComponent.database || !this.curComponent.database.paramsConfig) return [];
			return this.curComponent.database.paramsConfig;
		},
		// 对象UUID
		objectUUID() {
			return this.curComponent?.database?.objectData?.uuid;
		},
		// 视图UUID
		viewUUID() {
			return this.curComponent?.database?.viewData?.uuid;
		}
	},
	created() {
		if (this.sContainer) {
			this.componentList = flatTree(this.originComponentData || [], 'children', null, []);
		}
		this.componentList = unique(this.componentList.concat(flatTree(this.componentData, 'children', null, [])));
		this.userOutParams = this.curComponent.database.userOutParams;
		/* if ('ListContainerOne') {
			
		} */
	},
	methods: {
		/**
		 * @desc: 外部参数
		 */
		onUseOut() {
			this.$store.commit('setDatabaseOutParams', this.userOutParams);
		},
		/**
		 * @desc: 显示新增
		 */
		onShowConfig() {
			this.showConfig = true;
			this.configForm.configList = JSON.parse(JSON.stringify(this.curComponent.database.paramsConfig || []));
		},
		/**
		 * @desc: 新增
		 */
		onPlus() {
			this.$refs.configForm.validate((valid) => {
				if (!valid) return;
				this.configForm.configList.push({
					componentId: '', // 组件ID
					sourceType: '',
					urlParamsKey: '',
					isRequired: false,
					key: '', // 字段名称(发送给后端的字段名)
					paramKey: '', // 请求参数字段key
					statusCode: '', // 页面状态字段
					fixedValue: '' // 固定值
				});
			});
		},
		/**
		 * @desc: 移除
		 * @param {Object} item 参数对象
		 * @param {Number} index 需要移除的元素下标
		 */
		onRemove(item, index) {
			this.$confirm(`是否确认删除【${item.key}】参数?`)
				.then(() => {
					this.configForm.configList.splice(index, 1);
				})
				.catch(() => {});
		},
		/**
		 * @desc: 选择组件后获取对应的参数字段
		 * @param {Number} index 当前操作下标
		 * @param {Index} componentId 组件ID
		 */
		onComponent(index, componentId) {
			let fieldList = [];
			if (componentId) {
				const component = this.componentList.find((ele) => ele.id === componentId);
				// 特殊的参数值 分页器
				if (component && component.component === 'CommonPagination') {
					fieldList = [
						{
							uuid: 'page',
							name: '页码'
						},
						{
							uuid: 'size',
							name: '每页条数'
						}
					];
				} else if (['CommonTabs', 'CommonSelect'].includes(component?.component)) {
					fieldList = [
						{
							uuid: 'label',
							name: '说明文字'
						},
						{
							uuid: 'value',
							name: '值'
						}
					];
					// 暴露其余字段@蒲亚军
					const { metadata = {} } = component;
					for (let uuid in metadata) {
						fieldList.push({
							uuid,
							name: metadata[uuid] 
						});
					}
				} else if (component?.component === 'CommonProjectMap') {
					// GIS模型
					const { resolveData = {} } = component;
					fieldList = [];
					for (let key in resolveData) {
						fieldList.push({
							uuid: key,
							name: key
						});
					}
				} else if (['CommonDateFilter', 'CommonBaseDate', 'CommonCalendar'].includes(component?.component)) {
					// 日期组&时间选择器
					fieldList = [
						{
							uuid: 'date',
							name: '时间值'
						}
					];
				} else if (component?.component === 'CommonDateButton') {
					// 日期按钮
					this.fieldList = [
						{
							uuid: 'date',
							name: '时间值'
						},
						{
							uuid: 'weekDay',
							name: '星期几'
						}
					];
				} else if (component?.component === 'Calendar') {
					// 移动端日历组件选值
					fieldList = [
						{ name: '日期（年月日）', uuid: 'date' },
						{ name: '日期（年月日 时分秒）', uuid: 'dateTime' },
						{ name: '数量', uuid: 'num' },
						{ name: '总数量', uuid: 'total_num' },
						{ name: '当天日期范围', uuid: 'dateArr' },
						{ name: '星期', uuid: 'weekDay' }
					];
				} else {
					fieldList = component?.database ? component?.database?.fieldList || [] : [];
				}
			}
			this.configForm.configList.splice(index, 1, {
				...this.configForm.configList[index],
				fieldList
			});
		},
		/**
		 * @desc: 选择系统对象
		 */
		onSystemObject(index, systemKey) {
			let systemObject = {};
			if (systemKey === 'userInfo') {
				systemObject = this.$GetUserInfo();
			} else if (systemKey === 'targetArchi') {
				systemObject = this.$GetTargetArchi();
			}
			this.configForm.configList.splice(index, 1, {
				...this.configForm.configList[index],
				systemCode: '',
				systemObject
			});
		},
		/**
		 * @desc: 确认配置
		 */
		onConfirm() {
			this.$refs.configForm.validate((valid) => {
				if (!valid) return;
				const { configList } = this.configForm;
				const result = configList.map((ele) => {
					return {
						...ele
						// fieldList: []   保存后刷新  重新加载没有取指定组件的fieldList 不能下拉回显 我注释了  不清空此值
					};
				});
				this.$store.commit('setDatabaseParams', result);
				this.showConfig = false;
			});
		},
		/**
		 * @desc: 获取后端配置的视图条件
		 */
		async getViewParams() {
			if (!this.objectUUID) {
				this.$message.warning('请选择数据对象!');
				return false;
			}
			if (!this.viewUUID) {
				this.$message.warning('请选择数据视图!');
				return false;
			}
			this.getViewQueryList();
		},
		/**
		 * @desc: 获取当前视图下查询列表
		 */
		getViewQueryList() {
			this.$loading();
			dataInterface(
				{
					object_uuid: this.objectUUID,
					size: 999,
					page: 1
				},
				'/api/metadata/views',
				'GET'
			)
				.then((res) => {
					console.log(res?.data?.data, '---res?.data?.data-1111111111--');
					const viewList = res?.data?.data?.data || [];
					const viewObj = viewList.find((ele) => ele.uuid === this.viewUUID);
					const filterList = viewObj?.filter?.children || [];
					if (!viewObj || !filterList.length) {
						this.$loading().close();
						this.$message.info('暂无可同步参数！');
						return;
					}
					const { configList = [] } = this.configForm;
					filterList.forEach((ele) => {
						const { rightValue = {} } = ele;
						if (
							rightValue?.class === 'RequestValue' &&
							!this.configForm.configList.find((ele) => ele.key === rightValue?.payload?.key)
						) {
							// 仅仅将请求参数的值获取出来
							configList.push({
								componentId: '', // 组件ID
								sourceType: '',
								urlParamsKey: '',
								isRequired: false,
								key: rightValue?.payload?.key, // 字段名称(发送给后端的字段名)
								paramKey: '', // 请求参数字段key
								statusCode: '', // 页面状态字段
								fixedValue: '' // 固定值
							});
						}
					});
					this.$store.commit('setDatabaseParams', configList);
					this.$loading().close();
					this.$message.success('已同步！');
				})
				.catch((err) => {
					console.log(err, '---err---');
					this.$loading().close();
					this.$message.info('出错了，请手动配置！');
				});
		}
	}
};
</script>

<style lang="less" scoped>
.config-params {
	width: 100%;
	padding-bottom: 10px;
	border-bottom: 1px solid @borderColor;
	.title {
		font-weight: bold;
		text-align: left;
		padding: 5px 0;
	}
	.config-result {
		// padding-bottom: 10px;
		.config-item {
			display: flex;
			padding-bottom: 5px;
			text-align: center;
			margin: 0 auto;
			justify-content: space-between;
			.el-checkbox {
				line-height: 37px;
			}
			span {
				line-height: 32px;
				font-size: 14px;
				&.label {
					width: 80px;
					margin-right: 10px;
					text-align: right;
				}
			}
			.el-input {
				flex: 1;
				text-align: left;
			}
			.get-view-rules{
				color: #409EFF;
				line-height: 37px;
			}
		}
	}
	.footer {
		display: flex;
		.el-button {
			flex: 1;
		}
	}
	:deep(.el-drawer) {
		height: 100%;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.el-drawer__header{
      margin: 0;
      padding-bottom: 20px;
      span{
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }
    }
		.el-drawer__body {
			flex: 1;
			width: 100%;
			display: flex;
			overflow: hidden;
			flex-direction: column;
			.config-list {
				flex: 1;
				width: 100%;
				box-sizing: border-box;
				padding: 0 10px;
				display: flex;
				flex-direction: column;
				// height: calc(97vh - 165px);
				overflow: hidden;
				border-bottom: 1px solid #f2f3f5;
				overflow-y: auto;
				.config-box {
					border: 1px solid @borderColor;
					box-sizing: border-box;
					padding: 10px 5px;
					border-radius: 6px;
					margin-bottom: 10px;
					display: flex;
					cursor: pointer;
					&:hover {
						border: 1px solid @theme;
						background: #fefefe;
					}
					.box-content {
						flex: 1;
						.item {
							margin-bottom: 15px;
							.el-input,
							.el-select {
								width: 220px;
							}
						}
					}
					.iconfont {
						margin-left: 10px;
						color: @dangerColor;
						font-size: 18px;
						line-height: 115px;
					}
				}
			}
			.action {
				width: 100%;
				box-sizing: border-box;
				padding: 20px 10px;
				display: flex;
				.el-button{
					flex: 1;
				}
			}
		}
	}
	.el-icon-question{
		color: @warnColor;
	}
}
</style>
