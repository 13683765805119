<!--
 * @Description: 数仓配置
 * @Author: luocheng
 * @Date: 2022-07-19 15:17:16
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-19 16:07:08
-->
<template>
  <div class="dynamic-config">
    <header class="header">
      <el-button type="primary" size="mini" @click="onSave">保存</el-button>
    </header>
    <el-form :model="configForm" label-width="100px" v-if="configForm">
      <el-form-item label="数据对象">
        <SelectObjectUUID v-model="configForm.objectUUID"></SelectObjectUUID>
      </el-form-item>
      <el-form-item label="数据视图" v-if="configForm.objectUUID">
        <SelectViewUUID v-model="configForm.viewUUID" :objectUUID="configForm.objectUUID" @input="onGetField"></SelectViewUUID>
      </el-form-item>
      <!-- 字段配置 -->
      <template v-if="configForm.objectUUID && fieldList">
        <el-form-item label="图片字段">
          <SelectField v-model="configForm.imgUUID" :options="fieldList"></SelectField>
        </el-form-item>
        <el-form-item label="描述文本字段">
          <SelectField v-model="configForm.textUUID" :options="fieldList"></SelectField>
        </el-form-item>
        <el-form-item label="链接字段">
          <SelectField v-model="configForm.hrefUUID" :options="fieldList"></SelectField>
        </el-form-item>
        <el-form-item label="跳转类型字段">
          <SelectField v-model="configForm.hrefTypeUUID" :options="fieldList"></SelectField>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import SelectField from '@/components/common/SelectField';
import { mapState } from 'vuex';
import { getFields } from '@/apis/data';

export default {
  name: 'DynamicConfig',
  components: {
    SelectObjectUUID,
    SelectViewUUID,
    SelectField
  },
  data() {
    return {
      configForm: {
        objectUUID: '',
				viewUUID: '',
        imgUUID: '', // 图片链接
        textUUID: '', // 描述文本
        hrefUUID: '', // 跳转链接
        hrefTypeUUID: '' // 跳转方式
      },
      fieldList: null
    }
  },
  computed: {
    ...mapState(['curComponent']),
    databaseConfig() {
      return this.curComponent?.swiperConfig?.databaseConfig ?? this.configForm
    }
  },
  created() {
    this.configForm = {
      ...this.databaseConfig
    };
    if (this.configForm.objectUUID && this.configForm.viewUUID) {
      this.onGetField();
    }
  },
  methods: {
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					swiperConfig: {
            ...this.curComponent.swiperConfig,
            databaseConfig: this.configForm
          }
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 获取可用字段
     */
    onGetField() {
      getFields({
        object_uuid: this.configForm.objectUUID,
        view_uuid: this.configForm.viewUUID,
        size: 1000,
        page: 1
      }).then(res => {
        this.fieldList = res?.data?.data?.data || [];
      }).catch(err => {
        console.log(err);
        this.$message.error('获取字段失败！');
      })
    }
  }
}
</script>

<style lang="less" scoped>
.dynamic-config{
  .header{
    display: flex;
    padding-bottom: 10px;
    .el-button{
      flex: 1;
    }
  }
  .el-form{
    .el-form-item{
      margin-bottom: 10px;
    }
  }
}
</style>