<!--
 * @Description: 显示配置
 * @Author: luocheng
 * @Date: 2021-10-14 14:53:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-01 15:17:37
-->
<template>
    <div class="view-config" v-if="curComponent">
        <header class="header">
            <p class="item-title">模型显示配置</p>
            <el-button type="primary" @click="onConfirm">确认操作</el-button>
            <a
                href="javascript:;"
                class="header-btn"
                @click="toggleShow = !toggleShow"
            >
                {{ toggleShow ? "收起" : "展开" }}
            </a>
        </header>
        <div class="configs" v-show="toggleShow">
            <el-form :model="viewConfig" label-width="85px">
                <el-form-item label="应用ID">
                    <el-input
                        v-model="viewConfig.appId"
                        placeholder="请输入应用ID"
                        clearable
                        size="mini"
                    ></el-input>
                </el-form-item>
                <el-form-item label="应用签名">
                    <el-input
                        v-model="viewConfig.appSecret"
                        placeholder="请输入应用ID"
                        clearable
                        size="mini"
                    ></el-input>
                </el-form-item>
								<el-form-item label="默认模型">
                    <!-- <el-select
                        v-model="viewConfig.modelId.type"
                        placeholder="请选择来源"
                        size="mini"
                    >
                        <el-option label="固定值" value="fixed"></el-option>
                        <el-option
                            label="路由参数"
                            value="routeQuery"
                        ></el-option>
                    </el-select> -->
                    <el-input
                        v-model="viewConfig.modelId.value"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 6 }"
                        :placeholder="valuePlaceholder"
                        size="mini"
                    ></el-input>
                </el-form-item>
								<el-form-item label="模型背景">
									<el-switch v-model="viewConfig.showModelBg"></el-switch>
								</el-form-item>
                <el-form-item v-if="viewConfig.showModelBg" label="背景颜色">
                    <el-color-picker
                        v-model="viewConfig.modelBg" show-alpha
                    ></el-color-picker>
                </el-form-item>
                <el-form-item label="显示工具栏">
										<el-select
                        v-model="viewConfig.showToolbar"
                        placeholder="请选择"
                        size="mini"
                    >
                        <el-option label="不显示" value="0"></el-option>
												<el-option label="只显示底部" value="1"></el-option>
												<el-option label="全部显示" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="+viewConfig.showToolbar !== 0" label="工具栏背景">
                    <el-color-picker
                        v-model="viewConfig.toolbarBg"
                    ></el-color-picker>
                </el-form-item>
                <el-form-item v-if="+viewConfig.showToolbar !== 0" label="工具栏颜色">
                    <el-color-picker
                        v-model="viewConfig.toolbarCol"
                    ></el-color-picker>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { ColorPicker } from "element-ui";

export default {
    name: "ViewConfig",
    data() {
        return {
            toggleShow: true,
            // 显示配置
            viewConfig: {
                appId: "",
                appSecret: "",
								showModelBg:true,
                modelBg: "rgba(255, 255, 255, 0.8)",
                showToolbar: '0',
                toolbarBg: "#FFFFFF",
                toolbarCol: "#121619",
                modelId: {
                    type: "fixed",
                    value: "",
                },
            },
        };
    },
    components: {
        "el-color-picker": ColorPicker,
    },
    computed: {
        ...mapState(["curComponent"]),
        valuePlaceholder() {
            return `请输入${
                this.viewConfig.modelId.type ? "固定值" : "路由参数"
            }`;
        },
    },
    created() {
				if(this.curComponent && this.curComponent.modelConfig){
					this.viewConfig = this.curComponent && this.curComponent.modelConfig;
				}
				console.log(this.viewConfig,'this.viewConfig')
    },
    methods: {
        /**
         * @desc: 确认
         */
        onConfirm() {
            this.$store.commit("modifyComponent", {
                component: {
                    ...this.curComponent,
                    modelConfig: this.viewConfig,
                },
                containerId: null,
                isModify: true,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.view-config {
    padding: 10px 0;
    .header {
        width: 100%;
        display: flex;
				position:relative;
        margin-bottom: 10px;
        .item-title {
            flex: 1;
            text-align: left;
            padding-left: 10px;
            line-height: 32px;
        }
        .header-btn {
            margin-left: 5px;
            color: @theme;
            line-height: 32px;
        }
    }
		.header::before{
			content: '';
			position: absolute;
			left:0;
			top:9px;
			width: 3px;
			height: 14px;
			background-color: @theme;
		}
    .configs {
        .el-form-item {
            margin-bottom: 10px;
            .el-input {
                width: 180px;
            }
            .el-select {
                width: 180px;
            }
        }
    }
}
</style>