<!--
 * @Description: 选择人员配置
 * @Author: luocheng
 * @Date: 2021-10-21 11:42:13
 * @LastEditors: luocheng
 * @LastEditTime: 2022-02-28 10:39:13
-->
<template>
  <div class="select-person-config">
    <template v-if="curComponent">
      <h3 class="title">组件名称: {{ curComponent.name }}</h3>
      <article class="contents">
        <!-- 配置关联的数据源 用于表单提交的参数绑定 目前固定的是 data_id -->
        <RelationSource></RelationSource>
        <!-- 配置操作 -->
        <ActionConfig></ActionConfig>
      </article>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RelationSource from '../dialogConfig/RelationSource.vue';
import ActionConfig from '../dialogConfig/ActionConfig';

export default {
  name: 'SelectPersonConfig',
  components: {
    RelationSource,
    ActionConfig
  },
  computed: {
    ...mapState(['curComponent'])
  }
}
</script>

<style lang="less" scoped>
.select-person-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    padding-bottom: 20px;
    text-align: left;
  }
  .contents{
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    margin-right: -15px;
  }
}
</style>