<!--
 * @Author: ttheitao
 * @Description: 全局组件工厂
 * @Date: 2022-08-12 09:28:20
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-12 16:52:55
-->
<template>
  <div class="global-component-container">
    <div @click.stop.prevent="showDialog" class="click-box">
      <slot></slot>
    </div>
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :width="width"
      :fullscreen="fullscreen"
      custom-class="sss-dialog"
      @open="onOpen"
      @opened="onOpened"
      @close="onClose"
      @closed="onClosed"
    >
      <component
        :is="comp"
        :class="compCustomClass"
        :config="config"
        @close="handleClose"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import comps from './components';

export default {
  name: 'GlobalFactory',
  components: {
    ...comps,
  },
  props: {
    /**
     * Excel - Excel绑定组件
     */
    name: { type: String, require: true },
    title: { type: String, default: '提示' }, // 弹窗标题
    fullscreen: { type: Boolean, default: false }, // 弹窗是否全屏
    width: { type: String, default: '30%' }, // 弹窗宽度
    compCustomClass: { type: String, default: '' }, // 全局组件的自定义class
    // 组件prop参数
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false, // 对话框显示状态
    };
  },
  computed: {
    comp: function () {
      let name = this.name;
      return `Global${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;
    },
  },
  methods: {
    /**
     * @description: Dialog 打开的回调
     * @return {void}
     */
    onOpen() {
      this.$emit('onOpen');
    },
    /**
     * @description: Dialog 打开动画结束时的回调
     * @return {void}
     */
    onOpened() {
      this.$emit('onOpened');
    },
    /**
     * @description: Dialog 关闭的回调
     * @return {void}
     */
    onClose() {
      this.$emit('onClose');
    },
    /**
     * @description: Dialog 关闭动画结束时的回调
     * @return {void}
     */
    onClosed() {
      this.$emit('closed');
    },
    /**
     * @description: 显示对话框
     * @return {void}
     */
    showDialog() {
      this.dialogVisible = true;
    },
    /**
     * @description: 关闭对话框
     * @return {void}
     */
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.global-component-container {
  .click-box {
    display: inline-block;
  }
}
</style>
