/*
 * @Author: ttheitao
 * @Description: 全局组件Minxin
 * @Date: 2022-08-12 09:30:05
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-12 13:40:42
 */
export default {
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}