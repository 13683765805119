<!--
 * @Description: 规则
 * @Author: luocheng
 * @Date: 2022-05-16 18:25:33
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-16 18:54:49
-->
<template>
  <div class="rule-item">
    <el-form-item label="更新组件" label-width="80px">
      <el-select v-model="configForm.componentId" @change="onUpdate">
        <el-option
          :label="comItem.name"
          :value="comItem.id"
          v-for="comItem in componentList"
          :key="comItem.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <div class="actions" @click="onRemove">
      <i class="el-icon-delete"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RuleItem',
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {
        return {
          component: ''
        }
      }
    },
    // 可选组件列表
    componentList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      configForm: {
        component: ''
      }
    }
  },
  created() {
    this.configForm = {
      ...this.config
    };
  },
  methods: {
    onRemove() {
      this.$emit('remove', this.index);
    },
    onUpdate() {
      this.$emit('update', {
        index: this.index,
        config: this.configForm
      })
    }
  }
}
</script>

<style lang="less" scoped>
.rule-item {
  display: flex;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover{
    border-color: @theme;
  }
  .el-form-item{
    margin-bottom: 0;
  }
  .actions{
    padding-left: 10px;
    line-height: 32px;
    i{
      font-size: 16px;
      color: @dangerColor;
      font-weight: bold;
    }
  }
}
</style>