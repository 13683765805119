<!--
 * @Description: 弹窗组件附加页面事件
 * @Author: luocheng
 * @Date: 2022-10-24 11:11:47
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-24 14:22:07
-->
<template>
  <div class="dialog-event">
    <article class="list" v-if="actionList && Object.keys(actionList).length">
      <section class="item" v-for="(btn, key) in actionList" :key="key">
        <el-row>
          <el-col :span="8">名称：</el-col>
          <el-col :span="16"><strong>{{ btn.text }}</strong></el-col>
        </el-row>
        <el-form class="event-list" v-if="btn.eventList && btn.eventList.length">
          <el-row v-for="(value, index) in btn.eventList" :key="index" class="event-box">
            <el-form-item label="事件类型:" label-width="120px">
              <h3 class="title">{{ value.key | filterEvent }}</h3>
            </el-form-item>
            <div v-for="(effect, eIndex) in value.effects" :key="eIndex">
              <el-form-item label="作用组件:" label-width="120px">
                <el-select v-model="effect.targets" placeholder="请选择作用组件" multiple disabled>
                  <el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作:" label-width="120px">
                <div class="tag-list">
                  <el-tag>{{ effect.actions | filterActions }}</el-tag>
                </div>
              </el-form-item>
            </div>
          </el-row>
        </el-form>
        <el-row>
          <el-button type="primary" size="mini" @click="onShowSelect(btn)">绑定事件</el-button>
        </el-row>
      </section>
    </article>
    <p v-else>暂未配置操作按钮</p>
		<!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'tableEvent'" :partData="partData" :isNesting="false"></SelectEvent>
		</el-drawer>
  </div>
</template>

<script>
import { defaultList, actionList } from '../../config';
import { mapState } from 'vuex';
import SelectEvent from '../../SelectEvent';
import { flatTree, unique } from '@/utils/tools';
import { Tag } from 'element-ui';

export default {
	name: 'TableEvents',
	components: {
		'el-tag': Tag,
		SelectEvent
	},
	data() {
		return {
			// 显示配置抽屉
			showSelect: false,
			// 当前配置的按钮
			partData: null,
			targetList: []
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前(组件数据)
			'originComponentData',
			'sContainer',
			'componentData' // 图层数据
		]),
		// 绑定的操作栏
		actionList() {
      const result = this.curComponent?.actionConfig || [];
			return result.map(ele => {
        return {
          ...ele,
          eventList: ele.eventList ?? []
        }
      });
		}
	},
	filters: {
		// 事件名称
		filterEvent(value) {
			for (let i = 0; i < defaultList.length; i++) {
				if (defaultList[i].value === value) {
					return defaultList[i].label;
				}
			}
			return value;
		},
		// 操作
		filterActions(value) {
			let result = [];
			for (let i = 0; i < value.length; i++) {
				const item = actionList.find((ele) => ele.value === value[i]);
				if (item) {
					result.push(item.label);
				}
			}
			return result.join();
		}
	},
	created() {
		if (this.sContainer) {
			this.targetList = flatTree(this.originComponentData || [], 'children', {
				label: 'name',
				value: 'id'
			}, []);
		}
		this.targetList = unique(this.targetList.concat(flatTree(this.componentData, 'children', {
				label: 'name',
				value: 'id'
			}, [])));
	},
	methods: {
		/**
		 * @desc: 显示配置事件表单
		 * @param {Object} btnData 按钮配置数据
		 */
		onShowSelect(btnData) {
			if (!btnData) return;
			this.partData = btnData;
			this.showSelect = true;
		}
	}
};
</script>

<style lang="less" scoped>
.dialog-event {
  margin-top: 20px;
  border-top: 1px solid #f2f3f5;
  padding-top: 10px;
  .event-list {
    padding-top: 10px;
    .event-box{
      border-bottom: 1px solid @borderColor;
      &:last-of-type{
        border: none;
      }
    }
  }
}
</style>