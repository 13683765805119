import { render, staticRenderFns } from "./ParamItem.vue?vue&type=template&id=3bc3798a&scoped=true&"
import script from "./ParamItem.vue?vue&type=script&lang=js&"
export * from "./ParamItem.vue?vue&type=script&lang=js&"
import style0 from "./ParamItem.vue?vue&type=style&index=0&id=3bc3798a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc3798a",
  null
  
)

export default component.exports