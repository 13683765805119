var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.layerDataForShow && _vm.layerDataForShow.length)?_c('div',{staticClass:"layer-tree",class:{
		'is-last-item': _vm.isLast
	},style:({
		overflowY: _vm.level === 0 ? 'auto' : ''
	})},[_vm._l((_vm.layerDataForShow),function(item,index){return _c('section',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-content",class:{
          'active': _vm.curComponent && item.id === _vm.curComponent.id,
					'is-screen-container': item.type === 'screenContainer'
        },on:{"click":function($event){return _vm.onSelect(item)},"dblclick":function($event){return _vm.onScreenContainer(item)}}},[_c('i',{staticClass:"iconfont",class:'icon-' + item.icon,on:{"click":function($event){item.toggle = !item.toggle}}}),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.name || '--'))]),_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){return _vm.onContextMenu(index, item, $event)}}})]),(item.children && item.children.length && item.type !== 'screenContainer')?[_c('section',{directives:[{name:"show",rawName:"v-show",value:(item.toggle),expression:"item.toggle"}],key:index,staticClass:"children"},[_c('LayerTree',{attrs:{"layerData":item.children,"level":_vm.level + 1,"isLast":index === _vm.layerDataForShow.length - 1}})],1)]:_vm._e()],2)}),_c('LayerMenu',{style:(_vm.menuStyle),attrs:{"modifyComponent":_vm.modifyComponent},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }