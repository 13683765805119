<!--
 * @Description: 选择事件
 * @Author: luocheng
 * @Date: 2021-08-24 09:29:10
 * @LastEditors: zx
 * @LastEditTime: 2022-12-05 14:29:16
-->
<template>
	<div class="select-event">
		<article class="config-content">
			<el-form
				:model="eventForm"
				:rules="rules"
				:ref="'ruleForm' + index"
				:label-width="labelWidth"
				v-for="(eventForm, index) in eventsConfig"
				:key="index"
			>
				<section class="event-item">
					<section class="event-content">
						<el-form-item label="事件类型" prop="type" :rules="rules.type">
							<el-select v-model="eventForm.type" placeholder="请选择事件类型">
								<!-- :disabled="selectedKey.includes(item.value)" -->
								<!-- :disabled="item.disabled" -->
								<el-option
									v-for="item in eventForm.eventList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="模式">
							<el-radio-group v-model="eventForm.pattern">
								<el-radio label="">常规模式</el-radio>
								<el-radio label="special">复杂类型模式</el-radio>
							</el-radio-group>
						</el-form-item>
						<!-- 常规模式 -->
						<template v-if="!eventForm.pattern">
							<el-form-item label="操作类型">
								<el-select v-model="eventForm.actionType" placeholder="请选择操作类型" clearable>
									<el-option label="页面事件" value="pageAction"></el-option>
									<el-option label="跳转页面" value="jumpPage"></el-option>
									<el-option label="导出数据" value="export"></el-option>
									<el-option label="后端事件" value="eeAction"></el-option>
									<el-option label="触发组件行为" value="componentBehavior"></el-option>
									<el-option label="修改页面状态" value="pageStatus"></el-option>
								</el-select>
							</el-form-item>
							<!-- 页面事件 -->
							<template v-if="eventForm.actionType === 'pageAction'">
								<div
									class="effect-box"
									v-for="(effect, eIndex) in eventForm.effects"
									:key="eIndex"
								>
									<div class="effect-content">
										<el-form-item
											label="作用组件"
											:label-width="labelWidth"
											:prop="`effects[${eIndex}].targets`"
											:rules="rules.targets"
										>
											<el-select
												v-model="effect.targets"
												placeholder="请选择作用组件"
												multiple
												filterable
											>
												<el-option
													v-for="item in targetList"
													:key="item.value"
													:disabled="item.disabled"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item
											label="操作"
											:label-width="labelWidth"
											:prop="`effects[${eIndex}].actions`"
											:rules="rules.actions"
										>
											<!-- <el-checkbox-group v-model="effect.actions">
												<el-checkbox-button
													v-for="item in actionList"
													:label="item.value"
													:key="item.value"
												>
													{{ item.label }}
												</el-checkbox-button>
											</el-checkbox-group> -->
											<el-checkbox-group v-model="effect.actions" >
												<el-checkbox 
													v-for="item in actionList"
													:label="item.value"
													:key="item.value"
												>{{item.label}}</el-checkbox>	
											</el-checkbox-group>
											<el-popover
												placement="top-start"
												width="300"
												trigger="hover"
												content="缓存显示与缓存隐藏需要正确设置组件初始状态下的是否显示，默认不激活的组件请设置为不显示！">
												<i class="header-icon el-icon-question" slot="reference"></i>
											</el-popover>
										</el-form-item>
										<el-form-item label="逻辑判断" v-if="effect.logics">
											<LogicConfig :config="effect.logics" :index="index" :eIndex="eIndex" @updateConfig="onUpdateBefore"></LogicConfig>
										</el-form-item>
									</div>
									<a
										href="javascript:;"
										class="remove iconfont icongis_shanchu"
										@click="onRemoveEffect(eventForm, index, eIndex)"
									></a>
								</div>
							</template>
							<!-- 跳转页面 -->
							<template v-else-if="eventForm.actionType === 'jumpPage'">
								<div class="link-box">
									<el-form-item label="链接类型">
										<el-select v-model="eventForm.linkType">
											<el-option
												label="项目页面"
												value="projectPage"
											></el-option>
											<el-option
												label="外部页面"
												value="outPage"
											></el-option>
											<el-option
												label="自定义拼接"
												value="custom"
											></el-option>
											<el-option
												v-if="editorType === 'mobile'"
												label="自定义表单（仅H5可用）"
												value="customForm"
											></el-option>
											<el-option
												v-if="editorType === 'mobile'"
												label="配置页面（仅H5可用）"
												value="pageH5"
											></el-option>
										</el-select>
									</el-form-item>
									<!-- 外链 -->
									<el-form-item label="外链" v-if="eventForm.linkType === 'outPage'">
										<el-input
											v-model="eventForm.linkUrl"
											placeholder="请输入外链"
										></el-input>
									</el-form-item>
									<!-- 项目页面 -->
									<template v-if="eventForm.linkType === 'projectPage'">
										<el-form-item label="页面路由">
											<el-select
												v-model="eventForm.routePath"
												filterable
												reserve-keyword
												allow-create
												clearable
												placeholder="请输入关键词"
											>
												<el-option
													v-for="item in pathList"
													:key="item.id"
													:label="item.name"
													:value="item.url"
												>
												</el-option>
											</el-select>
										</el-form-item>
										<div
											class="query-item"
											v-for="(queryItem, queryIndex) in eventForm.queryList"
											:key="queryIndex"
										>
											<div class="query-item-form">
												<el-form-item label="参数名称">
													<el-input
														v-model="queryItem.key"
														placeholder="请输入参数名称"
														clearable
													></el-input>
												</el-form-item>
												<el-form-item label="来源组件">
													<el-select
														v-model="queryItem.componentId"
														@change="onComponent(index, queryIndex, queryItem.componentId)"
													>
														<el-option
															v-for="com in targetList"
															:key="com.value"
															:label="com.label"
															:value="com.value"
														></el-option>
													</el-select>
												</el-form-item>
												<el-form-item label="关联字段">
													<el-select v-model="queryItem.feild">
														<el-option
															v-for="(item, key) in queryItem.fieldList"
															:key="item"
															:label="item"
															:value="key"
														></el-option>
													</el-select>
												</el-form-item>
											</div>
											<section class="action">
												<i class="el-icon-delete" @click="onRemoveQuery(index, queryIndex)"></i>
											</section>
										</div>
										<el-button
											type="primary"
											@click="onAddQuery(index)"
										>新增路由参数</el-button>
									</template>
									<!-- 自定义拼接 -->
									<template v-else-if="eventForm.linkType === 'custom'">
										<el-form-item label="hosts">
											<span slot="label">
												<el-popover
													placement="top-start"
													title="提示"
													width="400"
													:center="true"
													trigger="click">
													<div class="rules-contents">
														<h4>系统将自动根据拼接模板进行跳转链接解析，花括号中的变量将被自动替换（'{ a }' => '我是a'）</h4>
														<h4>特殊的变量</h4>
														<ol class="rules-list">
															<li><strong>hosts</strong> => 当前页面链接hosts</li>
															<li><strong>token</strong> => 当前系统的bimcctoken</li>
														</ol>
													</div>
													<span slot="reference">
														<span>链接模板</span>
														<i class="el-icon-question"></i>
													</span>
												</el-popover>
											</span>
											<el-input v-model="eventForm.linkTemplate" placeholder="请输入拼接规则模板" clearable></el-input>
										</el-form-item>
									</template>
									<!-- 自定义表单 -->
									<template v-else-if="eventForm.linkType === 'customForm'">
										<CustomFormConfig v-model="eventForm.customFormLink"></CustomFormConfig>
									</template>
									<template v-else-if="eventForm.linkType === 'pageH5'">
										<el-form-item label="H5配置页面">
											<el-select
												v-model="eventForm.page_uuid"
												filterable
												remote
												placeholder="请选择功能页面"
												clearable
												:remote-method="onRemoteView"
												:loading="viewLoading"
											>
												<el-option
													v-for="item in pagesList"
													:key="item.name"
													:label="item.name"
													:value="item.uuid"
												>
													<span>
														{{ item.name }}
													</span>
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="参数携带" prop="params">
											<div class="params-list" v-for="(item, index) in eventForm.paramsList" :key="index">
												<div class="params-list-item">
													<el-input
														style="margin-bottom: 16px;align-items: center;"
														v-model="item.key"
														placeholder="请输入参数key值"
														clearable
													></el-input>
													<el-select style="margin-bottom: 16px;" v-model="item.uuid" clearable placeholder="选择携带参数值">
														<el-option v-for="(item) in configList" :key="item.uuid" :label="item.label" :value="item.uuid"></el-option>
													</el-select>
												</div>
												<div class="params-list-delete">
													<i class="iconfont icongis_shanchu" @click="deleteParams(eventForm, index)"></i>
												</div>
											</div>
											<el-button type="primary" @click="addParams(eventForm)">新增参数</el-button>
										</el-form-item>
									</template>
								</div>
							</template>
							<!-- 导出数据 -->
							<template v-else-if="eventForm.actionType === 'export'">
								<div class="export-box">
									<el-form-item label="数据源容器">
										<el-select
											v-model="eventForm.exportTarget"
											placeholder="请选择作用组件"
										>
											<el-option
												v-for="item in targetList"
												:key="item.value"
												:disabled="item.disabled"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="参数" v-if="eventForm.exportTarget && eventForm.exportTarget.includes('CommonTableContainer')">
										<ul class="params-list" v-if="eventForm.params">
											<li v-for="(param, pIndex) in eventForm.params" :key="pIndex">
												<section class="param-row">
													<p class="label">字段名：</p>
													<p>{{ param.key }}</p>
												</section>
												<section class="param-row">
													<p class="label">来源：</p>
													<p>{{ param.sourceType | filterSource }}</p>
												</section>
											</li>
										</ul>
										<p v-else>暂未配置参数</p>
										<el-button type="text" size="mini" @click="onPlusParams(eventForm, index)">添加参数</el-button>
									</el-form-item>
									<el-form-item label="类型">
										<el-select v-model="eventForm.exportType" placeholder="请选中导出类型">
											<el-option label="全部" value="all"></el-option>
											<template v-if="eventForm.exportTarget.indexOf('CommonTree-') === 0
											">
												<el-option label="当前行" value="targetRow"></el-option>
											</template>
											<template v-else>
												<el-option v-if=" eventForm.exportTarget.indexOf('CommonTableContainer-') === 0"
													label="当前行" value="targetRow"
												></el-option>
												<el-option label="带条件全部" value="searchAll"></el-option>
												<el-option label="本页数据" value="targetPage"></el-option>
												<el-option label="导出选中" value="checked"></el-option>
											</template>
										</el-select>
									</el-form-item>
									<el-form-item label="导出模板">
										<el-select
											v-model="eventForm.exportTemplate"
											clearable
											filterable
											remote
											:remote-method="getExportTemplate"
											:loading="templateLoading"
											placeholder="请选择导出模板"
										>
											<el-option
												v-for="tem in templateList"
												:key="tem.id"
												:label="tem.name"
												:value="tem.uuid"
											></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="导出结果类型">
										<el-select v-model="eventForm.exportTypeSheet" placeholder="请选择导出结果类型">
											<el-option label="表格" value="excel"></el-option>
											<el-option label="图片" value="image"></el-option>
											<el-option label="PDF" value="pdf"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="请求方式">
										<el-select v-model="eventForm.exportRequest" placeholder="请选择请求方式">
											<el-option label="列表" value="dataList"></el-option>
											<el-option label="详情" value="dataInfo"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="数据对象">
										<SelectObjectUUID
											v-model="eventForm.objectUUID"
											:placeholder="'请选择对象'"
										></SelectObjectUUID>
									</el-form-item>
									<el-form-item
										label="导出视图"
										v-if="eventForm.objectUUID"
									>
										<SelectViewUUID
											v-model="eventForm.viewUUID"
											:objectUUID="eventForm.objectUUID"
											:placeholder="'请选择视图'"
										>
										</SelectViewUUID>
									</el-form-item>
								</div>
							</template>
							<!-- 触发后端事件 -->
							<template v-else-if="eventForm.actionType === 'eeAction'">
								<el-form-item label="绑定对象">
									<SelectObjectUUID
										v-model="eventForm.objectUUID"
										:placeholder="'请选择对象'"
									>
									</SelectObjectUUID>
								</el-form-item>
								<el-form-item label="绑定视图" v-if="eventForm.objectUUID">
									<SelectViewUUID
										v-model="eventForm.viewUUID"
										:objectUUID="eventForm.objectUUID"
										:placeholder="'请选择视图'"
									>
									</SelectViewUUID>
								</el-form-item>
								<el-form-item label="事件名称" v-if="eventForm.objectUUID">
									<SelectEEEvent
										v-model="eventForm.eventName"
										:objectUUID="eventForm.objectUUID"
										:placeholder="'请选择事件'">
									</SelectEEEvent>
								</el-form-item>
								<el-form-item label-width="0">
									<!-- 关联参数 -->
									<RelationSource></RelationSource>
								</el-form-item>
								<el-form-item label="逻辑判断" v-if="editorType === 'mobile'">
									<LogicConfig :config="eventForm.logics" :index="index" @updateConfig="onUpdateBefore($event, true)"></LogicConfig>
								</el-form-item>
							</template>
							<!-- 触发组件行为 -->
							<template v-else-if="eventForm.actionType === 'componentBehavior'">
								<ConfigBehavior v-model="eventForm.behaviors"></ConfigBehavior>
							</template>
							<!-- 修改页面状态 -->
							<template v-else-if="eventForm.actionType === 'pageStatus'">
								<StateMachine v-model="eventForm.stateMachine"></StateMachine>
							</template>
							<!-- 操作 -->
							<div class="actions">
								<el-button
									type="success"
									@click="onEffect(eventForm, index)"
									v-if="eventForm.actionType === 'pageAction'"
								>新增作用域</el-button>
								<el-button
									type="success"
									@click="onPlusBehavior(eventForm, index)"
									v-if="eventForm.actionType === 'componentBehavior'"
								>新增作用组件</el-button>
								<el-button
									type="danger"
									@click="onRemove(index)"
								>删除事件</el-button>
							</div>
						</template>
						<!-- 复杂类型模式 -->
						<template v-else-if="eventForm.pattern === 'special'">
							<SpecialConfig v-model="eventForm.specialEventList" :index="index" @remove="onRemove"></SpecialConfig>
						</template>
					</section>
				</section>
			</el-form>
			<footer class="event-actions">
				<el-button type="primary" size="small" class="add-event" @click="onAddEvent">新增事件</el-button>
			</footer>
		</article>
		<footer class="select-event-footer">
			<el-button @click="onCancel">取 消</el-button>
			<el-button type="primary" @click="onSave" :loading="loading">{{ loading ? "提交中 ..." : "确 定" }}</el-button>
		</footer>
		<!-- 配置参数 -->
		<el-dialog
      title="参数配置"
      :visible.sync="showParams"
      append-to-body
      fullscreen
      lock-scroll
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%">
      <ConfigParams
        @update="onUpdateParams"
        @close="onCloseParams"
        :params="currentConfig?.params || []"
				:origin="currentConfig?.exportTarget"
      ></ConfigParams>
    </el-dialog>
	</div>
</template>

<script>
import { CheckboxGroup,  Popover, Checkbox } from 'element-ui';
import { mapState } from 'vuex';
import { flatTree, getComponentById, unique } from '@/utils/tools';
import { defaultList, actionList } from './config';
import { dataInterface, getFields } from '@/apis/data';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import LogicConfig from './logic/Index';
import SelectEEEvent from '@/components/common/SelectEEEvent';
import RelationSource from '@/components/dialogConfig/RelationSource';
import ConfigBehavior from './behavior/Index';
import SpecialConfig from './specialConfig/Index';
import StateMachine from './stateMachine/Index';
import CustomFormConfig from '@/components/events/customFormConfig/index.vue'
import ConfigParams from '@/components/common/ConfigParams'

export default {
	name: 'SelectEvent',
	props: {
		// 当前配置的作用对象类型  component 普通组件事件  tableEvent 表格内部事件 tabEvent tab事件
		type: {
			type: String,
			required: false,
			default: 'component'
		},
		// 当前操作的按钮数据
		partData: {
			type: [Object, null],
			required: false,
			default: () => null
		},
		// 是否为子表格事件
		isNesting: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		'el-checkbox-group': CheckboxGroup,
		'el-popover': Popover,
		'el-checkbox': Checkbox,
		SelectObjectUUID,
		SelectViewUUID,
		LogicConfig,
		SelectEEEvent,
		RelationSource,
		ConfigBehavior,
		SpecialConfig,
		StateMachine,
		CustomFormConfig,
		ConfigParams
	},
	data() {
		/* eslint-disable */
		// 验证选择的组件对象
		const validateTarget = (rule, value, callback) => {
			if (!value || !value.length) {
				callback(new Error('请选择作用组件！'));
			} else {
				callback();
			}
		};
		// 验证选择的事件类型
		const validateAction = (rule, value, callback) => {
			if (!value || !value.length) {
				callback(new Error('请选择操作!'));
			} else {
				callback();
			}
		};
		return {
			// 事件选择表单
			eventsConfig: [],
			// Label
			labelWidth: '100px',
			// loading
			loading: false,
			// 可选事件类型列表
			defaultList,
			// 验证
			rules: {
				// 类型
				type: [
					{
						required: true,
						message: '请选择事件类型',
						trigger: 'change'
					}
				],
				targets: [
					{
						validator: validateTarget,
						trigger: 'blur'
					}
				],
				actions: [
					{
						validator: validateAction,
						trigger: 'blur'
					}
				]
			},
			// 可选操作列表
			actionList,
			// 组件列表(扁平化)
			targetList: [],
			// 路径列表
			pathList: [],
			// 导出
			templateLoading: false,
			templateList: [],
      pagesList: [], // 移动端配置好的页面列表
			viewLoading: false,
			configList: [], // 参数列表
			// 参数（主要用于导出）
			showParams: false,
			currentConfig: null,
			currentIndex: 0
		};
	},
	computed: {
		...mapState([
			'curComponent', // 当前组件
			'componentData', // 组件列表
			'originComponentData',
			'sContainer',
			'editorType'
		]),
		// 已经添加的事件Key
		selectedKey() {
			return this.curComponent ? Object.keys(this.curComponent.events) : [];
		},
		// 可选组件列表
		// componentList() {
		// 	let result = [];
		// 	if (this.sContainer) {
		// 		result = flatTree(this.originComponentData || [], 'children', {
		// 			label: 'name',
		// 			value: 'id'
		// 		}, [])
		// 	}
		// 	return unique(result.concat(flatTree(this.componentData, 'children', {
		// 		label: 'name',
		// 		value: 'id'
		// 	}, [])))
		// }
	},
	async created() {
    this.getPagesList()
    this.getConfigList()
		this.fieldList = await this.getFieldsList('5131498a-5901-4bc2-a739-9d517c60d0fa');
		if (this.sContainer) {
			this.targetList = flatTree(this.originComponentData || [], 'children', {
				label: 'name',
				value: 'id'
			}, []);
		}
		this.targetList = unique(this.targetList.concat(flatTree(this.componentData, 'children', {
			label: 'name',
			value: 'id'
		}, [])));
		// 如果存在已经配置的事件则进行默认显示(新版事件可能存在兼容)
		if (['tableEvent', 'tabEvent', 'treeEvent', 'listEvent'].includes(this.type) && Array.isArray(this.partData?.eventList)) {
			this.eventsConfig = this.partData.eventList.map((ele) => {
				if (ele.pattern === undefined) {
					return {
						pattern: '',
						specialEventList: ele.specialEventList || [],
						...ele,
						type: ele.key,
						eventList: this.defaultList
					};
				} else if (!ele.pattern) {
					const { eventList = [] } = ele;
					const item = eventList[0] || {}
					return {
						pattern: '',
						specialEventList: item.specialEventList || [],
						...item,
						type: item.key,
						eventList: this.defaultList
					}
				} else if (ele.pattern === 'special') {
					// 特殊
					return {
						pattern: 'special',
						specialEventList: ele.specialEventList || [],
						...this.partData,
						type: this.defaultList.find(ele => ele.value === this.partData.type) ? this.partData.type : 'click',
						eventList: this.defaultList
					}
				}
			});
		} else {
			const events = this.curComponent.events;
			const keys = Object.keys(events);
			if (keys && keys.length) {
				keys.forEach((type) => {
					this.eventsConfig.push({
						pattern: '',
						specialEventList: events?.[type]?.specialEventList || [],
						type,
						...events[type],
						eventList: this.defaultList
					});
				});
			}
		}
		console.log(this.eventsConfig, '----eventsConfig----');
		// 获取页面列表
		this.getPathList();
		this.getExportTemplate('');
	},
	watch: {
		/**
		 * @desc: 过滤
		 */
		filterText(val) {
			this.$refs.tree.filter(val);
		}
	},
	filters: {
    filterSource(val) {
      if (val === 'url') {
        return 'URL参数'
      }
      if (val === 'system') {
        return '系统参数'
      }
      return '内部组件';
    },
  },
	methods: {
		/**
		 * @desc: 新增行为
		 */
		onPlusBehavior(form, index) {
			const item = JSON.parse(JSON.stringify(form));
			item.behaviors.push({
				component: '', // 触发的组件
				list: [ // 行为列表
					{
						behaviors: [],
						params: []
					}
				]
			});
			this.eventsConfig.splice(index, 1, {
				...item
			});
		},
		/**
		 * @desc: 取消
		 */
		onCancel() {
			this.$emit('close');
		},
		/**
		 * @desc: 保存
		 * 保存时候需要进行对内部和外部操作进行判断区分操作
		 */
		onSave() {
			const result = [];
			this.eventsConfig.forEach((item) => {
				const { pattern = undefined, specialEventList = [], type= '' } = item;
				if (!pattern) {
					// 普通模式
					result.push({
						type,
						key: type,
						pattern,
						eventList: [this.formatEvent(item)]
					})
				} else if (pattern === 'special') {
					// 特殊类型
					result.push({
						type,
						key: type,
						pattern,
						specialEventList: specialEventList.map(ele => {
							return this.formatEvent({
								...ele,
								...item
							});
						})
					});
				}
			});
			if (this.type === 'component') {
				this.$store.commit('modifyComponentEvent', result);
			} else {
				// 组件内部按钮事件
				this.$store.commit('modifyComInsideEvent', {
					eventList: result,
					partData: this.partData,
					isNesting: this.isNesting
				});
			}
			this.$emit('close');
		},
		/**
		 * @desc: 格式化事件
		 * @param {Object} item
		 */
		formatEvent(item) {
			if (item.type && item.actionType === 'pageAction' && item.effects.length) {
				return {
					...item,
					key: item.type, // 组件key值
					actionType: item.actionType,
					// targets: item.targets, // 目标组件
					// actions: item.actions, // 需要执行的操作
					logics: item.logics,
					effects: item.effects,
					beforeHandle: '', // 前置操作
					successHandle: '', // 成功回调
					failHandle: '' // 失败回调
				};
			} else if (item.type && item.actionType === 'jumpPage') {
				return {
					...item,
					pageId: item.linkType === 'outPage' ? '' : item.routePath,
					key: item.type, // 组件key值
					// targets: item.targets, // 目标组件
					// actions: item.actions, // 需要执行的操作
					beforeHandle: '', // 前置操作
					successHandle: '', // 成功回调
					failHandle: '' // 失败回调
				};
			} else if (item.type && item.actionType === 'export') {
				// 导出
				return {
					...item,
					key: item.type,
					beforeHandle: '', // 前置操作'----前置操作配置'
					successHandle: '', // 成功回调
					failHandle: '' // 失败回调
				}
			} else if (item.type && item.actionType === 'eeAction') {
				// 触发后端事件
				return {
					...item,
					key: item.type, // 组件key值
					actionType: item.actionType
				}
			} else if (item.type && item.actionType === 'componentBehavior') {
				// 组件行为
				return {
					...item,
					key: item.type, // 组件key值
					actionType: item.actionType
				}
			} else if (item.type && item.actionType === 'pageStatus') {
				return {
					...item,
					key: item.type
				}
			}
			return item;
		},
		/**
		 * @desc: 添加查询参数
		 * @param {Number} index
		 */
		onAddQuery(index) {
			const item = this.eventsConfig[index];
			let queryList = item.queryList || [];
			queryList.push({
				key: '', // 字段key
				componentId: '', // 关联组件
				feild: '', // 字段
				fieldList: '' // 字段列表
			});
			this.eventsConfig.splice(index, 1, {
				...item,
				queryList
			});
		},
		/**
		 * @desc: 选择组件后清空字段并重新获取字段列表
		 */
		onComponent(index, queryIndex, componentId) {
			const component = getComponentById(this.componentData, componentId);
			const item = this.eventsConfig[index];
			const queryList = item.queryList;
			queryList.splice(queryIndex, 1, {
				...queryList[queryIndex],
				fieldList: component && component.metadata ? component.metadata : []
			});
			this.eventsConfig.splice(index, 1, {
				...item,
				queryList
			});
		},
		/**
		 * @desc: 新增事件
		 */
		onAddEvent() {
			// 首先保存当前已编辑完成的
			if (this.eventsConfig.length && !this.validateForm()) {
				this.$message.warning('请正确填写相关内容');
				return;
			}
			// 新增
			const keys = Object.keys(this.curComponent.events);
			this.eventsConfig.push({
				type: '',
				pattern: '',
				specialEventList: [], // 复杂事件
				effects: [
					{
						targets: [],
						actions: [],
						logics: []
					}
				],
				eventList: this.defaultList.map((ele) => {
					return {
						...ele,
						disabled: keys.includes(ele.value)
					};
				}),
				// 导出
				exportTarget: '', // 导出的表格组件
				params: [],
				exportType: '', // 导出的方式 all 全部 searchAll 带参数全部 checked 选中数据  targetPage 当前页 searchTargetPage 带参数本页
				exportTemplate: '', // 导出模板
				exportRequest: '', // 请求方式
				exportTypeSheet: 'pdf', // 导出类型表格新增, [excel | image | pdf] 2022-02-16 14:38:28
				objectUUID: '', // 对象UUID
				viewUUID: '', // 视图
				eventName: '', // 触发的后端事件名称
				// 组件行为
				behaviors: [
					{
						component: '', // 触发的组件
						list: [ // 行为列表
							{
								behaviors: [],
								params: []
							}
						]
					}
				],
				// 状态机列表
				stateMachine: [
					// {
					// 	code: '', // 字段
					// 	type: '', // 取值类型， 固定值： fixed, 组件来源: component,  逻辑产出值： fromLogic 
					// 	fixedValue: '', // 固定值
					// 	componentId: '', // 组件ID
					// 	componentValueOrigin: '', // 组件取值值来源，resolveData: 暴露数据， propValue:  绑定值
					// 	resolveKey: '', // 暴露数据的取值key
					// }
				]
			});
		},
		/**
		 * @desc: 新增作用域
		 * @param {Object} eventForm 事件表单对象
		 * @param {Number} index 事件下标
		 */
		onEffect(eventForm, index) {
			const item = JSON.parse(JSON.stringify(eventForm));
			item.effects.push({
				targets: [],
				actions: [],
				logics: []
			});
			this.eventsConfig.splice(index, 1, {
				...item
			});
		},
		/**
		 * @desc: 删除事件作用域
		 * @param {Object} eventForm 事件表单对象
		 * @param {Number} index 事件下标
		 * @param {Number} eIndex 作用域下标
		 */
		onRemoveEffect(eventForm, index, eIndex) {
			const item = JSON.parse(JSON.stringify(eventForm));
			item.effects.splice(eIndex, 1);
			this.eventsConfig.splice(index, 1, {
				...item
			});
		},
		/**
		 * @desc: 移除事件
		 * @param {Number} index 事件下标
		 */
		onRemove(index) {
			this.eventsConfig.splice(index, 1);
		},
		/**
		 * @desc: 验证动态表单
		 */
		validateForm() {
			let validAll = true;
			for (let i = 0; i < this.eventsConfig.length; i++) {
				this.$refs[`ruleForm${i}`][0].validate((valid) => {
					validAll = valid;
				});
				if (!validAll) return false;
			}
			return true;
		},
		/**
		 * @desc: 获取页面列表
		 */
		getPathList() {
			this.loading = true;
			this.pageData = [];
			const search = [
				{
					field_uuid: '97fb3c74-5e6b-4770-9fb6-75bf2695f30b',
					ruleType: 'gt',
					value: ''
				}
			];
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: '5131498a-5901-4bc2-a739-9d517c60d0fa',
				view_uuid: 'd69059df-2793-43b7-a734-0714e07c4f2c',
				search,
				page: 1,
				size: 1000
			}).then((res) => {
				this.loading = false;
				if (res && res.status === 200 && res.data && res.data.data) {
					const pageData = this.formatField(
						Array.isArray(res.data.data) ? res.data.data : res.data.data.data || [],
						this.fieldList
					);
					this.pathList = [];
					pageData.forEach((ele) => {
						if (ele.type === 0) {
							this.pathList.push(ele);
						}
						if (ele.type === 2 && ele.children.length) {
							ele.children.forEach((item) => {
								if (item.type === 0) {
									this.pathList.push(item);
								}
							});
						}
					});
				}
			}).catch(() => {
				this.loading = false;
			});
		},
		/**
		 * @desc: 获取字段ID
		 * @param {String} object_uuid 对象UUID
		 */
		getFieldsList(object_uuid) {
			return new Promise((resolve, reject) => {
				getFields({
					object_uuid,
					size: 1000,
					page: 1
				})
					.then((res) => {
						resolve(res.data.data.data || []);
					})
					.catch(() => {
						reject([]);
					});
			});
		},
		/**
		 * @desc: 格式化数据列表 uuid => code
		 * @param {Array} arr
		 * @return {Array}
		 */
		formatField(arr, fieldList) {
			return arr.map((ele) => {
				const result = {};
				for (let key in ele) {
					for (let i = 0; i < fieldList.length; i++) {
						if (key === fieldList[i].uuid) {
							result[fieldList[i].code] = ele[key];
							break;
						}
					}
				}
				const children = ele['72da4bd8-c508-48c9-8e75-5ed59e6b09d2'];
				if (children) {
					const childrenList = this.formatField(children, fieldList);
					ele['72da4bd8-c508-48c9-8e75-5ed59e6b09d2'] = childrenList;
					ele.children = childrenList;
					return {
						...result,
						children: childrenList
					};
				}
				return result;
			});
		},
		/**
		 * @desc: 获取模板列表
		 */
		getExportTemplate(value = '') {
			this.templateLoading = true;
			dataInterface({
				object_uuid: 'object617fbedcefab4',
				view_uuid: 'view61baf4e99b7b3',
				__method_name__: 'dataList',
				search: [
					{
						field_uuid: 'field617fbedcf29d2',
						ruleType: 'like',
						value
					}
				],
				transcode: 0
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.templateList = data.data || [];
					}
					this.templateLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.templateList = [];
					this.templateLoading = false;
				});
		},
		/**
		 * @desc: 更新前置配置
		 */
		onUpdateBefore(data, boo) {
			let formObj = JSON.parse(JSON.stringify(this.eventsConfig[data.index]));
			if (!boo) {
				let effects = formObj.effects || [];
				let effectItem = effects[data.eIndex];
				effectItem = {
					...effectItem,
					logics: data.config || []
				}
				effects.splice(data.eIndex, 1, {
						...effectItem
					})
				const result = {
					...formObj,
					effects
				}
				this.eventsConfig.splice(data.index, 1, {
					...result
				});
			} else {
				/* 后端事件保存逻辑判断 */
				this.eventsConfig.splice(data.index, 1, {
					...formObj,
					logics: data.config || []
				});
			}
		},
		/**
		 * @desc: 移除query参数
		 * @param {Number} index
		 */
		onRemoveQuery(index, queryIndex) {
			const item = this.eventsConfig[index];
			const queryList = JSON.parse(JSON.stringify(item.queryList));
			queryList.splice(queryIndex, 1);
			this.eventsConfig.splice(index, 1, {
				...item,
				queryList
			})
		},
		/**
		 * @desc: 请求h5配置页面
		 * @param 
		 */
		getPagesList(params) {
			const data = [{
				field_uuid: "field61bc41f645e17", 
				ruleType: "like", 
				value: "mobile"
			}]
			if (params && params.field_uuid) {
				data.push(params)
			}
			this.viewLoading = true;
			dataInterface({
				object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
        page: 1,
        search: data,
        size: 20,
        transcode: 0,
        view_uuid: "view61922b1881103",
        __method_name__: "dataList"
			}).then((res) => {
				this.viewLoading = false;
        if (res.status === 200 && res.data && res.data.code == 200) {
          this.pagesList = res.data.data.data
        }
      }).catch((err) => {
				this.viewLoading = false;
        console.log(err, '----err');
      });
    },
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteView(name) {
			if (name) {
				const params = {field_uuid: "d1f7e149-2af7-468e-85be-5ef9bd97f634", ruleType: "like", value: name}
				this.getPagesList(params)
				return 
			}
			this.getPagesList()
		},
		/**
		 * @desc: 获取携带参数列表选择
		 */
    getConfigList() {
      this.configList = [];
			if (!this.curComponent) return;
      if (
        this.curComponent.fieldConfig &&
        this.curComponent.fieldConfig.length
      ) {
        this.configList = this.curComponent.fieldConfig.map(ele => {
          return {
            align: 'center',
            headerAlign: 'center',
            numberOptions: {
              type: '', // 类型
              decimalPlace: '', // 小数位数
              prefix: '', // 前缀
              suffix: '' // 后缀
            },
            permission: [],
            UILogic: null,
            ...ele,
            quickEdit: false,
          }
        });
        return;
      }
      if (!this.curComponent && !this.curComponent.metadata) return;
      // this.initField();
    },
		addParams(eventForm) {
			if (eventForm.paramsList && Array.isArray(eventForm.paramsList) && eventForm.paramsList.length) {
				const arr = eventForm.paramsList
				arr.push({key: '',uuid: ''})
				this.$set(eventForm, 'paramsList', arr)
			} else {
				const arr = []
				arr.push({key: '',uuid: ''})
				this.$set(eventForm, 'paramsList', arr)
			}
		},
		deleteParams(eventForm, index) {
			const arr = eventForm.paramsList
			arr.splice(index, 1)
			this.$set(eventForm, 'paramsList', arr)
		},
		/**
     * @desc: 添加参数
     */
    onPlusParams(config, index) {
			this.currentConfig = config;
			this.currentIndex = index;
      this.showParams = true;
    },
		/**
     * @desc: 参数配置
     * @param {Array} params
     */
		onUpdateParams(params) {
      // this.configData.params = params || [];
			this.eventsConfig.splice(this.currentIndex, 1, {
				...this.currentConfig,
				params
			});
      this.onCloseParams();
    },
    /**
     * @desc: 关闭
     */
    onCloseParams() {
      this.showParams = false
    }
	}
};
</script>

<style lang="less" scoped>
.select-event {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px 10px;
	.config-content {
		flex: 1;
		overflow: hidden;
		overflow-y: auto;
		:deep(.el-form)  {
			box-sizing: border-box;
			padding-top: 10px;
			// border-bottom: 1px solid @borderColor;
			.event-item {
				display: flex;
				.event-content {
					flex: 1;
					.el-form-item--mini.el-form-item,
					.el-form-item--small.el-form-item {
						margin-bottom: 15px;
					}
				}
				.remove {
					font-size: 20px;
					color: @dangerColor;
					box-sizing: border-box;
					padding-left: 10px;
					line-height: 100px;
				}
			}
			.el-form-item__label {
				text-align: center;
			}
			.el-select {
				width: 100%;
			}
			.effect-box {
				border: 1px solid #f2f3f5;
				box-sizing: border-box;
				padding: 5px;
				display: flex;
				margin-bottom:5px;
				.effect-content {
					flex: 1;
					.header-icon{
						color: @warnColor;
						margin-left: 10px;
						font-size: 16px;
					}
				}
				.remove {
					font-size: 20px;
					color: @dangerColor;
					box-sizing: border-box;
					padding-left: 10px;
					line-height: 100px;
				}
			}
		}
		.actions {
			padding: 5px 0;
		}
		.event-actions{
			display: flex;
			justify-content: space-between;
			padding: 10px 0;
			.remove-event{
				margin-right: 10px;
				height: 28px;
			}
			.add-event {
				flex: 1;
			}
		}
	}
}
.query-item{
	box-sizing: border-box;
	padding: 5px;
	border: 2px solid #f2f3f5;
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	display: flex;
	.query-item-form {
		flex: 1;
	}
	.action{
		padding: 0 5px;
		i{
			line-height: 100px;
			font-size: 16px;
			color: @dangerColor;
			font-weight: bold;
		}
	}
	.el-form-item{
		&:last-of-type{
			margin-bottom: 0px!important;
		}
	}
}
.params-list {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	.params-list-item {
		width: 80%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.params-list-delete {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			color: #F56C6C;
		}
	}
}
</style>
<style>
.rules-contents{
	box-sizing: border-box;
	padding: 0 15px;
}
</style>
