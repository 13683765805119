<!--
 * @Author: zx
 * @Date: 2022-07-20 11:00:50
 * @LastEditTime: 2022-10-26 15:46:50
 * @Description:
 * @LastEditors: Shiltin 18580045074@163.com
-->
<template>
  <div class="key-sitting">
    <p>组件key值设置</p>
    <div class="key-item">
      <div class="key-item-label">
        label显示:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="树状数据名称显示key值！">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-select v-model="mobileTreeInfo.labelKey" clearable placeholder="请选择" @change="selectChange">
        <el-option
          v-for="item in curComponent.database.fieldList"
          :key="item.uuid"
          :label="item.name"
          :value="item.uuid">
        </el-option>
      </el-select>
    </div>
    <div class="key-item">
      <div class="key-item-label">
        子级显示:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="树状数据子级key值！">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-select v-model="mobileTreeInfo.childrenKey" clearable placeholder="请选择" @change="selectChange">
        <el-option
          v-for="item in curComponent.database.fieldList"
          :key="item.uuid"
          :label="item.name"
          :value="item.uuid">
        </el-option>
      </el-select>
    </div>
    <div class="key-item" v-if="!hiddenJump">
      <div class="key-item-label">
        多选:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="多选模式下,不允许携带参数">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-switch size="small" v-model="mobileTreeInfo.checkBoxVisible" @change="selectChange"></el-switch>
    </div>
    <div class="key-item" v-if="!hiddenJump">
      <div class="key-item-label">
        数据是否分页:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="视图是否有数据分页，影响到树组件取值">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-switch size="small" v-model="mobileTreeInfo.isPage" @change="selectChange"></el-switch>
    </div>
    <div v-if="!mobileTreeInfo.checkBoxVisible && !hiddenJump" class="key-item">
      <div class="key-item-label">
        携带参数:
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          content="树状单选携带点击数据参数！">
          <i class="header-icon el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <div class="params-container" style="height: auto;flex: 1">
        <div class="params-container-for" v-for="(item, index) in mobileTreeInfo.params" :key="'index' + index">
          <div class="params-container-left">
            <div class="item">
              <el-input v-model="item.key" placeholder="请输入key值" clearable @change="selectChange"></el-input>
            </div>
            <div class="value">
              <el-select v-model="item.value" placeholder="请选择" @change="selectChange">
                <el-option
                  v-for="item in curComponent.database.fieldList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="params-container-right">
            <i
              class="el-icon-remove remove"
              v-if="mobileTreeInfo.params.length > 1"
              @click="onModifyOption(index, 'remove')"
            ></i>
            <i class="el-icon-plus plus" @click="onModifyOption(index, 'plus')"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="key-item" v-if="!hiddenJump">
      <div class="key-item-label">
        跳转类型:
      </div>
      <el-select style="flex: 1;" v-model="mobileTreeInfo.type" clearable placeholder="请选择跳转方式" @change="selectChange">
        <el-option
          v-for="item in typeList"
          :key="item.value"
          :label="item.name"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div v-if="mobileTreeInfo.type === 1 && !hiddenJump" class="key-item">
      <div class="key-item-label">
        页面uuid:
      </div>
      <el-select style="flex: 1;" v-model="mobileTreeInfo.page_uuid" clearable placeholder="请选择页面" @change="selectChange">
        <el-option
          v-for="item in allPages"
          :key="item.uuid"
          :label="item.name"
          :value="item.uuid">
        </el-option>
      </el-select>
    </div>
    <div v-if="mobileTreeInfo.type === 2 && !hiddenJump" class="key-item">
      <div class="key-item-label">
        路由:
      </div>
      <el-input style="flex: 1;" v-model="mobileTreeInfo.routerPath" placeholder="请输入路由路径" clearable @change="selectChange"></el-input>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Popover } from 'element-ui';
import { dataInterface } from '@/apis/data';
export default {
  components: {
    'el-popover': Popover
  },
  props: {
    hiddenJump: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
    ])
  },
  data() {
    return {
      mobileTreeInfo: {
        checkBoxVisible: false,
        isPage: false,
        type: 1,
        params: [
          {
            key: '',
            value: ''
          }
        ]
      },
      typeList: [
        {
          name: '配置页面',
          value: 1
        },
        {
          name: '开发页面路由',
          value: 2
        },
      ],
      allPages: []
    };
  },
  mounted() {
    this.getAllPages()
    if (
      this.curComponent && 
      this.curComponent.database && 
      this.curComponent.database.mobileTreeInfo && 
      (typeof this.curComponent.database.mobileTreeInfo === 'object')
    ) {
      this.mobileTreeInfo = this.curComponent.database.mobileTreeInfo
    }
    if (!this.mobileTreeInfo.params || !Array.isArray(this.mobileTreeInfo.params)) {
      this.mobileTreeInfo.params = [
        {
          key: '',
          value: ''
        }
      ]
    }
    if (!this.mobileTreeInfo.type) {
      this.mobileTreeInfo.type = 1
    }
    if (!this.mobileTreeInfo.isPage) {
      this.mobileTreeInfo.isPage = false
    }
    this.selectChange()
  },
  methods: {
    selectChange() {
      if (!this.mobileTreeInfo.checkBoxVisible) {
        this.mobileTreeInfo.checkBoxVisible = false
      }
      if (!this.mobileTreeInfo.isPage) {
        this.mobileTreeInfo.isPage = false
      }
      if (this.mobileTreeInfo.page_uuid && this.allPages.length) {
        if (!this.allPages.length) {
          this.$message.error('页面列表未请求成功')
          return
        }
        this.allPages.forEach(element => {
          if (element.uuid === this.mobileTreeInfo.page_uuid) {
            this.mobileTreeInfo.page_id = element.id
          }
        });
      }
      this.$emit('setting', this.mobileTreeInfo)
    },
    onModifyOption(index, type) {
      if (type === 'plus') {
        this.mobileTreeInfo.params.splice(index, 0, {key:'',value: ''})
        this.mobileTreeInfo = JSON.parse(JSON.stringify(this.mobileTreeInfo))
      } else if (type === 'remove') {
        this.mobileTreeInfo.params.splice(index, 1)
        this.mobileTreeInfo = JSON.parse(JSON.stringify(this.mobileTreeInfo))
      }
      this.selectChange()
    },
    /* 请求配置页面列表 */
    getAllPages(){
      const params = {
        object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
        page: 1,
        search: [{field_uuid: "field61bc41f645e17", ruleType: "like", value: "mobile"}],
        field_uuid: "field61bc41f645e17",
        ruleType: "like",
        value: "mobile",
        size: 100000,
        transcode: 0,
        view_uuid: "view61922b1881103",
        __method_name__: "dataList"
      }
      dataInterface(params).then(res=>{
        if(res && +res.data.code === 200){
          const data = res.data.data.data;
          this.allPages = data;
        }
      })
    }
  },
};
</script>
<style lang='less' scoped>
.key-sitting {
  padding-top: 8px;
  .key-item {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    display: flex;
    margin-top: 10px;
    .key-item-label {
      width: 90px;
    }
    .params-container {
      width: 100%;
      height: 100%;
      .params-container-for {
        width: 100%;
        display: flex;
        align-items: center;
        .params-container-left {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            height: 35px;
            margin-bottom: 6px;
          }
        }
        .params-container-right {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
</style>