<!--
 * @Description: 图层树
 * @Author: luocheng
 * @Date: 2021-10-19 14:34:49
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-17 11:25:00
-->
<template>
  <div class="layer-tree" :class="{
		'is-last-item': isLast
	}"
	:style="{
		overflowY: level === 0 ? 'auto' : ''
	}"
	v-if="layerDataForShow && layerDataForShow.length"
	>
		<!-- @contextmenu="onContextMenu(index, item, $event)" -->
    <section class="item" v-for="(item, index) in layerDataForShow" :key="index">
      <div class="item-content"
				@click="onSelect(item)"
				@dblclick="onScreenContainer(item)"
        :class="{
          'active': curComponent && item.id === curComponent.id,
					'is-screen-container': item.type === 'screenContainer'
        }">
        <i class="iconfont" :class="'icon-' + item.icon" @click="item.toggle = !item.toggle"></i>
        <p class="name">{{ item.name || '--' }}</p>
				<i class="el-icon-edit" @click="onContextMenu(index, item, $event)"></i>
        <!-- <el-input class="name" v-model="item.name" placeholder="请输入图层名称"
          v-else
          minlength="1" maxlength="10" size="mini"
          clearable
          @blur="onRename(item)"
        ></el-input>
				<i class="toggle-show" v-if="item.children && item.children.length" @click="onToggleShow(item, index)"
					:class="{
						'el-icon-caret-bottom': item.toggle,
						'el-icon-caret-top': !item.toggle
					}"
				></i> -->
      </div>
      <template v-if="item.children && item.children.length && item.type !== 'screenContainer'">
        <section class="children" v-show="item.toggle" :key="index">
          <LayerTree :layerData="item.children" :level="level + 1"  :isLast="index === layerDataForShow.length - 1"></LayerTree>
        </section>
      </template>
    </section>
    <LayerMenu v-model="showMenu" :style="menuStyle" :modifyComponent="modifyComponent"></LayerMenu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LayerMenu from './LayerMenu';

export default {
  name: 'LayerTree',
  components: {
    LayerMenu
  },
  props: {
    // 图层列表数据
		layerData: {
			type: Array,
			required: true,
			default: () => []
		},
		level: {
			type: Number,
			required: false,
			default: 0
		},
		// 是否最后一个
		isLast: {
			type: Boolean,
			required: false,
			default: false
		}
  },
	computed: {
		...mapState([
			'curComponent' // 当前组件
		]),
	},
  data() {
    return {
      showMenu: false,
      menuStyle: {
        top: 0
      },
			layerDataForShow: [],
			modifyComponent: {},
			childrenList: []
    }
  },
	created() {
		this.layerDataForShow = this.layerData.map(ele => {
			return {
				...ele,
				toggle: true,
				isModify: false
			}
		});
	},
	watch: {
		layerData() {
			this.layerDataForShow = this.layerData.map(ele => {
				return {
					...ele,
					toggle: true,
					isModify: false
				}
			});
		}
	},
  mounted() {
    this.$nextTick(() => {
      // document.addEventListener('click', () => {
      //   this.showMenu = false;
      // });
    });
  },
	methods: {
		/**
		 * @desc: 切换显示
		 */
		onToggleShow(item, index) {
			this.layerDataForShow.splice(index, 1, {
				...item,
				toggle: !item.toggle
			});
		},
		/**
		 * @desc: 点击右击菜单
		 * @param {Number} index 下标
		 * @param {Object} component 图层对象
		 */
		onContextMenu(index, component, e) {
			e.stopPropagation();
			e.preventDefault();
      const target = e && e.target;
      if (!target) return;
			this.childrenList = [];
			// this.getListById(this.layerDataForShow, 'CommonContainer-1653374201866');
			// console.log(this.childrenList, '-------list')
			const childrenCount = this.getSiblingChildrenCount(this.layerDataForShow, component, 0);
      this.menuStyle = {
        // top: index * 30 + target.offsetTop + 10 + 'px'
				// top: e?.clientY + 'px'
				top: (0 + childrenCount) * 30 + (childrenCount - 1) + 10 + 'px'
      };
      this.$store.commit('setCurComponent', {
        component
      });
			this.modifyComponent = component;
      this.showMenu = true;
			return false;
		},
		/**
		 * @desc: 获取兄弟节点的子节点数量
		 * @param {Array} arr 总数据
		 * @param {Number} index 截止下标
		 * @param {Number} level
		 */
		getSiblingChildrenCount(arr, component, defaultCount = 0) {
			if (!arr || !Array.isArray(arr) || !arr.length) return defaultCount;
			let result = defaultCount || 0;
			for (let i = 0; i < arr.length; i++) {
				const item = arr[i];
				if (item.id === component.id) {
					break;
				}
				result++;
				if (item && item.children && Array.isArray(item.children) && item.type !== 'screenContainer') {
					result = this.getSiblingChildrenCount(item.children, component, result);
				}
			}
			return result;
		},
		/**
		 * @desc: 选择组件进行操作
		 * @param {Object} component 组件对象
		 */
		onSelect(component) {
			this.$store.commit('setCurComponent', {
				component
			});
      this.showMenu = false;
		},
		/**
		 * @desc: 双击事件
		 * @param {Object} component 组件对象
		 */
		onScreenContainer(component) {
			if (component.type !== 'screenContainer') return false;
			this.$store.commit('toScreenContainer', component);
		},
		/**
		 * @desc: 重命名
		 * @param {Object} component 组件
		 */
		onRename(component) {
			this.$store.commit('modifyComponent', {
				component,
				containerId: null,
				isModify: true
			});
			this.onModify(component, false);
      this.showMenu = false;
		},
		/**
		 * @desc: 确定输入
		 * @param {Object} item 输入的内容
		 */
		onModify(item, isModify) {
			const index = this.layerDataForShow.findIndex(ele => ele.id === item.id);
			if (index === -1) return;
			this.layerDataForShow.splice(index, 1, {
				...item,
				isModify
			});
		},
		getListById(arr, id, level = 0) {
			arr.forEach((ele, index) => {
				if (ele.id === id) {
					this.childrenList.push({
						...ele,
						positionArr: `level: ${level}, index: ${index}`
					})
				}
				if (ele.children && Array.isArray(ele.children)) {
					this.getListById(ele.children, id, level + 1);
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.layer-tree{
  position: relative;
	height: 100%;
	// overflow: hidden;
	// overflow-y: auto; 
	position: relative;
	&:before{
		content: '';
		position: absolute;
		height: 30px;
		width: 1px;
		left: 0;
		top: 0;
		background: rgba(228, 230, 232, 1);
	}
	&.is-last-item{
		&:before{
			display: none;
		}
	}
	.item {
		min-height: 30px;
		width: 100%;
		.item-content {
      position: relative;
			width: 100%;
			display: flex;
			min-height: 30px;
			border-bottom: 1px solid #f3f5f2;
			cursor: pointer;
			box-sizing: border-box;
			padding: 0 2px;
			border-left: 1px solid rgb(121, 187, 255);
			&.active{
				background: @theme;
				.name{
					color: #fff;
					font-weight: 600;
				}
				.toggle-show{
					color: #fff;
				}
				&.is-screen-container{
					.name,.toggle-show{
						color: #67C23A!important;
						font-weight: bold!important;
					}
				}
				.el-icon-edit{
					color: #fff;
				}
			}
			&.is-screen-container{
				color: #67C23A!important;
				font-weight: bold!important;
			}
			&:hover {
				background: @theme;
				.iconfont {
					color: #fff;
				}
				.name {
					color: #fff;
					:deep(.el-input__inner){
						background: @theme;
						color: #fff;
					}
				}
				.toggle-show{
					color: #fff;
				}
				&.is-screen-container{
					.name,.toggle-show{
						color: #67C23A!important;
						font-weight: bold!important;
					}
				}
			}
			.iconfont {
				font-size: 16px;
				line-height: 30px;
			}
			.name {
				margin-left: 5px;
				height: 100%;
				flex: 1;
				line-height: 30px;
				font-size: 14px;
				box-sizing: border-box;
				padding: 0 5px;
				overflow: hidden;
				text-align: left;
				text-overflow: ellipsis;
				white-space: nowrap;
				cursor: pointer;
				:deep(.el-input__inner){
					border: none;
					cursor: pointer;
				}
			}
			.el-icon-edit{
				line-height: 30px;
				cursor: pointer;
			}
			.toggle-show{
				line-height: 30px;
				color: @theme;
			}
		}
		.children {
			box-sizing: border-box;
			width: 100%;
			padding-left: 15px;
			border-left: 1px dashed rgb(121, 187, 255);
			position: relative;
			&:after{
				position: absolute;
				content: '';
				width: 15px;
				height: 1px;
				background: rgb(121, 187, 255);
				bottom: 0;
				left: 0;
			}
		}
	}
}
</style>