<!--
 * @Description: 筛选项事件配置
 * @Author: luocheng
 * @Date: 2021-10-09 18:03:02
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 14:32:07
-->
<template>
  <div class="filter-event">
    <section class="item" v-for="(item, key) in actionList" :key="key">
      <el-row>
        <el-col :span="8">按钮名称：</el-col>
        <el-col :span="16">{{ item.label }}</el-col>
      </el-row>
      <el-form
        class="event-list"
        v-if="item.eventList && item.eventList.length"
      >
        <el-row
          v-for="(value, index) in item.eventList"
          :key="index"
          class="event-box"
        >
          <!-- 常规事件 -->
          <template v-if="!value.pattern">
            <el-form-item label="事件类型:" label-width="120px">
              <h3 class="title">{{ (value.key || value.type) | filterEvent }}</h3>
            </el-form-item>
            <template v-if="value.actionType === 'eeAction'">
              <!-- 后端事件 -->
              <el-form-item label="操作:" label-width="120px">
                <div class="tag-list">
                  <el-tag>后端事件--【{{ value.eventName }}】</el-tag>
                </div>
              </el-form-item>
            </template>
            <template v-else-if="value.actionType === 'pageAction'">
              <!-- 其他事件 -->
              <div v-for="(effect, eIndex) in value.effects" :key="eIndex">
                <el-form-item label="作用组件:" label-width="120px">
                  <el-select
                  v-if="value.actionType === 'export' "
                    v-model="value.exportTarget"
                    placeholder="请选择作用组件"
                    disabled
                  >
                    <el-option
                      v-for="item in targetList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                  v-else
                    v-model="effect.targets"
                    placeholder="请选择作用组件"
                    multiple
                    disabled
                    
                  >
                    <el-option
                      v-for="item in targetList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="操作:" label-width="120px">
                  <div class="tag-list">
                    <el-tag  v-if="value.actionType === 'export' ">
                      {{ value.exportType | filterExportType }}
                    </el-tag>
                    <el-tag v-else>{{ effect.actions | filterActions }}</el-tag>
                  </div>
                </el-form-item>
              </div>
            </template>
          </template>
          <!-- 特殊事件 -->
          <template v-else-if="value.pattern === 'special'">
            <section v-for="(special, specialIndex) in value.specialEventList" :key="specialIndex">
              <el-form-item label="操作类型:" label-width="120px">
                <h3 class="title">{{ special.actionType | filterActionType }}</h3>
              </el-form-item>
              <template v-if="special.actionType === 'eeAction'">
                <!-- 后端事件 -->
                <el-form-item label="操作:" label-width="120px">
                  <div class="tag-list">
                    <el-tag>后端事件--【{{ special.eventName }}】</el-tag>
                  </div>
                </el-form-item>
              </template>
              <template v-else-if="special.actionType === 'pageAction'">
                <!-- 其他事件 -->
                <div v-for="(effect, eIndex) in special.effects" :key="eIndex">
                  <el-form-item label="作用组件:" label-width="120px">
                    <el-select
                    v-if="special.actionType === 'export' "
                      v-model="special.exportTarget"
                      placeholder="请选择作用组件"
                      disabled
                    >
                      <el-option
                        v-for="item in targetList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-else
                      v-model="effect.targets"
                      placeholder="请选择作用组件"
                      multiple
                      disabled
                    >
                      <el-option
                        v-for="item in targetList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="操作:" label-width="120px">
                    <div class="tag-list">
                      <el-tag  v-if="special.actionType === 'export' ">
                        {{ special.exportType | filterExportType }}
                      </el-tag>
                      <el-tag v-else>{{ effect.actions | filterActions }}</el-tag>
                    </div>
                  </el-form-item>
                </div>
              </template>
            </section>
          </template>
        </el-row>
      </el-form>
      <el-row>
        <el-button type="success" size="mini" @click="onShowSelect(item)">绑定事件</el-button>
      </el-row>
    </section>
    <!-- 选择事件 -->
		<el-drawer
			title="选择事件"
			:visible.sync="showSelect"
			direction="ltr"
			custom-class="selct-drawer"
			destroy-on-close
			ref="drawer"
			:wrapperClosable="false"
			size="400px"
		>
			<SelectEvent @close="showSelect = false" :type="'tabEvent'" :partData="partData"></SelectEvent>
		</el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { flatTree, unique } from '@/utils/tools';
import { defaultList, actionList } from "../../config";
import SelectEvent from '../../SelectEvent';
import { Tag } from 'element-ui';

export default {
  name: 'FilterEvent',
  components: {
    SelectEvent,
    'el-tag': Tag
  },
  data() {
    return {
      showSelect: false,
      partData: null,
      actionList: []
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData',
      'sContainer',
      'originComponentData'
    ]),
    // 组件对象
    targetList() {
			let result = [];
      if (this.sContainer) {
        result = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
      }
			return unique(result.concat(flatTree(this.componentData, 'children', {
					label: 'name',
					value: 'id'
				}, [])))
    },
    // 操作配置
    actionConfig() {
      return this.curComponent && this.curComponent.actionConfig;
    }
  },
  watch: {
    actionConfig: {
      handler() {
        this.actionList = [];
        for (let i = 0; i < this.actionConfig.length; i++) {
          const item = this.actionConfig[i];
          if (item.type === 'search' || item.type === 'reset') {
            continue;
          }
          if (item.show) {
            this.actionList.push(item);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  filters: {
    // 事件名称过滤器
    filterEvent(value) {
      for (let i = 0; i < defaultList.length; i++) {
        if (defaultList[i].value === value) {
          return defaultList[i].label;
        }
      }
      return value;
    },
    // 操作过滤器
    filterActions(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        const item = actionList.find(ele => ele.value === value[i]);
        if (item) {
          result.push(item.label);
        }
      }
      return result.join();
    },
    // 导出类型
    filterExportType(val) {
      if (val === 'all') {
        return '导出全部'
      }
      if (val === 'targetPage') {
        return '导出本页'
      }
      if (val === 'checked') {
        return '导出选中'
      }
      return val;
    },
    // 操作类型
    filterActionType(type) {
      if (type === 'pageAction') {
        return '页面事件'
      }
      if (type === 'jumpPage') {
        return '跳转页面'
      }
      if (type === 'export') {
        return '导出数据'
      }
      if (type === 'eeAction') {
        return '后端事件'
      }
      if (type === 'componentBehavior') {
        return '触发组件行为'
      }
      return type;
    }
  },
  methods: {
    /**
		 * @desc: 显示配置事件表单
		 * @param {Object} tabData 按钮配置数据
		 */
		onShowSelect(tabData) {
			if (!tabData) return;
			this.partData = tabData;
			this.showSelect = true;
		}
  }
}
</script>

<style lang="less" scoped>
</style>