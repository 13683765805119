<!--
 * @Description: 选择图标
 * @Author: luocheng
 * @Date: 2021-09-06 11:59:26
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-25 10:56:51
-->
<template>
  <div class="select-icon">
    <section class="current">
      <p>当前选择</p>
      <el-input v-model="value" disabled placeholder="请选择图标"></el-input>
      <i class="el-icon-delete remove" @click="onSelect('')" v-if="value"></i>
    </section>
    <article class="content">
      <ul class="icon-list">
        <li class="icon-item" v-for="item in elementIcons" :key="item"
          @click="onSelect(item)"
          :class="{
            'active': item === value
          }"
        >
          <i :class="item" class="icon"></i>
          <p class="label">{{ item }}</p>
        </li>
      </ul>
    </article>
    <footer class="footer">
      <el-button @click="onClose">关闭</el-button>
    </footer>
  </div>
</template>

<script>
import { elementIcons } from '@/utils/icons';

export default {
  name: "SelectIcon",
  props: {
    // 图标值
    value: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      elementIcons
    }
  },
  methods: {
    /**
     * @desc: 选择icon
     * @param {String} icon 图标类名
     */
    onSelect(icon) {
      this.selectIcon = icon;
      this.$emit('input', icon);
    },
    /**
     * @desc: 关闭
     */
    onClose() {
      this.$emit('closeIcon');
    }
  }
}
</script>

<style lang="less" scoped>
.select-icon{
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  .current{
    display: flex;
    margin-bottom: 10px;
    padding: 0 10px;
    p{
      width: 100px;
      text-align: center;
      line-height: 32px;
    }
    .remove{
      line-height: 32px;
      padding: 0 5px;
      color: @dangerColor;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .content{
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px 20px;
    margin-right: -25px;
    
    .icon-list{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #eaeefb;
      border-radius: 4px;
      .icon-item{
        text-align: center;
        height: 120px;
        width: 33%;
        line-height: 120px;
        color: #666;
        font-size: 13px;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: -1px;
        box-sizing: border-box;
        padding: 20px 10px;
        cursor: pointer;
        &:nth-of-type(3n){
          border-right: 0;
        }
        &.active{
          border-right: 1px solid @theme;
          border-bottom: 1px solid @theme;
          background: @theme;
          color: #fff;
          font-weight: 600;
          .icon{
            color: #fff;
          }
          .label{
            color: #fff;
          }
        }
        .icon{
          display: block;
          font-size: 32px;
          margin-bottom: 15px;
          color: #606266;
          transition: color .15s linear;
        }
        .label{
          line-height: 20px;
          font-size: 14px;
          color: #606266;
        }
      }
    }
  }
  .footer{
    box-sizing: border-box;
    border-top: 1px solid @borderColor;
    padding: 20px;
  }
}
</style>