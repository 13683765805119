<!--
 * @Description: 树形图
 * @Author: luocheng
 * @Date: 2021-12-03 18:02:45
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 16:31:17
-->
<template>
  <div class="tree-config">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="树形图操作配置" name="1">
        <ActionConfig></ActionConfig>
      </el-collapse-item>
      <el-collapse-item title="图标配置" name="2">
        <IconConfig></IconConfig>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActionConfig from './ActionConfig';
import IconConfig from './iconConfig/Index';

export default {
  name: 'TabsConfig',
  components: {
    ActionConfig,
    IconConfig
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  data() {
    return {
      activeNames: '',
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.tree-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
    padding-bottom: 10px;
  }
  .tab-origin{
    display: flex;
    padding-bottom: 10px;
    p{
      width: 60px;
    }
    .origin-content{
      flex: 1;
      text-align: left;
      .el-radio{
        margin-bottom: 10px;
      }
    }
  }
  .el-collapse{
    flex: 1;
    overflow-y: auto;
  }
}
</style>