<!--
 * @Description: 操作
 * @Author: luocheng
 * @Date: 2021-09-09 10:59:07
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-08-16 17:54:20
-->
<template>
  <section class="action-item">
    <header class="header">
      <p class="name">{{ value.name }}</p>
      <a class="toggle" @click="onToggleShow">{{ showContent ? '隐藏' : '显示' }}</a>
    </header>
    <div v-show="showContent">
      <el-form-item label="是否使用">
        <el-checkbox v-model="value.use" @change="emitData">
          {{ value.use ? '使用中' : '禁用中' }}
        </el-checkbox>
      </el-form-item>
      <el-form-item label="KEY">
        <el-input disabled v-model="keyWord"></el-input>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="value.name" placeholder="请输入名称" @input="emitData"></el-input>
      </el-form-item>
      <!-- 依赖值判断操作 -->
      <el-form-item label="启用依赖">
        <el-switch v-model="value.useDepend"></el-switch>
      </el-form-item>
      <section class="login-rule" v-if="value.useDepend">
        <header class="rules-header">
          <p style="text-align: left;padding-bottom: 10px">依赖条件</p>
          <i :class="[showRules ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" @click="onToggleRule"></i>
        </header>
        <DependLogic v-model="value.dependLogic" :tableType="tableType" v-show="showRules"></DependLogic>
      </section>
      <!-- 架构限制 -->
      <el-form-item label="架构限制"  v-if="value.archiLimit">
        <SetArchiLimit v-model="value.archiLimit"></SetArchiLimit>
      </el-form-item>
      <!-- 权限 -->
      <el-form-item label="权限设置"  v-if="value.permission">
        <SetPermissions v-model="value.permission"></SetPermissions>
      </el-form-item>
      <!-- UI判断逻辑 -->
			<UILogic v-model="value.UILogic"></UILogic>
    </div>
  </section>
</template>

<script>
import { Switch } from 'element-ui';
import DependLogic from '@/components/tableConfig/DependLogic';
import SetArchiLimit from '@/components/common/SetArchiLimit';
import SetPermissions from '@/components/common/SetPermissions';
import UILogic from '@/components/common/UILogic';

export default {
  name: 'TableActionItem',
  components: {
    'el-switch': Switch,
    DependLogic,
    SetArchiLimit,
    SetPermissions,
    UILogic
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true
    },
    // 配置表类型
    tableType: {
      type: String,
      default: '',
      required: false
    },
    keyWord: {
      type: [String,Number],
      default: '',
      required: false
    }
  },
  data() {
    return {
			// 按钮类型列表
			btnTypes: [
				{
					value: '',
					label: '默认'
				},
				{
					value: 'text',
					label: '文本'
				},
				{
					value: 'primary',
					label: '主要'
				},
				{
					value: 'success',
					label: '成功'
				},
				{
					value: 'warning',
					label: '警告'
				},
				{
					value: 'danger',
					label: '危险'
				},
				{
					value: 'info',
					label: '信息'
				}
			],
      // 按钮尺寸
      btnSizes: [
        {
					value: '',
					label: '默认'
				},
        {
					value: 'medium',
					label: '中等'
				},
        {
					value: 'small',
					label: '小型'
				},
        {
					value: 'mini',
					label: '超小'
				}
      ],
      // 预定义颜色
			predefineColors: [
          '#ff4500',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585',
					'#000000',
					'#ffffff'
			],
      // 显示配置
      showContent: false,
      // 显示依赖
      showRules: false
    }
  },
  methods: {
    onToggleShow() {
      this.showContent = !this.showContent;
    },
    /**
     * @desc: 提交数据 
     */
    emitData() {
      this.$emit('input', this.value);
    },
    /**
     * @desc: 切换依赖显示隐藏
     */
    onToggleRule() {
      this.showRules = !this.showRules;
    }
  }
}
</script>

<style lang="less" scoped>
.action-item{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 5px 0;
  padding-left: 5px;
  .header{
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    .name{
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-family: PingFang SC;
    }
    .toggle{
      color: @theme;
    }
  }
  :deep(.el-form-item) { 
    display: flex;
    margin-right: 0;
    box-sizing: border-box;
    padding: 0 5px;
    margin-bottom: 10px;
    &.color-item{
      .el-form-item__content{
        display: flex;
      }
      .el-input{
        margin-left: 5px;
      }
    }
    .el-form-item__label{
      width: 80px;
    }
    .el-form-item__content{
      flex: 1;
      text-align: left;
    }
  }
  .login-rule{
    border: 1px solid #f2f3f5;
    box-sizing: border-box;
    width: 98%;
    margin-bottom: 10px;
    padding: 10px 10px 0 10px;
    border-radius: 6px;
  }
  .rules-header{
    display: flex;
    p{
      flex: 1;
      text-indent: 20px;
      line-height: 22px;
    }
    i{
      font-weight: bold;
      color: @theme;
      font-size: 16px;
      padding: 0 5px;
    }
  }
}
</style>