<!--
 * @Description: 操作配置
 * @Author: luocheng
 * @Date: 2021-09-30 11:46:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-10-28 16:44:34
-->
<template>
  <div class="action-config">
    <header class="header">
      <span class="line"></span>
      <p class="item-title">基础配置</p>
      <el-button type="warning" size="mini" @click="onReset">重置</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确认配置</el-button>
      <a href="javascript:;" class="header-btn" @click="toggleShow = !toggleShow">
        {{ toggleShow ? '收起' : '展开' }}
      </a>
    </header>
    <div class="configs" v-show="toggleShow">
      <el-form :model="bindConfig">
				<el-form-item label="绑定类型" label-width="85px">
					<el-select v-model="bindConfig.type" placeholder="请选择">
						<el-option v-for="(item,index) in typeData" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
        </el-form-item>
				<el-form-item v-if="bindConfig.type === 'bim' || bindConfig.type === 'gis'" label="模型AppId" label-width="85px">
					<el-input v-model="bindConfig.appId" placeholder="请输入" />
				</el-form-item>
				<el-form-item v-if="bindConfig.type === 'bim' || bindConfig.type === 'gis'" label="模型Secret" label-width="85px">
					<el-input v-model="bindConfig.appSecret" placeholder="请输入" />
				</el-form-item>
				<el-form-item label="显示按钮" label-width="85px">
					<el-switch	v-model="bindConfig.isBtn"></el-switch>
				</el-form-item>
      </el-form>
    </div>
		<RelationSource	></RelationSource>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RelationSource from '@/components/dialogConfig/RelationSource.vue'
export default {
  name: 'BindComponentConfig',
	components: {
		RelationSource
	},
  data() {
    return {
      toggleShow: true,
      bindConfig: {
				type: 'bim',
				isBtn: true,
				appSecret:'3EYcdAjbnWxP',
				appId:'bdafce12a2d9465d89821ec14214a382'
			},
			typeData:[
				{label:'BIM模型',value:'bim'},
				{label:'GIS模型',value:'gis'},
				{label:'经纬度',value:'latlng'},
				{label:'图纸',value:'drawing'},
			]
    }
  },
  computed: {
    ...mapState([
      'curComponent'
    ])
  },
  created() {
    this.bindConfig = this.curComponent && this.curComponent.bindConfig;
  },
  methods: {
		onReset(){
			this.bindConfig = {
				type:'bim',
				isBtn:true,
				appSecret:'3EYcdAjbnWxP',
				appId:'bdafce12a2d9465d89821ec14214a382'
			}
		},
		/**
		 * @desc: 保存
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					bindConfig: this.bindConfig
				},
				containerId: null,
				isModify: true
			});
		},
    
  }
}
</script>

<style lang="less" scoped>
.action-config{
  padding: 10px 0;
  .header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
    .item-title{
      flex: 1;
      text-align: left;
      padding-left: 10px;
      line-height: 28px;
    }
    .header-btn{
      margin-left: 5px;
      color: @theme;
      line-height: 28px;
    }
  }
  .configs{
    .el-form{
      .el-form-item{
        margin-bottom: 10px;
      }
    }
  }
}
</style>