<!--
 * @Description: 值映射
 * @Author: luocheng
 * @Date: 2022-10-08 15:32:39
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-11 17:18:11
-->
<template>
  <div class="value-map">
    <el-form :model="curComponent" label-width="100px">
      <el-form-item label="数据对象">
        <SelectObjectUUID v-model="configForm.objectUUID" @input="onObjectUUID"></SelectObjectUUID>
      </el-form-item>
			<el-form-item label="映射Code">
				<span slot="label">
          <el-popover
            placement="top-start"
            title="提示"
            width="200"
            :center="true"
            trigger="hover"
            content="在循环容器中使用UUID=>code的映射取值code">
            <span slot="reference">
              <span>映射Code</span>
              <i class="el-icon-question"></i>
            </span>
          </el-popover>
        </span>
				<!-- <el-input v-model="configForm.mapCode" placeholder="请设置映射Code" clearable></el-input> -->
        <el-select v-model="configForm.bindField"
          placeholder="请选择字段"
          filterable
          clearable
          v-loading="loading"
        >
          <el-option
            v-for="item in fieldList"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="onSave">保存</el-button>
      </el-form-item>
		</el-form>
  </div>
</template>

<script>
import { Popover } from 'element-ui';
import { mapState } from 'vuex';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import { getFields } from '@/apis/data';

export default {
  name: 'ValueMap',
	components: {
		'el-popover': Popover,
    SelectObjectUUID
	},
  data() {
    return {
      loading: false,
      fieldList: [],
      configForm: {
        objectUUID: '',
        bindField: ''
      }
    }
  },
  computed: {
		...mapState([
			'curComponent' // 当前组件
		])
	},
  created() {
    this.configForm = this.curComponent?.loopMapConfig || {
      objectUUID: '',
      bindField: ''
    };
    if (this.configForm.objectUUID) {
      this.onObjectUUID(this.configForm.objectUUID);
    }
  },
  methods: {
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					loopMapConfig: this.configForm
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 选择对象UUID
     * @param {String} object_uuid
     */
    onObjectUUID(object_uuid) {
      this.configForm.objectUUID = object_uuid;
      // 获取字段列表
      this.fieldList = [];
      if (!object_uuid) return;
      this.loading = true;
      getFields({
        object_uuid,
        size: 200,
        page: 1
      }).then(res => {
        this.fieldList = res?.data?.data?.data || [];
        this.loading = false;
      }).catch(err => {
        console.log(err, '---22---');
        this.loading = false;
      });
    }
  }
}
</script>

<style lang="less" scoped>
.value-map {
  width: 100%;
	.el-icon-question {
		color: @warnColor;
		z-index: 3000;
	}
}
</style>