<!--
 * @Description: 选择数据对象
 * @Author: luocheng
 * @Date: 2021-09-29 18:51:47
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-18 18:45:28
-->
<template>
  <div class="select-ee-event">
    <el-select
      v-model="uuid"
      filterable
      :placeholder="placeholder"
      :loading="eventLoading"
      @change="onEmit"
      clearable
    >
      <el-option
        v-for="item in eventList"
        :key="item.id"
        :label="item.name"
        :value="item.event"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { dataInterface } from "@/apis/data/index";

export default {
  name: "SelectEEEvent",
  props: {
    value: {
      type: String,
      default: "",
    },
    // 说明
    placeholder: {
      type: String,
      required: false,
      default: "请选择",
    },
    // 对象UUID
    objectUUID: {
      type: String,
      required: true,
      default: "",
    }
  },
  data() {
    return {
      uuid: "",
      eventLoading: false,
      eventList: [],
    };
  },
  created() {
    this.uuid = this.value || '';
    this.getEventList('');
  },
  watch: {
    objectUUID() {
      this.eventList = [];
      if (this.objectUUID) {
        this.getEventList('');
      }
    }
  },
  methods: {
    /**
     * @desc: 远程搜索视图
     * api/metadata/events
     * api/metadata/blueprintEvents
     */
    getEventList() {
      this.eventLoading = true;
      dataInterface({
        object_uuid: this.objectUUID,
        page: 1,
        size: 100
      }, 'api/metadata/blueprintEvents', 'GET')
        .then((res) => {
          if (res && res.data && res.data.data) {
            const data = res.data.data;
            this.eventList = data.data || data || [];
          }
          this.eventLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.eventLoading = false;
        });
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit("input", this.uuid);
      this.$emit("update", this.uuid);
    },
  },
};
</script>

<style>
</style>