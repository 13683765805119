<!--
 * @Description: 轮播图配置
 * @Author: luocheng
 * @Date: 2022-07-19 11:42:10
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 15:56:27
-->
<template>
  <div class="swiper-config">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <section class="content-type">
      <el-radio-group v-model="contentConfig.valueType">
        <el-radio label="fixed">固定内容</el-radio>
        <el-radio label="database">动态内容</el-radio>
      </el-radio-group>
    </section>
    <article class="config-main">
      <!-- 固定内容 -->
      <template v-if="contentConfig.valueType === 'fixed'">
        <FixedConfig></FixedConfig>
      </template>
      <template v-else>
        <DatabaseConfig></DatabaseConfig>
      </template>
    </article>
  </div>
</template>

<script>
import FixedConfig from './fixed/Index';
import DatabaseConfig from './database/Index';
import { mapState } from 'vuex';

export default {
  name: 'SwiperConfig',
  components: {
    FixedConfig,
    DatabaseConfig
  },
  data() {
    return {
      contentConfig: {
        valueType: 'fixed', // 值类型  fixed 固定值，database 数仓
				fixedConfig: [
					// {
					// 	img: '', // 图片链接
					// 	text: '', // 描述文本
					// 	href: '' // 跳转链接
					// }
				],
				databaseConfig: {
					objectUUID: '',
					viewUUID: ''
				}
      }
    }
  },
  computed: {
    ...mapState(['curComponent']),
  },
  created() {
    this.contentConfig = this.curComponent?.swiperConfig ?? this.contentConfig;
  }
}
</script>

<style lang="less" scoped>
.swiper-config{
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
    padding-bottom: 10px;
  }
  .content-type{
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #f2f3f5;
  }
  .config-main{
    width: 100%;
    flex: 1;
    overflow: hidden;
  }
}
</style>