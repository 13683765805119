<!--
 * @Description: 流程配置
 * @Author: luocheng
 * @Date: 2021-12-09 11:43:31
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-09 12:05:09
-->
<template>
	<div class="flow-config">
		<h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="绑定表单" name="1">
        <section class="bind-form">
          <DatabaseConfig :configData="flowConfig" :modifyKey="'flowConfig'"></DatabaseConfig>
        </section>
      </el-collapse-item>
    </el-collapse>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import DatabaseConfig from '@/components/common/DatabaseConfig.vue';

export default {
	name: 'FlowConfig',
  components: {
    DatabaseConfig
  },
  data() {
    return {
      activeNames: '1',
      // configData: {
      //   object_uuid: '',
			// 	view_uuid: '',
			// 	form_uuid: ''
      // }
    }
  },
	computed: {
		...mapState(['curComponent']),
    flowConfig() {
      return this.curComponent && this.curComponent.flowConfig;
    }
	}
};
</script>

<style lang="less" scoped>
.flow-config{
  .title{
    text-align: left;
    padding-bottom: 10px;
  }
}
</style>