<!--
 * @Description: 配置参数
 * @Author: luocheng
 * @Date: 2022-05-11 17:11:24
 * @LastEditors: zx
 * @LastEditTime: 2022-12-07 09:05:25
-->
<template>
	<div class="config-params event-config-params">
		<el-form
			:model="configForm"
			class="config-list"
			label-width="120px"
			ref="configForm"
			:rules="rules"
			:class="{
				'single-use': !showClose
			}"
		>
			<section class="config-box" v-for="(item, index) in configForm.configList" :key="index">
				<ul class="box-content">
					<el-form-item class="item" label="参数名：">
						<el-input v-model="item.key" placeholder="请输入参数名"></el-input>
					</el-form-item>
					<el-form-item class="item" label="是否必须：">
						<el-switch v-model="item.isRequired"></el-switch>
					</el-form-item>
					<el-form-item label="参数来源方式：">
						<el-select v-model="item.sourceType" placeholder="请获取参数来源方式">
							<el-option v-for="option in types" :key="option.value" :label="option.label" :value="option.value">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- 系统参数 -->
					<template v-if="item.sourceType === 'system'">
						<el-form-item label="系统对象">
							<el-select
								v-model="item.systemKey"
								placeholder="请选择系统对象"
								clearable
								@change="onSystemObject(index, item.systemKey)"
							>
								<el-option label="用户信息" value="userInfo"></el-option>
								<el-option label="当前架构" value="targetArchi"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="取值Code" v-if="item.systemKey">
							<el-select v-model="item.systemCode" placeholder="请选择取值对象">
								<el-option v-for="(value, key) in item.systemObject" :key="key" :label="key" :value="key"></el-option>
							</el-select>
						</el-form-item>
					</template>
					<!-- URL参数 -->
					<template v-else-if="item.sourceType === 'url'">
						<el-form-item class="item" label="链接参数key：">
							<el-input v-model="item.urlParamsKey" placeholder="请输入链接参数key"></el-input>
						</el-form-item>
					</template>
					<!-- 固定参数 -->
					<template v-else-if="item.sourceType === 'fixed'">
						<el-form-item label="固定参数值：">
							<el-input class="fixed-value" v-model="item.fixedValue" placeholder="请输入参数值" clearable></el-input>
						</el-form-item>
					</template>
					<!-- 状态 -->
					<template v-else-if="item.sourceType === 'pageStatus'">
						<el-form-item label="状态字段：">
							<el-input class="fixed-value" v-model="item.statusCode" placeholder="请输入状态字段" clearable></el-input>
						</el-form-item>
					</template>
					<!-- 组件来源 -->
					<template v-else>
						<el-form-item
							class="item"
							label="来源组件："
							:prop="`configList[${index}].componentId`"
							:rules="rules.componentId"
						>
							<el-select
								v-model="item.componentId"
								placeholder="请选择来源组件"
								@change="onComponent(index, item.componentId)"
							>
								<el-option
									:label="comItem.name"
									:value="comItem.id"
									v-for="comItem in componentList"
									:key="comItem.id"
								></el-option>
							</el-select>
						</el-form-item>
						<!-- item.componentId.indexOf('CommonForm') === -1 ||  -->
						<!-- 特殊取值（表格多选，或暴露值为数据列表情况） -->
						<el-form-item label="取值类型">
							<el-select v-model="item.comValueType" placeholder="请选择取值类型">
								<el-option label="暴露值(一般为对象)" value=""></el-option>
								<el-option label="当前选中（列表）" value="selectList"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item
							class="item"
							label="参数字段："
							:prop="`configList[${index}].paramKey`"
							:rules="rules.paramKey"
							v-if="item.key !== 'search' && !item.comValueType"
						>
							<el-select v-model="item.paramKey" filterable placeholder="请选择参数字段" :disabled="!item.componentId">
								<el-option
									:label="keyItem.name + '【' + keyItem.uuid + '】'"
									:value="keyItem.uuid"
									v-for="keyItem in item.fieldList"
									:key="keyItem.uuid"
								></el-option>
							</el-select>
						</el-form-item>
					</template>
				</ul>
				<i class="iconfont iconwangpan-shanchu1x" @click="onRemove(item, index)"></i>
			</section>
			<div class="plus-btns">
				<el-button type="success" class="plus-btn" @click="onPlus">新增参数</el-button>
				<el-button type="primary" v-if="!showClose" class="plus-btn" @click="onConfirm">确认参数</el-button>
			</div>
		</el-form>
		<footer class="action" v-if="showClose">
			<el-button type="primary" @click="onConfirm">确认</el-button>
			<el-button @click="onClose">取消</el-button>
		</footer>
	</div>
</template>

<script>
import { flatTree, unique } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'ConfigParams',
	props: {
		// 默认配置
		params: {
			type: Array,
			required: false,
			default: () => []
		},
		showClose: {
			type: Boolean,
			required: false,
			default: true
		},
		// 可选参数类型
		types: {
			type: Array,
			required: false,
			default: () => [
				{
					label: '内部组件',
					value: ''
				},
				{
					label: 'URL参数',
					value: 'url'
				},
				{
					label: '系统参数',
					value: 'system'
				},
				{
					label: '固定参数',
					value: 'fixed'
				},
				{
					label: '页面状态',
					value: 'pageStatus'
				}
			]
		}
	},
	data() {
		return {
			configForm: {
				configList: []
			},
			// 可选组件列表
			componentList: [],
			// 验证规则
			rules: {
				componentId: [{ required: true, message: '请选择来源组件', trigger: 'change' }],
				paramKey: [{ required: true, message: '请求参数字段', trigger: 'change' }]
			}
		};
	},
	computed: {
		...mapState(['componentData', 'originComponentData', 'sContainer'])
	},
	created() {
		this.configForm = {
			configList: this.params || []
		};
		if (this.sContainer) {
			this.componentList = flatTree(this.originComponentData || [], 'children', null, []);
		}
		this.componentList = unique(this.componentList.concat(flatTree(this.componentData, 'children', null, [])));
	},
	methods: {
		/**
		 * @desc: 提交
		 */
		onConfirm() {
			this.$refs.configForm.validate((valid) => {
				if (!valid) return;
				const { configList } = this.configForm;
				this.$emit('update', configList);
				this.showConfig = false;
			});
		},
		/**
		 * @desc: 关闭
		 */
		onClose() {
			this.$emit('close');
		},
		/**
		 * @desc: 移除
		 * @param {Object} item 参数对象
		 * @param {Number} index 需要移除的元素下标
		 */
		onRemove(item, index) {
			this.$confirm(`是否确认删除【${item.key}】参数?`)
				.then(() => {
					this.configForm.configList.splice(index, 1);
				})
				.catch(() => {});
		},
		/**
		 * @desc: 选择组件后获取对应的参数字段
		 * @param {Number} index 当前操作下标
		 * @param {Index} componentId 组件ID
		 */
		onComponent(index, componentId) {
			let fieldList = [];
			if (componentId) {
				const component = this.componentList.find((ele) => ele.id === componentId);
				// 特殊的参数值 分页器
				if (component && component.component === 'CommonPagination') {
					fieldList = [
						{
							uuid: 'page',
							name: '页码'
						},
						{
							uuid: 'size',
							name: '每页条数'
						}
					];
				} else if (component && (component.component === 'CommonTabs' || component.component === 'CommonSelect')) {
					fieldList = [
						{
							uuid: 'label',
							name: '说明文字'
						},
						{
							uuid: 'value',
							name: '值'
						}
					];
					// 暴露其余字段@蒲亚军
					const { metadata = {} } = component;
					for (let uuid in metadata) {
						fieldList.push({
							uuid,
							name: metadata[uuid] 
						});
					}
				} else if (component.component === 'CommonText') {
					fieldList = [
						{
							uuid: 'value',
							name: '绑定值'
						}
					];
				} else if (component?.component === 'AIOTTabs') {
					// 模型显示Tab
					fieldList = [
						{
							uuid: 'checked',
							name: '当前选中'
						}
					];
				} else if(component?.component === 'ConsoleList') {
					//中控台列表
					if(component?.fieldConfig.length){
						fieldList = component.fieldConfig.map( item => {
							this.$set(item,'name',item.label)
							return item
						})
					}else{
						fieldList = component?.fieldList || [];
					}
					

				} else {
					fieldList = component?.database?.fieldList || [];
				}
			}
			this.configForm.configList.splice(index, 1, {
				...this.configForm.configList[index],
				fieldList
			});
		},
		/**
		 * @desc: 选择系统对象
		 */
		onSystemObject(index, systemKey) {
			let systemObject = {};
			if (systemKey === 'userInfo') {
				systemObject = this.$GetUserInfo();
			} else if (systemKey === 'targetArchi') {
				systemObject = this.$GetTargetArchi();
			}
			this.configForm.configList.splice(index, 1, {
				...this.configForm.configList[index],
				systemCode: '',
				systemObject
			});
		},
		/**
		 * @desc: 新增
		 */
		onPlus() {
			this.$refs.configForm.validate((valid) => {
				if (!valid) return;
				this.configForm.configList.push({
					componentId: '', // 组件ID
					sourceType: '',
					urlParamsKey: '',
					isRequired: false,
					key: '', // 字段名称(发送给后端的字段名)
					comValueType: '', // 取值类型
					paramKey: '', // 请求参数字段key
					fixedValue: '', // 固定参数值
					statusCode: ''
				});
			});
		}
	}
};
</script>

<style lang="less" scoped>
.config-params {
	width: 100%;
	.config-list {
		width: 100%;
		box-sizing: border-box;
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		height: calc(97vh - 165px);
		overflow: hidden;
		border-bottom: 1px solid #f2f3f5;
		overflow-y: auto;
		&.single-use {
			height: auto;
			.plus-btns {
				display: flex;
				padding-bottom: 10px;
				.el-button {
					flex: 1;
				}
			}
		}
		.config-box {
			border: 1px solid @borderColor;
			box-sizing: border-box;
			padding: 10px 5px;
			border-radius: 6px;
			margin-bottom: 10px;
			display: flex;
			cursor: pointer;
			&:hover {
				border: 1px solid @theme;
				background: #fefefe;
			}
			.box-content {
				flex: 1;
				.item {
					margin-bottom: 15px;
				}
			}
			.iconfont {
				margin-left: 10px;
				color: @dangerColor;
				font-size: 18px;
				line-height: 115px;
			}
		}
	}
	.el-input,
	.el-select {
		width: 220px;
	}
	.action {
		padding: 30px 0;
	}
}
</style>
