<!--
 * @Description: 表格操作
 * @Author: luocheng
 * @Date: 2021-09-09 10:29:08
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-14 10:06:28
-->
<template>
	<div class="table-action">
		<el-form label-width="80px" :inline="true">
      <header class="header">
        <h4>操作栏</h4>
        <el-button type="primary" size="mini" @click="onConfirm">确认配置</el-button>
        <el-button type="text" @click="showAction = !showAction">
          {{ showAction ? '收起' : '展开' }}
        </el-button>
      </header>
      <el-form-item label="是否显示操作" label-width="120px">
        <el-switch v-model="value.needAction"></el-switch>
      </el-form-item>
			<el-form-item label="宽度" label-width="120px">
        <el-input v-model="value.width" placeholder="请输入宽度"></el-input>
      </el-form-item>
			<section v-for="(item, key) in value" :key="key" v-show="showAction" class="item-box">
				<template v-if="typeof item === 'object'">
					<TableActionItem v-model="value[key]"></TableActionItem>
				</template>
			</section>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import TableActionItem from './TableActionItem';

export default {
	name: 'TableAction',
	components: {
		TableActionItem
	},
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {
          needAction: false,
          configs: []
        }
      }
    }
  },
	data() {
		return {
      // 是否显示
      showAction: true,
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
  created() {
  },
  methods: {
    /**
     * @desc: 确认配置
     */
    onConfirm() {
			this.$emit('input', this.value);
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					statusConfig: {
						...this.curComponent.statusConfig,
						action: this.value
					}
				},
				containerId: null,
				isModify: true
			});
			this.$store.commit('setCurComponent', {
				component: {
					...this.curComponent,
					statusConfig: {
						...this.curComponent.statusConfig,
						action: this.value
					}
				}
			});
    }
  }
};
</script>

<style lang="less" scoped>
.table-action {
	width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  .header{
    display: flex;
    h4{
      flex: 1;
      text-align: left;
      color: @minorColor;
      line-height: 32px;
    }
  }
  :deep(.el-form-item) { 
    display: flex;
		padding-left: 0!important;
  }
}
</style>
