<!--
 * @Description: 关联关系配置
 * @Author: luocheng
 * @Date: 2021-09-17 16:27:38
 * @LastEditors: zx
 * @LastEditTime: 2022-10-09 09:04:22
-->
<template>
	<div class="select-relation" v-if="curComponent">
    <div class="relation-component">
      <p class=" name">关联组件</p>
      <el-select v-model="relationComponent" @change="onRelationComponent" class="value-box">
        <el-option v-for="container in containers" :key="container.id" :label="container.name" :value="container.id"></el-option>
      </el-select>
    </div>
    <div v-if="relationComponent && curComponent.database && curComponent.database.objectData">
      <h4 class="title">选择关联关系</h4>
      <div class="value-box" v-if="curComponent.database && curComponent.database.relationData">
        <p>
          <span class="label">名称:</span>
          <el-input v-model="curComponent.database.relationData.name" disabled></el-input>
        </p>
        <p>
          <span class="label">uuid:</span>
          <el-input v-model="curComponent.database.relationData.uuid" disabled>
            <template slot="prefix">
              <MetaTraceIcon method="ShowViewForm" :uuid="curComponent.database.relationData.uuid" style="line-height: 36px" />
            </template>
          </el-input>
        </p>
      </div>
      <el-button type="primary" @click="onSelectList">选择关联关系</el-button>
      <el-button type="danger" @click="onRemoveObject"
        v-show="curComponent.database && curComponent.database.objectData">
        移除
      </el-button>
    </div>
    <!-- 选择关联关系 -->
		<el-drawer title="关联关系" :visible.sync="showList" :size="400" :direction="'ltr'" :destroy-on-close="true">
			<article class="database-box">
				<el-table
          ref="objectTable"
					:data="relationList"
					border
					center
					style="width: 100%;"
					height="calc(97vh - 200px)"
					highlight-current-row
					@current-change="onCurrentChange"
				>
					<el-table-column prop="id" label="ID" width="180"> </el-table-column>
					<el-table-column prop="name" label="名称">
            <template slot-scope="scope">
              <MetaTraceIcon method="ShowRelationDetail" :uuid="scope.row.uuid" />
              <span style="margin-left:10px;">{{scope.row.name}}</span>
            </template>
          </el-table-column>
					<el-table-column prop="updated_at" label="创建时间"> </el-table-column>
					<!-- <el-table-column prop="remark" label="说明" width="180"> </el-table-column> -->
				</el-table>
				<el-pagination
					@size-change="onSize"
					@current-change="onPage"
					:current-page="+pager.page"
					:page-sizes="[15, 20, 50, 100, 200]"
					:page-size="+pager.size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="+pager.total"
				>
				</el-pagination>
			</article>
      <footer class="action">
        <el-button type="primary" @click="onConfirm">确认</el-button>
        <el-button @click="showList = false">取消</el-button>
      </footer>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getRelations } from '@/apis/data/index';
import MetaTraceIcon from '@/components/metaSelect/MetaTraceIcon.vue';

export default {
  name: 'SelectRelation',
  components: {
    MetaTraceIcon
  },
  data() {
    return {
      relationData: null,
      // 关联关系对象
      relationList: [],
      showList: false,
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      // 关系来源
      relationComponent: '',
      containers: []
    }
  },
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
    ])
  },
  created() {
    this.getContainers(this.componentData);
  },
  methods: {
    /**
     * @desc: 获取关联关系
     */
    getRelations() {
      this.$loading();
      const container = this.containers.find(ele => ele.id === this.relationComponent)
      if (!container || !container.database || !container.database.objectData) return;
      getRelations({
        owner_uuid: container.database.objectData.uuid,
        page: this.pager.page,
        size: this.pager.size
      }).then(res => {
        if (res && res.data && res.data.data && res.data.data) {
          this.relationList = res.data.data.data || [];
          this.pager = {
            page: res.data.data.cur_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.$loading().close();
        this.showList = true;
      }).catch(err => {
        console.log(err);
        this.$loading().close();
      });
    },
    /**
     * @desc: 获取可选的容器
     * @param {Array} arr 容器类表
     */
    getContainers(arr) {
      arr.forEach(ele => {
        if (ele.type === 'container') {
           if(ele.id !== this.curComponent.id && ele.database) {
            this.containers.push(ele);
          }
          if (ele.children && Array.isArray(ele.children)) {
            this.getContainers(ele.children);
          }
        }
      });
    },
    /**
     * @desc: 选择来源组件
     */
    onRelationComponent() {
      this.pager = {
        page: 1,
        size: 15,
        total: 0
      }
      this.getRelations();
    },
    /**
		 * @desc: 选择关系
		 * @param {Object} relationData 数据仓库对象
		 */
		onCurrentChange(relationData) {
      this.relationData = relationData;
		},
    /**
     * @desc: 确定选择
     */
    onConfirm() {
      this.showList = false;
      this.$store.commit('setDatabaseRelation', this.relationData);
    },
    /**
     * @desc: 显示选择关系
     */
    onSelectList() {
      this.showList = true;
    },
    /**
     * @desc: 移除关系
     */
    onRemoveObject() {
      this.$store.commit('removeDatabaseRelation');
    },
    /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getRelations();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getRelations();
		},
  }
}
</script>

<style lang="less" scoped>
.select-relation {
	width: 100%;
  .value-box {
		margin-top: 5px;
		padding-bottom: 5px;
		p {
			display: flex;
			line-height: 20px;
			font-size: 14px;
			padding-bottom: 5px;
			box-sizing: border-box;
			padding-left: 20px;
			.label {
				text-align: left;
        line-height: 32px;
        margin-right: 10px;
			}
			.el-input {
				flex: 1;
        text-align: left;
        word-break: break-all;
			}
		}
	}
  .relation-component{
    display: flex;
    box-sizing: border-box;
    padding-left: 10px;
    margin-top: 10px;
    .name{
      width: 100px;
      line-height: 32px;
    }
  }
  :deep(.el-drawer__body){
    display: flex;
    overflow: hidden;
    flex-direction: column;
    .el-drawer__header{
      margin: 0;
      padding-bottom: 20px;
      span{
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .database-box {
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      // height: calc(97vh - 165px);
      overflow: hidden;
      .search-box{
        display: flex;
        margin-bottom: 10px;
        .el-input{
          width: 200px;
          margin-right: 20px;
        }
      }
      .el-table {
        .el-table th, .el-table tr{
          cursor: pointer;
        }
      }
      .el-pagination {
        padding-top: 20px;
        text-align: center;
      }
    }
    .action{
      padding: 20px 10px;
      display: flex;
      .el-button{
        flex: 1;
      }
    }
  }
}
</style>
