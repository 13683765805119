<!--
 * @Description: 行为作用方式
 * @Author: luocheng
 * @Date: 2022-05-11 16:26:04
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-30 10:59:18
-->
<template>
  <div class="item" v-if="configData">
    <div class="item-main">
      <el-form-item label="行为">
        <el-select v-model="configData.behaviors" placeholder="请选择行为" multiple>
          <el-option v-for="item in behaviorList"
            :key="item.value"
            :label="item.label" 
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行为说明">
        <el-button type="text" size="mini" @click="showDesc = !showDesc">{{ showDesc ? '隐藏' : '显示' }}说明</el-button>
        <ul class="desc-list" v-show="showDesc">
          <li class="desc-item" v-for="item in selectList" :key="item.value">
            <h4>{{ item.label }}【{{ item.value }}】</h4>
            <div class="desc-box">
              <span>说明：</span>
              <p class="desc-text" :class="{
                'no-desc': !item.desc
              }">{{ item.desc || '暂无说明' }}</p>
            </div>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="参数">
        <ul class="params-list">
          <li v-for="(param, pIndex) in configData.params" :key="pIndex">
            <section class="param-row">
              <p class="label">字段名：</p>
              <p>{{ param.key }}</p>
            </section>
            <section class="param-row">
              <p class="label">来源：</p>
              <p>{{ param.sourceType | filterSource }}</p>
            </section>
          </li>
        </ul>
        <el-button type="text" size="mini" @click="onPlusParams">添加参数</el-button>
      </el-form-item>
    </div>
    <footer class="actions">
      <i class="iconfont el-icon-delete" @click="onRemove"></i>
    </footer>
    <!-- 配置参数 -->
    <el-dialog
      title="参数配置"
      :visible.sync="showParams"
      append-to-body
      fullscreen
      lock-scroll
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%">
      <ConfigParams
        @update="onUpdateParams"
        @close="onCloseParams"
        :params="configData.params"
      ></ConfigParams>
    </el-dialog>
  </div>
</template>

<script>
import ConfigParams from '@/components/common/ConfigParams'

export default {
  name: 'Item',
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 行为列表
    behaviorList: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    ConfigParams
  },
  data() {
    return {
      configData: null,
      showParams: false,
      showDesc: false
    }
  },
  created() {
    this.configData = this.config;
  },
  filters: {
    filterSource(val) {
      if (val === 'url') {
        return 'URL参数'
      }
      if (val === 'system') {
        return '系统参数'
      }
      return '内部组件';
    },
  },
  computed: {
    // 当前选中的行为列表
    selectList() {
      const { behaviors } = this.configData || {};
      if (!behaviors || !Array.isArray(behaviors) || !behaviors.length) return [];
      return this.behaviorList.filter(ele => {
        return behaviors.find(key => ele.value === key)
      });
    }
  },
  methods: {
    /**
     * @desc: 参数配置
     * @param {Array} params
     */
    onUpdateParams(params) {
      this.configData.params = params || [];
      this.onCloseParams();
    },
    /**
     * @desc: 关闭
     */
    onCloseParams() {
      this.showParams = false
    },
    /**
     * @desc: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 添加参数
     */
    onPlusParams() {
      this.showParams = true;
    },
  }
}
</script>

<style lang="less" scoped>
.item{
  width: 100%;
  border: 1px solid #f2f3f5;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  footer{
    padding-left: 10px;
    i{
      color: @dangerColor;
      font-weight: bold;
      line-height: 80px;
      cursor: pointer;
    }
  }
  :deep(.el-form-item) { 
    margin-bottom: 5px;
  }
  .params-list{
    li{
      margin-bottom: 10px;
      background: #f2f3f5;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 5px 10px;
      .param-row{
        display: flex;
      }
    }
  }
  .desc-list{
    .desc-item{
      border: 1px solid #f2f3f5;
      box-sizing: border-box;
      padding: 4px;
      border-radius: 4px;
      margin-bottom: 10px;
      h4{
        text-align: left;
      }
      .desc-box{
        display: flex;
        span{
          font-weight: bold;
        }
        p{
          flex: 1;
          text-align: left;
          &.no-desc{
            color: #ccc;
          }
        }
      }
    }
  }
}
:deep(.el-dialog){
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .el-dialog__body{
    flex: 1;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
}
</style>