<!--
 * @Description: UI逻辑判断
 根据用户信息、架构信息或五方信息进行UI逻辑判断
 * @Author: luocheng
 * @Date: 2022-06-02 14:35:59
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-12 14:52:46
-->
<template>
  <div class="UI-logic">
    <header class="header">
      <h4 class="title">UI逻辑判断-<span :class="{
        'is-enable': uiLogic.enable
      }">【{{uiLogic.enable ? '启用' : '禁用'}}
        <template v-if="uiLogic.enable">
          ({{ uiLogic.logicConfigs.length }}条)
        </template>】
        </span>
      </h4>
      <div class="action">
        <a href="javascript:;" @click="onToggle">{{ toggleShow ? '收起' : '展开' }}</a>
      </div>
    </header>
    <article v-show="toggleShow">
      <el-form :model="uiLogic">
        <el-form-item label="使用">
          <el-switch v-model="uiLogic.enable" size="mini"></el-switch>
        </el-form-item>
        <section class="logic-item" v-for="(logic, index) in uiLogic.logicConfigs" :key="index">
          <div class="item-main">
            <el-form-item label="类型" style="margin-left: 0">
              <el-select v-model="logic.type" placeholder="请选择逻辑类型" clearable size="mini">
                <el-option label="页面组件" value="component"></el-option>
                <el-option label="路由参数" value="url"></el-option>
                <el-option label="用户信息" value="userInfo"></el-option>
                <el-option label="架构信息" value="framework"></el-option>
                <el-option label="企业类型(五方)" value="enterpriseType"></el-option>
                <el-option label="映射值(循环容器)" value="mapValue"></el-option>
              </el-select>
            </el-form-item>
            <!-- 字段 用户信息和架构信息 -->
            <template v-if="logic.type === 'userInfo' || logic.type === 'framework'">
              <el-form-item label="字段">
                <el-select v-model="logic.field" placeholder="请选择判断字段" clearable size="mini">
                  <template v-if="logic.type === 'userInfo'">
                    <el-option :label="key" :value="key" v-for="(fieldItem, key) in userInfoField" :key="key"></el-option>
                  </template>
                  <template v-else-if="logic.type === 'framework'">
                    <el-option :label="key" :value="key" v-for="(fieldItem, key) in archiInfoField" :key="key"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="属性">
                <el-select v-model="logic.dataAttr" placeholder="去请选择取值属性" size="mini" clearable>
                  <el-option label="值" value="value"></el-option>
                  <el-option label="数据长度" value="length"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- 页面组件 -->
            <template v-else-if="logic.type === 'component'">
              <el-form-item label="来源组件">
                <SelectComponent v-model="logic.componentId"></SelectComponent>
              </el-form-item>
              <el-form-item label="组件取值" v-if="logic.componentId">
                <el-select v-model="logic.dataOrigin" placeholder="请选择取值属性" size="mini" clearable @change="getComponentField(index)">
                  <el-option label="暴露值" value="resolveData"></el-option>
                  <el-option label="组件绑定值" value="componentData"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="取值字段" v-if="logic.componentId">
                <el-select v-model="logic.valueField" placeholder="取值字段" size="mini" clearable>
                  <el-option v-for="(item, value) in logic.fieldList" :key="item"
                    :label="item"
                    :value="value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- url参数 -->
            <template v-else-if="logic.type === 'url'">
              <el-form-item label="URL参数key">
                <el-input v-model="logic.urlParamsKey" placeholder="请属于URL参数key" clearable size="mini">
                </el-input>
              </el-form-item>
            </template>
            <!-- 五方 -->
            <template v-else-if="logic.type === 'enterpriseType'">
              <el-form-item label="五方信息">
                <el-select v-model="logic.unitType" placeholder="请选择五方信息" clearable multiple>
                  <el-option
                    v-for="item in unitTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="五方判断逻辑">
                <el-select v-model="logic.unitLogic" placeholder="请选择五方判断逻辑" clearable>
                  <el-option label="包含一个" value="in"></el-option>
                  <el-option label="安全相等" value="equal"></el-option>
                  <el-option label="全不包含" value="none"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- 映射值取值 -->
            <template v-else-if="logic.type === 'mapValue'">
              <el-form-item label="映射对象">
                <SelectObjectUUID v-model="logic.mapObject" @input="onGetFiledList(index)"></SelectObjectUUID>
              </el-form-item>
              <el-form-item label="映射字段">
                <el-select v-model="logic.mapField"
                  placeholder="请选择字段"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in logic.mapFieldList"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- 普通判断逻辑 -->
            <template v-if="logic.type !== 'enterpriseType'">
              <el-form-item label="规则">
                <el-select v-model="logic.logicRule" placeholder="请选择逻辑规则" size="mini" clearable>
                  <el-option label="等于" value="==="></el-option>
                  <el-option label="不等于" value="!="></el-option>
                  <el-option label="大于" value=">"></el-option>
                  <el-option label="小于" value="<"></el-option>
                  <el-option label="大于等于" value=">="></el-option>
                  <el-option label="小于等于" value="<="></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="值类型">
                <el-select v-model="logic.valueType" placeholder="请选择值类型" size="mini" clearable>
                  <el-option label="字符串" value="string"></el-option>
                  <el-option label="数字" value="number"></el-option>
                  <el-option label="日期" value="date"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="比较值">
                <el-input-number
                  v-model="logic.value"
                  size="mini"
                  v-if="logic.valueType === 'number'"
                ></el-input-number>
                <el-input
                  size="mini"
                  v-model="logic.value"
                  v-else-if="logic.valueType === 'string'"
                  placeholder="请输入比较值"
                ></el-input>
                <el-date-picker
                  size="mini"
                  v-else-if="logic.valueType === 'date'"
                  v-model="logic.value"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="选择日期时间"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </template>
            <el-form-item label="组合">
              <el-select v-model="logic.itemLogic" placeholder="请选择逻辑组合" clearable>
                <el-option label="且" value="and"></el-option>
                <el-option label="或" value="or"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <i class="remove el-icon-delete" @click="onRemove(index)"></i>
        </section>
      </el-form>
      <footer class="actions">
        <el-button type="success" size="mini" @click="onPlus">新增</el-button>
        <el-button type="primary" size="mini" @click="onConfirm">确认</el-button>
      </footer>
    </article>
  </div>
</template>

<script>
import { InputNumber } from 'element-ui'
import { mapState } from 'vuex'
import { flatTree, unique, getComponentById } from '@/utils/tools';
import SelectComponent from '@/components/common/SelectComponent';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import { getFields } from '@/apis/data';

export default {
  name: 'UILogic',
  components: {
    'el-input-number': InputNumber,
    SelectComponent,
    SelectObjectUUID
  },
  props: {
    value: {
      required: true,
      default: () => {
        return {
          enable: false,
          logicConfigs: []
        }
      }
    }
  },
  data() {
    return {
      toggleShow: false,
      uiLogic: {
        enable: false,
        logicConfigs: []
      },
      unitTypes: [
        {
          label: '建设单位',
          value: 1
        },
        {
          label: '监理单位',
          value: 2
        },
        {
          label: '设计单位',
          value: 3
        },
        {
          label: '勘察单位',
          value: 4
        },
        {
          label: '施工单位',
          value: 5
        }
      ],
      // 映射
      mapFieldList: [],
      loading: false
    }
  },
  computed: {
    ...mapState([
      'componentData', // 组件数据
			'curComponent', // 当前组件
			'originComponentData',
			'sContainer'
    ]),
    // 用户信息字段列表
    userInfoField() {
      const userInfo = localStorage.getItem('userInfo');
      return userInfo && JSON.parse(userInfo);
    },
    // 架构字段列表
    archiInfoField() {
      const archiInfo = localStorage.getItem('targetArchi');
      return archiInfo && JSON.parse(archiInfo);
    },
    // 可选组件列表
		componentList() {
			let result = [];
			if (this.sContainer) {
				result = flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, [this.curComponent.id], []);
			}
			result = result.concat(flatTree(this.componentData, 'children', {
				label: 'name',
				value: 'id'
			}, [this.curComponent.id], []))
			return unique(result);
		}
  },
  created() {
    this.uiLogic = this.value ? {
      ...this.value
    } : {
      enable: false,
      logicConfigs: []
    }
  },
  methods: {
    /**
     * @desc: 确认
     */
    onConfirm() {
      this.$emit('input', this.uiLogic);
      this.$emit('change', this.uiLogic);
    },
    /**
     * @desc: 切换显示
     */
    onToggle() {
      this.toggleShow = !this.toggleShow;
    },
    /**
     * @desc: 新增逻辑
     */
    onPlus() {
      this.uiLogic.logicConfigs.push({
        type: '',
        field: '',
        dataAttr: 'value',
        logicRule: '===',
        valueType: 'string',
        value: '',
        urlParamsKey: '',
        itemLogic: 'and',
        componentId: '',
        dataOrigin: '',
        valueField: '',
        unitType: '',
        unitLogic: 'in',
        mapObject: '',
        mapField: ''
      });
    },
    /**
     * @desc: 移除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.uiLogic.logicConfigs.splice(index, 1);
    },
    /**
     * @desc: 获取组件的值
     */
    getComponentField(index) {
      console.log(this.uiLogic.logicConfigs[index], '-00000----23--');
      const { componentId } = this.uiLogic.logicConfigs[index] || {};
      let originComponent = null;
      if (this.sContainer) {
				originComponent = getComponentById(this.originComponentData, componentId);
      } else {
				originComponent = getComponentById(this.componentData, componentId);
      }
      this.uiLogic.logicConfigs.splice(index, 1, {
        ...this.uiLogic.logicConfigs[index],
        fieldList: originComponent?.metadata || {}
      });
    },
    /**
     * @desc: 获取映射值字段
     */
    onGetFiledList(index) {
      if (!this.uiLogic.logicConfigs[index].mapObject) return;
      getFields({
        object_uuid: this.uiLogic.logicConfigs[index].mapObject,
        size: 500,
        page: 1
      }).then(res => {
        this.uiLogic.logicConfigs.splice(index, 1, {
          ...this.uiLogic.logicConfigs[index],
          mapFieldList: res?.data?.data?.data || []
        });
      }).catch(err => {
        console.log(err, '---22---');
      });
    }
  }
}
</script>

<style lang="less" scoped>
.UI-logic{
  width: 100%;
  box-sizing: border-box;
  // border-bottom: 2px solid #f2f3f5;
  .header{
    display: flex;
    margin-bottom: 10px;
    .title{
      line-height: 32px;
      flex: 1;
      text-align: left;
      font-size: 14px;
      color: #606266;
      font-weight: 500;
      span{
        color: @dangerColor;
        &.is-enable{
          color: @theme;
        }
      }
    }
    .action{
      height: 32px;
      a{
        line-height: 32px;
        color: @theme;
      }
    }
  }
  .el-form{
    .el-form-item{
      margin-bottom: 5px;
      .el-input-number,.el-date-editor{
        width: 114px;
      }
      .el-form-item__label{
        font-size: 12px;
      }
    }
    .logic-item{
      border-radius: 4px;
      border: 1px solid #f2f3f5;
      box-sizing: border-box;
      padding: 5px;
      display: flex;
      margin-bottom: 5px;
      cursor: pointer;
      &:hover{
        border-color: @theme;
      }
      .item-main{
        flex: 1;
      }
      .remove{
        margin-top: 86px;
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
        color: @dangerColor;
        cursor: pointer;
      }
    }
    .action{
      margin-top: 5px;
    }
  }
  .actions{
    box-sizing: border-box;
    padding: 5px 0;
  }
  .el-select {
    // width: 225px;
  }
}
</style>