<!--
 * @Author: zqy
 * @Date: 2022-06-16 18:09:09
 * @LastEditTime: 2022-09-01 15:56:01
 * @Description: 
 * @LastEditors: Shiltin 18580045074@163.com
-->
<template>
  <div class="mobile-add-config" v-if="curComponent">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-divider style="margin:10px 0;"></el-divider>
    <el-form ref="form" :model="formData" label-width="80px">
      <div class="group-name">模型id配置</div>
      <el-form-item label="来源组件">
         <SelectComponent v-model="formData.componentId"></SelectComponent>
      </el-form-item>
      <el-form-item v-if="formData.componentId" label="模型字段选择">
				<el-select
					v-model="formData.model_id"
					placeholder="请选择模型回显字段"
					clearable
					size="small"
				>
					<el-option
						v-for="(item, index) in fieldList"
						:key="item.name + index"
						:label="item.name"
						:value="item.value"
					></el-option>
				</el-select>
      </el-form-item>
      <div class="group-name">模型构件回显配置</div>
      <el-form-item label="来源组件">
         <SelectComponent v-model="formData.dbidsComponentId"></SelectComponent>
      </el-form-item>
      <el-form-item v-if="formData.dbidsComponentId" label="字段选择">
				<el-select
					v-model="formData.dbids"
					placeholder="请选择构件字段"
					clearable
					size="small"
				>
					<el-option
						v-for="(item, index) in dbidsFieldList"
						:key="item.name + index"
						:label="item.name"
						:value="item.value"
					></el-option>
				</el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="confirmConfig">确认配置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Divider } from 'element-ui';
import SelectComponent from '@/components/common/SelectComponent';
import { getComponentById } from '@/utils/tools';

export default {
  components:{
    'el-divider':Divider,
    SelectComponent
  },
  props: {
    type: {
      type: String,
      default: 'add'
    }
  },
  data(){
    return{
      oldComponent: '',
      oldDbidsComponent: '',
      formData:{
        componentId: '', // 取值组件id
        dbidsComponentId: '', // 构件回显取值组件id
        model_id: '', // 目标组件指定字段
        dbids: '', // 构件回显字段
      },
      fieldList: [], // 字段数组
      dbidsFieldList: [], // 字段数组
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData',
      'originComponentData',
    ]),
  },
	watch: {
		'formData.componentId': {
			handler() {
        if (this.formData?.componentId) {
          if (this.formData.componentId && this.formData.componentId === this.oldComponent) {
            return
          }
          this.fieldList = []
          if (this.oldComponent) {
            this.formData.model_id = ''
          }
          this.oldComponent = this.formData.componentId
          this.getOriginComponent(1)
        }
			},
			deep: true,
			immediate: true
		},
		'formData.dbidsComponentId': {
			handler() {
        if (this.formData?.dbidsComponentId) {
          if (this.formData.dbidsComponentId && this.formData.dbidsComponentId === this.oldDbidsComponent) {
            return
          }
          this.dbidsFieldList = []
          if (this.oldDbidsComponent) {
            this.formData.dbids = ''
          }
          this.oldDbidsComponent = this.formData.dbidsComponentId
          this.getOriginComponent(2)
        }
			},
			deep: true,
			immediate: true
		}
	},
  methods:{
    confirmConfig(){
      const component = {
        ...this.curComponent,
        modelMobileConfig: this.formData
      }
      this.$store.commit("modifyComponent", {
        component,
        containerId: null,
        isModify: true,
      });
    },
    getOriginComponent(num) {
      let originComponent = null
      if (num === 1) {
        originComponent = getComponentById(this.componentData, this.formData.componentId);
      }
      if (num === 2) {
        originComponent = getComponentById(this.componentData, this.formData.dbidsComponentId);
      }
      const arr = []
      let objetInfo = {}
      if (originComponent?.metadata && Object.keys(originComponent.metadata).length) {
        objetInfo = originComponent.metadata
      } else if (originComponent?.database?.metadata && Object.keys(originComponent.database.metadata).length) {
        objetInfo = originComponent.database.metadata
      }
      if(objetInfo && Object.keys(objetInfo).length) {
        for (const key in objetInfo) {
          arr.push({ name: objetInfo[key], value: key })
        }
      }
      if (num === 1) {
        this.fieldList = arr
      } else {
        this.dbidsFieldList = arr
      }
    }
  },
  created() {
    if (this.curComponent?.modelMobileConfig) {
      this.formData.model_id = this.curComponent?.modelMobileConfig?.model_id || ''
      this.formData.componentId = this.curComponent?.modelMobileConfig?.componentId || ''
      this.formData.dbidsComponentId = this.curComponent?.modelMobileConfig?.dbidsComponentId || ''
      this.formData.dbids = this.curComponent?.modelMobileConfig?.dbids || ''
    }
  },
}
</script>
<style lang="less" scoped>
.mobile-add-config{
  width: 100%;
  height: 100%;
  .title{
    text-align: left;
    padding: 0 10px;
  }
  .group-name {
    text-align: left;
    font-size: 14px;
    font-weight: 550;
    margin-bottom: 12px;
  }
}
</style>