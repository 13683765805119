<!--
 * @Description: 过滤字段配置
 * @Author: luocheng
 * @Date: 2021-12-07 15:57:04
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 18:16:40
-->
<!--
 * @Description: 单元格配置
 * @Author: luocheng
 * @Date: 2021-12-06 14:16:50
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 14:31:14
-->
<template>
  <section class="column-action">
    <header class="header">
      <p>{{ configData.name }}</p>
      <div class="actions">
        <span class="el-icon-delete remove" @click="onRemove"></span>
        <span class="toggle" @click="showToggle = !showToggle">{{ showToggle ? '收起' : '展开' }}</span>
      </div>
    </header>
    <article class="column-content" :class="[showToggle ? 'toggle' : '']">
      <el-form :model="configData" label-width="100px">
        <el-form-item label="名称">
          <el-input v-model="configData.name" placeholder="请输入名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="组件类型">
          <el-select v-model="configData.component" placeholder="请选择组件类型" clearable @change="onCode">
            <el-option
              v-for="item in componentList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="提示语">
          <el-input v-model="configData.placeholder" placeholder="请输入提示语" clearable></el-input>
        </el-form-item>
        <el-form-item label="绑定字段">
          <el-select v-model="configData.code" placeholder="请选择组件类型" clearable @change="onCode">
            <el-option
              v-for="item in fieldList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="逻辑判断">
          <el-select v-model="configData.ruleType" placeholder="请选择逻辑判断" clearable @change="onCode">
            <el-option
              v-for="item in ruleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </article>
  </section>
</template>

<script>
import { ruleList } from '@/components/filterConfig/options';
import { mapState } from 'vuex';
export default {
  name: 'ColumnAction',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => { }
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      // name: "",
      //   component: "",
      //   placeholder: "",
      //   code: "",
      //   ruleType: ""
      configData: null,
      showToggle: false,
      // 组件列表
      componentList: [
        {
          label: '输入框',
          value: 'input'
        },
        {
          label: '下拉选项',
          value: 'select'
        },
        {
          label: '日期',
          value: 'date'
        },
        {
          label: '时间段',
          value: 'dateRange'
        }
      ],
      ruleList
    }
  },
  computed: {
    ...mapState(['curComponent']),
    fieldList() {
      return this.curComponent && this.curComponent.templateConfig.fieldList;
    }
  },
  created() {
    this.configData = this.data || null;
  },
  methods: {
    /**
     * @desc: 选择或输入code
     */
    onCode() {
      this.configData.name = this.configData.name || this.configData.code || '';
      this.emitData();
    },
    /**
     * @desc: 删除字段
     */
    onRemove() {
      this.$confirm("是否确定移除字段", '提示').then(res => {
        console.log(res);
        this.$emit('removeConfig', {
          key: 'components',
          index: this.index
        })
      }).catch(() => { });
    },
    /**
     * @desc: 提交编辑数据
     */
    emitData() {
      console.log('提交编辑数据')
    }
  }
}
</script>

<style lang="less" scoped>
.column-action {
  width: 100%;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px;
  margin-bottom: 10px;
  .header {
    display: flex;
    height: 28px;
    p {
      flex: 1;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .actions {
      padding-left: 10px;
      span {
        cursor: pointer;
        box-sizing: border-box;
        line-height: 28px;
        padding: 0 5px;
        color: @theme;
        font-size: 16px;
        font-weight: bold;
        &.remove {
          color: @dangerColor;
        }
        &.toggle {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .column-content {
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
    &.toggle {
      height: auto;
    }
  }
  .el-color-picker,
  .el-color-picker--mini {
    float: left;
  }
  .status-config {
    display: flex;
    padding-bottom: 5px;
    .remove {
      color: @dangerColor;
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .status-action {
    text-align: left;
  }
}
</style>