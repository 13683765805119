<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-18 16:45:02
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 16:12:35
 * @FilePath: /dataview-next/src/components/treeConfig/iconConfig/IconItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="icon-item">
    <el-form :model="configForm" label-width="90px" size="mini">
      <el-form-item label="图标">
        <el-input v-model="configForm.icon" placeholder="请输入icon或图片链接" @input="onEmit"></el-input>
      </el-form-item>
      <p>逻辑判断</p>
      <LogicConfig :config="configForm.logics" @updateConfig="onUpdateLogic" :type="'inner'"></LogicConfig>
    </el-form>
    <i class="el-icon-delete" @click="onRemove"></i>
  </section>
</template>

<script>
import LogicConfig from '@/components/events/logic/Index';

export default {
  name: 'IconItem',
  components: {
    LogicConfig
  },
  props: {
    item: {
      type: Object,
      isRequired: true,
      default: () => {}
    },
    index: {
      type: Number,
      isRequired: true
    }
  },
  data() {
    return {
      configForm: {
        logics: [],
        icon: ''
      }
    }
  },
  created() {
    this.configForm = this.item || {
      logics: [],
      icon: ''
    }
  },
  methods: {
    /**
     * @description: 更新逻辑配置
     * @param {Object} config
     */
    onUpdateLogic({ config }) {
      this.configForm.logics = config || [];
      this.onEmit();
    },
    /**
     * @description: 更新数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.configForm
      });
    },
    /**
     * @description: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    }
  }
}
</script>

<style lang="less" scoped>
.icon-item {
  display: flex;
  padding: 4px;
  border: 1px solid @theme;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 10px;
  .el-form {
    flex: 1;
  }
  .el-icon-delete {
    box-sizing: border-box;
    padding: 0 4px;
    color: @dangerColor;
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    text-align: left;
    padding-bottom: 10px;
  }
}
</style>