<!--
 * @Description: 筛选项配置
 * @Author: luocheng
 * @Date: 2021-12-07 15:43:15
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-08 11:31:59
-->
<template>
  <div class="filter-config">
    <el-form :model="filterConfig">
      <el-form-item label="需要操作">
        <el-switch v-model="filterConfig.needColumnActions"></el-switch>
      </el-form-item>
      <template v-if="filterConfig.needColumnActions">
        <el-form-item label="重置按钮">
          <el-switch v-model="filterConfig.searchConfig.needResetButton"></el-switch>
        </el-form-item>
        <el-form-item label="查询按钮">
          <el-switch v-model="filterConfig.searchConfig.needSearchButton"></el-switch>
        </el-form-item>
        <!-- 筛选配置 -->
        <section class="filter-items">
          <header class="item-title">
            <p class="title">字段配置</p>
            <el-button size="mini" type="text" @click="onPlusStatus">新增字段</el-button>
          </header>
          <div v-if="filterConfig.searchConfig && filterConfig.searchConfig.components.length">
            <FilterItem
              v-for="(item, index) in filterConfig.searchConfig.components"
              :key="index"
              :data="item"
              :index="index"
              @removeConfig="onRemove"
            ></FilterItem>
          </div>
          <p v-else class="no-data">暂无字段</p>
        </section>
        <!-- 附加按钮 -->
        <section class="filter-items">
          <header class="item-title">
            <p class="title">附加按钮</p>
            <el-button size="mini" type="text" @click="onPlusBtns('otherButtons')">新增按钮</el-button>
          </header>
          <div v-if="filterConfig.searchConfig && filterConfig.searchConfig.otherButtons.length">
            <BtnItem
              v-for="(item, index) in filterConfig.searchConfig.otherButtons"
              :key="index"
              :data="item"
              :index="index"
              @removeConfig="onRemove"
            ></BtnItem>
          </div>
          <p v-else class="no-data">暂未配置</p>
        </section>
        <!-- 右侧按钮 -->
        <section class="filter-items">
          <header class="item-title">
            <p class="title">右侧按钮</p>
            <el-button size="mini" type="text" @click="onPlusBtns('rightButtons')">新增按钮</el-button>
          </header>
          <p class="title">附加按钮</p>
          <div v-if="filterConfig.searchConfig && filterConfig.searchConfig.rightButtons.length">
            <BtnItem
              v-for="(item, index) in filterConfig.searchConfig.rightButtons"
              :key="index"
              :data="item"
              :index="index"
              @removeConfig="onRemove"
            ></BtnItem>
          </div>
          <p v-else class="no-data">暂未配置</p>
        </section>
      </template>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import FilterItem from "./FilterItem"
import BtnItem from './BtnItem';
import { mapState } from 'vuex';

export default {
  name: 'FilterConfig',
  components: {
    FilterItem,
    BtnItem
  },
  data() {
    return {
      filterConfig: {
        needSearch: false,
        searchConfig: {
          needResetButton: true, // 需要重置按钮？
          needSearchButton: true, // 需要查询按钮？ 为false 的时候将实时给出搜索值 防抖200ms
          components: [],
          otherButtons: [],
          rightButtons: []
        }
      }
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.filterConfig = {
      needSearch: this.curComponent?.needSearch || false,
      searchConfig: this.curComponent?.searchConfig || {
        needResetButton: true, // 需要重置按钮？
        needSearchButton: true, // 需要查询按钮？ 为false 的时候将实时给出搜索值 防抖200ms
        components: [],
        otherButtons: [],
        rightButtons: []
      }
    }
  },
  methods: {
    /**
     * @desc: 提交
     */
    onSubmit() {
      this.$store.commit('modifyComponent', {
        component: {
          ...this.curComponent,
          templateConfig: {
            ...this.templateConfig,
            ...this.filterConfig
          }
        },
        containerId: null,
        isModify: true
      });
    },
    /**
     * @desc: 新增状态
     */
    onPlusStatus() {
      this.filterConfig.searchConfig.components.push({
        name: "",
        component: "",
        placeholder: "",
        code: "",
        ruleType: ""
      })
    },
    /**
     * @desc: 新增其他按钮
     */
    onPlusBtns(key) {
      this.filterConfig.searchConfig[key].push({
        name: "",
        type: "",
        icon: "",
        function: "",
        payload: {
          object_uuid: "", // 这里面就必须填了，可以先默认起
          form_uuid: "", // 这里面就必须填了
          data_id: "",
          title: '',
          message: ''
        },
      })
    },
    /**
     * @desc: 移除
     */
    onRemove({ index, key }) {
      this.filterConfig.searchConfig[key].splice(index, 1);
    },
  }
}
</script>

<style lang="less" scoped>
.filter-config {
  .el-form-item {
    margin-bottom: 10px;
  }
  .title {
    display: block;
    text-align: left;
    width: 100%;
    padding-bottom: 10px;
  }

  .no-data {
    color: #ccc;
    text-align: center;
  }
  .item-title {
    display: flex;
    .title {
      flex: 1;
    }
  }
}
</style>