/*
 * @Author: ttheitao
 * @Description: 遍历所有组件
 * @Date: 2022-08-12 09:31:45
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-12 09:32:09
 */
const files = require.context('./', true, /\.vue$/);
const comps = {}

files.keys().map(fileName => {
  const comp = files(fileName).default;
  comps[comp.name] = comp;
})

export default comps;