<!--
 * @Description: 循环容器配置
 * @Author: luocheng
 * @Date: 2022-10-09 14:53:27
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-11 16:20:39
-->
<template>
  <div class="loop-config">
    <h3 class="title">组件名称: {{ curComponent.name }}</h3>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="循环模版" name="card">
        <CardConfig></CardConfig>
      </el-collapse-item>
      <!-- <el-collapse-item title="值映射" name="map">
        <MapConfig></MapConfig>
      </el-collapse-item> -->
    </el-collapse>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'element-ui';
import { mapState } from 'vuex';
// import MapConfig from './MapConfig';
import CardConfig from './CardConfig';

export default {
  name: 'LoopConfig',
  components: {
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    // MapConfig,
    CardConfig
  },
  data() {
    return {
      activeNames: 'card'
    }
  },
  computed: {
    ...mapState(['curComponent'])
  }
}
</script>

<style lang="less" scoped>
.loop-config {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title{
    text-align: left;
    padding-bottom: 10px;
  }
}
</style>