<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-20 14:04:43
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 16:32:08
 * @FilePath: /dataview-next/src/components/treeConfig/iconConfig/CustomItem.vue
 * @Description: 单项自定义配置
-->
<template>
  <div class="custom-item" v-show="isShow">
    <header>
      <el-button type="success" size="mini" @click="onPlus">新增逻辑</el-button>
    </header>
    <template v-if="configList && configList.length">
      <IconItem
        v-for="(item, index) in configList"
        :key="index"
        :item="item"
        @update="onUpdate"
        @remove="onRemove"
      ></IconItem>
    </template>
    <el-empty description="暂未配置，将使用默认" v-else></el-empty>
  </div>
</template>

<script>
import { Empty } from 'element-ui';
import IconItem from './IconItem';

export default {
  name: 'CustomItem',
  props: {
    value: {
      type: Array,
      isRequired: true,
      default: () => []
    },
    // 是否显示
    isShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconItem,
    'el-empty': Empty
  },
  data() {
    return {
      configList: []
    }
  },
  created() {
    this.configList = this.value ?? [];
  },
  methods: {
    /**
     * @description: 新增
     */
    onPlus() {
      this.configList.push({
        logics: [],
        icon: ''
      });
    },
    /**
     * @description: 更新配置
     * @param {Number} index 下标
     * @param {Object} config 配置
     */
    onUpdate({ index, config }) {
      this.configList.splice(index, 1, {
        ...config
      });
      this.onEmit();
    },
    /**
     * @description: 删除配置
     * @param {Number} index下标
     */
    onRemove(index) {
      this.configList.splice(index, 1);
      this.onEmit();
    },
    onEmit() {
      this.$emit('input', this.configList);
      this.$emit('change');
    }
  }
}
</script>

<style lang="less" scoped>
.custom-item {
  &>header {
    display: flex;
    .el-button {
      flex: 1;
    }
  }
}
</style>