<!--
 * @Description: 字段配置
 * @Author: luocheng
 * @Date: 2021-09-30 16:13:39
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-18 18:33:28
-->
<template>
	<div class="nesting-field-config">
		<header class="header">
      <span class="line"></span>
			<p class="item-title">子表字段配置</p>
			<el-button type="warning" @click="onResetField" size="mini">重置</el-button>
			<el-button type="primary" @click="onConfirm" size="mini">确认</el-button>
			<a
				href="javascript:;"
				class="header-btn"
				@click="toggleShow = !toggleShow"
			>{{ toggleShow ? "收起" : "展开" }}</a>
		</header>
		<div class="configs" v-show="toggleShow">
			<section class="config-item" v-for="(item, index) in configList" :key="item.uuid">
				<header class="config-item-header">
					<!-- ——{{ item.uuid }} -->
					<el-checkbox v-model="item.show"></el-checkbox>
					<!-- <h4 class="title">{{ item.label }}--{{item.uuid}}</h4> -->
					<h4 class="title" v-if="!item.quickEdit" @dblclick="item.quickEdit = true">{{ item.label }} -- {{ item.uuid }}</h4>
					<el-input class="quick-title-input" size="mini" v-else v-model="item.label" @blur="item.quickEdit = false" placeholder="请输入名称" clearable></el-input>
					<section class="actions">
						<i class="el-icon-caret-top move" @click="onMove('up', index)" v-if="index > 0"></i>
						<i
							class="el-icon-caret-bottom move"
							@click="onMove('down', index)"
							v-if="index < configList.length"
						></i>
						<el-button
							type="text"
							size="mini"
							@click="item.showConfigDesc = !item.showConfigDesc"
						>{{ item.showConfigDesc ? '收起' : '展开' }}</el-button>
					</section>
				</header>
				<el-form v-show="item.showConfigDesc" class="config-form" :model="item" label-width="90px">
					<el-form-item label="字段UUID">
						<p class="uuid-label">{{ item.uuid }}</p>
					</el-form-item>
					<el-form-item label="说明文字">
						<el-input v-model="item.label" clearable placeholder="请输入说明文字"></el-input>
					</el-form-item>
					<el-form-item label="是否使用">
						<el-switch v-model="item.show"></el-switch>
					</el-form-item>
					<el-form-item label="单元格宽度">
						<el-input v-model="item.width" clearable placeholder="宽度(不输入自适应)"></el-input>
					</el-form-item>
					<el-form-item label="字体颜色" class="color-item">
						<el-color-picker v-model="item.color"></el-color-picker>
					</el-form-item>
					<el-form-item label="架构限制">
						<el-select v-model="item.archiType" multiple placeholder="请选择架构限制(默认无)">
							<el-option label="集团" value="group"></el-option>
							<el-option label="公司" value="company"></el-option>
							<el-option label="项目" value="project"></el-option>
						</el-select>
					</el-form-item>
					<!-- 权限 -->
					<el-form-item label="权限设置"  v-if="item.permission">
						<SetPermissions v-model="item.permission"></SetPermissions>
					</el-form-item>
					<!-- UI判断逻辑 -->
					<UILogic v-model="item.UILogic"></UILogic>
					<el-form-item label="字段类型">
						<el-select v-model="item.type">
							<el-option
								v-for="type in typeList"
								:key="type.value"
								:label="type.label"
								:value="type.value"
							></el-option>
						</el-select>
					</el-form-item>
					<!-- 图片 -->
					<el-form-item v-if="item.type === 2" label="图片配置">
						<section class="img-config">
							<span>宽度：</span>
							<el-input type="number" size="mini" v-model="item.imgConfig.width" placeholder="请输入宽度"></el-input>px
						</section>
						<section class="img-config">
							<span>高度：</span>
							<el-input type="number" size="mini" v-model="item.imgConfig.height" placeholder="请输入宽度"></el-input>px
						</section>
					</el-form-item>
					<!-- 状态值 -->
					<el-form-item v-if="item.type === 3" label="状态值">
						<section class="status-item" v-for="(status, sIndex) in item.statusOptions" :key="sIndex">
							<ConfigStatus :status="status" @updateData="onModifyStatus" :index="index" :statusIndex="sIndex"
								@removeStatus="onRemoveStatus"
							>
							</ConfigStatus>
						</section>
						<el-button
							type="success"
							size="mini"
							class="plus-btn"
							@click="onPlusStatus(index, item.statusOptions)"
						>新增状态</el-button>
					</el-form-item>
					<!-- 格式 -->
					<el-form-item v-if="item.type === 4" label="时间格式">
						<el-select v-model="item.timeFormat" placeholder="请选择时间格式">
							<el-option
								v-for="(formatItem, fIndex) in formatList"
								:key="fIndex"
								:label="formatItem.label"
								:value="formatItem.value"
							></el-option>
						</el-select>
						<el-select v-model="item.connector">
							<el-option label="默认" value=""></el-option>
							<el-option label="斜杠" value="/"></el-option>
							<el-option label="汉字" value="cn"></el-option>
						</el-select>
					</el-form-item>
					<!-- switch操作 -->
					<el-form-item v-if="item.type === 7" label="编辑视图">
						<section class="switch-item">
							<div class="switch-content">
								<span class="label">值类型：</span>
								<el-select v-model="item.switchOptions.type" clearable size="mini">
									<el-option label="布尔值" value="boolean"></el-option>
									<el-option label="数字" value="number"></el-option>
									<el-option label="字符串" value="string"></el-option>
								</el-select>
							</div>
						</section>
						<section class="switch-item">
							<div class="switch-content">
								<span class="label">真值：</span>
								<el-input v-model="item.switchOptions.trueValue" clearable size="mini" placeholder="请输入值"></el-input>
							</div>
						</section>
						<section class="switch-item">
							<div class="switch-content">
								<span class="label">假值：</span>
								<el-input v-model="item.switchOptions.falseValue" clearable size="mini" placeholder="请输入值"></el-input>
							</div>
						</section>
					</el-form-item>
					<!-- 数字类型 -->
					<template v-if="item.type === 8">
						<el-form-item label="数字类型">
							<el-select v-model="item.numberOptions.type" placeholder="请选择数字类型" clearable>
								<el-option label="原始数据" value=""></el-option>
								<el-option label="小数" value="float"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="小数位数">
							<el-input-number v-model="item.numberOptions.decimalPlace" placeholder="请设置后缀"></el-input-number>
						</el-form-item>
						<el-form-item label="前缀">
							<el-input v-model="item.numberOptions.prefix" placeholder="请设置前缀"></el-input>
						</el-form-item>
						<el-form-item label="后缀">
							<el-input v-model="item.numberOptions.suffix" placeholder="请设置后缀"></el-input>
						</el-form-item>
					</template>
				</el-form>
			</section>
		</div>
	</div>
</template>

<script>
import { ColorPicker } from 'element-ui';
import { mapState } from 'vuex';
import SetPermissions from '@/components/common/SetPermissions';
import UILogic from '@/components/common/UILogic';
import ConfigStatus from './ConfigStatus';

export default {
	name: 'NestingFieldConfig',
	components: {
		'el-color-picker': ColorPicker,
		SetPermissions,
		UILogic,
		ConfigStatus
	},
	data() {
		return {
			toggleShow: false,
			// 配置
			configList: [],
			// 字段类型
			typeList: [
				{
					value: 1,
					label: '普通文本'
				},
				{
					value: 2,
					label: '图片'
				},
				{
					value: 3,
					label: '状态'
				},
				{
					value: 4,
					label: '时间'
				},
				{
					value: 5,
					label: '富文本'
				},
				{
					value: 6,
					label: '超链接'
				},
				{
					value: 7,
					label: 'switch编辑'
				}
			],
			// 时间格式
			formatList: [
				{
					label: '默认',
					value: ''
				},
				{
					label: '年月日时分秒',
					value: 'YMDhms'
				},
				{
					label: '年月日时分',
					value: 'YMDhm'
				},
        {
          label: '年月日时',
          value: 'YMDh'
        },
				{
					label: '年月日',
					value: 'YMD'
				},
				{
					label: '月日',
					value: 'MD'
				},
				{
					label: '时分秒',
					value: 'hms'
				},
				{
					label: '时分',
					value: 'hm'
				},
				{
					label: '年月',
					value: 'YM'
				},
				{
					label: '年份',
					value: 'yyyy'
				},
				{
					label: '月份',
					value: 'mm'
				},
				{
					label: '日期',
					value: 'dd'
				},
				{
					label: '周几',
					value: 'weekDay'
				},
				{
					label: '第几周',
					value: 'week'
				}
			],
			activeNames: 0
		};
	},
	computed: {
		...mapState(['curComponent'])
	},
	watch: {
		curComponent: {
			handler() {
				this.configList = [];
				if (!this.curComponent) return;
				if (
					this.curComponent.nestingFieldConfig &&
					this.curComponent.nestingFieldConfig.length
				) {
					this.configList = this.curComponent.nestingFieldConfig.map(ele => {
						return {
							numberOptions: {
								type: '', // 类型
								decimalPlace: '', // 小数位数
								prefix: '', // 前缀
								suffix: '' // 后缀
							},
							permission: [],
							UILogic: null,
							...ele,
							quickEdit: false
						}
					});
					return;
				}
				if (!this.curComponent && !this.curComponent.metadata) return;
				this.initField();
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		/**
		 * @desc: 状态值配置
		 * @param {Object} config
		 */
		onModifyStatus(config) {
			let item = JSON.parse(JSON.stringify(this.configList[config.index]));
			let statusOptions = item.statusOptions;
			statusOptions.splice(config.statusIndex, 1, {
				...config.config
			})
			this.configList.splice(config.index, 1, {
				...item,
				statusOptions
			});
		},
		/**
		 * @desc: 移除状态
		 * @param {Number} index
		 * @param {Number} statusIndex
		 */
		onRemoveStatus({ index, statusIndex }) {
			let item = JSON.parse(JSON.stringify(this.configList[index]));
			let statusOptions = item.statusOptions;
			statusOptions.splice(statusIndex, 1)
			this.configList.splice(index, 1, {
				...item,
				statusOptions
			});
		},
		/**
		 * @desc: 重置字段
		 */
		onResetField() {
			this.$confirm('是否确认重置字段?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const originFieldList = JSON.parse(JSON.stringify(this.configList));
					this.initField(originFieldList);
					this.onConfirm();
					this.$message.success('重置字段成功!');
				})
				.catch(() => { });
		},
		/**
		 * @desc: 初始化字段
		 */
		initField(originFieldList = null) {
			this.configList = [];
			const metadata = this.curComponent.metadata || {};
			let order = 0;
			for (let uuid in metadata) {
				order = order + 1;
				this.configList.push({
					uuid, // 字段uuid
					order,
					showConfigDesc: false,
					label: metadata[uuid], // 说明
					width: '',
					show: false, // 是否显示
					type: 1, // 字段类型
					color: '#333',
					archiType: '',
					permission: [],
					UILogic: null,
					statusOptions: [
						// 状态值对应配置
						{
							value: '',
							label: '',
							color: '#333'
						}
					],
					imgConfig: {
						// 图片尺寸配置
						width: 60,
						height: 60
					},
					timeFormat: '', // 时间格式化
					connector: '', // 连接符
					switchOptions: {
						type: '',
						trueValue: '',
						falseValue: ''
					},
					numberOptions: {
						type: '', // 类型
						decimalPlace: '', // 小数位数
						prefix: '', // 前缀
						suffix: '' // 后缀
					}
				});
			}
			// 保持排序
			if (!originFieldList) return;
			const originSelect = originFieldList.filter(ele => {
					return ele.show && this.configList.find(item => item.uuid === ele.uuid)
			});
			this.configList = this.configList.filter(ele => !originSelect.find(item => item.uuid === ele.uuid));
			this.configList = originSelect.concat(this.configList);
		},
		/**
		 * @desc: 保存
		 */
		onConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					nestingFieldConfig: this.configList
				},
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 增加状态值
		 * @param {Number} index 当前编辑字段下标
		 * @param {Array} statusList 状态列表
		 */
		onPlusStatus(index, statusList) {
			const statusOptions = statusList;
			statusOptions.push({
				label: '',
				value: '',
				color: '#333'
			});
			this.configList.splice(index, 1, {
				...this.configList[index],
				statusOptions
			});
		},
		/**
		 * @desc: 拖拽开始
		 */
		onStart() {
			// console.log('拖拽开始');
		},
		/**
		 * @desc: 拖拽结束
		 */
		onEnd() {
			// console.log('拖拽结束');
			// console.log(this.configList, '---配置列表');
		},
		/**
		 * @desc: 移动数据
		 * @param {String} type 类型 up down
		 * @param {Number} index 下标
		 */
		onMove(type, index) {
			const item = JSON.parse(JSON.stringify(this.configList[index]));
			const movedIndex = type === 'up' ? index - 1 : index + 1;
			const beMoved = JSON.parse(JSON.stringify(this.configList[movedIndex]));
			const beMovedShow = beMoved.show || false;
			const itemShow = item.show || false;
			// 为了触发更新
			setTimeout(() => {
				this.$set(this.configList, movedIndex, {
					...JSON.parse(JSON.stringify(item)),
					order: beMoved.order,
					show: itemShow
				})
				this.$set(this.configList, index, {
					...JSON.parse(JSON.stringify(beMoved)),
					order: item.order,
					show: beMovedShow
				})
				this.onConfirm();
			}, 0);
		}
	}
};
</script>

<style lang="less" scoped>
.nesting-field-config {
	padding-top: 10px;
	.header {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		flex-wrap: wrap;
		.line{
      width: 3px;
      border-radius: 1px;
      height: 16px;
      margin-top: 6px;
      background: @theme;
    }
		.item-title {
			margin-right: 10px;
			text-align: left;
			padding-left: 10px;
			line-height: 28px;
		}
		.header-btn {
			margin-left: 5px;
			color: @theme;
			line-height: 28px;
		}
	}
	.configs {
		.config-item {
			margin-top: 5px;
			.config-item-header {
				display: flex;
				.el-checkbox{
					margin-right: 5px;
					line-height: 26px;
				}
				.title {
					flex: 1;
					text-align: left;
					margin-right: 5px;
					line-height: 28px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.quick-title-input{
					// flex: 1;
					width: 150px;
				}
				.actions {
					i {
						box-sizing: border-box;
						padding: 5px;
						cursor: pointer;
						border-radius: 4px;
						&:hover {
							background: @theme;
							color: #fff;
						}
					}
				}
			}
		}
		.config-form {
			border-radius: 4px;
			margin: 5px 0;
			padding: 5px;
			border: 1px solid #f2f3f5;
			.uuid-label{
				word-break: break-all;
			}
			&:hover {
				border: 1px solid @theme;
				box-shadow: 3px 5px 6px 5px rgb(0 0 0 / 8%);
			}
			.el-form-item {
				margin-bottom: 10px;
				.el-form-item__content {
					vertical-align: middle;
					.el-color-picker {
						float: left;
					}
					.el-switch {
						float: left;
						margin-top: 6px;
					}
				}
				&.color-item {
					margin-bottom: 10px;
				}
			}
			.status-item {
				display: flex;
				text-align: left;
				// border-bottom: 1px solid #f2f3f5;
				&:last-of-type {
					border: none;
				}
			}
			.icon {
				margin-left: 10px;
				line-height: 28px;
				color: @dangerColor;
				font-size: 18px;
				cursor: pointer;
			}
			.plus-btn {
				width: 116px;
				margin-left: -28px;
			}
			.img-config {
				display: flex;
				span {
					width: 80px;
					font-size: 12px;
				}
				.el-input {
					margin-right: 10px;
				}
			}
			.switch-item {
				// border-bottom: 1px solid #ccc;
				&:last-of-type {
					border-bottom: none;
				}
				.switch-content {
					display: flex;
					.label {
						width: 50px;
						font-size: 12px;
					}
					.el-select,
					.el-input {
						flex: 1;
					}
				}
			}
		}
	}

	:deep(.el-collapse-item__header)  {
		border: none;
		height: 30px;
	}
	:deep(.el-collapse-item__wrap) {
		border: none;
	}
	:deep(.el-collapse-item__content) {
		padding-bottom: 0px !important;
	}
}
</style>