<!--
 * @Description: 魔法盒子配置
 * @Author: luocheng
 * @Date: 2021-12-10 17:13:34
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-22 10:40:09
-->
<template>
	<div class="magic-config">
			<h3 class="title">组件名称: {{ curComponent.name }}</h3>
      <el-collapse v-model="activeNames">
      <el-collapse-item title="基本配置" name="1">
        <el-form :model="formData" label-width="100px">
          <el-form-item label="跳转链接(例：/pc/home)">
            <el-input
              v-model="formData.link"
              placeholder="请输入跳转链接"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="默认host">
            <!-- <el-input v-model="formData.defaultHost" placeholder="请输入默认host" clearable></el-input> -->
            <el-select
              v-model="formData.defaultHost"
              placeholder="请选择默认host"
              clearable
              allow-create
              filterable
              default-first-option
            >
              <el-option label="系统默认host" value=""></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">确认</el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MagicConfig",
  data() {
    return {
      formData: {
        defaultHost: "",
        link: "",
      },
      activeNames: "1",
    };
  },
  computed: {
    ...mapState(["curComponent"]),
    // 配置
    magicConfig() {
      return (
        this.curComponent?.magicConfig || {
          defaultHost: "",
          link: "",
        }
      );
    },
  },
  created() {
    this.formData = this.magicConfig || { defaultHost: "", link: "" };
  },
  methods: {
    /**
     * @desc: 保存配置
     */
    onSubmit() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          magicConfig: this.formData,
        },
        containerId: null,
        isModify: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>