<template>
  <div class="screen-tabs-config-container">
    <template v-if="curComponent">
      <h3 class="title">组件名称: {{ curComponent.name }}</h3>
      <article class="config-list"  v-if="curComponent.type !== 'group'">
        <el-form label-position="top">
          <el-form-item label="显示受控组件">
            <TargetSelect class="target-list-select" v-model="tabConfig.showList" />
          </el-form-item>
          <el-form-item label="虚拟显示受控组件">
            <TargetSelect class="target-list-select" v-model="tabConfig.displayList" />
          </el-form-item>
        </el-form>
        <div class="action-row">
          <el-button @click.stop="doConfirm" type="primary">
            确认配置
          </el-button>
        </div>
      </article>
    </template>
  </div>
</template>
<script>
import TargetSelect from './TargetSelect';
import { mapState } from 'vuex';
export default {
  components: {
    TargetSelect
  },
  data() {
    return {
      tabConfig: {
        showList: [],
				displayList: [],
        leave: 0
      }
    }
  },
  computed: {
    ...mapState([
      'curComponent', // 当前组件
      'componentData', // 组件数据
      'sContainer',
      'originComponentData'
    ])
  },
  methods: {
    doConfirm() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					tabConfig: this.tabConfig
				},
				containerId: null,
				isModify: true
			});
		},
  },
  mounted() {
    this.tabConfig = this.curComponent?.tabConfig || {};
  }
}
</script>
<style lang="less" scoped>
  .screen-tabs-config-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-align: left;
      .title{
      text-align: left;
      line-height: 20px;
      line-height: 16px;
      padding-bottom: 10px;
      word-break: break-all;
      color: #333;
    }
    .config-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      // padding: 10px 0;
      padding-bottom: 10px;
      overflow: hidden;
      overflow-y: auto;
      .target-list-select{
        width: 100%;
      }
    }
    .action-row{
      text-align: center;
    }
  }
</style>