<!--
 * @Author: zx
 * @Date: 2022-08-03 13:59:38
 * @LastEditTime: 2022-10-14 11:16:38
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="form">
    <el-form ref="form" style="overflow-y: auto;padding-bottom: 10px" :model="formData" label-width="80px" label-position="left">
      <el-form-item label="标题" prop="object_uuid">
        <el-input
          v-model="formData.title"
          placeholder="请输入功能名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="数据对象" prop="object_uuid">
        <el-select
          v-model="formData.object_uuid"
          filterable
          remote
          :remote-method="onRemoteObject"
          :loading="objLoading"
          @change="onObjectUUID"
        >
          <el-option
            v-for="obj in objectList"
            :key="obj.id"
            :label="obj.name"
            :value="obj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="视图"
        v-if="formData.object_uuid"
        prop="view_uuid"
      >
        <!-- 视图 -->
        <el-select
          v-model="formData.view_uuid"
          filterable
          remote
          :remote-method="onRemoteView"
          :loading="viewLoading"
        >
          <el-option
            v-for="view in viewList"
            :key="view.id"
            :label="view.name"
            :value="view.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="详情表单uuid"
        v-if="formData.object_uuid && curComponent.component === 'FormMobileDetail'"
        prop="detailData"
      >
        <!-- 表单对象 -->
        <el-select
          v-model="formData.detailData"
          filterable
          remote
          :remote-method="onRemoteForm"
          :loading="formLoading"
        >
          <el-option
            v-for="formObj in formList"
            :key="formObj.id"
            :label="formObj.name || '默认'"
            :value="formObj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="编辑表单uuid"
        v-if="formData.object_uuid && curComponent.component === 'FormMobileDetail'"
        prop="editData"
      >
        <!-- 表单对象 -->
        <el-select
          v-model="formData.editData"
          filterable
          remote
          :remote-method="onRemoteForm"
          :loading="formLoading"
        >
          <el-option
            v-for="formObj in formList"
            :key="formObj.id"
            :label="formObj.name || '默认'"
            :value="formObj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="新增表单uuid"
        v-if="formData.object_uuid && curComponent.component === 'FormMobileAdd'"
        prop="editData"
      >
        <!-- 表单对象 -->
        <el-select
          v-model="formData.createData"
          filterable
          remote
          :remote-method="onRemoteForm"
          :loading="formLoading"
        >
          <el-option
            v-for="formObj in formList"
            :key="formObj.id"
            :label="formObj.name || '默认'"
            :value="formObj.uuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="开启缓存"
        v-if="curComponent.component === 'FormMobileAdd'"
        prop="editData"
      >
        <el-switch v-model="formData.openCache" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <div class="dividing-line" v-if="curComponent.component === 'FormMobileDetail'">
        <el-form-item label="编辑架构限制">
          <SetArchiLimit v-model="formData.detailLimit.archiLimit"></SetArchiLimit>
        </el-form-item>
        <el-form-item label="编辑权限设置">
          <SetPermissions v-model="formData.detailLimit.permission"></SetPermissions>
        </el-form-item>
        <el-form-item label="删除架构限制">
          <SetArchiLimit v-model="formData.deleteLimit.archiLimit"></SetArchiLimit>
        </el-form-item>
        <el-form-item label="删除权限设置">
          <SetPermissions v-model="formData.deleteLimit.permission"></SetPermissions>
        </el-form-item>
      </div>
      <!-- <div class="dividing-line" v-if="curComponent.component === 'FormMobileDetail'">
        依赖值判断操作
        当前直接展示详情自定义表单  取不到数据  不能进行判断
        <el-form-item v-if="curComponent.component === 'FormMobileDetail'" label="启用编辑依赖" label-width="110px">
          <div class="switch-container">
            <el-popover
              placement="top-start"
              width="250"
              trigger="hover"
              content="编辑依赖配置设置的是自定义表单页面内的编辑按钮是否显示"
            >
              <i class="header-icon el-icon-question" slot="reference"></i>
            </el-popover>
            <el-switch v-model="formData.detailShow.useDepend"></el-switch>
          </div>
        </el-form-item>
        <section class="login-rule" v-if="formData.detailShow.useDepend">
          <header class="rules-header">
            <p style="text-align: left;padding-bottom: 10px">依赖条件</p>
            <i :class="[formData.detailShow.expand ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" @click="onToggleRule('edit')"></i>
          </header>
          <DependLogic v-model="formData.detailShow.dependLogic" v-show="formData.detailShow.expand"></DependLogic>
        </section>
      </div> -->
      <div class="dividing-line" v-if="curComponent.component === 'FormMobileDetail'">
        <!-- 详情ui逻辑判断 -->
        <div class="dividing-line-title">编辑ui逻辑判断</div>
        <UILogic v-model="formData.detaiUILogic"></UILogic>
      </div>
      <!-- <div class="dividing-line" v-if="curComponent.component === 'FormMobileDetail'">
        删除判断操作
        当前直接展示详情自定义表单  取不到数据  不能进行判断
        <el-form-item v-if="curComponent.component === 'FormMobileDetail'" label="启用删除依赖" label-width="110px">
          <div class="switch-container">
            <el-popover
              placement="top-start"
              width="250"
              trigger="hover"
              content="删除依赖配置设置的是自定义表单页面内的删除按钮是否显示"
            >
              <i class="header-icon el-icon-question" slot="reference"></i>
            </el-popover>
            <el-switch v-model="formData.deleteShow.useDepend"></el-switch>
          </div>
        </el-form-item>
        <section class="login-rule" v-if="formData.deleteShow.useDepend">
          <header class="rules-header">
            <p style="text-align: left;padding-bottom: 10px">依赖条件</p>
            <i :class="[formData.deleteShow.expand ? 'el-icon-arrow-up' : 'el-icon-arrow-down']" @click="onToggleRule('delete')"></i>
          </header>
          <DependLogic v-model="formData.deleteShow.dependLogic" v-show="formData.deleteShow.expand"></DependLogic>
        </section>
      </div> -->
      <div class="dividing-line" v-if="curComponent.component === 'FormMobileDetail'">
        <!-- 删除ui逻辑判断 -->
        <div class="dividing-line-title">删除ui逻辑判断</div>
        <UILogic v-model="formData.deleteUILogic"></UILogic>
      </div>
      <el-form-item label="参数配置" prop="type">
        <div class="form-mobile">
          <div
            class="query-item"
            v-for="(queryItem, queryIndex) in formData.params"
            :key="queryIndex"
          >
            <div class="query-item-form">
              <el-input
                style="margin-bottom: 10px;"
                v-model="queryItem.key"
                placeholder="请输入参数名称"
                clearable
              ></el-input>
              <el-select v-model="queryItem.queryType" style="margin-bottom: 10px;"  clearable placeholder="选择携带参数" @change="changeQueryType(queryIndex)">
                <el-option v-for="(item) in configList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
              <el-select v-if="queryItem.queryType === 'query'" style="margin-bottom: 10px;" v-model="queryItem.value">
                <el-option
                  v-for="(item) in getQueryStringArgs()"
                  :key="item.value"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
              <el-select v-if="queryItem.queryType === 'component'" style="margin-bottom: 10px;" v-model="queryItem.component" placeholder="请选择组件" @change="changeComponent(queryIndex)">
                <el-option v-for="item in targetList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-select v-if="queryItem.queryType === 'component'" style="margin-bottom: 10px;" v-model="queryItem.value" placeholder="请选择组件内部参数">
                <el-option
                  v-for="(item) in queryItem.fieldList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </div>
            <section class="action">
              <i class="el-icon-delete" @click="onRemoveQuery(queryIndex)"></i>
            </section>
          </div>
          <el-button
            type="primary"
            @click="onAddQuery()"
          >新增参数</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      @click="submit()"
    >确认</el-button>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { flatTree, unique, getComponentById } from '@/utils/tools';
import { getObjects, getViews, dataInterface } from '@/apis/data/index';
// import DependLogic from '@/components/tableConfig/DependLogic';
import UILogic from '@/components/common/UILogic.vue'
import SetArchiLimit from '@/components/common/SetArchiLimit';
import SetPermissions from '@/components/common/SetPermissions'; 
// import { Popover } from 'element-ui';
export default {
  components:{
    // DependLogic,
    UILogic,
    SetArchiLimit,
    SetPermissions,
    // 'el-popover': Popover
  },
  props:{
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData', // 组件数据
      'sContainer',
      'originComponentData',
    ]),
  },
  data() {
    return {
      objLoading: false,
      viewLoading: false,
      formLoading: false,
      configList: [
        {
          name: 'query参数',
          value: 'query'
        },
        {
          name: '其他组件内部参数',
          value: 'component'
        },
      ], // 字段数组
      formData: {
        params: [],
      },
      targetList: [],
      objectList: [],
      viewList: [],
      formList: [],
    }
  },
  mounted() {

  },
  created() {
    let result = [];
    if (this.sContainer) {
      result = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
    }
    this.targetList = unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id'
      }, [])))
    if (this.curComponent?.formConfig) {
      this.formData = this.curComponent.formConfig
    }
    this.onRemoteObject('')
		if (this.formData.object_uuid) {
			this.onObjectUUID();
		}
    if (!this.formData.detailShow) {
      this.formData.detailShow = {
        useDepend: false,
        expand: false,
        dependLogic: [{
          type: '',
          field: '',
          dataAttr: 'value',
          logicRule: '===',
          valueType: 'string',
          value: '',
          itemLogic: 'and'
        }]
      }
    }
    if (!this.formData.deleteShow) {
      this.formData.deleteShow = {
        useDepend: false,
        expand: false,
        dependLogic: [{
          type: '',
          field: '',
          dataAttr: 'value',
          logicRule: '===',
          valueType: 'string',
          value: '',
          itemLogic: 'and'
        }]
      }
    }
    if (!this.formData.detaiUILogic) {
      this.formData.detaiUILogic = {
        enable: false,
        logicConfigs: []
      }
    }
    if (!this.formData.deleteUILogic) {
      this.formData.deleteUILogic = {
        enable: false,
        logicConfigs: []
      }
    }
    if (!this.formData.detailLimit) {
      this.formData.detailLimit = {
        archiLimit: [],
        permission: []
      }
    }
    if (!this.formData.deleteLimit) {
      this.formData.deleteLimit = {
        archiLimit: [],
        permission: []
      }
    }
  },
  methods:{
		/**
		 * @desc: 获取url参数
		 */
    getQueryStringArgs() {
      //取得查询字符串并去掉开头的问号
      var qs = location.search.length > 0 ? location.search.substring(1) : "",
      //保存数据的对象
      args = [],
      //取得每一项
      items = qs.length ? qs.split("&") : [],
      item = null,
      name = null,
      value = null,
      //在 for 循环中使用
      i = 0,
      len = items.length;
      //逐个将每一项添加到 args 对象中
      for (i = 0; i < len; i++) {
        item = items[i].split("=");
        name = decodeURIComponent(item[0]);
        value = decodeURIComponent(item[1]);
        if (name.length) {
          args.push({
            name,
            value
          })
        }
      }
      return args;
    },
    /* 切换参数携带类型 */
    changeQueryType(index) {
      this.formData.params[index].value = ''
    },
    /* 新增参数携带 */
    onAddQuery() {
      this.formData.params.push({
        key: '',
        queryType: '',
        value: ''
      })
    },
    /* 删除 */
    onRemoveQuery(index) {
      this.formData.params.splice(index, 1)
    },
    /* 携带参数选择组件参数触发 */
    changeComponent(index) {
      let originComponent = getComponentById(this.componentData, this.formData.params[index].component);
      this.formData.params[index].fieldList = originComponent.database.fieldList
      if (originComponent.component !== "Calendar") {
        this.formData.params[index].fieldList = originComponent.database.fieldList
        if (originComponent.component === "ThreeTerminal") {
          this.formData.params[index].fieldList = [
            {
              name: '项目org id值（id）',
              uuid: 'id',
            },
            {
              name: '项目id值（archiId）',
              uuid: 'archiId',
            },
            {
              name: '项目类型（archiType）',
              uuid: 'archiType',
            },
            {
              name: '公司id值（company_id）',
              uuid: 'company_id',
            },
            {
              name: '公司table值（company_table）',
              uuid: 'company_table',
            },
            {
              name: '公司org id值（company_type）',
              uuid: 'company_type',
            },
            {
              name: '架构名（name）',
              uuid: 'name',
            },
            {
              name: '架构table值（table）',
              uuid: 'table',
            },
          ]
        }
      } else if (originComponent.component === "Calendar") {
          const arr = [
            { name: '日期（年月日）', uuid: 'date' },
            { name: '日期（年月日 时分秒）', uuid: 'dateTime' },
            { name: '数量', uuid: 'num' },
            { name: '总数量', uuid: 'total_num' },
            { name: '当天日期范围', uuid: 'dateArr' },
            { name: '星期', uuid: 'weekDay' },
          ]
          this.formData.params[index].fieldList = arr
      }
    },
    /**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.objLoading = true;
			getObjects({ name, uuid: !name ? this.formData.object_uuid : '' })
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.objectList = data.data || [];
					}
					this.objLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.objectList = [];
					this.objLoading = false;
				});
		},
		/**
		 * @desc: 远程搜索视图
		 */
		onRemoteView(name) {
			this.viewLoading = true;
			getViews({
				object_uuid: this.formData.object_uuid,
				name,
				size: 200
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
					}
					this.viewLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.viewLoading = false;
				});
		},
    /**
		 * @desc: 获取可用表单对象
		 * @param {String} name <未启用>
		 */
		onRemoteForm(name) {
			this.formLoading = true;
			dataInterface({
				object_uuid: this.formData.object_uuid,
				name
			}, 'api/saas/metadata/getFormDesigns', 'GET')
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.formList = data || [];
					}
					this.formLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.formList = [];
					this.formLoading = false;
				});
		},
		/**
		 * @desc: 选择对象
		 */
		onObjectUUID() {
			this.onRemoteView('');
			this.onRemoteForm('')
		},
    /* 依赖条件展开或者收起 */
    onToggleRule(type) {
      if (type === 'edit') {
        this.formData.detailShow.expand = !this.formData.detailShow.expand
      } else {
        this.formData.deleteShow.expand = !this.formData.deleteShow.expand
      }
    },
    submit() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          formConfig:this.formData,
        },
        containerId: null,
        isModify: true,
      })
      this.$message.success('保存成功!');
    }
  }
}
</script>
<style lang='less' scoped>
.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .form-mobile {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    .query-item {
      display: flex;
      align-items: center;
      border: 1px solid #eee;
      margin-bottom: 10px;
      .query-item-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-right: 10px;
      }
      .action {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: @dangerColor;
        font-weight: bold;
      }
    }
  }
  
  .dividing-line {
    margin: 15px 0;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid rgb(201, 194, 194);
    .dividing-line-title {
      line-height: 24px;
    }
    .switch-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .login-rule{
      border: 1px solid #f2f3f5;
      box-sizing: border-box;
      width: 98%;
      margin-bottom: 10px;
      padding: 10px 10px 0 10px;
      border-radius: 6px;
    }
    .rules-header{
      display: flex;
      p{
        flex: 1;
        text-indent: 20px;
        line-height: 22px;
      }
      i{
        font-weight: bold;
        color: @theme;
        font-size: 16px;
        padding: 0 5px;
      }
    }
  }

}
</style>
