<!--
 * @Description: 单个配置
 * @Author: luocheng
 * @Date: 2022-06-08 17:13:23
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-16 10:41:45
-->
<template>
  <div class="single-config">
    <el-form-item label="操作类型">
      <el-select
        v-model="eventForm.actionType"
        placeholder="请选择操作类型"
        @change="onEmit"
      >
        <el-option label="页面事件" value="pageAction"></el-option>
        <el-option label="跳转页面" value="jumpPage"></el-option>
        <el-option label="导出数据" value="export"></el-option>
        <el-option label="后端事件" value="eeAction"></el-option>
        <el-option label="触发组件行为" value="componentBehavior"></el-option>
        <el-option label="修改页面状态" value="pageStatus"></el-option>
      </el-select>
    </el-form-item>
    <!-- 页面事件 -->
    <template v-if="eventForm.actionType === 'pageAction'">
      <div
        class="effect-box"
        v-for="(effect, eIndex) in eventForm.effects"
        :key="eIndex"
      >
        <div class="effect-content">
          <el-form-item
            label="作用组件"
            :label-width="labelWidth"
            :rules="rules.targets"
          >
            <el-select v-model="effect.targets" placeholder="请选择作用组件" multiple filterable @change="onEmit">
              <el-option
                v-for="item in targetList"
                :key="item.value"
                :disabled="item.disabled"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作" :label-width="labelWidth" :rules="rules.actions">
            <el-checkbox-group v-model="effect.actions" @change="onEmit">
              <el-checkbox-button
                v-for="item in actionList"
                :label="item.value"
                :key="item.value"
              >
                {{ item.label }}
              </el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="逻辑判断" v-if="effect.logics">
            <LogicConfig :config="effect.logics" :eIndex="eIndex" @updateConfig="onUpdateBefore"></LogicConfig>
          </el-form-item>
        </div>
        <a
          href="javascript:;"
          class="remove iconfont icongis_shanchu"
          @click="onRemoveEffect(eIndex)"
        ></a>
      </div>
    </template>
    <!-- 跳转页面 -->
    <template v-else-if="eventForm.actionType === 'jumpPage'">
      <div class="link-box">
        <el-form-item label="链接类型">
          <el-select v-model="eventForm.linkType" @change="onEmit">
            <el-option
              label="项目页面"
              value="projectPage"
            ></el-option>
            <el-option
              label="外部页面"
              value="outPage"
            ></el-option>
            <el-option
              label="自定义拼接"
              value="custom"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 外链 -->
        <el-form-item label="外链" v-if="eventForm.linkType === 'outPage'">
          <el-input
            v-model="eventForm.linkUrl"
            placeholder="请输入外链"
            @input="onEmit"
          ></el-input>
        </el-form-item>
        <!-- 项目页面 -->
        <template v-if="eventForm.linkType === 'projectPage'">
          <el-form-item label="页面路由">
            <el-select
              v-model="eventForm.routePath"
              filterable
              reserve-keyword
              allow-create
              clearable
              placeholder="请输入关键词"
              @change="onEmit"
            >
              <el-option
                v-for="item in pathList"
                :key="item.id"
                :label="item.name"
                :value="item.url"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div
            class="query-item"
            v-for="(queryItem, queryIndex) in eventForm.queryList"
            :key="queryIndex"
          >
            <div class="query-item-form">
              <el-form-item label="参数名称">
                <el-input
                  v-model="queryItem.key"
                  placeholder="请输入参数名称"
                  clearable
                  @input="onEmit"
                ></el-input>
              </el-form-item>
              <el-form-item label="来源组件">
                <el-select
                  v-model="queryItem.componentId"
                  @change="onComponent(queryIndex, queryItem.componentId)"
                >
                  <el-option
                    v-for="com in componentList"
                    :key="com.value"
                    :label="com.label"
                    :value="com.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联字段">
                <el-select v-model="queryItem.feild" @change="onEmit">
                  <el-option
                    v-for="(item, key) in queryItem.fieldList"
                    :key="item"
                    :label="item"
                    :value="key"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <section class="action">
              <i class="el-icon-delete" @click="onRemoveQuery(queryIndex)"></i>
            </section>
          </div>
          <el-button type="primary" @click="onAddQuery" size="mini">新增路由参数</el-button>
        </template>
        <!-- 自定义拼接 -->
        <template v-else-if="eventForm.linkType === 'custom'">
          <el-form-item label="hosts">
            <span slot="label">
              <el-popover
                placement="top-start"
                title="提示"
                width="400"
                :center="true"
                trigger="click">
                <div class="rules-contents">
                  <h4>系统将自动根据拼接模板进行跳转链接解析，花括号中的变量将被自动替换（'{ a }' => '我是a'）</h4>
                  <h4>特殊的变量</h4>
                  <ol class="rules-list">
                    <li><strong>hosts</strong> => 当前页面链接hosts</li>
                    <li><strong>token</strong> => 当前系统的bimcctoken</li>
                  </ol>
                </div>
                <span slot="reference">
                  <span>链接模板</span>
                  <i class="el-icon-question"></i>
                </span>
              </el-popover>
            </span>
            <el-input v-model="eventForm.linkTemplate" placeholder="请输入拼接规则模板" clearable @input="onEmit"></el-input>
          </el-form-item>
        </template>
      </div>
    </template>
    <!-- 导出数据 -->
    <template v-else-if="eventForm.actionType === 'export'">
      <div class="export-box">
        <el-form-item label="数据源容器">
          <el-select
            v-model="eventForm.exportTarget"
            placeholder="请选择作用组件"
            @change="onEmit"
          >
            <el-option
              v-for="item in targetList"
              :key="item.value"
              :disabled="item.disabled"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="eventForm.exportType" placeholder="请选中导出类型" @change="onEmit">
            <el-option label="全部" value="all"></el-option>
            <template v-if="eventForm.exportTarget.indexOf('CommonTree-') === 0">
              <el-option label="当前行" value="targetRow"></el-option>
            </template>
            <template v-else>
              <el-option label="带条件全部" value="searchAll"></el-option>
              <el-option label="本页数据" value="targetPage"></el-option>
              <el-option label="导出选中" value="checked"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="导出模板">
          <el-select
            v-model="eventForm.exportTemplate"
            clearable
            filterable
            remote
            :remote-method="getExportTemplate"
            :loading="templateLoading"
            placeholder="请选择导出模板"
            @change="onEmit"
          >
            <el-option
              v-for="tem in templateList"
              :key="tem.id"
              :label="tem.name"
              :value="tem.uuid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="导出结果类型">
          <el-select v-model="eventForm.exportTypeSheet" placeholder="请选择导出结果类型" @change="onEmit">
            <el-option label="表格" value="excel"></el-option>
            <el-option label="图片" value="image"></el-option>
            <el-option label="PDF" value="pdf"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请求方式">
          <el-select v-model="eventForm.exportRequest" placeholder="请选择请求方式" @change="onEmit">
            <el-option label="列表" value="dataList"></el-option>
            <el-option label="详情" value="dataInfo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据对象">
          <SelectObjectUUID
            v-model="eventForm.objectUUID"
            :placeholder="'请选择对象'"
            @update="onEmit"
          ></SelectObjectUUID>
        </el-form-item>
        <el-form-item
          label="导出视图"
          v-if="eventForm.objectUUID"
        >
          <SelectViewUUID
            v-model="eventForm.viewUUID"
            :objectUUID="eventForm.objectUUID"
            :placeholder="'请选择视图'"
            @update="onEmit"
          >
          </SelectViewUUID>
        </el-form-item>
      </div>
    </template>
    <!-- 触发后端事件 -->
    <template v-else-if="eventForm.actionType === 'eeAction'">
      <el-form-item label="绑定对象">
        <SelectObjectUUID
          v-model="eventForm.objectUUID"
          :placeholder="'请选择对象'"
          @update="onEmit"
        >
        </SelectObjectUUID>
      </el-form-item>
      <el-form-item label="绑定视图" v-if="eventForm.objectUUID">
        <SelectViewUUID
          v-model="eventForm.viewUUID"
          :objectUUID="eventForm.objectUUID"
          :placeholder="'请选择视图'"
          @update="onEmit"
        >
        </SelectViewUUID>
      </el-form-item>
      <el-form-item label="事件名称" v-if="eventForm.objectUUID">
        <SelectEEEvent
          v-model="eventForm.eventName"
          :objectUUID="eventForm.objectUUID"
          @update="onEmit"
          :placeholder="'请选择事件'">
        </SelectEEEvent>
      </el-form-item>
      <el-form-item label-width="0">
        <!-- 关联参数 -->
        <RelationSource></RelationSource>
      </el-form-item>
    </template>
    <!-- 触发组件行为 -->
    <template v-else-if="eventForm.actionType === 'componentBehavior'">
      <ConfigBehavior v-model="eventForm.behaviors" @update="onEmit"></ConfigBehavior>
    </template>
    <!-- 修改页面状态 -->
    <template v-else-if="eventForm.actionType === 'pageStatus'">
      <StateMachine v-model="eventForm.stateMachine"></StateMachine>
    </template>
    <!-- 操作 -->
    <div class="actions">
      <el-button type="success" @click="onEffect" v-if="eventForm.actionType === 'pageAction'" size="mini">新增作用域</el-button>
      <el-button type="success" @click="onPlusBehavior" v-if="eventForm.actionType === 'componentBehavior'" size="mini">新增作用组件</el-button>
      <el-button type="danger" size="mini" @click="onRemove">删除操作</el-button>
    </div>
  </div>
</template>

<script>
import LogicConfig from '../logic/Index';
import { flatTree, getComponentById, unique } from '@/utils/tools';
import { mapState } from 'vuex';
import { actionList } from '../config';
import { CheckboxGroup, CheckboxButton, Popover } from 'element-ui';
import { dataInterface } from '@/apis/data';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import RelationSource from '@/components/dialogConfig/RelationSource';
import SelectEEEvent from '@/components/common/SelectEEEvent';
import ConfigBehavior from '../behavior/Index';
import StateMachine from '../stateMachine/Index';

export default {
  name: 'SingleConfig',
  components: {
    'el-checkbox-group': CheckboxGroup,
    'el-checkbox-button': CheckboxButton,
    'el-popover': Popover,
    LogicConfig,
    SelectObjectUUID,
    SelectViewUUID,
    RelationSource,
    SelectEEEvent,
    ConfigBehavior,
    StateMachine
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    /* eslint-disable */
		// 验证选择的组件对象
		const validateTarget = (rule, value, callback) => {
			if (!value || !value.length) {
				callback(new Error('请选择作用组件！'));
			} else {
				callback();
			}
		};
		// 验证选择的事件类型
		const validateAction = (rule, value, callback) => {
			if (!value || !value.length) {
				callback(new Error('请选择操作!'));
			} else {
				callback();
			}
		};
    return {
			labelWidth: '100px',
      eventForm: {},
      // 验证
			rules: {
				// 类型
				type: [
					{
						required: true,
						message: '请选择事件类型',
						trigger: 'change'
					}
				],
				targets: [
					{
						validator: validateTarget,
						trigger: 'blur'
					}
				],
				actions: [
					{
						validator: validateAction,
						trigger: 'blur'
					}
				]
			},
      targetList: [],
      // 可选操作列表
			actionList,
      // 路径列表
			pathList: [],
      loading: false,
      // 模板
      templateLoading: false,
      templateList: []
    }
  },
  computed: {
    ...mapState(['originComponentData', 'componentData', 'sContainer'])
  },
  created() {
    this.eventForm = {
      ...this.config
    };
    if (this.sContainer) {
      this.targetList = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
    }
    this.targetList = unique(this.targetList.concat(flatTree(this.componentData, 'children', {
      label: 'name',
      value: 'id'
    }, [])));
    // 获取页面列表
		this.getPathList();
		this.getExportTemplate('');
  },
  methods: {
    /**
     * @desc: 移除事件
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 更新数据
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.eventForm
      });
    },
    /**
		 * @desc: 更新前置配置
		 */
		onUpdateBefore(data) {
			const effects = this.eventForm.effects || [];
			let effectItem = effects[data.eIndex];
			effectItem = {
				...effectItem,
				logics: data.config || []
			}
			effects.splice(data.eIndex, 1, {
        ...effectItem
      });
			this.eventForm = {
        ...this.eventForm,
        effects
      }
      this.onEmit();
		},
    /**
     * @desc: 新增作用域
     */
    onEffect() {
      this.eventForm.effects.push({
        targets: [],
				actions: [],
				logics: []
      });
      this.onEmit();
    },
    /**
     * @desc: 移除作用域
     */
    onRemoveEffect(eIndex) {
			this.eventForm.effects.splice(eIndex, 1);
      this.onEmit();
    },
    /**
     * @desc: 新增组件行为
     */
    onPlusBehavior() {
			this.eventsConfig.behaviors.push({
				component: '', // 触发的组件
				list: [ // 行为列表
					{
						behaviors: [],
						params: []
					}
				]
			});
      this.onEmit();
		},
    /**
		 * @desc: 获取页面列表
		 */
		getPathList() {
			this.loading = true;
			this.pageData = [];
			const search = [
				{
					field_uuid: '97fb3c74-5e6b-4770-9fb6-75bf2695f30b',
					ruleType: 'gt',
					value: ''
				}
			];
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: '5131498a-5901-4bc2-a739-9d517c60d0fa',
				view_uuid: 'd69059df-2793-43b7-a734-0714e07c4f2c',
				search,
				page: 1,
				size: 1000
			}).then((res) => {
				this.loading = false;
				if (res && res.status === 200 && res.data && res.data.data) {
					const pageData = this.formatField(
						res.data.data.data || [],
						this.fieldList
					);
					this.pathList = [];
					pageData.forEach((ele) => {
						if (ele.type === 0) {
							this.pathList.push(ele);
						}
						if (ele.type === 2 && ele.children.length) {
							ele.children.forEach((item) => {
								if (item.type === 0) {
									this.pathList.push(item);
								}
							});
						}
					});
				}
			}).catch(() => {
				this.loading = false;
			});
		},
    /**
		 * @desc: 格式化数据列表 uuid => code
		 * @param {Array} arr
		 * @return {Array}
		 */
		formatField(arr, fieldList) {
			return arr.map((ele) => {
				const result = {};
				for (let key in ele) {
					for (let i = 0; i < fieldList.length; i++) {
						if (key === fieldList[i].uuid) {
							result[fieldList[i].code] = ele[key];
							break;
						}
					}
				}
				const children = ele['72da4bd8-c508-48c9-8e75-5ed59e6b09d2'];
				if (children) {
					const childrenList = this.formatField(children, fieldList);
					ele['72da4bd8-c508-48c9-8e75-5ed59e6b09d2'] = childrenList;
					ele.children = childrenList;
					return {
						...result,
						children: childrenList
					};
				}
				return result;
			});
		},
    /**
		 * @desc: 获取模板列表
		 */
		getExportTemplate(value = '') {
			this.templateLoading = true;
			dataInterface({
				object_uuid: 'object617fbedcefab4',
				view_uuid: 'view61baf4e99b7b3',
				__method_name__: 'dataList',
				search: [
					{
						field_uuid: 'field617fbedcf29d2',
						ruleType: 'like',
						value
					}
				],
				transcode: 0
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.templateList = data.data || [];
					}
					this.templateLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.templateList = [];
					this.templateLoading = false;
				});
		},
    /**
		 * @desc: 添加查询参数
		 * @param {Number} index
		 */
		onAddQuery() {
      this.eventForm.queryList.push({
        key: '', // 字段key
				componentId: '', // 关联组件
				feild: '', // 字段
				fieldList: '' // 字段列表
      });
      this.onEmit();
		},
    /**
		 * @desc: 移除query参数
		 * @param {Number} queryIndex
		 */
		onRemoveQuery(queryIndex) {
      this.eventForm.queryList.splice(queryIndex, 1);
      this.onEmit();
		},
    /**
		 * @desc: 选择组件后清空字段并重新获取字段列表
		 */
		onComponent(queryIndex, componentId) {
			const component = getComponentById(this.componentData, componentId);
			this.eventForm.queryList.splice(index, 1, {
        ...this.eventForm.queryList[queryIndex],
        fieldList: component && component.metadata ? component.metadata : []
      });
      this.onEmit();
		},
  }
}
</script>

<style lang="less" scoped>
.single-config{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  .actions{
    padding-top: 10px;
  }
}
</style>