<!--
 * @Description: 事件触发状态机
 * @Author: luocheng
 * @Date: 2022-06-14 16:43:15
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-15 16:14:42
-->
<template>
  <div class="state-machine">
    <article class="state-list">
      <StateItem v-for="(item, index) in stateList"
        :key="index"
        :index="index"
        :config="item"
        @remove="onRemove"
        @update="onUpdate"
      >
      </StateItem>
    </article>
    <footer class="actions">
      <el-button type="success" size="mini" @click="onPlus">新增状态</el-button>
    </footer>
  </div>
</template>

<script>
import StateItem from './StateItem';

export default {
  name: 'StateMachine',
  components: {
    StateItem
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      stateList: []
    }
  },
  created() {
    this.stateList = this.value || [];
  },
  methods: {
    /**
     * @desc: 移除
     */
    onRemove(index) {
      this.stateList.splice(index, 1);
      this.$emit('input', this.stateList);
    },
    /**
     * @desc: 更新配置
     * @param {Object} config
     * @param {Number} index
     */
    onUpdate({ config, index }) {
      this.stateList.splice(index, 1, {
        ...config
      });
      this.$emit('input', this.stateList);
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.stateList.push({
        code: '', // 字段
        type: '', // 取值类型， 固定值： fixed, 组件来源: component,  逻辑产出值： fromLogic 
        fixedValue: '', // 固定值
        componentId: '', // 组件ID
        componentValueOrigin: '', // 组件取值值来源，resolveData: 暴露数据， propValue:  绑定值
        resolveKey: '', // 暴露数据的取值key
        logics: [] // 逻辑
      });
      this.$emit('input', this.stateList);
    }
  }
}
</script>

<style lang="less" scoped>
.state-machine{
  width: 100%;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 6px;
  // margin-bottom: 10px;
  .actions{
    padding-top: 5px;
  }
}
</style>