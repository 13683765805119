<!--
 * @Description: 选择视图
 * @Author: luocheng
 * @Date: 2021-09-07 16:25:44
 * @LastEditors: zx
 * @LastEditTime: 2022-10-12 15:08:26
-->
<template>
	<div class="select-view" v-if="curComponent">
		<header class="header">
      <h4 class="title">选择视图</h4>
      <i class="el-icon-edit edit" @click="onSelectList"></i>
      <i class="el-icon-delete delete" @click="onRemoveObject" v-show="curComponent.database && curComponent.database.viewData"></i>
    </header>
		<div class="value-box" v-if="curComponent.database && curComponent.database.viewData">
			<p>
				<span class="label">名称:</span>
				<el-input v-model="curComponent.database.viewData.name" disabled></el-input>
			</p>
			<p>
				<span class="label">uuid:</span>
				<el-input v-model="curComponent.database.viewData.uuid" disabled>
          <template slot="prefix">
            <MetaTraceIcon method="ShowViewForm" :uuid="curComponent.database.viewData.uuid" style="line-height: 36px" />
          </template>
        </el-input>
			</p>
		</div>
		<!-- <section class="actions">
			<el-button type="primary" @click="onSelectList">选择视图</el-button>
			<el-button type="danger" @click="onRemoveObject" v-show="curComponent.database && curComponent.database.viewData">移除</el-button>
		</section> -->
		<!-- 选择对象 -->
		<el-drawer title="选择视图" :visible.sync="showList" :size="400" :direction="'ltr'" v-loading="loading">
			<article class="database-box">
				<!-- <header class="search-box">
					<el-input v-model="search" placeholder="输入关键字搜索"></el-input>
					<el-button type="primary" @click="onSearch">搜索</el-button>
				</header> -->
				<el-table
					ref="viewTable"
					:data="viewList"
					border
					center
					style="width: 100%;"
					height="calc(97vh - 200px)"
					highlight-current-row
					@current-change="onCurrentChange"
				>
					<el-table-column prop="id" label="ID" width="120"></el-table-column>
					<el-table-column prop="name" label="名称">
            <template slot-scope="scope">
              <MetaTraceIcon method="ShowViewForm" :uuid="scope.row.uuid" />
              <span style="margin-left:10px;">{{scope.row.name}}</span>
            </template>
          </el-table-column>
					<!-- <el-table-column prop="updated_at" label="创建时间"></el-table-column> -->
				</el-table>
				<el-pagination
					@size-change="onSize"
					@current-change="onPage"
					:current-page="+pager.page"
					:page-sizes="[15, 20, 50, 100, 200]"
					:page-size="+pager.size"
					layout="total, sizes, prev, pager, next"
					:total="+pager.total"
				>
				</el-pagination>
			</article>
			<footer class="action">
				<el-button type="primary" @click="onConfirm">确认</el-button>
				<el-button @click="showList = false">取消</el-button>
			</footer>
		</el-drawer>
	</div>
</template>

<script>
import { Pagination, Table, TableColumn } from 'element-ui';
import { mapState } from 'vuex';
import { getViews } from '@/apis/data/index';
// import objects from "@/api/apiSaasStore";
import MetaTraceIcon from '@/components/metaSelect/MetaTraceIcon.vue';

export default {
	name: 'SelectObject',
	components: {
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-pagination': Pagination,
    MetaTraceIcon
	},
	data() {
		return {
			// 选择对象
			showList: false,
			// 视图列表
			viewList: [],
			pager: {
				page: 1,
				size: 15,
				total: 0
			},
			// 选中的项
			viewData: null,
			loading: false
		};
	},
	computed: {
		...mapState([
			'curComponent' // 当前组件
		]),
		// 对象ID
		objectId() {
			if (!this.curComponent || !this.curComponent.database || !this.curComponent.database.objectData) {
				return '';
			}
			return this.curComponent.database.objectData.uuid;
		}
	},
	created() {
		if (this.curComponent && this.curComponent.database) {
			this.viewData = this.curComponent.database.viewData;
		}
	},
	methods: {
		/**
		 * @desc: 选择视图
		 * @param {Object} viewData 数据仓库对象
		 */
		onCurrentChange(viewData) {
			if (!viewData) return;
			this.viewData = viewData;
		},
		/**
		 * @desc: 显示选择列表
		 */
		async onSelectList() {
			if (!this.objectId) {
				this.$message.error('请选择数据对象!');
				return false;
			}
			this.showList = true;
			if (this.viewList && this.viewList.length) return;
			await this.getViewList();
			this.setTableCurrent();
		},
		/**
		 * @desc: 获取视图列表
		 */
		getViewList() {
			this.loading = true;
			// objects.etlList(this.pager.page, this.pager.size, '',this.objectId)
			getViews({
				object_uuid: this.objectId
			})
			.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.viewList = data.data || [];
						this.pager = {
							page: data.current_page,
							size: data.per_page,
							total: data.total
						};
					}
					this.loading = false;
				})
				.catch((err) => {
					this.$message.error(err && err.msg);
					this.loading = false;
				});
		},
		/**
		 * @desc: 移除所选视图
		 */
		onRemoveObject() {
			this.$store.commit('removeDatabaseView');
		},
		/**
		 * @desc: 确定选择
		 */
		onConfirm() {
			this.showList = false;
			this.$store.commit('setDatabaseView', this.viewData);
		},
		/**
		 * @desc: 设置选中项
		 */
		setTableCurrent() {
			this.$nextTick(() => {
				if (this.viewData && this.viewList && this.viewList.length) {
					const index = this.viewList.findIndex((ele) => ele.uuid === this.viewData.uuid);
					if (index > -1) {
						this.$refs.viewTable.setCurrentRow(this.viewList[index]);
					}
				}
			});
		},
		/**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
			this.pager.size = size;
			this.pager.page = 1;
			this.getViewList();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
			this.pager.page = page;
			this.getViewList();
		}
	}
};
</script>

<style lang="less" scoped>
.select-view {
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid #f2f3f5;
	border-bottom: 1px solid #f2f3f5;
	margin-top: 10px;
	padding-bottom: 10px;
	.header{
		display: flex;
		.title {
			flex: 1;
			padding: 5px;
		}
		i{
			font-weight: bold;
			line-height: 33px;
			font-size: 16px;
			box-sizing: border-box;
			padding: 0 5px;
			cursor: pointer;
			color: #409EFF;
      &.delete{
        color: @dangerColor;
      }
		}
	}
	.value-box {
		margin-top: 5px;
		padding-bottom: 5px;
		p {
			display: flex;
			line-height: 20px;
			font-size: 14px;
			padding-bottom: 5px;
			box-sizing: border-box;
			padding-left: 20px;
			.label {
				text-align: left;
				line-height: 32px;
				margin-right: 10px;
			}
			.el-input {
				flex: 1;
				text-align: left;
				word-break: break-all;
			}
		}
	}
	.actions {
    display: flex;
    .el-button{
      flex: 1;
    }
  }
	:deep(.el-drawer) {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .el-drawer__header{
      margin: 0;
      padding-bottom: 20px;
      span{
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .el-drawer__body{
      flex: 1;
      width: 100%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      .database-box {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        // height: calc(97vh - 165px);
        overflow: hidden;
        .search-box{
          display: flex;
          margin-bottom: 10px;
          .el-input{
            width: 200px;
            margin-right: 20px;
          }
        }
        .el-table {
          .el-table th, .el-table tr{
            cursor: pointer;
          }
        }
        .el-pagination {
          padding-top: 20px;
          text-align: center;
        }
      }
      .action{
        padding: 20px 10px;
        display: flex;
        .el-button{
          flex: 1;
        }
      }
    }
  }
}
</style>
