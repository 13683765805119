<!--
 * @Description: 配置统计分析
 * @Author: luocheng
 * @Date: 2022-02-12 14:10:53
 * @LastEditors: luocheng
 * @LastEditTime: 2022-02-12 14:33:51
-->
<template>
  <div class="config-statistics">
    <p class="toggle" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</p>
    <div v-show="isShow">
      <section class="static-item" v-for="(item, index) in statisticsConfig" :key="index">
        <div class="item-content">
          <!-- <el-input
            v-model="item.key"
            placeholder="请输入code"
            clearable
            size="mini"
          ></el-input> -->
          <el-select
            v-model="item.view_uuid"
            placeholder="请选择统计视图"
            :remote-method="onRemote"
            clearable
            filterable
            remote
            size="mini"
          >
            <el-option
              v-for="view in viewList"
              :key="view.id"
              :label="view.object_name + '___' + view.name"
              :value="view.uuid"
            ></el-option>
          </el-select>
          <el-input
            v-model="item.field_uuid"
            placeholder="请输入统计字段(uuid)"
            size="mini"
            clearable
          ></el-input>
          <el-select
            v-model="item.aggregate"
            placeholder="请选择统计统计函数"
            clearable
            size="mini"
          >
            <el-option
              v-for="agg in aggList"
              :key="agg.value"
              :label="agg.label"
              :value="agg.value"
            ></el-option>
          </el-select>
        </div>
        <!-- <i
          class="el-icon-delete"
          @click="onRemove(index)"
        ></i> -->
      </section>
      <section class="action">
        <!-- <a href="javascript:;" class="plus">增加</a> -->
        <a href="javascript:;" class="confirm" @click="onConfirm">确认配置</a>
      </section>
    </div>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';

export default {
  name: 'ConfigStatistics',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      // 配置
      statisticsConfig: [],
      // 视图列表
			viewList: [],
			// 全局函数列表(可能会补充)
			aggList: [
				{
					label: '最大值',
					value: 0
				},
				{
					label: '最小值',
					value: 1
				},
				{
					label: '平均值',
					value: 2
				},
				{
					label: '求和',
					value: 3
				},
				{
					label: '总数',
					value: 4
				}
			],
      // 显示
      isShow: false
    }
  },
  created() {
    this.statisticsConfig = this.value;
    this.onRemote();
  },
  methods: {
    /**
     * @desc: 确认配置
     */
    onConfirm() {
      this.$emit('input', this.statisticsConfig);
    },
    onRemove(index) {
      console.log(index);
    },
    /**
		 * @desc: 获取视图列表
		 */
		onRemote(value = '') {
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: '8e0c05b5-ca9a-48ca-8dcb-19a29bbb00f1',
				view_uuid: 'view61b2c4874bd5b',
				page: 1,
				size: 20,
				search: [
					{
						field_uuid: 'field61b2c40475628',
						ruleType: 'like',
						value
					}
				],
				transcode: 0
			})
				.then((res) => {
					console.log(res);
          this.viewList = res?.data?.data.data || [];
				})
				.catch((err) => {
					console.log(err, '---错误');
				});
		}
  }
}
</script>

<style lang="less" scoped>
.config-statistics{
  width: 100%;
  .toggle{
    cursor: pointer;
    text-align: right;
    color: @theme;
  }
  .static-item {
    display: flex;
    box-sizing: border-box;
    border: 1px solid #f2f3f5;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 4px;
    &:focus {
      border-color: red;
    }
    .item-content {
      flex: 1;
      .el-input,
      .el-select {
        margin-bottom: 5px;
        width: 100%;
      }
    }
    .el-icon-delete {
      font-size: 16px;
      color: @dangerColor;
      line-height: 80px;
      padding-left: 15px;
      cursor: pointer;
    }
  }
  .action{
    display: flex;
    a{
      box-sizing: border-box;
      padding: 0 10px;
      &.plus{
        color: #67C23A;
      }
      &.confirm{
        color: @theme;
      }
    }
  }
}
</style>