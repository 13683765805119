/*
 * @Description: 布局模板列表
 * @Author: luocheng
 * @Date: 2021-08-13 16:42:20
 * @LastEditors: luocheng
 * @LastEditTime: 2021-08-16 14:18:35
 */

const list = [
  {
    id: 1,
    name: '整页',
    desc: '整页布局(适用于整页详情，表单页，新增编辑页或其他不规则布局)',
    cover: require('@/assets/images/layout/main.jpeg'),
    layoutData: [
      {
        component: 'LayoutContainer',
        type: 'container',
				name: '布局-Main',
				icon: 'icona-kanbantongjifill',
				isLock: true, // 是否锁定
        key: 'main',
        style: {
          margin: 0,
          padding: 15,
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroud: '#fff'
        }
      }
    ]
  },
  {
    id: 2,
    name: '经典列表页1',
    desc: '经典列表页(上中下结构)',
    cover: require('@/assets/images/layout/top_center_bottom.jpeg'),
    style: {
      flexDirection: 'column',
    },
    layoutData: [
      {
        component: 'LayoutContainer',
        type: 'container',
				name: '布局--Header',
				icon: 'icona-kanbantongjifill',
				isLock: true, // 是否锁定
        key: 'header',
        style: {
          margin: 0,
          padding: 15,
          minHeight: 80,
          width: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
          backgroud: '#fff',
          boxSizing: 'border-box',
          position: 'relative'
        }
      },
      {
        component: 'LayoutContainer',
        type: 'container',
				name: '布局--Main',
				icon: 'icona-kanbantongjifill',
				isLock: true, // 是否锁定
        key: 'main',
        style: {
          flex: 1,
          margin: 0,
          padding: 15,
          height: '',
          width: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroud: '#fff',
          boxSizing: 'border-box',
          position: 'relative'
        }
      },
      {
        component: 'LayoutContainer',
        type: 'container',
				name: '布局--Footer',
				icon: 'icona-kanbantongjifill',
				isLock: true, // 是否锁定
        key: 'footer',
        style: {
          margin: 0,
          padding: 15,
          minHeight: 80,
          width: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
          backgroud: '#fff',
          boxSizing: 'border-box',
          position: 'relative'
        }
      },
    ]
  },
  {
    id: 3,
    name: '经典列表页2',
    desc: '经典列表页(上下结构)',
    cover: require('@/assets/images/layout/top_bottom.jpeg')
  },
  {
    id: 4,
    name: '双筛选列表1',
    desc: '带侧边栏筛选的列表页(带底部)',
    cover: require('@/assets/images/layout/left_top_center_bottom.jpeg')
  },
  {
    id: 5,
    name: '双筛选列表2',
    desc: '带侧边栏筛选的列表页(不带底部)',
    cover: require('@/assets/images/layout/left_top_bottom.jpeg')
  }
];

export default list;