<template>
  <el-select :value="value" @input="(res) => { $emit('input', res)}" multiple filterable>
    <el-option 
      v-for="item in optionsList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { flatTree, unique } from '@/utils/tools';
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
      require: true
    }
  },
  data() {
    return {
      targetList: []
    }
  },
  computed: {
		...mapState([
			'curComponent', // 当前组件
			'componentData', // 组件列表
			'originComponentData',
			'sContainer'
		]),
    optionsList () {
      let data = [];
      if (this.sContainer) {
        data = flatTree(this.originComponentData || [], 'children', {
          label: 'name',
          value: 'id'
        }, []);
      }
      data = unique(data.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id'
      }, [])));
      return data.filter((el) => el.component !== 'ScreenTabs')
    }
  }
}
</script>
<style lang="less" scoped>

</style>