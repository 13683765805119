<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-16 11:03:02
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-17 17:40:54
 * @FilePath: /dataview-next/src/components/events/custom/table/EventDetails.vue
 * @Description: 事件配置详情回显
-->
<template>
  <el-form :model="item" label-width="100px" label-position="right" class="evnet-details">
    <!-- 后端事件 -->
    <template v-if="item.actionType === 'eeAction'">
      <!-- 后端事件 -->
      <el-form-item
        label="操作:"
        label-width="120px"
      >
        <div class="tag-list">
          <el-tag>后端事件--【{{ item.eventName }}】</el-tag>
        </div>
      </el-form-item>
    </template>
    <!-- 页面操作 -->
    <template v-else-if="item.actionType === 'pageAction'">
      <div
        v-for="(target, iIndex) in item.effects"
        :key="iIndex"
      >
        <el-form-item
          label="作用组件:"
          :label-width="labelWidth"
          prop="targets"
        >
          <el-select
            v-model="target.targets"
            placeholder="请选择作用组件"
            multiple
            disabled
            v-if="target"
          >
            <el-option
              v-for="item in targetList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="操作:"
          :label-width="labelWidth"
        >
          <div class="tag-list">
            <el-tag>{{ target.actions | filterActions }}</el-tag>
          </div>
        </el-form-item>
      </div>
    </template>
    <!-- 组件行为 -->
    <template v-else-if="item.actionType === 'componentBehavior'">
      <div
        v-for="(target, iIndex) in item.behaviors"
        :key="iIndex"
      >
        <el-form-item
          label="作用组件:"
          :label-width="labelWidth"
          prop="targets"
        >
          <el-select
            v-model="target.component"
            placeholder="请选择作用组件"
            disabled
            v-if="target"
          >
            <el-option
              v-for="item in targetList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="操作类型:"
          :label-width="labelWidth"
        >
          <div class="tag-list">
            <el-tag>触发行为</el-tag>
          </div>
        </el-form-item>
      </div>
    </template>
    <!-- 跳转 -->
    <template v-else-if="item.actionType === 'jumpPage'">
      <el-form-item label="类型:">
        <el-tag>{{ filterLinkType(item.linkType) }}</el-tag>
      </el-form-item>
    </template>
    <!-- 导出 -->
    <template v-else-if="item.actionType === 'export'">
      <el-form-item label="操作：">
        <el-tag>导出数据</el-tag>
      </el-form-item>
      <el-form-item label="数据源容器：">
        <el-tag>{{ filterExportTarget(item.exportTarget) }}</el-tag>
      </el-form-item>
    </template>
    <!-- 修改状态 -->
    <template v-else-if="item.actionType === 'pageStatus'">
      <el-form-item label="操作：">
        <el-tag>修改页面状态</el-tag>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import { unique, flatTree } from '@/utils/tools';
import { Tag } from 'element-ui';
import { actionList } from './config';
import { mapState } from 'vuex';

export default {
  name: 'EventDetails',
  components: {
    'el-tag': Tag
  },
  props: {
    item: {
      type: Object,
      isRequired: true,
      default: () => {}
    }
  },
  data() {
    return {
      labelWidth: '120px',
      targetList: [],
      linkTypeObj: {
        projectPage: '项目页面',
        outPage: '外链',
        custom: '自定义拼接',
        customForm: '自定义表单（仅H5可用）',
        pageH5: '配置页面（仅H5可用）'
      }
    };
  },
  computed: {
    ...mapState([
      'curComponent', // 当前组件
      'componentData', // 组件数据
      'sContainer',
      'originComponentData'
    ])
  },
  created() {
    let result = [];
    if (this.sContainer) {
      result = flatTree(this.originComponentData || [], 'children', {
        label: 'name',
        value: 'id'
      }, []);
    }
    this.targetList = unique(result.concat(flatTree(this.componentData, 'children', {
        label: 'name',
        value: 'id'
      }, [])))
  },
  filters: {
    // 操作
    filterActions(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        const item = actionList.find(ele => ele.value === value[i]);
        if (item) {
          result.push(item.label);
        }
      }
      return result.join();
    }
  },
  methods: {
    /**
     * @description: 跳转类型
     * @param {String} value 类型Key
     */
    filterLinkType (value) {
      return this.linkTypeObj?.[value] || '其他类型';
    },
    /**
     * @description: 数据源容器
     * @param {String} targetId
     */
    filterExportTarget(targetId) {
      return this.targetList.find(ele => ele.id === targetId)?.name || targetId;
    }
  }
};
</script>

<style lang="less" scoped>
.event-details {
  height: auto;
  width: 100%;
}
</style>
