<!--
 * @Description: 状态管理(状态机)
 * @Author: luocheng
 * @Date: 2022-06-14 11:50:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 13:55:08
-->
<template>
  <div class="state-machine">
    <header class="header">
      <el-button type="success" @click="onModifyStatus(null)">新增状态</el-button>
    </header>
    <article class="state-box">
      <section class="state-header">
        <el-radio-group v-model="filterType">
          <el-radio-button :label="1">页面状态</el-radio-button>
          <el-radio-button :label="2">全局状态</el-radio-button>
        </el-radio-group>
      </section>
      <div class="state-list">
        <template v-if="statusListForView && statusListForView.length">
          <StateItem v-for="item in statusListForView"
            :key="item.code"
            :stateData="item"
            @modifyInfo="onModifyStatus"
          ></StateItem>
        </template>
        <template v-else>
          <el-empty></el-empty>
        </template>
      </div>
    </article>
    <!-- 配置状态机 -->
    <el-drawer
      title="页面状态"
      :visible.sync="showState"
      :size="400"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      :wrapperClosable="false"
      :direction="'ltr'">
      <ConfigState
        v-if="showState"
        :defaultData="modifyData"
        @close="onClose"
        @update="onUpdate"
      ></ConfigState>
    </el-drawer>
  </div>
</template>

<script>
import { Empty, RadioButton, RadioGroup } from 'element-ui';
import { mapState } from 'vuex';
import ConfigState from './ConfigState';
import StateItem from './StateItem';

export default {
  name: 'StateMachine',
  components: {
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-empty': Empty,
    ConfigState,
    StateItem
  },
  data() {
    return {
      showState: false,
      // 编辑， 0 创建， 1 编辑
      modifyType: 0,
      modifyIndex: 0,
      // 当前编辑数据
      modifyData: null,
      // 状态列表
      statusList: [],
      // 筛选类型
      filterType: 1
    }
  },
  computed: {
    ...mapState(['statusConfig']),
    statusListForView() {
      return this.statusList.filter(ele => ele.type === this.filterType);
    }
  },
  created() {
    this.statusList = this.statusConfig || [];
  },
  methods: {
    /**
     * @desc: 确认编辑
     * @param {Object} item
     */
    onUpdate(item) {
      this.showState = false;
      if (this.modifyType) {
        // 编辑
        this.statusList.splice(this.modifyIndex, 1, {
          ...item
        });
        return;
      }
      // 新增
      this.statusList.push(item);
    },
    /**
     * @desc: 添加全局状态
     */
    onModifyStatus(item) {
      this.modifyType = !item ? 0 : 1;
      this.modifyIndex = this.statusList.findIndex(ele => ele.code === item?.code);
      this.modifyData = item;
      this.showState = true;
    },
    /**
     * @desc: 关闭
     */
    onClose() {
      this.showState = false;
    }
  }
}
</script>

<style lang="less" scoped>
.state-machine{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .header{
    border-bottom: 1px solid #f2f3f5;
    padding-bottom: 10px;
    display: flex;
    .el-button{
      flex: 1;
    }
  }
  .state-box{
    flex: 1;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    .state-header{
      .el-radio-group{
        display: flex;
        justify-content: center;
      }
      .el-radio{
        margin-right: 10px;
      }
    }
    .state-list{
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      padding-top: 10px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  :deep(.el-drawer) {
    .el-drawer__header {
      margin: 0;
      padding-bottom: 20px;
      span{
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
</style>