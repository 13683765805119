import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=4cd8aa0c&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=js&"
export * from "./Item.vue?vue&type=script&lang=js&"
import style0 from "./Item.vue?vue&type=style&index=0&id=4cd8aa0c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd8aa0c",
  null
  
)

export default component.exports