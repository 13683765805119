<!--
 * @Description: 数仓配置
 * @Author: luocheng
 * @Date: 2022-08-05 15:10:55
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-05 15:26:08
-->
<template>
  <div class="database-config">
    <header class="header">
      <el-button type="primary" size="mini" @click="onSave">确认配置</el-button>
    </header>
    <el-form :model="configForm" label-width="100px" v-if="configForm">
      <el-form-item label="数据对象">
        <SelectObjectUUID v-model="configForm.objectUUID"></SelectObjectUUID>
      </el-form-item>
      <el-form-item label="视图" v-if="configForm.objectUUID">
        <SelectViewUUID v-model="configForm.viewUUID" :objectUUID="configForm.objectUUID"></SelectViewUUID>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';

export default {
  name: 'DatabaseConfig',
  components: {
    SelectObjectUUID,
    SelectViewUUID
  },
  data() {
    return {
      configForm: null
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  created() {
    this.configForm = this.curComponent?.database || {
      objectUUID: '',
      viewUUID: ''
    };
  },
  methods: {
    onSave() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					database: this.configForm
				},
				containerId: null,
				isModify: true
			});
    }
  }
}
</script>

<style lang="less" scoped>
.database-config{
  width: 100%;
  .header{
    padding-bottom: 10px;
    text-align: right;
  }
}
</style>