
<template>
  <article class="action-box">
    <section class="action-limit">
      <!-- 整体权限 -->
      <header>
        <h4>删除操作限制</h4>
        <el-button type="primary" @click="onSubmitDelete" size="mini"
          >确认</el-button
        >
      </header>
      <section class="all-limit">
        <p class="title">删除架构</p>
        <SetArchiLimit v-model="deleteLimitForm.archiLimit"></SetArchiLimit>
      </section>
      <section class="all-limit">
        <p class="title">删除权限</p>
        <SetPermissions v-model="deleteLimitForm.permission"></SetPermissions>
      </section>
      <!-- UI判断逻辑 -->
      <UILogic v-model="deleteLimitForm.UILogic"></UILogic>
    </section>
    <div class="action-config" v-if="actionList && actionList.length">
      <!-- 分开的按钮 -->
      <h4>按钮配置</h4>
      <section
        v-for="(item, index) in actionList"
        :key="index"
        class="action-item"
      >
        <el-form :model="item" label-width="80px">
          <el-form-item label="key">
            <el-input
              placeholder="请输入key"
              v-model="item.key"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              placeholder="请输入名称"
              v-model="item.name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="颜色">
            <el-color-picker v-model="item.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="架构限制">
            <SetArchiLimit v-model="item.archiLimit"></SetArchiLimit>
          </el-form-item>
          <el-form-item label="权限">
            <SetPermissions v-model="item.permission"></SetPermissions>
          </el-form-item>
          <!-- UI判断逻辑 -->
          <UILogic v-model="item.UILogic"></UILogic>
        </el-form>
        <i class="el-icon-delete remove-btn" @click="onRemove(index)"></i>
      </section>
      <div class="actions">
        <el-button type="primary" size="mini" @click="onSave">保存</el-button>
        <el-button type="success" size="mini" @click="onPlus">新增</el-button>
      </div>
    </div>
  </article>
</template>

<script>
import { ColorPicker } from "element-ui";
import { mapState } from "vuex";
import SetPermissions from "@/components/common/SetPermissions";
import SetArchiLimit from "@/components/common/SetArchiLimit";
import UILogic from "@/components/common/UILogic";

export default {
  name: "DorpDownActionConfig",
  components: {
    "el-color-picker": ColorPicker,
    SetPermissions,
    SetArchiLimit,
    UILogic,
  },
  data() {
    return {
      actionList: [],
      actionLimitForm: {
        permission: [], // 权限
        archiLimit: [], // 架构限制
        UILogic: null,
      },
      deleteLimitForm: {
        permission: [], // 权限
        archiLimit: [], // 架构限制
        UILogic: null,
      },
      countNumberShow: false,
      countNumberConfig: {
        numberDataType: 1, // 1:自动查找 2:来自组件 3:来自视图
        idToParam:"type_id", // id 字段变成什么参数名来请求数据
        preText: "", // 前置文本
        showPreNumber: false,
        masterColorPosition: "", // 显示位置
        masterColor: "red", // 提醒色
        forceViewUuid: "", // 强制视图uuid
        forceObjectUuid: "", // 强制视图uuid
        numberDataComponent:"", // 数据组件
      },
    };
  },
  computed: {
    ...mapState(["curComponent","componentData"]),
    // 操作配置
    actionConfig() {
      return (this.curComponent && this.curComponent.actionConfig) || [];
    },
    componentArray(){
      return this.getComponentArray(this.componentData);
    },
    actionLimit() {
      return (
        this.curComponent?.actionLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
    // 删除权限
    deleteLimit() {
      return (
        this.curComponent?.deleteLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
  },
  created() {
    console.log(this.curComponent, "this.curComponent");
    if (this.actionConfig && this.actionConfig.length) {
      this.actionList = this.actionConfig.map((ele) => {
        return {
          permission: [], // 权限
          archiLimit: [], // 架构限制
          ...ele,
        };
      });
    }
    if (this.actionLimit) {
      this.actionLimitForm = this.actionLimit;
    }
    if (this.deleteLimit) {
      this.deleteLimitForm = this.deleteLimit;
    }
    if (this.curComponent.countNumberShow) {
      this.countNumberShow = true;
    } else {
      this.countNumberShow = false;
    }
    if (this.curComponent.countNumberConfig) {
      this.countNumberConfig = this.curComponent.countNumberConfig;
    }
  },
  methods: {
    /**
     * @desc: 整体权限配置
     */
    onSubmitAll() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          actionLimit: this.actionLimitForm || {
            permission: [], // 权限
            archiLimit: [], // 架构限制
            UILogic: null,
          },
          countNumberShow: this.countNumberShow,
          countNumberConfig: this.countNumberConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 删除权限配置
     */
    onSubmitDelete() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          deleteLimit: this.deleteLimitForm || {
            permission: [], // 权限
            archiLimit: [], // 架构限制
            UILogic: null,
          },
          countNumberShow: this.countNumberShow,
          countNumberConfig: this.countNumberConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 删除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.actionList.splice(index, 1);
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$store.commit("modifyComponent", {
        component: {
          ...this.curComponent,
          actionConfig: this.actionList || [],
          countNumberShow: this.countNumberShow,
          countNumberConfig: this.countNumberConfig,
        },
        containerId: null,
        isModify: true,
      });
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.actionList.push({
        key: "",
        name: "",
        color: "#409EFF",
        eventList: [],
        permission: [], // 权限
        archiLimit: [], // 架构限制
        UILogic: null,
      });
    },

    /**
     * @description: 获取摊平的组件列表
     * @param {*} component 待处理的数据
     * @param {Array} data 已处理好的数据
     * @return {Array} 摊平的组件数据
    */
    getComponentArray(component = null,data = []){
      if(component === null){
        component = this.componentData;
      }
      for(let i = 0;i<component.length;i++){
        data.push(component[i]);
        if(component[i].children && component[i].children.length){
          data = this.getComponentArray(component[i].children,data)
        }
      }
      return data;
    }
  },
};
</script>

<style lang="less" scoped>
.action-limit {
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 10px;
  header {
    display: flex;
    padding-bottom: 10px;
    h4 {
      flex: 1;
    }
  }
  .all-limit {
    display: flex;
    padding-bottom: 5px;
    p {
      flex: 1;
      line-height: 32px;
    }
  }
}
.action-config {
  height: 100%;
  .action-item {
    display: flex;
    border-bottom: 1px solid #f2f3f5;
    padding-top: 10px;
    .el-form {
      flex: 1;
    }
    .remove-btn {
      margin-left: 10px;
      font-size: 16px;
      line-height: 40px;
      color: @dangerColor;
      cursor: pointer;
    }
  }
  .actions {
    padding-top: 10px;
  }
}
</style>