<!--
 * @Description: 列表模板配置项
 * @Author: luocheng
 * @Date: 2021-12-03 14:03:01
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 15:53:17
-->
<template>
  <div class="list-page-cofnig">
    <!-- {{ templateConfig }} -->
    <PartHeader></PartHeader>
    <article class="config-main">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="数据仓库" name="database">
          <DatabaseConfig :config="templateConfig"></DatabaseConfig>
        </el-collapse-item>
        <el-collapse-item title="单元格配置" name="columnConfig">
          <ColumnConfig></ColumnConfig>
        </el-collapse-item>
        <el-collapse-item title="表格操作" name="tableAction">
          <TableActionConfig></TableActionConfig>
        </el-collapse-item>
        <el-collapse-item title="筛选配置" name="filter">
          <FilterConfig></FilterConfig>
        </el-collapse-item>
      </el-collapse>
    </article>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'element-ui';
import { mapState } from 'vuex';
import DatabaseConfig from '../compnents/DatabaseConfig.vue';
import PartHeader from '../compnents/PartHeader';
import ColumnConfig from '../compnents/ColumnConfig';
import TableActionConfig from '../compnents/TableActionConfig';
import FilterConfig from '../compnents/FilterConfig';

export default {
  name: 'ListPageConfig',
  components: {
    "el-collapse": Collapse,
    "el-collapse-item": CollapseItem,
    DatabaseConfig,
    PartHeader,
    ColumnConfig,
    TableActionConfig,
    FilterConfig
},
  data() {
    return {
      activeName: 'filter'
    }
  },
  computed: {
    ...mapState(['curComponent']),
    // 页面配置
    templateConfig() {
      return this.curComponent && this.curComponent.templateConfig
    }
  }
}
</script>

<style lang="less" scoped>
.list-page-cofnig {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .config-main{
    flex: 1;
    overflow: hidden;
    width: 100%;
    overflow-y: auto;
  }
}
</style>

