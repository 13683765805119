<!--
 * @Description: 参数配置ITEM
 * @Author: luocheng
 * @Date: 2022-03-30 09:36:02
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-23 10:17:21
-->
<template>
  <div class="param-item">
    <header class="item-header">
      <h3>参数：{{ initData.code || initData.uuid }} 【{{ index + 1 }}】</h3>
      <a href="javascript:;" class="remove" @click="onRemove">删除</a>
      <a href="javascript:;" @click="toggleShow">{{ showConfig ? '收起' : '展开' }}</a>
    </header>
    <div v-show="showConfig">
      <section class="row">
        <p class="label">参数类型</p>
        <el-select v-model="param.paramType" placeholder="请选择参数类型" @change="onEmit">
          <el-option label="搜索项" value="search"></el-option>
          <el-option label="普通参数" value="param"></el-option>
        </el-select>
      </section>
      <section class="row">
        <p class="label">code</p>
        <el-input v-model="param.code" placeholder="请输入code" clearable @input="onEmit"></el-input>
      </section>
      <section class="row">
        <p class="label">uuid</p>
        <el-input v-model="param.uuid" placeholder="请输入uuid" clearable @input="onEmit"></el-input>
      </section>
      <section class="row">
        <p class="label">必填字段</p>
        <el-switch v-model="param.isRequired" @change="onEmit"></el-switch>
      </section>
      <section class="row">
        <p class="label">参数来源</p>
        <el-select v-model="param.originType" placeholder="请选择参数来源" @change="onEmit">
          <el-option label="内部组件" value="component"></el-option>
          <el-option label="URL参数" value="url"></el-option>
          <el-option label="页面状态" value="pageStatus"></el-option>
          <el-option label="固定值" value="fixed"></el-option>
        </el-select>
      </section>
      <!-- 搜索项 -->
      <!-- 组件来源 -->
      <template v-if="param.originType === 'component'">
        <section class="row">
          <p class="label">来源组件</p>
          <el-select
            v-model="param.componentId"
            placeholder="请选择来源组件"
            @change="onComponent"
            filterable
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in componentList"
              :key="index"
            ></el-option>
          </el-select>
        </section>
        <!-- <el-select v-model="param.componentField" placeholder="请选择参数类型">
          <el-option label="暴露数据" value="resolveData"></el-option>
          <el-option label="普通参数" value="param"></el-option>
        </el-select> -->
        <section class="row" v-if="resolveData">
          <p class="label">组件参数字段</p>
          <el-select
            v-model="param.componentKey"
            placeholder="请选择参数字段"
            :disabled="!param.componentId"
            @change="onEmit"
            allow-create
            filterable
          >
          <template v-if="resolveComponents.includes(originComponent && originComponent.component)">
              <el-option
              :label="key"
              :value="key"
              v-for="(value, key) in resolveData"
              :key="key"
            ></el-option>
          </template>
          <template v-else>
              <el-option
              :label="value"
              :value="item"
              v-for="(value, item) in resolveData"
              :key="item"
            ></el-option>
          </template>
          </el-select>
        </section>
      </template>
      <!-- URL参数 -->
      <template v-else-if="param.originType === 'url'">
        <section class="row">
          <p class="label">URL参数key</p>
          <el-input v-model="param.urlKey" placeholder="请输入URL参数key" clearable @input="onEmit"></el-input>
        </section>
      </template>
      <!-- 页面状态 -->
      <template v-else-if="param.originType === 'pageStatus'">
        <section class="row">
          <p class="label">状态字段</p>
          <el-select v-model="param.statusCode" placeholder="请选择状态字段" @change="onEmit">
            <el-option v-for="statusItem in statusConfig"
              :key="statusItem.code"
              :value="statusItem.code"
              :label="statusItem.code + '【' + (statusItem.type === 1 ? '页面' : '全局') + '状态】'"
            ></el-option>
          </el-select>
        </section>
      </template>
      <!-- 固定值 -->
      <section class="row" v-else-if="param.originType === 'fixed'">
        <p class="label">参数值</p>
        <el-input v-model="param.fixedValue" placeholder="请输入固定参数值" clearable @input="onEmit"></el-input>
      </section>
      <!-- 搜索项 search -->
      <template v-if="param.paramType === 'search'">
        <section class="row">
          <p class="label">逻辑关系</p>
          <el-select v-model="param.ruleType" placeholder="请选择逻辑关系" clearable @change="onEmit">
            <el-option v-for="item in ruleList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { ruleList } from '@/components/filterConfig/options';
import { mapState } from 'vuex';

export default {
  name: 'ParamItem',
  props: {
    componentList: {
      type: Array,
      default: () => [],
      required: true
    },
    // 初始配置数据
    initData: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      param: {
        paramType: '', // 请求类型
        code: '', //  字段code
        fieldUUID: '', // 字段UUID
        isRequired: false, // 是否必填字段
        originType: 'component', // 参数来源. 组件 component， URL参数 url
        componentId: '', // 来源组件 Id
        componentKey: '', // 组件字段key
        urlKey: '', // url参数的key
        statusCode: '', // 页面状态字段
        ruleType: '', // 逻辑类型
        uuid: '', // 兼容之前
        fixedValue: '', // 固定取值
      },
      // 字段列表
      fieldList: [],
      ruleList,
      // 显示配置
      showConfig: false,
      // 暴露的数据
      resolveData: null,
      resolveComponents: ['CommonSelect', 'CommonTabs', 'CommonBaseDate', 'CommonDateFilter', 'CommonCalendar'],
      originComponent: null
    }
  },
  computed: {
    ...mapState(['statusConfig'])
  },
  created() {
    this.param = this.initData ? {
      statusCode: '',
      ...JSON.parse(JSON.stringify(this.initData))
    } : this.param;
    this.onComponent();
  }, 
  methods: {
    /**
     * @desc: 选择组件
     */
    onComponent() {
      const componentItem = this.componentList.find(ele => ele.id === this.param.componentId);
      this.originComponent = componentItem;
      if (!componentItem) return;
      if (this.resolveComponents.includes(componentItem.component)) {
        this.resolveData = componentItem.resolveData || {};
      } else {
        this.resolveData = componentItem?.metadata || {};
      }
      // console.log(componentItem, componentItem.resolveData, '----0000---', componentItem?.metadata)
      this.onEmit();
    },
    /**
     * @desc: 删除
     */
    onRemove() {
      this.$confirm(`确认删除参数【${this.param.name}】`).then(() => {
        this.$emit('remove', this.index);
      }).catch(() => {});
    },
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('updateData', {
        index: this.index,
        config: this.param
      });
    },
    /**
     * @desc: 切换显示
     */
    toggleShow() {
      this.showConfig = !this.showConfig;
    }
  }
}
</script>

<style lang="less" scoped>
.param-item{
  width: 100%;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  padding: 5px;
  padding-bottom: 0;
  border-radius: 6px;
  margin: 10px 0;
  .item-header{
    display: flex;
    padding-bottom: 5px;
    h3{
      flex: 1;
      line-height: 22px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }
    a{
      color: @theme;
      font-weight: bold;
      line-height: 22px;
      &.remove{
        color: @dangerColor;
        margin-right: 10px;
      }
    }
  }
  &>div{
    margin-top: 10px;
  }
  section.row{
    display: flex;
    box-sizing: border-box;
    padding-bottom: 10px;
    .label{
      margin-right: 10px;
      width: 120px;
    }
    .el-input{
      width: 220px;
    }
    .el-select{
      width: 220px;
    }
    .el-switch{
      text-align: left;
    }
  }
}
</style>