<!--
 * @Description: 参数配置
 * @Author: luocheng
 * @Date: 2022-03-29 17:44:41
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-04 10:59:56
-->
<template>
  <div class="params-config">
    <ParamItem
      v-for="(item, index) in paramsList"
      :key="index"
      :index="index"
      :initData="item"
      :componentList="componentList"
      @updateData="onUpdateData"
      @remove="onRemove"
    >
    </ParamItem>
    <footer class="actions">
      <el-button type="success" size="mini" @click="onPlusParam">新增参数配置</el-button>
      <el-button type="primary" size="mini" @click="onSubmit">保存</el-button>
    </footer>
  </div>
</template>

<script>
import ParamItem from './ParamItem';
import { flatTree, unique } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  name: 'ParamsConfig',
  components: {
    ParamItem
  },
  data() {
    return {
      paramsList: [],
      // 可选类表
      componentList: []
    }
  },
  computed: {
    ...mapState(['componentData', 'curComponent', 'originComponentData', 'sContainer'])
  },
  watch: {
    componentData: {
      handler() {
        if (this.sContainer) {
          this.componentList = flatTree(this.originComponentData || [], 'children', null, []);
        } 
        this.componentList = unique(this.componentList.concat(flatTree(this.componentData, 'children', null, [])));
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.paramsList = this.curComponent?.paramsConfig || [];
  },
  methods: {
    /**
     * @desc: 新增参数
     */
    onPlusParam() {
      this.paramsList.push({
        paramType: 'search', // 请求类型
        code: '', //  字段code
        fieldUUID: '', // 字段UUID
        isRequired: false, // 是否必填字段
        originType: 'component', // 参数来源. 组件 component， URL参数 url
        componentId: '', // 来源组件 Id
        componentKey: '', // 组件字段key
        urlKey: '', // url参数的key
        ruleType: '', // 逻辑类型
        fixedValue: '' // 固定值
      });
    },
    /**
     * @desc: 更新数据
     */
    onUpdateData({ index, config }) {
      this.paramsList.splice(index, 1, {
        ...config
      });
    },
    /**
     * @desc: 提交
     */
    onSubmit() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					paramsConfig: this.paramsList
				},
				containerId: null,
				isModify: true
			});
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.paramsList.splice(index, 1);
    }
  }
}
</script>

<style lang="less" scoped>
.params-config{
  width: 100%;
}
</style>