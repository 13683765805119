<!--
 * @Description: 图表盒子配置
 * @Author: luocheng
 * @Date: 2022-01-10 11:20:31
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-09-01 15:54:43
-->
<template>
	<div class="chart-config">
		<h3 class="title">组件名称: {{ curComponent.name }}</h3>
		<el-collapse v-model="activeNames" accordion>
			<el-collapse-item
				title="基本配置"
				name="base"
			>
				<el-form
					:model="formData"
					label-width="100px"
				>
					<el-form-item label="绑定图表">
						<el-select
							v-model="formData.chartUUID"
							placeholder="请选择绑定图表"
							clearable
							filterable
						>
							<el-option
              v-for="item in chartList"
              :key="item.id"
							:label="item.name"
							:value="item.uuid"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button
							type="primary"
							@click="onSubmit"
						>确认</el-button>
					</el-form-item>
				</el-form>
			</el-collapse-item>
			<!-- 参数配置 -->
			<el-collapse-item title="参数配置" name="paramsConfig">
				<ParamsConfig></ParamsConfig>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
import ParamsConfig from '@/components/common/ParamsConfig';

export default {
	name: 'ChartConfig',
	components: {
		ParamsConfig
	},
  data() {
    return {
      activeNames: 'paramsConfig',
      formData: { // 配置
        chartUUID: ''
      },
      chartList: [], // 图表列表
    }
  },
	computed: {
		...mapState(['curComponent']),
    // 配置
		chartConfig() {
			return (
				this.curComponent?.chartConfig || {
					defaultHost: '',
					link: ''
				}
			);
		}
	},
  created() {
    this.getCharts();
		this.formData = this.chartConfig || { chartUUID: '' };
  },
  methods: {
    /**
		 * @desc: 获取页面列表
		 */
		getCharts() {
			this.loading = true;
			this.tableData = [];
			const search = [
				{
					field_uuid: 'field61bc41f645e17',
					ruleType: 'eq',
					value: 'chart'
				}
			];
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61922b1881103',
				search,
				page: 1,
				size: 9999,
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					if (res && res.status === 200 && res.data && res.data.data) {
						this.chartList = res.data.data.data || [];
            // console.log(this.chartList, 'this.chartList')
					}
				})
				.catch((err) => {
					this.loading = false;
					this.$message.error(`获取列表:${err.msg}`);
				});
		},
    /**
     * @desc: 提交配置
     */
    onSubmit() {
      this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					chartConfig: this.formData
				},
				containerId: null,
				isModify: true
			});
    }
  }
};
</script>

<style lang="less" scoped>
.chart-config {
}
</style>