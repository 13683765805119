<!--
 * @Description: 工具栏
 * @Author: luocheng
 * @Date: 2021-08-05 13:58:43
 * @LastEditors: zx
 * @LastEditTime: 2022-11-08 09:57:52
-->
<template>
	<div class="toolbar">
    <div class="header-wrap">
      <div class="left">
        <i v-if="!$route.query.isAdd" class="el-icon-arrow-left" @click="onClose"></i>
        <img class="logo" :src="logo">
      </div>
      <div class="middle">
        <div class="btn-group">
          <el-tooltip class="item" effect="dark" content="上一步" placement="top-start">
            <el-button type="default" icon="el-icon-refresh-left" @click="prevStep" :disabled="recordIndex <= -1"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="下一步" placement="top-start">
            <el-button type="default" icon="el-icon-refresh-right" @click="nextStep" :disabled="recordData.length <= 1 || recordIndex >= recordData.length - 1"></el-button>
          </el-tooltip>
          <template v-if="editorType !== 'page' && editorType !== 'template' && editorType !== 'mobile'">
            <el-tooltip class="item" effect="dark" content="组合" placement="top-start">
              <el-button type="default" icon="el-icon-s-help" :disabled="!areaData?.components?.length" @click="onCompose"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="取消组合" placement="top-start">
              <el-button type="default" icon="el-icon-help" :disabled="!curComponent || curComponent.type !== 'group'" @click="onSplit"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="锁定" placement="top-start">
              <el-button type="default" icon="el-icon-lock" :disabled="!curComponent || curComponent.isLock" @click="toggleLock(true)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="解锁" placement="top-start">
              <el-button type="default" icon="el-icon-unlock" :disabled="!curComponent || !curComponent.isLock" @click="toggleLock(false)"></el-button>
            </el-tooltip>
          </template>
          <el-tooltip class="item" effect="dark" content="全屏" placement="top-start">
            <el-button type="default" icon="el-icon-full-screen" @click="fullscreen"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="清空" placement="top-start">
            <el-button type="danger" icon="el-icon-delete" :disabled="!componentData || !componentData.length" @click="onClear"></el-button>
          </el-tooltip>
          <el-select
            v-if="editorType === 'mobile'"
            style="margin-left: 15px;width: 125px;"
            v-model="previewEquipment"
            placeholder="请选择"
            @change="chooseEquipment"
          >
            <el-option v-for="item in equipmentList" :key="item.key" :label="item.name" :value="item.key"> </el-option>
          </el-select>
          <section class="action-box size" style="margin-left: 16px">
            <span>画布大小</span>
            <input type="number" v-model="canvasStyle.width" />
            <span>*</span>
            <input type="number" v-model="canvasStyle.height" />
          </section>
          <el-divider direction="vertical"></el-divider>
          <section class="action-box scale">
            <span>画布比例</span>
            <input type="number" v-model="canvasStyle.scale" />
            <span>%</span>
          </section>
          <el-button type="primary" icon="el-icon-data-analysis" @click="onUseTemplate">使用模板</el-button>
        </div>
      </div>
      <div class="right">
        <el-button type="default" icon="el-icon-view" @click="onPreview">预览</el-button>
        <el-button type="primary" icon="el-icon-finished" @click="onSave">保存并发布</el-button>
      </div>
    </div>
<!--		<el-button icon="el-icon-arrow-left" @click="prevStep" :disabled="recordIndex <= -1">上一步</el-button>
		<el-button @click="nextStep" :disabled="recordData.length <= 1 || recordIndex >= recordData.length - 1"
			>下一步<i class="el-icon-arrow-right el-icon&#45;&#45;right"></i
		></el-button>
		<el-button type="danger" :disabled="!componentData || !componentData.length" @click="onClear">清空</el-button>
		&lt;!&ndash; 大屏容器 &ndash;&gt;
		<template v-if="sContainer"> </template>
		&lt;!&ndash; 非大屏容器 &ndash;&gt;
		<template v-else>
			<el-button type="primary" @click="onPreview">预览</el-button>
			<el-button type="success" @click="onSave">保存</el-button>
			<el-button type="warning" @click="onClose">关闭</el-button>
		</template>
		<el-button type="primary" @click="onUseTemplate">使用模板</el-button>
		&lt;!&ndash; <GenerateCode></GenerateCode> &ndash;&gt;
		&lt;!&ndash; <el-divider direction="vertical"></el-divider> &ndash;&gt;
		<template v-if="editorType !== 'page' && editorType !== 'template' && editorType !== 'mobile'">
			<el-button :disabled="!areaData?.components?.length" @click="onCompose">
				组合
			</el-button>
			<el-button :disabled="!curComponent || curComponent.type !== 'group'" @click="onSplit">拆分</el-button>
			<el-button :disabled="!curComponent || curComponent.isLock" @click="toggleLock(true)">锁定</el-button>
			<el-button :disabled="!curComponent || !curComponent.isLock" @click="toggleLock(false)">解锁</el-button>
			<el-divider direction="vertical"></el-divider>
		</template>
		<el-select
			v-if="editorType === 'mobile'"
			style="margin-right: 10px"
			v-model="previewEquipment"
			placeholder="请选择"
			@change="chooseEquipment"
		>
			<el-option v-for="item in equipmentList" :key="item.key" :label="item.name" :value="item.key"> </el-option>
		</el-select>
		<section class="action-box size" style="margin-left: 16px">
			<span>画布大小</span>
			<input type="number" v-model="canvasStyle.width" />
			<span>*</span>
			<input type="number" v-model="canvasStyle.height" />
		</section>
		<el-divider direction="vertical"></el-divider>
		<section class="action-box scale">
			<span>画布比例</span>
			<input type="number" v-model="canvasStyle.scale" />
			<span>%</span>
		</section>-->
	</div>
</template>

<script>
/* eslint-disable */
import { Divider,Tooltip } from 'element-ui';
import { mapState } from 'vuex';
import { commonAttr } from '@/custom-component/component-list';
import { getComponentId } from '@/utils/tools';
import splitCompose from '@/utils/splitCompose';
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data';
// import Preview from '@/components/preview/Index';
// import MockArchi from './MockArchi';
import { deepCopy } from '@/utils/tools';
import logo from '@/assets/images/logo_saas.png'
// import GenerateCode from './generate/Index';

export default {
	name: 'Toolbar',
	components: {
		'el-tooltip': Tooltip,
		'el-divider': Divider,
		// MockArchi,
		// GenerateCode
		// Preview
	},
	data() {
		return {
      logo,
      showFullscreen: false,
      // 预览
			showPreview: false,
			width: 375,
			height: 667,
			previewEquipment: 'iphone6',
			currentEquipment: {
				name: 'iphone6/7/7',
				key: 'iphone6',
				width: 375,
				height: 667
			},
			equipmentList: [
				{
					name: 'iphone6/7/8',
					key: 'iphone6',
					width: 375,
					height: 667
				},
				{
					name: 'iPhone 12 pro',
					key: 'iPhone 12 pro',
					width: 390,
					height: 844
				},
				{
					name: 'iPhone XR',
					key: 'iPhone XR',
					width: 414,
					height: 896
				},
				{
					name: 'iPhone SE',
					key: 'iPhone SE',
					width: 375,
					height: 667
				},
				{
					name: 'Sumsung Galaxy A51/71',
					key: 'sumsung Galaxy A51',
					width: 412,
					height: 914
				},
				{
					name: 'Sumsung Galaxy 520 Uitra',
					key: 'Sumsung Galaxy 520',
					width: 412,
					height: 914
				},
				{
					name: 'Sumsung Galaxy 58+',
					key: 'Sumsung Galaxy 58+',
					width: 360,
					height: 740
				},
				{
					name: 'Galaxy Foid',
					key: 'Galaxy Foid',
					width: 280,
					height: 653
				},
				{
					name: 'Surface Duo',
					key: 'Surface Duo',
					width: 540,
					height: 720
				},
				{
					name: 'Surface Pro 7',
					key: 'Surface Pro 7',
					width: 912,
					height: 1368
				},
				{
					name: 'Pixel 5',
					key: 'Pixel 5',
					width: 393,
					height: 851
				}
			]
		};
	},
	computed: {
		...mapState([
			'canvasStyle', // 编辑器样式
			'areaData', // 选择的即将组合组件
			'componentData', // 图层列表
			'editor', // 编辑器对象
			'curComponent', // 当前选择组件对象
			'curIndex', // 当前选择组件下标
			'recordData', // 记录列表
			'recordIndex', // 当前步骤
			'editorType', // 类型
			'sContainer', // 是否为大屏容器编辑状态
			'statusConfig' // 状态管理
		])
	},
	created() {
		// 如果是移动端页面，则设置初始尺寸为iphone6尺寸
		if (this.editorType === 'mobile') {
			this.canvasStyle.width = this.currentEquipment.width;
			this.canvasStyle.height = this.currentEquipment.height;
			sessionStorage.setItem('canvasStyle', JSON.stringify(this.canvasStyle));
		}
	},
	methods: {
    fullscreen(){
      const docElm = document.documentElement;
      if(!this.showFullscreen){
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
      this.showFullscreen = !this.showFullscreen
    },
		/**
		 * @desc: 使用模板
		 */
		onUseTemplate() {
			if (this.componentData?.length) {
				this.$confirm('使用模板将会覆盖当前配置，是否确认使用?')
					.then(() => {
						eventBus.$emit('usePageTemplate');
					})
					.catch(() => {});
			} else {
				eventBus.$emit('usePageTemplate');
			}
		},
		/**
		 * @desc: 组合
		 */
		onCompose() {
			// 获取需要组合的组件
			if (!this.areaData || !this.areaData.components || !this.areaData.components.length) {
				return;
			}
			/* eslint-disable */
			const { top = 0, left = 0 } = this.areaData?.style || {};
			const groupComponents = [];
			this.areaData.components.forEach((item) => {
				// 需要组合分组之前 需要将组合拆开以简化操作
				if (item.component === 'CommonGroup') {
					// 如果要组合的组件中，已经存在组合数据，则需要提前拆分
					const subComponents = item.propValue;
					const editorRect = this.editor.getBoundingClientRect();
					subComponents.forEach((child) => {
						const component = splitCompose(child, editorRect, item.style);
						this.$store.commit('modifyComponent', { component });
						groupComponents.push(component);
					});
					// 删除当前组
					this.$store.commit('deleteComponent', item);
				} else {
					// 普通组件
					const itemLeft = item.style.left || 0;
					const itemTop = item.style.top || 0;
					groupComponents.push({
						...item,
						style: {
							...item.style,
							left: itemLeft - left < 0 ? 0 : itemLeft - left,
							top: itemTop - top < 0 ? 0 : itemTop - top
						},
						groupStyle: {
							...this.areaData.style
						}
					});
				}
			});
			// 组合
			this.$store.commit('modifyComponent', {
				component: {
					id: getComponentId('group'),
					component: 'CommonGroup',
					type: 'group',
					name: '组合',
					icon: 'icona-kanbantongjifill',
					isLock: false,
					// 组件列表
					propValue: groupComponents,
					...commonAttr,
					style: {
						// ...commonStyle,
						rotate: 0,
						...this.areaData.style
					},
					statusConfig: {
						isShow: true
					}
				}
			});
			// 将已组合的组件从列表中删除
			groupComponents.forEach((comItem) => {
				this.$store.commit('deleteComponent', comItem);
			});
			// 清空待组合状态 聚焦组合
			this.areaData.components = [];
			this.$store.commit('setCurComponent', {
				component: this.componentData[this.componentData.length - 1],
				index: this.componentData.length - 1
			});
			// 隐藏拖选框
			eventBus.$emit('hideArea');
		},
		/**
		 * @desc: 拆分组合
		 */
		onSplit() {
			const parentStyle = { ...this.curComponent.style };
			const components = JSON.parse(JSON.stringify(this.curComponent.propValue));
			const editorRect = this.editor.getBoundingClientRect();
			// 删除组件
			const curIndex = this.componentData.findIndex((ele) => ele.id === this.curComponent.id);
			this.componentData.splice(curIndex, 1);
			// 将组合中组件重新写入图层列表
			// console.log(editorRect, parentStyle, '----editorRect, parentStyle')
			components.forEach((item) => {
				const component = splitCompose(item, editorRect, parentStyle);
				this.$store.commit('modifyComponent', {
					component
				});
			});
		},
		/**
		 * @desc: 切换组件是否锁定
		 * @param {Boolean} toggle 是否锁定true / false
		 */
		toggleLock(toggle) {
			this.$store.commit('toggleLock', toggle);
		},
		/**
		 * @desc: 清空
		 */
		onClear() {
			this.$confirm('是否确认清空画布？', '提示', {
				type: 'warning',
				center: true
			}).then(() => {
				this.$store.commit('setComponentData', []);
				this.$store.commit('recordSnapshot');
			});
		},
		/**
		 * @desc: 上一步
		 */
		prevStep() {
			this.$store.commit('prevStep');
		},
		/**
		 * @desc: 下一步
		 */
		nextStep() {
			this.$store.commit('nextStep');
		},
		/**
		 * @desc: 保存(未完成)
		 */
		onSave() {
			this.$loading();
			/* eslint-disable */
			const data_id = this.$route.query.id;
			sessionStorage.setItem('componentData', JSON.stringify(this.componentData));
			sessionStorage.setItem('canvasStyle', JSON.stringify(this.canvasStyle));
			sessionStorage.setItem('statusConfig', JSON.stringify(this.statusConfig));
			sessionStorage.setItem('editorType', this.editorType);
			const componentData = this.cleanComponentData(deepCopy(this.componentData));
			const params = {
				editorType: this.editorType,
				//不保存中控台标记数据
				componentData: componentData.filter(v => !v.id.includes('-view')),
				editorConfig: this.canvasStyle,
				statusConfig: this.statusConfig
			};
			this.$loading();
			dataInterface({
				__method_name__: data_id ? 'updateData' : 'createData',
				data_id: data_id || '',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				'f708a9c6-2514-47ad-9056-3c3a1c37496f': JSON.stringify(params), // 配置数据
				// 'd1f7e149-2af7-468e-85be-5ef9bd97f634': this.canvasStyle.title, // 页面名称
				'3402cf7d-3b26-4d3c-82cb-72f37a7cbcb0': data_id
			}).then((res) => {
				this.$loading().close();
				if (res.status === 200 && res.data.code === 200) {
					this.$message.success('已保存！');
					if (!data_id) {
						// 新增保存后返回避免id缓存问题
						this.$router.replace({
							path: '/index'
						});
					}
				}
			}).catch((err) => {
				this.$loading().close();
				this.$message.error(`保存失败:${err.msg}`);
			});
		},
		/**
		 * @desc: 清除无用数据
		 * @param {Array} arr 希望被清除的数组
		 * @return {Array} 处理完毕的数组结果
		 */
		cleanComponentData(arr) {
			if (!arr || !Array.isArray(arr)) return arr;
			return arr.map((ele) => {
				if (ele.type === 'container' || ele.type === 'screenContainer') {
					let children = ele.children;
					if (Array.isArray(children) && children.length) {
						children = this.cleanComponentData(children);
					}
					return {
						...ele,
						children,
						containerData: null,
						fullData: null,
						resolveData: null,
						database: ele.database
							? {
									...ele.database,
									viewData: ele.database.viewData
										? {
												uuid: ele.database.viewData.uuid,
												id: ele.database.viewData.id,
												name: ele.database.viewData.name
										  }
										: null,
									objectData: ele.database.objectData
										? {
												code: ele.database.objectData.code,
												uuid: ele.database.objectData.uuid,
												id: ele.database.objectData.id,
												name: ele.database.objectData.name
										  }
										: null
							  }
							: null
					};
				}
				return {
					...ele,
					resolveData: null
				};
			});
		},
		/**
		 * @desc: 预览
		 */
		onPreview() {
			// this.showPreview = true;
			window.open(`${window.location.origin}/page-view?pageUUID=${this.$route.query.pageUUID}`);
		},
		/**
		 * @desc: 关闭
		 */
		onClose() {
			this.$confirm('是否放弃编辑并返回？', '提示', {
				type: 'warning',
				center: true
			})
				.then(() => {
					this.$router.go(-1)
					return
					const isModifySite = sessionStorage.getItem('isModifySite');
					if (isModifySite || isModifySite === null) {
						this.$store.commit('clearComponents');
						sessionStorage.removeItem('isModifySite');
						// 需要将状态值清空
						this.$router.replace({
							path: '/index'
						});
					} else {
						window.parent.postMessage({ flag: true, type: 'closeEditor' }, '*');
					}
				})
				.catch(() => {});
		},
		chooseEquipment(key) {
			const index = this.equipmentList.findIndex((item) => item.key === key);
			this.currentEquipment = this.equipmentList[index];
			this.canvasStyle.width = this.currentEquipment.width;
			this.canvasStyle.height = this.currentEquipment.height;
			sessionStorage.setItem('canvasStyle', JSON.stringify(this.canvasStyle));
		}
	}
};
</script>

<style lang="less" scoped>
.toolbar {
	width: 100%;
	min-height: 64px;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding: 16px 0;

  .header-wrap{
    width: 100%;
    display: flex;

    .left{
      width: 200px;
      .el-icon-arrow-left{
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
      }
      .logo{
        width: 120px;
        height: 30px;
        vertical-align: middle;
      }
    }
    .middle{
      flex: 1;
      display: flex;
      flex-direction: column;

      .btn-group{
        display: flex;
        align-self: center;
      }
    }
    .right{
      width: 200px;
      text-align: right;
    }
  }

	.el-divider--vertical {
		height: 20px;
		margin-top: 6px;
	}
	.action-box {
		display: flex;
		span {
			line-height: 32px;
			font-size: 14px;
			padding: 0 10px;
			&:first-of-type {
				padding-left: 0;
			}
		}
		input {
			width: 50px;
			outline: none;
			padding: 0 5px;
			height: 20px;
			margin-top: 5px;
			border: 1px solid #ddd;
			color: #606266;
			border-radius: 2px;
		}
	}
}
</style>
