<!--
 * @Description: 选择页面上可用的组件
 * @Author: luocheng
 * @Date: 2022-07-21 13:53:29
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-29 14:37:26
-->
<template>
  <div class="select-component">
    <el-select v-model="componentId" placeholder="请选择组件" clearable @change="onComponent">
      <el-option
        v-for="item in componentList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { flatTree, unique } from '@/utils/tools';

export default {
  name: 'SelectComponent',
  props: {
    value: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      componentId: ''
    }
  },
  computed: {
    ...mapState([
      'curComponent',
      'componentData',
      'originComponentData',
			'sContainer'
    ]),
    // 可选组件列表
		componentList() {
			let result = [];
			if (this.sContainer) {
				result = flatTree(this.originComponentData || [], 'children', {
					label: 'name',
					value: 'id'
				}, [this.curComponent?.id], []);
			}
			result = result.concat(flatTree(this.componentData, 'children', {
				label: 'name',
				value: 'id'
			}, [this.curComponent?.id], []))
			return unique(result);
		}
  },
  created() {
    this.componentId = this.value;
  },
  methods: {
    /**
     * @desc: 选择组件
     */
    onComponent() {
      this.$emit('input', this.componentId);
    }
  }
}
</script>

<style lang="less" scoped>
.select-component{}
</style>