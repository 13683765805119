<!--
 * @Description: 表格字段配置
 * @Author: luocheng
 * @Date: 2021-12-06 14:14:55
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 11:33:58
-->
<template>
  <div class="table-config">
    <ColumnItem
      v-for="(item, index) in columnList"
      :key="item.order"
      :data="item"
      :index="index"
      :count="columnList.length"
      @removeConfig="onRemoveColumn"
      @setOrder="onSetOrder"
    ></ColumnItem>
    <section class="actions">
      <el-button size="mini">重置</el-button>
      <el-button type="primary" size="mini">确认</el-button>
      <el-button type="success" size="mini" @click="onPlus">新增</el-button>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ColumnItem from "./ColumnItem.vue"
export default {
  name: 'TableConfig',
  components: {
    ColumnItem
  },
  data() {
    return {
      // 单元格配置列表
      columnList: []
    }
  },
  computed: {
    ...mapState(['curComponent']),
  },
  created() {
    this.columnList = this.curComponent?.templateConfig?.columns || [];
  },
  methods: {
    /**
     * @desc: 新增
     */
    onPlus() {
      this.columnList.push({
        order: this.columnList.length,
        code: '',
        name: '',
        width: '',
        type: 'text',
        color: '#333',
        formula: "",
        // 状态
        statusList: [],
        // 图片尺寸
        imageSize: {
          height: 60,
          width: 60
        },
        // 时间格式
        dateFormat: 'yyyy-MM-dd'
      })
    },
    /**
     * @desc: 移除单元格配置
     * @param {Number} index 下标
     */
    onRemoveColumn({ index }) {
      this.columnList.splice(index, 1);
    },
    /**
     * @desc: 移动
     */
    onSetOrder({ direction, index }) {
			const item = this.columnList[index];
			const movedIndex = direction === 'up' ? index - 1 : index + 1;
      console.log(direction, 'direction', movedIndex)
			const beMoved = JSON.parse(JSON.stringify(this.columnList[movedIndex]));
      this.columnList.splice(index, 1, {
        ...beMoved
      })
      this.columnList.splice(movedIndex, 1, {
        ...item
      })
    }
  }
}
</script>

<style lang="less" scoped>
.table-config {
  width: 100%;
}
</style>