<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-20 13:38:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-20 13:59:42
 * @FilePath: /dataview-next/src/components/treeConfig/iconConfig/FixedIcon.vue
 * @Description: 固定图标
-->
<template>
  <section class="fixed-icon">
    <h3 class="title">
      固定图标
      <el-popover
        placement="top-start"
        title="提示"
        width="400"
        :center="true"
        content="支持图标、图片类型，自定义判断渲染。不输入将使用默认图标"
        trigger="click">
        <span slot="reference">
          <i class="el-icon-question"></i>
        </span>
      </el-popover>
    </h3>
    <el-form :model="fixedConfig" v-if="fixedConfig" label-width="90px">
      <el-form-item label="前置图标">
        <el-input v-model="fixedConfig.preIcon" placeholder="请输入前置图标" @input="onEmit"></el-input>
      </el-form-item>
      <el-form-item label="操作图标">
        <el-input v-model="fixedConfig.actionIcon" placeholder="请输入操作图标" @input="onEmit"></el-input>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { Popover } from 'element-ui'
export default {
  name: 'FixedIcon',
  components: {
    'el-popover': Popover
  },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      fixedConfig: null
    }
  },
  created() {
    this.fixedConfig = this.value ??  {
      preIcon: '',
      actionIcon: ''
    }
  },
  methods: {
    onEmit() {
      this.$emit('input', this.fixedConfig);
    }
  }
}
</script>

<style lang="less" scoped>
.fixed-icon {
  .title {
    position: relative;
    margin-bottom: 10px;
    text-align: left;
    text-indent: 8px;
    &::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 14px;
      background: @theme;
      left: 0;
      top: 6px;
      border-radius: 1px;
    }
    .el-icon-question {
      color: @warnColor;
    }
  }
}
</style>