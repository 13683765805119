<!--
 * @Description: 状态
 * @Author: luocheng
 * @Date: 2022-06-14 15:22:55
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-02 13:58:00
-->
<template>
  <div class="state-item" @click="onModify">
    <el-form :model="stateData" label-width="100px" size="mini">
      <el-form-item label="类型">
        <el-tag>{{ +stateData.type === 1 ? '页面状态' : '全局状态' }}</el-tag>
      </el-form-item>
      <el-form-item label="状态标识">
        <span>{{ stateData.code }}</span>
      </el-form-item>
      <el-form-item label="状态值类型">
        <span>{{ stateData.valueType | filterValueType }}</span>
      </el-form-item>
      <el-form-item label="默认值">
        <el-tag type="info">
          <template v-if="!stateData.useDefault">未使用默认值</template>
          <template v-else-if="stateData.valueType === 'string'">{{ stateData.defaultValue || '空字符串' }}</template>
          <template v-else>{{ stateData.defaultValue }}</template>
        </el-tag>
      </el-form-item>
      <el-form-item label="当前值">
        <el-tag type="success" v-if="stateData.valueType === 'string'">{{ targetValue || '空字符串' }}</el-tag>
          <template v-else>{{ targetValue }}</template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Tag } from 'element-ui';
import { mapState } from 'vuex';

const valueTypes = [
  {
    label: '数字',
    value: 'number'
  },
  {
    label: '字符串',
    value: 'string'
  },
  {
    label: '判断值',
    value: 'boolean'
  },
  {
    label: '时间日期',
    value: 'date'
  },
  {
    label: '复杂对象',
    value: 'object'
  },
  {
    label: '数组列表',
    value: 'array'
  }
];

export default {
  name: 'StateItem',
  props: {
    // 状态数据
    stateData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    'el-tag': Tag
  },
  data() {
    return {
    }
  },
  filters: {
    filterValueType(val) {
      return valueTypes.find(ele => ele.value === val)?.label;
    }
  },
  computed: {
    ...mapState(['_PageCustomStatus', '_APPCustomStatus']),
    // 当前状态值
    targetValue() {
      const { type, code } = this.stateData;
      if (type === 1) {
        return this._PageCustomStatus[code];
      }
      return this._APPCustomStatus[code];
    }
  },
  methods: {
    /**
     * @desc: 编辑信息 
     */
    onModify() {
      this.$emit('modifyInfo', this.stateData);
    }
  }
}
</script>

<style lang="less" scoped>
.state-item{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 5px;
  &:hover{
    border: 1px solid @theme;
  }
  .el-form{
    .el-form-item{
      margin-bottom: 5px;
    }
  }
}
</style>