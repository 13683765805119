<!--
 * @Description: 统计分析
 * @Author: luocheng
 * @Date: 2021-12-10 11:19:08
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-01 16:06:03
-->
<template>
	<div class="statistical-config">
		<header class="header">
			<h4 class="title">统计配置</h4>
			<i class="el-icon-plus" @click="onPlus"></i>
		</header>
		<el-form :model="dataConfig" v-if="dataConfig.statisticalConfig && dataConfig.statisticalConfig.length">
			<article class="statics-params">
				<section class="static-item" v-for="(item, index) in dataConfig.statisticalConfig" :key="index">
					<div class="item-content">
						<el-input v-model="item.key" placeholder="请输入code" clearable size="mini"></el-input>
						<el-select
							v-model="item.view_uuid"
							placeholder="请选择统计视图"
							:remote-method="onRemote"
							clearable
							filterable
							remote
							size="mini"
						>
							<el-option
								v-for="view in viewList"
								:key="view.id"
								:label="view.object_name + '___' + view.name"
								:value="view.uuid"
							></el-option>
						</el-select>
						<el-input v-model="item.field_uuid" placeholder="请输入统计字段(uuid)" size="mini" clearable></el-input>
						<el-select v-model="item.aggregate" placeholder="请选择统计统计函数" clearable size="mini">
							<el-option v-for="agg in aggList" :key="agg.value" :label="agg.label" :value="agg.value"></el-option>
						</el-select>
					</div>
					<i class="el-icon-delete" @click="onRemove(index)"></i>
				</section>
				<!-- <el-button
					type="text"
					style="color:#409EFF"
					size="mini"
					@click="onPlus"
				>添加</el-button> -->
			</article>
			<el-button class="confirm-item" type="primary" @click="onSubmit">确认配置</el-button>
		</el-form>
		<el-empty v-else></el-empty>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { Empty } from 'element-ui';

export default {
	name: 'StatisticalConfig',
	components: {
		'el-empty': Empty
	},
	data() {
		return {
			// 配置
			dataConfig: {
				mapping: 'object',
				requestType: 'dataList',
				objectData: null,
				viewData: null,
				relationData: null,
				fieldList: [],
				paramsConfig: [],
				metadata: [],
				userOutParams: false,
				mockData: [],
				// 统计分析配置
				statisticalConfig: []
			},
			// 视图列表
			viewList: [],
			// 全局函数列表(可能会补充)
			aggList: [
				{
					label: '最大值',
					value: 0
				},
				{
					label: '最小值',
					value: 1
				},
				{
					label: '平均值',
					value: 2
				},
				{
					label: '求和',
					value: 3
				},
				{
					label: '总数',
					value: 4
				}
			]
		};
	},
	computed: {
		...mapState(['curComponent']),
		database() {
			return (this.curComponent && this.curComponent.database) || null;
		}
	},
	created() {
		this.dataConfig = this.database || {
			mapping: 'object',
			requestType: 'dataList',
			objectData: null,
			viewData: null,
			relationData: null,
			fieldList: [],
			paramsConfig: [],
			metadata: [],
			userOutParams: false,
			mockData: [],
			// 统计分析配置
			statisticalConfig: []
		};
		this.onRemote('');
	},
	methods: {
		/**
		 * @desc: 添加配置
		 */
		onPlus() {
			this.dataConfig.statisticalConfig.push({
				key: '',
				view_uuid: '',
				field_uuid: '',
				aggregate: 4
			});
		},
		/**
		 * @desc: 移除配置
		 * @param {Number} index
		 */
		onRemove(index) {
			this.$confirm('是否确认移除?', '提示').then(() => {
				this.dataConfig.statisticalConfig.splice(index, 1);
			});
		},
		/**
		 * @desc: 确认配置
		 */
		onSubmit() {
			this.$store.commit('modifyComponent', {
				component: {
					...this.curComponent,
					database: this.dataConfig
				},
				containerId: null,
				isModify: true
			});
			// 初始化获取参数
			eventBus.$emit('databaseTrigger', {
				componentId: this.curComponent.id,
				action: this.database.requestType,
				output: '',
				isInit: true
			});
		},
		/**
		 * @desc: 获取视图列表
		 */
		onRemote(value = '') {
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: '8e0c05b5-ca9a-48ca-8dcb-19a29bbb00f1',
				view_uuid: 'view61b2c4874bd5b',
				page: 1,
				size: 20,
				search: [
					{
						field_uuid: 'field61b2c40475628',
						ruleType: 'like',
						value
					}
				],
				transcode: 0
			})
				.then((res) => {
					this.viewList = res?.data?.data.data || [];
				})
				.catch((err) => {
					console.log(err, '---错误');
				});
		}
	}
};
</script>

<style lang="less" scoped>
.statistical-config {
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid #f2f3f5;
	border-bottom: 1px solid #f2f3f5;
	padding: 10px 0;
	margin-bottom: 10px;
	.header {
		display: flex;
		.title {
			flex: 1;
			font-weight: 500;
			padding: 5px;
		}
		.el-icon-plus {
			font-weight: bold;
			line-height: 33px;
			font-size: 16px;
			box-sizing: border-box;
			padding: 0 6px;
			cursor: pointer;
			color: #409eff;
		}
	}
	.confirm-item{
		width: 100%;
		span{
			display: block;
			text-align: center;
		}
	}
	.statics-params {
		.static-item {
			display: flex;
			box-sizing: border-box;
			border: 1px solid #f2f3f5;
			margin-bottom: 15px;
			padding: 5px;
			border-radius: 4px;
			&:focus {
				border-color: red;
			}
			.item-content {
				flex: 1;
				.el-input,
				.el-select {
					margin-bottom: 5px;
					width: 100%;
				}
			}
			.el-icon-delete {
				font-size: 16px;
				color: @dangerColor;
				line-height: 80px;
				padding-left: 15px;
				cursor: pointer;
			}
		}
	}
}
</style>
